var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "tab--panel flex-row-justify-center" },
    _vm._l(_vm.tabItems, function(item) {
      return _c(
        "li",
        {
          key: item.key,
          staticClass: "tab--item",
          style: _vm.tabItems.length === 1 && "justify-content: center",
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.onClick(item.key)
            }
          }
        },
        [
          item.icon
            ? _c(
                "span",
                { staticClass: "tab--icon" },
                [
                  _c("icons", {
                    attrs: {
                      name: item.icon,
                      color: _vm.isActive(item.key) ? "active" : "inactive",
                      size: "medium"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "item--text title-h5",
              class: [{ active: _vm.isActive(item.key) }, item.key]
            },
            [
              _c(
                "span",
                { class: { "active--text": _vm.isActive(item.key) } },
                [_vm._v(_vm._s(item.name))]
              ),
              _vm._v(" "),
              item.label
                ? _c(
                    "span",
                    {
                      staticClass: "item--label body-b3",
                      class: { "active--count": _vm.isActive(item.key) }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.getItemCount(item.label)) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e()
            ]
          )
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }