var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "collaborators" },
    _vm._l(Object.entries(_vm.groupedMembers), function(ref) {
      var category = ref[0]
      var membersInCategory = ref[1]
      return _c(
        "div",
        { key: category, staticClass: "collaborators-table" },
        [
          _c(
            "div",
            {
              staticClass:
                "flex-row flex-align flex-justify--between mg-right--small"
            },
            [
              _c("div", { staticClass: "flex-row flex-align gap--smaller" }, [
                _c("span", {
                  staticClass: "title-h5 text-dark capitalize",
                  domProps: { textContent: _vm._s(_vm.tableTitle(category)) }
                })
              ]),
              _vm._v(" "),
              membersInCategory.length
                ? _c("metric-capsule", [
                    _vm._v(
                      "\n        " +
                        _vm._s(membersInCategory.length) +
                        "\n      "
                    )
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("data-table", {
            attrs: {
              columns: _vm.tableColumns,
              "table-data": membersInCategory,
              loading: _vm.isLoading,
              "enable-animations": false
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "loading-state",
                  fn: function() {
                    return [_c("skeleton-loader")]
                  },
                  proxy: true
                }
              ],
              null,
              true
            )
          })
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }