var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "footer-section flex-row flex-justify--between" },
    [
      _c(
        "woot-base-button",
        {
          on: {
            click: function($event) {
              return _vm.$emit("submitFieldForm")
            }
          }
        },
        [_vm._v("\n    " + _vm._s(_vm.editView ? "SAVE" : "CREATE") + "\n  ")]
      ),
      _vm._v(" "),
      _c(
        "woot-base-button",
        {
          attrs: { variant: "secondary-danger" },
          on: {
            click: function($event) {
              return _vm.$router.push({ name: "settings_custom_ticket_fields" })
            }
          }
        },
        [_vm._v("\n    DISCARD\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }