var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.handleSubmit($event)
        }
      }
    },
    [
      !_vm.selectedTeam
        ? _c("div", {
            staticClass: "title-h3 text-dark mg-bottom--two",
            domProps: { textContent: _vm._s("Details") }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("woot-input", {
        staticClass: "medium-12 columns content-margin",
        class: { error: _vm.$v.name.$error },
        attrs: {
          required: "",
          "auto-focus": "",
          "has-error": _vm.$v.name.$error,
          error: _vm.$t("TEAMS_SETTINGS.FORM.NAME.ERROR"),
          label: _vm.$t("TEAMS_SETTINGS.FORM.NAME.LABEL"),
          placeholder: _vm.$t("TEAMS_SETTINGS.FORM.NAME.PLACEHOLDER")
        },
        on: { input: _vm.$v.name.$touch },
        model: {
          value: _vm.name,
          callback: function($$v) {
            _vm.name = typeof $$v === "string" ? $$v.trim() : $$v
          },
          expression: "name"
        }
      }),
      _vm._v(" "),
      _c("woot-input", {
        staticClass: "medium-12 columns",
        attrs: {
          rows: "2",
          label: _vm.$t("TEAMS_SETTINGS.FORM.DESCRIPTION.LABEL"),
          placeholder: _vm.$t("TEAMS_SETTINGS.FORM.DESCRIPTION.PLACEHOLDER")
        },
        model: {
          value: _vm.description,
          callback: function($$v) {
            _vm.description = typeof $$v === "string" ? $$v.trim() : $$v
          },
          expression: "description"
        }
      }),
      _vm._v(" "),
      _c(
        "page-header",
        {
          attrs: {
            size: "small",
            "header-title": _vm.$t("TEAMS_SETTINGS.FORM.AUTO_ASSIGN.LABEL"),
            "show-border": false
          }
        },
        [
          _c("woot-switch", {
            attrs: { id: Math.random(), value: _vm.allowAutoAssign },
            on: {
              click: function() {
                return (_vm.allowAutoAssign = !_vm.allowAutoAssign)
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mg-bottom--jumbo" },
        [
          _c(
            "label",
            {
              staticClass: "formlabel flex-row",
              staticStyle: { "margin-bottom": "0.4rem" }
            },
            [
              _c("span", {
                staticClass: "title-h5 text-dark",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("TEAMS_SETTINGS.CREATE_FLOW.AGENTS.TITLE")
                  )
                }
              }),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.$t("TEAMS_SETTINGS.CREATE_FLOW.AGENTS.DESC"),
                      expression: "$t('TEAMS_SETTINGS.CREATE_FLOW.AGENTS.DESC')"
                    }
                  ],
                  staticClass: "desc-text capital_c3"
                },
                [
                  _c("icons", {
                    attrs: {
                      name: "warning",
                      color: "grey evenodd",
                      size: "semimedium",
                      "show-title": false
                    }
                  })
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _vm.selectedAgents.length
            ? _c(
                "div",
                {
                  staticClass:
                    "medium-12 agent-chips custom-scroll mg-bottom--slab"
                },
                _vm._l(_vm.selectedAgents, function(agent, index) {
                  return _c("woot-chips", {
                    key: agent.id,
                    attrs: {
                      title: agent.available_name,
                      variant: "primary-small",
                      "show-cancel-icon": true,
                      "show-tooltip": false
                    },
                    on: {
                      click: function($event) {
                        return _vm.onRemoveAgent(index)
                      }
                    }
                  })
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("woot-single-selector", {
            staticClass: "medium-12",
            attrs: {
              "default-option": _vm.$t(
                "TEAMS_SETTINGS.CREATE_FLOW.AGENTS.BUTTON_TEXT"
              ),
              "hide-on-select": false,
              "option-list": _vm.notSelectedAgents,
              "custom-style": { left: "auto", width: "100%" }
            },
            on: { click: _vm.onAgentSelect }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "pagination-controls",
          class: { "snap-to-bottom": !_vm.selectedTeam }
        },
        [
          !_vm.selectedTeam
            ? _c(
                "woot-base-button",
                {
                  staticClass: "modal-button",
                  attrs: { variant: "tertiary-danger" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.$emit("onClose")
                    }
                  }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("TEAMS_SETTINGS.ADD.CANCEL_BUTTON_TEXT")) +
                      "\n    "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "woot-base-button",
            {
              staticClass: "modal-button",
              attrs: {
                variant: "primary",
                disabled: _vm.$v.name.$invalid,
                loading: _vm.uiFlags.isCreating || _vm.uiFlags.isUpdating,
                type: "submit"
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.selectedTeam ? "Save" : "Create") +
                  "\n    "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }