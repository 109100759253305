var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.enabledCrms.length !== 0
    ? _c(
        "div",
        { staticClass: "ticket-form-container" },
        [
          _c(
            "div",
            { staticClass: "content-margin" },
            [
              _c("woot-single-selector", {
                attrs: {
                  "default-option": _vm.selectedCrm,
                  "option-list": _vm.crmTypeList,
                  "custom-style": {
                    left: 0,
                    top: 0,
                    width: "20rem"
                  }
                },
                on: { click: _vm.onSelectCrmType }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(_vm.currentCRMComponent, {
            key: _vm.selectedCrm,
            tag: "component"
          })
        ],
        1
      )
    : _c("span", {
        staticClass: "body-b3 text-light",
        domProps: { textContent: _vm._s("No CRMs available") }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }