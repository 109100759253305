var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "message-text__wrap" },
    [
      _c(
        "div",
        { staticClass: "message-image-container", on: { click: _vm.onClick } },
        [
          _c("img", {
            ref: "image",
            staticClass: "message-image",
            attrs: { src: _vm.url }
          })
        ]
      ),
      _vm._v(" "),
      _vm.hasAttachedContent
        ? _c("span", {
            staticClass: "message-span",
            class: {
              "message-deleted": _vm.isDeleted
            },
            domProps: { innerHTML: _vm._s(_vm.message) }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: {
            "full-width": true,
            show: _vm.show,
            "on-close": _vm.onClose
          },
          on: {
            "update:show": function($event) {
              _vm.show = $event
            }
          }
        },
        [
          _c(
            "span",
            {
              staticClass: "download-button",
              on: {
                click: function($event) {
                  return _vm.onDownload(_vm.url)
                }
              }
            },
            [
              _c("icons", {
                attrs: { name: "download", color: "green", size: "medium" }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("img", { staticClass: "modal-image", attrs: { src: _vm.url } })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }