var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "conditions-section" }, [
    _c(
      "div",
      { staticClass: "columns filters-wrap" },
      [
        _c("label", { staticClass: "formlabel" }, [
          _c("span", {
            staticClass: "title-h5 text-dark",
            domProps: {
              textContent: _vm._s(
                _vm.$t("AUTOMATION.ADD.FORM.CONDITIONS.LABEL")
              )
            }
          })
        ]),
        _vm._v(" "),
        _vm._l(_vm.conditions, function(condition, idx) {
          return _c("condition-input", {
            key: idx,
            attrs: {
              index: idx,
              "filter-attributes": _vm.getAttributes(_vm.eventName),
              "input-type": _vm.getInputType(_vm.conditions[idx].attribute_key),
              "label-key": _vm.getLabelKey(_vm.conditions[idx].attribute_key),
              operators: _vm.getOperators(_vm.conditions[idx].attribute_key),
              "dropdown-values": _vm.getConditionDropdownValues(
                _vm.conditions[idx].attribute_key
              ),
              "show-query-operator": idx !== _vm.conditions.length - 1,
              "show-user-input": !!_vm.conditions[idx].attribute_key
            },
            on: {
              resetFilter: function($event) {
                return _vm.resetFilter(idx, _vm.conditions[idx])
              },
              removeFilter: function($event) {
                return _vm.removeFilter(idx)
              }
            },
            model: {
              value: _vm.conditions[idx],
              callback: function($$v) {
                _vm.$set(_vm.conditions, idx, $$v)
              },
              expression: "conditions[idx]"
            }
          })
        }),
        _vm._v(" "),
        !!_vm.lastCondition.attribute_key
          ? _c(
              "div",
              { staticClass: "filter-actions" },
              [
                _c(
                  "woot-base-button",
                  {
                    attrs: { tag: "span", size: "small", variant: "secondary" },
                    on: { click: _vm.appendCondition }
                  },
                  [
                    _c("icons", {
                      attrs: {
                        name: "plus",
                        color: "green",
                        size: "semimedium",
                        "show-title": false,
                        view: "0 0 20 20"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }