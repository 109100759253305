var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-base-button",
    {
      attrs: { "front-icon": "plus", size: "small", variant: "secondary" },
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [_vm._v("\n  " + _vm._s(_vm.label) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }