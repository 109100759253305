var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box settings-box no-padding" },
    [
      _c(
        "div",
        { staticClass: "settings-button flex-row flex-align gap--normal" },
        [
          _c("reference-box", {
            attrs: {
              to: _vm.documentation.canned,
              content: "Know More",
              type: "document"
            }
          }),
          _vm._v(" "),
          _c(
            "woot-base-button",
            {
              attrs: {
                size: "medium",
                "front-icon": "plus",
                "icon-view-box": "0 0 20 20"
              },
              on: { click: _vm.openAddPopup }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("CANNED_MGMT.HEADER_BTN_TXT")) +
                  "\n    "
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "settings-container" },
        [
          _c("div", { staticClass: "column settings-main-menu-new" }, [
            _c(
              "div",
              { staticClass: "table-container" },
              [
                _c("data-table", {
                  attrs: {
                    columns: _vm.tableColumns,
                    "table-data": _vm.cannedList,
                    actions: _vm.actionsList,
                    loading: _vm.uiFlags.fetchingList,
                    "enable-animations": _vm.cannedList.length <= 10,
                    _class: "scrollable-table custom-scroll"
                  },
                  on: {
                    select: _vm.onSelectCanned,
                    delete: _vm.openDeletePopup
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "table-features",
                      fn: function() {
                        return [
                          _c("search-box", {
                            attrs: {
                              placeholder: _vm.$t("CANNED_MGMT.SEARCH_TXT"),
                              value: _vm.search,
                              "custom-class": "search-box"
                            },
                            on: { setSearch: _vm.setSearch }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "zero-state",
                      fn: function() {
                        return [
                          !_vm.cannedList.length
                            ? _c("table-zero-state", {
                                attrs: {
                                  title: "No Canned responses added till now",
                                  subtitle:
                                    "Click on the Add Canned Response Button",
                                  "asset-link":
                                    "/brand-assets/agent_zero_state.svg"
                                }
                              })
                            : _vm._e()
                        ]
                      },
                      proxy: true
                    }
                  ])
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("settings-side-card", [
            _c(
              "div",
              { staticClass: "flex-column full-height" },
              [
                _vm.selectedResponse.id
                  ? _c(
                      "div",
                      { staticClass: "canned-rhs-panel custom-scroll" },
                      [
                        _c("add-edit-canned", {
                          key: _vm.selectedResponse.id,
                          attrs: { data: _vm.selectedResponse }
                        })
                      ],
                      1
                    )
                  : _c(
                      "educational-onboarding",
                      { attrs: { "show-case-data": _vm.SHOWCASE_CANNED_DATA } },
                      [
                        _c(
                          "div",
                          { staticClass: "mg-top--slab mg-left mg-right" },
                          [
                            _c("span", {
                              staticClass: "title-h5 text-dark",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t(
                                    "CANNED_MGMT.EDUCATIONAL_ONBOARDING.HOW_TO.TITLE"
                                  )
                                )
                              }
                            }),
                            _vm._v(" "),
                            _c("div", {
                              staticClass: "body-b3 text-light",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t(
                                    "CANNED_MGMT.EDUCATIONAL_ONBOARDING.HOW_TO.DESC"
                                  )
                                )
                              }
                            })
                          ]
                        )
                      ]
                    ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "action-btns full-width flex-row flex-justify gap--one"
                  },
                  [
                    _vm.cannedList.length
                      ? _c(
                          "woot-base-button",
                          {
                            attrs: { variant: "secondary", "full-width": "" },
                            on: {
                              click: function($event) {
                                _vm.showExportCannedModal = true
                              }
                            }
                          },
                          [_vm._v("\n            Export\n          ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "woot-base-button",
                      {
                        attrs: { variant: "secondary", "full-width": "" },
                        on: {
                          click: function($event) {
                            _vm.showImportCannedModal = true
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("CANNED_MGMT.IMPORT_CSV")) +
                            "\n          "
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm.showImportCannedModal
        ? _c("import-canned", {
            on: {
              onClose: function($event) {
                _vm.showImportCannedModal = false
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showExportCannedModal
        ? _c("export-canned", {
            on: {
              onClose: function($event) {
                _vm.showExportCannedModal = false
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "woot-modal-new",
        {
          attrs: { show: _vm.showAddPopup, "on-close": _vm.hideAddPopup },
          on: {
            "update:show": function($event) {
              _vm.showAddPopup = $event
            }
          }
        },
        [
          _c("woot-modal-header-new", {
            attrs: { "header-title": _vm.$t("CANNED_MGMT.ADD.TITLE") }
          }),
          _vm._v(" "),
          _c("add-edit-canned", {
            staticClass: "settings-form",
            attrs: { "on-close": _vm.hideAddPopup, "show-close": "" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("woot-delete-modal-new", {
        attrs: {
          show: _vm.showDeleteConfirmationPopup,
          "on-close": _vm.closeDeletePopup,
          "on-confirm": _vm.confirmDeletion,
          "show-close": "",
          title: _vm.$t("CANNED_MGMT.DELETE.CONFIRM.TITLE"),
          message: _vm.deleteMessage,
          "confirm-text": "Continue",
          "reject-text": "Back",
          "custom-style": {
            "max-width": "35.2rem",
            height: "auto"
          }
        },
        on: {
          "update:show": function($event) {
            _vm.showDeleteConfirmationPopup = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }