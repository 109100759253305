var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    {
      attrs: {
        show: _vm.opened,
        "custom-style": { width: "60rem" },
        "on-close": function() {
          return _vm.$emit("onClose")
        }
      },
      on: {
        "update:show": function($event) {
          _vm.opened = $event
        }
      }
    },
    [
      _c("woot-modal-header", {
        attrs: {
          "header-title": _vm.$t(
            "CUSTOM_TICKET_FIELDS.CREATE.DETAILS_MODAL.TITLE"
          )
        }
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "modal-body",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.$emit("onSubmit", {
                name: _vm.newName,
                description: _vm.newDesc
              })
            }
          }
        },
        [
          _c("woot-input", {
            staticClass: "mg-bottom",
            class: { error: _vm.$v.newName.$error },
            attrs: {
              size: "small",
              value: _vm.newName,
              label: _vm.$t("CUSTOM_TICKET_FIELDS.ADD.FORM.NAME.LABEL"),
              type: "text",
              "auto-focus": "",
              required: "",
              "has-error": _vm.$v.newName.$error,
              error: _vm.nameError,
              placeholder: _vm.$t(
                "CUSTOM_TICKET_FIELDS.ADD.FORM.NAME.PLACEHOLDER"
              )
            },
            on: {
              input: function($event) {
                _vm.newName = $event
              },
              blur: _vm.$v.newName.$touch
            }
          }),
          _vm._v(" "),
          _c("woot-input", {
            staticClass: "content-margin",
            attrs: {
              size: "small",
              value: _vm.newDesc,
              label: _vm.$t("CUSTOM_TICKET_FIELDS.ADD.FORM.DESC.LABEL"),
              tag: "textarea",
              rows: "3",
              "full-width": "",
              placeholder: _vm.$t(
                "CUSTOM_TICKET_FIELDS.ADD.FORM.DESC.PLACEHOLDER"
              )
            },
            on: {
              input: function($event) {
                _vm.newDesc = $event
              }
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-body--footer flex-row flex-justify--end" },
            [
              _c(
                "woot-base-button",
                {
                  attrs: {
                    size: "small",
                    type: "submit",
                    disabled: _vm.$v.$invalid
                  }
                },
                [_vm._v("\n        Continue\n      ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }