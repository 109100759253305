var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "actions-section" }, [
    _c(
      "div",
      { staticClass: "columns filters-wrap" },
      [
        _c("label", { staticClass: "formlabel" }, [
          _c("span", {
            staticClass: "title-h5 text-dark",
            domProps: {
              textContent: _vm._s(_vm.$t("AUTOMATION.ADD.FORM.ACTIONS.LABEL"))
            }
          })
        ]),
        _vm._v(" "),
        _vm._l(_vm.actions, function(action, idx) {
          return _c("action-input", {
            key: idx,
            attrs: {
              index: idx,
              "is-first-action": idx === 0,
              "action-types": _vm.actionTypes,
              "dropdown-values": _vm.getActionDropdownValues(
                _vm.actions[idx].action_name
              ),
              "show-action-input": _vm.showActionInput(
                _vm.actions[idx].action_name
              ),
              "initial-file-name": _vm.getFileName(_vm.actions[idx], _vm.files)
            },
            on: {
              resetAction: function($event) {
                return _vm.resetAction(idx)
              },
              removeAction: function($event) {
                return _vm.removeAction(idx)
              }
            },
            model: {
              value: _vm.actions[idx],
              callback: function($$v) {
                _vm.$set(_vm.actions, idx, $$v)
              },
              expression: "actions[idx]"
            }
          })
        }),
        _vm._v(" "),
        _vm.lastAction && !!_vm.lastAction.action_name
          ? _c(
              "div",
              { staticClass: "filter-actions" },
              [
                _c(
                  "woot-base-button",
                  {
                    attrs: { tag: "span", size: "small", variant: "secondary" },
                    on: { click: _vm.appendAction }
                  },
                  [
                    _c("icons", {
                      attrs: {
                        name: "plus",
                        color: "green",
                        size: "semimedium",
                        "show-title": false,
                        view: "0 0 20 20"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }