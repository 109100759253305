var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "ui-snackbar", class: _vm.variant }, [
      _c(
        "span",
        { staticClass: "icon", class: _vm.iconProps.color },
        [
          _c("icons", {
            attrs: { name: _vm.iconProps.name, color: "white", size: "medium" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "title-h4_m text-dark ui-snackbar-text" }, [
        _vm._v("\n      " + _vm._s(_vm.message) + "\n    ")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }