var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wizard-body columns content-box small-12" },
    [
      _c(
        "div",
        {
          staticClass: "login-init full-height text-center flex-column-center"
        },
        [
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.requestAuthorization($event)
                }
              }
            },
            [
              _c("woot-primary-button", {
                attrs: {
                  "front-icon": "twitter",
                  name: "Sign in with Twitter",
                  loading: _vm.isRequestingAuthorization
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.$t("INBOX_MGMT.ADD.TWITTER.HELP")))])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }