import { render, staticRenderFns } from "./EmployeeDetails.vue?vue&type=template&id=385121e5&scoped=true&"
import script from "./EmployeeDetails.vue?vue&type=script&lang=js&"
export * from "./EmployeeDetails.vue?vue&type=script&lang=js&"
import style0 from "./EmployeeDetails.vue?vue&type=style&index=0&id=385121e5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "385121e5",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/limechat/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('385121e5')) {
      api.createRecord('385121e5', component.options)
    } else {
      api.reload('385121e5', component.options)
    }
    module.hot.accept("./EmployeeDetails.vue?vue&type=template&id=385121e5&scoped=true&", function () {
      api.rerender('385121e5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/lime-ui/dashboard/routes/dashboard/settings/agents/add/EmployeeDetails.vue"
export default component.exports