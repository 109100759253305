<template>
  <drag-me
    v-show="showCallingPopup"
    :custom-style="{
      left: '40%',
      top: '25%',
    }"
    @dragging="handleDragging"
    @dropped="handleDropped"
  >
    <div
      class="dragged-container flex-column flex-align flex-justify--between"
      :class="{
        dragging: isActivated,
        'error-shadow': callStatesFlags.callFailure,
      }"
    >
      <div
        :style="`height: ${dynamicHeight}`"
        class="flex-column flex-justify--between"
      >
        <div class="container--top">
          <call-status :current-status="callStatus" />
          <!-- call timer -->
          <div
            v-show="
              eventName.CUSTOMER_ANSWER || eventName.BRIDGE || eventName.HANGUP
            "
            class="timer flex-column flex-justify flex-align"
          >
            <call-timer ref="callTimer" />
          </div>
        </div>
        <div class="container--mid">
          <div
            v-if="eventName.AGENT_CALL || !eventName"
            class="avatar-container flex-column flex-align"
          >
            <div
              v-if="eventName.AGENT_CALL || eventName.CUSTOMER_ANSWER"
              class="outbound-ringer"
            >
              <lottie :options="defaultOptions" :height="150" :width="150" />
            </div>
            <avatar
              v-if="recepientName"
              :username="recepientName"
              rounded
              :size="80"
              :colored="true"
              :custom-style="{
                'font-size': '2.4rem',
                'box-shadow': 'none',
                border: 'none',
              }"
            />
            <span v-else class="user-icon">
              <icons name="user" color="primary" size="largest" />
            </span>
          </div>
          <customer-connection
            v-else
            :event-name="eventName"
            :agent-name="agentName"
            :agent-role="agentRole"
            :customer-name="computedCustomerName"
          />
          <div class="customer-info flex-column flex-align">
            <span
              v-if="recepientName !== ''"
              class="text-align--center title-h1 text-dark"
            >
              {{
                recepientName ||
                  $t('INBOX_MGMT.ADD.VOICE_CHANNEL.CALLING_COMPONENT.NEW_USER')
              }}
            </span>
            <div v-if="!eventName" class="content-margin">
              <woot-input
                v-model.trim="customerNumber"
                type="Number"
                size="small"
                class="medium-12 columns phone-number-field"
                :class="{ error: $v.customerNumber.$error }"
                :error="
                  $t(
                    'INBOX_MGMT.ADD.VOICE_CHANNEL.CALLING_COMPONENT.PHONE.ERROR'
                  )
                "
                :has-error="$v.customerNumber.$error"
                :placeholder="
                  $t(
                    'INBOX_MGMT.ADD.VOICE_CHANNEL.CALLING_COMPONENT.PHONE.PLACEHOLDER'
                  )
                "
                @keyup.enter.native="placeCall"
                @blur="$v.customerNumber.$touch"
              />
            </div>
            <div
              v-else-if="callingMeta"
              style="gap: 0.4rem"
              class="flex-column flex-align"
            >
              <span
                v-for="(title, idx) in Object.keys(callingMeta)"
                :key="idx"
                class="body-b3 text-light"
              >
                {{ callingMeta[title] }}
              </span>
            </div>
          </div>
          <span v-if="!eventName" class="flex-column gap--normal">
            <span>
              <span class="text-light subtitle-s2">
                {{
                  $t(
                    'INBOX_MGMT.ADD.VOICE_CHANNEL.CALLING_COMPONENT.INBOX_DROPDOWN.LABEL'
                  )
                }}
              </span>
              <woot-single-selector
                size="small"
                :button-style="{
                  'border-radius': '0.4rem',
                }"
                :custom-style="selectorCustomStyle"
                :show-selected-text="false"
                :default-option="defaultSelectedInbox.name"
                :option-list="inboxList"
                @click="onSelectInbox"
              />
            </span>
            <span v-if="virtualNumbers">
              <span
                class="text-light subtitle-s2"
                v-text="
                  $t(
                    'INBOX_MGMT.ADD.VOICE_CHANNEL.CALLING_COMPONENT.VIRTUAL_NUMBER_DROPDOWN.LABEL'
                  )
                "
              />
              <woot-single-selector
                size="small"
                :button-style="{
                  'border-radius': '0.4rem',
                }"
                :custom-style="selectorCustomStyle"
                :show-selected-text="false"
                :default-option="selectedVirtualNumber"
                :option-list="virtualNumbers"
                @click="onSelectVirtualNumber"
              />
            </span>
          </span>
          <span v-if="eventName.BRIDGE || eventName.HANGUP">
            <span v-if="eventName.BRIDGE || notes" class="text-dark body-b3">
              {{
                $t('INBOX_MGMT.ADD.VOICE_CHANNEL.CALLING_COMPONENT.CALL_NOTES')
              }}
            </span>
            <woot-input
              v-if="eventName.BRIDGE"
              v-model.trim="notes"
              rows="3"
              type="text"
              :placeholder="'Jot down something...'"
            />
            <div
              v-else
              class="body-b2 text-light notes custom-scroll"
              v-text="notes"
            />
          </span>
        </div>
      </div>
      <div
        v-if="!eventName || eventName.HANGUP"
        class="container--bottom flex-column flex-align"
      >
        <div :class="{ shake: callStatesFlags.callFailure }">
          <woot-base-button
            size="small"
            front-icon="phone"
            icon-size="semimedium"
            class="call--btn"
            :disabled="$v.customerNumber.$error"
            :loading="callStatesFlags.initiatingCall"
            @click="placeCall"
          >
            {{ placeCallBtnLabel }}
          </woot-base-button>
        </div>
        <woot-base-button
          type="button"
          variant="tertiary-danger"
          size="small"
          class="call--btn"
          @click="cancelCall"
        >
          {{ $t('INBOX_MGMT.ADD.VOICE_CHANNEL.CALLING_COMPONENT.CANCEL') }}
        </woot-base-button>
      </div>
      <div v-if="eventName.BRIDGE" class="flex-column flex-align mt-1">
        <woot-primary-button
          v-if="
            isInboundCall && currentCallState.conversationId && eventName.BRIDGE
          "
          type="button"
          size="small"
          :name="
            $t('INBOX_MGMT.ADD.VOICE_CHANNEL.CALLING_COMPONENT.OPEN_TICKET')
          "
          front-icon="history"
          icon-size="semimedium"
          @click="openTicket"
        />
        <woot-tertiary-button
          v-if="eventName.BRIDGE"
          type="button"
          size="small"
          :name="$t('INBOX_MGMT.ADD.VOICE_CHANNEL.CALLING_COMPONENT.CANCEL')"
          custom-class="cancel--btn"
          @click="() => $store.dispatch('showCallPopup', false)"
        />
      </div>
    </div>
  </drag-me>
</template>
<script>
import { mapGetters } from 'vuex';
import Lottie from 'vue-lottie';

import { isValidPhoneNumber } from 'libphonenumber-js';
import { required } from 'vuelidate/lib/validators';

import CallStatus from './CallStatus';
import CallTimer from './CallTimer';
import CustomerConnection from './CustomerConnection';
import DragMe from 'dashboard/components/ui/DragMe.vue';
import Avatar from 'dashboard/components/widgets/Avatar.vue';
import animationData from 'dashboard/assets/lottie/outboundRinger.json';
import { CALL_DIRECTION } from 'dashboard/constants';

import router from '../../../routes';
import { frontendURL, conversationUrl } from '../../../helper/URLHelper';

import callingLifecycleMixin from './mixins/callingLifecycle';
import alertMixin from 'shared/mixins/alertMixin';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';

export default {
  components: {
    CallStatus,
    CallTimer,
    CustomerConnection,
    DragMe,
    Avatar,
    Lottie,
  },
  mixins: [callingLifecycleMixin, alertMixin, googleAnalyticsMixin],
  props: {
    inboxList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isActivated: false,
      defaultOptions: { animationData },
      callStatus: {
        message: this.$t(
          'INBOX_MGMT.ADD.VOICE_CHANNEL.CALLING_COMPONENT.CALL_STATUSES.DEFAULT'
        ),
        color: '#808975',
      },
      activeInbox: null,
      selectedInbox: null,
      selectedVirtualNumber: '',
      virtualNumbers: null,
      dynamicHeight: '90%',
      previousAvailabilityStatus: '',
      agentName: '',
      agentNumber: '',
      agentRole: '',
      customerName: '',
      customerNumber: '',
      notes: '',
      callDirection: CALL_DIRECTION,
    };
  },
  validations: {
    customerNumber: {
      required,
      checkValidPhoneNumber(value) {
        return isValidPhoneNumber('+' + value);
      },
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      accountId: 'getCurrentAccountId',
      callStatesFlags: 'getCallStatesUiFlags',
      currentCallState: 'getCurrentCallState',
      currentAvailabilityStatus: 'getCurrentUserAvailabilityStatus',
    }),
    selectorCustomStyle() {
      return {
        left: 0,
        top: 0,
        width: '240px',
      };
    },
    isInboundCall() {
      return (
        this.currentCallState.callDirection.toUpperCase() ===
        this.callDirection.INBOUND
      );
    },
    computedCustomerName() {
      return this.customerName || this.currentCallState.customerName || null;
    },
    computedCustomerNumber() {
      return this.customerNumber || this.currentCallState.customerNumber || '';
    },
    showCallingPopup() {
      const { showCallPopup, showCallNotification } = this.callStatesFlags;
      return showCallPopup && !showCallNotification;
    },
    eventName() {
      if (!this.currentCallState.eventName) return '';
      return { [this.currentCallState.eventName]: true };
    },
    recepientName() {
      if (this.eventName.AGENT_CALL) return this.agentName;
      if (this.eventName.AGENT_ANSWER || this.eventName.CUSTOMER_CALL)
        return this.computedCustomerName;
      if (
        this.eventName.CUSTOMER_ANSWER ||
        this.eventName.BRIDGE ||
        this.eventName.HANGUP
      )
        return '';
      return this.computedCustomerName;
    },
    callType() {
      return this.currentCallState.callType;
    },
    callingMeta() {
      if (this.eventName.AGENT_CALL)
        return {
          number: this.agentNumber,
          role: `(${this.agentRole})`,
          message: 'Dialing',
        };
      if (this.eventName.AGENT_ANSWER || this.eventName.CUSTOMER_CALL)
        return {
          number: this.computedCustomerNumber,
          role: '(customer)',
          message: 'Dialing',
        };
      return null;
    },
    defaultSelectedInbox() {
      const selectedInbox = this.selectedInbox ||
        this.inboxList[0] || { name: 'Select Option' };

      this.onSelectInbox(selectedInbox);
      return selectedInbox;
    },
    placeCallBtnLabel() {
      if (this.isInboundCall && this.eventName.HANGUP) return 'Call customer';
      return this.eventName.HANGUP ? 'Retry' : 'Call now';
    },
  },
  created() {
    bus.$on('initiateCallInstance', data => {
      if (this.currentCallState.eventName === 'HANGUP') {
        this.notes = '';
        this.$refs.callTimer.clearTimer();
        this.$store.dispatch('resetCallState');
      }

      this.$v.$reset();

      const {
        activeInbox,
        agentName,
        agentNumber,
        agentRole,
        customerName,
        customerNumber,
      } = data;

      this.activeInbox = activeInbox;
      this.agentName = agentName;
      this.agentNumber = agentNumber;
      this.agentRole = agentRole;
      this.customerName = customerName;
      this.customerNumber = customerNumber;
    });
  },
  methods: {
    placeCall() {
      this.$v.$touch();
      this.$refs.callTimer.clearTimer();
      this.notes = '';

      if (!this.computedCustomerNumber.trim()) return;
      if (!this.agentNumber) {
        this.showAlert(
          this.$t(
            'INBOX_MGMT.ADD.VOICE_CHANNEL.CALLING_COMPONENT.ERROR.AGENT_NUMBER_NOT_AVAILABLE'
          ),
          'error'
        );
        return;
      }

      const {
        id: channelId = '',
        knowlarityNumber,
        callerId,
        id: inboxId = '',
        provider = '',
      } = this.defaultSelectedInbox;

      const payload = {
        agentNumber: this.includePlus(this.agentNumber || ''),
        callerId,
        channelId,
        customerNumber: this.includePlus(this.computedCustomerNumber || ''),
        inboxId,
        knowlarityNumber,
        virtualNumber: this.includePlus(this.selectedVirtualNumber?.id || ''),
        provider,
      };

      this.$store.dispatch('initiateOutboundCall', payload).catch(() => {
        this.showAlert(
          this.$t(
            'INBOX_MGMT.ADD.VOICE_CHANNEL.CALLING_COMPONENT.ERROR.OUTBOUND_ERROR'
          ),
          'error'
        );
      });
    },
    changeAvailabilityStatus(availability) {
      this.previousAvailabilityStatus = this.currentAvailabilityStatus;
      this.logAvailibilityChange(availability);

      let account_id = this.accountId;
      this.$store.dispatch('updateAvailability', {
        availability,
        account_id,
      });
    },
    includePlus(phoneNumber) {
      return phoneNumber.includes('+') ? phoneNumber : '+' + phoneNumber;
    },
    cancelCall() {
      this.notes = '';
      this.$store.dispatch('showCallPopup', false);
      this.$store.dispatch('resetCallState');
    },
    openTicket() {
      const { activeInbox } = this;

      const path = conversationUrl({
        accountId: this.accountId,
        activeInbox,
        id: this.currentCallState.conversationId,
      });

      this.logConversationCardClick(path);
      router.push({ path: frontendURL(path) });
    },
    onSelectInbox(value) {
      this.selectedInbox = value;

      if (this.hasVirtualNumbers(this.selectedInbox)) {
        this.initializeVirtualNumbers(this.selectedInbox.virtual_numbers);
        return;
      }

      this.resetVirtualNumbers();
    },
    onSelectVirtualNumber(value) {
      this.selectedVirtualNumber = value;
    },
    handleDragging() {
      this.isActivated = true;
    },
    handleDropped() {
      this.isActivated = false;
    },
    initializeVirtualNumbers(numbers) {
      if (Array.isArray(numbers) && numbers.length > 0) {
        this.virtualNumbers = numbers.map(number => ({
          id: number,
          name: number,
        }));
        this.selectedVirtualNumber = this.virtualNumbers[0];
      } else {
        this.resetVirtualNumbers();
      }
    },
    hasVirtualNumbers(value) {
      return (
        Array.isArray(value?.virtual_numbers) &&
        value.virtual_numbers.length > 0
      );
    },
    resetVirtualNumbers() {
      this.virtualNumbers = null;
      this.selectedVirtualNumber = '';
    },
    isKnowlarityInbox(inbox) {
      return inbox.provider === 'knowlarity';
    },
  },
};
</script>
