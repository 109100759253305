var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: { "email-body": _vm.isEmail } }, [
    _c("span", { staticClass: "message-text__wrap" }, [
      _c("div", {
        staticClass: "message-span",
        class: {
          "message-email body-b2": _vm.isEmail,
          "show--quoted": _vm.showQuotedContent,
          "hide--quoted": !_vm.showQuotedContent
        },
        domProps: { innerHTML: _vm._s(_vm.sanitizeContent) }
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "quoted-text--button",
          on: { click: _vm.toggleQuotedContent }
        },
        [
          _vm.showQuotedContent
            ? _c("span", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("CHAT_LIST.HIDE_QUOTED_TEXT")) +
                    "\n      "
                )
              ])
            : _c("span", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("CHAT_LIST.SHOW_QUOTED_TEXT")) +
                    "\n      "
                )
              ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }