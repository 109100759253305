var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "message-text--metadata" },
    [
      _vm.isAnEmail && _vm.isPrivate
        ? _c("span", { staticClass: "time" }, [
            _vm._v(_vm._s(_vm.readableTime))
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.isAnEmail || _vm.isActivity
        ? [
            _c("span", { staticClass: "time" }, [
              _vm._v(_vm._s(_vm.readableTime))
            ]),
            _vm._v(" "),
            _vm.sentByMessage
              ? _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip.left",
                        value: _vm.details,
                        expression: "details",
                        modifiers: { left: true }
                      }
                    ],
                    staticClass: "tick"
                  },
                  [
                    _vm.isFailed
                      ? _c("icons", {
                          attrs: { name: "error", color: "warning" }
                        })
                      : _vm.isSent
                      ? _c("icons", {
                          attrs: {
                            name: "tick",
                            color: "grey",
                            view: "0 0 16 16"
                          }
                        })
                      : _vm.isDelivered
                      ? _c("icons", {
                          attrs: {
                            name: "tick",
                            color: "darkestgrey",
                            view: "0 0 16 16"
                          }
                        })
                      : _vm.isRead
                      ? _c("icons", {
                          attrs: {
                            name: "tick",
                            color: "tick",
                            view: "0 0 16 16"
                          }
                        })
                      : _c(
                          "div",
                          [
                            _c("spinner", {
                              attrs: { size: "small", color: "grey" }
                            })
                          ],
                          1
                        )
                  ],
                  1
                )
              : _vm._e()
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.isATweet && _vm.isIncoming
        ? _c("i", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip.top-start",
                value: _vm.$t("CHAT_LIST.REPLY_TO_TWEET"),
                expression: "$t('CHAT_LIST.REPLY_TO_TWEET')",
                modifiers: { "top-start": true }
              }
            ],
            staticClass: "icon ion-reply cursor-pointer",
            on: { click: _vm.onTweetReply }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isATweet && _vm.isIncoming
        ? _c(
            "a",
            {
              attrs: {
                href: _vm.linkToTweet,
                target: "_blank",
                rel: "noopener noreferrer nofollow"
              }
            },
            [
              _c("i", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip.top-start",
                    value: _vm.$t("CHAT_LIST.VIEW_TWEET_IN_TWITTER"),
                    expression: "$t('CHAT_LIST.VIEW_TWEET_IN_TWITTER')",
                    modifiers: { "top-start": true }
                  }
                ],
                staticClass: "icon ion-android-open cursor-pointer"
              })
            ]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }