var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-column gap--small" },
    [
      _c(
        "div",
        { staticClass: "summary-bubble" },
        [
          _c("summary-head"),
          _vm._v(" "),
          _c("summary-content", { attrs: { message: _vm.message } })
        ],
        1
      ),
      _vm._v(" "),
      _c("capture-feedback", {
        attrs: { "conversation-id": _vm.message.conversation_id }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }