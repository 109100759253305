var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-box settings-box" }, [
    _c("div", { staticClass: "settings-container gap--normal" }, [
      _c(
        "div",
        { staticClass: "custom-scroll lime-card-1dp" },
        [
          _c(
            "page-header",
            {
              staticClass: "page-header",
              attrs: { "header-title": _vm.integrationFields.TITLE },
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function() {
                    return [
                      _c("div", { staticClass: "crm-img-container" }, [
                        _c("div", { staticClass: "centered-image" }, [
                          _c("img", {
                            attrs: {
                              src:
                                "/dashboard/images/integrations/" +
                                _vm.integrationFields.THUMBNAIL
                            }
                          })
                        ])
                      ])
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _vm._v(" "),
              _c("reference-box", {
                attrs: {
                  to:
                    _vm.$t("INTEGRATION_SETTINGS.GITBOOK_URL") +
                    _vm.integrationFields.ACTION,
                  content:
                    "Know more about " +
                    _vm.integrationFields.TITLE +
                    " Integration",
                  type: "document"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "crm-integration flex-column" },
            [
              _vm.isLoading
                ? _c("woot-loading-state", {
                    attrs: { message: "Fetching integration details" }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { class: _vm.isLoading ? "disable-container" : "" }, [
                _c("div", { staticClass: "crm-container" }, [
                  _c("div", { staticClass: "crm-body-container" }, [
                    _c(
                      "form",
                      {
                        staticClass: "flex-column-center",
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.logisticsConnect($event)
                          }
                        }
                      },
                      [
                        _vm.integrationType === "logistics"
                          ? _c(
                              "page-header",
                              {
                                staticClass: "crm-input",
                                attrs: {
                                  "header-title": "Is Integration Active",
                                  size: "small"
                                }
                              },
                              [
                                _c("woot-switch", {
                                  attrs: { id: "active_switch" },
                                  on: { click: _vm.activationToggler },
                                  model: {
                                    value: _vm.model[_vm.formLength],
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, _vm.formLength, $$v)
                                    },
                                    expression: "model[formLength]"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(_vm.integrationFields.FORM, function(
                          data,
                          index
                        ) {
                          return _c("woot-input", {
                            key: index,
                            staticClass: "crm-input",
                            attrs: {
                              label: data.LABEL,
                              placeholder: data.PLACEHOLDER,
                              "has-error":
                                data.REQUIRED !== "false"
                                  ? _vm.$v.model.$each[index].$error
                                  : false,
                              error: "Required"
                            },
                            on: { input: _vm.$v.model.$each[index].$touch },
                            model: {
                              value: _vm.model[index],
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.model,
                                  index,
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "model[index]"
                            }
                          })
                        }),
                        _vm._v(" "),
                        _vm._t("more-inputs"),
                        _vm._v(" "),
                        _vm.showDefaultButton
                          ? _c(
                              "woot-button",
                              {
                                staticClass: "button nice crm-button",
                                attrs: {
                                  type: "submit",
                                  disabled: _vm.disableButton
                                }
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t(
                                        "INTEGRATION_SETTINGS.SAVE.BUTTON_TEXT"
                                      )
                                    ) +
                                    "\n                "
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      2
                    )
                  ])
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }