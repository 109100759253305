var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass:
        "assign-agent-section title-h5_m text-dark flex-row flex-align gap--small"
    },
    [
      _c("span", {
        domProps: {
          textContent: _vm._s(
            _vm.$t("CREATE_TICKET.FORM.SECTIONS.ASSIGN_AGENT.SUB_HEADING")
          )
        }
      }),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "agent-selector" },
        [
          _c("woot-single-selector", {
            attrs: {
              size: "small",
              "custom-style": {
                left: 0,
                maxHeight: "20rem",
                width: "20rem"
              },
              "button-style": {
                color: "#808975",
                padding: "0.4rem 0.6rem"
              },
              "show-selected-text": false,
              "default-option": _vm.selectedAgent.name,
              "option-list": _vm.modifiedAgentList
            },
            on: { click: _vm.onSelectAgent }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }