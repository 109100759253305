var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    {
      attrs: { show: _vm.show, "on-close": _vm.onClose, "small-modal": "" },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c("div", { staticClass: "column content-box" }, [
        _c("div", { staticClass: "column page-top-bar modal-header" }, [
          _c("span", {
            staticClass: "page-sub-title title-h2",
            domProps: { textContent: _vm._s(_vm.modalHeader) }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row settings-form" }, [
          _c("div", { staticClass: "time-selection-container" }, [
            _c("div", {
              staticClass: "text-light body-b2 mg-bottom--small",
              domProps: { textContent: _vm._s(_vm.$t("CHAT_LIST.SELECT_TIME")) }
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "medium-12 flex-row flex-wrap gap--slab mg-bottom--slab"
              },
              _vm._l(_vm.quickActions, function(item) {
                return _c(
                  "label",
                  {
                    key: item.NAME,
                    staticClass: "radio-container",
                    staticStyle: { cursor: "pointer" },
                    attrs: { for: item.NAME }
                  },
                  [
                    _c("span", {
                      staticClass: "body-b2 text-light",
                      domProps: { textContent: _vm._s(item.NAME) }
                    }),
                    _vm._v(" "),
                    _c("input", {
                      attrs: {
                        id: item.NAME,
                        type: "radio",
                        name: "quick-action"
                      },
                      domProps: {
                        value: _vm.alertDelay,
                        checked: _vm.isChecked(item)
                      },
                      on: {
                        change: function($event) {
                          return _vm.onChangeQuickAction(item.VALUE)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "radio-circle" })
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _vm.showTimePicker
              ? _c(
                  "div",
                  { staticClass: "timepicker" },
                  [
                    !_vm.isEmailChannel
                      ? _c("vue-timepicker", {
                          attrs: {
                            "hide-clear-button": "",
                            "minute-interval": 10,
                            format: "HH:mm"
                          },
                          model: {
                            value: _vm.alertDelay,
                            callback: function($$v) {
                              _vm.alertDelay = $$v
                            },
                            expression: "alertDelay"
                          }
                        })
                      : _c("date-picker", {
                          staticClass: "medium-6",
                          attrs: {
                            value: _vm.selectedDateTime,
                            "disable-previous-date": "",
                            "disabled-future-dates":
                              _vm.disableAfter3DaysFromCurrent,
                            type: "datetime",
                            variant: "small",
                            format: "YYYY-MM-DD HH:mm",
                            "show-range": false,
                            "show-second": false
                          },
                          on: { change: _vm.onSelectDateTime }
                        })
                  ],
                  1
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.showNextStatus
            ? _c(
                "div",
                { staticClass: "mg-top" },
                [
                  _c("div", {
                    staticClass: "body-b3 text-light mg-bottom--small",
                    domProps: {
                      textContent: _vm._s(_vm.$t("CHAT_LIST.STATUS_DROPDOWN"))
                    }
                  }),
                  _vm._v(" "),
                  _c("woot-single-selector", {
                    attrs: {
                      "option-list": _vm.statusActionList,
                      "default-option": _vm.selectedNextStatus,
                      "custom-style": {
                        left: 0,
                        top: 0,
                        width: "100%"
                      }
                    },
                    on: { click: _vm.changeNextStatus }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "flex-row mg-top--larger mg-bottom flex-align--start"
            },
            [
              _c("img", {
                staticClass: "mg-right--small",
                attrs: { src: require("assets/images/light-bulb.svg") }
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "body-b3 text-light",
                domProps: { textContent: _vm._s(_vm.modalContent) }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-footer" },
            [
              _c(
                "woot-base-button",
                {
                  attrs: { size: "small", disabled: _vm.isSubmitting },
                  on: { click: _vm.onSubmit }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("CHAT_LIST.SAVE")) +
                      "\n        "
                  )
                ]
              )
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }