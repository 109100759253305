var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "small-12 columns" },
    [
      _c("page-header", {
        attrs: {
          "header-title": _vm.$t("INBOX_MGMT.ADD.WHATSAPP_API.TITLE"),
          "header-content": _vm.$t("INBOX_MGMT.ADD.WHATSAPP_API.DESC")
        }
      }),
      _vm._v(" "),
      _vm.showWebhookDetails
        ? _c("div", { staticClass: "settings-sub-menu-content--larger" }, [
            _c(
              "div",
              { staticClass: "profile--settings--row mg-bottom" },
              [
                _c("woot-modal-header", {
                  attrs: {
                    "header-title": _vm.$t(
                      "INBOX_MGMT.ADD.WHATSAPP_API.WEBHOOK_DETAILS"
                    ),
                    "header-content": _vm.$t(
                      "INBOX_MGMT.ADD.WHATSAPP_API.WEBHOOK_URL"
                    )
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "columns medium-12" },
                  [_c("woot-code", { attrs: { script: _vm.webhookUrl } })],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "profile--settings--row mg-bottom" },
              [
                _c("woot-modal-header", {
                  attrs: {
                    "header-content": _vm.$t(
                      "INBOX_MGMT.ADD.WHATSAPP_API.WEBHOOK_TOKEN"
                    )
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "columns medium-12" },
                  [_c("woot-code", { attrs: { script: _vm.webhookToken } })],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mg-top flex-row flex-justify--end" },
              [
                _c("woot-primary-button", {
                  attrs: {
                    name: _vm.$t("INBOX_MGMT.ADD.WHATSAPP_API.NEXT_STEP")
                  },
                  on: { click: _vm.redirectToAddAgents }
                })
              ],
              1
            )
          ])
        : _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.createChannel($event)
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "settings-sub-menu-content--larger mg-bottom--jumbo"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex-row flex-justify--between content-margin full-width gap--two"
                    },
                    [
                      _c("woot-input", {
                        class: { error: _vm.$v.inboxName.$error },
                        attrs: {
                          required: "",
                          "auto-focus": "",
                          error: _vm.$t(
                            "INBOX_MGMT.ADD.WHATSAPP_API.INBOX_NAME.ERROR"
                          ),
                          label: _vm.$t(
                            "INBOX_MGMT.ADD.WHATSAPP_API.INBOX_NAME.LABEL"
                          ),
                          placeholder: _vm.$t(
                            "INBOX_MGMT.ADD.WHATSAPP_API.INBOX_NAME.PLACEHOLDER"
                          ),
                          "has-error": _vm.$v.inboxName.$error,
                          "full-width": ""
                        },
                        on: { blur: _vm.$v.inboxName.$touch },
                        model: {
                          value: _vm.inboxName,
                          callback: function($$v) {
                            _vm.inboxName =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "inboxName"
                        }
                      }),
                      _vm._v(" "),
                      _c("woot-input", {
                        class: { error: _vm.$v.phoneNumber.$error },
                        attrs: {
                          required: "",
                          "full-width": "",
                          error: _vm.$t(
                            "INBOX_MGMT.ADD.WHATSAPP_API.PHONE_NUMBER.ERROR"
                          ),
                          label: _vm.$t(
                            "INBOX_MGMT.ADD.WHATSAPP_API.PHONE_NUMBER.LABEL"
                          ),
                          placeholder: _vm.$t(
                            "INBOX_MGMT.ADD.WHATSAPP_API.PHONE_NUMBER.PLACEHOLDER"
                          ),
                          "has-error": _vm.$v.phoneNumber.$error
                        },
                        on: { blur: _vm.$v.phoneNumber.$touch },
                        model: {
                          value: _vm.phoneNumber,
                          callback: function($$v) {
                            _vm.phoneNumber =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "phoneNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("woot-input", {
                    staticClass: "medium-12 columns content-margin",
                    class: { error: _vm.$v.systemUserAdminAccessToken.$error },
                    attrs: {
                      required: "",
                      error:
                        _vm.systemUserAdminAccessToken === ""
                          ? _vm.$t("INBOX_MGMT.COMMON_ERROR_MESSAGE")
                          : _vm.$t(
                              "INBOX_MGMT.ADD.WHATSAPP_API.AUTH_TOKEN.ERROR"
                            ),
                      label: _vm.$t(
                        "INBOX_MGMT.ADD.WHATSAPP_API.AUTH_TOKEN.LABEL"
                      ),
                      placeholder: _vm.$t(
                        "INBOX_MGMT.ADD.WHATSAPP_API.AUTH_TOKEN.PLACEHOLDER"
                      ),
                      "has-error": _vm.$v.systemUserAdminAccessToken.$error
                    },
                    on: { blur: _vm.$v.systemUserAdminAccessToken.$touch },
                    model: {
                      value: _vm.systemUserAdminAccessToken,
                      callback: function($$v) {
                        _vm.systemUserAdminAccessToken =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "systemUserAdminAccessToken"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex-row flex-justify--between content-margin full-width gap--two"
                    },
                    [
                      _c("woot-input", {
                        class: { error: _vm.$v.accountName.$error },
                        attrs: {
                          required: "",
                          "full-width": "",
                          error: _vm.$t(
                            "INBOX_MGMT.ADD.WHATSAPP_API.ACCOUNT_NAME.ERROR"
                          ),
                          label: _vm.$t(
                            "INBOX_MGMT.ADD.WHATSAPP_API.ACCOUNT_NAME.LABEL"
                          ),
                          placeholder: _vm.$t(
                            "INBOX_MGMT.ADD.WHATSAPP_API.ACCOUNT_NAME.PLACEHOLDER"
                          ),
                          "has-error": _vm.$v.accountName.$error
                        },
                        on: { blur: _vm.$v.accountName.$touch },
                        model: {
                          value: _vm.accountName,
                          callback: function($$v) {
                            _vm.accountName =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "accountName"
                        }
                      }),
                      _vm._v(" "),
                      _c("woot-input", {
                        class: { error: _vm.$v.accountId.$error },
                        attrs: {
                          required: "",
                          "full-width": "",
                          error: _vm.$t(
                            "INBOX_MGMT.ADD.WHATSAPP_API.ACCOUNT_ID.ERROR"
                          ),
                          label: _vm.$t(
                            "INBOX_MGMT.ADD.WHATSAPP_API.ACCOUNT_ID.LABEL"
                          ),
                          placeholder: _vm.$t(
                            "INBOX_MGMT.ADD.WHATSAPP_API.ACCOUNT_ID.PLACEHOLDER"
                          ),
                          "has-error": _vm.$v.accountId.$error
                        },
                        on: { blur: _vm.$v.accountId.$touch },
                        model: {
                          value: _vm.accountId,
                          callback: function($$v) {
                            _vm.accountId =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "accountId"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("woot-input", {
                    staticClass: "medium-12 columns content-margin",
                    class: { error: _vm.$v.phoneNumberId.$error },
                    attrs: {
                      required: "",
                      error: _vm.$t(
                        "INBOX_MGMT.ADD.WHATSAPP_API.PHONE_NUMBER_ID.ERROR"
                      ),
                      label: _vm.$t(
                        "INBOX_MGMT.ADD.WHATSAPP_API.PHONE_NUMBER_ID.LABEL"
                      ),
                      placeholder: _vm.$t(
                        "INBOX_MGMT.ADD.WHATSAPP_API.PHONE_NUMBER_ID.PLACEHOLDER"
                      ),
                      "has-error": _vm.$v.phoneNumberId.$error
                    },
                    on: { blur: _vm.$v.phoneNumberId.$touch },
                    model: {
                      value: _vm.phoneNumberId,
                      callback: function($$v) {
                        _vm.phoneNumberId =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "phoneNumberId"
                    }
                  }),
                  _vm._v(" "),
                  _c("woot-input", {
                    staticClass: "medium-12 columns content-margin",
                    class: { error: _vm.$v.catalogId.$error },
                    attrs: {
                      error: _vm.$t(
                        "INBOX_MGMT.ADD.WHATSAPP_API.CATALOG_ID.ERROR"
                      ),
                      label: _vm.$t(
                        "INBOX_MGMT.ADD.WHATSAPP_API.CATALOG_ID.LABEL"
                      ),
                      placeholder: _vm.$t(
                        "INBOX_MGMT.ADD.WHATSAPP_API.CATALOG_ID.PLACEHOLDER"
                      ),
                      "has-error": _vm.$v.catalogId.$error
                    },
                    on: { blur: _vm.$v.catalogId.$touch },
                    model: {
                      value: _vm.catalogId,
                      callback: function($$v) {
                        _vm.catalogId =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "catalogId"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "medium-12 columns settings-sub-menu-bottom-nav"
                },
                [
                  _c(
                    "woot-base-button",
                    {
                      attrs: { variant: "secondary" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return (function() {
                            return _vm.$router.go(-1)
                          })($event)
                        }
                      }
                    },
                    [_vm._v("\n        Back\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "woot-base-button",
                    {
                      attrs: { loading: _vm.uiFlags.isCreating, type: "submit" }
                    },
                    [_vm._v("\n        Next\n      ")]
                  )
                ],
                1
              )
            ]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }