var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box settings-box no-padding" },
    [
      _c(
        "router-link",
        {
          attrs: { to: _vm.addAccountScoping("settings/inboxes/new") },
          nativeOn: {
            click: function($event) {
              return _vm.InlineButtonClickHandler($event)
            }
          }
        },
        [
          _vm.isAdmin
            ? _c(
                "woot-base-button",
                {
                  staticClass: "settings-button",
                  attrs: {
                    size: "medium",
                    "front-icon": "plus",
                    disabled:
                      _vm.inPendingPaymentForNonShopify && !_vm.isEnterprise,
                    "icon-view-box": "0 0 20 20"
                  }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("SETTINGS.INBOXES.NEW_INBOX")) +
                      "\n    "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "settings-container" },
        [
          _c("div", { staticClass: "column settings-main-menu-new" }, [
            _c(
              "div",
              { staticClass: "table-container" },
              [
                _c("data-table", {
                  attrs: {
                    actions: _vm.actionsList,
                    columns: _vm.tableColumns,
                    "table-data": _vm.inboxList,
                    loading: _vm.uiFlags.isFetching,
                    "enable-animations": _vm.inboxList.length <= 10,
                    _class: "scrollable-table custom-scroll"
                  },
                  on: {
                    edit: _vm.openInboxSettings,
                    select: _vm.viewInbox,
                    delete: _vm.openDelete
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "table-features",
                      fn: function() {
                        return [
                          _c("search-box", {
                            attrs: {
                              placeholder: _vm.$t("INBOX_MGMT.SEARCH_TXT"),
                              value: _vm.search,
                              "custom-class": "search-box"
                            },
                            on: { setSearch: _vm.setSearch }
                          }),
                          _vm._v(" "),
                          _vm.inboxes.length
                            ? _c("woot-single-selector", {
                                attrs: {
                                  disabled: false,
                                  variant: "secondary",
                                  size: "medium",
                                  "front-icon": _vm.inboxFilterIcon
                                    ? _vm.inboxFilterIcon
                                    : "inbox",
                                  "default-option": _vm.selectedInboxName,
                                  "option-list": _vm.inboxTypeList,
                                  "show-selected-text": false,
                                  "dropdown-container-style": _vm.filterStyles,
                                  "button-style": { "border-radius": "0.4rem" },
                                  "custom-style": { left: 0 }
                                },
                                on: { click: _vm.applyInboxFilter }
                              })
                            : _vm._e()
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "zero-state",
                      fn: function() {
                        return [
                          !_vm.inboxList.length
                            ? _c(
                                "table-zero-state",
                                {
                                  attrs: {
                                    title: "No Inboxes added till now",
                                    "asset-link":
                                      "/brand-assets/inbox_zero_state.svg"
                                  }
                                },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: _vm.addAccountScoping(
                                          "settings/inboxes/new"
                                        )
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.InlineButtonClickHandler(
                                            $event
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm.isAdmin
                                        ? _c(
                                            "woot-base-button",
                                            {
                                              staticStyle: {
                                                "margin-bottom": "5vh"
                                              },
                                              attrs: {
                                                size: "medium",
                                                "front-icon": "plus",
                                                "icon-view-box": "0 0 20 20"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "SETTINGS.INBOXES.NEW_INBOX"
                                                    )
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      },
                      proxy: true
                    }
                  ])
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "settings-side-card",
            [_c("inboxes-rhs-panel", { attrs: { inbox: _vm.selectedInbox } })],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("woot-delete-modal-new", {
        attrs: {
          show: _vm.showDeletePopup,
          "on-close": _vm.closeDelete,
          "on-confirm": _vm.confirmDeletion,
          "show-close": "",
          title: _vm.$t("INBOX_MGMT.DELETE.CONFIRM.TITLE"),
          message: _vm.$t("INBOX_MGMT.DELETE.CONFIRM.MESSAGE"),
          "confirm-text": _vm.$t("INBOX_MGMT.DELETE.CONFIRM.YES"),
          "reject-text": _vm.$t("INBOX_MGMT.DELETE.CONFIRM.NO"),
          "custom-style": {
            "max-width": "35.2rem",
            height: "auto"
          }
        },
        on: {
          "update:show": function($event) {
            _vm.showDeletePopup = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }