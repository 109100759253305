var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "medium-3 bg-white contact--panel columns" },
    [
      _c("tab-nav", {
        attrs: {
          active: _vm.activeTab || "contact",
          "tab-items": _vm.contactTabItems
        },
        on: { click: _vm.handleTabClick }
      }),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "menu-list", mode: "out-in" } },
        [_c("router-view")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }