var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    {
      attrs: { show: _vm.show, "on-close": _vm.onCancel },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "column content-box" },
        [
          _c("woot-modal-header", {
            attrs: {
              "header-title":
                "Profile - " + (_vm.contact.name || _vm.contact.email)
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "edit-contact--form" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "medium-9 columns" }, [
                _vm.name
                  ? _c("label", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("EDIT_CONTACT.FORM.NAME.LABEL")) +
                          "\n            "
                      ),
                      _c("span", [_vm._v(_vm._s(_vm.name))])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.email
                  ? _c("label", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t("EDIT_CONTACT.FORM.EMAIL_ADDRESS.LABEL")
                          ) +
                          "\n            "
                      ),
                      _c("span", [_vm._v(_vm._s(_vm.email))])
                    ])
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _vm.description
              ? _c("div", { staticClass: "medium-12 columns" }, [
                  _c("label", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("EDIT_CONTACT.FORM.BIO.LABEL")) +
                        "\n          "
                    ),
                    _c("span", [_vm._v(_vm._s(_vm.description))])
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.phoneNumber
              ? _c("div", { staticClass: "row" }, [
                  _c("span", { staticClass: "medium-6 columns" }, [
                    _vm._v(_vm._s(_vm.phoneNumber))
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.companyName
              ? _c("span", { staticClass: "medium-6 columns" }, [
                  _vm._v(_vm._s(_vm.companyName))
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "medium-12 columns" },
              [
                _c("label", [_vm._v("\n          Social Profiles\n        ")]),
                _vm._v(" "),
                _vm._l(_vm.socialProfileKeys, function(socialProfile) {
                  return _c(
                    "div",
                    { key: socialProfile.key, staticClass: "input-group" },
                    [
                      _c("span", { staticClass: "input-group-label" }, [
                        _vm._v(_vm._s(socialProfile.prefixURL))
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "input-group-field" }, [
                        _vm._v(
                          _vm._s(_vm.socialProfileUserNames[socialProfile.key])
                        )
                      ])
                    ]
                  )
                })
              ],
              2
            )
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }