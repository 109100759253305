var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "csat--table-container" }, [
    _vm.uiFlags.isFetching
      ? _c("div", [_c("table-skeleton")], 1)
      : _c(
          "div",
          { staticClass: "medium-12 bottom-container" },
          [
            _c("woot-table", {
              staticClass: "lime-card-1dp",
              attrs: {
                title: "Feedback Utterances",
                "header-list": _vm.tableHeader,
                "table-data": _vm.tableData,
                "is-sortable": true,
                "is-link": true
              }
            })
          ],
          1
        ),
    _vm._v(" "),
    _vm.metrics.totalResponseCount
      ? _c(
          "div",
          { staticClass: "table-pagination" },
          [
            _c("ve-pagination", {
              attrs: {
                total: _vm.metrics.totalResponseCount,
                "page-index": _vm.pageIndex,
                "page-size": 25,
                "page-size-option": [25]
              },
              on: { "on-page-number-change": _vm.onPageNumberChange }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }