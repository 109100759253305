import Vue from 'vue';
import * as types from '../../mutation-types';
import ConversationApi from '../../../api/inbox/conversation';
import MessageApi from '../../../api/inbox/message';
import { MESSAGE_STATUS, MESSAGE_TYPE } from 'shared/constants/messages';
import { createPendingMessage } from 'dashboard/helper/commons';

// actions
const actions = {
  createConversation: async ({ commit }, payload) => {
    commit(types.default.SET_CONVERSATION_UI_FLAGS, {
      isCreatingConversation: true,
    });
    try {
      await ConversationApi.createConversation(payload);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.default.SET_CONVERSATION_UI_FLAGS, {
        isCreatingConversation: false,
      });
    }
  },

  getConversation: async ({ commit }, conversationId) => {
    try {
      const response = await ConversationApi.show(conversationId);
      commit(
        `contacts/${types.default.SET_CONTACT_ITEM}`,
        response.data.meta.sender
      );
      // check if backend sends empty object, then don't set state
      if (response.data?.id) {
        commit(types.default.UPDATE_CONVERSATION, response.data);
        return response.data;
      }
    } catch (error) {
      throw new Error(error);
    }
    return null;
  },

  fetchConversations: async (_, params) => {
    try {
      const response = await ConversationApi.get(params);
      const { data } = response.data;
      return data;
    } catch (error) {
      // Handle error
      throw new Error(error);
    }
  },

  fetchAllConversations: async ({ commit, dispatch }, params) => {
    commit(types.default.SET_LIST_LOADING_STATUS);
    try {
      const { payload: chatList, meta: metaData } = await dispatch(
        'fetchConversations',
        params
      );
      commit(types.default.SET_ALL_CONVERSATION, chatList);
      commit(types.default.SET_ALL_TAB_CONVERSATION, chatList);
      dispatch('conversationStats/set', metaData);
      commit(
        `contacts/${types.default.SET_CONTACTS}`,
        chatList.map(chat => chat.meta.sender)
      );
      return chatList;
    } catch (error) {
      // Handle error
      return [];
    } finally {
      commit(types.default.CLEAR_LIST_LOADING_STATUS);
    }
  },

  setConversationFilters: ({ commit }, filters) => {
    commit(types.default.SET_CONVERSATION_UI_FLAGS, { isFilterActive: true });
    commit(types.default.SET_CONVERSATION_FILTERS, filters);
  },

  resetConversationFilters: ({ dispatch, commit }) => {
    commit(types.default.SET_CONVERSATION_UI_FLAGS, { isFilterActive: false });
    dispatch('setActiveInbox', null);
    commit(types.default.RESET_CONVERSATION_FILTERS);
  },

  updateConversationFilters: ({ commit }, { key, value }) => {
    commit(types.default.UPDATE_CONVERSATION_FILTERS, { key, value });
  },

  emptyAllConversations({ commit }) {
    commit(types.default.EMPTY_ALL_TAB_CONVERSATION);
  },

  clearSelectedState({ commit }) {
    commit(types.default.CLEAR_CURRENT_CHAT_WINDOW);
  },

  fetchPreviousMessages: async ({ commit }, data) => {
    try {
      const {
        data: { meta, payload },
      } = await MessageApi.getPreviousMessages(data);
      commit(
        `conversationMetadata/${types.default.SET_CONVERSATION_METADATA}`,
        {
          id: data.conversationId,
          data: meta,
        }
      );
      commit(types.default.SET_PREVIOUS_CONVERSATIONS, {
        id: data.conversationId,
        data: payload,
      });
      if (payload.length < 20) {
        commit(types.default.SET_ALL_MESSAGES_LOADED);
      }
    } catch (error) {
      // Handle error
    }
  },

  updatePendingMessages: async ({ commit }, data) => {
    try {
      const {
        data: { payload },
      } = await MessageApi.getPreviousMessages(data);
      commit(types.default.UPDATE_PENDING_MESSAGES, {
        id: data.conversationId,
        data: payload,
      });
    } catch (error) {
      // Handle error
    }
  },

  async setActiveChat({ commit, dispatch }, data) {
    commit(types.default.SET_CURRENT_CHAT_WINDOW, data);
    commit(types.default.CLEAR_ALL_MESSAGES_LOADED);

    if (data.dataFetched === undefined) {
      try {
        await dispatch('fetchPreviousMessages', {
          conversationId: data.id,
          before: data.messages[0].id,
        });
        Vue.set(data, 'dataFetched', true);
      } catch (error) {
        // Ignore error
      }
    }
  },

  assignAgent: async ({ commit, dispatch }, { conversationId, agentId }) => {
    try {
      const response = await ConversationApi.assignAgent({
        conversationId,
        agentId,
      });
      commit(types.default.ASSIGN_AGENT, response.data);
      dispatch('setCurrentChatAssignee', response.data);
    } catch (error) {
      // Handle error
      throw new Error(error);
    }
  },

  setCurrentChatAssignee({ commit }, assignee) {
    commit(types.default.ASSIGN_AGENT, assignee);
  },

  assignTeam: async ({ dispatch }, { conversationId, teamId }) => {
    try {
      const response = await ConversationApi.assignTeam({
        conversationId,
        teamId,
      });
      dispatch('setCurrentChatTeam', response.data);
    } catch (error) {
      // Handle error
      throw new Error(error);
    }
  },

  setCurrentChatTeam({ commit }, team) {
    commit(types.default.ASSIGN_TEAM, team);
  },

  toggleStatus: async ({ commit }, data) => {
    try {
      const response = await ConversationApi.toggleStatus(data);
      commit(
        types.default.RESOLVE_CONVERSATION,
        response.data.payload.current_status
      );
      return response;
    } catch (error) {
      throw new Error(error.response?.data?.error || error);
    }
  },

  sendMessage: async ({ commit, state, dispatch }, data) => {
    try {
      const pendingMessage = createPendingMessage(data);
      commit(types.default.ADD_MESSAGE, pendingMessage);
      commit(types.default.ADD_PENDING_MESSAGE, pendingMessage.echo_id);

      // wait 5 seconds for pending message to be sent otherwise fetch from BE
      setTimeout(() => {
        const { pendingMessages } = state;
        if (pendingMessages.includes(pendingMessage.echo_id)) {
          const { conversationId } = data;
          dispatch('updatePendingMessages', { conversationId });
        }
      }, 5000);

      const response = await MessageApi.create(pendingMessage);
      commit(types.default.ADD_MESSAGE, {
        ...response.data,
        status: MESSAGE_STATUS.SENT,
      });
    } catch (error) {
      // Handle error
    }
  },

  deleteMessage: async (_, data) => {
    try {
      await MessageApi.deleteMessage(data);
    } catch (error) {
      // Handle error
    }
  },

  addMessage({ commit }, message) {
    commit(types.default.ADD_MESSAGE, message);
    commit(types.default.SET_UNREAD_COUNT, message);
    commit(
      types.default.UPDATE_MESSAGE_TIMESTAMP_IN_ALL_TAB_CONVERSATION,
      message
    );
    commit(types.default.REMOVE_PENDING_MESSAGE, message.echo_id);
    if (message.message_type === MESSAGE_TYPE.INCOMING) {
      commit(types.default.SET_CONVERSATION_CAN_REPLY, {
        conversationId: message.conversation_id,
        canReply: true,
      });
    }
  },

  updateMessage({ commit }, message) {
    commit(types.default.ADD_MESSAGE, message);
  },

  addConversation({ commit, state, dispatch }, conversation) {
    const { currentInbox } = state;
    const {
      inbox_id: inboxId,
      meta: { sender },
    } = conversation;
    if (!currentInbox || Number(currentInbox) === inboxId) {
      commit(types.default.ADD_CONVERSATION, conversation);
      commit(types.default.ADD_CONVERSATION_TO_ALL_TAB, conversation);
      dispatch('contacts/setContact', sender);
    }
  },

  updateConversation({ commit, dispatch }, conversation) {
    const {
      meta: { sender },
    } = conversation;
    commit(types.default.UPDATE_CONVERSATION, conversation);
    commit(types.default.UPDATE_ALL_TAB_CONVERSATION, conversation);
    dispatch('contacts/setContact', sender);
  },

  updateConversationProperty({ commit }, payload) {
    commit(types.default.UPDATE_ALL_CONVERSATION_PROPERTY, payload);
  },

  updateAllTabConversationProperty({ commit }, payload) {
    commit(types.default.UPDATE_ALL_TAB_CONVERSATION_PROPERTY, payload);
  },

  markMessagesRead: async ({ commit }, data) => {
    try {
      const {
        data: { id, agent_last_seen_at: lastSeen, unread_count: unreadCount },
      } = await ConversationApi.markMessageRead(data);
      commit(types.default.MARK_MESSAGE_READ, {
        id,
        lastSeen,
      });
      commit(types.default.SET_UNREAD_COUNT, {
        conversation_id: id,
        unread_message_count: unreadCount,
      });
    } catch (error) {
      // Handle error
    }
  },

  setChatStatus({ commit }, data) {
    commit(types.default.CHANGE_CHAT_STATUS, data);
  },

  updateAssignee({ commit }, data) {
    commit(types.default.UPDATE_ASSIGNEE, data);
  },

  updateConversationContact({ commit }, data) {
    if (data.id) {
      commit(`contacts/${types.default.SET_CONTACT_ITEM}`, data);
    }
    commit(types.default.UPDATE_CONVERSATION_CONTACT, data);
  },

  setActiveInbox({ commit }, inboxId) {
    commit(types.default.SET_ACTIVE_INBOX, inboxId);
  },

  sendAttachment: async ({ commit }, data) => {
    try {
      const response = await MessageApi.sendAttachment(data);
      commit(types.default.ADD_MESSAGE, response.data);
    } catch (error) {
      // Handle error
    }
  },

  muteConversation: async ({ commit }, conversationId) => {
    try {
      await ConversationApi.mute(conversationId);
      commit(types.default.MUTE_CONVERSATION);
    } catch (error) {
      //
    }
  },

  unmuteConversation: async ({ commit }, conversationId) => {
    try {
      await ConversationApi.unmute(conversationId);
      commit(types.default.UNMUTE_CONVERSATION);
    } catch (error) {
      //
    }
  },

  updateConversationNewLabel: async ({ commit }, conversation) => {
    commit(types.default.UPDATE_CONVERSATION_NEW_LABEL, conversation);
  },

  starConversation: async ({ commit }, conversationId) => {
    try {
      const response = await ConversationApi.toggleStarred(conversationId);
      commit(types.default.STAR_CONVERSATION, response.data.payload);
    } catch (error) {
      //
    }
  },

  sendEmailTranscript: async (_, { conversationId, email }) => {
    try {
      await ConversationApi.sendEmailTranscript({ conversationId, email });
    } catch (error) {
      throw new Error(error);
    }
  },

  bulkConversationUpdate: async ({ commit }, payload) => {
    try {
      const response = await ConversationApi.bulkUpdate(payload);
      // status is being skipped here as status might not change depending on validations
      let conversations = payload.conversationIds.map(item => {
        return {
          conversation_id: item,
          labels: payload.labels,
          starred: payload.starred,
          assignee: payload.agentId,
          team: payload.team_id,
        };
      });

      if (typeof payload.agentId === 'number') {
        const data = { conversations, assignee: response.data };
        commit(types.default.BULK_ASSIGN_AGENT, data);
      }
      if (typeof payload.team_id === 'number') {
        const data = { conversations, team: payload.team_id };
        commit(types.default.BULK_ASSIGN_TEAM, data);
      }
      if (payload.labels.length) {
        commit(types.default.SET_BULK_CONVERSATION_LABELS, conversations);
      }
      if (payload.starred) {
        commit(types.default.BULK_STAR_CONVERSATION, conversations);
      }
    } catch (error) {
      // handle error
    }
  },
};

export default actions;
