var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "notification--table-wrap custom-scroll" },
    [
      _vm.notificationMetadata.unreadCount
        ? _c(
            "woot-base-button",
            {
              staticClass: "mg-bottom--one",
              attrs: {
                loading: _vm.isUpdating,
                "front-icon": "tickRound",
                "icon-size": "medium",
                "preserve-case": ""
              },
              on: { click: _vm.onMarkAllAsRead }
            },
            [
              _vm._v(
                "\n    " +
                  _vm._s(_vm.$t("NOTIFICATIONS_PAGE.MARK_ALL_AS_READ")) +
                  "\n  "
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("table", { staticClass: "woot-table notifications-table" }, [
        _c(
          "tbody",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isLoading,
                expression: "!isLoading"
              }
            ]
          },
          _vm._l(_vm.notifications, function(notificationItem) {
            return _c(
              "tr",
              {
                key: notificationItem.id,
                on: {
                  click: function() {
                    return _vm.onClickNotification(notificationItem)
                  }
                }
              },
              [
                _c("td", [
                  !notificationItem.read_at
                    ? _c("div", {
                        staticClass: "notification--unread-indicator"
                      })
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("h5", {
                    staticClass: "notification--title title-h5 text-dark",
                    domProps: {
                      textContent: _vm._s(
                        "#" +
                          (notificationItem.primary_actor
                            ? notificationItem.primary_actor.id
                            : "deleted")
                      )
                    }
                  }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass:
                      "notification--message-title body-b3 text-light line-clamp-2",
                    domProps: {
                      textContent: _vm._s(notificationItem.push_message_title)
                    }
                  })
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "text-align" }, [
                  _c("span", {
                    staticClass: "notification--type text-dark title-h5_m",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "NOTIFICATIONS_PAGE.TYPE_LABEL." +
                            notificationItem.notification_type
                        )
                      )
                    }
                  })
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "thumbnail--column" },
                  [
                    notificationItem.primary_actor.meta.assignee
                      ? _c("thumbnail", {
                          attrs: {
                            src:
                              notificationItem.primary_actor.meta.assignee
                                .thumbnail,
                            size: "36px",
                            username:
                              notificationItem.primary_actor.meta.assignee.name
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c("td", [
                  _c("div", { staticClass: "text-align timestamp--column" }, [
                    _c("span", {
                      staticClass:
                        "title-h5_m notification--created-at text-light",
                      domProps: {
                        textContent: _vm._s(
                          _vm.dynamicTime(notificationItem.created_at)
                        )
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    !notificationItem.read_at
                      ? _c("mark-as-read-button", {
                          attrs: { notification: notificationItem }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]
            )
          }),
          0
        )
      ]),
      _vm._v(" "),
      _vm.showEmptyResult
        ? _c("empty-state", {
            attrs: { title: _vm.$t("NOTIFICATIONS_PAGE.LIST.404") }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "notifications--loader title-h4" },
            [
              _c("spinner"),
              _vm._v(" "),
              _c("span", {
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("NOTIFICATIONS_PAGE.LIST.LOADING_MESSAGE")
                  )
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }