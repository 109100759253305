var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    {
      attrs: {
        show: _vm.showModal,
        "on-close":
          this.$t("RELEASE.NEW_FEATURES").length === 1
            ? _vm.hideModal
            : function() {},
        "show-close": this.$t("RELEASE.NEW_FEATURES").length === 1,
        "small-width": true
      },
      on: {
        "update:show": function($event) {
          _vm.showModal = $event
        }
      }
    },
    [
      _c("div", { staticClass: "feature-intro-modal" }, [
        _c("p", { staticClass: "modal-heading" }, [
          _vm._v("\n      What's new in LimeChat Helpdesk?\n    ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "media-container" }, [
          this.$t("RELEASE.NEW_FEATURES")[_vm.currentIndex].image
            ? _c("img", {
                attrs: {
                  src: this.$t("RELEASE.NEW_FEATURES")[_vm.currentIndex].image
                }
              })
            : _vm.$t("RELEASE.NEW_FEATURES")[_vm.currentIndex].video
            ? _c(
                "video",
                {
                  key: _vm.$t("RELEASE.NEW_FEATURES")[_vm.currentIndex].video,
                  attrs: { preload: "true", autoPlay: "", muted: "", loop: "" },
                  domProps: { muted: true }
                },
                [
                  _c("source", {
                    attrs: {
                      src: _vm.$t("RELEASE.NEW_FEATURES")[_vm.currentIndex]
                        .video,
                      type: "video/mp4"
                    }
                  })
                ]
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "feature-container" }, [
          _c("p", { staticClass: "feature-heading" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  this.$t("RELEASE.NEW_FEATURES")[_vm.currentIndex].title
                ) +
                "\n      "
            )
          ]),
          _vm._v(" "),
          _c("div", {
            domProps: {
              innerHTML: _vm._s(
                this.$t("RELEASE.NEW_FEATURES")[_vm.currentIndex].description
              )
            }
          })
        ]),
        _vm._v(" "),
        this.$t("RELEASE.NEW_FEATURES").length > 1
          ? _c("div", { staticClass: "modal-navigation flex-space-between" }, [
              _c(
                "div",
                { staticClass: "button-container flex-space-between" },
                [
                  _vm.currentIndex !== 0
                    ? _c("woot-secondary-button", {
                        attrs: { size: "small", name: "BACK" },
                        on: { click: _vm.prevSlide }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.currentIndex ===
                  this.$t("RELEASE.NEW_FEATURES").length - 1
                    ? _c("woot-primary-button", {
                        attrs: {
                          size: "small",
                          "custom-class": "primary-button",
                          name: "GOT IT!"
                        },
                        on: { click: _vm.hideModal }
                      })
                    : _c("woot-primary-button", {
                        attrs: {
                          size: "small",
                          "custom-class": "primary-button",
                          name: "NEXT"
                        },
                        on: { click: _vm.nextSlide }
                      })
                ],
                1
              )
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }