var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["date-picker", "date-picker--" + _vm.variant] },
    [
      _c("date-picker", {
        attrs: {
          type: _vm.type,
          range: _vm.showRange,
          "show-hour": _vm.showHour,
          "show-minute": _vm.showMinute,
          "show-second": _vm.showSecond,
          clearable: false,
          format: _vm.format,
          editable: false,
          "input-class": [
            "date-picker-input",
            "date-picker-input--" + _vm.variant
          ],
          "confirm-text": _vm.confirmText,
          placeholder: _vm.placeholder,
          value: _vm.value,
          "disabled-date": function(date, currentValue) {
            return _vm.disabledDates(date, currentValue)
          }
        },
        on: { change: _vm.handleChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }