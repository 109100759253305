var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column content-box settings-box " }, [
    _c(
      "div",
      { staticClass: "flex-space-between" },
      [
        _c("search-box", {
          attrs: {
            placeholder: _vm.$t("PRODUCTS_MGMT.SEARCH_TXT"),
            value: _vm.search,
            "on-search": _vm.searchProducts
          },
          on: { setSearch: _vm.setSearch }
        }),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "view-button lime-card-1dp",
            on: { click: _vm.toggleView }
          },
          [
            _c("icons", {
              attrs: {
                name: _vm.isGridView ? "list" : "grid",
                size: "semimedium",
                color: "green"
              }
            })
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "settings-container lime-card-1dp" },
      [
        !_vm.uiFlags.fetchingProducts && _vm.productsList.length
          ? _c(
              "div",
              { staticClass: "row custom-scroll " },
              _vm._l(_vm.productsList, function(item) {
                return _c(
                  "article",
                  {
                    key: item.id,
                    class: _vm.itemClass,
                    on: {
                      click: function($event) {
                        return _vm.openCard(item)
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        class: {
                          card__media: true,
                          "lime-card-light": !_vm.isGridView
                        }
                      },
                      [
                        _c("img", { attrs: { src: _vm.setImage(item) } }),
                        _vm._v(" "),
                        item.sale_price < item.price && _vm.isGridView
                          ? _c("div", { staticClass: "card__discount" }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    Math.floor(
                                      100 - (100 * item.sale_price) / item.price
                                    ) + "%"
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("span", [_vm._v("OFF")])
                            ])
                          : _vm._e()
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "card__content" }, [
                      _c(
                        "header",
                        { staticClass: "card__header text-truncate" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.name) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      item.description !== null
                        ? _c("p", {
                            staticClass: "card__excerpt",
                            domProps: {
                              innerHTML: _vm._s(
                                item.description.replace(/(<([^>]+)>)/gi, "")
                              )
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "footer",
                        {
                          staticClass: "card__meta flex-space-between",
                          attrs: { role: "contentinfo" }
                        },
                        [
                          _c("div", { staticClass: "card__price" }, [
                            item.sale_price < item.price
                              ? _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.accountCurrency +
                                        " " +
                                        _vm.getPrice(item.sale_price)
                                    )
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("span", {
                              class: {
                                "sale-price": item.sale_price < item.price
                              },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.accountCurrency +
                                    " " +
                                    _vm.getPrice(item.price)
                                )
                              }
                            }),
                            _vm._v(" "),
                            !_vm.isGridView && item.sale_price < item.price
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "price__discount flex-row-justify-center"
                                  },
                                  [
                                    _c("icons", {
                                      attrs: {
                                        name: "discount",
                                        color: "primary",
                                        size: "semimedium"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          "Buy this at " +
                                            Math.floor(
                                              100 -
                                                (100 * item.sale_price) /
                                                  item.price
                                            ) +
                                            "% OFF discount"
                                        )
                                      )
                                    ])
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _vm.isGridView
                            ? _c(
                                "button",
                                { staticClass: "button clear see-more" },
                                [
                                  _vm._v(
                                    "\n              See More\n            "
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    ])
                  ]
                )
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.uiFlags.fetchingProducts && !_vm.productsList.length
          ? _c("p", { staticClass: "no-items-error-message" }, [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("PRODUCTS_MGMT.LIST.404")) + "\n    "
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.uiFlags.fetchingProducts
          ? _c("woot-loading-state", {
              attrs: { message: _vm.$t("PRODUCTS_MGMT.LIST.LOADING") }
            })
          : _vm._e(),
        _vm._v(" "),
        _c("pagination", {
          attrs: {
            "on-page-change": _vm.onPageChange,
            "current-page": _vm.currentPage,
            "page-size": 8,
            "total-count": _vm.meta.count
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }