var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bubble" },
    [
      _vm.withHeader
        ? _c(
            "div",
            {
              staticClass:
                "reply-header flex-row flex-align flex-justify--between gap--six"
            },
            [
              _c(
                "div",
                { staticClass: "flex-row flex-align gap--six" },
                [
                  _c("thumbnail", {
                    attrs: {
                      username: _vm.replierName,
                      "bg-color": "#6bac1b",
                      color: "#fff",
                      size: "20px"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "title-h6 text-dark",
                    staticStyle: { "white-space": "nowrap" },
                    domProps: { textContent: _vm._s(_vm.replierName) }
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "body-b3 text-light line-clamp-1" },
                    [_vm._v("replied")]
                  ),
                  _vm._v(" "),
                  !_vm.isIncoming && _vm.recipientName
                    ? _c(
                        "span",
                        { staticClass: "body-b3 text-light line-clamp-1" },
                        [
                          _vm._v(
                            "\n        to " +
                              _vm._s(_vm.recipientName) +
                              "'s comment\n      "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _vm.actionsList.length
                ? _c("woot-single-selector", {
                    attrs: {
                      variant: "tertiary",
                      "custom-style": {
                        width: "auto",
                        left: "auto",
                        right: _vm.isIncoming ? "auto" : "0.8rem",
                        borderRadius: "0.8rem"
                      },
                      "option-list": _vm.actionsList,
                      "icon-size-prop": "semimedium",
                      icon: "threeDot",
                      "show-selected-text": false,
                      "show-option-name": false
                    },
                    on: {
                      click: function(value) {
                        return _vm.$emit("onActionSelected", value)
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "reply-container mg-bottom--small",
          class: _vm.theme,
          on: { click: _vm.onClickReplyMessage }
        },
        [
          _c("span", { staticClass: "title-h5 reply-text" }, [_vm._v("Reply")]),
          _vm._v(" "),
          _c("span", {
            staticClass: "text-light body-b3 line-clamp-3",
            domProps: { textContent: _vm._s(_vm.parentMessageContent) }
          })
        ]
      ),
      _vm._v(" "),
      _vm.attachment
        ? [
            _c(
              _vm.attachment.file_type === "image"
                ? "bubble-image"
                : "bubble-file",
              {
                tag: "component",
                attrs: {
                  url: _vm.attachment.data_url,
                  "has-attached-content": !!_vm.content,
                  message: _vm.content
                }
              }
            )
          ]
        : _c("span", {
            staticClass: "message-span",
            domProps: { innerHTML: _vm._s(_vm.formatMessage(_vm.content)) }
          }),
      _vm._v(" "),
      _vm.showClose
        ? _c(
            "woot-base-button",
            {
              staticClass: "close-icon",
              attrs: { tag: "span", variant: "tertiary-danger" },
              on: {
                click: function($event) {
                  return _vm.$emit("onClose")
                }
              }
            },
            [
              _c("icons", {
                attrs: {
                  name: "closeRound",
                  color: "red",
                  size: "normal",
                  "show-title": false
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }