var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    {
      attrs: { show: _vm.showPopup, "show-close": false, "medium-width": true },
      on: {
        "update:show": function($event) {
          _vm.showPopup = $event
        }
      }
    },
    [
      _c("div", { staticClass: "flex-row-justify-center" }, [
        _c("div", { staticClass: "feature-toggle" }, [
          _c("img", {
            staticClass: "logo",
            attrs: { src: "/brand-assets/logo.svg" }
          }),
          _vm._v(" "),
          _c(
            "ul",
            _vm._l(_vm.contentToShow, function(item) {
              return _c(
                "li",
                {
                  key: item.ID,
                  class:
                    item.FEATURE ===
                      _vm.contentToShow[_vm.currentIndex].FEATURE &&
                    _vm.activeItemClassname,
                  on: {
                    click: function($event) {
                      return _vm.navigateToFeature(item.FEATURE)
                    }
                  }
                },
                [_vm._v("\n          " + _vm._s(item.FEATURE) + "\n        ")]
              )
            }),
            0
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "feature-preview flex-space-between-col" }, [
          _c(
            "div",
            { staticClass: "content-container flex-space-between-col" },
            [
              _c("div", { staticClass: "media-container" }, [
                _vm.currentIndex === 0
                  ? _c("img", {
                      attrs: { src: "/brand-assets/onboarding.svg" }
                    })
                  : _c(
                      "video",
                      {
                        key: _vm.source,
                        attrs: {
                          preload: "true",
                          autoPlay: "",
                          muted: "",
                          loop: "",
                          width: "724",
                          height: "377"
                        },
                        domProps: { muted: true }
                      },
                      [
                        _c("source", {
                          attrs: { src: _vm.source, type: "video/mp4" }
                        })
                      ]
                    )
              ]),
              _vm._v(" "),
              _vm.currentIndex === 0
                ? _c("h2", { staticClass: "header-title" }, [
                    _vm._v(
                      "\n          Let's make LimeChat work for " +
                        _vm._s(_vm.companyName) +
                        "\n        "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("p", { staticClass: "typography" }, [
                _vm._v("\n          " + _vm._s(_vm.content) + "\n        ")
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "button-container" },
            [
              [
                _vm.currentIndex !== 0
                  ? _c(
                      "button",
                      {
                        staticClass: "button hollow",
                        staticStyle: { width: "12rem" },
                        on: { click: _vm.prevSlide }
                      },
                      [_vm._v("\n            BACK\n          ")]
                    )
                  : _vm._e()
              ],
              _vm._v(" "),
              _vm.currentIndex === _vm.contentToShow.length - 1
                ? [
                    _c(
                      "woot-button",
                      {
                        staticClass: "button-width",
                        attrs: { id: "intercom_widget_open" },
                        on: { click: _vm.closeModal }
                      },
                      [_vm._v("\n            FINISH\n          ")]
                    )
                  ]
                : [
                    _c(
                      "woot-button",
                      {
                        staticClass: "button-width",
                        class: _vm.nextSlideButtonClassname,
                        on: { click: _vm.nextSlide }
                      },
                      [
                        _c("span", [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.currentIndex === 0
                                  ? "LET'S EXPLORE"
                                  : "NEXT"
                              ) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "icon-rotate" },
                          [
                            _vm.currentIndex === 0
                              ? _c("icons", {
                                  attrs: {
                                    name: "arrow",
                                    color: "white",
                                    size: "semimedium"
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    )
                  ]
            ],
            2
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }