var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "status" },
        [
          _c("icons", {
            attrs: {
              name: "danger",
              color: "warningyellow200",
              size: "mediumlarge"
            },
            on: { click: _vm.showEmailPopup }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.showEmailVerifyModal
        ? _c("email-verify-modal", {
            attrs: {
              show: _vm.showEmailVerifyModal,
              title: _vm.$t("REGISTER.CONFIRMATION.TITLE"),
              description: _vm.$t("REGISTER.CONFIRMATION.DESCRIPTION", {
                email: _vm.currentUser.email
              }),
              "on-close": _vm.hideEmailPopup,
              email: _vm.currentUser.email
            },
            on: {
              "update:show": function($event) {
                _vm.showEmailVerifyModal = $event
              },
              resend: _vm.resendEmailConfirmation
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }