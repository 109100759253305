var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Header", {
    staticClass: "mg-bottom--slab CUSTOM_FIELD_HEADER",
    attrs: {
      name: _vm.customFieldName,
      description: _vm.customFieldDesc,
      "name-placeholder": _vm.$t("CUSTOM_TICKET_FIELDS.ADD.FORM.HEADER.NAME"),
      "description-placeholder": _vm.$t(
        "CUSTOM_TICKET_FIELDS.ADD.FORM.HEADER.DESC"
      ),
      disabled: _vm.editView,
      "on-click": _vm.showDetailsModal,
      "on-back": function() {
        return _vm.$router.push({ name: "settings_custom_ticket_fields" })
      }
    },
    scopedSlots: _vm._u([
      {
        key: "right-section",
        fn: function() {
          return [
            _c(
              "div",
              {
                staticClass: "status-indicator flex-row flex-align gap--small",
                class: { enableStatus: _vm.enableStatus }
              },
              [
                _c(
                  "span",
                  { staticClass: "title-h5_m text-dark mg-left--slab" },
                  [_vm._v("\n        " + _vm._s(_vm.enableText) + "\n      ")]
                ),
                _vm._v(" "),
                _c("custom-field-status-toggle", {
                  staticStyle: { margin: "1.2rem 0" },
                  attrs: { enabled: _vm.enableStatus },
                  on: {
                    toggleFieldStatus: function($event) {
                      return _vm.$emit("toggleFieldStatus", $event)
                    }
                  }
                })
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }