var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex-row flex-align gap--small" }, [
    _c("label", { staticClass: "radio-container", attrs: { for: _vm.label } }, [
      _c("span", {
        staticClass: "title-h4 text-dark",
        domProps: { textContent: _vm._s(_vm.label) }
      }),
      _vm._v(" "),
      _c("input", {
        attrs: { id: _vm.label, type: "radio" },
        domProps: { value: _vm.isActive, checked: _vm.isActive },
        on: {
          input: function($event) {
            return _vm.$emit("toggle")
          }
        }
      }),
      _vm._v(" "),
      _c("span", { staticClass: "radio-circle" })
    ]),
    _vm._v(" "),
    _vm.infoText
      ? _c(
          "span",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.infoText,
                expression: "infoText"
              }
            ]
          },
          [
            _c("icons", {
              attrs: { name: "info", size: "small", color: "darkgrey" }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }