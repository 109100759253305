var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    {
      staticClass: "vertical dropdown menu canned",
      style: { top: _vm.getTopPadding() + "rem" },
      attrs: { id: "canned-list" }
    },
    [
      !_vm.cannedMessages.length
        ? _c("li", [
            _c("a", { staticClass: "text-truncate" }, [
              _vm._v(
                "\n      " +
                  _vm._s("No such Canned Messages found for this inbox.") +
                  "\n    "
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.cannedMessages, function(item, index) {
        return _c(
          "li",
          {
            key: item.short_code,
            class: { active: index === _vm.selectedIndex },
            attrs: { id: "canned-" + index },
            on: {
              click: function($event) {
                return _vm.onListItemSelection(index)
              },
              mouseover: function($event) {
                return _vm.onHover(index)
              }
            }
          },
          [
            _c(
              "strong",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: item.short_code,
                    expression: "item.short_code"
                  }
                ]
              },
              [_vm._v(_vm._s(item.short_code))]
            ),
            _vm._v(" "),
            _c("a", { staticClass: "text-truncate" }, [
              _vm._v("\n      " + _vm._s(item.content) + "\n    ")
            ])
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }