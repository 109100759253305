var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    {
      attrs: {
        show: _vm.opened,
        "custom-style": { width: "500px" },
        "on-close": _vm.onClose
      },
      on: {
        "update:show": function($event) {
          _vm.opened = $event
        }
      }
    },
    [
      _c("div", { staticClass: "modal-body" }, [
        _c(
          "div",
          { staticClass: "flex-row flex-align gap--small" },
          [
            _c("span", {
              staticClass: "title-h3 text-blue",
              domProps: {
                textContent: _vm._s(_vm.$t("AUTOMATION.FORM.TEST_MODE.TITLE"))
              }
            }),
            _vm._v(" "),
            _c("woot-switch", {
              attrs: { id: "toggle-test-mode", value: _vm.isTestModeActive },
              on: {
                click: function($event) {
                  return _vm.toggleTestMode(!_vm.isTestModeActive)
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", {
          staticClass: "body-b2 text-light mg-bottom--slab",
          domProps: {
            textContent: _vm._s(_vm.$t("AUTOMATION.FORM.TEST_MODE.DESC"))
          }
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "test-with-phone-numbers" },
          [
            _c("add-contact", {
              attrs: { "selected-contacts": _vm.selectedContacts },
              on: { updateContacts: _vm.onUpdatePhoneNumbers },
              scopedSlots: _vm._u([
                {
                  key: "selected-contacts-display-panel",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        { staticClass: "flex-row flex-wrap" },
                        _vm._l(_vm.selectedContacts, function(ref, idx) {
                          var phone_number = ref.phone_number
                          return _c("woot-chips", {
                            key: idx,
                            attrs: {
                              "show-cancel-icon": "",
                              "show-tooltip": false,
                              variant: "primary-small",
                              title: phone_number
                            },
                            on: {
                              click: function($event) {
                                return _vm.removeContact($event)
                              }
                            }
                          })
                        }),
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }