var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "columns content-box settings-box" },
    [
      _c(
        "woot-base-button",
        {
          staticClass: "settings-button",
          attrs: { loading: _vm.uiFlags.isUpdating },
          on: { click: _vm.updateSettings }
        },
        [_vm._v("\n    Update\n  ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "settings-container column custom-scroll lime-card-1dp mr-right"
        },
        [
          _c("bot-csat-form", {
            model: {
              value: _vm.botCsatConfig,
              callback: function($$v) {
                _vm.botCsatConfig = $$v
              },
              expression: "botCsatConfig"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }