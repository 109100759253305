var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box settings-box no-padding" },
    [
      _c(
        "woot-base-button",
        {
          staticClass: "settings-button",
          attrs: {
            size: "medium",
            "front-icon": "plus",
            "icon-view-box": "0 0 20 20"
          },
          on: { click: _vm.openAddPopup }
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("TEMPLATE.HEADER_BTN_TXT")) + "\n  ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "settings-container" },
        [
          _c(
            "div",
            { staticClass: "column settings-main-menu-new" },
            [
              _c("view-template", {
                attrs: { "actions-list": _vm.actionsList },
                on: {
                  edit: _vm.openEditPopup,
                  delete: _vm.openDeletePopup,
                  select: function(template) {
                    return (_vm.selectedTemplate = template)
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "settings-side-card",
            [
              !_vm.selectedTemplate.id
                ? _c(
                    "educational-onboarding",
                    {
                      attrs: { "show-case-data": _vm.SHOWCASE_TEMPLATES_DATA }
                    },
                    [
                      _c("reference-box", {
                        staticClass: "mg-top--large",
                        attrs: {
                          to: _vm.documentation.templates,
                          content: "Know more about templates here",
                          type: "document"
                        }
                      })
                    ],
                    1
                  )
                : _c("rhs-panel", {
                    key: _vm.selectedTemplate.id,
                    attrs: { "selected-template": _vm.selectedTemplate },
                    on: { openEditModal: _vm.openEditPopup }
                  }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex-column full-height flex-justify--end" },
                [
                  _c(
                    "div",
                    { staticClass: "action-btns flex-column gap--one" },
                    [
                      _c(
                        "woot-base-button",
                        {
                          attrs: { "full-width": "", loading: _vm.isFetching },
                          on: { click: _vm.debouncedFetchTemplates }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("TEMPLATE.IMPORT")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex-row gap--one" },
                        [
                          _vm.templateList.length
                            ? _c(
                                "woot-base-button",
                                {
                                  attrs: {
                                    variant: "secondary",
                                    "full-width": ""
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.showExportTemplateModal = true
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("TEMPLATE.EXPORT")) +
                                      "\n            "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "woot-base-button",
                            {
                              attrs: { variant: "secondary", "full-width": "" },
                              on: {
                                click: function($event) {
                                  _vm.showImportTemplateModal = true
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("CANNED_MGMT.IMPORT_CSV")) +
                                  "\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.showImportTemplateModal
        ? _c("import-template", {
            on: {
              onClose: function($event) {
                _vm.showImportTemplateModal = false
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showExportTemplateModal
        ? _c("export-template", {
            on: {
              onClose: function($event) {
                _vm.showExportTemplateModal = false
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "modal-fade" } },
        [
          _vm.showAddPopup
            ? _c("add-template", { attrs: { "on-close": _vm.hideAddPopup } })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "modal-fade" } },
        [
          _vm.showEditPopup
            ? _c("edit-template", {
                attrs: {
                  "selected-template": _vm.selectedTemplate,
                  "on-close": _vm.hideEditPopup
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("woot-delete-modal-new", {
        attrs: {
          show: _vm.showDeleteConfirmationPopup,
          "on-close": _vm.closeDeletePopup,
          "on-confirm": _vm.confirmDeletion,
          title: _vm.$t("TEMPLATE.DELETE.CONFIRM.TITLE"),
          message: _vm.deleteMessage,
          "confirm-text": "Continue",
          "reject-text": "Back",
          "custom-style": {
            "max-width": "35.2rem",
            height: "auto"
          }
        },
        on: {
          "update:show": function($event) {
            _vm.showDeleteConfirmationPopup = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }