var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.viewsUiFlags.isFetching
    ? _c(
        "div",
        { staticClass: "chat-list-top" },
        [
          _c("views", { on: { onChange: _vm.fetchConversations } }),
          _vm._v(" "),
          _c("search-filter"),
          _vm._v(" "),
          _c("conversation-filter-tab", {
            staticClass: "conversation-filters-container",
            attrs: {
              "parent-component-name": "Tickets",
              "filter-list": _vm.$t("FILTERS.TICKET_SECTION_FILTER_LIST"),
              "applied-filters": _vm.appliedConversationFilters
            },
            on: { filterChange: _vm.onUpdateFilter }
          })
        ],
        1
      )
    : _c("zero-state")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }