var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "column padding-container settings-box custom-scroll",
      staticStyle: { "padding-top": "0px" }
    },
    [
      _c("div", { staticClass: "filter-pane" }, [
        _c(
          "div",
          {
            staticClass: "flex-space-between",
            staticStyle: { "margin-top": "0.5rem" }
          },
          [
            _c(
              "div",
              { staticClass: "flex-row analytics-filters" },
              [
                _c("date-range-selector", {
                  attrs: { "default-selection": 2 },
                  on: { "date-range-change": _vm.onDateRangeChange }
                }),
                _vm._v(" "),
                _c("inbox-filter", {
                  attrs: {
                    "allow-multi-select": true,
                    value: _vm.$t(
                      "ANALYTICS.FILTER_SELECTION.INBOX.PLACEHOLDER"
                    ),
                    "exclude-inbox-types": _vm.excludedInboxTypes,
                    "toggle-filter": _vm.changeInboxId
                  }
                }),
                _vm._v(" "),
                _c("woot-single-selector", {
                  attrs: {
                    disabled: false,
                    variant: "secondary",
                    size: "medium",
                    "default-option": _vm.selectedWorkingHour,
                    "option-list": _vm.optionList,
                    "show-selected-text": false,
                    "custom-style": { width: "auto" }
                  },
                  on: { click: _vm.changeWorkingHourType }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("woot-primary-button", {
                  attrs: {
                    name: _vm.$t(
                      "ANALYTICS.DOWNLOAD_REPORT.PERFORMANCE_REPORT"
                    ),
                    "front-icon": "email"
                  },
                  on: { click: _vm.download }
                })
              ],
              1
            )
          ]
        )
      ]),
      _vm._v(" "),
      _vm.cardUIFlag
        ? _c(
            "div",
            { staticClass: "metrics-card-container" },
            _vm._l(_vm.metricsCount, function(item, index) {
              return _c("metrics-card-skeleton", { key: index })
            }),
            1
          )
        : _c(
            "div",
            { staticClass: "metrics-card-container" },
            _vm._l(_vm.metricsCount, function(item, index) {
              return _c("metrics-card", {
                key: index,
                attrs: {
                  title: item.title,
                  "sub-title": item.sub,
                  unit: item.unit,
                  actual: item.actual,
                  color: item.color
                }
              })
            }),
            1
          ),
      _vm._v(" "),
      _vm.lineChartUIFlag
        ? _c(
            "div",
            { staticClass: "bottom-container" },
            [_c("line-chart-skeleton")],
            1
          )
        : _c("div", { staticClass: "medium-12 bottom-container" }, [
            _c(
              "div",
              { staticClass: "lime-card-1dp medium-12" },
              [
                _c(
                  "div",
                  {
                    staticClass: "flex-space-between",
                    staticStyle: { position: "relative", height: "3.5rem" }
                  },
                  [
                    _c("div", { staticClass: "table-title" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.selectedLineChart) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "flex-row-justify-center",
                        staticStyle: { "margin-top": "2rem" }
                      },
                      [
                        _c("woot-single-selector", {
                          attrs: {
                            "option-list": _vm.lineChartType,
                            "default-option": _vm.selectedLineChart,
                            "custom-style": {
                              "margin-top": "-0.1rem",
                              "z-index": "10000"
                            },
                            "show-selected-text": false,
                            "front-icon": "filterHollow"
                          },
                          on: { click: _vm.changeLineChartType }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _vm._v(" "),
                _c("line-chart", {
                  attrs: {
                    series: _vm.chartSeries,
                    categories: _vm.chartCategories,
                    colors: _vm.chartColors,
                    "show-marker": false,
                    "toggle-data": false,
                    "highlight-data": false,
                    "toolbar-enable": false
                  },
                  on: {
                    "zoom-button-clicked": function($event) {
                      return _vm.handleZoomButtonClick(
                        "bot_performance",
                        "automation_percentage"
                      )
                    }
                  }
                })
              ],
              1
            )
          ]),
      _vm._v(" "),
      _vm.uiFlag
        ? _c(
            "div",
            { staticClass: "bottom-container" },
            [_c("line-chart-skeleton")],
            1
          )
        : _c("div", { staticClass: "medium-12 bottom-container" }, [
            _c(
              "div",
              { staticClass: "lime-card-1dp medium-12" },
              [
                _c("column-chart", {
                  attrs: {
                    title: "User Intent Breakdown",
                    series: _vm.columnChartSeries,
                    categories: _vm.columnChartCategories,
                    colors: _vm.columnChartColors,
                    "has-title": true,
                    "border-radius": 10,
                    "column-width": "30%",
                    "chart-offset-y": 30
                  }
                })
              ],
              1
            )
          ]),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: { show: _vm.downloading, "on-close": _vm.hidePopup },
          on: {
            "update:show": function($event) {
              _vm.downloading = $event
            }
          }
        },
        [
          _c("div", { staticClass: "download-note" }, [
            _c("img", {
              attrs: {
                src:
                  "https://s3.ap-south-1.amazonaws.com/cdn.limechat.ai/assets/images/limechat_report_sent.svg"
              }
            }),
            _vm._v(" "),
            _c("div", [
              _vm._v(
                "\n        An email with attached report will be sent to\n        "
              ),
              _c("span", [_vm._v(_vm._s(_vm.userEmail) + " ")]),
              _vm._v(" shortly.\n      ")
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: { show: _vm.downloadError, "on-close": _vm.hidePopup },
          on: {
            "update:show": function($event) {
              _vm.downloadError = $event
            }
          }
        },
        [
          _c("div", { staticClass: "download-note" }, [
            _c("div", [_vm._v("Sorry for inconvenience some error occured.")])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }