var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "signup" }, [
    _c(
      "div",
      { staticClass: "signup__hero", staticStyle: { padding: "0rem 6rem" } },
      [
        _c("img", {
          staticClass: "hero__logo",
          attrs: {
            src: _vm.globalConfig.logo,
            alt: _vm.globalConfig.installationName
          }
        }),
        _vm._v(" "),
        _c("h2", {
          staticClass: "hero__title",
          domProps: { textContent: _vm._s(_vm.$t("REGISTER.TRY_WOOT")) }
        })
      ]
    ),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "signup__box",
        staticStyle: { padding: "0rem 6rem" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.submit($event)
          }
        }
      },
      [
        _c("woot-input", {
          staticClass: "columns content-margin",
          class: { error: _vm.$v.accountName.$error },
          attrs: {
            label: _vm.$t("REGISTER.COMPANY_NAME.LABEL"),
            placeholder: _vm.$t("REGISTER.COMPANY_NAME.PLACEHOLDER"),
            error: _vm.$v.accountName.$error
              ? _vm.$t("REGISTER.COMPANY_NAME.ERROR")
              : ""
          },
          on: { blur: _vm.$v.accountName.$touch },
          model: {
            value: _vm.accountName,
            callback: function($$v) {
              _vm.accountName = $$v
            },
            expression: "accountName"
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "signin__footer" }, [
          _c("p", {
            staticClass: "desc-text accept__terms",
            domProps: { innerHTML: _vm._s(_vm.termsLink) }
          }),
          _vm._v(" "),
          _c("p", {
            staticClass: "desc-text",
            domProps: {
              textContent: _vm._s("Copyright © LimeChat.ai All Rights Reserved")
            }
          })
        ]),
        _vm._v(" "),
        _c("woot-primary-button", {
          attrs: {
            disabled: _vm.isSignupInProgress || _vm.$v.accountName.$error,
            name: _vm.$t("REGISTER.SUBMIT"),
            loading: _vm.isSignupInProgress
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }