var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "inbox-selection-section" }, [
    _c(
      "section",
      [
        _c("label", { staticClass: "formlabel" }, [
          _c("span", { staticClass: "title-h5 text-light" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.$t("CUSTOM_TICKET_FIELDS.SUMMARY.SUB_HEADINGS.INBOXES")
                ) +
                "\n      "
            )
          ])
        ]),
        _vm._v(" "),
        _c("woot-single-selector", {
          attrs: {
            size: "small",
            "option-list": _vm.inactiveInboxes,
            "default-option": "Select inbox for field",
            "custom-style": {
              left: "auto",
              width: "100%",
              maxHeight: "20rem"
            },
            "dropdown-container-style": { width: "36rem" }
          },
          on: { click: _vm.addInbox }
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex-row flex-wrap mg-top--normal" },
          _vm._l(_vm.customFieldInboxes, function(inbox, idx) {
            return _c("woot-chips", {
              key: idx,
              staticStyle: { width: "fit-content", "max-width": "23.2rem" },
              attrs: {
                "show-cancel-icon": "",
                "show-tooltip": false,
                variant: "primary-small",
                title: inbox.name,
                "front-icon": _vm.getInboxIcon(inbox).name
              },
              on: {
                click: function($event) {
                  return _vm.removeInbox(inbox)
                }
              }
            })
          }),
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }