var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: { show: _vm.show, "on-close": _vm.onClose, "show-close": false },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "column content-box" },
        [
          _c("woot-modal-header", {
            attrs: {
              "header-title":
                _vm.selectedCount +
                " " +
                _vm.$t("CONVERSATION.BULK_MODAL_HEADER")
            }
          }),
          _vm._v(" "),
          _c(
            "form",
            {
              staticClass: "row settings-form",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.modifyConv($event)
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "medium-12 content-margin" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("INBOX_MGMT.ADD.AGENTS.TITLE")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("woot-single-selector", {
                    attrs: {
                      size: "large",
                      "custom-style": { width: "100%" },
                      "option-list": _vm.agentList,
                      "default-option": _vm.selectedAgent
                    },
                    on: { click: _vm.onAgentSelect }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "medium-12 content-margin" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("INBOX_MGMT.ADD.TEAMS.TITLE")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("woot-single-selector", {
                    attrs: {
                      size: "large",
                      "custom-style": { width: "100%" },
                      "option-list": _vm.teamList,
                      "default-option": _vm.selectedTeam
                    },
                    on: { click: _vm.onTeamSelect }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "medium-12 content-margin" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v(
                      "\n          " + _vm._s("Assign Status") + "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("woot-single-selector", {
                    attrs: {
                      size: "large",
                      "custom-style": { width: "100%" },
                      "option-list": _vm.getActionList,
                      "show-all": true,
                      "default-option": _vm.currentStatus
                    },
                    on: { click: _vm.toggleStatus }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.waitTimeOptions
                ? _c("div", { staticClass: "medium-12 row" }, [
                    _c("span", { staticClass: "form-label medium-12" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s("Set the time for alert reminders") +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "content-margin" },
                      _vm._l(_vm.quickAction, function(item, index) {
                        return _c(
                          "button",
                          {
                            key: item.NAME,
                            staticClass: "button hollow quick-button",
                            class: {
                              "active-quick": index === _vm.selectedQuickAction
                            },
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.setDelay(item.VALUE, index)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.NAME) +
                                "\n          "
                            )
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _vm.showTimePicker
                      ? _c("div", { staticClass: "content-margin" }, [
                          _c("span", { staticClass: "form-label medium-12" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s("Or select manually") +
                                "\n          "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "limechat-timepicker row" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "column flex-row-justify-center picker-container"
                                },
                                [
                                  _c("vue-timepicker", {
                                    attrs: {
                                      "hide-clear-button": "",
                                      "minute-interval": 10,
                                      format: "HH:mm"
                                    },
                                    model: {
                                      value: _vm.alertDelay,
                                      callback: function($$v) {
                                        _vm.alertDelay = $$v
                                      },
                                      expression: "alertDelay"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ])
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "medium-12 content-margin" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("CONVERSATION.ASSIGN_LABELS")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _vm.activeList.length
                    ? _c(
                        "div",
                        { staticStyle: { "margin-bottom": "1.2rem" } },
                        _vm._l(_vm.activeList, function(label) {
                          return _c("woot-chips", {
                            key: label.id,
                            attrs: {
                              variant: "primary-small",
                              "bg-color": label.color,
                              title: label.title,
                              description: label.description,
                              "show-cancel-icon": true
                            },
                            on: { click: _vm.onRemove }
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("woot-single-selector", {
                    attrs: {
                      size: "large",
                      "option-list": _vm.inactiveList,
                      "custom-style": { width: "100%" },
                      "default-option": _vm.$t("CONVERSATION.ASSIGN_LABELS")
                    },
                    on: { click: _vm.onSelect }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "medium-12 content-margin" },
                [
                  _vm.showCannedResponsesList
                    ? _c(
                        "div",
                        { staticClass: "canned-container" },
                        [
                          _c("canned-response", {
                            attrs: {
                              "data-dropdown-menu": "",
                              "without-padding": true,
                              "on-keyenter": _vm.replaceText,
                              "on-click": _vm.replaceText
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "cancel-button",
                              attrs: { type: "button" },
                              on: { click: _vm.hideCannedResponse }
                            },
                            [
                              _c("icons", {
                                attrs: {
                                  name: "cancel",
                                  color: "stroke-red",
                                  size: "semimedium"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("woot-input", {
                    attrs: {
                      placeholder: this.$t("CONVERSATION.FOOTER.MSG_INPUT"),
                      rows: 3
                    },
                    model: {
                      value: _vm.message,
                      callback: function($$v) {
                        _vm.message = $$v
                      },
                      expression: "message"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "medium-12 content-margin" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("\n          Actions\n        ")
                  ]),
                  _vm._v(" "),
                  _c("woot-action-button", {
                    attrs: {
                      active: _vm.isStarred,
                      name: "Mark Starred",
                      icon: "starStroke"
                    },
                    on: { click: _vm.toggleStarred }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "button clear flex-row-justify-center clear-all",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.onClear($event)
                      }
                    }
                  },
                  [
                    _c("icons", {
                      attrs: {
                        name: "cancel",
                        color: "stroke-red",
                        size: "semimedium"
                      }
                    }),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("CONVERSATION.CLEAR_ALL")) +
                        "\n        "
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex-row-justify-center" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "button clear red",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.onClose($event)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("LABEL_MGMT.FORM.CANCEL")) +
                            "\n          "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("woot-primary-button", {
                      attrs: {
                        name: _vm.$t("CONVERSATION.APPLY_CHANGE"),
                        size: "medium",
                        loading: _vm.isLoading,
                        "front-icon": "verified"
                      }
                    })
                  ],
                  1
                )
              ])
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }