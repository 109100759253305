var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "th",
    { staticClass: "title-h4 text-dark", style: _vm.colHeaderStyle },
    [
      _c(
        "div",
        {
          class: ["flex-row", "flex-align", _vm.colHeaderClasses],
          style: _vm.sortable && "cursor: pointer",
          on: { click: _vm.toggleSort }
        },
        [
          _c("span", { domProps: { textContent: _vm._s(_vm.label) } }),
          _vm._v(" "),
          _vm.sortable
            ? _c(
                "span",
                {
                  class: [
                    "sort-icon",
                    { "sort-icon--filled": !!_vm.sortDirection }
                  ]
                },
                [
                  _c("icons", {
                    attrs: {
                      name:
                        _vm.sortDirection === "asc" || _vm.sortDirection === ""
                          ? "chevronTop"
                          : "chevronDown",
                      color: !!_vm.sortDirection ? "white" : "darkestgrey",
                      "show-title": false,
                      view: "0 0 24 24",
                      size: "semimedium"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }