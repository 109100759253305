var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-base-button",
    {
      staticClass: "tags-filter-button",
      class: { active: _vm.selectedOptionsLength },
      attrs: { tag: "span", size: "small", variant: "secondary" }
    },
    [
      _c("icons", {
        attrs: { name: "tag", color: "grey", size: "semimedium" }
      }),
      _vm._v(" "),
      _vm.selectedOptionsLength
        ? _c("span", {
            key: _vm.selectedOptionsLength,
            staticClass: "number-badge zoom-in-out",
            domProps: { textContent: _vm._s(_vm.selectedOptionsLength) }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }