var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("woot-single-selector", {
    ref: "singleSelector",
    attrs: {
      icon: "sortSleek",
      variant: _vm.variant,
      "option-list": _vm.filters,
      "show-selected-text": false,
      "default-option": _vm.getTitle,
      "button-style": Object.assign({}, { color: "#808975" }, _vm.buttonStyle),
      "custom-style": {
        width: "auto",
        right: "0.1rem"
      }
    },
    on: {
      "dropdown-opened": _vm.handleDropdownOpened,
      click: _vm.handleSelectedOption
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }