var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "channel" },
    [
      _c(
        "router-link",
        {
          attrs: {
            to: _vm.frontendURL(
              "accounts/" +
                _vm.accountId +
                "/settings/integrations/" +
                _vm.integrationAction
            )
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "channel-image-container",
              class: { active: _vm.integrationEnabled }
            },
            [
              _c(
                "span",
                { staticClass: "linked-icon flex-row flex-align flex-justify" },
                [
                  _c("icons", {
                    attrs: {
                      name: "link",
                      color: "darkgreen",
                      size: "medium",
                      view: "-3 -3 24 24",
                      title: "linked"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "channel-image flex-row flex-align flex-justify"
                },
                [
                  _c("img", {
                    attrs: {
                      src:
                        "/dashboard/images/integrations/" + _vm.integrationLogo
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c("span", {
                staticClass: "channel__title body-b3 text-light",
                domProps: { textContent: _vm._s(_vm.integrationName) }
              })
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c("woot-delete-modal", {
        attrs: {
          show: _vm.showDeleteConfirmationPopup,
          "on-close": _vm.closeDeletePopup,
          "on-confirm": _vm.confirmDeletion,
          title: _vm.$t("INTEGRATION_SETTINGS.WEBHOOK.DELETE.CONFIRM.TITLE"),
          message: _vm.$t(
            "INTEGRATION_SETTINGS.WEBHOOK.DELETE.CONFIRM.MESSAGE"
          ),
          "confirm-text": _vm.$t(
            "INTEGRATION_SETTINGS.WEBHOOK.DELETE.CONFIRM.YES"
          ),
          "reject-text": _vm.$t(
            "INTEGRATION_SETTINGS.WEBHOOK.DELETE.CONFIRM.NO"
          )
        },
        on: {
          "update:show": function($event) {
            _vm.showDeleteConfirmationPopup = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }