var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view-box columns" },
    [
      _c("conversation-header", { attrs: { chat: _vm.currentChat } }),
      _vm._v(" "),
      _vm.showSubjectHeader ? _c("email-subject-header") : _vm._e(),
      _vm._v(" "),
      _vm.showBanner
        ? _c(
            "div",
            { staticClass: "banner" },
            [
              _c("woot-banner", {
                attrs: {
                  variant: "error",
                  href: _vm.$t("DOCUMENTS.24HOURPOLICY"),
                  "link-msg": _vm.$t("CONVERSATION.KNOW_MORE"),
                  title: _vm.$t("CONVERSATION.REPLY_WINDOW", {
                    duration: _vm.replyWindowDuration
                  }),
                  "custom-style": {
                    background: "#faf9f5",
                    "justify-content": "center"
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isOptChatOut
        ? _c("div", { staticClass: "banner messenger-policy--info" }, [
            _c("span", [
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.$t("DOCUMENTS.OPTCHATOUT"),
                    rel: "noopener noreferrer nofollow",
                    target: "_blank"
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("CONVERSATION.OPT_OUT")) +
                      "\n      "
                  )
                ]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isATweet
        ? _c("div", { staticClass: "banner" }, [
            !_vm.selectedTweetId
              ? _c("span", [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("CONVERSATION.LAST_INCOMING_TWEET")) +
                      "\n    "
                  )
                ])
              : _c("span", [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("CONVERSATION.REPLYING_TO")) +
                      "\n      " +
                      _vm._s(_vm.selectedTweet) +
                      "\n    "
                  )
                ]),
            _vm._v(" "),
            _vm.selectedTweetId
              ? _c(
                  "button",
                  {
                    staticClass: "banner-close-button",
                    on: { click: _vm.removeTweetSelection }
                  },
                  [
                    _c("i", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.$t("CONVERSATION.REMOVE_SELECTION"),
                          expression: "$t('CONVERSATION.REMOVE_SELECTION')"
                        }
                      ],
                      staticClass: "ion-close"
                    })
                  ]
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ul",
        {
          staticClass:
            "conversation-panel flex-column flex-justify--between custom-scroll"
        },
        [
          _c(
            "div",
            {
              staticClass: "conversations",
              class: { "conversations--email": _vm.isAnEmailChannel }
            },
            [
              _c("transition", { attrs: { name: "slide-up" } }, [
                _vm.shouldShowSpinner
                  ? _c("li", { staticClass: "spinner--container" }, [
                      _c("span", { staticClass: "spinner message" })
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _vm.isASocialPost
                ? _c("social-post", {
                    attrs: {
                      "media-data":
                        _vm.currentChat.additional_attributes.media_data,
                      type: _vm.currentChat.identifier,
                      data: _vm.currentChat,
                      "get-reply-data": _vm.getReplyData
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.getReadMessages, function(message, index) {
                return _c("message", {
                  key: message.id,
                  attrs: {
                    index: index,
                    inbox: _vm.inbox,
                    channel: _vm.currentChat.meta.channel,
                    data: message,
                    "is-an-email": _vm.isAnEmailChannel,
                    "is-latest-email":
                      _vm.isAnEmailChannel &&
                      message.id === _vm.latestEmailMessageId,
                    "in-reply-data-dep": _vm.getReplyDataDep(message),
                    "get-reply-data": _vm.getReplyData(message),
                    "is-a-tweet": _vm.isATweet,
                    "is-a-post": _vm.isASocialPost,
                    "is-reply-box-open": _vm.showReplyBox,
                    "next-message": _vm.getReadMessages[index + 1]
                  },
                  on: {
                    onReplyEmail: _vm.onReply,
                    onReplyAllEmail: _vm.onReplyToAll,
                    onForwardEmail: _vm.onForwardEmail,
                    onPrivateReply: _vm.onPrivateReply
                  }
                })
              }),
              _vm._v(" "),
              _vm.getUnreadCount != 0
                ? _c(
                    "li",
                    {
                      staticClass: "unread--toast",
                      attrs: { id: "unread_toast" }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "text-uppercase lime-card-1dp" },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.getUnreadCount) +
                              "\n          " +
                              _vm._s(
                                _vm.getUnreadCount > 1
                                  ? _vm.$t("CONVERSATION.UNREAD_MESSAGES")
                                  : _vm.$t("CONVERSATION.UNREAD_MESSAGE")
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.getUnReadMessages, function(message, index) {
                return _c("message", {
                  key: message.id,
                  attrs: {
                    index: index,
                    inbox: _vm.inbox,
                    channel: _vm.currentChat.meta.channel,
                    data: message,
                    "is-an-email": _vm.isAnEmailChannel,
                    "is-latest-email":
                      _vm.isAnEmailChannel &&
                      message.id === _vm.latestEmailMessageId,
                    "in-reply-data-dep": _vm.getReplyDataDep(message),
                    "get-reply-data": _vm.getReplyData(message),
                    "is-a-tweet": _vm.isATweet,
                    "is-a-post": _vm.isASocialPost,
                    "is-reply-box-open": _vm.showReplyBox,
                    "next-message": _vm.getUnReadMessages[index + 1]
                  },
                  on: {
                    onReplyEmail: _vm.onReply,
                    onReplyAllEmail: _vm.onReplyToAll,
                    onForwardEmail: _vm.onForwardEmail,
                    onPrivateReply: _vm.onPrivateReply
                  }
                })
              }),
              _vm._v(" "),
              _vm.isAnyoneTyping
                ? _c("li", { staticClass: "left" }, [
                    _c("div", { staticClass: "typing-indicator" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.typingUserNames) +
                          "\n          "
                      ),
                      _c("img", {
                        staticClass: "gif",
                        attrs: {
                          src: require("dashboard/assets/images/typing.gif"),
                          alt: "Someone is typing"
                        }
                      })
                    ])
                  ])
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "transition",
            { key: "reply-box", attrs: { name: "slide-fade" } },
            [
              _vm.isAnEmailChannel && _vm.showReplyBox
                ? _c(
                    "div",
                    [
                      _c("ReplyBox", {
                        ref: "replyBox",
                        attrs: {
                          "is-an-email": _vm.isAnEmailChannel,
                          status: _vm.currentChat.status,
                          "conversation-id": _vm.currentChat.id,
                          assignee: _vm.currentChat.meta.assignee,
                          "disable-template": _vm.disableTemplateMode,
                          "in-reply-to": _vm.selectedTweetId,
                          "reply-state": _vm.replyShowState,
                          "is-a-post": _vm.isASocialPost,
                          "show-banner": _vm.showBanner,
                          "email-forwarding-mode": _vm.emailForwardingMode
                        },
                        on: {
                          scrollToMessage: _vm.scrollToBottom,
                          toggleReply: _vm.toggleReplySelection,
                          onCloseReplyBox: function() {
                            _vm.showReplyBox = false
                            _vm.privateReplyMode = false
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "conversation-footer" },
        [
          _vm.showScrollButton
            ? _c(
                "button",
                {
                  staticClass: "scroll-button",
                  on: { click: _vm.scrollToBottom }
                },
                [
                  _c("icons", {
                    attrs: {
                      name: "chevronDown",
                      color: "grey",
                      size: "semimedium"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isAnEmailChannel
            ? _c("ReplyBox", {
                ref: "replyBox",
                attrs: {
                  status: _vm.currentChat.status,
                  "conversation-id": _vm.currentChat.id,
                  assignee: _vm.currentChat.meta.assignee,
                  "disable-template": _vm.disableTemplateMode,
                  "in-reply-to": _vm.selectedTweetId,
                  "get-reply-data": _vm.getReplyData(
                    _vm.selectedMessageForReply
                  ),
                  "reply-state": _vm.replyShowState,
                  "is-a-post": _vm.isASocialPost,
                  "show-banner": _vm.showBanner
                },
                on: {
                  scrollToMessage: _vm.scrollToBottom,
                  toggleReply: _vm.toggleReplySelection
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.showPrivateReplyModal
        ? _c("private-reply-modal", {
            attrs: {
              type: _vm.currentChat.identifier,
              data: _vm.currentChat,
              message: _vm.selectedMessageforPrivateReply
            },
            on: { togglePrivateReply: _vm.onTogglePrivateReply }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }