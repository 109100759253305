var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scroll--panel custom-scroll" },
    [
      _vm.search
        ? _c("span", { staticClass: "body-b3 text-light" }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.uiFlags.fetchingList ? "Searching" : "Search") +
                " by\n    " +
                _vm._s(_vm.defaultSelectedParam.name) +
                "\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "search-box",
        {
          attrs: {
            placeholder: "Search by " + _vm.defaultSelectedParam.name,
            value: _vm.search,
            variant: true,
            "on-search": _vm.searchOrders
          },
          on: { setSearch: _vm.setSearch }
        },
        [
          _c(
            "woot-single-selector",
            {
              attrs: {
                variant: "tertiary",
                size: "large",
                "default-option": _vm.defaultSelectedParam.name,
                "custom-button": true,
                "option-list": _vm.searchParams,
                "custom-style": { left: "calc(100% - 13.6rem)" },
                "top-position": { top: "3.3rem" }
              },
              on: { click: _vm.setSearchParam }
            },
            [
              _c("div", { staticClass: "dropdown-button" }, [
                _c(
                  "span",
                  { staticClass: "flex-row" },
                  [
                    _c("icons", {
                      attrs: {
                        name: "chevronDown",
                        size: "medium",
                        color: "green"
                      }
                    })
                  ],
                  1
                )
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      !_vm.uiFlags.fetchingList && !_vm.records.length
        ? _c("div", { staticClass: "no-order" }, [
            _c("img", {
              attrs: {
                src: require("dashboard/assets/images/noOrder.svg"),
                alt: "No Order"
              }
            }),
            _vm._v(" "),
            _c("p", { staticClass: "no-items-error-message" }, [
              _vm._v("\n      No orders found.\n    ")
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.uiFlags.fetchingList
        ? _c("woot-loading-state", { attrs: { message: "Fetching orders..." } })
        : _vm._e(),
      _vm._v(" "),
      !_vm.uiFlags.fetchingList && _vm.records.length
        ? _c(
            "div",
            _vm._l(_vm.records, function(order, index) {
              return _c(
                "accordian-card",
                {
                  key: order.id,
                  attrs: {
                    name: "Order " + order.order_id,
                    color: "blue",
                    "always-open": index === 0
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "close",
                        fn: function() {
                          return [
                            _c(
                              "div",
                              { staticClass: "close-button-container" },
                              [
                                order.fulfillment_status &&
                                order.fulfillment_status.name
                                  ? _c("span", [
                                      _vm._v(
                                        "\n            Fulfilled\n          "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                order.financial_status === "paid"
                                  ? _c("span", [
                                      _vm._v("\n            Paid\n          ")
                                    ])
                                  : _vm._e()
                              ]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    true
                  )
                },
                [
                  _vm._v(" "),
                  _c("table", [
                    _c("tbody", [
                      order.last_name || order.first_nam
                        ? _c("tr", [
                            _c("td", [_vm._v("Invoice Name :")]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(order.first_name + " " + order.last_name)
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      order.purchase_datetime
                        ? _c("tr", [
                            _c("td", [_vm._v("Placed On :")]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.formatDate(order.purchase_datetime))
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      order.shipping_address
                        ? _c("tr", [
                            _c("td", [
                              _vm._v(
                                "\n              Delivery Address :\n            "
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(_vm._s(_vm.getShippingAddress(order)))
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      order.shipping_address
                        ? _c("tr", [
                            _c("td", [_vm._v("Pincode :")]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(_vm._s(order.shipping_address.zip))
                            ])
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  _c("order-panel-item", {
                    attrs: {
                      order: order.items,
                      "shipping-charges": order.shipping_charges,
                      amount: order.amount,
                      "sub-total": order.subtotal_price
                    }
                  }),
                  _vm._v(" "),
                  order.fulfillment_status &&
                  order.fulfillment_status.tracking_info
                    ? _c("div", { staticClass: "tracking-container" }, [
                        _c(
                          "span",
                          { staticStyle: { "margin-bottom": "0.8rem" } },
                          [_vm._v("Tracking:")]
                        ),
                        _vm._v(" "),
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              order.fulfillment_status.tracking_info[0].url
                                ? _vm.formatMessage(
                                    order.fulfillment_status.tracking_info[0]
                                      .url
                                  )
                                : "-"
                            )
                          }
                        })
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-button" },
                    [
                      _c("woot-secondary-button", {
                        attrs: {
                          size: "small",
                          name: _vm.$t("PRODUCTS_MGMT.SHARE_ORDER"),
                          "full-width": true,
                          "custom-style": {
                            width: "60%",
                            "justify-content": "center"
                          }
                        },
                        on: {
                          click: function($event) {
                            return _vm.openSharePopup(order)
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.uiFlags.fetchingList && _vm.records.length && _vm.hasNextPage
        ? _c("p", {
            staticClass: "clear button load-more-conversations",
            domProps: { textContent: _vm._s("Load More Orders") },
            on: { click: _vm.loadMoreOrders }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.uiFlags.fetchingList && _vm.records.length && !_vm.hasNextPage
        ? _c("p", {
            staticClass: "text-center text-muted end-of-list-text",
            domProps: { textContent: _vm._s("All Orders Loaded") }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("woot-delete-modal", {
        attrs: {
          show: _vm.showShareModal,
          "on-close": _vm.closeSharePopup,
          "on-confirm": _vm.shareOrder,
          title: "Share Order",
          message: _vm.shareMessage,
          "confirm-text": "Share",
          "reject-text": "Cancel"
        },
        on: {
          "update:show": function($event) {
            _vm.showShareModal = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }