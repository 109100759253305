var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bottom-container lime-card-new" }, [
    _c("div", { staticClass: "skeleton-animation heading-skeleton" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "flex-row-justify-center margin-container" },
      _vm._l(4, function(index) {
        return _c(
          "div",
          { key: index, staticClass: "header-skeleton-margin" },
          [_c("div", { staticClass: "skeleton-animation header-skeleton" })]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("div", {
      staticClass: "skeleton-animation",
      style: { height: _vm.chartHeight }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }