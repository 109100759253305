var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass:
        "flex-row flex-align gap--smaller pd-left--smaller pd-right--smaller"
    },
    [
      _c("span", {
        staticClass: "deleted-tag",
        domProps: { textContent: _vm._s(_vm.$t("CONVERSATION.DELETED")) }
      }),
      _vm._v(" "),
      _vm.deletedByText
        ? _c("span", {
            staticClass: "text-dark",
            domProps: { textContent: _vm._s(_vm.deletedByText) }
          })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }