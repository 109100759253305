var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "code--container" }, [
    _vm.isWhatsapp
      ? _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "code--container--header flex-space-between" },
              [
                _c("span", { staticClass: "body-b2" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("COMPONENTS.CODE.WIDGET_CODE_TITLE")) +
                      "\n        " +
                      _vm._s(_vm.$t("COMPONENTS.CODE.WHATSAPP_TITLE_TEXT")) +
                      "\n      "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "woot-base-button",
                  { attrs: { size: "small" }, on: { click: _vm.onCopy } },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("COMPONENTS.CODE.WHATSAPP_BUTTON_TEXT")) +
                        "\n      "
                    )
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("highlightjs", {
              staticClass: "whatsapp-widget-pane",
              attrs: { language: _vm.lang, code: _vm.script }
            })
          ],
          1
        )
      : _vm.isLimechatWidget
      ? _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "code--container--header flex-space-between" },
              [
                _c("span", { staticClass: "body-b2" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("COMPONENTS.CODE.WIDGET_CODE_TITLE")) +
                      "\n        " +
                      _vm._s(_vm.$t("COMPONENTS.CODE.LIMECHAT_TITLE_TEXT")) +
                      "\n      "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "woot-base-button",
                  { attrs: { size: "small" }, on: { click: _vm.onCopy } },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("COMPONENTS.CODE.WHATSAPP_BUTTON_TEXT")) +
                        "\n      "
                    )
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("highlightjs", {
              staticClass: "whatsapp-widget-pane",
              attrs: { language: _vm.lang, code: _vm.script }
            })
          ],
          1
        )
      : _c(
          "div",
          [
            _c(
              "woot-base-button",
              {
                staticClass: "button--copy-code",
                attrs: { size: "small" },
                on: { click: _vm.onCopy }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("COMPONENTS.CODE.BUTTON_TEXT")) +
                    "\n    "
                )
              ]
            ),
            _vm._v(" "),
            _c("highlightjs", {
              attrs: { language: _vm.lang, code: _vm.script }
            })
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }