var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-box settings-box" },
    [
      _vm.inboxes.length && !_vm.uiFlags.isFetching
        ? _c(
            "div",
            {
              staticClass:
                "settings-container column custom-scroll lime-card-1dp mr-right"
            },
            [
              _c(
                "page-header",
                {
                  attrs: {
                    "header-title": _vm.$t("TICKET_ASSIGNMENT.TITLE"),
                    "header-content": _vm.$t("TICKET_ASSIGNMENT.DESCRIPTION")
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex-row flex-align gap--small" },
                    [
                      _c("reference-box", {
                        staticClass: "mg-top--large",
                        attrs: {
                          to: _vm.FEATURE_DEMO_URL,
                          content: "Video Demo",
                          type: "video"
                        }
                      }),
                      _vm._v(" "),
                      _c("reference-box", {
                        staticClass: "mg-top--large",
                        attrs: {
                          to: _vm.PLAYBOOK_URL,
                          content: "Know More",
                          type: "document"
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c("ticket-assignment-settings")
            ],
            1
          )
        : !_vm.inboxes.length && !_vm.uiFlags.isFetching
        ? _c("zero-state")
        : _c("loading-state", { staticClass: "loader-cmpt" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }