var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ticket-assignment-settings" },
    [
      _c(
        "div",
        { staticClass: "assignment-panels" },
        [
          _c("assignment-panel", {
            attrs: {
              mode: _vm.ASSIGNMENT_MODES.AUTO,
              inboxes: _vm.autoAssignmentInboxes,
              "current-assignment-logic": _vm.selectedAssignmentLogic
            },
            on: {
              update: _vm.onUpdate,
              updateAssigmentLogic: _vm.onUpdateAssignmentLogic
            }
          }),
          _vm._v(" "),
          _c("assignment-panel", {
            attrs: {
              mode: _vm.ASSIGNMENT_MODES.MANUAL,
              inboxes: _vm.manualAssignmentInboxes
            },
            on: { update: _vm.onUpdate }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("offline-assignment-selector", {
        staticClass: "mg-top--medium",
        attrs: {
          inboxes: _vm.autoAssignmentInboxes,
          "selected-inboxes": _vm.autoInboxesWithOfflineAssignmentEnabled
        },
        on: { update: _vm.onUpdate }
      }),
      _vm._v(" "),
      _c(
        "woot-base-button",
        {
          staticClass: "settings-button",
          attrs: { loading: _vm.isUpdatingSettings },
          on: { click: _vm.onSubmit }
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("TICKET_ASSIGNMENT.SAVE.BUTTON_TITLE")) +
              "\n  "
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }