var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    {
      attrs: {
        "custom-style": { width: "60%" },
        show: _vm.show,
        "on-close": function() {
          return _vm.$emit("onClose")
        }
      },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c("woot-modal-header", {
        attrs: { "header-title": "Understand how states work" }
      }),
      _vm._v(" "),
      _c("safe-markdown", {
        staticClass: "page-top-bar",
        attrs: { content: _vm.renderedMarkdown }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }