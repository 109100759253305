var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "page-header",
        {
          attrs: {
            "header-title": _vm.$t("BOT_CSAT.TITLE"),
            "info-text": _vm.$t("BOT_CSAT.DESCRIPTION")
          }
        },
        [
          !_vm.uiFlags.isFetchingBotConfigs
            ? _c("woot-switch", {
                attrs: { id: Math.random(), value: _vm.value.enable_bot_csat },
                on: {
                  click: function() {
                    return _vm.updateConfig(
                      "enable_bot_csat",
                      !_vm.value.enable_bot_csat
                    )
                  }
                }
              })
            : _c("woot-spinner", {
                staticClass: "margin-right--medium",
                attrs: { size: "", color: "lime-green" }
              })
        ],
        1
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "modal-fade" } }, [
        _vm.value.enable_bot_csat
          ? _c(
              "div",
              { staticClass: "settings-sub-menu-content--larger" },
              [
                _c("bot-flow-checkboxes", {
                  attrs: { "flows-object": _vm.value.csat_flow_trigger },
                  on: {
                    updateConfig: function(newVal) {
                      return _vm.updateConfig("csat_flow_trigger", newVal)
                    }
                  }
                }),
                _vm._v(" "),
                _c("bot-rating-radios", {
                  attrs: { rating: _vm.value.csat_point_system },
                  on: {
                    updateConfig: function(newVal) {
                      return _vm.updateConfig("csat_point_system", newVal)
                    }
                  }
                }),
                _vm._v(" "),
                _c("bot-csat-time-delay", {
                  attrs: {
                    title: _vm.$t("BOT_CSAT.CONTROLS.TIME_DELAY.TITLE"),
                    description: _vm.$t(
                      "BOT_CSAT.CONTROLS.RATING_SCALE.DESCRIPTION"
                    ),
                    time: _vm.value.bot_csat_timer,
                    error: _vm.$v.value.bot_csat_timer.$error
                      ? _vm.$t(
                          "BOT_CSAT.CONTROLS.TIME_DELAY.ERRORS.INVALID_TIME"
                        )
                      : ""
                  },
                  on: {
                    updateConfig: function(newVal) {
                      _vm.$v.value.bot_csat_timer.$touch()
                      _vm.updateConfig("bot_csat_timer", newVal)
                    }
                  }
                }),
                _vm._v(" "),
                _c("bot-csat-time-delay", {
                  attrs: {
                    title: _vm.$t("BOT_CSAT.CONTROLS.REMINDER_TIME.TITLE"),
                    description: _vm.$t(
                      "BOT_CSAT.CONTROLS.REMINDER_TIME.DESCRIPTION"
                    ),
                    time: _vm.value.csat_reminder_timer,
                    error: _vm.$v.value.csat_reminder_timer.$error
                      ? _vm.$t(
                          "BOT_CSAT.CONTROLS.REMINDER_TIME.ERRORS.INVALID_TIME"
                        )
                      : ""
                  },
                  on: {
                    updateConfig: function(newVal) {
                      _vm.$v.value.csat_reminder_timer.$touch()
                      _vm.updateConfig("csat_reminder_timer", newVal)
                    }
                  }
                })
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }