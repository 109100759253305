var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "email-body": _vm.isEmail } },
    [
      _vm.isVideo
        ? _c("video-view", { attrs: { url: _vm.videoUrl } })
        : _vm.isYoutube
        ? _c("youtube-view", { attrs: { url: _vm.isYoutube } })
        : _c("span", { staticClass: "message-text__wrap" }, [
            _c(
              "div",
              {
                staticClass: "message-span",
                class: {
                  "message-activity": [2, 7].includes(_vm.data.message_type),
                  "message-email body-b2": _vm.isEmail,
                  "message-deleted": _vm.isDeleted
                }
              },
              [
                _vm.temporaryContent
                  ? _c("span", {
                      domProps: { innerHTML: _vm._s(_vm.temporaryContent) }
                    })
                  : _vm._e()
              ]
            )
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }