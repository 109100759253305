var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("woot-single-selector", {
    attrs: {
      variant: "secondary",
      size: "small",
      "default-option": _vm.$t(
        "SLA.CREATE.SECTIONS.TRIGGER.KEYS." + _vm.value.toUpperCase()
      ),
      "option-list": _vm.options,
      "button-style": {
        width: "8rem"
      },
      "custom-style": {
        left: 0,
        width: "auto"
      },
      "show-selected-text": false
    },
    on: { click: _vm.onClick }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }