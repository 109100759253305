var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "view-box columns",
      class: { "view-box-new": _vm.newBackground }
    },
    [
      _c("settings-header", {
        attrs: {
          "button-route": "new",
          icon: _vm.icon,
          "header-title": _vm.$t(_vm.headerTitle),
          "show-back-button": _vm.showBackButton,
          "back-url": _vm.backUrl,
          "custom-style": _vm.customStyle
        }
      }),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "modal-fade", duration: "100", mode: "out-in" } },
        [
          _c(
            "keep-alive",
            { attrs: { include: _vm.cmptsList.join(","), max: 7 } },
            [_c("router-view")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }