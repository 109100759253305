var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-container" },
    [
      _c("data-table", {
        attrs: {
          actions: _vm.actionsList,
          "collapse-actions": "",
          columns: _vm.tableColumns,
          "table-data": _vm.customFieldsList,
          loading: _vm.loading,
          "enable-animations": _vm.customFieldsList.length <= 10,
          _class: "scrollable-table custom-scroll"
        },
        on: {
          select: _vm.viewCustomField,
          deleteCustomField: _vm.openDeletePopup
        },
        scopedSlots: _vm._u([
          {
            key: "zero-state",
            fn: function() {
              return [
                !_vm.customFieldsList.length
                  ? _c(
                      "table-zero-state",
                      {
                        attrs: {
                          title: _vm.$t(
                            "CUSTOM_TICKET_FIELDS.ZERO_STATE.TITLE"
                          ),
                          subtitle: _vm.$t(
                            "CUSTOM_TICKET_FIELDS.ZERO_STATE.SUBTITLE"
                          ),
                          "asset-link": "/brand-assets/inbox_zero_state.svg"
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex-row flex-align gap--small" },
                          [
                            _c(
                              "woot-base-button",
                              {
                                attrs: {
                                  size: "medium",
                                  "front-icon": "plus",
                                  "icon-view-box": "0 0 20 20"
                                },
                                on: { click: _vm.openAddPopup }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t(
                                        "CUSTOM_TICKET_FIELDS.HEADER_BTN_TXT"
                                      )
                                    ) +
                                    "\n          "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }