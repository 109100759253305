var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "menu-list" } }, [
    _vm.callStatesFlags.showCallNotification && !_vm.clearNotification
      ? _c("div", { staticClass: "notification" }, [
          _c(
            "span",
            { staticClass: "notification--controls flex-row gap--small" },
            [
              _c(
                "span",
                {
                  staticClass: "controls-icon",
                  on: { click: _vm.muteCallNotification }
                },
                [
                  _c("icons", {
                    attrs: {
                      name: "soundoff",
                      color: "green",
                      size: "normal",
                      "show-title": true,
                      title: "Mute"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "controls-icon",
                  on: { click: _vm.closeCallNotification }
                },
                [
                  _c("icons", {
                    attrs: {
                      name: "cross",
                      color: "red",
                      size: "normal",
                      "show-title": false
                    }
                  })
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "notification--container flex-row flex-align gap--micro"
            },
            [
              _c(
                "span",
                [
                  _c("lottie", {
                    attrs: {
                      options: _vm.defaultOptions,
                      height: 90,
                      width: 90
                    },
                    on: { animCreated: _vm.handleAnimation }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex-column flex-align--start" }, [
                _c("span", { staticClass: "text-light body-b2" }, [
                  _vm._v(
                    "\n          Please check your phone, call incoming from\n        "
                  )
                ]),
                _vm._v(" "),
                _vm.customerName
                  ? _c("span", { staticClass: "inbound-caller title-h3" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.customerName) +
                          " (" +
                          _vm._s(_vm.customerNumber) +
                          ")\n        "
                      )
                    ])
                  : _c("span", { staticClass: "inbound-caller title-h3" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.customerNumber) +
                          "\n        "
                      )
                    ])
              ])
            ]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }