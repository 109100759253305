var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sidebar-labels-wrap" },
    [
      _vm.contactLabels.length || _vm.systemLabels.length
        ? _c("div", { staticClass: "body-b3 text-light mg-top--slab" }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t("CONTACT_PANEL.ASSIGNED_TAGS.LABEL")) +
                "\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showAddButton
        ? _c(
            "span",
            {
              staticClass: "add-btn",
              on: {
                click: function($event) {
                  _vm.showLabelsModal = true
                }
              }
            },
            [
              _c("icons", {
                attrs: {
                  name: "circleAdd",
                  color: "stroke-green",
                  view: "0 0 24 24",
                  size: "medium"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: {
            show: _vm.showLabelsModal,
            "on-close": _vm.hideModal,
            "small-width": true
          },
          on: {
            "update:show": function($event) {
              _vm.showLabelsModal = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "column content-box" },
            [
              _c("woot-modal-header", {
                attrs: { "header-title": "Add Tags for Customer" }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row settings-form" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "label-container columns",
                      staticStyle: { "margin-bottom": "1.2rem" }
                    },
                    [
                      _vm._l(_vm.contactLabels, function(label) {
                        return _c("woot-chips", {
                          key: label.id,
                          attrs: {
                            title: label.title,
                            "custom-style": {
                              "border-color": _vm.labelColor(label),
                              color: _vm.labelColor(label)
                            },
                            description: label.description,
                            variant: "secondary-small",
                            "show-cancel-icon": true,
                            "show-tooltip": false
                          },
                          on: { click: _vm.onRemove }
                        })
                      }),
                      _vm._v(" "),
                      !_vm.contactLabels.length
                        ? _c("div", { staticClass: "no--label" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "CONTACT_PANEL.LABELS.NO_AVAILABLE_CONTACT_LABELS"
                                  )
                                ) +
                                "\n          "
                            )
                          ])
                        : _vm._e()
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("labels", {
                    attrs: {
                      "modal-mode": _vm.showLabelsModal,
                      "selected-tags": _vm.contactLabels,
                      "on-label-select": _vm.onSelect,
                      resource: "Contact"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      !_vm.labelUiFlags.isFetching
        ? _c("div", { staticClass: "contact-conversation--list row" }, [
            _c(
              "div",
              { staticClass: "label-container columns" },
              [
                _vm._l(_vm.labelsToShow, function(label) {
                  return _c("woot-chips", {
                    key: label.id,
                    attrs: {
                      title: label.title,
                      "custom-style": {
                        "border-color": _vm.labelColor(label),
                        color: "#3C492C"
                      },
                      description: label.description,
                      variant: "secondary-small",
                      "show-cancel-icon": !_vm.readOnly,
                      "show-tooltip": false
                    },
                    on: { click: _vm.onRemove }
                  })
                }),
                _vm._v(" "),
                !_vm.contactLabels.length
                  ? _c("div", {
                      staticClass: "body-b2 text-dark mg-bottom--small",
                      domProps: {
                        textContent: _vm._s(
                          _vm.placeholder ||
                            _vm.$t(
                              "CONTACT_PANEL.LABELS.NO_AVAILABLE_CONTACT_LABELS"
                            )
                        )
                      }
                    })
                  : _vm._e()
              ],
              2
            )
          ])
        : _c("spinner"),
      _vm._v(" "),
      !_vm.showAllLabelChips
        ? _c("div", [
            _vm.contactLabels.length > 3 || _vm.systemLabels.length
              ? _c(
                  "div",
                  {
                    staticClass: "see-more-conv body-b3",
                    on: {
                      click: function($event) {
                        _vm.showAllLabelChips = true
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n      See More\n      " +
                        _vm._s(
                          _vm.contactLabels.length > 3
                            ? "(" + (_vm.contactLabels.length - 3) + ")"
                            : ""
                        ) +
                        "\n    "
                    )
                  ]
                )
              : _vm._e()
          ])
        : _c(
            "div",
            [
              _vm._l(_vm.contactLabels.slice(3), function(label) {
                return _c("woot-chips", {
                  key: label.id,
                  attrs: {
                    title: label.title,
                    "custom-style": {
                      "border-color": _vm.labelColor(label),
                      color: "#3C492C"
                    },
                    description: label.description,
                    variant: "secondary-small",
                    "show-cancel-icon": !_vm.readOnly,
                    "show-tooltip": false
                  },
                  on: { click: _vm.onRemove }
                })
              }),
              _vm._v(" "),
              _vm.systemLabels && _vm.systemLabels.length
                ? _c("div", { staticClass: "mg-top--small" }, [
                    _c("span", { staticClass: "body-b3 text-light" }, [
                      _vm._v(_vm._s(_vm.$t("CONTACT_PANEL.SYSTEM_TAGS.LABEL")))
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "contact-conversation--list row" },
                      [
                        _vm._l(_vm.systemLabels, function(label) {
                          return _c("woot-chips", {
                            key: label.id,
                            attrs: {
                              title: label.title,
                              "custom-style": {
                                "border-color": _vm.labelColor(label),
                                color: "#3C492C"
                              },
                              description: label.description,
                              variant: "secondary-small",
                              "show-tooltip": false
                            }
                          })
                        }),
                        _vm._v(" "),
                        !_vm.systemLabels.length
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "body-b2 text-dark mg-bottom--small"
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      _vm.$t(
                                        "CONTACT_PANEL.LABELS.NO_AVAILABLE_CONTACT_LABELS"
                                      )
                                    ) +
                                    "\n        "
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      2
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "see-more-conv body-b3",
                  on: {
                    click: function($event) {
                      _vm.showAllLabelChips = false
                    }
                  }
                },
                [_vm._v("\n      See Less\n    ")]
              )
            ],
            2
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }