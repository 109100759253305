var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-row flex-justify--between gap--normal" },
    [
      _c("div", { staticClass: "lime-card-1dp full-width" }, [
        _c(
          "div",
          { staticStyle: { position: "relative" } },
          [
            _c("span", {
              staticClass: "table-title",
              domProps: {
                textContent: _vm._s(
                  _vm.$t(
                    "ANALYTICS.SHOPIFY_ANALYTICS.REVENUE.TOTAL_ORDERS.LEGEND"
                  )
                )
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "line-chart-legend title-h5" }, [
              _c("div", { staticClass: "green-icon" }),
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(
                      "ANALYTICS.SHOPIFY_ANALYTICS.REVENUE.TOTAL_ORDERS.LABEL"
                    )
                  ) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            !_vm.uiFlags.fetchingPeriodicOrdersCount
              ? _c("line-chart", {
                  attrs: {
                    "chart-height": "230",
                    series: _vm.chartSeries,
                    categories: _vm.chartCategories,
                    colors: ["#6BAC1B"],
                    "show-marker": false,
                    "toggle-data": false,
                    "highlight-data": false,
                    "show-legend": false,
                    "toolbar-offset-y": -35
                  }
                })
              : _c("line-chart-skeleton", { attrs: { "chart-height": "8rem" } })
          ],
          1
        )
      ]),
      _vm._v(" "),
      !_vm.uiFlags.fetchingCodPrepaidSplit
        ? _c(
            "div",
            {
              staticClass:
                "lime-card-1dp flex-row flex-justify flex-align pie-chart-mr"
            },
            [
              _c("pie-chart", {
                attrs: {
                  title: "Response Split",
                  series: _vm.pieSeries,
                  colors: ["#6BAC1B", "#1b83ac"],
                  labels: _vm.pieLabels,
                  height: 180,
                  "legend-position": "right",
                  "legend-offset-x": 0,
                  "legend-offset-y": -30,
                  "marker-offset-x": 0,
                  "marker-offset-y": 1,
                  "chart-offset-x": 0,
                  "chart-offset-y": 0
                }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }