var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "add-contact-wrapper" }, [
    _c(
      "div",
      { staticClass: "add-email-wrapper flex-column gap--smaller" },
      [
        _c("span", {
          staticClass: "title-h6 text-dark",
          staticStyle: { padding: "0 8px" },
          domProps: { textContent: _vm._s(_vm.title) }
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "search-contact",
            staticStyle: { "margin-bottom": "0" }
          },
          [
            _c("search-box", {
              attrs: {
                placeholder: "Search for contact email IDs here",
                "custom-class": "search-box",
                size: "medium",
                "input-type": "email",
                value: _vm.searchQuery,
                "on-search": _vm.onSearchSubmit,
                "on-enter": _vm.onAddNewContact
              },
              on: { setSearch: _vm.onInputSearch }
            }),
            _vm._v(" "),
            _c(
              "woot-base-button",
              {
                attrs: {
                  variant: "secondary",
                  size: "small",
                  disabled: !_vm.searchQuery
                },
                on: { click: _vm.onAddNewContact }
              },
              [_vm._v("\n        ADD\n      ")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "transition",
          { attrs: { name: "slide-up" } },
          [
            _vm.showSearchResults
              ? _c("show-contact-results-panel", {
                  attrs: {
                    "is-loading": _vm.isFetchingContacts,
                    "inbox-type": "email",
                    contacts: _vm.filteredContacts,
                    "on-select-contact": _vm.onAddContact,
                    "on-add-new-contact": _vm.onAddNewContact,
                    "can-load-more-results": _vm.canLoadMoreResults,
                    "close-panel": function() {
                      return (_vm.showSearchResults = false)
                    }
                  },
                  on: { onPageChange: _vm.onPageChange }
                })
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c("show-selected-emails", {
          attrs: {
            "contact-type": _vm.emailContactType,
            contacts: _vm.selectedContacts
          },
          on: { updateContacts: _vm.onUpdateContacts }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }