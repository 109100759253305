var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tbody",
    _vm._l(_vm.columns, function(idx) {
      return _c(
        "tr",
        { key: idx },
        _vm._l(_vm.columns, function(index) {
          return _c("td", { key: index }, [_c("box-skeleton")], 1)
        }),
        0
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }