var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "lime-card-1dp flex-row flex-align--start flex-justify--start email-subject-container"
    },
    [
      _c(
        "span",
        [
          _c("icons", {
            attrs: { name: "email", color: "darkestgrey", size: "medium" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("span", {
        staticClass: "mail-subject title-h4_m text-dark custom-scroll",
        domProps: { innerHTML: _vm._s(_vm.mailSubject) }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }