var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "carousel-wrapper" },
    [
      _vm._l(_vm.filteredPaginatedComponents, function(node, index) {
        return [
          _c("node-renderer", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: index === _vm.currIndex,
                expression: "index === currIndex"
              }
            ],
            key: node.key || index,
            attrs: { value: node, tag: "div" }
          })
        ]
      }),
      _vm._v(" "),
      _vm.canShowPagination
        ? [
            _c(
              "div",
              {
                staticClass:
                  "carousel-pagination carousel-pagination--left flex-row flex-align gap-normal"
              },
              [
                _c("span", {
                  staticClass: "title-h5_m text-light",
                  domProps: {
                    textContent: _vm._s(
                      _vm.currIndex + 1 + "/" + _vm.numberOfComponents
                    )
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "carousel-pagination carousel-pagination--right flex-row flex-align gap--normal"
              },
              [
                _c(
                  "woot-base-button",
                  {
                    attrs: {
                      tag: "span",
                      size: "small",
                      variant: "tertiary",
                      disabled: _vm.currIndex === 0
                    },
                    on: { click: _vm.prev }
                  },
                  [
                    _c("icons", {
                      attrs: {
                        name: "chevronLeft",
                        size: "medium",
                        color: "grey"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "woot-base-button",
                  {
                    attrs: {
                      tag: "span",
                      size: "small",
                      variant: "tertiary",
                      disabled: _vm.currIndex === _vm.numberOfComponents - 1
                    },
                    on: { click: _vm.next }
                  },
                  [
                    _c("icons", {
                      attrs: {
                        name: "chevronRight",
                        size: "medium",
                        color: "grey"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }