import { render, staticRenderFns } from "./LineChartSkeleton.vue?vue&type=template&id=452ab932&scoped=true&"
import script from "./LineChartSkeleton.vue?vue&type=script&lang=js&"
export * from "./LineChartSkeleton.vue?vue&type=script&lang=js&"
import style0 from "./LineChartSkeleton.vue?vue&type=style&index=0&id=452ab932&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "452ab932",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/limechat/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('452ab932')) {
      api.createRecord('452ab932', component.options)
    } else {
      api.reload('452ab932', component.options)
    }
    module.hot.accept("./LineChartSkeleton.vue?vue&type=template&id=452ab932&scoped=true&", function () {
      api.rerender('452ab932', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/lime-ui/dashboard/components/LineChartSkeleton.vue"
export default component.exports