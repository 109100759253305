var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "feedback flex-column gap--small" }, [
    _c(
      "div",
      {
        staticClass:
          "feedback-bubble feedback-head flex-row flex-align gap--slab"
      },
      [
        _c("icons", {
          attrs: {
            name: "file",
            size: "medium",
            color: "stroke-blue",
            "custom-style": { strokeWidth: 2 }
          }
        }),
        _vm._v(" "),
        _c("span", {
          staticClass: "body-b2 text-dark",
          domProps: {
            innerHTML: _vm._s(_vm.$t("CONVERSATION.GPT.FEEDBACK.TITLE"))
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "flex-row flex-align flex-justify--between gap--smaller" },
      [
        _c(
          "div",
          {
            staticClass:
              "feedback-btn flex-row flex-justify flex-align gap--one",
            class: { active: _vm.isNo },
            on: {
              click: function($event) {
                _vm.captureResponse(_vm.$t("CONVERSATION.GPT.FEEDBACK.NO"))
              }
            }
          },
          [
            _c("icons", {
              attrs: {
                name: "dislike",
                size: "semimedium",
                color: "primary",
                "custom-style": { strokeWidth: 2 }
              }
            }),
            _vm._v(" "),
            _c("span", {
              staticClass: "button-medium",
              domProps: {
                textContent: _vm._s(_vm.$t("CONVERSATION.GPT.FEEDBACK.NO"))
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "feedback-btn flex-row flex-justify flex-align gap--one",
            class: { active: _vm.isYes },
            on: {
              click: function($event) {
                _vm.captureResponse(_vm.$t("CONVERSATION.GPT.FEEDBACK.YES"))
              }
            }
          },
          [
            _c("icons", {
              attrs: {
                name: "like",
                size: "medium",
                color: "primary",
                "custom-style": { strokeWidth: 2 }
              }
            }),
            _vm._v(" "),
            _c("span", {
              staticClass: "button-medium",
              domProps: {
                textContent: _vm._s(_vm.$t("CONVERSATION.GPT.FEEDBACK.YES"))
              }
            })
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }