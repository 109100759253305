var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "columns small-12" },
    [
      _c("page-header", {
        attrs: {
          "header-title": _vm.$t("INBOX_MGMT.ADD.THREE_SIXTY_DIALOG.TITLE"),
          "header-content": _vm.$t("INBOX_MGMT.ADD.THREE_SIXTY_DIALOG.DESC")
        }
      }),
      _vm._v(" "),
      !_vm.ibxUiFlags.isCreating
        ? _c(
            "div",
            { staticClass: "settings-sub-menu-content--larger" },
            [
              _c("woot-input", {
                staticClass: "mg-bottom--slab",
                class: { error: _vm.$v.inboxName.$error },
                attrs: {
                  required: "",
                  "auto-focus": "",
                  "full-width": "",
                  "has-error": _vm.$v.inboxName.$error,
                  error: _vm.$t(
                    "INBOX_MGMT.ADD.THREE_SIXTY_DIALOG.CHANNEL_CREATION.INBOX_NAME.ERROR"
                  ),
                  label: _vm.$t(
                    "INBOX_MGMT.ADD.THREE_SIXTY_DIALOG.CHANNEL_CREATION.INBOX_NAME.LABEL"
                  ),
                  placeholder: _vm.$t(
                    "INBOX_MGMT.ADD.THREE_SIXTY_DIALOG.CHANNEL_CREATION.INBOX_NAME.PLACEHOLDER"
                  )
                },
                on: { blur: _vm.$v.inboxName.$touch },
                model: {
                  value: _vm.inboxName,
                  callback: function($$v) {
                    _vm.inboxName = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "inboxName"
                }
              }),
              _vm._v(" "),
              _c("woot-input", {
                staticClass: "content-margin",
                class: { error: _vm.$v.phoneNumber.$error },
                attrs: {
                  required: "",
                  "full-width": "",
                  "has-error": _vm.$v.phoneNumber.$error,
                  error: _vm.$t(
                    "INBOX_MGMT.ADD.THREE_SIXTY_DIALOG.CHANNEL_CREATION.PHONE_NUMBER.ERROR"
                  ),
                  label: _vm.$t(
                    "INBOX_MGMT.ADD.THREE_SIXTY_DIALOG.CHANNEL_CREATION.PHONE_NUMBER.LABEL"
                  ),
                  placeholder: _vm.$t(
                    "INBOX_MGMT.ADD.THREE_SIXTY_DIALOG.CHANNEL_CREATION.PHONE_NUMBER.PLACEHOLDER"
                  )
                },
                on: { blur: _vm.$v.phoneNumber.$touch },
                model: {
                  value: _vm.phoneNumber,
                  callback: function($$v) {
                    _vm.phoneNumber = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "phoneNumber"
                }
              })
            ],
            1
          )
        : _c("loading-state", {
            staticClass: "loader-cmpt",
            attrs: {
              message: _vm.$t(
                "INBOX_MGMT.ADD.THREE_SIXTY_DIALOG.CHANNEL_CREATION.LOADING"
              )
            }
          }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "medium-12 columns settings-sub-menu-bottom-nav" },
        [
          _c(
            "woot-base-button",
            {
              attrs: { variant: "secondary" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return (function() {
                    return _vm.$router.go(-1)
                  })($event)
                }
              }
            },
            [_vm._v("\n      Back\n    ")]
          ),
          _vm._v(" "),
          _c(
            "woot-base-button",
            {
              attrs: { loading: _vm.ibxUiFlags.isCreating },
              on: { click: _vm.createChannel }
            },
            [_vm._v("\n      Next\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }