var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.inbox.id
    ? _c(
        "div",
        [
          _c(
            "div",
            {
              staticClass: "inbox-viewer",
              style: { background: _vm.shadeColor }
            },
            [
              _vm.inbox.id
                ? _c(
                    "div",
                    {
                      staticClass:
                        "flex-row flex-justify mg-top--small mg-auto gap--slab"
                    },
                    [
                      _vm.isWhatsappChannel
                        ? _c("span", [
                            _c("img", {
                              attrs: {
                                width: "48",
                                src: require("dashboard/assets/images/channels/whatsapp.svg")
                              }
                            })
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isAKnowlarityInbox
                        ? _c("span", [
                            _c("img", {
                              attrs: {
                                width: "48",
                                src: require("dashboard/assets/images/channels/voice_channels.svg")
                              }
                            })
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isWhatsappChannel || _vm.isAKnowlarityInbox
                        ? _c(
                            "span",
                            [
                              _c("icons", {
                                attrs: {
                                  name: "link",
                                  color: "green",
                                  size: "medium",
                                  "show-title": false
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("span", [
                        _c("img", {
                          attrs: {
                            width: "48",
                            src: _vm.imageSrc(_vm.channelImageIdentifier)
                          }
                        })
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.inbox.name
                ? _c("span", {
                    staticClass: "inbox-name text-dark line-clamp-2 title-h3",
                    domProps: { textContent: _vm._s(_vm.inbox.name) }
                  })
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "search-container flex-row flex-justify--between gap--slab"
            },
            [
              _c("search-box", {
                attrs: {
                  placeholder: "Search for Collaborator/Status",
                  "custom-class": "search-box",
                  value: _vm.search
                },
                on: { setSearch: _vm.setSearch }
              }),
              _vm._v(" "),
              _c(
                "woot-base-button",
                {
                  attrs: {
                    tag: "span",
                    variant: "secondary",
                    disabled:
                      _vm.inPendingPaymentForNonShopify && !_vm.isEnterprise
                  },
                  on: {
                    click: function() {
                      return (_vm.showAgentPopup = true)
                    }
                  }
                },
                [
                  _c("icons", {
                    attrs: {
                      name: "plus",
                      color: "green",
                      size: "medium",
                      view: "-2 -2 24 24",
                      "show-title": false
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "flex-column",
              style: {
                "flex-direction": _vm.isAddCollaboratorsPanelOpen
                  ? "column-reverse"
                  : "column"
              }
            },
            [
              _c(
                "div",
                { staticClass: "tables-container custom-scroll" },
                [
                  _c("collaborators-table", {
                    attrs: {
                      search: _vm.search,
                      members: _vm.computedMembers,
                      inbox: _vm.inbox,
                      "is-loading": _vm.isLoading || _vm.uiFlags.isFetching,
                      "custom-table-title":
                        _vm.isAFacebookInbox && !_vm.isAddCollaboratorsPanelOpen
                          ? "Added for DMs"
                          : "",
                      "categorise-by":
                        _vm.isAFacebookInbox && !_vm.isAddCollaboratorsPanelOpen
                          ? "inbox_type"
                          : "role",
                      action: !_vm.isAddCollaboratorsPanelOpen
                        ? "remove"
                        : "add"
                    }
                  }),
                  _vm._v(" "),
                  _vm.isAFacebookInbox && !_vm.isAddCollaboratorsPanelOpen
                    ? _c("collaborators-table", {
                        attrs: {
                          search: _vm.search,
                          members: _vm.computedMembersForComments,
                          inbox: _vm.inbox,
                          "is-loading": _vm.isLoading || _vm.uiFlags.isFetching,
                          "custom-table-title": "Added for Comments",
                          action: "remove",
                          "categorise-by": "inbox_type"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _vm.isAFacebookInbox && _vm.isAddCollaboratorsPanelOpen
                ? _c(
                    "div",
                    {
                      staticClass:
                        "member-type-switch flex-row flex-justify--between gap--small full-width"
                    },
                    [
                      _c(
                        "woot-base-button",
                        {
                          attrs: {
                            variant: _vm.showCommentCollaborators
                              ? "primary"
                              : "secondary",
                            size: "small",
                            "full-width": ""
                          },
                          on: {
                            click: function($event) {
                              _vm.showCommentCollaborators = true
                            }
                          }
                        },
                        [_vm._v("\n        Comments\n      ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "woot-base-button",
                        {
                          attrs: {
                            variant: _vm.showCommentCollaborators
                              ? "secondary"
                              : "primary",
                            size: "small",
                            "full-width": ""
                          },
                          on: {
                            click: function($event) {
                              _vm.showCommentCollaborators = false
                            }
                          }
                        },
                        [_vm._v("\n        Direct Messages\n      ")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showAddCollaboratorsPanel
                ? _c(
                    "woot-base-button",
                    {
                      staticClass:
                        "custom-button flex-row flex-justify--between",
                      class: {
                        "position-absolute": !_vm.isAddCollaboratorsPanelOpen
                      },
                      attrs: {
                        "full-width": "",
                        "back-icon": _vm.isAddCollaboratorsPanelOpen
                          ? "chevronDown"
                          : "chevronTop",
                        size: "large"
                      },
                      on: { click: _vm.toggleAddCollaborators }
                    },
                    [_vm._v("\n      Add Collaborators\n    ")]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.showAgentPopup
            ? _c("add-edit-agent", {
                attrs: {
                  show: _vm.showAgentPopup,
                  "agent-data": {},
                  "inbox-list": _vm.populateInboxData
                },
                on: {
                  onClose: function() {
                    return (_vm.showAgentPopup = false)
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    : _c("educational-onboarding", {
        attrs: { "show-case-data": _vm.showCaseData }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }