var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.fetchingProjects
    ? _c("loading-state", {
        staticStyle: { height: "20rem" },
        attrs: {
          message: _vm.$t(
            "INTEGRATION_SETTINGS.CRM.INTEGRATIONS.JIRA.PROJECTS.LOADING"
          )
        }
      })
    : _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.onSubmit($event)
            }
          }
        },
        [
          _c("label", { staticClass: "formlabel" }, [
            _c("span", {
              staticClass: "title-h5 text-dark",
              domProps: {
                textContent: _vm._s(
                  _vm.$t(
                    "INTEGRATION_SETTINGS.CRM.INTEGRATIONS.JIRA.PROJECTS.TITLE"
                  )
                )
              }
            })
          ]),
          _vm._v(" "),
          _vm.selectedProject
            ? _c("woot-single-selector", {
                staticClass: "content-margin",
                attrs: {
                  "default-option": _vm.selectedProject.name,
                  "option-list": _vm.projectOptions,
                  "custom-style": { left: 0, top: 0, width: "100%" }
                },
                on: { click: _vm.onProjectSelect }
              })
            : _c("div", {
                staticClass: "body-b3 text-light content-margin",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t(
                      "INTEGRATION_SETTINGS.CRM.INTEGRATIONS.JIRA.PROJECTS.ZERO_STATE"
                    )
                  )
                }
              }),
          _vm._v(" "),
          _c("label", { staticClass: "formlabel" }, [
            _c("span", {
              staticClass: "title-h5 text-dark",
              domProps: {
                textContent: _vm._s(
                  _vm.$t(
                    "INTEGRATION_SETTINGS.CRM.INTEGRATIONS.JIRA.ISSUE_TYPES.TITLE"
                  )
                )
              }
            })
          ]),
          _vm._v(" "),
          _vm.selectedIssueType
            ? _c("woot-single-selector", {
                staticClass: "content-margin",
                attrs: {
                  "default-option": _vm.selectedIssueType.name,
                  "option-list": _vm.issueTypeOptions,
                  "custom-style": { left: 0, top: 0, width: "100%" }
                },
                on: { click: _vm.onIssueSelect }
              })
            : _c("div", {
                staticClass: "body-b3 text-light content-margin",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t(
                      "INTEGRATION_SETTINGS.CRM.INTEGRATIONS.JIRA.ISSUE_TYPES.ZERO_STATE"
                    )
                  )
                }
              }),
          _vm._v(" "),
          !_vm.fetchingSchema
            ? [
                _vm._l(_vm.formFieldNames, function(fieldName) {
                  return _c(
                    "div",
                    { key: fieldName, staticClass: "content-margin" },
                    [
                      _c("data-type-input", {
                        attrs: {
                          label: _vm.schema[fieldName].name,
                          "data-type": _vm.schema[fieldName].data_type,
                          options: _vm.schema[fieldName].allowed_values,
                          value: _vm.formData[fieldName],
                          validator: _vm.$v.formData[fieldName],
                          "update-value": function(newValue) {
                            return _vm.updateFormData(fieldName, newValue)
                          }
                        }
                      })
                    ],
                    1
                  )
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "full-width flex-row flex-justify" },
                  [
                    _c(
                      "woot-base-button",
                      {
                        attrs: {
                          type: "submit",
                          loading: _vm.uiFlags.creatingTicket,
                          disabled: _vm.$v.formData.$invalid
                        }
                      },
                      [_vm._v("\n        Submit\n      ")]
                    )
                  ],
                  1
                )
              ]
            : _c("loading-state", {
                staticStyle: { height: "8rem" },
                attrs: { width: "80", message: "" }
              })
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }