var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "transition",
        { attrs: { name: "slide-down" } },
        [
          _vm.canImproveText && _vm.hasTyped
            ? _c("improve-text", {
                attrs: { text: _vm.selectedText || _vm.message },
                on: {
                  improvedText: _vm.onReplaceText,
                  onTextImproved: _vm.onTextImproved,
                  onClose: _vm.onCloseImproveText
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "reply-box",
          class: {
            "reply-box-active": _vm.showDropZone,
            "is-focused": _vm.isFocused
          },
          style: _vm.isAnEmail && "max-height: none",
          on: {
            dragenter: function($event) {
              $event.preventDefault()
              _vm.showDropZone = true
            },
            dragleave: function($event) {
              $event.preventDefault()
              _vm.showDropZone = false
            },
            drop: function($event) {
              $event.preventDefault()
              return _vm.onDropped($event)
            },
            dragover: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "woot-modal",
            {
              attrs: {
                show: _vm.showDraftModal,
                "on-close": function() {
                  return (_vm.showDraftModal = false)
                },
                "show-close": true,
                "small-modal": true
              },
              on: {
                "update:show": function($event) {
                  _vm.showDraftModal = $event
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "modal-content-wrap" },
                [
                  _c("woot-modal-header", {
                    attrs: {
                      "header-title": "Discard draft",
                      "header-content":
                        "Your draft will be discarded. Are you sure?"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "button-container flex-row flex-justify--end"
                    },
                    [
                      _c("woot-secondary-button", {
                        attrs: {
                          size: "medium",
                          "custom-style": { width: "132px" },
                          name: "No"
                        },
                        on: {
                          click: function() {
                            return (_vm.showDraftModal = false)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("woot-primary-button", {
                        attrs: {
                          size: "medium",
                          "custom-style": { width: "132px" },
                          "custom-class": "primary-button",
                          name: "Yes"
                        },
                        on: { click: _vm.deleteDraft }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _vm.showDropZone && _vm.files.length === 0
            ? [
                _c("drop-zone", {
                  attrs: {
                    height: 200,
                    "drop-directory": true,
                    "multi-file-support": _vm.isAnEmail
                  },
                  on: { fileData: _vm.uploadedFileData }
                })
              ]
            : [
                _c(
                  "div",
                  {
                    staticClass: "reply-box__top",
                    class: { "is-private": _vm.isPrivate },
                    style: _vm.isAnEmail && "max-height: none"
                  },
                  [
                    _vm.isAPrivateEmail
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "flex-row flex-align--center private-note-head"
                          },
                          [
                            _c("icons", {
                              attrs: {
                                name: "lock",
                                color: "primary",
                                size: "medium"
                              }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "subtitle-s2" }, [
                              _vm._v("Private Note")
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showCannedResponsesList
                      ? _c("canned-response", {
                          directives: [
                            {
                              name: "on-clickaway",
                              rawName: "v-on-clickaway",
                              value: _vm.hideCannedResponse,
                              expression: "hideCannedResponse"
                            }
                          ],
                          attrs: {
                            "data-dropdown-menu": "",
                            "on-keyenter": _vm.replaceText,
                            "on-click": _vm.replaceText
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showTemplateList
                      ? _c("templates", {
                          directives: [
                            {
                              name: "on-clickaway",
                              rawName: "v-on-clickaway",
                              value: _vm.hideTemplate,
                              expression: "hideTemplate"
                            }
                          ],
                          attrs: { "data-dropdown-menu": "" },
                          on: { click: _vm.openTemplateModal }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showReplyHead
                      ? _c("reply-email-head", {
                          attrs: {
                            "cc-emails": _vm.ccEmails,
                            "bcc-emails": _vm.bccEmails,
                            "receiver-emails": _vm.toEmails
                          },
                          on: {
                            "update:ccEmails": function($event) {
                              _vm.ccEmails = $event
                            },
                            "update:cc-emails": function($event) {
                              _vm.ccEmails = $event
                            },
                            "update:bccEmails": function($event) {
                              _vm.bccEmails = $event
                            },
                            "update:bcc-emails": function($event) {
                              _vm.bccEmails = $event
                            },
                            "update:receiverEmails": function($event) {
                              _vm.toEmails = $event
                            },
                            "update:receiver-emails": function($event) {
                              _vm.toEmails = $event
                            },
                            validEmails: function($event) {
                              _vm.areEmailsValid = $event
                            }
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showEmojiPicker
                      ? _c("emoji-input", {
                          directives: [
                            {
                              name: "on-clickaway",
                              rawName: "v-on-clickaway",
                              value: _vm.hideEmojiPicker,
                              expression: "hideEmojiPicker"
                            }
                          ],
                          attrs: { "on-click": _vm.emojiOnClick }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.replyState
                      ? _c("reply-message", {
                          attrs: {
                            "get-reply-data": _vm.modifyReplyDataForReplyBox(
                              _vm.getReplyData
                            ),
                            theme: _vm.replyMessageTheme,
                            "show-close": ""
                          },
                          on: {
                            onClose: function() {
                              return this$1.$emit("toggleReply", false)
                            }
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.files.length && _vm.attachmentPreview && !_vm.isAnEmail
                      ? _c("attachment-preview", {
                          attrs: { files: _vm.files },
                          on: { clear: _vm.clearAttachments }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "reply-box-input",
                        class: {
                          "flex-direction--column-reverse": _vm.isAnEmail
                        }
                      },
                      [
                        _vm.files.length &&
                        _vm.attachmentPreview &&
                        _vm.isAnEmail
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "flex-row flex-justify--start uploaded-files-container"
                              },
                              _vm._l(_vm.files, function(file) {
                                return _c(
                                  "div",
                                  { key: file.id },
                                  [
                                    _c("email-file", {
                                      attrs: {
                                        id: "" + file.id,
                                        "attachment-type": file.type,
                                        "attachment-name": file.name,
                                        "attachment-size": file.size,
                                        "attachment-url": file.url,
                                        "can-delete": ""
                                      },
                                      on: { clear: _vm.clearAttachments }
                                    })
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("transition", { attrs: { name: "fade" } }, [
                          _vm.isEmailSignatureActive
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "email-signature flex-row flex-align--start flex-justify--start"
                                },
                                [
                                  _c("span", {
                                    staticClass: "email-signature--container",
                                    domProps: {
                                      innerHTML: _vm._s(_vm.emailSignature)
                                    }
                                  })
                                ]
                              )
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _vm.showTemplateModal
                          ? _c("template-modal", {
                              attrs: {
                                "selected-template": _vm.selectedTemplate,
                                parameters: _vm.templateParameters,
                                "template-media-url": _vm.templateMediaUrl,
                                "template-button-url": _vm.templateButtonUrl
                              },
                              on: {
                                "update:templateMediaUrl": function($event) {
                                  _vm.templateMediaUrl = $event
                                },
                                "update:template-media-url": function($event) {
                                  _vm.templateMediaUrl = $event
                                },
                                "update:templateButtonUrl": function($event) {
                                  _vm.templateButtonUrl = $event
                                },
                                "update:template-button-url": function($event) {
                                  _vm.templateButtonUrl = $event
                                },
                                setParams: _vm.setTemplateParameter,
                                close: _vm.closeTemplateModal
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isAnEmail && _vm.isPrivate
                          ? _c("resizable-text-area", {
                              ref: "messageInput",
                              staticClass: "input",
                              attrs: {
                                placeholder: _vm.messagePlaceHolder,
                                "min-height": 20,
                                "is-an-email": true
                              },
                              on: {
                                focus: _vm.onFocus,
                                blur: _vm.onBlur,
                                fileData: _vm.uploadedFileData
                              },
                              model: {
                                value: _vm.message,
                                callback: function($$v) {
                                  _vm.message = $$v
                                },
                                expression: "message"
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isAnEmail && !_vm.isPrivate
                          ? _c("text-editor", {
                              attrs: {
                                "auto-focus": "",
                                "no-border": "",
                                "is-menu-bar-required": "",
                                "is-format-mode": "",
                                "for-email": "",
                                "min-height": 20,
                                placeholder: _vm.messagePlaceHolder
                              },
                              on: { focus: _vm.onFocus, blur: _vm.onBlur },
                              model: {
                                value: _vm.message,
                                callback: function($$v) {
                                  _vm.message = $$v
                                },
                                expression: "message"
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.shouldDisplayTextArea
                          ? _c("resizable-text-area", {
                              ref: "messageInput",
                              staticClass: "input",
                              attrs: {
                                placeholder: _vm.messagePlaceHolder,
                                "min-height": 4,
                                "retain-selection-on-focus": _vm.canImproveText
                              },
                              on: {
                                focus: _vm.onFocus,
                                blur: _vm.onBlur,
                                fileData: _vm.uploadedFileData,
                                onSelectText: function(text) {
                                  return (_vm.selectedText = text)
                                }
                              },
                              model: {
                                value: _vm.message,
                                callback: function($$v) {
                                  _vm.message = $$v
                                },
                                expression: "message"
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.showTemplateModal &&
                        _vm.shouldDisplayTextArea &&
                        !_vm.isAnEmail
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "reply-box-icons flex-row flex-align gap--small"
                              },
                              [
                                !_vm.isTemplateTabActive
                                  ? _c(
                                      "span",
                                      {
                                        ref: "improveTextButton",
                                        staticClass: "reply-box-item",
                                        on: { click: _vm.improveText }
                                      },
                                      [
                                        _c("icons", {
                                          attrs: {
                                            name: "wand",
                                            size: "medium",
                                            color: _vm.message.trim().length
                                              ? "green"
                                              : "grey",
                                            "show-title": false
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip.top",
                                        value: _vm.attachmentUploadTooltip,
                                        expression: "attachmentUploadTooltip",
                                        modifiers: { top: true }
                                      }
                                    ],
                                    staticClass: "reply-box-item"
                                  },
                                  [
                                    _vm.inbox.channel_type !==
                                    "Channel::Freshchat"
                                      ? _c(
                                          "file-upload",
                                          {
                                            ref: "uploadNonEmail",
                                            class: {
                                              disabled:
                                                _vm.disableAttachmentUpload
                                            },
                                            attrs: {
                                              size: 4096 * 4096,
                                              accept:
                                                "image/*, application/pdf, audio/mpeg, video/mp4, audio/ogg, .zip, .csv"
                                            },
                                            on: {
                                              "input-file": _vm.onFileUpload
                                            },
                                            model: {
                                              value: _vm.files,
                                              callback: function($$v) {
                                                _vm.files = $$v
                                              },
                                              expression: "files"
                                            }
                                          },
                                          [
                                            !_vm.isUploading
                                              ? _c("icons", {
                                                  attrs: {
                                                    name: "clip",
                                                    size: "medium",
                                                    color: "secondaryblue",
                                                    view: "0 -4 28 28"
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.isUploading ? _c("woot-spinner") : _vm._e(),
                                _vm._v(" "),
                                _c("span", { staticClass: "reply-box-item" }, [
                                  _c("img", {
                                    staticClass: "emoji-picker",
                                    attrs: {
                                      src: require("assets/images/emoji.svg")
                                    },
                                    on: { click: _vm.toggleEmojiPicker }
                                  })
                                ])
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "reply-box__bottom flex-row flex-align",
              class: _vm.isAnEmail
                ? "flex-justify--between"
                : "flex-justify--evenly"
            },
            [
              !_vm.isAnEmail
                ? _c("ul", { staticClass: "tabs" }, [
                    _c(
                      "li",
                      {
                        staticClass: "tabs-title",
                        class: {
                          "is-active":
                            !_vm.isPrivate && !_vm.isTemplateTabActive,
                          "disable-reply-box": _vm.disableReplyButton
                        }
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "reply-tab",
                            attrs: { href: "#" },
                            on: { click: _vm.setReplyMode }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("CONVERSATION.REPLYBOX.REPLY"))
                            )
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        staticClass: "tabs-title is-private",
                        class: {
                          "is-active": _vm.isPrivate && !_vm.isTemplateTabActive
                        }
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "private-notes-tab",
                            attrs: { href: "#" },
                            on: { click: _vm.setPrivateReplyMode }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t("CONVERSATION.REPLYBOX.PRIVATE_NOTE")
                                ) +
                                "\n          "
                            )
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    !_vm.disableTemplate
                      ? _c(
                          "li",
                          {
                            staticClass: "tabs-title",
                            class: { "is-active": _vm.isTemplateTabActive }
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "templates-tab",
                                attrs: { href: "#" },
                                on: { click: _vm.setTemplateReplyMode }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t("CONVERSATION.REPLYBOX.TEMPLATE")
                                    ) +
                                    "\n          "
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        staticClass: "tabs-title body-b2 message-length",
                        class: { "show-counter": _vm.message.length }
                      },
                      [
                        _c(
                          "a",
                          {
                            class: {
                              "message-error":
                                _vm.isMessageLengthReachingThreshold
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.characterCountIndicator) +
                                "\n          "
                            )
                          ]
                        )
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.showTemplateModal &&
              !_vm.isAPrivateEmail &&
              _vm.isAnEmail &&
              !_vm.isUploading
                ? _c(
                    "div",
                    {
                      staticClass:
                        "reply-box-icons email-icons flex-row flex-align"
                    },
                    [
                      _c(
                        "file-upload",
                        {
                          ref: "uploadEmail",
                          attrs: {
                            multiple: true,
                            size: 4096 * 4096,
                            accept:
                              "image/*, application/pdf, audio/mpeg, video/mp4, audio/ogg, .zip, .csv"
                          },
                          on: { "input-file": _vm.onFileUpload },
                          model: {
                            value: _vm.files,
                            callback: function($$v) {
                              _vm.files = $$v
                            },
                            expression: "files"
                          }
                        },
                        [
                          _c("icons", {
                            attrs: {
                              name: "clip",
                              size: "semimedium",
                              color: "darkestgrey",
                              title: "Upload file(s)"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: !_vm.emailSignature && "Sign Unavailable",
                              expression:
                                "!emailSignature && 'Sign Unavailable'"
                            }
                          ],
                          style: _vm.emailSignature && "cursor: pointer;",
                          attrs: {
                            title: _vm.isEmailSignatureActive
                              ? "Remove Signature"
                              : "Add Signature"
                          },
                          on: {
                            click: function($event) {
                              _vm.hasEmailSignature = !_vm.hasEmailSignature
                            }
                          }
                        },
                        [
                          _c("icons", {
                            attrs: {
                              name: "signature",
                              size: "semimedium",
                              color: _vm.signatureColor,
                              "show-title": false
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm.isUploading
                ? _c(
                    "span",
                    { staticStyle: { "margin-left": "3rem" } },
                    [_c("woot-spinner")],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isReplyTabActive
                ? _c(
                    "div",
                    { staticClass: "mr-right flex-row" },
                    [
                      _vm.isAnEmail && !_vm.message
                        ? _c("woot-tertiary-button", {
                            attrs: {
                              "custom-class": "button clear alert",
                              size: "small",
                              name: _vm.$t("CONVERSATION.REPLYBOX.CANCEL")
                            },
                            on: { click: _vm.onCloseReplyBox }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isAnEmail && _vm.message
                        ? _c(
                            "span",
                            {
                              staticClass: "mr-right",
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function($event) {
                                  _vm.showDraftModal = true
                                }
                              }
                            },
                            [
                              _c("icons", {
                                attrs: {
                                  name: "delete",
                                  size: "semimedium",
                                  color: "red"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showReplyDropdown && !_vm.emailForwardingMode
                        ? _c("woot-dropdown-button", {
                            attrs: {
                              size: "small",
                              "is-inverted": true,
                              name: _vm.replyButtonLabel,
                              "option-list": _vm.replyOptions,
                              "button-click": _vm.sendMessage,
                              "custom-button-style": _vm.disableBtnStyle
                            },
                            on: { click: _vm.toggleReply }
                          })
                        : _c("woot-primary-button", {
                            attrs: {
                              size: "small",
                              name: _vm.emailForwardingMode
                                ? _vm.$t("CONVERSATION.REPLYBOX.FORWARD")
                                : _vm.$t("CONVERSATION.REPLYBOX.REPLY"),
                              "custom-style": _vm.disableBtnStyle
                            },
                            on: { click: _vm.sendMessage }
                          })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isAPrivateEmail ||
              _vm.isPrivateTabActive ||
              _vm.isTemplateTabActive
                ? _c(
                    "div",
                    {
                      staticClass: "flex-row flex-align",
                      class: { "flex-justify--between": _vm.isAPrivateEmail },
                      style: _vm.isAPrivateEmail && "width: 100%"
                    },
                    [
                      _vm.isAPrivateEmail
                        ? _c("woot-tertiary-button", {
                            attrs: {
                              "custom-class": "button clear alert",
                              size: "small",
                              name: _vm.$t("CONVERSATION.REPLYBOX.CANCEL")
                            },
                            on: { click: _vm.onCloseReplyBox }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isPrivateTabActive
                        ? _c("woot-primary-button", {
                            attrs: {
                              type: "button",
                              size: "small",
                              name: _vm.$t("CONVERSATION.REPLYBOX.CREATE"),
                              "custom-class":
                                "button send-button " +
                                (_vm.isPrivateTabActive && "private-button"),
                              disabled:
                                _vm.isReplyButtonDisabled && !_vm.files.length
                            },
                            on: { click: _vm.sendMessage }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isTemplateTabActive
                        ? _c("woot-primary-button", {
                            attrs: {
                              size: "small",
                              name: _vm.$t("CONVERSATION.REPLYBOX.SEND"),
                              "custom-class": "button send-button",
                              "custom-style": _vm.shopifyRestrictionCheck
                                ? {
                                    "pointer-events": "auto",
                                    opacity: 0.5
                                  }
                                : undefined,
                              disabled: !_vm.isTemplateReady
                            },
                            on: { click: _vm.sendTemplate }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c("follow-up-modal", {
            attrs: {
              show: _vm.showFollowupModal,
              "on-close": _vm.onCancel,
              "on-click": _vm.updateStatus
            }
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }