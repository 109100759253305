var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip",
          value: _vm.disabled ? _vm.title : null,
          expression: "disabled ? title : null"
        }
      ]
    },
    [
      _c("input", {
        staticClass: "toggle--checkbox",
        class: { disabled: _vm.disabled },
        attrs: { id: _vm.id, type: "checkbox", disabled: _vm.disabled },
        domProps: { value: _vm.value, checked: _vm.value },
        on: {
          input: function($event) {
            return _vm.$emit("click", $event.target.value)
          }
        }
      }),
      _vm._v(" "),
      _c("label", {
        staticClass: "lime-switch",
        class: { "disabled-switch": _vm.disabled },
        attrs: { for: _vm.id }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }