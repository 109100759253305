var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "csat--table-container" },
    [
      _vm.uiFlags.isFetching
        ? _c("table-skeleton")
        : _c(
            "div",
            { staticClass: "medium-12 bottom-container" },
            [
              _c("woot-table", {
                staticClass: "lime-card-1dp",
                attrs: {
                  title: "BOT CSAT Summary",
                  "header-list": _vm.$t("ANALYTICS.BOT_CSAT_HEADER"),
                  "table-data": _vm.tableData
                }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }