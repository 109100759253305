var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "conversation flex-row flex-justify--between gap--normal",
      on: { click: _vm.navigateToConversation }
    },
    [
      _c(
        "div",
        { staticClass: "contact-avatar" },
        [
          _c("thumbnail", {
            attrs: {
              src: _vm.data.thumbnail,
              username: _vm.data.name,
              "custom-style": { border: "none", fontSize: "1.4rem" },
              status: _vm.data.availability_status,
              "bg-color": "#F5F5F5",
              color: "#5B8FF9",
              size: "36px"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "contact-name info-container full-width" }, [
        _c("span", {
          staticClass: "title-h5 text-dark line-clamp-1 text-align--left",
          domProps: { textContent: _vm._s(_vm.data.name) }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "info-container" }, [
        _vm.data.phone_number
          ? _c("div", {
              staticClass: "body-b2 text-dark text-align--right",
              domProps: { textContent: _vm._s(_vm.data.phone_number) }
            })
          : _vm._e(),
        _vm._v(" "),
        _c("div", {
          staticClass: "body-b3 line-clamp-1 text-light text-align--right",
          domProps: { textContent: _vm._s(_vm.data.email || "N/A") }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }