var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-column", staticStyle: { height: "100vh" } },
    [
      _c(
        "transition",
        { attrs: { name: "modal-fade" } },
        [
          _vm.isAdmin && _vm.showIntegratedOnboardingModal
            ? _c("three-sixty-onboarding-modal", {
                attrs: {
                  inbox: _vm.latestInProgressThreeSixtyInbox,
                  screen: _vm.currentOnboardingScreen
                },
                on: {
                  updateCurrentScreen: function(value) {
                    return (_vm.currentOnboardingScreen = value)
                  },
                  closeModal: _vm.onCloseOnboardingModal
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("onboard-modal", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isAdmin && _vm.showOnboardingModal,
            expression: "!isAdmin && showOnboardingModal"
          }
        ],
        on: { closeModal: _vm.onOnboardModalClose }
      }),
      _vm._v(" "),
      _vm.userRole && _vm.showSectionIntroTour
        ? _c("onboarding-tour", {
            attrs: {
              "tour-name": "sectionIntroduction",
              "tour-version": _vm.sectionIntroTourSteps.TOUR_VERSION,
              "tour-steps": _vm.sectionIntroSteps
            },
            on: { unmountTour: _vm.onCloseSectionIntroTour }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showChatSectionTour
        ? _c("onboarding-tour", {
            attrs: {
              "tour-name": "chatSection",
              "tour-version": _vm.chatSectionSteps.TOUR_VERSION,
              "tour-steps": _vm.chatSectionSteps.STEPS
            },
            on: {
              unmountTour: function($event) {
                _vm.showChatSectionTour = false
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("feature-intro-modal", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showFeatureIntroModal,
            expression: "showFeatureIntroModal"
          }
        ],
        on: { closeModal: _vm.onFeatureIntroModalClose }
      }),
      _vm._v(" "),
      _c("shopify-restriction-modal", {
        attrs: { content: _vm.shopifyBannerMsg, type: _vm.shopifyModalType }
      }),
      _vm._v(" "),
      !_vm.showBanners
        ? _c(
            "render-in-carousel",
            [
              _vm.isShopifyBilling
                ? [
                    _vm.showBanner
                      ? _c("top-banner", { attrs: { variant: "danger" } }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex-row flex-align flex-justify full-width"
                            },
                            [
                              _c("span", {
                                staticClass: "subtitle-s2",
                                staticStyle: { "max-width": "80%" },
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.shopifyBannerMsg.content
                                  )
                                }
                              }),
                              _vm._v(" "),
                              _vm.shopifyBannerMsg &&
                              _vm.shopifyBannerMsg.show_cta &&
                              _vm.isAdmin
                                ? _c(
                                    "div",
                                    { staticClass: "alert-text" },
                                    [
                                      _c(
                                        "woot-base-button",
                                        {
                                          attrs: { size: "small" },
                                          on: {
                                            click: function($event) {
                                              return _vm.$router.push(
                                                "/app/accounts/" +
                                                  _vm.$route.params.accountId +
                                                  "/settings/billing_plan/choose_billing_plan"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n              browse plans\n            "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.currentAccount && _vm.currentAccount.payment_pending
                      ? _c("top-banner", { attrs: { variant: "danger" } }, [
                          _c("span", {
                            staticClass: "subtitle-s2",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("CONVERSATION.DISABLE_HELPDESK")
                              )
                            }
                          })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showTrialBanner
                      ? _c("top-banner", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex-row flex-align flex-justify full-width"
                            },
                            [
                              _c("div", { staticClass: "subtitle-s2" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t("BILLING.FREE_TRIAL_BANNER")
                                    ) +
                                    "\n            "
                                ),
                                _c("span", {
                                  staticClass: "title-h5",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.shopifyFreeTrialEndDate + "."
                                    )
                                  }
                                })
                              ]),
                              _vm._v(" "),
                              _c("div", {
                                staticClass:
                                  "title-h3 mg-left--medium alert-text",
                                domProps: {
                                  textContent: _vm._s(_vm.shopifyTrialDays)
                                }
                              })
                            ]
                          )
                        ])
                      : _vm._e()
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.showBillingBannerForNonShopify
                ? _c("non-shopify-billing-banner")
                : _vm._e(),
              _vm._v(" "),
              _vm.showInboxAuthStatusBanner
                ? _c("inbox-authorization-status")
                : _vm._e(),
              _vm._v(" "),
              _vm.showOnboardingBannerComputed
                ? _c(
                    "top-banner",
                    {
                      attrs: { variant: _vm.onboardingProgressStatus.variant }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "text-align flex-row flex-justify flex-align full-width"
                        },
                        [
                          _c("div", {
                            key: _vm.onboardingProgressStatus.content,
                            staticClass:
                              "body-b2 text-align shake line-clamp-1",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.onboardingProgressStatus.content
                              )
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "woot-base-button",
                            {
                              staticClass: "mg-left",
                              attrs: { size: "small" },
                              on: {
                                click: function($event) {
                                  _vm.showIntegratedOnboardingModal = true
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.$t(
                                      "ONBOARDING.WHATSAPP.WABA_INTEGRATION_COMPLETE.CONTINUE"
                                    )
                                  ) +
                                  "\n        "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row app-wrapper" },
        [
          _c("navbar", { attrs: { route: _vm.currentRoute } }),
          _vm._v(" "),
          _c(
            "section",
            { staticClass: "app-content columns" },
            [_c("router-view")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }