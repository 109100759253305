var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lime-card-1dp medium-12" },
    [
      _c("span", { staticClass: "table-title" }, [
        _vm._v("Agent Attendance Data")
      ]),
      _vm._v(" "),
      _c("table", { staticClass: "metrics-table" }, [
        _c(
          "tr",
          _vm._l(_vm.headerList, function(item) {
            return _c("th", { key: item.id }, [
              _c(
                "div",
                {
                  staticClass: "table-header",
                  on: {
                    click: function($event) {
                      return _vm.sortBy(item.id)
                    }
                  }
                },
                [
                  _c("icons", {
                    attrs: { name: "sort", color: "green", size: "semimedium" }
                  }),
                  _vm._v(" "),
                  _c("span", { staticStyle: { "margin-left": "0.8rem" } }, [
                    _vm._v(_vm._s(item.name))
                  ])
                ],
                1
              )
            ])
          }),
          0
        ),
        _vm._v(" "),
        !_vm.uiFlag && _vm.attendanceMetrics.length
          ? _c(
              "tbody",
              _vm._l(_vm.sortedAttendanceMetrics, function(item, index) {
                return _c(
                  "tr",
                  {
                    key: index,
                    on: {
                      click: function($event) {
                        return _vm.$emit("select-agent", item.id)
                      }
                    }
                  },
                  [
                    _c("td", { staticStyle: { width: "30%" } }, [
                      _vm._v("\n          " + _vm._s(item.name) + "\n        ")
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        class:
                          "status-color status-color__" +
                          item.current_availability
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(item.current_availability || "-") +
                            "\n        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.humanReadableTime(item.last_login)) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.humanReadableTime(item.last_logout)) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.formattedDuration(
                            _vm.secondsToDuration(
                              parseInt(item.active_time * 60, 10)
                            )
                          )
                        )
                      }
                    }),
                    _vm._v(" "),
                    _c("td", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.formattedDuration(
                            _vm.secondsToDuration(
                              parseInt(item.break_time * 60, 10)
                            )
                          )
                        )
                      }
                    })
                  ]
                )
              }),
              0
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      !_vm.attendanceMetrics.length && !_vm.uiFlag
        ? _c("p", { staticClass: "no-items-error-message" }, [
            _vm._v("\n    " + _vm._s("No data found") + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.uiFlag
        ? _c("woot-loading-state", {
            attrs: { message: "Fetching agent attendance" }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }