var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    {
      attrs: { show: _vm.show, "on-close": _vm.onClose },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c("page-header", {
        attrs: {
          "header-title": _vm.$t("CONVERSATION.SEARCH.TITLE"),
          "show-border": false
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "search-feature" }, [
        _c(
          "div",
          { staticClass: "search-bar flex-row flex-align" },
          [
            _vm.searchByFilter
              ? _c(
                  "div",
                  { staticClass: "search-by-filter" },
                  [
                    _c(
                      "woot-base-button",
                      {
                        attrs: {
                          size: "small",
                          "front-icon": _vm.searchByFilter.icon,
                          "back-icon": "cross"
                        },
                        on: { click: _vm.removeFilter }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.searchByFilter.name) +
                            "\n        "
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("search-box", {
              ref: "searchBox",
              staticClass: "search-box",
              attrs: {
                variant: "",
                placeholder: _vm.$t("CONVERSATION.SEARCH.PLACEHOLDER"),
                value: _vm.searchTermComputed
              },
              on: { setSearch: _vm.setSearch }
            })
          ],
          1
        ),
        _vm._v(" "),
        !_vm.searchByFilter
          ? _c("div", { staticClass: "filters-section" }, [
              _c("div", {
                staticClass: "text-light body-b2 mg-bottom--small",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("CONVERSATION.SEARCH.SEARCH_BY.HEADING")
                  )
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "search-by-options flex-row gap--small" },
                _vm._l(_vm.SEARCH_BY_OPTIONS, function(option) {
                  return _c(
                    "woot-base-button",
                    {
                      key: option.name,
                      staticClass: "search-by-btn",
                      attrs: {
                        size: "small",
                        variant: "secondary",
                        "front-icon": option.icon
                      },
                      on: {
                        click: function($event) {
                          return _vm.applySearchByFilter(option)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(option.name) + "\n        "
                      )
                    ]
                  )
                }),
                1
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.showFilterByOptions
          ? _c("div", { staticClass: "filters-section" }, [
              _c("div", {
                staticClass: "text-light body-b2 mg-bottom--small",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("CONVERSATION.SEARCH.FILTER_BY.HEADING")
                  )
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "filters flex-row flex-align--start gap--small"
                },
                [
                  _c("status-time-filter", {
                    attrs: { "default-selection": _vm.defaultRangeSelection },
                    on: { "date-range-change": _vm.onDateRangeChange }
                  }),
                  _vm._v(" "),
                  _c("woot-single-selector", {
                    attrs: {
                      variant: "secondary",
                      size: "small",
                      "front-icon": "inbox",
                      "default-option": _vm.selectedInboxName,
                      "option-list": _vm.inboxList,
                      "show-selected-text": false,
                      "custom-style": { left: 0, width: "20rem" },
                      "button-style": {
                        color: "#808975",
                        padding: "0.4rem 0.6rem"
                      }
                    },
                    on: { click: _vm.applyInboxFilter }
                  })
                ],
                1
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "search-results-container" }, [
          _c("div", {
            staticClass: "text-light body-b2 mg-bottom--small",
            domProps: {
              textContent: _vm._s(_vm.$t("CONVERSATION.SEARCH.RESULTS.HEADING"))
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "results custom-scroll" },
            [
              _c(
                "transition",
                { attrs: { name: "modal-fade", mode: "out-in", appear: "" } },
                [
                  !_vm.conversations.length &&
                  !_vm.hasSearched &&
                  !_vm.uiFlags.isFetching
                    ? _c("img", {
                        staticClass: "zero-state",
                        attrs: {
                          src: require("dashboard/assets/images/search_zero_state.svg"),
                          alt: "search-for-results"
                        }
                      })
                    : _vm.uiFlags.isFetching
                    ? _c("loading-state", {
                        staticClass: "loading-state",
                        attrs: { message: _vm.loadingMessage }
                      })
                    : _vm.showConversationCards
                    ? _c(
                        "div",
                        { staticClass: "result-cards" },
                        _vm._l(_vm.conversations, function(result) {
                          return _c("conversation-details-card", {
                            key: result.id,
                            attrs: {
                              identifier: result.conversation.identifier,
                              "assignee-name": result.conversation.agent_name,
                              "contact-name": result.conversation.contact_name,
                              "display-id": result.conversation.display_id,
                              content: result.content,
                              data: result,
                              "search-term": _vm.searchTermComputed,
                              "contact-id": result.id
                            },
                            on: {
                              routeNavigated: function($event) {
                                return _vm.onClose(
                                  result.conversation.display_id
                                )
                              }
                            }
                          })
                        }),
                        1
                      )
                    : _vm.showContactCards
                    ? _c(
                        "div",
                        { staticClass: "result-cards contact-cards" },
                        _vm._l(_vm.conversations, function(conversation) {
                          return _c("contact-details-card", {
                            key: conversation.id,
                            attrs: {
                              data: conversation,
                              "search-term": _vm.searchTermComputed
                            },
                            on: {
                              routeNavigated: function(conversationid) {
                                return _vm.onClose(conversationid)
                              }
                            }
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.uiFlags.isFetching &&
                  _vm.hasSearched &&
                  !_vm.conversations.length
                    ? _c("img", {
                        staticClass: "zero-state",
                        attrs: {
                          src: require("dashboard/assets/images/search_not_found.svg"),
                          alt: "no-results-found"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "transition",
                { attrs: { name: "modal-fade", mode: "out-in", appear: "" } },
                [
                  _vm.showLoadMore
                    ? _c(
                        "div",
                        { staticClass: "flex-row flex-justify" },
                        [
                          _c(
                            "woot-base-button",
                            {
                              attrs: { variant: "tertiary", size: "small" },
                              on: { click: _vm.loadMoreResults }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      "CONVERSATION.SEARCH.RESULTS.LOAD_MORE"
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showEndOfResultsText
                    ? _c("div", {
                        staticClass: "text-center body-b2 text-light",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("CONVERSATION.SEARCH.RESULTS.END_OF_RESULTS")
                          )
                        }
                      })
                    : _vm._e()
                ]
              )
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "medium-12 columns settings-sub-menu-bottom-nav" },
        [
          _c(
            "woot-base-button",
            {
              attrs: { size: "small", variant: "secondary-danger" },
              on: { click: _vm.resetAllFilters }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("CONVERSATION.SEARCH.RESET")) +
                  "\n    "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "woot-base-button",
            {
              attrs: { size: "small", disabled: _vm.uiFlags.isFetching },
              on: { click: _vm.saveSearch }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("CONVERSATION.SEARCH.APPLY.TITLE")) +
                  "\n    "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }