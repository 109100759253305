var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isFooterVisible
    ? _c("footer", { staticClass: "footer" }, [
        _c("div", { staticClass: "left-aligned-wrap" }, [
          _c("div", { staticClass: "page-meta" }, [
            _c("strong", [_vm._v(_vm._s(_vm.firstIndex))]),
            _vm._v("\n      - "),
            _c("strong", [_vm._v(_vm._s(_vm.lastIndex))]),
            _vm._v(" of\n      "),
            _c("strong", [_vm._v(_vm._s(_vm.totalCount))]),
            _vm._v(" items\n    ")
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "right-aligned-wrap" }, [
          _vm.totalCount
            ? _c(
                "div",
                { staticClass: "primary button-group pagination-button-group" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "button small goto-first",
                      class: _vm.firstPageButtonClass,
                      on: { click: _vm.onFirstPage }
                    },
                    [
                      _c("i", { staticClass: "ion-chevron-left" }),
                      _vm._v(" "),
                      _c("i", { staticClass: "ion-chevron-left" })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "button small",
                      class: _vm.prevPageButtonClass,
                      on: { click: _vm.onPrevPage }
                    },
                    [_c("i", { staticClass: "ion-chevron-left" })]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "button",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.currentPage) + "\n      "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "button small",
                      class: _vm.nextPageButtonClass,
                      on: { click: _vm.onNextPage }
                    },
                    [_c("i", { staticClass: "ion-chevron-right" })]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "button small goto-last",
                      class: _vm.lastPageButtonClass,
                      on: { click: _vm.onLastPage }
                    },
                    [
                      _c("i", { staticClass: "ion-chevron-right" }),
                      _vm._v(" "),
                      _c("i", { staticClass: "ion-chevron-right" })
                    ]
                  )
                ]
              )
            : _vm._e()
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }