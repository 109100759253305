var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box settings-box no-padding" },
    [
      _c("woot-tertiary-button", {
        attrs: {
          "front-icon": "addFilled",
          "layer-fill": "white",
          name: _vm.$t("INTEGRATION_SETTINGS.WEBHOOK.HEADER_BTN_TXT"),
          "custom-class": "settings-button"
        },
        on: {
          click: function($event) {
            return _vm.openAddPopup()
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "settings-container" },
        [
          _c(
            "div",
            { staticClass: "column settings-main-menu" },
            [
              !_vm.uiFlags.fetchingList && !_vm.records.length
                ? _c("p", { staticClass: "no-items-error-message" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t("INTEGRATION_SETTINGS.WEBHOOK.LIST.404")
                        ) +
                        "\n      "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.uiFlags.fetchingList
                ? _c("woot-loading-state", {
                    attrs: {
                      message: _vm.$t("INTEGRATION_SETTINGS.WEBHOOK.LOADING")
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.uiFlags.fetchingList && _vm.records.length
                ? _c("table", { staticClass: "woot-table" }, [
                    _c(
                      "thead",
                      _vm._l(
                        _vm.$t(
                          "INTEGRATION_SETTINGS.WEBHOOK.LIST.TABLE_HEADER"
                        ),
                        function(thHeader) {
                          return _c(
                            "th",
                            {
                              key: thHeader,
                              style: _vm.getComputedStyle(thHeader)
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(thHeader) +
                                  "\n          "
                              )
                            ]
                          )
                        }
                      ),
                      0
                    ),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.records, function(webHookItem) {
                        return _c("tr", { key: webHookItem.id }, [
                          _c("td", [
                            _c("div", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(webHookItem.url) +
                                  "\n              "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              staticClass: "flex-row flex-justify--start",
                              staticStyle: { margin: "1.2rem 2rem" }
                            },
                            [
                              webHookItem.status === "inactive"
                                ? _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value:
                                            webHookItem.unsubscription_reason,
                                          expression:
                                            "webHookItem.unsubscription_reason"
                                        }
                                      ]
                                    },
                                    [
                                      _c("icons", {
                                        attrs: {
                                          name: "error",
                                          color: "danger",
                                          size: "semimedium",
                                          "show-title": false
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    [
                                      _c("icons", {
                                        attrs: {
                                          name: "checked",
                                          color: "green",
                                          size: "medium",
                                          "show-title": false
                                        }
                                      })
                                    ],
                                    1
                                  )
                            ]
                          ),
                          _vm._v(" "),
                          _c("td", [
                            _c(
                              "div",
                              { staticClass: "button-wrapper" },
                              [
                                _c(
                                  "woot-base-button",
                                  {
                                    attrs: { tag: "span", variant: "tertiary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.openEditPopup(webHookItem)
                                      }
                                    }
                                  },
                                  [
                                    _c("icons", {
                                      attrs: {
                                        title: "edit",
                                        name: "editRound",
                                        size: "medium",
                                        color: "textlightgrey"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "woot-base-button",
                                  {
                                    attrs: { tag: "span", variant: "tertiary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.openDeletePopup(webHookItem)
                                      }
                                    }
                                  },
                                  [
                                    _c("icons", {
                                      attrs: {
                                        name: "delete",
                                        size: "medium",
                                        color: "textlightgrey"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        ])
                      }),
                      0
                    )
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("settings-side-card", { attrs: { image: "inbox" } }, [
            _c("span", { staticClass: "info-card-header" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("INTEGRATION_SETTINGS.WEBHOOK.SIDEBAR_QUES")) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c("span", {
              staticClass: "info-card-content",
              domProps: {
                innerHTML: _vm._s(
                  _vm.useInstallationName(
                    _vm.$t("INTEGRATION_SETTINGS.WEBHOOK.SIDEBAR_TXT")
                  )
                )
              }
            })
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: { show: _vm.showAddPopup, "on-close": _vm.hideAddPopup },
          on: {
            "update:show": function($event) {
              _vm.showAddPopup = $event
            }
          }
        },
        [_c("new-webhook", { attrs: { "on-close": _vm.hideAddPopup } })],
        1
      ),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: { show: _vm.showEditPopup, "on-close": _vm.hideEditPopup },
          on: {
            "update:show": function($event) {
              _vm.showEditPopup = $event
            }
          }
        },
        [
          _c("edit-webhook", {
            attrs: {
              id: _vm.selectedWebHook.id,
              inboxes: _vm.selectedWebHook.inboxes,
              show: _vm.showEditPopup,
              editurl: _vm.selectedWebHook.url,
              editsubscriptions: _vm.selectedWebHook.subscriptions,
              editmessagetypes: _vm.selectedWebHook.message_types,
              "on-close": _vm.hideEditPopup
            },
            on: {
              "update:show": function($event) {
                _vm.showEditPopup = $event
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("woot-delete-modal", {
        attrs: {
          show: _vm.showDeleteConfirmationPopup,
          "on-close": _vm.closeDeletePopup,
          "on-confirm": _vm.confirmDeletion,
          title: _vm.$t("INTEGRATION_SETTINGS.WEBHOOK.DELETE.CONFIRM.TITLE"),
          message: _vm.$t(
            "INTEGRATION_SETTINGS.WEBHOOK.DELETE.CONFIRM.MESSAGE"
          ),
          "confirm-text": _vm.$t(
            "INTEGRATION_SETTINGS.WEBHOOK.DELETE.CONFIRM.YES"
          ),
          "reject-text": _vm.$t(
            "INTEGRATION_SETTINGS.WEBHOOK.DELETE.CONFIRM.NO"
          )
        },
        on: {
          "update:show": function($event) {
            _vm.showDeleteConfirmationPopup = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }