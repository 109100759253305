var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view-box columns" },
    [
      _c("settings-header", {
        attrs: {
          icon: "megaphone",
          "header-title": _vm.$t("BROADCAST.HEADER"),
          "show-back-button": false
        }
      }),
      _vm._v(" "),
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "center-aligned" },
            [
              _c("woot-loading-state", {
                attrs: {
                  message: _vm.$t("BROADCAST.BROADCAST_TAB.LOADING_TEXT")
                }
              })
            ],
            1
          )
        : _c("div", { staticClass: "settings-box" }, [
            _c("div", { staticClass: "campaign-name flex-row-center" }, [
              _c(
                "span",
                { staticClass: "back-button", on: { click: _vm.navigateBack } },
                [
                  _c("icons", {
                    attrs: {
                      name: "chevronLeft",
                      color: "green",
                      size: "large"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.campaignName,
                    expression: "campaignName"
                  }
                ],
                staticClass: "broadcast-input title-h1 text-light",
                attrs: {
                  type: "text",
                  placeholder: "Enter Your Campaign Name"
                },
                domProps: { value: _vm.campaignName },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.campaignName = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "lime-card-1dp broadcast-container custom-scroll"
              },
              [
                _c(
                  "div",
                  { staticClass: "mg-bottom--medium" },
                  [
                    _c("woot-folder-tabs", {
                      attrs: { active: _vm.step, "tab-list": _vm.tabList }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "keep-alive",
                  [
                    _vm.step === 0
                      ? _c("select-template", { on: { next: _vm.nextStep } })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.step === 1
                      ? _c("select-users", {
                          attrs: {
                            "selected-template": _vm.selectedTemplate,
                            "params-count": _vm.templateParameterCount,
                            "needs-media-header": _vm.needsMediaHeader,
                            "opt-out-list-prop": _vm.optOutListProp
                          },
                          on: {
                            next: _vm.nextStepAfterCSVUpload,
                            prev: _vm.prevStepBeforeCSVUpload
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.step === 2
                      ? _c("confirm-test", {
                          attrs: {
                            "selected-template": _vm.selectedTemplate,
                            "selected-inbox": _vm.selectedInbox,
                            "first-row": _vm.firstRow,
                            "params-count": _vm.templateParameterCount
                          },
                          on: {
                            testMessage: _vm.sendTestMessage,
                            sendBrd: _vm.sendMsgConfirmation,
                            prev: _vm.prevStepBeforeBroadcast,
                            scheduleBrd: _vm.scheduleBroadcast
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c("woot-delete-modal", {
                  attrs: {
                    show: _vm.showConfirmationModal,
                    "on-close": _vm.closeModal,
                    "on-confirm": _vm.send,
                    message:
                      "You are about to send this Broadcast message to " +
                      _vm.phoneNumberList.length +
                      " people. Would you like to confirm?",
                    "confirm-text": _vm.$t(
                      "BROADCAST.BROADCAST_TAB.UPLOAD_CSV.CONTINUE"
                    ),
                    "reject-text": _vm.$t(
                      "BROADCAST.BROADCAST_TAB.UPLOAD_CSV.BACK"
                    )
                  },
                  on: {
                    "update:show": function($event) {
                      _vm.showConfirmationModal = $event
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "woot-modal",
                  {
                    attrs: {
                      show: _vm.showSuccessModal,
                      "show-close": false,
                      "small-modal": ""
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "flex-colum-center",
                        staticStyle: { padding: "3.6rem" }
                      },
                      [
                        _c(
                          "span",
                          [
                            _c("lottie", {
                              attrs: {
                                options: _vm.defaultOptions,
                                height: 90,
                                width: 90
                              },
                              on: { animCreated: _vm.handleAnimation }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "title-h1 text-dark mg-top" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.successModalContent) +
                                "\n          "
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c("schedule-broadcast", {
                  attrs: {
                    show: _vm.showScheduleModal,
                    "on-cancel": _vm.closeScheduleModal,
                    "on-save-and-schedule": _vm.convertToTimeStamp
                  }
                })
              ],
              1
            )
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }