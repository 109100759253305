var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.onSubmit($event)
        }
      }
    },
    [
      _vm._t("default"),
      _vm._v(" "),
      _vm._l(_vm.formFields, function(field, key) {
        return _c("woot-input", {
          key: key,
          staticClass: "content-margin",
          class: { error: _vm.$v.formData[key].$error },
          attrs: {
            rows: key === "description" ? 5 : undefined,
            required: field.required,
            label: _vm.$t(field.label),
            placeholder: _vm.$t(field.placeholder),
            "has-error": _vm.$v.formData[key].$error,
            error: _vm.$t(field.error)
          },
          on: { blur: _vm.$v.formData[key].$touch },
          model: {
            value: _vm.formData[key],
            callback: function($$v) {
              _vm.$set(
                _vm.formData,
                key,
                typeof $$v === "string" ? $$v.trim() : $$v
              )
            },
            expression: "formData[key]"
          }
        })
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content-margin" },
        [
          _vm.activeLabelList.length
            ? _c(
                "label",
                {
                  staticClass: "formlabel",
                  staticStyle: { "margin-bottom": "0" }
                },
                [
                  _c("span", {
                    staticClass: "title-h5 text-dark",
                    domProps: { textContent: _vm._s("Tags") }
                  })
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("conversation-labels", {
            attrs: {
              "conversation-id": _vm.conversationId,
              "selected-labels": _vm.selectedLabels,
              labels: _vm.activeLabelList,
              "read-only": ""
            },
            on: { updateLabels: _vm.UpdateLabels }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.canUploadMedia
        ? [
            _vm.uploadingAttachment
              ? _c("span", {
                  staticClass: "body-b3 text-light",
                  domProps: { textContent: _vm._s("Uploading...") }
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "attachment-container flex-row flex-wrap gap--small mg-bottom--small"
              },
              _vm._l(_vm.allAttachments, function(img) {
                return _c("email-file", {
                  key: img,
                  attrs: {
                    id: img,
                    "attachment-type": "image",
                    "show-img-modal": "",
                    "attachment-url": img,
                    "can-delete": ""
                  },
                  on: { clear: _vm.deleteAttachment }
                })
              }),
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "flex-column content-margin" }, [
              _c(
                "div",
                { staticClass: "attachment-holder flex-space-between" },
                [
                  _c("label", { staticClass: "formlabel" }, [
                    _c("span", {
                      staticClass: "title-h5 text-dark",
                      domProps: { textContent: _vm._s("Attach Images") }
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "label",
                    [
                      _c("icons", {
                        attrs: {
                          name: "circleAdd",
                          color: "stroke-green",
                          view: "0 0 24 24",
                          size: "medium"
                        }
                      }),
                      _vm._v(" "),
                      _c("input", {
                        ref: "file",
                        staticClass: "form-input",
                        attrs: { type: "file" },
                        on: { change: _vm.uploadFile }
                      })
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c("span", {
                staticClass:
                  "mg-bottom--small body-b3 text-light mg-left--micro",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t(
                      "INTEGRATION_SETTINGS.CRM.INTEGRATIONS.COMMON.WARNINGS.ATTACHMENT_WARNING"
                    )
                  )
                }
              })
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "full-width flex-row flex-justify" },
        [
          _c(
            "woot-base-button",
            {
              attrs: {
                type: "submit",
                loading: _vm.uiFlags.creatingTicket,
                disabled: _vm.$v.$invalid
              }
            },
            [_vm._v("\n      Submit\n    ")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }