var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "column page-top-bar",
      style: { backgroundImage: "url(" + _vm.headerBg + ")" }
    },
    [
      _vm.headerTitle
        ? _c("span", {
            staticClass: "title-h5 text-light",
            domProps: { textContent: _vm._s(_vm.headerTitle) }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.headerContent
        ? _c("p", {
            staticClass: "small-12 column header-content title-h5_m text-dark",
            domProps: { innerHTML: _vm._s(_vm.headerContent) }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }