var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "PiiMaskWrapper",
    _vm._b(
      {
        attrs: {
          "user-has-permission":
            _vm.userHasPermission && _vm.accountOptedContactMasking,
          "mask-state": _vm.effectiveMaskState
        },
        on: { update: _vm.updateData },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function(slotProps) {
                return [_vm._t("default", null, null, slotProps)]
              }
            }
          ],
          null,
          true
        )
      },
      "PiiMaskWrapper",
      _vm.$props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }