var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("woot-switch", {
    staticStyle: { margin: "-1rem" },
    attrs: { id: _vm.id, value: _vm.enabled },
    on: { click: _vm.toggleFieldStatus }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }