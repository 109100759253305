var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("woot-multi-selector", {
    ref: "multiSelector",
    attrs: {
      "default-key": "title",
      variant: "secondary",
      "show-search": "",
      "highlight-selected": "",
      size: _vm.size,
      "custom-button": _vm.customButton,
      "static-title": _vm.staticTitle,
      "default-option": _vm.value,
      "front-icon": _vm.frontIcon,
      "option-list": _vm.filteredLabels,
      "collapse-selected": _vm.collapseSelected,
      "custom-style": _vm.customStyle,
      "show-selected-text": _vm.showSelectedText,
      "show-option-tooltip": _vm.showOptionTooltip,
      "button-style": _vm.buttonStyle,
      "toggle-filter": _vm.toggleFilter,
      "search-placeholder": _vm.$t("LABEL_MGMT.SEARCH_TXT")
    },
    on: { "selected-option": _vm.propagateSelect },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function(ref) {
            var data = ref.data
            return [_vm._t("default", null, { data: data })]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }