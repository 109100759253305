var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip.bottom",
          value:
            _vm.showTooltip &&
            (_vm.description || _vm.title.replaceAll("_", " ")),
          expression:
            "\n    showTooltip && (description || title.replaceAll('_', ' '))\n  ",
          modifiers: { bottom: true }
        }
      ],
      staticClass: "label",
      class: _vm.cssClass,
      style: [
        _vm.bgColor
          ? {
              background: _vm.bgColor,
              color: _vm.textColor,
              "border-color": _vm.bgColor
            }
          : { color: _vm.textColor }
      ].concat(_vm.customStyle),
      attrs: { title: _vm.description }
    },
    [
      _vm.frontIcon
        ? _c("icons", {
            attrs: {
              name: _vm.frontIcon,
              size: _vm.iconSize,
              color: _vm.frontIconColor
                ? _vm.frontIconColor
                : _vm.bgColor
                ? "white"
                : "darkestgrey"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.href
        ? _c("span", { staticClass: "label--box" }, [
            _vm._v("\n    " + _vm._s(_vm.title) + "\n  ")
          ])
        : _c(
            "a",
            { style: { color: _vm.textColor }, attrs: { href: _vm.href } },
            [_vm._v(_vm._s(_vm.title))]
          ),
      _vm._v(" "),
      _vm.showCancelIcon
        ? _c("icons", {
            attrs: {
              name: _vm.icon,
              size: _vm.iconSize,
              "custom-style": {
                fill: "none",
                stroke: _vm.textColor,
                strokeLinecap: "round",
                strokeLinejoin: "round",
                cursor: "pointer"
              }
            },
            on: { click: _vm.onClick }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }