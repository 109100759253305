var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "medium-12 auth-container" }, [
    _c(
      "div",
      {
        staticClass: "auth-title-container",
        style: {
          backgroundImage:
            "url(" + require("dashboard/assets/images/authBgLayer.svg") + ")"
        }
      },
      [
        _c("div", { staticClass: "auth-header" }, [
          _c("h2", [_vm._v("eCommerce Helpdesk")]),
          _vm._v(" "),
          _c("div", { staticClass: "banner-container" }, [
            _c(
              "span",
              { staticClass: "rotate-banner" },
              _vm._l(_vm.$t("LOGIN.FEATURE_LIST"), function(item, index) {
                return _c(
                  "span",
                  { key: index, staticClass: "flex-row-justify-center" },
                  [
                    _c("icons", {
                      attrs: {
                        name: item.ICON,
                        size: "semimedium",
                        color: "stroke-grey evenodd"
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticStyle: { "margin-left": "0.4rem" } }, [
                      _vm._v(_vm._s(item.NAME))
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "green-dot" })
                  ],
                  1
                )
              }),
              0
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "device-image" }, [
          _c("img", {
            attrs: { src: require("dashboard/assets/images/authBgDevice.svg") }
          })
        ])
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "auth-form-container" }, [_c("router-view")], 1)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }