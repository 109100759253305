var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lime-card-1dp agent-info-wrapper flex-row gap--normal" },
    [
      _c("thumbnail", {
        attrs: {
          src: _vm.avatarSource,
          username: _vm.name,
          "bg-color": "#6bac1b",
          color: "#fff",
          size: "6.4rem"
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "agent-meta flex-column flex-justify flex-align--start"
        },
        [
          _c("div", { staticClass: "flex-row flex-align gap--smaller" }, [
            _vm.role
              ? _c("span", {
                  staticClass: "body-b3 text-light capitalize",
                  domProps: { textContent: _vm._s(_vm.role) }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.status
              ? _c(
                  "div",
                  {
                    class:
                      "flex-row flex-align gap--smaller status-badge status-badge__" +
                      _vm.status
                  },
                  [
                    _c("span", { staticClass: "status-dot" }),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "capital_c3",
                      domProps: { textContent: _vm._s(_vm.status) }
                    })
                  ]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.name
            ? _c("span", {
                staticClass: "title-h5 capitalize",
                domProps: { textContent: _vm._s(_vm.name) }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.email
            ? _c("span", {
                staticClass: "body-b3 capitalize",
                domProps: { textContent: _vm._s(_vm.email) }
              })
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }