var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.inboxData.id
    ? _c(
        "div",
        {
          staticClass: "inbox-card",
          on: {
            click: function($event) {
              return _vm.onClickMoveButton(_vm.inboxData.id)
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "flex-row flex-align gap--smaller mg-bottom--smaller"
            },
            [
              _c("icons", {
                attrs: {
                  name: _vm.inboxData.iconName,
                  color: _vm.inboxData.iconColor + " evenodd",
                  "show-title": false,
                  size: "semimedium"
                }
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "title-h6 line-clamp-1",
                style: { color: _vm.inboxData.color },
                domProps: { textContent: _vm._s(_vm.inboxData.inboxType) }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("span", {
            staticClass: "body-b2 text-dark line-clamp-1",
            domProps: { textContent: _vm._s(_vm.inboxData.name) }
          }),
          _vm._v(" "),
          _c("transition", { attrs: { name: "slide-fade" } }, [
            _c(
              "div",
              {
                staticClass:
                  "move-button flex-row flex-align flex-justify mg-top--smaller"
              },
              [
                _vm.isManual
                  ? _c("icons", {
                      attrs: {
                        name: "chevronLeft",
                        color: "green",
                        size: "medium"
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("span", {
                  staticClass: "title-h6 text-lime-green",
                  domProps: { textContent: _vm._s(_vm.buttonText) }
                }),
                _vm._v(" "),
                _vm.isAuto
                  ? _c("icons", {
                      attrs: {
                        name: "chevronRight",
                        color: "green",
                        size: "medium"
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }