<template>
  <div v-if="isSent" class="message-tooltip">
    <span v-if="!isDeleted">
      {{ $t('CONVERSATION.SENT_BY') }}
    </span>
    <span v-else class="message-deleted">
      {{ $t('CONVERSATION.DELETED_BY') }}
    </span>
    <span class="message-tooltip--value">{{ name }}</span>
  </div>
</template>

<script>
export default {
  props: {
    isSent: {
      type: Boolean,
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
    isDeleted: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.message-tooltip {
  display: none;
  white-space: nowrap;
  align-items: flex-end;
  order: 1;
}
</style>
