var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("section-header", { attrs: { "translation-key": "METRICS" } }),
      _vm._v(" "),
      _c("aside", [
        _c(
          "div",
          { staticClass: "sla-metrics-container" },
          [
            _c("time-selector-with-office-check", {
              attrs: {
                "label-key": _vm.METRICS.FIRST_RESPONSE_TIME,
                data: _vm.data.metrics[_vm.METRICS.FIRST_RESPONSE_TIME]
              },
              on: { update: _vm.handleUpdate }
            }),
            _vm._v(" "),
            _c("time-selector-with-office-check", {
              attrs: {
                "label-key": _vm.METRICS.NEXT_RESPONSE_TIME,
                data: _vm.data.metrics[_vm.METRICS.NEXT_RESPONSE_TIME]
              },
              on: { update: _vm.handleUpdate }
            }),
            _vm._v(" "),
            _c("time-selector-with-office-check", {
              attrs: {
                "label-key": _vm.METRICS.RESOLUTION_TIME,
                data: _vm.data.metrics[_vm.METRICS.RESOLUTION_TIME]
              },
              on: { update: _vm.handleUpdate }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }