var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "custom-fields-rhs-panel" }, [
    _c(
      "div",
      {
        staticClass: "flex-column gap--medium custom-scroll",
        staticStyle: { height: "calc(100% - 6rem)" }
      },
      [
        _c(
          "div",
          {
            staticClass:
              "custom-fields-name flex-row flex-align flex-wrap gap--small"
          },
          [
            _c("div", { staticClass: "body-b2 text-light" }, [
              _vm._v("\n        Name :\n      ")
            ]),
            _vm._v(" "),
            _vm.customField.mandatory ? _c("mandatory-badge") : _vm._e(),
            _vm._v(" "),
            _c("div", {
              staticClass: "title-h4 text-dark line-clamp-1",
              domProps: { textContent: _vm._s(_vm.customFieldName) }
            })
          ],
          1
        ),
        _vm._v(" "),
        _vm.customFieldDescription
          ? [
              _c("div", { staticClass: "flex-column gap--smaller" }, [
                _c("div", { staticClass: "body-b2 text-light" }, [
                  _vm._v("\n          Description:\n        ")
                ]),
                _vm._v(" "),
                _c("div", {
                  staticClass:
                    "custom-fields-description body-b2 text-dark line-clamp-3",
                  domProps: { textContent: _vm._s(_vm.customFieldDescription) }
                })
              ])
            ]
          : _vm._e(),
        _vm._v(" "),
        _c("custom-field-summary", {
          attrs: { "custom-field": _vm.customField }
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "rhs-panel-footer" },
          [
            _c(
              "woot-base-button",
              {
                attrs: { size: "small", variant: "secondary" },
                on: { click: _vm.editCustomField }
              },
              [_vm._v("\n        EDIT\n      ")]
            )
          ],
          1
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }