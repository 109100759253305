var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isMenuStyled && _vm.uiFlags.isFetching
        ? _c("div", { staticClass: "loading skeleton-animation" })
        : _c(
            "woot-single-selector",
            {
              attrs: {
                size: "small",
                "custom-button": !_vm.isMenuStyled,
                "default-option": _vm.selectedInboxName,
                "top-position": { top: "4rem" },
                "custom-style": {
                  left: "0.01rem",
                  maxHeight: _vm.maxHeight,
                  width: "20rem"
                },
                "dropdown-container-style": { backgroundColor: "white" },
                "option-list": _vm.waAndEmailInboxes
              },
              on: { click: _vm.onClick }
            },
            [
              _c(
                "woot-base-button",
                {
                  attrs: {
                    variant: "secondary",
                    size: "medium",
                    "front-icon": "plus",
                    "icon-view-box": "0 0 20 20"
                  }
                },
                [_vm._v("\n      Ticket\n    ")]
              )
            ],
            1
          ),
      _vm._v(" "),
      _c("woot-delete-modal-new", {
        attrs: {
          show: _vm.showWarningModal,
          "on-close": _vm.hidePopup,
          "on-confirm": function() {
            return _vm.onSelectOption(_vm.selectedInbox)
          },
          "show-close": "",
          title: _vm.$t("CREATE_TICKET.WARNING_MODAL.TITLE"),
          message: _vm.$t("CREATE_TICKET.WARNING_MODAL.MESSAGE"),
          "confirm-text": "Confirm",
          "reject-text": "Back",
          "custom-style": {
            "max-width": "44rem",
            height: "auto",
            textAlign: "center"
          }
        },
        on: {
          "update:show": function($event) {
            _vm.showWarningModal = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }