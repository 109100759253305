var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "contact--info flex-column flex-align--start" }, [
      _c(
        "div",
        { staticClass: "info--container flex-space-between flex-align--start" },
        [
          _c(
            "div",
            { staticClass: "flex-row-justify-center" },
            [
              _c("woot-avatar", {
                attrs: {
                  username: _vm.contact.name,
                  color: "#fff",
                  "background-color": "#1B83AC"
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "info--name title-h2 medium-12 columns flex-row-center"
                  },
                  [
                    _c("account-pii-mask-wrapper", {
                      attrs: {
                        "pii-data": _vm.contact.name,
                        "mask-type": _vm.maskType.NAME,
                        "unmask-action": "piiMask/logUnmaskEvent",
                        "unmask-payload": _vm.unmaskRequestPayload,
                        "on-unmask": function(unmaskedData) {
                          return _vm.handleUnmask(
                            _vm.maskType.NAME,
                            unmaskedData
                          )
                        }
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var data = ref.data
                            return [
                              _c("span", { staticClass: "text-truncate" }, [
                                _vm._v(" " + _vm._s(data))
                              ])
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.inboxInfo.name
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "medium-12 columns edit-btn flex-row-center"
                      },
                      [
                        _c("icons", {
                          attrs: {
                            name: _vm.badgeType.name,
                            color: _vm.badgeType.color + " evenodd"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "inbox-name body-b3 text-light" },
                          [_vm._v(_vm._s(_vm.inboxInfo.name))]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "edit-btn flex-row-center",
              on: { click: _vm.toggleEditModal }
            },
            [
              _c("icons", {
                attrs: {
                  name: "editRound",
                  size: "medium",
                  view: "0 0 24 24",
                  color: "green"
                }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "separator" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "contact--details" },
        [
          _vm._l(_vm.contactDetails, function(detail, index) {
            return _c(
              "div",
              { key: index, staticClass: "flex-space-between detail-row" },
              [
                detail.href
                  ? _c(
                      "a",
                      {
                        attrs: {
                          target: "_blank",
                          rel: "noopener noreferrer nofollow",
                          href: detail.href
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex-row-center" },
                          [
                            _c(
                              "span",
                              { staticClass: "mg-right mg-left--smaller" },
                              [
                                _c("icons", {
                                  attrs: {
                                    name: detail.icon,
                                    color: detail.iconColor,
                                    size: "semimedium"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            detail.value
                              ? _c("account-pii-mask-wrapper", {
                                  attrs: {
                                    "mask-type": _vm.getMaskTypeByIcon(
                                      detail.icon
                                    ),
                                    "pii-data": detail.value,
                                    "unmask-action": "piiMask/logUnmaskEvent",
                                    "unmask-payload": _vm.unmaskRequestPayload,
                                    "on-unmask": function(unmaskedData) {
                                      return _vm.handleUnmask(
                                        detail,
                                        unmaskedData
                                      )
                                    }
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var data = ref.data
                                          return [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "subtitle-s2 text-dark"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(data) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    )
                  : _c("div", { staticClass: "flex-row-center" }, [
                      _c(
                        "span",
                        { staticClass: "mg-right mg-left--smaller" },
                        [
                          _c("icons", {
                            attrs: {
                              name: detail.icon,
                              color: detail.iconColor,
                              size: "semimedium"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      detail.value && detail.value !== ""
                        ? _c("span", { staticClass: "subtitle-s2 text-dark" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(detail.value) +
                                "\n          "
                            )
                          ])
                        : _c(
                            "span",
                            { staticClass: "subtitle-s2 text-light" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t("CONTACT_PANEL.NOT_AVAILABLE")
                                  ) +
                                  "\n          "
                              )
                            ]
                          )
                    ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "copy-icon",
                    style: !detail.value && { "pointer-events": "none" },
                    on: {
                      click: function($event) {
                        return _vm.onCopy(detail.value)
                      }
                    }
                  },
                  [
                    _c(
                      "span",
                      [
                        _c("icons", {
                          attrs: {
                            name: "copy",
                            view: "0 0 16 16",
                            color: "darkestgrey",
                            size: "normal"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ]
            )
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "contact-labels-container" },
            [
              _c("div", { staticClass: "flex-row flex-align" }, [
                _c(
                  "span",
                  { staticClass: "mg-left--smaller mg-right--two" },
                  [
                    _c("icons", {
                      attrs: {
                        name: "hashFilled",
                        color: "darkgrey",
                        size: "normal"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", {
                  staticClass: "text-dark subtitle-s2 text-truncate",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("CONTACT_PANEL.CONTACT_TAGS.LABEL")
                    )
                  }
                })
              ]),
              _vm._v(" "),
              _c("contact-labels", {
                attrs: {
                  "contact-labels": _vm.contactLabels,
                  "contact-id": _vm.contact.id,
                  "show-add-button": ""
                },
                on: { updateLabels: _vm.UpdateLabels }
              })
            ],
            1
          )
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }