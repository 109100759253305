var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    {
      attrs: {
        "small-modal": "",
        "has-transition": "",
        "close-on-backdrop-click": "",
        show: "",
        "on-close": _vm.closePrivateReplyModal
      }
    },
    [
      _c(
        "div",
        { staticClass: "column content-box" },
        [
          _c("woot-modal-header", {
            attrs: {
              "header-title": _vm.$t(
                "CONVERSATION.SOCIAL_POST.PRIVATE_REPLY.TITLE"
              ),
              "header-content": _vm.$t(
                "CONVERSATION.SOCIAL_POST.PRIVATE_REPLY.DESC"
              )
            }
          }),
          _vm._v(" "),
          _c(
            "form",
            {
              staticClass: "row settings-form",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.sendPrivateReply($event)
                }
              }
            },
            [
              _c("woot-input", {
                staticClass: "medium-12 columns content-margin",
                class: { error: _vm.$v.privateReplyContent.$error },
                attrs: {
                  "auto-focus": "",
                  error: _vm.$t(
                    "CONVERSATION.SOCIAL_POST.PRIVATE_REPLY.CONTENT.VALIDATION"
                  ),
                  "has-error": _vm.$v.privateReplyContent.$error,
                  label: _vm.$t(
                    "CONVERSATION.SOCIAL_POST.PRIVATE_REPLY.CONTENT.LABEL"
                  ),
                  "help-text": _vm.$t(
                    "CONVERSATION.SOCIAL_POST.PRIVATE_REPLY.CONTENT.HELP_TEXT"
                  ),
                  placeholder: _vm.$t(
                    "CONVERSATION.SOCIAL_POST.PRIVATE_REPLY.CONTENT.PLACEHOLDER"
                  )
                },
                on: {
                  input: _vm.$v.privateReplyContent.$touch,
                  blur: _vm.$v.privateReplyContent.$touch
                },
                model: {
                  value: _vm.privateReplyContent,
                  callback: function($$v) {
                    _vm.privateReplyContent =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "privateReplyContent"
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "modal-footer gap--small" },
                [
                  _c(
                    "woot-base-button",
                    {
                      attrs: { variant: "tertiary-danger" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.closePrivateReplyModal($event)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t(
                              "CONVERSATION.SOCIAL_POST.PRIVATE_REPLY.CANCEL"
                            )
                          ) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "woot-base-button",
                    {
                      attrs: {
                        "front-icon": "peopleFilled",
                        disabled: _vm.$v.privateReplyContent.$error,
                        loading: _vm.uiFlags.creatingItem,
                        type: "submit"
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t(
                              "CONVERSATION.SOCIAL_POST.PRIVATE_REPLY.SEND"
                            )
                          ) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }