var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "create-ticket-zero-state" },
    [
      _c(
        "table-zero-state",
        {
          staticStyle: { height: "100%", background: "transparent" },
          attrs: {
            title: _vm.$t("CREATE_TICKET.ZERO_STATE.TITLE"),
            subtitle: _vm.$t("CREATE_TICKET.ZERO_STATE.SUBTITLE"),
            "asset-link": "/brand-assets/inbox_zero_state.svg"
          }
        },
        [
          _c("create-ticket-button", {
            staticStyle: { width: "20rem" },
            attrs: { "is-menu-styled": "" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }