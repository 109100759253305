var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-container actions--container", attrs: { id: _vm.id } },
    [
      _c(
        "div",
        { staticClass: "action--button", style: _vm.customButtonStyle },
        [
          _c(
            "span",
            {
              staticClass: "primary-button",
              class: [_vm.size, "button-" + _vm.size],
              on: { click: _vm.buttonClick }
            },
            [
              _vm.frontIcon
                ? _c(
                    "span",
                    { staticStyle: { "margin-right": "0.8rem" } },
                    [
                      _c("icons", {
                        attrs: {
                          name: _vm.frontIcon,
                          color: "white",
                          size: _vm.iconSize
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v("\n      " + _vm._s(_vm.name) + "\n    ")
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "clear more--button",
              class: "toggle-" + _vm.size,
              on: { click: _vm.toggleDropdown }
            },
            [
              _c("icons", {
                attrs: {
                  name: _vm.toggleIcon,
                  size: _vm.iconSize,
                  color: "white"
                }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm.showDropdown
        ? _c(
            "ul",
            {
              directives: [
                {
                  name: "on-clickaway",
                  rawName: "v-on-clickaway",
                  value: _vm.hideDropdown,
                  expression: "hideDropdown"
                }
              ],
              staticClass: "dropdown--pane custom-scroll",
              class: [
                { "dropdown--pane--open": _vm.showDropdown },
                _vm.customClass
              ],
              style: [_vm.customStyle, _vm.modalPosition]
            },
            [
              _vm._l(_vm.optionList, function(option) {
                return _c("li", { key: option.id }, [
                  !_vm.isSelected(option) || _vm.showAll
                    ? _c(
                        "button",
                        {
                          staticClass: "button dropdown-button",
                          class: {
                            "dropdown-button--selected":
                              (_vm.isSelected(option) && _vm.disabled) ||
                              option.selected,
                            "dropdown-button--disabled": option.disabled
                          },
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.onClick(option)
                            }
                          }
                        },
                        [
                          option.icon
                            ? _c(
                                "span",
                                { staticStyle: { "margin-right": "8px" } },
                                [
                                  _c("icons", {
                                    attrs: {
                                      name: option.icon,
                                      size: "semimedium",
                                      color: "grey"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("span", { staticClass: "text-truncate" }, [
                            _vm._v(_vm._s(option.name))
                          ])
                        ]
                      )
                    : _vm._e()
                ])
              }),
              _vm._v(" "),
              !_vm.optionList.length
                ? _c("li", [
                    _c("button", { staticClass: "button action--button" }, [
                      _c("span", [_vm._v(_vm._s("No options available"))])
                    ])
                  ])
                : _vm._e()
            ],
            2
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }