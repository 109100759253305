var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("woot-single-selector", {
    staticClass: "status-dropdown-btn",
    attrs: {
      id: "status-filter",
      size: _vm.size,
      variant: _vm.variant,
      "button-style": Object.assign({}, { color: "#8c8c8c" }, _vm.buttonStyle),
      "custom-style": {
        left: 0,
        width: "auto",
        "z-index": 400
      },
      "default-option": _vm.activeStatus,
      "option-list": _vm.getChatFilterList,
      "show-all": true,
      "highlight-selected": ""
    },
    on: { click: _vm.onTabChange }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }