var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "channel" }, [
    _c(
      "div",
      {
        staticClass: "channel-image-container",
        class: { inactive: !_vm.isActive },
        on: { click: _vm.onItemClick }
      },
      [
        _c(
          "div",
          { staticClass: "channel-image flex-row flex-align flex-justify" },
          [
            _vm.channel.key === "gupshup_enterprise"
              ? _c("img", {
                  attrs: {
                    src: require("dashboard/assets/images/channels/gupshup.svg")
                  }
                })
              : ["whatsapp_channels", "whatsapp_cloud_API"].includes(
                  _vm.channel.key
                )
              ? _c("img", {
                  attrs: {
                    src: require("dashboard/assets/images/channels/whatsapp.svg")
                  }
                })
              : _c("img", {
                  attrs: {
                    src: require("dashboard/assets/images/channels/" +
                      _vm.channel.key.toLowerCase() +
                      ".svg")
                  }
                })
          ]
        ),
        _vm._v(" "),
        _c("span", {
          staticClass: "channel__title body-b3 text-light",
          domProps: {
            textContent: _vm._s(_vm.stringPrettify(_vm.channel.name))
          }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }