var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-margin filters-wrap" },
    [
      _c("label", { staticClass: "formlabel", class: { error: _vm.error } }, [
        _c("span", {
          staticClass: "title-h5 text-dark",
          domProps: {
            textContent: _vm._s(_vm.$t("AUTOMATION.ADD.FORM.EVENT.LABEL"))
          }
        })
      ]),
      _vm._v(" "),
      _c("woot-single-selector", {
        staticClass: "AUTOMATION_EVENT_NAME",
        attrs: {
          "default-option": _vm.dropdownTitle,
          "option-list": _vm.events,
          "show-selected-text": false,
          "custom-style": { left: 0, width: "100%" },
          "dropdown-container-style": { height: "3.6rem", width: "22rem" }
        },
        on: { click: _vm.selectEvent }
      }),
      _vm._v(" "),
      _vm.error
        ? _c("span", { staticClass: "message" }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t("AUTOMATION.ADD.FORM.EVENT.ERROR")) +
                "\n  "
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }