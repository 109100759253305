import { render, staticRenderFns } from "./RenderInCarousel.vue?vue&type=template&id=513b971e&scoped=true&"
import script from "./RenderInCarousel.vue?vue&type=script&lang=js&"
export * from "./RenderInCarousel.vue?vue&type=script&lang=js&"
import style0 from "./RenderInCarousel.vue?vue&type=style&index=0&id=513b971e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "513b971e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/limechat/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('513b971e')) {
      api.createRecord('513b971e', component.options)
    } else {
      api.reload('513b971e', component.options)
    }
    module.hot.accept("./RenderInCarousel.vue?vue&type=template&id=513b971e&scoped=true&", function () {
      api.rerender('513b971e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/lime-ui/dashboard/components/ui/RenderInCarousel.vue"
export default component.exports