var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gap--normal", class: _vm.computedClass },
    [
      _c(
        "div",
        { staticClass: "flex-row gap--two" },
        [
          _vm._t("icon"),
          _vm._v(" "),
          _c("div", [
            _c("div", { staticClass: "flex-column" }, [
              _c(
                "div",
                {
                  staticClass: "page-subtitle text-dark flex-row gap--slab",
                  class: { "mg-bottom--small": _vm.headerContent.trim() }
                },
                [
                  _vm._v(
                    "\n          " + _vm._s(_vm.headerTitle) + "\n          "
                  ),
                  _vm.infoText
                    ? _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.infoText,
                              expression: "infoText"
                            }
                          ]
                        },
                        [
                          _c("icons", {
                            attrs: {
                              name: "info",
                              color: "green",
                              size: "normal",
                              "show-title": false
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c("span", {
                staticClass: "page-subtitle small-12 column body-b3 text-light",
                domProps: { innerHTML: _vm._s(_vm.headerContent) }
              })
            ])
          ])
        ],
        2
      ),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }