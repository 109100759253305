var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "accordian-card",
    {
      attrs: { "custom-desc": true, "custom-class": "notes-container" },
      scopedSlots: _vm._u([
        {
          key: "desc",
          fn: function() {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "address-btn flex-row flex-align flex-justify--between"
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "subtitle-s2",
                      staticStyle: { width: "100%", cursor: "pointer" }
                    },
                    [_vm._v("\n        Notes\n      ")]
                  )
                ]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _c("woot-input", {
        staticClass: "notes",
        attrs: {
          rows: "6",
          type: "text",
          placeholder: "Jot down something here..."
        },
        on: { change: _vm.changeNotesData },
        model: {
          value: _vm.content,
          callback: function($$v) {
            _vm.content = $$v
          },
          expression: "content"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }