var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "inbox-viewer flex-row flex-align flex-justify mg-bottom--larger",
        style: { background: _vm.shadeColor }
      },
      [
        _vm.inbox.id
          ? _c(
              "div",
              {
                staticClass:
                  "flex-row flex-justify mg-top--small mg-auto gap--slab"
              },
              [
                _vm.isWhatsappChannel
                  ? _c("span", [
                      _c("img", {
                        attrs: {
                          width: "80",
                          src: require("dashboard/assets/images/channels/whatsapp.svg")
                        }
                      })
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.isAKnowlarityInbox
                  ? _c("span", [
                      _c("img", {
                        attrs: {
                          width: "80",
                          src: require("dashboard/assets/images/channels/voice_channels.svg")
                        }
                      })
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.isWhatsappChannel || _vm.isAKnowlarityInbox
                  ? _c(
                      "span",
                      [
                        _c("icons", {
                          attrs: {
                            name: "link",
                            color: "green",
                            size: "large",
                            "show-title": false
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("span", [
                  _c("img", {
                    attrs: {
                      width: "80",
                      src: _vm.imageSrc(_vm.channelImageIdentifier)
                    }
                  })
                ])
              ]
            )
          : _vm._e()
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "wizard-body columns content-box small-12 flex-column" },
      [
        _c(
          "empty-state",
          { attrs: { title: _vm.title, message: _vm.message } },
          [
            _c("div", { staticClass: "medium-12 columns text-center" }, [
              _c(
                "div",
                { staticClass: "medium-12" },
                [
                  _vm.isATwilioChannel
                    ? _c("woot-code", {
                        attrs: { lang: "html", script: _vm.twilioCallbackURL }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "medium-12" },
                [
                  _vm.isAnEmailChannel
                    ? _c("woot-code", {
                        attrs: {
                          lang: "html",
                          script: _vm.inbox.forward_to_email
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "medium-12 router-button",
                attrs: {
                  to: {
                    name: "settings_inbox_show",
                    params: { inboxId: this.$route.params.inbox_id }
                  }
                }
              },
              [
                _c(
                  "woot-base-button",
                  {
                    staticClass: "cta-btn",
                    attrs: { size: "large", "front-icon": "settings" }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("INBOX_MGMT.FINISH.BUTTON_TEXT")) +
                        "\n        "
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }