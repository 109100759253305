var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-row-justify-center full-height" },
    [
      _vm.showProgressTrackerTour
        ? _c("onboarding-tour", {
            attrs: {
              "tour-name": _vm.TOUR_NAME,
              "tour-version": _vm.progressTrackerTourSteps.TOUR_VERSION,
              "tour-steps": _vm.progressTrackerTourSteps.STEPS
            },
            on: { unmountTour: _vm.onCloseTour }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "aside",
        {
          staticClass:
            "sidebar-container animated shrink sidebar-container--close"
        },
        [
          _c("div", { staticClass: "sidebar columns" }, [
            _c(
              "a",
              { staticClass: "logo", attrs: { href: _vm.dashboardPath } },
              [
                _c("icons", {
                  attrs: {
                    name: "lime",
                    color: "white",
                    "custom-style": { width: "4.4rem" }
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "main-nav" },
              [
                _c(
                  "transition-group",
                  {
                    staticClass: "menu vertical",
                    attrs: { name: "menu-list", tag: "ul" }
                  },
                  _vm._l(_vm.accessibleMenuItems, function(item) {
                    return _c("navbar-item", {
                      key: item.toStateName,
                      attrs: { "menu-item": item }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "bottom-nav flex-column flex-align flex-justify gap--two"
              },
              [
                _vm.isCallFeatureAvailable
                  ? _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip.right",
                            value: _vm.tooltipMsg,
                            expression: "tooltipMsg",
                            modifiers: { right: true }
                          }
                        ],
                        staticClass: "bottom-nav-item",
                        class: {
                          "phone-icon": _vm.isCallFeatureAvailable,
                          "phone-icon--disabled": !_vm.isCallFeatureAvailable,
                          jiggle: _vm.callStatesFlags.showCallPopup
                        },
                        on: { click: _vm.onShowCallPopup }
                      },
                      [
                        _c("icons", {
                          attrs: {
                            name: _vm.isCallInProgress ? "phone" : "call",
                            title: _vm.isCallInProgress
                              ? "call in progress"
                              : "call",
                            color: _vm.callIconColor,
                            size: "mediumlarge"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showProgressTrackerButton
                  ? _c(
                      "woot-base-button",
                      {
                        staticClass: "progress-tracker-button bottom-nav-item",
                        attrs: {
                          tag: "span",
                          size: "small",
                          variant: "tertiary"
                        },
                        on: { click: _vm.onShowProgressTrackerModal }
                      },
                      [
                        _c("icons", {
                          attrs: {
                            name: "filterSquircle",
                            color: "white",
                            size: "medium",
                            view: "4 4 24 24",
                            "show-title": false
                          }
                        }),
                        _vm._v(" "),
                        _c("div", {
                          staticClass:
                            "statbadge statbadge-dot statbadge--danger"
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("notification-bell", { staticClass: "bottom-nav-item" }),
                _vm._v(" "),
                !_vm.isCurrentUserConfirmed
                  ? _c("email-confirmation-status")
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "content-margin bottom-avatar",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.showBottomNavOptions($event)
                      }
                    }
                  },
                  [
                    _c("thumbnail", {
                      attrs: {
                        "colored-avatar": true,
                        src: _vm.currentUser.avatar_url,
                        size: "48px",
                        username: _vm.currentUserAvailableName
                      }
                    }),
                    _vm._v(" "),
                    _c("div", {
                      class:
                        "statbadge statbadge-dot statbadge__" +
                        _vm.currentUser.availability_status
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("transition", { attrs: { name: "menu-slide" } }, [
                  _vm.showBottomNavMenu
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "on-clickaway",
                              rawName: "v-on-clickaway",
                              value: _vm.showBottomNavOptions,
                              expression: "showBottomNavOptions"
                            }
                          ],
                          staticClass: "dropdown-pane bottom-pane-menu top"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "availibility-container" },
                            [_c("availability-status")],
                            1
                          ),
                          _vm._v(" "),
                          _c("ul", { staticClass: "vertical dropdown menu" }, [
                            _c(
                              "li",
                              {
                                staticClass: "text-dark title-h5",
                                staticStyle: { color: "#3c492c" }
                              },
                              [
                                _c(
                                  "span",
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "text-dark",
                                        attrs: {
                                          to:
                                            "/app/accounts/" +
                                            _vm.accountId +
                                            "/profile"
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.inlineButtonClickHandler(
                                              $event
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.$t(
                                                "SIDEBAR_ITEMS.PROFILE_SETTINGS"
                                              )
                                            ) +
                                            "\n                  "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-dark title-h5 flex-space-between"
                              },
                              [
                                _c(
                                  "span",
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "download-text",
                                        attrs: { to: "#" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.$t(
                                                "SIDEBAR_ITEMS.DOWNLOAD_APPS"
                                              )
                                            ) +
                                            "\n                  "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-dark title-h5 flex-space-between download-icon-container"
                                  },
                                  [
                                    _c("icons", {
                                      staticClass: "apple-icon",
                                      attrs: {
                                        name: "apple",
                                        color: _vm.appleIconHover
                                          ? "green"
                                          : "darkestdarkgrey",
                                        size: "medium",
                                        "show-title": true,
                                        cursor: "pointer"
                                      },
                                      on: {
                                        mouseover: function($event) {
                                          _vm.appleIconHover = true
                                        },
                                        mouseleave: function($event) {
                                          _vm.appleIconHover = false
                                        },
                                        click: function($event) {
                                          return _vm.gotoAppStore()
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("icons", {
                                      attrs: {
                                        name: "android",
                                        color: _vm.androidIconHover
                                          ? "green"
                                          : "darkestdarkgrey",
                                        size: "medium",
                                        "show-title": true,
                                        cursor: "pointer"
                                      },
                                      on: {
                                        mouseover: function($event) {
                                          _vm.androidIconHover = true
                                        },
                                        mouseleave: function($event) {
                                          _vm.androidIconHover = false
                                        },
                                        click: function($event) {
                                          return _vm.gotoPlayStore()
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("li", { staticClass: "title-h5 text-dark" }, [
                              _c(
                                "a",
                                {
                                  staticStyle: { color: "#ff7e61" },
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.logout()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.$t("SIDEBAR_ITEMS.LOGOUT")) +
                                      "\n                "
                                  )
                                ]
                              )
                            ])
                          ])
                        ]
                      )
                    : _vm._e()
                ])
              ],
              1
            )
          ])
        ]
      ),
      _vm._v(" "),
      _vm.showSubMenu()
        ? _c(
            "div",
            { staticClass: "submenu flex-column custom-scroll" },
            _vm._l(
              _vm.currentMenuItem && _vm.currentMenuItem.children,
              function(item) {
                return _c(
                  item.isExternal ? "a" : "router-link",
                  {
                    key: item.id,
                    tag: "component",
                    attrs: {
                      to: !item.isExternal ? item.toState : null,
                      href: item.isExternal ? item.toState : null,
                      rel: item.isExternal ? "noopener noreferrer" : null,
                      target: item.isExternal ? "_blank" : null,
                      "active-class": "active-submenu"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "submenu--label flex-row flex-align",
                        on: {
                          click: function($event) {
                            return _vm.logClickEvent(item)
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "submenu--label--bar" }),
                        _vm._v(" "),
                        item.icon
                          ? _c(
                              "span",
                              { staticClass: "submenu--label--icon" },
                              [
                                _c("icons", {
                                  attrs: {
                                    name: item.icon,
                                    size: "medium",
                                    color: "darkgreen"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticStyle: { position: "relative" } }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("SIDEBAR." + item.label)) +
                              "\n          "
                          ),
                          item.isNew
                            ? _c(
                                "span",
                                { staticClass: "statbadge statbadge-new" },
                                [_vm._v("\n            NEW\n          ")]
                              )
                            : _vm._e()
                        ])
                      ]
                    )
                  ]
                )
              }
            ),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "modal-fade" } },
        [
          _vm.showProgressTrackerModal
            ? _c("progress-tracker-modal", {
                on: {
                  closeModal: function($event) {
                    _vm.showProgressTrackerModal = false
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }