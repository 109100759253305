var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "preview--container" }, [
    _c("div", { staticClass: "image--container" }, [
      _c("div", { staticClass: "preview--image" }, [
        _vm.isTypeImage(_vm.files[0].type)
          ? _c("img", { attrs: { src: _vm.files[0].url } })
          : _vm.isTypePdf(_vm.files[0].type)
          ? _c("img", {
              attrs: {
                width: _vm.size,
                height: _vm.size,
                src: require("../../../assets/images/pdf.svg")
              }
            })
          : _c("img", {
              attrs: {
                width: _vm.size,
                height: _vm.size,
                src: require("../../../assets/images/file.svg")
              }
            })
      ]),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "cancel--preview" },
        [
          _c("icons", {
            attrs: {
              name: "cancel",
              color: "stroke-grey",
              size: "semimedium",
              "custom-style": {
                strokeWidth: "2",
                cursor: "pointer"
              }
            },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.$emit("clear", _vm.files[0].id)
              }
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("span", [
      _vm._v(
        _vm._s(
          _vm.files[0].name + " (" + _vm.formatFileSize(_vm.files[0].size) + ")"
        )
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }