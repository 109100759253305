var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "automation-tester" },
    [
      _c("test-mode-button", {
        staticClass: "AUTOMATION_TEST_MODE",
        attrs: { "is-active": _vm.automationTestData.is_test },
        on: {
          onClick: function($event) {
            _vm.showTestModal = true
          }
        }
      }),
      _vm._v(" "),
      _c("test-modal", {
        attrs: {
          opened: _vm.showTestModal,
          "is-test-mode-active": _vm.automationTestData.is_test,
          "phone-numbers": _vm.automationTestData.test_numbers
        },
        on: {
          onUpdate: function($event) {
            return _vm.$emit("updateAutomationTestData", $event)
          },
          onClose: function($event) {
            _vm.showTestModal = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }