var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "conversation-view" },
    [
      _c("Header"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "app-content columns" },
        [
          _vm.savedSearchMeta.results.length
            ? _c("search-view", {
                staticClass: "conversations-sidebar columns",
                attrs: {
                  "on-show-search-modal": _vm.onShowSearchModal,
                  "on-clear-search": _vm.clearSearchResult
                },
                on: { "conversation-load": _vm.onConversationLoad }
              })
            : _vm.isAdvancedTicketViewsEnabled
            ? _c("chat-list", {
                attrs: { label: _vm.label, "conversation-inbox": _vm.inboxId },
                on: { "conversation-load": _vm.onConversationLoad }
              })
            : _c("chat-list-deprecated", {
                attrs: { label: _vm.label, "conversation-inbox": _vm.inboxId },
                on: { "conversation-load": _vm.onConversationLoad }
              }),
          _vm._v(" "),
          _c("conversation-box"),
          _vm._v(" "),
          _c("router-view"),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "modal-fade" } },
            [
              _vm.showSearchModal
                ? _c("search-modal", {
                    attrs: {
                      "saved-search-data": _vm.savedSearchMeta.results.length
                        ? _vm.savedSearchMeta
                        : null,
                      "on-close": _vm.closeSearch
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }