var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "contact-entry",
      class: { disabled: !_vm.isOptIn },
      on: { click: _vm.handleClick }
    },
    [
      _c("span", { staticClass: "title-h5 text-dark text-truncate medium-6" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.inboxType === "email" ? _vm.email : _vm.contactPhone) +
            "\n    "
        ),
        !_vm.isOptIn
          ? _c("span", { staticClass: "body-b3 text-red" }, [
              _vm._v("\n      (Opt-out)\n    ")
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("span", {
        staticClass: "body-b3 text-light text-truncate",
        domProps: { textContent: _vm._s(_vm.contactName) }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }