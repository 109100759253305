var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-container" },
    [
      _c("div", { staticClass: "flex-row flex-align--start gap--small" }, [
        _vm.computedIconName
          ? _c(
              "div",
              [
                _c("icons", {
                  attrs: {
                    name: _vm.computedIconName,
                    color: _vm.computedIconColor,
                    size: _vm.iconSize,
                    view: _vm.viewBox,
                    "show-title": false
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "box-content flex-row flex-justify--between flex-align--start full-width"
          },
          [
            _vm.to
              ? [
                  _c("a", {
                    staticClass: "title-h6 hyper-linked-text",
                    style: { color: _vm.computedTextColor },
                    attrs: {
                      href: _vm.to,
                      rel: "noopener noreferrer nofollow",
                      target: "_blank"
                    },
                    domProps: { textContent: _vm._s(_vm.content) }
                  }),
                  _vm._v(" "),
                  _vm.type === "video"
                    ? _c(
                        "a",
                        {
                          staticClass: "open-link-icon",
                          attrs: {
                            href: _vm.to,
                            rel: "noopener noreferrer nofollow",
                            target: "_blank"
                          }
                        },
                        [
                          _c("icons", {
                            attrs: {
                              name: "openLink",
                              color: "grey",
                              size: "medium",
                              "show-title": false
                            }
                          })
                        ],
                        1
                      )
                    : _c(
                        "a",
                        {
                          staticClass: "open-link-icon",
                          attrs: {
                            href: _vm.to,
                            rel: "noopener noreferrer nofollow",
                            target: "_blank"
                          }
                        },
                        [
                          _c("icons", {
                            attrs: {
                              name: "openLink",
                              color: "grey",
                              size: "medium",
                              "show-title": false
                            }
                          })
                        ],
                        1
                      )
                ]
              : _c("div", [
                  _vm.heading
                    ? _c("div", {
                        staticClass: "title-h5 text-dark mg-bottom--smaller",
                        domProps: { innerHTML: _vm._s(_vm.heading) }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "body-b2 text-light text-content",
                    style: { color: _vm.computedTextColor },
                    domProps: { innerHTML: _vm._s(_vm.content) }
                  })
                ])
          ],
          2
        )
      ]),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }