var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box settings-wrapper" },
    [
      !_vm.uiFlags.isFetching && _vm.selectedAutomation
        ? _c("automation-form", {
            attrs: {
              "selected-response": _vm.selectedAutomation,
              mode: _vm.selectedMode
            },
            on: { saveAutomation: _vm.saveAutomation }
          })
        : _c("loading-state", { staticStyle: { height: "100%" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }