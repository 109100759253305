var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "contact--profile lime-card-border" }, [
      _c(
        "div",
        { staticClass: "contact--info" },
        [
          _c(
            "div",
            { staticClass: "info--container flex-row-justify-center" },
            [
              _c(
                "div",
                { staticClass: "flex-row-justify-center" },
                [
                  _c("thumbnail", {
                    attrs: {
                      src: _vm.contact.thumbnail,
                      size: "44px",
                      color: "#1B83AC",
                      username: _vm.contact.name,
                      status: _vm.contact.availability_status
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "row",
                      staticStyle: { "max-width": "20rem" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "info--name medium-12 columns" },
                        [
                          _c("account-pii-mask-wrapper", {
                            attrs: {
                              "pii-data": _vm.contact.name,
                              "mask-type": _vm.maskType,
                              "unmask-action": "piiMask/logUnmaskEvent",
                              "unmask-payload": _vm.unmaskRequestPayload
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var data = ref.data
                                  return [
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: data,
                                            expression: "data"
                                          }
                                        ],
                                        staticClass: "text-truncate",
                                        staticStyle: { "max-width": "18rem" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(data) +
                                            "\n                  "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.inboxInfo.name
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "profile--button medium-12 columns name--box"
                            },
                            [
                              _c("icons", {
                                attrs: {
                                  name: _vm.badgeType.name,
                                  color: _vm.badgeType.color + " evenodd"
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticStyle: { "margin-left": "0.2rem" } },
                                [_vm._v(_vm._s(_vm.inboxInfo.name))]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "edit-btn name--box",
                  on: { click: _vm.toggleEditModal }
                },
                [
                  _c("icons", {
                    attrs: {
                      name: "edit",
                      size: "medium",
                      view: "0 0 24 24",
                      color: "stroke-green"
                    }
                  })
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "contact--details" },
            [
              _vm.additionalAttributes.description
                ? _c("div", { staticClass: "contact--bio" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.additionalAttributes.description) +
                        "\n        "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("social-icons", {
                attrs: { "social-profiles": _vm.socialProfiles }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "contact--metadata" },
                [
                  _c("contact-info-row", {
                    attrs: {
                      href: _vm.contact.email
                        ? "mailto:" + _vm.contact.email
                        : "",
                      value: _vm.contact.email,
                      icon: "email",
                      title: _vm.$t("CONTACT_PANEL.EMAIL_ADDRESS"),
                      "contact-id": _vm.contact.id
                    }
                  }),
                  _vm._v(" "),
                  _c("contact-info-row", {
                    attrs: {
                      href: _vm.contact.phone_number
                        ? "tel:" + _vm.contact.phone_number
                        : "",
                      value: _vm.contact.phone_number,
                      icon: "phone",
                      title: _vm.$t("CONTACT_PANEL.PHONE_NUMBER"),
                      "contact-id": _vm.contact.id
                    }
                  }),
                  _vm._v(" "),
                  _c("contact-info-row", {
                    attrs: {
                      value: _vm.additionalAttributes.location,
                      icon: "location",
                      title: _vm.$t("CONTACT_PANEL.LOCATION"),
                      "contact-id": _vm.contact.id
                    }
                  }),
                  _vm._v(" "),
                  _c("contact-info-row", {
                    attrs: {
                      value: _vm.additionalAttributes.company_name,
                      icon: "work",
                      title: _vm.$t("CONTACT_PANEL.COMPANY"),
                      "contact-id": _vm.contact.id
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.showEditModal
            ? _c("edit-contact", {
                attrs: {
                  show: _vm.showEditModal,
                  contact: _vm.contact,
                  "parent-component-name": "ContactInfo"
                },
                on: { cancel: _vm.toggleEditModal }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showViewModal
            ? _c("view-contact", {
                attrs: { show: _vm.showViewModal, contact: _vm.contact },
                on: { cancel: _vm.toggleViewModal }
              })
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }