var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view-box-new columns" },
    [
      _c("settings-header", {
        attrs: {
          icon: "graph",
          "header-title": _vm.$t("ANALYTICS.HEADER.ANALYTICS"),
          "show-back-button": false
        }
      }),
      _vm._v(" "),
      _c("last-updated-time", {
        staticClass: "flex-row flex-justify--end pd-right",
        attrs: { scope: "tickets" }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "filter-pane" }, [
        _c(
          "div",
          {
            staticClass: "flex-space-between",
            staticStyle: { "margin-top": "0.5rem" }
          },
          [
            _c(
              "div",
              { staticClass: "flex-row-justify-center analytics-filters" },
              [
                _c("date-range-selector", {
                  attrs: { "default-selection": 2 },
                  on: { "date-range-change": _vm.onDateRangeChange }
                }),
                _vm._v(" "),
                _c("inbox-filter", {
                  attrs: {
                    "allow-multi-select": true,
                    value: _vm.$t(
                      "ANALYTICS.FILTER_SELECTION.INBOX.PLACEHOLDER"
                    ),
                    "toggle-filter": _vm.changeInboxId
                  },
                  on: { "selected-option": _vm.logSelectedInbox }
                }),
                _vm._v(" "),
                _c("tags-filter", {
                  attrs: {
                    value: "All Tags",
                    "toggle-filter": _vm.changeLabelId
                  },
                  on: { "selected-option": _vm.logSelectedTag }
                }),
                _vm._v(" "),
                _c("woot-single-selector", {
                  attrs: {
                    disabled: false,
                    variant: "secondary",
                    size: "medium",
                    "default-option": _vm.selectedWorkingHour,
                    "option-list": _vm.optionList,
                    "show-selected-text": false,
                    "custom-style": { width: "auto" }
                  },
                  on: { click: _vm.changeWorkingHourType }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "woot-single-selector",
              {
                attrs: {
                  "custom-button": "",
                  "option-list": _vm.reportTypeList,
                  "show-selected-text": false,
                  "custom-style": { left: 0, width: "100%" }
                },
                on: { click: _vm.downloadReport }
              },
              [
                _c(
                  "woot-base-button",
                  {
                    attrs: {
                      "front-icon": "email",
                      "back-icon": "chevronDown",
                      loading: _vm.isDownloadingReport
                    }
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s("GENERATE REPORT") + "\n        "
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "column padding-container settings-box custom-scroll",
          staticStyle: { "padding-top": "0px" }
        },
        [
          _c(
            "div",
            { staticClass: "settings-container columns" },
            [
              _vm.metricCardUIFlag
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "metrics-card-container container-width" },
                      _vm._l(_vm.metricsCount.slice(0, 5), function(
                        item,
                        index
                      ) {
                        return _c("metrics-card-skeleton", { key: index })
                      }),
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "metrics-card-container" },
                      _vm._l(_vm.metricsCount.slice(-4), function(item, index) {
                        return _c("metrics-card-skeleton", { key: index })
                      }),
                      1
                    )
                  ])
                : _c("div", [
                    _c(
                      "div",
                      { staticClass: "metrics-card-container container-width" },
                      _vm._l(_vm.metricsCount.slice(0, 5), function(
                        item,
                        index
                      ) {
                        return _c("metrics-card", {
                          key: index,
                          attrs: {
                            title: item.title,
                            "sub-title": item.sub,
                            unit: item.unit,
                            color: item.color,
                            actual: item.actual
                          }
                        })
                      }),
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "metrics-card-container" },
                      _vm._l(_vm.metricsCount.slice(-4), function(item, index) {
                        return _c(
                          "metrics-card",
                          {
                            key: index,
                            attrs: {
                              title: item.title,
                              "sub-title": item.sub,
                              unit: item.unit,
                              color: item.color,
                              actual: item.actual
                            }
                          },
                          [
                            item.extra
                              ? _c("div", {
                                  staticClass:
                                    "title-h5_m text-light extra-info",
                                  domProps: { innerHTML: _vm._s(item.extra) }
                                })
                              : _vm._e()
                          ]
                        )
                      }),
                      1
                    )
                  ]),
              _vm._v(" "),
              _vm.lineChartUIFlag
                ? _c(
                    "div",
                    [
                      _c("line-chart-skeleton", {
                        attrs: { "chart-height": "17rem" }
                      })
                    ],
                    1
                  )
                : _c("div", [
                    _c(
                      "div",
                      { staticClass: "lime-card-1dp bottom-container" },
                      [
                        _c(
                          "div",
                          { staticStyle: { position: "relative" } },
                          [
                            _c("span", { staticClass: "table-title" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      "ANALYTICS.TICKET_OVERVIEW.AGENT_STATES"
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "tooltip-position" },
                              [
                                _c("icons", {
                                  attrs: {
                                    name: "info",
                                    size: "medium",
                                    color: "grey"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "line-chart-legend title-h5" },
                              [
                                _c("div", { staticClass: "green-icon" }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t("ANALYTICS.TICKET_OVERVIEW.TOTAL")
                                    ) +
                                    "\n            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("line-chart", {
                              attrs: {
                                "chart-height": "230",
                                series: _vm.chartSeries,
                                categories: _vm.chartCategories,
                                colors: _vm.chartColors,
                                "show-marker": false,
                                "toggle-data": false,
                                "highlight-data": false,
                                "show-legend": false,
                                "toolbar-enable": false
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ]),
              _vm._v(" "),
              _vm.handoffTrendUIFlag
                ? _c("div", [_c("line-chart-skeleton")], 1)
                : _c("div", { staticClass: "medium-12 bottom-container" }, [
                    _c(
                      "div",
                      { staticClass: "lime-card-1dp medium-12" },
                      [
                        _c("div", { staticStyle: { position: "relative" } }, [
                          _c("span", { staticClass: "table-title" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(
                                    "ANALYTICS.TICKET_OVERVIEW.AGENT_HANDOFF"
                                  )
                                ) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "tooltip-position" },
                            [
                              _c("icons", {
                                attrs: {
                                  name: "info",
                                  size: "medium",
                                  color: "grey"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("line-chart", {
                          staticClass: "agentHandoffChart",
                          staticStyle: { "margin-left": "1rem" },
                          attrs: {
                            series: _vm.handOffChartSeries,
                            categories: _vm.handOffChartCategories,
                            colors: _vm.handOffChartColors,
                            "toolbar-enable": false
                          }
                        })
                      ],
                      1
                    )
                  ]),
              _vm._v(" "),
              _c(
                "woot-modal",
                {
                  attrs: {
                    show: _vm.isDownloadingReport,
                    "on-close": _vm.hidePopup
                  },
                  on: {
                    "update:show": function($event) {
                      _vm.isDownloadingReport = $event
                    }
                  }
                },
                [
                  _c("div", { staticClass: "download-note" }, [
                    _c("img", {
                      attrs: {
                        src:
                          "https://s3.ap-south-1.amazonaws.com/cdn.limechat.ai/assets/images/limechat_report_sent.svg"
                      }
                    }),
                    _vm._v(" "),
                    _c("div", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("ANALYTICS.DOWNLOAD_REPORT.EMAIL", {
                            email: _vm.userEmail
                          })
                        )
                      }
                    })
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "woot-modal",
                {
                  attrs: { show: _vm.downloadError, "on-close": _vm.hidePopup },
                  on: {
                    "update:show": function($event) {
                      _vm.downloadError = $event
                    }
                  }
                },
                [
                  _c("div", { staticClass: "download-note" }, [
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.$t("ANALYTICS.DOWNLOAD_REPORT.REPORT_FAIL"))
                      )
                    ])
                  ])
                ]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }