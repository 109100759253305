var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(_vm.inputComponent, {
    tag: "component",
    attrs: {
      value: _vm.value,
      error: _vm.error,
      label: _vm.label,
      "data-type": _vm.dataType,
      choices: _vm.options,
      validator: _vm.validator
    },
    on: { "update-value": _vm.updateValue }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }