var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "columns small-12" },
    [
      !_vm.hasLoginStarted
        ? _c(
            "page-header",
            { attrs: { "header-title": _vm.$t("INBOX_MGMT.ADD.FB.TITLE_FB") } },
            [
              _c("reference-box", {
                staticClass: "reference-box",
                attrs: {
                  to: _vm.documentation.channels.facebook,
                  content: "Know how to create a facebook inbox here",
                  type: "document"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasLoginStarted
        ? _c("div", { staticClass: "login-init full-height" }, [
            _c("a", { attrs: { href: "#" }, on: { click: _vm.startLogin } }, [
              _c("img", {
                attrs: {
                  src: require("dashboard/assets/images/channels/facebook_login.png"),
                  alt: "Facebook-logo"
                }
              })
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.useInstallationName(_vm.$t("INBOX_MGMT.ADD.FB.HELP"))
                  ) +
                  "\n    "
              )
            ])
          ])
        : _c(
            "div",
            [
              _vm.showLoader && !_vm.inboxCreated
                ? _c("loading-state", {
                    attrs: { message: _vm.emptyStateMessage }
                  })
                : !_vm.showLoader && !_vm.inboxCreated
                ? _c(
                    "div",
                    [
                      _c("page-header", {
                        attrs: {
                          "header-title": _vm.$t(
                            "INBOX_MGMT.ADD.DETAILS.TITLE"
                          ),
                          "header-content": _vm.useInstallationName(
                            _vm.$t("INBOX_MGMT.ADD.DETAILS.DESC")
                          )
                        }
                      }),
                      _vm._v(" "),
                      _c("page-header", {
                        attrs: {
                          size: "medium",
                          "header-title": _vm.$t(
                            "INBOX_MGMT.ADD.FB.CHOOSE_PAGE"
                          )
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "form",
                        {
                          staticClass: "row",
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.createChannel($event)
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "medium-12 columns" }, [
                            _vm.selectedPages.length
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "settings-sub-menu-content--larger medium-12 agent-container"
                                  },
                                  _vm._l(_vm.selectedPages, function(
                                    page,
                                    index
                                  ) {
                                    return _c("woot-chips", {
                                      key: page.id,
                                      attrs: {
                                        title: page.name,
                                        "show-cancel-icon": true,
                                        variant: "secondary-small",
                                        "bg-color": "#CDF0A2"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.onRemovePage(index)
                                        }
                                      }
                                    })
                                  }),
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "settings-sub-menu-content--larger medium-12 columns content-margin"
                              },
                              [
                                _c(
                                  "selector",
                                  {
                                    attrs: {
                                      "custom-button": true,
                                      "hide-on-select": false,
                                      "option-list": _vm.getSelectablePages,
                                      "custom-style": {
                                        top: "4.8rem",
                                        left: "auto",
                                        width: "100%"
                                      }
                                    },
                                    on: { click: _vm.selectPage }
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "selector-button",
                                        attrs: { type: "button" }
                                      },
                                      [
                                        _c("span", {
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(
                                                "INBOX_MGMT.ADD.FB.PICK_A_VALUE"
                                              )
                                            )
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("icons", {
                                          attrs: {
                                            name: "chevronDown",
                                            color: "grey",
                                            size: "semimedium"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.$v.selectedPages.$error
                                  ? _c(
                                      "span",
                                      { staticClass: "desc-text capital_c3" },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.$t(
                                                "INBOX_MGMT.ADD.FB.CHOOSE_PLACEHOLDER"
                                              )
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "settings-sub-menu-content--larger flex-row flex-justify--end"
                              },
                              [
                                _c(
                                  "woot-base-button",
                                  {
                                    attrs: {
                                      disabled: !_vm.selectedPages.length,
                                      type: "submit"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              Create Inbox\n            "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c(
                        "page-header",
                        {
                          staticClass: "flex-column flex-align--start",
                          attrs: { "header-title": "Inboxes Created!" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "body-b2 text-light" },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    " You can add agents to the Inbox by going to Inbox settings or"
                                  ) +
                                  "\n          "
                              ),
                              _c(
                                "router-link",
                                { attrs: { to: _vm.routeState } },
                                [_vm._v("\n            Click here\n          ")]
                              ),
                              _vm._v(
                                "\n          " +
                                  _vm._s("to go to conversations.") +
                                  "\n        "
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "table-container settings-sub-menu-content--larger"
                        },
                        [
                          _c("data-table", {
                            attrs: {
                              actions: _vm.actionsList,
                              columns: _vm.tableColumns,
                              "table-data": _vm.inboxList,
                              loading: !_vm.inboxList.length,
                              _class: "scrollable-table custom-scroll"
                            },
                            on: { edit: _vm.openInboxSettings }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "medium-12 columns settings-sub-menu-bottom-nav" },
        [
          _c(
            "woot-base-button",
            {
              attrs: { variant: "secondary" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return (function() {
                    return _vm.$router.go(-1)
                  })($event)
                }
              }
            },
            [_vm._v("\n      Back\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }