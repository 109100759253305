<template>
  <div class="bubble">
    <div
      v-if="withHeader"
      class="reply-header flex-row flex-align flex-justify--between gap--six"
    >
      <div class="flex-row flex-align gap--six">
        <thumbnail
          :username="replierName"
          bg-color="#6bac1b"
          color="#fff"
          size="20px"
        />
        <span
          style="white-space: nowrap;"
          class="title-h6 text-dark"
          v-text="replierName"
        />
        <span class="body-b3 text-light line-clamp-1">replied</span>
        <span
          v-if="!isIncoming && recipientName"
          class="body-b3 text-light line-clamp-1"
        >
          to {{ recipientName }}'s comment
        </span>
      </div>
      <woot-single-selector
        v-if="actionsList.length"
        variant="tertiary"
        :custom-style="{
          width: 'auto',
          left: 'auto',
          right: isIncoming ? 'auto' : '0.8rem',
          borderRadius: '0.8rem',
        }"
        :option-list="actionsList"
        icon-size-prop="semimedium"
        icon="threeDot"
        :show-selected-text="false"
        :show-option-name="false"
        @click="value => $emit('onActionSelected', value)"
      />
    </div>
    <div
      class="reply-container mg-bottom--small"
      :class="theme"
      @click="onClickReplyMessage"
    >
      <span class="title-h5 reply-text">Reply</span>
      <span
        class="text-light body-b3 line-clamp-3"
        v-text="parentMessageContent"
      />
    </div>
    <template v-if="attachment">
      <component
        :is="attachment.file_type === 'image' ? 'bubble-image' : 'bubble-file'"
        :url="attachment.data_url"
        :has-attached-content="!!content"
        :message="content"
      />
    </template>
    <span v-else class="message-span" v-html="formatMessage(content)" />
    <woot-base-button
      v-if="showClose"
      class="close-icon"
      tag="span"
      variant="tertiary-danger"
      @click="$emit('onClose')"
    >
      <icons name="closeRound" color="red" size="normal" :show-title="false" />
    </woot-base-button>
    <slot />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Thumbnail from 'dashboard/components/widgets/Thumbnail';
import BubbleImage from '../bubble/Image';
import BubbleFile from '../bubble/File';

import alertMixin from 'shared/mixins/alertMixin';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';

import { MESSAGE_TYPE } from 'shared/constants/messages';

export default {
  components: { Thumbnail, BubbleImage, BubbleFile },
  mixins: [alertMixin, messageFormatterMixin],
  props: {
    withHeader: {
      type: Boolean,
      default: false,
    },
    showClose: { type: Boolean, default: false },
    theme: {
      type: String,
      default: 'whatsapp',
    },
    getReplyData: {
      type: [Function, Object],
      required: true,
    },
    actionsList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      parentMessageSourceId: null,
      parentMessageContent: '',
      content: '',
      replierName: '',
      recipientName: '',
      messageType: '',
    };
  },
  computed: {
    ...mapGetters(['getAllConversations', 'getSelectedChat']),
    isIncoming() {
      return this.messageType === MESSAGE_TYPE.INCOMING;
    },
    replyData() {
      return this.getReplyData().payload;
    },
    attachment() {
      if (this.replyData?.attachments?.length === 0) return null;

      const { data_url = '', file_type = '' } = this.replyData?.attachments[0];
      if (!data_url) return null;

      return { data_url, file_type };
    },
    currentConversation() {
      return this.getAllConversations.find(
        c => c.id === this.getSelectedChat.id
      );
    },
  },
  mounted() {
    // init states with reply data
    Object.assign(this, this.replyData);
  },
  methods: {
    async onClickReplyMessage() {
      const findParentMessage = () => {
        return this.currentConversation.messages.find(
          message =>
            (message.ums_message_id ?? message.source_id) ===
            this.parentMessageSourceId
        );
      };

      let parentMessage = findParentMessage();

      if (!parentMessage) {
        await this.fetchPreviousMessages();
        parentMessage = findParentMessage();
      }

      if (parentMessage) {
        this.navigateToParentMessage(parentMessage.id);
      }
    },
    navigateToParentMessage(id) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        element.classList.add('highlight-message');
        setTimeout(() => element.classList.remove('highlight-message'), 3000);
      }
    },
    fetchPreviousMessages() {
      return this.$store.dispatch('fetchPreviousMessages', {
        conversationId: this.getSelectedChat.id,
        before: this.currentConversation.messages[0].id,
        referenced_message_id: this.parentMessageSourceId,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.bubble {
  position: relative;
}

.reply-header {
  margin-bottom: $space-smaller;
}

.reply-container {
  background-color: $pg-light-1;
  border-left: $space-smaller solid $pg-1-200;
  border-radius: $border-radius-smaller;
  cursor: pointer;
  padding: $space-small;

  &.facebook {
    background-color: $neutral-grey-200;
    border-left-color: $facebook-blue;

    .reply-text {
      color: $facebook-blue;
    }
  }

  &.instagram {
    background-color: $neutral-grey-200;
    border-left-color: $insta-grad-3;

    .reply-text {
      color: $insta-grad-3;
    }
  }

  &.whatsapp {
    background-color: $pg-light-1;
    border-left-color: $pg-1-200;

    .reply-text {
      color: $pg-1-600;
    }
  }
}

.close-icon {
  position: absolute;
  top: $zero;
  right: $zero;
}
</style>
