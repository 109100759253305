var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view-box columns" },
    [
      _c("settings-header", {
        attrs: {
          icon: "megaphone",
          "header-title": _vm.$t("BROADCAST.HEADER"),
          "show-back-button": false
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "settings-box flex-row" },
        [
          _c("div", { staticClass: "small-12" }, [
            _c(
              "div",
              { staticClass: "settings-button flex-row flex-justify--end" },
              [
                _c(
                  "woot-base-button",
                  {
                    attrs: { "front-icon": "add" },
                    on: { click: _vm.addContact }
                  },
                  [_vm._v("\n          Add Contact\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mg-left" },
                  [
                    _c(
                      "woot-base-button",
                      {
                        attrs: { "front-icon": "download" },
                        on: { click: _vm.downloadOptOuts }
                      },
                      [_vm._v("\n            Download list\n          ")]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "table-container lime-card-1dp custom-scroll mg-top",
                staticStyle: {
                  padding: "0",
                  margin: "0.1rem 0.2rem 0.1rem 0.1rem"
                }
              },
              [
                _c("table", { staticClass: "woot-table" }, [
                  _c(
                    "thead",
                    _vm._l(_vm.$t("BROADCAST.OPT_OUT.TABLE_HEADER"), function(
                      thHeader
                    ) {
                      return _c("th", { key: thHeader }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(thHeader) +
                            "\n            "
                        )
                      ])
                    }),
                    0
                  ),
                  _vm._v(" "),
                  !_vm.isEmpty
                    ? _c(
                        "tbody",
                        _vm._l(_vm.records, function(contact) {
                          return _c(
                            "tr",
                            {
                              key: contact.id,
                              staticStyle: { "border-bottom": "none" }
                            },
                            [
                              _c("td", { staticClass: "body-b2 text-dark" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(contact.name) +
                                    "\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "body-b2 text-dark" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(contact.phone_number || "-") +
                                    "\n              "
                                )
                              ])
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _vm.isEmpty
                  ? _c("div", { staticClass: "no-data flex-column-center" }, [
                      _c("div", [
                        _c("img", {
                          attrs: {
                            src: require("dashboard/assets/images/noData.svg"),
                            alt: "No Data",
                            height: "100px",
                            width: "100px"
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "no-items-error-message" }, [
                        _vm._v("\n            No Contacts Found\n          ")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isEmpty
                  ? _c("table-footer", {
                      attrs: {
                        "on-page-change": _vm.onPageChange,
                        "current-page": Number(_vm.meta.currentPage),
                        "total-count": _vm.meta.count,
                        "page-size": 8
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ]),
          _vm._v(" "),
          _vm.uiFlags.isFetching
            ? _c("woot-loading-state", { attrs: { message: "Loading" } })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: {
            show: _vm.showContactModal,
            "on-close": _vm.hideContactModal
          },
          on: {
            "update:show": function($event) {
              _vm.showContactModal = $event
            }
          }
        },
        [
          _c("woot-modal-header", {
            attrs: { "header-title": "Add contact to be opted out" }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { padding: "0rem 2.4rem 2.4rem 2.4rem" } },
            [
              _c("woot-input", {
                staticClass: "medium-12 columns content-margin",
                class: { error: _vm.$v.contactNo.$error },
                attrs: {
                  "has-error": _vm.$v.contactNo.$error,
                  error: _vm.$t("INBOX_MGMT.ADD.WEBSITE_CHANNEL.PHONE.ERROR"),
                  label: "Phone Number",
                  placeholder: "Add phone number to be opted out\n          "
                },
                on: { blur: _vm.$v.contactNo.$touch },
                model: {
                  value: _vm.contactNo,
                  callback: function($$v) {
                    _vm.contactNo = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "contactNo"
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "flex-row-justify-center",
                  staticStyle: { "justify-content": "flex-end" }
                },
                [
                  _c("woot-primary-button", {
                    attrs: {
                      name: "Add Contact",
                      "front-icon": "add",
                      disabled:
                        _vm.contactNo === "" ||
                        _vm.$v.contactNo.$error ||
                        !_vm.contactNo
                    },
                    on: { click: _vm.addPhoneNumber }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }