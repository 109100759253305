var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lime-card-1dp medium-12" },
    [
      _c(
        "span",
        { staticClass: "table-title flex-row-justify-center" },
        [
          _c("icons", {
            attrs: { name: "chevronLeft", color: "green", size: "mediumlarge" },
            on: {
              click: function($event) {
                return _vm.$emit("select-summary")
              }
            }
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticStyle: { "margin-left": "1.2rem", "align-items": "center" }
            },
            [_vm._v("\n      " + _vm._s(_vm.agentName) + "\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("table", { staticClass: "metrics-table" }, [
        _c(
          "tr",
          _vm._l(_vm.headerList, function(item) {
            return _c("th", { key: item.id }, [
              _vm._v("\n        " + _vm._s(item.name) + "\n      ")
            ])
          }),
          0
        ),
        _vm._v(" "),
        !_vm.uiFlag && _vm.attendanceLogs && _vm.attendanceLogs["logs"].length
          ? _c(
              "tbody",
              _vm._l(_vm.attendanceLogs["logs"], function(item, index) {
                return _c("tr", { key: index }, [
                  _c(
                    "td",
                    { class: "status-color status-color__" + item["Status"] },
                    [
                      _vm._v(
                        "\n          " + _vm._s(item["Status"]) + "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.humanReadableTime(item["Update Time"])) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.formattedDuration(
                          _vm.secondsToDuration(
                            parseInt(item["Time in State"] * 60, 10)
                          )
                        )
                      )
                    }
                  })
                ])
              }),
              0
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      !(_vm.attendanceLogs && _vm.attendanceLogs["logs"].length) && !_vm.uiFlag
        ? _c("p", { staticClass: "no-items-error-message" }, [
            _vm._v("\n    " + _vm._s("No data found") + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.uiFlag
        ? _c("woot-loading-state", {
            attrs: { message: "Fetching agent logs" }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }