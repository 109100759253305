var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content-margin" }, [
      _c("h1", {
        staticClass: "title-h1 text-dark",
        domProps: {
          innerHTML: _vm._s(_vm.$t("ONBOARDING.WHATSAPP.SETUP.HEADING"))
        }
      }),
      _vm._v(" "),
      _c("span", {
        staticClass: "body-b2 text-light",
        domProps: {
          innerHTML: _vm._s(_vm.$t("ONBOARDING.WHATSAPP.SETUP.SUB_HEADING"))
        }
      })
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "flex-row flex-justify--between gap--large flex-align--start"
      },
      [
        _c(
          "div",
          [
            _c("span", { staticClass: "body-b2 text-light" }, [
              _vm._v("\n        Live in\n      ")
            ]),
            _vm._v(" "),
            _vm.timerTime !== null
              ? _c("call-timer", {
                  ref: "countDown",
                  staticClass: "countdown",
                  attrs: { counter: _vm.timerTime },
                  on: {
                    "update:counter": function($event) {
                      _vm.timerTime = $event
                    }
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c("reference-box", {
          staticClass: "reference-box content-margin",
          class: _vm.currentStatus.class,
          attrs: {
            "icon-size": _vm.currentStatus.iconSize,
            "view-box": _vm.currentStatus.viewBox,
            "icon-name": _vm.currentStatus.iconName,
            "icon-color": _vm.currentStatus.iconColor,
            content: _vm.currentStatus.content
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "flex-row flex-justify--end gap--small" },
      [
        _c(
          "woot-base-button",
          {
            attrs: { variant: _vm.timeLimitExceeded ? "tertiary" : "primary" },
            on: { click: _vm.onCloseModal }
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("ONBOARDING.WHATSAPP.SETUP.EXPLORE_HELPDESK")) +
                "\n    "
            )
          ]
        ),
        _vm._v(" "),
        _vm.timeLimitExceeded
          ? _c("connect-button", {
              staticClass: "navigation-button",
              attrs: {
                label: _vm.$t("ONBOARDING.WHATSAPP.SETUP.RETRY"),
                "query-parameters": {
                  redirect_url: _vm.setRedirectUrl(),
                  state: _vm.inbox.phone_number
                }
              }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }