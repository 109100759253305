var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal-new",
    {
      attrs: {
        show: true,
        "on-close": function() {
          return _vm.$emit("onClose")
        }
      }
    },
    [
      _c("woot-modal-header-new", {
        attrs: { "header-title": "Import Template Responses" }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal-content-wrapper flex-column flex-justify--between"
        },
        [
          _c("div", { staticClass: "flex-column gap--normal" }, [
            _c("div", {
              staticClass: "illustration-container",
              style: {
                backgroundImage:
                  "url(" + require("assets/images/import_template.svg") + ")"
              }
            }),
            _vm._v(" "),
            _c("span", {
              staticClass: "body-b2 text-light",
              domProps: {
                textContent: _vm._s(
                  "Please upload the Template responses in the given format. If you don’t have a template ready, feel free to download here and fill & upload accordingly"
                )
              }
            })
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-row flex-justify--between" },
            [
              _c(
                "woot-base-button",
                {
                  attrs: { to: "/example_template.csv", variant: "secondary" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.InlineButtonClickHandler($event)
                    }
                  }
                },
                [_vm._v("\n        Download Template\n      ")]
              ),
              _vm._v(" "),
              _c(
                "woot-base-button",
                {
                  staticClass: "custom-import-btn",
                  attrs: { loading: _vm.isUploading }
                },
                [
                  _c(
                    "file-upload",
                    {
                      attrs: { size: 4096 * 4096, accept: ".csv" },
                      on: { "input-file": _vm.onFileUpload }
                    },
                    [_vm._v("\n          Upload Csv\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }