var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "automation-header flex-row flex-align--end flex-justify--between gap--small"
    },
    [
      _c("div", { staticClass: "flex-row flex-align--start" }, [
        _c(
          "span",
          {
            staticClass: "mg-right--small",
            staticStyle: { cursor: "pointer" }
          },
          [
            _c("icons", {
              attrs: { name: "chevronLeft", color: "grey", size: "medium" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.onBack($event)
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          _vm._g(
            {
              staticClass: "automation-header--content",
              class: { disabled: _vm.disabled }
            },
            _vm.disabled ? {} : { click: _vm.onClick }
          ),
          [
            _c("div", { staticClass: "flex-row flex-align gap--small" }, [
              _c("h2", {
                staticClass: "title-h2 text-light AUTOMATION_NAME",
                domProps: {
                  textContent: _vm._s(_vm.name || _vm.namePlaceholder)
                }
              }),
              _vm._v(" "),
              !_vm.disabled
                ? _c(
                    "span",
                    { staticStyle: { cursor: "pointer" } },
                    [
                      _c("icons", {
                        attrs: {
                          name: "editRound",
                          color: "grey",
                          size: "semimedium"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("span", {
              staticClass: "body-b2 text-light line-clamp-1",
              domProps: {
                textContent: _vm._s(
                  _vm.description || _vm.descriptionPlaceholder
                )
              }
            })
          ]
        )
      ]),
      _vm._v(" "),
      _vm._t("right-section")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }