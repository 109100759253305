import { render, staticRenderFns } from "./EducationalOnboarding.vue?vue&type=template&id=492ad3b8&scoped=true&"
import script from "./EducationalOnboarding.vue?vue&type=script&lang=js&"
export * from "./EducationalOnboarding.vue?vue&type=script&lang=js&"
import style0 from "./EducationalOnboarding.vue?vue&type=style&index=0&id=492ad3b8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "492ad3b8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/limechat/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('492ad3b8')) {
      api.createRecord('492ad3b8', component.options)
    } else {
      api.reload('492ad3b8', component.options)
    }
    module.hot.accept("./EducationalOnboarding.vue?vue&type=template&id=492ad3b8&scoped=true&", function () {
      api.rerender('492ad3b8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/lime-ui/dashboard/components/ui/settings/EducationalOnboarding.vue"
export default component.exports