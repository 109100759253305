import { render, staticRenderFns } from "./BotPerformance.vue?vue&type=template&id=580d99e4&scoped=true&"
import script from "./BotPerformance.vue?vue&type=script&lang=js&"
export * from "./BotPerformance.vue?vue&type=script&lang=js&"
import style0 from "./BotPerformance.vue?vue&type=style&index=0&id=580d99e4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "580d99e4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/limechat/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('580d99e4')) {
      api.createRecord('580d99e4', component.options)
    } else {
      api.reload('580d99e4', component.options)
    }
    module.hot.accept("./BotPerformance.vue?vue&type=template&id=580d99e4&scoped=true&", function () {
      api.rerender('580d99e4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/lime-ui/dashboard/routes/dashboard/analytics/BotPerformance.vue"
export default component.exports