var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "personal-details-container" }, [
    _c("form", { staticClass: "settings-form custom-scroll" }, [
      _c(
        "div",
        {
          staticClass:
            "flex-row medium-12 flex-justify--between content-margin gap--small"
        },
        [
          _c("woot-input", {
            staticClass: "half-width",
            class: { error: _vm.$v.agentNameVal.$error },
            attrs: {
              size: "small",
              "has-error": _vm.$v.agentNameVal.$error,
              error:
                _vm.agentNameVal === ""
                  ? _vm.$t("INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_NAME.ERROR")
                  : _vm.$t("INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_NAME.ERROR"),
              label: _vm.$t("AGENT_MGMT.ADD.FORM.AGENT_NAME.LABEL"),
              placeholder: _vm.$t("AGENT_MGMT.ADD.FORM.AGENT_NAME.PLACEHOLDER"),
              "auto-focus": ""
            },
            on: {
              blur: _vm.$v.agentNameVal.$touch,
              input: function($event) {
                return _vm.$emit("update:agentName", _vm.agentNameVal)
              }
            },
            model: {
              value: _vm.agentNameVal,
              callback: function($$v) {
                _vm.agentNameVal = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "agentNameVal"
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "half-width",
              class: { disabled: _vm.shouldDisableFeature }
            },
            [
              _c(
                "label",
                {
                  staticClass: "formlabel flex-row flex-justify--between gap-4"
                },
                [
                  _c("span", {
                    staticClass: "title-h5 text-dark",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("AGENT_MGMT.ADD.FORM.AGENT_TYPE.LABEL")
                      )
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c("woot-single-selector", {
                attrs: {
                  size: "large",
                  "option-list": _vm.roles,
                  "default-option": _vm.getAgentTypeLabel,
                  "custom-style": {
                    top: "4.8rem",
                    left: "auto",
                    width: "100%"
                  },
                  "dropdown-container-style": { height: "3.6rem" }
                },
                on: { click: _vm.changeAgentType }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "flex-row medium-12 flex-justify--between content-margin gap--small"
        },
        [
          _c("woot-input", {
            class: { error: _vm.$v.agentEmailVal.$error },
            attrs: {
              size: "small",
              "has-error": _vm.$v.agentEmailVal.$error,
              error:
                _vm.agentEmailVal === ""
                  ? _vm.$t("INBOX_MGMT.COMMON_ERROR_MESSAGE")
                  : _vm.$t("AGENT_MGMT.ADD.FORM.EMAIL.ERROR_MESSAGE"),
              label: _vm.$t("AGENT_MGMT.ADD.FORM.EMAIL.LABEL"),
              placeholder: _vm.$t("AGENT_MGMT.ADD.FORM.EMAIL.PLACEHOLDER"),
              disabled: _vm.shouldDisableFeature,
              "full-width": ""
            },
            on: {
              blur: _vm.$v.agentEmailVal.$touch,
              input: function($event) {
                return _vm.$emit("update:agentEmail", _vm.agentEmailVal)
              }
            },
            model: {
              value: _vm.agentEmailVal,
              callback: function($$v) {
                _vm.agentEmailVal = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "agentEmailVal"
            }
          }),
          _vm._v(" "),
          _c("woot-input", {
            class: { error: _vm.$v.phoneNumberVal.$error },
            attrs: {
              size: "small",
              type: "Number",
              "has-error": _vm.$v.phoneNumberVal.$error,
              error:
                _vm.phoneNumberVal === ""
                  ? _vm.$t("INBOX_MGMT.COMMON_ERROR_MESSAGE")
                  : _vm.$t("AGENT_MGMT.ADD.FORM.PHONE_NUMBER.ERROR"),
              label: _vm.$t("AGENT_MGMT.ADD.FORM.PHONE_NUMBER.LABEL"),
              placeholder: _vm.$t(
                "AGENT_MGMT.ADD.FORM.PHONE_NUMBER.PLACEHOLDER"
              ),
              "full-width": ""
            },
            on: {
              blur: _vm.$v.phoneNumberVal.$touch,
              input: function($event) {
                return _vm.$emit("update:phoneNumber", _vm.phoneNumberVal)
              }
            },
            model: {
              value: _vm.phoneNumberVal,
              callback: function($$v) {
                _vm.phoneNumberVal = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "phoneNumberVal"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "medium-12 content-margin" },
        [
          _c("div", { staticClass: "flex-row flex-align" }, [
            _c(
              "label",
              {
                staticClass: "formlabel",
                class: { error: _vm.$v.inboxIdsVal.$error }
              },
              [
                _c("span", {
                  staticClass: "title-h5 text-dark",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("AGENT_MGMT.EDIT.FORM.INBOX.LABEL")
                    )
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: "Select Inbox for Agent",
                    expression: "'Select Inbox for Agent'"
                  }
                ],
                staticClass: "mg-bottom--smaller mg-left--small"
              },
              [
                _c("icons", {
                  attrs: {
                    name: "warning",
                    color: "grey evenodd",
                    size: "semimedium",
                    "show-title": false
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("woot-single-selector", {
            attrs: {
              size: "large",
              "option-list": _vm.inactiveInbox,
              "default-option": "Select Inbox for Agent",
              "custom-style": {
                top: "4.8rem",
                left: "auto",
                width: "100%",
                maxHeight: "16rem"
              },
              "dropdown-container-style": { height: "3.6rem" }
            },
            on: { click: _vm.addInbox }
          }),
          _vm._v(" "),
          _vm.inboxIdsVal.length
            ? _c(
                "div",
                { staticClass: "medium-12 agent-container custom-scroll" },
                _vm._l(_vm.inboxIdsVal, function(inbox, index) {
                  return _c("woot-chips", {
                    key: inbox.id,
                    attrs: {
                      title: inbox.name,
                      variant: "secondary-small",
                      "show-cancel-icon": "",
                      "show-tooltip": false,
                      "custom-style": {
                        "border-color": _vm.getInboxMeta(inbox).hex,
                        color: _vm.getInboxMeta(inbox).hex
                      },
                      "front-icon": _vm.getInboxMeta(inbox).name,
                      "front-icon-color": _vm.getInboxMeta(inbox).color
                    },
                    on: {
                      click: function($event) {
                        return _vm.onRemoveInbox(index)
                      }
                    }
                  })
                }),
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      !!_vm.agentId
        ? _c(
            "div",
            { staticClass: "medium-12 content-margin" },
            [
              _c("label", { staticClass: "formlabel" }, [
                _c("span", {
                  staticClass: "title-h5_m text-light",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("AGENT_MGMT.EDIT.PASSWORD_RESET.ADMIN_RESET_TEXT")
                    )
                  }
                })
              ]),
              _vm._v(" "),
              _c(
                "woot-base-button",
                {
                  attrs: { size: "small", variant: "secondary" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.resetPassword($event)
                    }
                  }
                },
                [
                  _c("i", { staticClass: "ion-locked" }),
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t(
                          "AGENT_MGMT.EDIT.PASSWORD_RESET.ADMIN_RESET_BUTTON"
                        )
                      ) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }