var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loadingState
    ? _c(
        "div",
        { staticClass: "small-12 custom-scroll center-aligned" },
        [
          _c("woot-loading-state", {
            attrs: { message: "Fetching Plan details" }
          })
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "column settings-box  billing-box" },
        [
          _c("div", [
            _c(
              "div",
              { staticClass: "button-large text-dark flex-row mg-top" },
              [
                _c(
                  "span",
                  {
                    staticClass: "mg-right--smaller go-back",
                    on: { click: _vm.goToCurrentBillPage }
                  },
                  [
                    _c("icons", {
                      attrs: {
                        name: "chevronLeft",
                        color: "green",
                        size: "medium"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("BILLING.CHOOSE_PLAN")) + "\n    "
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "mg-top mg-left--large" }, [
              _c("span", { staticClass: "subtitle-s2 text-dark" }, [
                _vm._v("\n        " + _vm._s(_vm.$t("BILLING.DESC_TEXT"))),
                _c("br"),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("BILLING.DESC_TEXT_TWO")) +
                    "\n      "
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "column plans-box settings-box custom-scroll" },
            [
              _c(
                "div",
                { staticClass: "plans-container" },
                _vm._l(_vm.plans, function(plan) {
                  return _c("div", { key: plan.id, staticClass: "plan-box" }, [
                    _c(
                      "div",
                      {
                        staticClass: "title-h4 text-dark",
                        staticStyle: { "text-transform": "uppercase" }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.planTitle(plan)) +
                            "\n        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "title-h1 blue-text mg-top",
                        staticStyle: { "text-align": "left" }
                      },
                      [
                        _vm._v(
                          "\n          $" + _vm._s(plan.price) + "/mo\n        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    plan.name === _vm.$t("BILLING.ENTERPRISE_PLAN") ||
                    plan.custom_plan_identifier
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "title-h1 blue-text mg-top custom-text",
                            staticStyle: { "text-align": "left" }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("BILLING.CUSTOM")) +
                                "\n        "
                            )
                          ]
                        )
                      : _c("div", [
                          _c("div", { staticClass: "title-h6_m text-light" }, [
                            _vm._v(
                              "\n            $" +
                                _vm._s(plan.extra_usage_rate) +
                                "\n            " +
                                _vm._s(_vm.$t("BILLING.EXTRA_TICKETS_TEXT")) +
                                "\n          "
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "title-h6_m text-light" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("BILLING.AGENT_CHARGE")) +
                                "\n          "
                            )
                          ])
                        ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mg-top--medium" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "capital_c1 text-light mg-bottom--small"
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.getFeatures(plan.name) &&
                                    _vm.getFeatures(plan.name).SUBTITLE
                                ) +
                                "\n          "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm._l(
                          _vm.getFeatures(plan.name) &&
                            _vm.getFeatures(plan.name).FEATURES,
                          function(feature) {
                            return _c(
                              "div",
                              {
                                key: feature,
                                staticClass: "flex-row-center mg-bottom--small"
                              },
                              [
                                _c("icons", {
                                  attrs: {
                                    name: "tickSingle",
                                    color: "green",
                                    size: "normal",
                                    view: "0 0 16 16"
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "subtitle-s2 text-dark mg-left--smaller"
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(feature) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          }
                        )
                      ],
                      2
                    ),
                    _vm._v(" "),
                    plan.name !== _vm.$t("BILLING.ENTERPRISE_PLAN")
                      ? _c("div", [
                          !_vm.isCurrentPlan(plan.name)
                            ? _c(
                                "div",
                                { staticClass: "button-position" },
                                [
                                  _c("woot-primary-button", {
                                    attrs: {
                                      name: "Choose Plan",
                                      "full-width": ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.showModal(plan.name)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _c(
                                "div",
                                { staticClass: "button-position" },
                                [
                                  _vm.showStarterPlanCta(
                                    _vm.subscriptionForShopify.trial_end,
                                    _vm.onGracePeriod
                                  ) ||
                                  _vm.isGracePeriodLastDay(
                                    _vm.gracePeriodLastDate
                                  )
                                    ? _c("woot-primary-button", {
                                        attrs: {
                                          name: "continue",
                                          "full-width": ""
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.continueWithCurrentPlan(
                                              plan.name
                                            )
                                          }
                                        }
                                      })
                                    : _c("woot-secondary-button", {
                                        attrs: {
                                          name: "current plan",
                                          "full-width": "",
                                          "custom-style": {
                                            "pointer-events": "none"
                                          }
                                        }
                                      })
                                ],
                                1
                              )
                        ])
                      : _c(
                          "div",
                          { staticClass: "button-position" },
                          [
                            _c("woot-primary-button", {
                              attrs: {
                                name: "contact sales",
                                "full-width": ""
                              },
                              on: { click: _vm.openSalesPage }
                            })
                          ],
                          1
                        )
                  ])
                }),
                0
              )
            ]
          ),
          _vm._v(" "),
          _c("woot-delete-modal", {
            attrs: {
              show: _vm.showConfirmationModal,
              "on-close": _vm.closeModal,
              "on-confirm": _vm.choosePlan,
              title: _vm.$t("BILLING.FREE_TRIAL_LAST_DAY_HEADER", {
                plan: _vm.modalPlanTitle
              }),
              message: _vm.confirmationModalContent,
              "confirm-text": _vm.$t("BILLING.CONTINUE"),
              "reject-text": _vm.$t("BILLING.BACK")
            },
            on: {
              "update:show": function($event) {
                _vm.showConfirmationModal = $event
              }
            }
          }),
          _vm._v(" "),
          _c("woot-delete-modal", {
            attrs: {
              show: _vm.showContinuePlanModal,
              "on-close": _vm.closeContinuePlanModal,
              "on-confirm": _vm.choosePlan,
              title: _vm.$t("BILLING.FREE_TRIAL_LAST_DAY_HEADER", {
                plan: _vm.modalPlanTitle
              }),
              message: _vm.continueModalContent,
              "confirm-text": _vm.$t("BILLING.CONTINUE"),
              "reject-text": _vm.$t("BILLING.BACK")
            },
            on: {
              "update:show": function($event) {
                _vm.showContinuePlanModal = $event
              }
            }
          }),
          _vm._v(" "),
          _c("woot-delete-modal", {
            attrs: {
              show: _vm.showGenericConfirmationModal,
              "on-close": _vm.closeGenericConfirmationModal,
              "on-confirm": _vm.choosePlan,
              title: _vm.$t("BILLING.FREE_TRIAL_LAST_DAY_HEADER", {
                plan: _vm.modalPlanTitle
              }),
              message:
                "You have selected  " +
                _vm.modalPlanTitle +
                " plan. Do you wish to continue to activate billing with Shopify?",
              "confirm-text": _vm.$t("BILLING.CONTINUE"),
              "reject-text": _vm.$t("BILLING.BACK")
            },
            on: {
              "update:show": function($event) {
                _vm.showGenericConfirmationModal = $event
              }
            }
          }),
          _vm._v(" "),
          _c("woot-delete-modal", {
            attrs: {
              show: _vm.showUnistallFlowModal,
              "on-close": _vm.closeUnistallFlowModal,
              title: "Download LimeChat Shopify App",
              message:
                "Your store has uninstalled the LimeChat Shopify App. Contact your Shopify store admin to reinstall the app and then select a plan.\n    ",
              "show-buttons": false,
              "reject-text": _vm.$t("BILLING.BACK")
            },
            on: {
              "update:show": function($event) {
                _vm.showUnistallFlowModal = $event
              }
            }
          }),
          _vm._v(" "),
          _c("woot-delete-modal", {
            attrs: {
              show: _vm.gracePeriodConfirmationModal,
              "on-close": _vm.closegracePeriodConfirmationModal,
              "on-confirm": _vm.choosePlan,
              title: _vm.$t("BILLING.FREE_TRIAL_LAST_DAY_HEADER", {
                plan: _vm.modalPlanTitle
              }),
              message: _vm.gracePeriodConfirmationModalContent,
              "confirm-text": _vm.$t("BILLING.CONTINUE"),
              "reject-text": _vm.$t("BILLING.BACK")
            },
            on: {
              "update:show": function($event) {
                _vm.gracePeriodConfirmationModal = $event
              }
            }
          })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }