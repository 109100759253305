var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column content-box settings-box" }, [
    _c(
      "div",
      { staticClass: "settings-container gap--normal" },
      [
        _c(
          "div",
          {
            staticClass: "column custom-scroll lime-card-1dp",
            class: { "settings-sub-menu": _vm.shouldShowKnowlarityAgents }
          },
          [_c("router-view")],
          1
        ),
        _vm._v(" "),
        _vm.shouldShowKnowlarityAgents
          ? _c(
              "settings-side-card",
              {
                attrs: { image: !_vm.shouldShowKnowlarityAgents ? "inbox" : "" }
              },
              [
                _c("knowlarity-agents", {
                  attrs: { "channel-id": _vm.currentChannel.channel_id }
                })
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }