var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "settings-container" },
    [
      _c(
        "div",
        { staticClass: "settings-button flex-row flex-align gap--normal" },
        [
          _c("reference-box", {
            attrs: {
              to: _vm.documentation.sla.resource,
              content: "Know More",
              type: "document"
            }
          }),
          _vm._v(" "),
          _c("reference-box", {
            attrs: {
              to: _vm.documentation.sla.demoVideo,
              content: "Video Demo",
              type: "video"
            }
          }),
          _vm._v(" "),
          _c(
            "woot-base-button",
            {
              attrs: {
                size: "medium",
                "front-icon": "plus",
                "icon-view-box": "0 0 20 20"
              },
              on: { click: _vm.openAddPopup }
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("SLA.CREATE.TITLE")) + "\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "sla-container column settings-main-menu-new" },
        [
          _c("toolbar", {
            attrs: { "search-term": _vm.searchTerm },
            on: {
              setSearch: function($event) {
                _vm.searchTerm = $event
              }
            }
          }),
          _vm._v(" "),
          _c("draggable-list", {
            attrs: { "selected-sla": _vm.selectedSLA, filters: _vm.filters },
            on: {
              view: function($event) {
                _vm.selectedSLA = $event
              },
              onCreate: _vm.openAddPopup
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "settings-side-card",
        [
          _vm.selectedSLA.id
            ? _c(
                "div",
                [_c("rhs-panel", { attrs: { sla: _vm.selectedSLA } })],
                1
              )
            : _c("educational-onboarding", {
                attrs: { "show-case-data": _vm.SHOWCASE_SLA_DATA }
              })
        ],
        1
      ),
      _vm._v(" "),
      _c("create-modal", {
        attrs: {
          show: _vm.showCreateModal,
          name: _vm.sla.name,
          description: _vm.sla.description
        },
        on: {
          updateDetail: _vm.onUpdateDetail,
          close: _vm.onCloseModal,
          submit: _vm.onSubmitModal
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }