var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("top-banner", { attrs: { variant: _vm.bannerVariant[0] } }, [
    _c(
      "div",
      { staticClass: "text-align flex-row flex-justify flex-align full-width" },
      [
        _c("div", {
          staticClass: "body-b2 text-align shake line-clamp-1",
          domProps: { textContent: _vm._s(_vm.bannerVariant[1]) }
        }),
        _vm._v(" "),
        _vm.isAdmin
          ? _c(
              "woot-base-button",
              {
                staticClass: "mg-left",
                attrs: {
                  size: "small",
                  loading: _vm.uiFlags.isFetchingPaymentLink
                },
                on: { click: _vm.getPaymentLink }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("BILLING.NON_SHOPIFY.PAY_NOW")) +
                    "\n    "
                )
              ]
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }