var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-scroll" },
    [
      _c(
        "woot-modal",
        {
          attrs: { show: _vm.downloading, "on-close": _vm.hidePopup },
          on: {
            "update:show": function($event) {
              _vm.downloading = $event
            }
          }
        },
        [
          _c("div", { staticClass: "download-note" }, [
            _c("img", {
              attrs: {
                src:
                  "https://s3.ap-south-1.amazonaws.com/cdn.limechat.ai/assets/images/limechat_report_sent.svg"
              }
            }),
            _vm._v(" "),
            _c("div", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.$t("ANALYTICS.DOWNLOAD_REPORT.EMAIL", {
                    email: _vm.userEmail
                  })
                )
              }
            })
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: { show: _vm.downloadError, "on-close": _vm.hidePopup },
          on: {
            "update:show": function($event) {
              _vm.downloadError = $event
            }
          }
        },
        [
          _c("div", { staticClass: "download-note" }, [
            _c("div", [
              _vm._v(_vm._s(_vm.$t("ANALYTICS.DOWNLOAD_REPORT.REPORT_FAIL")))
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "column content-box settings-box" }, [
        _c(
          "div",
          { staticClass: "settings-container columns" },
          [
            _c(
              "div",
              { staticClass: "flex-space-between" },
              [
                _c(
                  "div",
                  { staticClass: "flex-container medium-flex-dir-row" },
                  [
                    _c(
                      "div",
                      { staticClass: "mr-right" },
                      [
                        _c("report-date-range-selector", {
                          attrs: { "default-selection": 0 },
                          on: { "date-range-change": _vm.onDateRangeChange }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mr-right" },
                      [
                        _c("woot-single-selector", {
                          attrs: {
                            variant: "secondary",
                            size: "medium",
                            "option-list": _vm.timingList,
                            "default-option": _vm.selectedTiming,
                            "show-selected-text": false,
                            "custom-style": { width: "auto" }
                          },
                          on: { click: _vm.onTimingChange }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mr-right" },
                      [
                        _c("inbox-filter", {
                          attrs: {
                            value: "All Inboxes",
                            "allow-multi-select": true,
                            "exclude-inbox-types": _vm.excludedInboxTypes,
                            "toggle-filter": _vm.changeInboxId
                          }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "woot-base-button",
                  {
                    attrs: { "front-icon": "email" },
                    on: { click: _vm.download }
                  },
                  [_vm._v("\n          CSAT Report\n        ")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("csat-metrics"),
            _vm._v(" "),
            _c("csat-table")
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }