var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-column gap--small" },
    [
      _c("woot-single-selector", {
        attrs: {
          id: "inbox--filter",
          variant: "secondary",
          size: "small",
          "front-icon": "history",
          "default-option": _vm.filterTitle,
          "option-list": _vm.dateRange,
          "button-style": {
            color: "#808975",
            padding: "0.4rem"
          },
          "custom-style": { left: 0 },
          "show-selected-text": false,
          "highlight-selected": _vm.highlightDateFilter
        },
        on: { click: _vm.changeDateSelection }
      }),
      _vm._v(" "),
      _vm.isDateRangeSelected
        ? _c("woot-date-range-picker", {
            attrs: {
              "show-range": "",
              value: _vm.customDateRange,
              variant: "small",
              "disabled-future-dates": _vm.currentDate
            },
            on: { change: _vm.onChange }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }