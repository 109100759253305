var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "automations column content-box settings-box no-padding" },
    [
      _c(
        "div",
        { staticClass: "settings-button flex-row flex-align gap--normal" },
        [
          _c("reference-box", {
            attrs: {
              to: _vm.documentation.automation.resource,
              content: "Know More",
              type: "document"
            }
          }),
          _vm._v(" "),
          _c("reference-box", {
            attrs: {
              to: _vm.documentation.automation.demoVideo,
              content: "Video Demo",
              type: "video"
            }
          }),
          _vm._v(" "),
          _c(
            "woot-base-button",
            {
              attrs: {
                size: "medium",
                "front-icon": "plus",
                "icon-view-box": "0 0 20 20"
              },
              on: { click: _vm.openAddPopup }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("AUTOMATION.HEADER_BTN_TXT")) +
                  "\n    "
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "settings-container" },
        [
          _c(
            "div",
            { staticClass: "column settings-main-menu-new" },
            [
              _c("search-box", {
                attrs: {
                  placeholder: _vm.$t("AUTOMATION.SEARCH_TXT"),
                  value: _vm.search,
                  "custom-class": "search-box"
                },
                on: { setSearch: _vm.setSearch }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mg-bottom--smaller" },
                [
                  _c(
                    "woot-tabs",
                    {
                      attrs: { index: _vm.selectedTabIndex },
                      on: { change: _vm.onTabChange }
                    },
                    _vm._l(_vm.tabs, function(tab) {
                      return _c("woot-tabs-item", {
                        key: tab.key,
                        attrs: { name: tab.name }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("List", {
                key: _vm.selectedTabIndex,
                attrs: {
                  "selected-tab-index": _vm.selectedTabIndex,
                  "actions-list": _vm.actionsList,
                  "table-columns": _vm.tableColumns,
                  "automations-list": _vm.automationsList,
                  loading: _vm.isLoading,
                  search: _vm.search
                },
                on: {
                  setSearch: _vm.setSearch,
                  viewAutomation: _vm.viewAutomation,
                  openEditPopup: _vm.openEditPopup,
                  openAddPopup: _vm.openAddPopup,
                  changeSelectedTab: _vm.onTabChange,
                  openDeletePopup: _vm.openDeletePopup,
                  cloneAutomation: _vm.cloneAutomation
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "settings-side-card",
            [
              !_vm.selectedAutomation
                ? _c("educational-onboarding", {
                    attrs: { "show-case-data": _vm.SHOWCASE_AUTOMATION_DATA }
                  })
                : _c("rhs-panel", {
                    attrs: { automation: _vm.selectedAutomation },
                    on: { cloneAutomation: _vm.cloneAutomation }
                  })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "modal-fade" } },
            [
              _vm.showCreateModal
                ? _c("create-automation-modal", {
                    on: {
                      handleCreate: _vm.onHandleCreate,
                      onClose: function($event) {
                        _vm.showCreateModal = false
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "modal-fade" } },
            [
              _vm.showDetailsModal
                ? _c("automation-details-modal", {
                    attrs: {
                      opened: "",
                      name: _vm.selectedTemplateDetails.name,
                      description: _vm.selectedTemplateDetails.description
                    },
                    on: {
                      updateDetail: _vm.onUpdateTargetRoute,
                      onSubmit: _vm.onSubmitDetailsModal,
                      onClose: function($event) {
                        _vm.showDetailsModal = false
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("woot-delete-modal-new", {
            attrs: {
              show: _vm.showDeletePopup,
              "on-close": _vm.closeDeletePopup,
              "on-confirm": _vm.confirmDeletion,
              title: _vm.$t("LABEL_MGMT.DELETE.CONFIRM.TITLE"),
              message: _vm.deleteMessage,
              "show-close": "",
              "confirm-text": "Continue",
              "reject-text": "Back",
              "custom-style": {
                "max-width": "35.2rem",
                height: "auto"
              }
            },
            on: {
              "update:show": function($event) {
                _vm.showDeletePopup = $event
              }
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }