var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "pos-relative" }, [
      _c("span", { staticClass: "table-title" }, [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "tooltip-position" },
        [
          _c("icons", {
            attrs: { name: "info", size: "medium", color: "grey" }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { id: "chart" } },
      [
        _c("apexchart", {
          attrs: {
            type: "heatmap",
            width: "100%",
            height: _vm.height,
            options: _vm.chartOptions,
            series: _vm.series
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }