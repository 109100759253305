var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "profile--container" }, [
    _c(
      "form",
      {
        staticClass: "columns profile--settings custom-scroll",
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.updateUser($event)
          }
        }
      },
      [
        _c(
          "woot-base-button",
          {
            staticClass: "settings-button",
            attrs: {
              type: "submit",
              disabled: _vm.$v.$invalid,
              loading: _vm.isUpdating
            }
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("PROFILE_SETTINGS.BTN_TEXT")) +
                "\n    "
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "profile--settings--row" },
          [
            _c("woot-modal-header", {
              attrs: {
                "header-title": _vm.$t(
                  "PROFILE_SETTINGS.FORM.PROFILE_SECTION.TITLE"
                ),
                "header-content": _vm.$t(
                  "PROFILE_SETTINGS.FORM.PROFILE_SECTION.NOTE"
                )
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row form-box" },
              [
                _c(
                  "div",
                  { staticClass: "columns small-12" },
                  [
                    _c("woot-avatar-uploader", {
                      attrs: { src: _vm.avatarUrl, username: _vm.name },
                      on: { change: _vm.handleImageUpload }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("woot-input", {
                  staticClass: "small-12 medium-6 columns content-margin",
                  class: { error: _vm.$v.name.$error },
                  attrs: {
                    label: _vm.$t("PROFILE_SETTINGS.FORM.NAME.LABEL"),
                    placeholder: _vm.$t(
                      "PROFILE_SETTINGS.FORM.NAME.PLACEHOLDER"
                    )
                  },
                  on: { input: _vm.$v.name.$touch },
                  model: {
                    value: _vm.name,
                    callback: function($$v) {
                      _vm.name = typeof $$v === "string" ? $$v.trim() : $$v
                    },
                    expression: "name"
                  }
                }),
                _vm._v(" "),
                _c("woot-input", {
                  staticClass: "small-12 medium-6 columns content-margin",
                  class: { error: _vm.$v.displayName.$error },
                  attrs: {
                    label: _vm.$t("PROFILE_SETTINGS.FORM.DISPLAY_NAME.LABEL"),
                    placeholder: _vm.$t(
                      "PROFILE_SETTINGS.FORM.DISPLAY_NAME.PLACEHOLDER"
                    )
                  },
                  on: { input: _vm.$v.displayName.$touch },
                  model: {
                    value: _vm.displayName,
                    callback: function($$v) {
                      _vm.displayName =
                        typeof $$v === "string" ? $$v.trim() : $$v
                    },
                    expression: "displayName"
                  }
                }),
                _vm._v(" "),
                _c("woot-input", {
                  staticClass: "small-12 medium-6 columns content-margin",
                  class: { error: _vm.$v.email.$error },
                  attrs: {
                    label: _vm.$t("PROFILE_SETTINGS.FORM.EMAIL.LABEL"),
                    placeholder: _vm.$t(
                      "PROFILE_SETTINGS.FORM.EMAIL.PLACEHOLDER"
                    ),
                    "has-error": _vm.$v.email.$error,
                    error: _vm.$t("PROFILE_SETTINGS.FORM.EMAIL.ERROR")
                  },
                  on: { input: _vm.$v.email.$touch },
                  model: {
                    value: _vm.email,
                    callback: function($$v) {
                      _vm.email = typeof $$v === "string" ? $$v.trim() : $$v
                    },
                    expression: "email"
                  }
                }),
                _vm._v(" "),
                _c("woot-input", {
                  staticClass: "small-12 medium-6 columns content-margin",
                  class: { error: _vm.$v.phone.$error },
                  attrs: {
                    label: _vm.$t("PROFILE_SETTINGS.FORM.PHONE.LABEL"),
                    placeholder: _vm.$t(
                      "PROFILE_SETTINGS.FORM.PHONE.PLACEHOLDER"
                    ),
                    "has-error": _vm.$v.phone.$error,
                    error: _vm.$t("PROFILE_SETTINGS.FORM.PHONE.ERROR")
                  },
                  on: { input: _vm.$v.phone.$touch },
                  model: {
                    value: _vm.phone,
                    callback: function($$v) {
                      _vm.phone = typeof $$v === "string" ? $$v.trim() : $$v
                    },
                    expression: "phone"
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "columns small-12 medium-6" },
                  [
                    _c(
                      "label",
                      { staticClass: "body-b2 text-dark formlabel" },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t("PROFILE_SETTINGS.FORM.TIMEZONE.LABEL")
                            ) +
                            "\n          "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("multiselect", {
                      attrs: {
                        options: _vm.timeZones,
                        "deselect-label": "",
                        "select-label": "",
                        "selected-label": "",
                        "track-by": "value",
                        label: "label",
                        "close-on-select": true,
                        placeholder: _vm.$t(
                          "PROFILE_SETTINGS.FORM.TIMEZONE.PLACEHOLDER"
                        ),
                        "allow-empty": false
                      },
                      model: {
                        value: _vm.timezone,
                        callback: function($$v) {
                          _vm.timezone = $$v
                        },
                        expression: "timezone"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "columns small-12 medium-6" },
                  [
                    _c("woot-input", {
                      attrs: {
                        rows: "6",
                        type: "text",
                        label: _vm.$t("PROFILE_SETTINGS.FORM.ADDRESS.LABEL"),
                        placeholder: _vm.$t(
                          "PROFILE_SETTINGS.FORM.ADDRESS.PLACEHOLDER"
                        )
                      },
                      on: { input: _vm.$v.address.$touch },
                      model: {
                        value: _vm.address,
                        callback: function($$v) {
                          _vm.address =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "address"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "profile--settings--row" },
          [
            _c("woot-modal-header", {
              attrs: {
                "header-title": _vm.$t(
                  "PROFILE_SETTINGS.FORM.PASSWORD_SECTION.TITLE"
                ),
                "header-content": _vm.$t(
                  "PROFILE_SETTINGS.FORM.PASSWORD_SECTION.NOTE"
                )
              }
            }),
            _vm._v(" "),
            _c("woot-primary-button", {
              attrs: {
                name: _vm.$t(
                  "PROFILE_SETTINGS.FORM.PASSWORD_SECTION.CHANGE_PASSWORD"
                ),
                type: "button"
              },
              on: { click: _vm.onRequestPasswordChange }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("notification-settings"),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "profile--settings--row" },
          [
            _c("woot-modal-header", {
              attrs: {
                "header-title": _vm.$t(
                  "PROFILE_SETTINGS.FORM.ACCESS_TOKEN.TITLE"
                ),
                "header-content": _vm.$t(
                  "PROFILE_SETTINGS.FORM.ACCESS_TOKEN.NOTE"
                )
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "columns medium-6" },
              [
                _c("woot-code", {
                  attrs: { script: _vm.currentUser.access_token }
                })
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }