var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "time-selector-with-office-check" },
    [
      _c("time-selector", {
        attrs: {
          label: _vm.$t(
            "SLA.CREATE.SECTIONS.METRICS." +
              _vm.labelKey.toUpperCase() +
              ".TITLE"
          ),
          "time-in-minutes": _vm.data.time
        },
        on: {
          select: function($event) {
            return _vm.handleUpdate({ time: $event })
          }
        }
      }),
      _vm._v(" "),
      _c("label", { staticClass: "container-checkbox" }, [
        _c("div", { staticClass: "body-b2 label-text" }, [
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("SLA.CREATE.SECTIONS.METRICS.OOO"))
            }
          })
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.isOutOfOfficeIncluded,
              expression: "isOutOfOfficeIncluded"
            }
          ],
          attrs: { type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.isOutOfOfficeIncluded)
              ? _vm._i(_vm.isOutOfOfficeIncluded, null) > -1
              : _vm.isOutOfOfficeIncluded
          },
          on: {
            change: function($event) {
              var $$a = _vm.isOutOfOfficeIncluded,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.isOutOfOfficeIncluded = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.isOutOfOfficeIncluded = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.isOutOfOfficeIncluded = $$c
              }
            }
          }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "checkmark" })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }