var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "emoji-dialog", attrs: { role: "dialog" } }, [
    _c(
      "header",
      { staticClass: "emoji-dialog--header", attrs: { role: "menu" } },
      [
        _c(
          "ul",
          _vm._l(Object.keys(_vm.emojis), function(category) {
            return _c(
              "li",
              {
                key: category,
                class: { active: _vm.selectedKey === category },
                on: {
                  click: function($event) {
                    return _vm.changeCategory(category)
                  }
                }
              },
              [
                _c("button", {
                  staticClass: "emoji--item",
                  domProps: { innerHTML: _vm._s(_vm.emojis[category][0]) },
                  on: {
                    click: function($event) {
                      return _vm.changeCategory(category)
                    }
                  }
                })
              ]
            )
          }),
          0
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "emoji--row" },
      [
        _c("h5", { staticClass: "emoji-category--title" }, [
          _vm._v("\n      " + _vm._s(_vm.selectedKey) + "\n    ")
        ]),
        _vm._v(" "),
        _vm._l(_vm.emojis[_vm.selectedKey], function(emoji) {
          return _c("button", {
            key: emoji,
            staticClass: "emoji--item",
            attrs: { "track-by": "$index" },
            domProps: { innerHTML: _vm._s(emoji) },
            on: {
              click: function($event) {
                return _vm.onClick(emoji)
              }
            }
          })
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }