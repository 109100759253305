var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.shouldShowVariables
        ? _c("variable-list", {
            attrs: { "search-key": _vm.variableSearchTerm },
            on: {
              click: _vm.insertVariable,
              onSelectVariable: _vm.insertVariable
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", {
        ref: "editor",
        class: {
          "editor--with-border": !_vm.noBorder,
          "editor--email": _vm.forEmail
        },
        staticStyle: { width: "100%" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }