var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column custom-scroll overflow-overlay" },
    [
      !_vm.isNewPage
        ? _c(
            "woot-base-button",
            {
              staticClass: "settings-button",
              attrs: { "layer-fill": "white", loading: _vm.isUpdating },
              on: { click: _vm.updateAgents }
            },
            [_vm._v("\n    Update\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isNewPage && _vm.inbox && _vm.inbox.name
        ? _c("page-header", { attrs: { "header-title": _vm.inbox.name } })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "settings-sub-menu-content--larger" }, [
        _c("div", [
          _c("span", {
            staticClass: "title-h5 text-dark mg-right--slab",
            domProps: {
              textContent: _vm._s(
                _vm.isAFacebookInbox
                  ? _vm.$t("INBOX_MGMT.SETTINGS_POPUP.INBOX_AGENTS") + " For"
                  : _vm.$t("INBOX_MGMT.SETTINGS_POPUP.INBOX_AGENTS")
              )
            }
          }),
          _vm._v(" "),
          !_vm.isAFacebookInbox
            ? _c("span", {
                staticClass: "body-b3 text-light",
                domProps: {
                  textContent: _vm._s(
                    "(" +
                      _vm.$t(
                        "INBOX_MGMT.SETTINGS_POPUP.INBOX_AGENTS_SUB_TEXT"
                      ) +
                      ")"
                  )
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isAFacebookInbox
            ? _c(
                "div",
                { staticClass: "flex-row gap--micro mg-top--small" },
                [
                  _c(
                    "woot-base-button",
                    {
                      attrs: {
                        variant: _vm.showCommentCollaborators
                          ? "primary"
                          : "secondary",
                        size: "small"
                      },
                      on: {
                        click: function($event) {
                          _vm.showCommentCollaborators = true
                        }
                      }
                    },
                    [_vm._v("\n          Comments\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "woot-base-button",
                    {
                      attrs: {
                        variant: _vm.showCommentCollaborators
                          ? "secondary"
                          : "primary",
                        size: "small"
                      },
                      on: {
                        click: function($event) {
                          _vm.showCommentCollaborators = false
                        }
                      }
                    },
                    [_vm._v("\n          Direct Messages\n        ")]
                  )
                ],
                1
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "flex-row flex-justify--between flex-align gap--slab mg-top--normal search-container"
          },
          [
            _c("search-box", {
              attrs: {
                placeholder: "Search for Agent name/role",
                "custom-class": "search-box",
                value: _vm.search
              },
              on: { setSearch: _vm.setSearch }
            }),
            _vm._v(" "),
            _c(
              "woot-base-button",
              {
                staticStyle: { height: "4.4rem" },
                attrs: { variant: "secondary" },
                on: { click: _vm.openAddPopup }
              },
              [_vm._v("\n        Add agent\n      ")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "pd-top--two bg-green mg-bottom--slab" },
          [
            _c("data-table", {
              attrs: {
                columns: _vm.tableColumns,
                "table-data": _vm.addedCollaborators,
                loading: _vm.uiFlags.isFetching || _vm.isFetchingMembers,
                "enable-animations": false,
                title: "Collaborators Added"
              },
              scopedSlots: _vm._u([
                {
                  key: "actions",
                  fn: function(ref) {
                    var data = ref.row
                    return [
                      _c(
                        "woot-base-button",
                        {
                          staticClass: "custom-button",
                          attrs: { variant: "secondary-danger", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.onRemoveMember(data)
                            }
                          }
                        },
                        [_vm._v("\n            Remove\n          ")]
                      )
                    ]
                  }
                },
                {
                  key: "zero-state",
                  fn: function() {
                    return [
                      _c("span", {
                        staticClass: "title-h5 text-light",
                        domProps: { textContent: _vm._s("None") }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "pd-top bg-white mg-bottom--larger" },
          [
            _c("data-table", {
              attrs: {
                columns: _vm.tableColumns,
                "table-data": _vm.notAddedCollaborators,
                loading: _vm.uiFlags.isFetching || _vm.isFetchingMembers,
                "enable-animations": false,
                title: "Available Collaborators"
              },
              scopedSlots: _vm._u([
                {
                  key: "actions",
                  fn: function(ref) {
                    var data = ref.row
                    return [
                      _c(
                        "woot-base-button",
                        {
                          staticClass: "custom-button",
                          attrs: { variant: "secondary", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.onAddCollaborator(data)
                            }
                          }
                        },
                        [_vm._v("\n            Add to inbox\n          ")]
                      )
                    ]
                  }
                },
                {
                  key: "zero-state",
                  fn: function() {
                    return [
                      _c("span", {
                        staticClass: "title-h5 text-light",
                        domProps: { textContent: _vm._s("None") }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.isNewPage
        ? _c(
            "div",
            { staticClass: "medium-12 columns settings-sub-menu-bottom-nav" },
            [
              _c(
                "woot-base-button",
                {
                  attrs: { variant: "secondary" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return (function() {
                        return _vm.$router.go(-1)
                      })($event)
                    }
                  }
                },
                [_vm._v("\n      Back\n    ")]
              ),
              _vm._v(" "),
              _c(
                "woot-base-button",
                {
                  attrs: {
                    disabled:
                      _vm.isAKnowlarityInbox && !_vm.addedCollaborators.length
                  },
                  on: { click: _vm.updateAgents }
                },
                [_vm._v("\n      Finish\n    ")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showAgentPopup
        ? _c("add-edit-agent", {
            attrs: {
              show: _vm.showAgentPopup,
              "agent-data": {},
              "inbox-list": _vm.populateInboxData
            },
            on: {
              onClose: function() {
                return (_vm.showAgentPopup = false)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }