var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    {
      tag: "router-link",
      class: _vm.computedClass,
      style: { position: "relative" },
      attrs: { to: _vm.menuItem.toState, tag: "li", "active-class": "active" },
      nativeOn: {
        click: function($event) {
          return _vm.InlineButtonClickHandler($event)
        }
      }
    },
    [
      _c("span", [
        _c(
          "a",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip.right",
                value: _vm.$t("SIDEBAR." + _vm.menuItem.label),
                expression: "$t(`SIDEBAR.${menuItem.label}`)",
                modifiers: { right: true }
              }
            ],
            staticClass: "side-menu-collapsed",
            class: _vm.menuItem.toStateName,
            attrs: {
              title: _vm.menuItem.toolTip,
              "data-tooltip": "",
              "aria-haspopup": "true"
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "wrap",
                staticStyle: { "justify-content": "center" }
              },
              [
                _c("icons", {
                  attrs: {
                    name: _vm.isActiveIcon
                      ? _vm.menuItem.activeIcon
                      : _vm.menuItem.icon,
                    color: "white",
                    size: "mediumlarge",
                    "show-title": false
                  }
                })
              ],
              1
            )
          ]
        )
      ]),
      _vm._v(" "),
      _vm.menuItem.isNew
        ? _c("div", {
            staticClass: "statbadge statbadge-dot statbadge--danger"
          })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }