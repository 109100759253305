var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "accordian-card",
    {
      attrs: { "custom-desc": true, "custom-class": "address-container" },
      scopedSlots: _vm._u([
        {
          key: "desc",
          fn: function() {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "address-btn flex-row flex-align flex-justify--between"
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "subtitle-s2",
                      staticStyle: {
                        width: "100%",
                        cursor: "pointer",
                        "text-transform": "capitalize"
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.addressType) +
                          " Address\n      "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm.addressType === "shipping"
                    ? _c(
                        "span",
                        {
                          staticClass: "add-new-address",
                          attrs: { title: "Add New" },
                          on: { click: _vm.onShowCreateOrder }
                        },
                        [
                          _c("icons", {
                            attrs: {
                              name: "circleAdd",
                              color: "stroke-green",
                              size: "medium",
                              "custom-style": { "stroke-width": 2 },
                              "show-title": false
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _vm.addresses.length === 0
        ? _c("div", { staticClass: "body-b3 text-light" }, [
            _vm._v(
              "\n    " + _vm._s(_vm.$t("ORDER.ADDRESS.NOT_FOUND")) + "\n  "
            )
          ])
        : _vm.addressType === "billing"
        ? _c("span", { staticClass: "body-b3 text-light" }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t("ORDER.BILLING.SAME_AS_SHIPPING")) +
                "\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.addressType === "shipping"
        ? _c(
            "span",
            _vm._l(_vm.sortedAddresses, function(address, idx) {
              return _c("address-field", {
                key: address.id,
                attrs: {
                  index: idx,
                  selected: _vm.selectedAddress,
                  data: address
                },
                on: { updateSelectedAddress: _vm.updateSelectedAddress }
              })
            }),
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }