var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "action-group" },
    [
      _c("span", { staticStyle: { "white-space": "nowrap" } }, [
        _vm._v(
          "\n    Send " +
            _vm._s(_vm.escalationLevel) +
            " Level Escalation to\n  "
        )
      ]),
      _vm._v(" "),
      _c("multi-select-trigger-values", {
        staticClass: "multi-select-filter",
        attrs: {
          value: _vm.selectedValues,
          "dropdown-values": _vm.dropdownValues
        },
        on: { input: _vm.updateValues }
      }),
      _vm._v(" "),
      _c("span", [_vm._v("in")]),
      _vm._v(" "),
      _c("time-duration-selector", {
        attrs: { value: _vm.action.after },
        on: { click: _vm.updateTimeDuration }
      }),
      _vm._v(" "),
      _c(
        "woot-base-button",
        {
          attrs: { tag: "span", variant: "tertiary-danger" },
          on: { click: _vm.deleteAction }
        },
        [
          _c("icons", {
            attrs: {
              name: "bin",
              color: "red",
              size: "semimedium",
              "show-title": false
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }