import { render, staticRenderFns } from "./AddContactSection.vue?vue&type=template&id=03b13216&"
import script from "./AddContactSection.vue?vue&type=script&lang=js&"
export * from "./AddContactSection.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/limechat/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('03b13216')) {
      api.createRecord('03b13216', component.options)
    } else {
      api.reload('03b13216', component.options)
    }
    module.hot.accept("./AddContactSection.vue?vue&type=template&id=03b13216&", function () {
      api.rerender('03b13216', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/lime-ui/dashboard/routes/dashboard/conversation/createTicket/components/form/createWATicketForm/AddContactSection.vue"
export default component.exports