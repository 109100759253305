var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-switcher", on: { click: _vm.show } },
    [
      _c("icons", { attrs: { name: "menu", color: "grey", size: "medium" } }),
      _vm._v(" "),
      _vm.showAppSwitcher
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "on-clickaway",
                  rawName: "v-on-clickaway",
                  value: _vm.hide,
                  expression: "hide"
                }
              ],
              staticClass: "app-switch-container"
            },
            [
              _c(
                "div",
                { staticClass: "app-switch-container__app" },
                _vm._l(_vm.apps, function(app) {
                  return _c(
                    "a",
                    {
                      key: app.id,
                      attrs: {
                        href: _vm.targetUrl(app),
                        target: "_blank",
                        rel: "noopener noreferrer"
                      },
                      on: { click: _vm.hide }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex-column-center padding-slab" },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.getLogoForApp(app),
                              alt: app.name
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "title-h5 text-dark mg-top--slab" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(app.name) +
                                  "\n          "
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c("div", { staticClass: "body-b3 text-light branding" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("SIDEBAR_ITEMS.PRODUCT_BY")) +
                    "\n      "
                ),
                _c("img", {
                  staticClass: "mg-left--small mg-right--micro",
                  staticStyle: { height: "12px" },
                  attrs: { src: "/brand-assets/logo_thumbnail.svg" }
                }),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("SIDEBAR_ITEMS.LIMECHAT")) +
                    "\n    "
                )
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }