var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("form-section-header", {
        attrs: {
          heading: _vm.$t("CREATE_TICKET.FORM.SECTIONS.ADD_CONTACT.HEADING"),
          "sub-heading": _vm.$t(
            "CREATE_TICKET.FORM.SECTIONS.ADD_CONTACT.SUB_HEADING"
          ),
          icon: "people"
        }
      }),
      _vm._v(" "),
      _c("add-contact", {
        attrs: {
          "selected-contacts": _vm.selectedContacts,
          "contact-limit": 5
        },
        on: { updateContacts: _vm.updateSelectedContacts },
        scopedSlots: _vm._u([
          {
            key: "selected-contacts-display-panel",
            fn: function(ref) {
              var contacts = ref.contacts
              var updateContacts = ref.updateContacts
              return [
                _c("show-selected-contacts", {
                  attrs: { contacts: contacts },
                  on: { updateContacts: updateContacts }
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }