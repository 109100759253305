var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "conversation", on: { click: _vm.navigateToConversation } },
    [
      _c("div", { staticClass: "conversation--details columns" }, [
        _c("div", { staticClass: "conversation--top" }, [
          _c(
            "span",
            { staticClass: "title-h5 text-dark conversation--user flex-row" },
            [
              _c(
                "span",
                [
                  _c("icons", {
                    attrs: {
                      name: _vm.computedInboxClass.name,
                      color: _vm.computedInboxClass.color + " evenodd",
                      "show-title": false,
                      size: "semimedium"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("account-pii-mask-wrapper", {
                attrs: {
                  "pii-data": _vm.contactName,
                  "mask-type": _vm.maskType,
                  "unmask-action": "piiMask/logUnmaskEvent",
                  "unmask-payload": _vm.unmaskRequestPayload
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var displayData = ref.data
                      return [
                        displayData
                          ? _c("span", {
                              staticClass: "text-truncate",
                              staticStyle: { "max-width": "15rem" },
                              domProps: { textContent: _vm._s(displayData) }
                            })
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "conversation--meta" }, [
            _c("span", { staticClass: "timestamp body-b3" }, [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.dynamicTime(_vm.data.timestamp || _vm.data.created_at)
                  ) +
                  "\n        "
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "conversation--middle" }, [
          _c("span", {
            staticClass: "conversation--message body-b2 text-light",
            domProps: { innerHTML: _vm._s(_vm.prepareContent) }
          }),
          _vm._v(" "),
          _c("span", {
            staticClass: "assignee--label",
            domProps: { textContent: _vm._s(_vm.getChatAssignee) }
          })
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }