var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "filters" }, [
    _c("div", { staticClass: "filter" }, [
      _c(
        "div",
        { staticClass: "filter-inputs" },
        [
          _c("woot-single-selector", {
            class: "AUTOMATION_CONDITION_ATTRIBUTE_KEY_" + _vm.index,
            attrs: {
              "default-option": _vm.attributeKey,
              "option-list": _vm.filterAttributes,
              "custom-style": _vm.selectorCustomStyle,
              "dropdown-container-style": _vm.attributeDropdownStyle
            },
            on: { click: _vm.handleAttributeChange }
          }),
          _vm._v(" "),
          _vm.operators.length !== 0
            ? _c("woot-single-selector", {
                attrs: {
                  "default-option": _vm.filterOperator,
                  "option-list": _vm.operators,
                  "custom-style": _vm.selectorCustomStyle,
                  "dropdown-container-style": _vm.operatorDropdownStyle
                },
                on: { click: _vm.handleOperatorChange }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showUserInput
            ? _c(
                "div",
                {
                  staticClass: "filter__answer--wrap",
                  class: "AUTOMATION_CONDITION_VALUES_" + _vm.index
                },
                [
                  _vm.inputType === "multi_select"
                    ? _c(
                        "div",
                        { staticClass: "multiselect-wrap--small" },
                        [
                          _c("multiselect", {
                            attrs: {
                              "track-by": "id",
                              label: _vm.labelKey,
                              placeholder: "Select",
                              multiple: "",
                              "selected-label": "",
                              "deselect-label": "",
                              "select-label": _vm.$t(
                                "FORMS.MULTISELECT.ENTER_TO_SELECT"
                              ),
                              "max-height": 160,
                              options: _vm.dropdownValues,
                              "allow-empty": ""
                            },
                            model: {
                              value: _vm.values,
                              callback: function($$v) {
                                _vm.values = $$v
                              },
                              expression: "values"
                            }
                          })
                        ],
                        1
                      )
                    : _vm.inputType === "search_select"
                    ? _c(
                        "div",
                        { staticClass: "multiselect-wrap--small" },
                        [
                          _c("multiselect", {
                            attrs: {
                              "track-by": "id",
                              label: "name",
                              placeholder: "Select",
                              "selected-label": "",
                              "select-label": _vm.$t(
                                "FORMS.MULTISELECT.ENTER_TO_SELECT"
                              ),
                              "deselect-label": "",
                              "max-height": 160,
                              options: _vm.dropdownValues,
                              "allow-empty": "",
                              "option-height": 104
                            },
                            model: {
                              value: _vm.values,
                              callback: function($$v) {
                                _vm.values = $$v
                              },
                              expression: "values"
                            }
                          })
                        ],
                        1
                      )
                    : _vm.inputType === "date"
                    ? _c("div", { staticClass: "multiselect-wrap--small" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.values,
                              expression: "values"
                            }
                          ],
                          staticClass: "answer--text-input datepicker",
                          attrs: { type: "date", editable: false },
                          domProps: { value: _vm.values },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.values = $event.target.value
                            }
                          }
                        })
                      ])
                    : _vm.inputType === "array[text]"
                    ? _c(
                        "div",
                        [
                          _c("keyword-input", {
                            attrs: {
                              keywords: _vm.values,
                              size: "small",
                              "input-placeholder":
                                "Type a keyword and press enter"
                            },
                            on: { updateKeywords: _vm.handleValueChange }
                          })
                        ],
                        1
                      )
                    : _c("woot-input", {
                        staticClass: "answer--text-input",
                        attrs: { placeholder: "Enter value" },
                        model: {
                          value: _vm.values,
                          callback: function($$v) {
                            _vm.values = $$v
                          },
                          expression: "values"
                        }
                      })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isFirstCondition
            ? _c(
                "woot-base-button",
                {
                  attrs: { tag: "span", variant: "tertiary-danger" },
                  on: { click: _vm.removeFilter }
                },
                [
                  _c("icons", {
                    attrs: {
                      name: "bin",
                      color: "red",
                      size: "semimedium",
                      "show-title": false
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ]),
    _vm._v(" "),
    _vm.showQueryOperator
      ? _c(
          "div",
          { staticClass: "filter__join-operator" },
          [
            _c("hr", { staticClass: "operator__line" }),
            _vm._v(" "),
            _c("woot-single-selector", {
              attrs: {
                "default-option": _vm.queryOperator,
                "option-list": _vm.queryOperatorOptions,
                "custom-style": _vm.selectorCustomStyle,
                "dropdown-container-style": _vm.queryOperatorDropdownStyle
              },
              on: { click: _vm.handleQueryOperatorChange }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }