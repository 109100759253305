var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box settings-wrapper" },
    [
      _c("form-header", {
        attrs: {
          "edit-view": _vm.editView,
          "custom-field-name": _vm.customField.name,
          "custom-field-desc":
            _vm.customField.description ||
            _vm.$t("CUSTOM_TICKET_FIELDS.ADD.FORM.HEADER.DESC"),
          "show-details-modal": function() {
            return (_vm.showDetailsModal = true)
          },
          "enable-status": _vm.customField.enabled
        },
        on: {
          toggleFieldStatus: function($event) {
            _vm.customField.enabled = $event
          }
        }
      }),
      _vm._v(" "),
      _vm.showDetailsModal
        ? _c("custom-field-details-modal", {
            attrs: {
              opened: "",
              name: _vm.customField.name,
              description: _vm.customField.description
            },
            on: {
              onSubmit: _vm.onSubmitDetailsModal,
              onClose: function($event) {
                _vm.showDetailsModal = false
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "custom-field-form-wrapper lime-card-1dp flex-column flex-justify--between"
        },
        [
          _c(
            "div",
            {
              staticClass:
                "flex-column gap--normal custom-scroll mg-bottom--small full-height"
            },
            [
              _c("type-section", {
                attrs: {
                  "edit-view": _vm.editView,
                  "selected-type": {
                    field_type: _vm.customFieldType,
                    name: _vm.getCustomFieldNameByType(_vm.customFieldType)
                  }
                },
                on: { updateType: _vm.changeFieldType }
              }),
              _vm._v(" "),
              _vm.isDropdownField
                ? _c("draggable-values-section", {
                    staticClass: "CUSTOM_FIELD_VALUES",
                    attrs: {
                      disabled: _vm.isMultiSelectField && _vm.editView,
                      list: _vm.customField.options
                    },
                    on: {
                      updateValues: function($event) {
                        _vm.customField.options = $event
                      },
                      updateLabelName: function(name, index) {
                        return (_vm.customField.options[index].value = name)
                      },
                      addField: function(index) {
                        return _vm.customField.options.splice(index + 1, 0, {
                          id: null,
                          value: ""
                        })
                      },
                      removeField: _vm.deleteField
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("inbox-selection-section", {
                staticClass: "CUSTOM_FIELD_INBOXES",
                attrs: { "custom-field-inboxes": _vm.customFieldInboxes },
                on: {
                  addInbox: function($event) {
                    return _vm.customFieldInboxes.push($event)
                  },
                  removeInbox: _vm.removeInboxFromList
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "checkbox-container" }, [
                _c("label", { staticClass: "container-checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.customField.mandatory,
                        expression: "customField.mandatory"
                      }
                    ],
                    staticClass: "notification--checkbox",
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.customField.mandatory)
                        ? _vm._i(_vm.customField.mandatory, null) > -1
                        : _vm.customField.mandatory
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.customField.mandatory,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.customField,
                                "mandatory",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.customField,
                                "mandatory",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.customField, "mandatory", $$c)
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "checkmark" })
                ]),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "checkbox-label",
                    attrs: { for: "customField.mandatory" }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("CUSTOM_TICKET_FIELDS.ADD.FORM.MANDATORY")
                        ) +
                        "\n        "
                    )
                  ]
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("Footer", {
            attrs: { "edit-view": _vm.editView },
            on: { submitFieldForm: _vm.createOrEditField }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }