var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-container actions--container-new" },
    [
      !_vm.customButton
        ? _c(
            "div",
            {
              staticClass: "action--button-new",
              class: [_vm.cssClassName, { pressed: _vm.showDropdown }],
              style: [_vm.buttonStyle, _vm.highlightButtonStyle],
              attrs: { id: _vm.id },
              on: { click: _vm.toggleDropdown }
            },
            [
              _c(
                "span",
                { staticStyle: { "margin-right": "0.2rem" } },
                [
                  _vm.frontIcon
                    ? _c("icons", {
                        attrs: {
                          name: _vm.frontIcon,
                          color: _vm.highlightStyle
                            ? "green"
                            : _vm.frontIconColor,
                          size: _vm.iconSize
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              !_vm.staticTitle
                ? _c(
                    "span",
                    {
                      staticClass: "selected-option title-h6_m",
                      style: [
                        _vm.size === "secondary-large"
                          ? { "margin-right": "5rem" }
                          : { "margin-right": "auto" },
                        _vm.highlightStyle
                      ]
                    },
                    [
                      _c("span", {
                        domProps: { innerHTML: _vm._s(_vm.getOptionTitle) }
                      }),
                      _vm._v(" "),
                      _vm.collapseSelected && _vm.optionLength > 1
                        ? _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.tooltipOptions,
                                  expression: "tooltipOptions"
                                }
                              ],
                              staticClass: "title-h5_m"
                            },
                            [
                              _vm._v(
                                "\n        +" +
                                  _vm._s(_vm.optionLength - 1) +
                                  "\n      "
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                : _c("span", {
                    staticClass: "title-h6_m",
                    domProps: { textContent: _vm._s(_vm.staticTitle) }
                  }),
              _vm._v(" "),
              _c(
                "span",
                { staticStyle: { "margin-left": "0.8rem" } },
                [
                  _c("icons", {
                    attrs: {
                      name: _vm.icon,
                      size: _vm.iconSize,
                      color: _vm.iconColor
                    }
                  })
                ],
                1
              )
            ]
          )
        : _c(
            "div",
            { on: { click: _vm.toggleDropdown } },
            [
              _vm._t("default", null, {
                data: { selectedOptionsLength: _vm.selectedOptionId.length }
              })
            ],
            2
          ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "dropdown" } }, [
        _vm.showDropdown
          ? _c(
              "ul",
              {
                staticClass: "dropdown-list dropdown--pane-new custom-scroll",
                class: { "dropdown--pane-new--open": _vm.showDropdown },
                style: [
                  _vm.customStyle,
                  _vm.dropDownPanePosition,
                  _vm.paneWidth
                ]
              },
              [
                _vm.showSearch
                  ? _c(
                      "div",
                      { staticClass: "search-input" },
                      [
                        _c("search-box", {
                          attrs: {
                            placeholder: _vm.searchPlaceholder,
                            value: _vm.searchVal,
                            "custom-class": "no-border"
                          },
                          on: { setSearch: _vm.setSearch }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.filteredOptionList, function(option) {
                  return _c(
                    "li",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value:
                            _vm.showOptionTooltip && option[_vm.defaultKey],
                          expression: "showOptionTooltip && option[defaultKey]"
                        }
                      ],
                      key: option.id
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "dropdown-button-new",
                          class: {
                            "dropdown-button-new--selected":
                              _vm.isSelectedId(option.id) > -1
                          },
                          style: { "pointer-events": "auto" },
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.onClick(option)
                            }
                          }
                        },
                        [
                          _vm.optionIcon
                            ? _c(
                                "span",
                                { staticStyle: { "margin-right": "0.4rem" } },
                                [
                                  _c("icons", {
                                    attrs: {
                                      name: _vm.optionIcon,
                                      size: _vm.iconSize,
                                      color:
                                        _vm.isSelectedId(option.id) > -1
                                          ? "white"
                                          : "darkestgrey"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          option.icon
                            ? _c(
                                "span",
                                { staticStyle: { "margin-right": "0.4rem" } },
                                [
                                  _c("icons", {
                                    attrs: {
                                      name: option.icon,
                                      size: _vm.iconSize,
                                      color:
                                        _vm.isSelectedId(option.id) > -1
                                          ? "white"
                                          : option.color + " evenodd"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "option-name title-h6_m text-truncate",
                              style: {
                                color:
                                  _vm.isSelectedId(option.id) > -1
                                    ? "white"
                                    : option.textcolor
                                    ? option.textcolor
                                    : "black"
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(option[_vm.defaultKey]) +
                                  "\n          "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm.showSelectedText
                            ? _c("span", [
                                _vm.isSelectedId(option.id) > -1
                                  ? _c("span", [_vm._v("Selected")])
                                  : _c(
                                      "span",
                                      { staticClass: "show-selected" },
                                      [_vm._v("Select")]
                                    )
                              ])
                            : _vm._e()
                        ]
                      )
                    ]
                  )
                }),
                _vm._v(" "),
                !_vm.optionList.length
                  ? _c("li", [
                      _c(
                        "button",
                        {
                          staticClass:
                            "button action--button-new no-border-radius"
                        },
                        [_c("span", [_vm._v(_vm._s("No options available"))])]
                      )
                    ])
                  : _vm._e()
              ],
              2
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }