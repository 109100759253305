var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _vm.title
        ? _c("label", {
            staticClass: "views-section-title",
            domProps: { textContent: _vm._s(_vm.title) }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        _vm.hasAccordion ? "accordian-card" : "div",
        {
          key: _vm.isAccordionOpen,
          tag: "component",
          attrs: {
            name: _vm.accordionTitle,
            "always-open": _vm.isAccordionOpen
          }
        },
        [
          _vm.hasViews
            ? _c(
                "div",
                { staticClass: "custom-views-container" },
                _vm._l(_vm.views, function(view) {
                  return _c("Item", {
                    key: view.id,
                    attrs: {
                      data: view,
                      "is-default": _vm.isDefault(view),
                      "is-active": _vm.isActive(view),
                      controls: _vm.controls
                    },
                    on: {
                      onEdit: _vm.onEditView,
                      onDelete: _vm.onDeleteView,
                      onMakeCurrent: _vm.onMakeCurrent,
                      onMakeDefault: _vm.onMakeDefault
                    }
                  })
                }),
                1
              )
            : _c("div", {
                staticClass: "views-zero-state",
                domProps: { textContent: _vm._s(_vm.zeroStateMessage) }
              })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }