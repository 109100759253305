var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", {
      staticClass: "backdrop",
      on: {
        click: function($event) {
          return _vm.$emit("closeImageSlider")
        }
      }
    }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "slide flex-column", on: { click: _vm.closeImageSlider } },
      [
        _c(
          "div",
          {
            staticClass: "slide--wrapper flex-row flex-justify flex-align",
            class: { "ml-9": _vm.numberOfAttachments > 1 },
            on: { click: _vm.closeImageSlider }
          },
          _vm._l(_vm.attachments, function(attachment, idx) {
            return _c(
              "div",
              {
                key: attachment.id,
                staticClass:
                  "slide--image-container flex-row flex-justify flex-align",
                on: {
                  click: function($event) {
                    return _vm.traverseAttachments(idx)
                  },
                  dblclick: _vm.closeImageSlider
                }
              },
              [
                !_vm.attachmentsWithError.includes(idx)
                  ? [
                      !_vm.isMp4(attachment.url)
                        ? _c("img", {
                            attrs: { src: attachment.url },
                            on: {
                              error: function($event) {
                                return _vm.imgPlaceholder(idx)
                              }
                            }
                          })
                        : _c("video-view", {
                            attrs: { url: attachment.url, width: "100%" }
                          })
                    ]
                  : _c(
                      "span",
                      { staticClass: "flex-column flex-justify flex-align" },
                      [
                        _c("icons", {
                          attrs: {
                            name: "image",
                            size: "mediumlarge",
                            color: "white"
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "title-h4_m placeholder" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("IMAGE_PREVIEW.ERROR")) +
                              "\n          "
                          )
                        ])
                      ],
                      1
                    )
              ],
              2
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "file-wrapper flex-column flex-align flex-justify" },
          [
            _c(
              "div",
              {
                staticClass: "file flex-row flex-align flex-justify",
                staticStyle: { cursor: "pointer" },
                attrs: { title: _vm.fileName(_vm.attachments[_vm.index].url) },
                on: {
                  click: function($event) {
                    return _vm.onDownload(_vm.attachments[_vm.index].url)
                  }
                }
              },
              [
                _c("span", { staticClass: "text-block-title title-h6_m" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.truncateFilename(
                          _vm.fileName(_vm.attachments[_vm.index].url),
                          20
                        )
                      ) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("icons", {
                  attrs: { name: "download", size: "medium", color: "white" }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm.numberOfAttachments > 1
              ? _c("div", [
                  _c(
                    "span",
                    {
                      staticClass:
                        "file navigation-buttons flex-row flex-align flex-justify"
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "icon-wrap prev",
                          on: { click: _vm.prevBtn }
                        },
                        [
                          _c("icons", {
                            attrs: {
                              name: "chevronLeft",
                              size: "medium",
                              "show-title": "",
                              title: "Previous",
                              color: _vm.index === 0 ? "grey" : "white"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "text-block-title title-h6_m",
                          staticStyle: { "user-select": "none" }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.index + 1) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "icon-wrap next",
                          on: { click: _vm.nextBtn }
                        },
                        [
                          _c("icons", {
                            attrs: {
                              name: "chevronRight",
                              size: "medium",
                              "show-title": "",
                              title: "Next",
                              color:
                                _vm.index === _vm.numberOfAttachments - 1
                                  ? "grey"
                                  : "white"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ])
              : _vm._e()
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }