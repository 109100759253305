var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "limechat-video-wrap", style: "width: " + _vm.width },
    [
      _c(
        "video",
        {
          ref: "video",
          staticClass: "limechat-video",
          attrs: { controls: "true" }
        },
        [
          _c("source", {
            ref: "vidsrc",
            attrs: { src: _vm.url, type: "video/mp4" }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }