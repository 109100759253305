var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    {
      staticClass: "onboarding-modal",
      attrs: {
        show: _vm.showPopup,
        "show-close": !_vm.isWelcomeScreen,
        "on-close": _vm.onClose
      },
      on: {
        "update:show": function($event) {
          _vm.showPopup = $event
        }
      }
    },
    [
      _c("header", { staticClass: "modal-header", style: _vm.headerHeight }, [
        _vm.isWelcomeScreen
          ? _c("img", {
              staticClass: "image",
              attrs: {
                src: require("assets/images/onboarding_welcome.svg"),
                alt: "welcome-illustration"
              }
            })
          : _vm.isConfigurationErrorScreen
          ? _c("img", {
              staticClass: "image",
              attrs: {
                src: require("assets/images/configuration_error.svg"),
                alt: "welcome-illustration"
              }
            })
          : _c("img", {
              staticClass: "image",
              attrs: {
                src: require("assets/images/three_sixty_integration_visual.svg"),
                alt: "welcome-illustration"
              }
            })
      ]),
      _vm._v(" "),
      _c(
        "main",
        { staticClass: "modal-content" },
        [
          _c(_vm.currentComponent, {
            tag: "component",
            attrs: { inbox: _vm.inbox }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }