var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-ticket-form",
    {
      attrs: {
        "parent-form-fields": {
          department_id: _vm.selectedDepartmentId,
          selectedCrm: _vm.selectedCrm
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "content-margin" },
        [
          _c("label", { staticClass: "formlabel" }, [
            _c("span", {
              staticClass: "title-h5 text-dark",
              domProps: { textContent: _vm._s("Department") }
            })
          ]),
          _vm._v(" "),
          _vm.departmentOptionList.length
            ? _c("woot-single-selector", {
                attrs: {
                  "default-option": _vm.selectedDepartment,
                  "option-list": _vm.departmentOptionList,
                  "custom-style": { width: "100%", "margin-bottom": "12px" }
                },
                on: { click: _vm.changeDepartment }
              })
            : _c("div", {
                staticClass: "body-b3 text-light",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t(
                      "INTEGRATION_SETTINGS.CRM.INTEGRATIONS.ZOHO.OVERVIEW_PANEL.FORM.ERRORS.NO_DEPARTMENT"
                    )
                  )
                }
              })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }