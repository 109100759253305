var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-wrapper app-root", attrs: { id: "app" } },
    [
      _c("network-notification"),
      _vm._v(" "),
      _vm.loading
        ? _c(
            "div",
            { staticClass: "full-height full-width" },
            [
              _c("LoadingState", {
                attrs: { message: "Getting this ready..." }
              })
            ],
            1
          )
        : _vm.signupOnboarding
        ? [_c("SignupOnboarding", { attrs: { user: _vm.user } })]
        : [
            _c(
              "transition",
              { attrs: { name: "fade", mode: "out-in" } },
              [_c("router-view")],
              1
            ),
            _vm._v(" "),
            _c("snackbar-box"),
            _vm._v(" "),
            _vm.hasVoiceCallInboxes
              ? [
                  _c("calling-popup", {
                    attrs: { "inbox-list": _vm.voiceInboxList }
                  }),
                  _vm._v(" "),
                  _c("calling-notification")
                ]
              : _vm._e()
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }