var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-column-center flex-justify full-width full-height" },
    [
      _c("img", {
        staticClass: "hero__logo",
        attrs: {
          src: _vm.globalConfig.logo,
          alt: _vm.globalConfig.installationName
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "align-center mg-top--large",
          staticStyle: { padding: "0rem 6rem" }
        },
        [
          _c(
            "div",
            { staticClass: "small-12 column align-center text-align" },
            [
              _c("p", { staticClass: "title-h1 dark" }, [
                _vm._v("\n        " + _vm._s(_vm.errorTitle) + "\n      ")
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "body-b1 light" }, [
                _vm._v("\n        " + _vm._s(_vm.errorSubtitle) + "\n      ")
              ]),
              _vm._v(" "),
              _c("woot-submit-button", {
                staticClass: "go-back",
                attrs: {
                  "button-text": _vm.buttonTitle,
                  "button-class": "medium"
                },
                on: { click: _vm.onButtonClick }
              })
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }