var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-container" },
    [
      _c("data-table", {
        attrs: {
          actions: _vm.actionsList,
          columns: _vm.tableColumns,
          "table-data": _vm.templateList,
          loading: _vm.uiFlags.fetchingTemplateList,
          "enable-animations": _vm.templateList.length <= 10,
          _class: "scrollable-table custom-scroll"
        },
        on: {
          edit: _vm.onEdit,
          delete: _vm.onDelete,
          select: _vm.OnSelectTemplate
        },
        scopedSlots: _vm._u([
          {
            key: "table-features",
            fn: function() {
              return [
                _c("search-box", {
                  attrs: {
                    placeholder: _vm.$t("TEMPLATE.SEARCH_TXT"),
                    value: _vm.search,
                    "custom-class": "search-box"
                  },
                  on: { setSearch: _vm.setSearch }
                }),
                _vm._v(" "),
                _vm.inboxList.length && _vm.showInbox
                  ? _c("woot-single-selector", {
                      attrs: {
                        variant: "secondary",
                        size: "medium",
                        "default-option": _vm.selectedInboxName,
                        "front-icon": _vm.inboxFilterIcon
                          ? _vm.inboxFilterIcon
                          : "inbox",
                        "option-list": _vm.inboxList,
                        "show-selected-text": false,
                        "dropdown-container-style": {
                          height: "4.4rem",
                          "max-width": "15rem"
                        },
                        "button-style": { "border-radius": "0.4rem" },
                        "custom-style": { left: 0 }
                      },
                      on: { click: _vm.applyInboxFilter }
                    })
                  : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "zero-state",
            fn: function() {
              return [
                !_vm.templateList.length
                  ? _c("table-zero-state", {
                      attrs: {
                        title: "No template added till now",
                        subtitle: "Click on the Sync Button to sync templates",
                        "asset-link": "/brand-assets/agent_zero_state.svg"
                      }
                    })
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }