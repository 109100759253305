var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "products-data flex-row flex-align--start flex-justify--between gap--normal"
    },
    [
      _c(
        "div",
        { staticClass: "full-width products-table" },
        [
          _vm.uiFlags.fetchingTopOrderedProducts
            ? _c("table-skeleton", { staticClass: "products-table-skeleton" })
            : _c("woot-table", {
                staticClass: "lime-card-1dp",
                attrs: {
                  title: _vm.$t(
                    "ANALYTICS.SHOPIFY_ANALYTICS.TABLES.TOP_ORDERED_PRODUCTS.TITLE"
                  ),
                  "header-list": _vm.tableHeader,
                  "table-data": _vm.topOrderedProductsTableData
                },
                scopedSlots: _vm._u([
                  {
                    key: "bottom",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          { staticClass: "flex-row flex-justify--end" },
                          [
                            _c("ve-pagination", {
                              attrs: {
                                total: _vm.topOrderedProductsMeta.total_entries,
                                "page-index":
                                  _vm.topOrderedProductsMeta.current_page,
                                "page-size": 5,
                                "paging-count": 2,
                                layout: ["total", "prev", "pager", "next"]
                              },
                              on: {
                                "on-page-number-change": function(idx) {
                                  return _vm.onPageNumberChange(idx, "ordered")
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "full-width products-table" },
        [
          _vm.uiFlags.fetchingTopCancelledProducts
            ? _c("table-skeleton", { staticClass: "products-table-skeleton" })
            : _c("woot-table", {
                staticClass: "lime-card-1dp",
                attrs: {
                  title: _vm.$t(
                    "ANALYTICS.SHOPIFY_ANALYTICS.TABLES.TOP_CANCELLED_PRODUCTS.TITLE"
                  ),
                  "header-list": _vm.tableHeader,
                  "table-data": _vm.topCancelledProductsTableData
                },
                scopedSlots: _vm._u([
                  {
                    key: "bottom",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          { staticClass: "flex-row flex-justify--end" },
                          [
                            _c("ve-pagination", {
                              attrs: {
                                total:
                                  _vm.topCancelledProductsMeta.total_entries,
                                "page-index":
                                  _vm.topCancelledProductsMeta.current_page,
                                "page-size": 5,
                                "paging-count": 2,
                                layout: ["total", "prev", "pager", "next"]
                              },
                              on: {
                                "on-page-number-change": function(idx) {
                                  return _vm.onPageNumberChange(
                                    idx,
                                    "cancelled"
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }