var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lime-card-new" },
    [
      _c("div", {
        staticClass: "text-dark title-h1",
        domProps: { textContent: _vm._s(_vm.allHandledTicketsCount) }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "flex-row flex-align gap--medium" }, [
        _c("span", { staticClass: "text-light title-h5_m" }, [
          _vm._v("Tickets Handled")
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "text-light title-h5_m" }, [
          _vm._v("\n      Avg: "),
          _c("b", {
            domProps: { textContent: _vm._s(_vm.averageHandledTickets) }
          })
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "charts-container" },
        _vm._l(_vm.charts, function(chart) {
          return _c("apex-chart", {
            key: chart.id,
            attrs: { type: "bar", options: chart.options, series: chart.series }
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "educational-cta" }, [
        _c(
          "a",
          {
            on: {
              click: function($event) {
                _vm.showEducationalModal = true
              }
            }
          },
          [_vm._v("How is it calculated?")]
        )
      ]),
      _vm._v(" "),
      _c("educational-onboarding-modal", {
        attrs: { show: _vm.showEducationalModal },
        on: {
          onClose: function($event) {
            _vm.showEducationalModal = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }