var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "company-modal-popup" }, [
    _c(
      "div",
      { staticClass: "company-avatar" },
      [
        _vm.loading
          ? _c("woot-loading-state", {
              attrs: {
                "custom-style": {
                  padding: "0rem",
                  height: "7.2rem",
                  display: "flex",
                  "align-items": "center"
                }
              }
            })
          : _c("Avatar", {
              attrs: {
                username: _vm.currentAccount && _vm.currentAccount.name,
                rounded: false,
                size: 56,
                colored: true,
                "show-upload-button": true,
                "custom-style": {
                  "font-size": "2.2rem",
                  "box-shadow": "none"
                },
                "img-src": _vm.avatarUrl ? _vm.avatarUrl : _vm.avatarImgSrc
              },
              on: { change: _vm.handleImageUpload }
            }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "flex-column flex-justify mg-left--slab",
            style: { overflow: "hidden" }
          },
          [
            _c("div", { staticClass: "title-h4 text-dark text-truncate" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.currentAccount.name) + "\n      "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "body-b3 text-light text-truncate" }, [
              _vm._v(
                "\n        " +
                  _vm._s("Account ID: " + _vm.currentAccount.id) +
                  "\n      "
              )
            ])
          ]
        )
      ],
      1
    ),
    _vm._v(" "),
    _vm.currentUser &&
    _vm.currentUser.accounts &&
    _vm.currentUser.accounts.length > 1
      ? _c(
          "div",
          { staticClass: "account-list" },
          [
            _c("span", { staticClass: "divider" }),
            _vm._v(" "),
            _c("search-box", {
              attrs: {
                placeholder: _vm.$t("SIDEBAR_ITEMS.ACCOUNT_SEARCH_TXT"),
                value: _vm.search,
                size: "medium"
              },
              on: { setSearch: _vm.setSearch }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "custom-scroll account-list--container" },
              [
                _c(
                  "label",
                  {
                    staticClass: "selected-account-container flex-space-between"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "title-h5 text-dark selected-account-container--name text-truncate"
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              "#" +
                                _vm.currentAccount.id +
                                " " +
                                _vm.currentAccount.name
                            ) +
                            "\n        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "subtitle-s2 text-light selected-account-container--role"
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.currentAccount.role) +
                            "\n        "
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _vm._l(_vm.filteredUserAccount, function(account) {
                  return _c(
                    "a",
                    {
                      key: account.id,
                      staticClass: "account-selector",
                      attrs: { href: _vm.redirectOnSwitchAccount(account.id) }
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "account--details",
                          attrs: { for: account.name }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "title-h5 text-dark account--name text-truncate"
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    "#" + account.id + " " + account.name
                                  ) +
                                  "\n          "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "subtitle-s2 text-light account--role"
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(account.role) +
                                  "\n          "
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                })
              ],
              2
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }