import { isValidPhoneNumber } from 'libphonenumber-js';
import { required, url, email } from 'vuelidate/lib/validators';

export const checkValidPhoneNumber = value => {
  return isValidPhoneNumber('+' + value);
};

export function validatePayload(payload) {
  if (!payload.inbox_id) {
    return ['NO_INBOX', false, 'INBOX_ID'];
  }

  if (!payload.contacts || payload.contacts.length === 0) {
    return ['NO_CONTACT', false, 'CONTACTS'];
  }

  if (payload.is_whatsapp_inbox) {
    // Check phone_number
    const notValidPhoneNumbers = payload.contacts.reduce(
      (acc, contact, index) => {
        if (!checkValidPhoneNumber(contact.phone_number)) {
          acc.push({
            error: 'NOT_VALID_PHONE_NUMBER',
            field: 'PHONE_NUMBER' + index,
          });
        }
        return acc;
      },
      []
    );

    if (notValidPhoneNumbers.length > 0) {
      return [
        notValidPhoneNumbers[0].error,
        false,
        notValidPhoneNumbers[0].field,
      ];
    }

    if (!payload.template_id) {
      return ['NO_TEMPLATE', false, 'TEMPLATE_ID'];
    }

    if (
      payload.has_media &&
      (!required(payload.media_url) || !url(payload.media_url))
    ) {
      return ['NOT_VALID_MEDIA_URL', false, 'MEDIA_URL'];
    }

    // Check template_attributes
    const missingSubstitutions = payload.contacts.reduce((acc, contact) => {
      if (contact.template_attributes) {
        contact.template_attributes.forEach((attr, attrIndex) => {
          if (!attr) {
            acc.push({
              error: 'MISSING_SUBSTITUTIONS',
              field: `TEMPLATE_ATTRIBUTE${attrIndex}`,
            });
          }
        });
      }
      return acc;
    }, []);

    if (missingSubstitutions.length > 0) {
      return [
        missingSubstitutions[0].error,
        false,
        missingSubstitutions[0].field,
      ];
    }

    if (payload.has_dynamic_button && !required(payload.dynamic_url)) {
      return ['NOT_VALID_DYNAMIC_URL', false, 'DYNAMIC_URL'];
    }
  } else if (payload.is_email_inbox) {
    // Check To emails
    let notValidEmails = payload.contacts.reduce((acc, contact, index) => {
      if (!email(contact.email_address)) {
        acc.push({
          error: 'NOT_VALID_EMAIL',
          field: 'EMAIL_TO_' + index,
        });
      }
      return acc;
    }, []);

    if (notValidEmails.length > 0) {
      return [notValidEmails[0].error, false, notValidEmails[0].field];
    }

    // Check CC emails
    notValidEmails = payload.cc_emails
      ?.split(',')
      .reduce((acc, cc_email, index) => {
        if (!email(cc_email)) {
          acc.push({
            error: 'NOT_VALID_EMAIL',
            field: 'EMAIL_CC_' + index,
          });
        }
        return acc;
      }, []);

    if (notValidEmails?.length > 0) {
      return [notValidEmails[0].error, false, notValidEmails[0].field];
    }

    // Check BCC emails
    notValidEmails = payload.bcc_emails
      ?.split(',')
      .reduce((acc, bcc_email, index) => {
        if (!email(bcc_email)) {
          acc.push({
            error: 'NOT_VALID_EMAIL',
            field: 'EMAIL_BCC_' + index,
          });
        }
        return acc;
      }, []);

    if (notValidEmails?.length > 0) {
      return [notValidEmails[0].error, false, notValidEmails[0].field];
    }

    if (!required(payload.subject)) {
      return ['NO_SUBJECT', false, 'SUBJECT'];
    }

    if (!required(payload.content)) {
      return ['NO_MESSAGE', false, 'BODY'];
    }
  }

  // All validations passed
  return ['', true];
}
