var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("section-header", { attrs: { "translation-key": "REMINDERS" } }),
      _vm._v(" "),
      _c(
        "aside",
        { staticClass: "sla-reminders" },
        [
          _vm._l(_vm.reminders, function(metricKey) {
            return _c(
              "div",
              {
                key: metricKey,
                staticClass: "filter filter--with-border",
                class: {
                  "mg-bottom--medium":
                    _vm.reminders.indexOf(metricKey) !==
                    _vm.reminders.length - 1
                }
              },
              [
                _c("span", [_vm._v("When SLA of ")]),
                _vm._v(" "),
                _c("metric-selector", {
                  class: "sla-reminders__metric_selector__" + metricKey,
                  attrs: {
                    value: metricKey,
                    "excluded-options": _vm.reminders
                  },
                  on: {
                    click: function($event) {
                      return _vm.onSelectMetric($event, metricKey)
                    }
                  }
                }),
                _vm._v(" "),
                metricKey !== "select"
                  ? [
                      _c("span", [_vm._v("approaches in")]),
                      _vm._v(" "),
                      _c("time-duration-selector", {
                        attrs: {
                          value: _vm.data.reminders[metricKey].before,
                          "include-immediate-option": false
                        },
                        on: {
                          click: function($event) {
                            return _vm.onSelectTimeDuration($event, metricKey)
                          }
                        }
                      })
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex-row flex-align gap--slab" },
                  [
                    metricKey !== "select"
                      ? [
                          _c(
                            "span",
                            { staticStyle: { "white-space": "nowrap" } },
                            [_vm._v("Send Reminder to")]
                          ),
                          _vm._v(" "),
                          _c("multi-select-trigger-values", {
                            staticClass: "multi-select-filter",
                            class: "multi-select-filter__" + metricKey,
                            attrs: {
                              value: _vm.getValues(metricKey),
                              "dropdown-values": _vm.getDropdownValues(
                                _vm.assigneeTrigger
                              )
                            },
                            on: {
                              input: function($event) {
                                return _vm.handleValues($event, metricKey)
                              }
                            }
                          })
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "woot-base-button",
                      {
                        attrs: { tag: "span", variant: "tertiary-danger" },
                        on: {
                          click: function($event) {
                            return _vm.deleteCondition(metricKey)
                          }
                        }
                      },
                      [
                        _c("icons", {
                          attrs: {
                            name: "bin",
                            color: "red",
                            size: "semimedium",
                            "show-title": false
                          }
                        })
                      ],
                      1
                    )
                  ],
                  2
                )
              ],
              2
            )
          }),
          _vm._v(" "),
          _vm.reminders.length !== 3
            ? _c(
                "div",
                { staticClass: "mg-top" },
                [
                  _c("add-filter-button", {
                    attrs: {
                      label: _vm.$t("SLA.CREATE.SECTIONS.METRICS.ADD_FILTER")
                    },
                    on: { click: _vm.onAddFilter }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }