var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.contacts.length === 0
    ? _c("span", { staticClass: "text-light body-b3" }, [
        _vm._v("\n  No Contacts added, please search and add from above\n")
      ])
    : _c(
        "transition-group",
        {
          staticClass: "selected-contact-results flex-row flex-wrap",
          attrs: { name: "menu-list", tag: "div" }
        },
        _vm._l(_vm.contacts, function(ref, idx) {
          var email = ref.email
          var type = ref.type
          return _c("woot-chips", {
            key: type + "_" + idx,
            class: "CREATE_TICKET_EMAIL_" + _vm.contactType + "_" + idx,
            staticStyle: { width: "fit-content", "max-width": "23.2rem" },
            attrs: {
              "show-cancel-icon": "",
              "show-tooltip": false,
              variant: "primary-small",
              title: email
            },
            on: { click: _vm.removeContact }
          })
        }),
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }