var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "flex-column gap--normal" }, [
    _c("div", { staticClass: "section-header" }, [
      _c("span", { staticClass: "text-light body-b2" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.$t("CUSTOM_TICKET_FIELDS.SUMMARY.HEADING")) +
            "\n    "
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "section-type flex-column gap--smaller" }, [
      _c("span", { staticClass: "title-h5 text-light" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.$t("CUSTOM_TICKET_FIELDS.SUMMARY.SUB_HEADINGS.TYPE")) +
            "\n    "
        )
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "title-h5 text-dark" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.customFieldType) +
            " - " +
            _vm._s(_vm.customFieldSubType) +
            "\n    "
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "section-name flex-column gap--smaller" }, [
      _c("span", { staticClass: "title-h5 text-light" }, [
        _vm._v(
          "\n      " +
            _vm._s(
              _vm.$t("CUSTOM_TICKET_FIELDS.SUMMARY.SUB_HEADINGS.FIELD_NAME")
            ) +
            "\n    "
        )
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "title-h5 text-dark" }, [
        _vm._v("\n      " + _vm._s(_vm.customField.name) + "\n    ")
      ])
    ]),
    _vm._v(" "),
    _vm.customField.dropdown_options
      ? _c("div", { staticClass: "section-values flex-column gap--smaller" }, [
          _c("span", { staticClass: "title-h5 text-light" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.$t("CUSTOM_TICKET_FIELDS.SUMMARY.SUB_HEADINGS.VALUES")
                ) +
                "\n    "
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-row flex-wrap" },
            _vm._l(_vm.customField.dropdown_options, function(ref, idx) {
              var value = ref.value
              return _c("woot-chips", {
                key: idx,
                staticStyle: { width: "fit-content", "max-width": "23.2rem" },
                attrs: {
                  "show-tooltip": false,
                  variant: "primary-small",
                  title: value
                }
              })
            }),
            1
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "section-inboxes flex-column gap--smaller" }, [
      _c("span", { staticClass: "title-h5 text-light" }, [
        _vm._v(
          "\n      " +
            _vm._s(
              _vm.$t("CUSTOM_TICKET_FIELDS.SUMMARY.SUB_HEADINGS.INBOXES")
            ) +
            "\n    "
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-row flex-wrap" },
        _vm._l(_vm.customField.inboxes, function(inbox, idx) {
          return _c("woot-chips", {
            key: idx,
            staticStyle: { width: "fit-content", "max-width": "23.2rem" },
            attrs: {
              "show-tooltip": false,
              variant: "primary-small",
              title: inbox.name,
              "front-icon": _vm.getInboxIconName(inbox)
            }
          })
        }),
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }