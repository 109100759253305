var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.uiFlags.isConfiguring
    ? _c("div", [
        _c("div", { staticClass: "text-align mg-bottom--larger" }, [
          _c(
            "div",
            {
              staticClass:
                "flex-row flex-justify flex-align gap--small mg-bottom"
            },
            [
              _c("icons", {
                attrs: {
                  name: "tickFilled",
                  size: "medium",
                  color: "green",
                  "show-title": false
                }
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "title-h1 text-dark",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t(
                      "ONBOARDING.WHATSAPP.WABA_INTEGRATION_COMPLETE.HEADING"
                    )
                  )
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("span", {
            staticClass: "body-b2 text-light",
            domProps: {
              innerHTML: _vm._s(
                _vm.$t(
                  "ONBOARDING.WHATSAPP.WABA_INTEGRATION_COMPLETE.SUB_HEADING"
                )
              )
            }
          })
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex-row flex-justify--end" },
          [
            _c("connect-button", {
              staticClass: "navigation-button",
              attrs: {
                label: _vm.$t(
                  "ONBOARDING.WHATSAPP.WABA_INTEGRATION_COMPLETE.CONTINUE"
                ),
                callback: _vm.configureChannel,
                "query-parameters": {
                  redirect_url: _vm.setRedirectUrl(),
                  state: _vm.inbox.phone_number
                }
              }
            })
          ],
          1
        )
      ])
    : _c("loading-state", {
        staticClass: "loader-cmpt",
        attrs: {
          message: _vm.$t(
            "INBOX_MGMT.ADD.THREE_SIXTY_DIALOG.CHANNEL_CREATION.LOADING"
          )
        }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }