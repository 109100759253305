var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(_vm.dynamicCmpt, { tag: "component", attrs: { data: _vm.data } })
    : _c("box-skeleton")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }