import { render, staticRenderFns } from "./MediaSlider.vue?vue&type=template&id=7648ebbf&scoped=true&"
import script from "./MediaSlider.vue?vue&type=script&lang=js&"
export * from "./MediaSlider.vue?vue&type=script&lang=js&"
import style0 from "./MediaSlider.vue?vue&type=style&index=0&id=7648ebbf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7648ebbf",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/limechat/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7648ebbf')) {
      api.createRecord('7648ebbf', component.options)
    } else {
      api.reload('7648ebbf', component.options)
    }
    module.hot.accept("./MediaSlider.vue?vue&type=template&id=7648ebbf&scoped=true&", function () {
      api.rerender('7648ebbf', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/lime-ui/dashboard/components/ui/MediaSlider.vue"
export default component.exports