import { render, staticRenderFns } from "./BaseTicketForm.vue?vue&type=template&id=4ce9fb81&scoped=true&"
import script from "./BaseTicketForm.vue?vue&type=script&lang=js&"
export * from "./BaseTicketForm.vue?vue&type=script&lang=js&"
import style0 from "./BaseTicketForm.vue?vue&type=style&index=0&id=4ce9fb81&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ce9fb81",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/limechat/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4ce9fb81')) {
      api.createRecord('4ce9fb81', component.options)
    } else {
      api.reload('4ce9fb81', component.options)
    }
    module.hot.accept("./BaseTicketForm.vue?vue&type=template&id=4ce9fb81&scoped=true&", function () {
      api.rerender('4ce9fb81', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/lime-ui/dashboard/routes/dashboard/conversation/overview/views/crms/create/crmTicketForms/shared/BaseTicketForm.vue"
export default component.exports