var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "status" }, [
    _c("div", { staticClass: "status-view" }, [
      _c("div", {
        class: "status-badge status-badge__" + _vm.currentUserAvailabilityStatus
      }),
      _vm._v(" "),
      _c("div", { staticClass: "status-view--title title-h5" }, [
        _vm._v("\n      " + _vm._s(_vm.availabilityDisplayLabel) + "\n    ")
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "status-change title-h5_m" },
      [
        _c("transition", { attrs: { name: "menu-slide" } }, [
          _vm.isStatusMenuOpened
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "on-clickaway",
                      rawName: "v-on-clickaway",
                      value: _vm.closeStatusMenu,
                      expression: "closeStatusMenu"
                    }
                  ],
                  staticClass: "dropdown-pane top availabilty-modal",
                  staticStyle: { width: "25rem" }
                },
                [
                  _c(
                    "ul",
                    { staticClass: "vertical dropdown menu" },
                    _vm._l(_vm.availabilityStatuses, function(status) {
                      return _c(
                        "li",
                        { key: status.value, staticClass: "status-items" },
                        [
                          _c("div", {
                            class: "status-badge status-badge__" + status.value
                          }),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass:
                                "button clear status-change--dropdown-button",
                              class: { disabled: status.disabled },
                              on: {
                                click: function($event) {
                                  return _vm.changeAvailabilityStatus(
                                    status.value
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(status.label) +
                                  "\n            "
                              )
                            ]
                          )
                        ]
                      )
                    }),
                    0
                  )
                ]
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "status-change--change-button",
            on: { click: _vm.openStatusMenu }
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("SIDEBAR_ITEMS.CHANGE_AVAILABILITY_STATUS")) +
                "\n    "
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }