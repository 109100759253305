var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "keyword-selector" },
    [
      _c(
        "woot-input",
        _vm._g(
          _vm._b(
            {
              attrs: {
                size: _vm.size,
                label: _vm.inputLabel,
                placeholder: _vm.inputPlaceholder,
                required: _vm.required,
                type: _vm.type,
                "has-error": !!_vm.errorMessage,
                error: _vm.errorMessage,
                "help-text": _vm.helpText
              },
              on: { input: _vm.clearError },
              nativeOn: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.addKeyword($event)
                }
              },
              model: {
                value: _vm.keyword,
                callback: function($$v) {
                  _vm.keyword = $$v
                },
                expression: "keyword"
              }
            },
            "woot-input",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mg-top--smaller" },
        _vm._l(_vm.keywords, function(chip, index) {
          return _c("woot-chips", {
            key: index,
            attrs: {
              title: chip,
              "custom-style": {
                color: "text-black-800"
              },
              variant: "secondary-small",
              "show-cancel-icon": true,
              "show-tooltip": false
            },
            on: {
              click: function($event) {
                return _vm.removeKeyword(index)
              }
            }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }