var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.hasReplaced && !_vm.errorMessage
    ? _c(
        "div",
        {
          staticClass:
            "text-container flex-row flex-align--start flex-justify--between gap--small"
        },
        [
          _c(
            "div",
            {
              staticClass: "flex-row gap--small",
              class: "flex-align" + (_vm.isImprovingText ? "" : "--start")
            },
            [
              _c("icons", {
                attrs: {
                  name: "wand",
                  size: "semimedium",
                  color: "green",
                  "show-title": false
                }
              }),
              _vm._v(" "),
              _vm.isImprovingText
                ? [
                    _c("span", {
                      staticClass: "title-h6 text-green",
                      domProps: {
                        textContent: _vm._s(_vm.$t("CONVERSATION.GPT.LOADING"))
                      }
                    }),
                    _vm._v(" "),
                    _c("three-dot-loader", { attrs: { color: "lime-green" } })
                  ]
                : _c("span", {
                    staticClass:
                      "body-b2 text-light improved-text custom-scroll",
                    domProps: { textContent: _vm._s(_vm.improvedText) }
                  })
            ],
            2
          ),
          _vm._v(" "),
          !_vm.isImprovingText
            ? _c(
                "div",
                { staticClass: "actions flex-row flex-align gap--small" },
                [
                  _c(
                    "woot-base-button",
                    {
                      staticStyle: { width: "8rem" },
                      attrs: {
                        tag: "span",
                        size: "small",
                        variant: "secondary",
                        "preserve-case": ""
                      },
                      on: { click: _vm.onReplace }
                    },
                    [
                      _c("icons", {
                        attrs: {
                          name: "tickSingle",
                          color: "green",
                          size: "small",
                          view: "1 1.5 14 14",
                          "show-title": false
                        }
                      }),
                      _vm._v("\n      Replace\n    ")
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "woot-base-button",
                    {
                      attrs: { tag: "span", variant: "tertiary-danger" },
                      on: {
                        click: function() {
                          _vm.mix_panel_clicked_reject_draft()
                          _vm.onClose()
                        }
                      }
                    },
                    [
                      _c("icons", {
                        attrs: {
                          name: "closeRound",
                          color: "lightgrey",
                          "show-title": false
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ]
      )
    : _vm.hasReplaced && !_vm.errorMessage
    ? _c("div", { staticClass: "success-container" }, [
        _c("span", { staticClass: "title-h5 text-lime-green" }, [
          _vm._v("Writing Improved with AI")
        ])
      ])
    : _c("div", { staticClass: "error-container" }, [
        _c("span", {
          staticClass: "title-h5 text-light",
          domProps: { textContent: _vm._s(_vm.errorMessage) }
        })
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }