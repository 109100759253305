var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    {
      attrs: {
        show: _vm.show,
        "on-close": function() {
          return _vm.$emit("onClose")
        }
      }
    },
    [
      _c("woot-modal-header", {
        attrs: {
          "header-title":
            "Edit Variable {{" + (_vm.currentSelectedVariableIndex + 1) + "}}"
        }
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "substitute-variables",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.$emit("onClose")
            }
          }
        },
        [
          _vm._l(_vm.substitutions[_vm.currentSelectedVariableIndex], function(
            value,
            index
          ) {
            return _c("woot-input", {
              key: index,
              staticClass: "mg-bottom--slab",
              attrs: {
                required: "",
                size: "small",
                "auto-focus": index === 0,
                value: value,
                label: "Customer " + (index + 1),
                placeholder: "Input substitution value for the customer"
              },
              on: {
                input: function($event) {
                  return _vm.$emit("input", $event, index)
                }
              }
            })
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-footer" },
            [
              _c("woot-base-button", { attrs: { type: "submit" } }, [
                _vm._v("\n        Save\n      ")
              ])
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }