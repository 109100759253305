var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("woot-single-selector", {
    attrs: {
      variant: "secondary",
      size: "small",
      "default-option": _vm.$t(
        "SLA.CREATE.SECTIONS.TIME_DURATION." + _vm.value.toString()
      ),
      "option-list": _vm.options,
      "custom-style": { left: 0, width: "20rem" },
      "show-selected-text": false
    },
    on: { click: _vm.onClick }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }