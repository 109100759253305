var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lime-card-1dp conv-header" },
    [
      _c("div", { staticClass: "header-actions-wrap" }, [
        _c("div", { staticClass: "multiselect-box" }, [
          _c(
            "div",
            {
              on: {
                click: function($event) {
                  _vm.showContactModal = true
                }
              }
            },
            [
              _c("woot-avatar", {
                attrs: {
                  username: _vm.currentContact.name,
                  color: "#fff",
                  "background-color": "#1B83AC"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "margin-left": "1rem" } },
            [
              _vm.currentContact.name
                ? _c("account-pii-mask-wrapper", {
                    key: "conversation-header-" + _vm.currentContact.id,
                    attrs: {
                      "mask-type": _vm.maskType.NAME,
                      "pii-data": _vm.currentContact.name,
                      "unmask-action": "piiMask/logUnmaskEvent",
                      "unmask-payload": _vm.unmaskRequestPayload
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var data = ref.data
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "title-h2 text-dark conversation--user",
                                  on: {
                                    click: function($event) {
                                      _vm.showContactModal = true
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(data) +
                                      "\n            "
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2101502839
                    )
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "flex-row-center flex-justify--start flex-wrap"
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex-row" },
                    [
                      _c("icons", {
                        attrs: {
                          name: _vm.computedInboxClass.name,
                          color: _vm.computedInboxClass.color + " evenodd",
                          size: "normal"
                        }
                      }),
                      _vm._v(" "),
                      _vm.accountId !== _vm.chat.account_id
                        ? _c(
                            "span",
                            {
                              staticClass: "inbox-name text-truncate",
                              on: {
                                click: function($event) {
                                  _vm.showContactModal = true
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.companyName(
                                      _vm.chat.account_id,
                                      _vm.currentUser
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          )
                        : _c(
                            "span",
                            {
                              staticClass:
                                "inbox-name body-b3 text-light text-truncate",
                              class: _vm.computedInboxClass.color + "-color",
                              on: {
                                click: function($event) {
                                  _vm.showContactModal = true
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.inboxInfo.name) +
                                  "\n            "
                              )
                            ]
                          )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.contact.phone_number
                    ? _c("account-pii-mask-wrapper", {
                        attrs: {
                          "mask-type": _vm.maskType.PHONE,
                          "pii-data": _vm.contact.phone_number,
                          "unmask-action": "piiMask/logUnmaskEvent",
                          "unmask-payload": _vm.unmaskRequestPayload,
                          "hide-toggle-icon": ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var data = ref.data
                                return [
                                  data
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "body-b3 text-dark contact-phone-number",
                                          on: {
                                            click: function($event) {
                                              return _vm.onCopy(data)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(data) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3889259691
                        )
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "actions-container flex-row flex-align flex-justify" },
          [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip.bottom",
                    value: _vm.tooltipMsg,
                    expression: "tooltipMsg",
                    modifiers: { bottom: true }
                  }
                ],
                class: {
                  "phone-icon": _vm.isCallFeatureAvailable,
                  "phone-icon--disabled": !_vm.isCallFeatureAvailable,
                  jiggle: _vm.callStatesFlags.showCallPopup
                },
                on: { click: _vm.onShowCallPopup }
              },
              [
                _c("icons", {
                  attrs: {
                    name:
                      _vm.isCallInProgress ||
                      _vm.callStatesFlags.showCallNotification
                        ? "phone"
                        : "call",
                    title: _vm.isCallInProgress ? "call in progress" : "call",
                    color: _vm.callIconColor,
                    size: "medium"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("more-actions", {
              attrs: { "conversation-id": _vm.currentChat.id }
            }),
            _vm._v(" "),
            _c("fav-button", {
              attrs: {
                "is-conv-fav": _vm.isConvFav,
                icon: "star",
                "icon-active-color": "warningyellow",
                "icon-inactive-color": "lightgrey",
                "icon-view-size": "0 0 20 20",
                "icon-size": "medium"
              },
              on: { click: _vm.toggleFav }
            }),
            _vm._v(" "),
            _vm.isLoading
              ? _c(
                  "div",
                  {
                    staticClass:
                      "spinner-container flex-row flex-align flex-justify"
                  },
                  [_c("spinner", { attrs: { size: "large" } })],
                  1
                )
              : _c(
                  "div",
                  {
                    staticClass: "multiselect-box",
                    staticStyle: { width: "12rem" }
                  },
                  [
                    _vm.currentStatus !== "Open"
                      ? _c(
                          "woot-single-selector",
                          {
                            attrs: {
                              id: "conversation-status--dropdown",
                              variant: "tertiary",
                              size: "small",
                              "custom-button": true,
                              "icon-size-prop": "medium",
                              "option-list": _vm.getActionList,
                              "default-option": _vm.currentStatus,
                              "show-option-name": false
                            },
                            on: { click: _vm.toggleStatus }
                          },
                          [
                            _vm.currentStatus !== "Open"
                              ? _c("woot-badge", {
                                  attrs: {
                                    "is-status": true,
                                    status: _vm.currentStatus
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "0.8rem" } },
                              [
                                _c("icons", {
                                  attrs: {
                                    name: "chevronDown",
                                    size: "medium",
                                    color: "green"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.currentStatus === "Open"
                      ? _c("woot-dropdown-button", {
                          attrs: {
                            id: "conversation-status-selector",
                            name: _vm.resolvedState,
                            "option-list": _vm.getActionList,
                            "button-click": _vm.changeToResolved,
                            "custom-style": { "min-width": "15rem" }
                          },
                          on: { click: _vm.toggleStatus }
                        })
                      : _vm._e()
                  ],
                  1
                )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("follow-up-modal", {
        attrs: {
          show: _vm.showAutoClosePopUp,
          status: _vm.newStatus,
          "on-close": _vm.onCancel,
          "on-click": _vm.updateStatus
        }
      }),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: {
            show: _vm.showContactModal,
            "on-close": _vm.onCancelContactModal,
            "show-close": false,
            "has-transition": false,
            "custom-style": { "margin-top": "3rem", "margin-left": "15rem" },
            "modal-position": { "align-items": "flex-start" }
          },
          on: {
            "update:show": function($event) {
              _vm.showContactModal = $event
            }
          }
        },
        [
          _c("contact-info", {
            attrs: { contact: _vm.contact, "inbox-info": _vm.inboxInfo },
            on: { showEdit: _vm.toggleEditModal }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.showEditModal
        ? _c("edit-contact", {
            attrs: { show: _vm.showEditModal, contact: _vm.contact },
            on: { cancel: _vm.toggleEditModal }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }