var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal-fade" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.show,
            expression: "show"
          }
        ],
        staticClass: "modal-mask",
        style: _vm.modalPosition,
        on: { click: _vm.onBackDropClick }
      },
      [
        _c(
          "div",
          {
            class: _vm.modalContainerClassName,
            style: _vm.customStyle,
            on: {
              click: function($event) {
                $event.stopPropagation()
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "positioned-container flex-row flex-align" },
              [
                _vm.numberOfCmpts > 0
                  ? _c("div", [
                      _vm.showProgress && _vm.numberOfCmpts - 1 !== 0
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "progress--container flex-row flex-align"
                            },
                            [
                              _c("span", {
                                staticClass:
                                  "progress--text title-h5 text-dark",
                                domProps: {
                                  textContent: _vm._s(_vm.progressText)
                                }
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "progress--bar" }, [
                                _c("div", {
                                  staticClass: "progress--bar--filled",
                                  style: { width: _vm.calculateWidth }
                                })
                              ])
                            ]
                          )
                        : _vm._e()
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.showClose
                  ? _c(
                      "woot-base-button",
                      {
                        staticClass: "close-icon",
                        attrs: { tag: "span", variant: "tertiary-danger" },
                        on: { click: _vm.onClose }
                      },
                      [
                        _c("icons", {
                          attrs: {
                            name: "closeRound",
                            color: "red",
                            size: "normal",
                            "show-title": false
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _vm._t("default"),
            _vm._v(" "),
            _vm._l(_vm.filteredPaginatedComponents, function(node, index) {
              return _c("node-renderer", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: index === _vm.currIndex,
                    expression: "index === currIndex"
                  }
                ],
                key: node.key || index,
                attrs: { value: node, tag: "div" }
              })
            }),
            _vm._v(" "),
            _vm.numberOfCmpts
              ? _c(
                  "div",
                  { staticClass: "pagination-controls" },
                  [
                    !_vm.isFirstIndex
                      ? _c(
                          "woot-base-button",
                          {
                            staticClass: "modal-button",
                            attrs: { variant: "secondary" },
                            on: { click: _vm.decrementIndex }
                          },
                          [_vm._v("\n          GO BACK\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isLastIndex
                      ? _c(
                          "woot-base-button",
                          {
                            staticClass: "modal-button",
                            attrs: {
                              variant: "primary",
                              disabled:
                                _vm.disableBtn && _vm.disableBtn[_vm.currIndex]
                            },
                            on: { click: _vm.incrementIndex }
                          },
                          [_vm._v("\n          NEXT\n        ")]
                        )
                      : _c(
                          "woot-base-button",
                          {
                            staticClass: "modal-button",
                            attrs: {
                              variant: "primary",
                              loading: _vm.loadingBtn,
                              disabled:
                                _vm.disableBtn && _vm.disableBtn[_vm.currIndex]
                            },
                            on: {
                              click: function($event) {
                                return _vm.$emit("submit")
                              }
                            }
                          },
                          [_vm._v("\n          SUBMIT\n        ")]
                        )
                  ],
                  1
                )
              : _vm._e()
          ],
          2
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }