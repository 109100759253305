var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "button-container" }, [
    _vm.stateText
      ? _c("span", {
          staticClass: "custom-switch-button title-h6 flex-row-justify-center",
          class: {
            "no-pointer-events":
              !_vm.stateText || !_vm.complimentStateText || _vm.activeState
          },
          style: [
            _vm.activeState
              ? !_vm.complimentStateText
                ? _vm.switchStyleInactive
                : _vm.switchStyleActive
              : _vm.switchStyleInactive
          ],
          domProps: { textContent: _vm._s(_vm.stateText) },
          on: {
            click: function($event) {
              return _vm.$emit("click")
            }
          }
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.complimentStateText
      ? _c("span", {
          staticClass: "custom-switch-button title-h6 flex-row-justify-center",
          class: {
            "no-pointer-events":
              !_vm.stateText || !_vm.complimentStateText || !_vm.activeState
          },
          style: [
            !_vm.activeState
              ? !_vm.stateText
                ? _vm.switchStyleInactive
                : _vm.switchStyleActive
              : _vm.switchStyleInactive
          ],
          domProps: { textContent: _vm._s(_vm.complimentStateText) },
          on: {
            click: function($event) {
              return _vm.$emit("click")
            }
          }
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }