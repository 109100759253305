var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "draggable-values-section" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "draggable",
        {
          attrs: { list: _vm.list },
          on: {
            end: function($event) {
              return _vm.$emit("updateValues", _vm.list)
            }
          }
        },
        _vm._l(_vm.list, function(_, index) {
          return _c(
            "div",
            { key: index, staticClass: "flex-row flex-justify--start" },
            [
              _c(
                "span",
                {
                  staticClass: "mg-right--small",
                  staticStyle: { cursor: "pointer" }
                },
                [
                  _c("icons", {
                    attrs: {
                      name: "drag",
                      color: "lightgrey-fill-only",
                      size: "mediumlarge"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("woot-input", {
                staticClass: "name-input",
                attrs: {
                  size: "small",
                  disabled: _vm.disabled && !!_vm.list[index].id
                },
                on: {
                  blur: function($event) {
                    return _vm.$emit("updateLabelName", $event, index)
                  }
                },
                model: {
                  value: _vm.list[index].value,
                  callback: function($$v) {
                    _vm.$set(_vm.list[index], "value", $$v)
                  },
                  expression: "list[index].value"
                }
              }),
              _vm._v(" "),
              _c(
                "woot-base-button",
                {
                  staticStyle: { "margin-bottom": "-0.4rem" },
                  attrs: { size: "small", tag: "span", variant: "tertiary" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("addField", index)
                    }
                  }
                },
                [
                  _c(
                    "span",
                    [
                      _c("icons", {
                        attrs: {
                          name: "plus",
                          color: "textlightgrey",
                          size: "medium"
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "woot-base-button",
                {
                  attrs: {
                    size: "small",
                    tag: "span",
                    variant: "tertiary-danger"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("removeField", index)
                    }
                  }
                },
                [
                  _c(
                    "span",
                    { staticStyle: { cursor: "pointer" } },
                    [
                      _c("icons", {
                        attrs: {
                          name: "delete",
                          color: "textlightgrey",
                          size: "medium"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "formlabel" }, [
      _c("span", { staticClass: "title-h5 text-dark" }, [_vm._v(" Values ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }