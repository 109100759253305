var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-row flex-align flex-justify zero-state" },
    [
      _c(
        "div",
        { staticClass: "flex-column flex-align assets-container" },
        [
          _c("img", { attrs: { src: _vm.assetLink } }),
          _vm._v(" "),
          _c("span", {
            staticClass: "title-h2 text-light mg-top--large text-align--center",
            domProps: { textContent: _vm._s(_vm.title) }
          }),
          _vm._v(" "),
          _c("div", {
            staticClass:
              "subtitle-s2 text-light text-align--center mg-top--small mg-bottom--large",
            domProps: { textContent: _vm._s(_vm.subtitle) }
          }),
          _vm._v(" "),
          _vm._t("default")
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }