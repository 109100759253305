var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "user-thumbnail-box",
      style: { height: _vm.size, width: _vm.size }
    },
    [
      !_vm.imgError && Boolean(_vm.src) && !_vm.isSrcBrokenGravatar
        ? _c("img", {
            class: _vm.thumbnailClass,
            style: _vm.imgStyle,
            attrs: { id: "image", src: _vm.src },
            on: {
              error: function($event) {
                return _vm.onImgError()
              }
            }
          })
        : _vm.dummy !== 0
        ? _c("img", {
            class: _vm.thumbnailClass,
            style: _vm.imgStyle,
            attrs: {
              id: "dummy-avatar",
              src: require("dashboard/assets/images/thumbnail/" +
                _vm.dummy +
                ".svg")
            }
          })
        : _c("Avatar", {
            class: _vm.thumbnailClass,
            attrs: {
              username: _vm.username,
              "custom-style": _vm.customStyle,
              color: _vm.color,
              "background-color": _vm.bgColor,
              size: _vm.avatarSize
            }
          }),
      _vm._v(" "),
      _vm.showStatusIndicator
        ? _c("div", {
            class:
              "source-badge user-online-status user-online-status--" +
              _vm.status,
            style: _vm.statusStyle
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }