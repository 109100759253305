var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mg-left--smaller mg-right--smaller" }, [
    _c(
      "div",
      { staticClass: "flex-row flex-justify flex-align gap--small" },
      [
        _c(
          "div",
          { staticClass: "small-10" },
          [
            _c("dynamic-input", {
              attrs: {
                value: _vm.userInputValue,
                label: _vm.label,
                "data-type": _vm.innerDataType,
                validator: _vm.validator,
                "help-text": "Type the keyword and hit + button"
              },
              on: {
                "update-value": function(newValue) {
                  return (_vm.userInputValue = newValue)
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "woot-base-button",
          {
            staticClass: "small-2 mg-top--smaller",
            attrs: { tag: "span", variant: "tertiary" },
            on: { click: _vm.onAdd }
          },
          [
            _c("icons", {
              attrs: {
                name: "plus",
                color: "green",
                size: "mediumlarge",
                view: "-2 -2 24 24",
                "show-title": false
              }
            })
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mg-top--small" },
      _vm._l(_vm.inputValues, function(keyword) {
        return _c("woot-chips", {
          key: keyword,
          attrs: {
            title: keyword.toString(),
            "show-tooltip": false,
            "show-cancel-icon": "",
            variant: "primary-small"
          },
          on: { click: _vm.onRemove }
        })
      }),
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }