var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "column padding-container settings-box custom-scroll",
      staticStyle: { "padding-top": "0px" }
    },
    [
      _c("div", { staticClass: "filter-pane" }, [
        _c(
          "div",
          {
            staticClass: "flex-space-between",
            staticStyle: { "margin-top": "0.5rem" }
          },
          [
            _c(
              "div",
              { staticClass: "flex-row analytics-filters" },
              [
                _c("date-range-selector", {
                  attrs: { "default-selection": 2 },
                  on: { "date-range-change": _vm.onDateRangeChange }
                }),
                _vm._v(" "),
                _c("inbox-filter", {
                  attrs: {
                    "allow-multi-select": true,
                    value: _vm.$t(
                      "ANALYTICS.FILTER_SELECTION.INBOX.PLACEHOLDER"
                    ),
                    "exclude-inbox-types": _vm.excludedInboxTypes,
                    "toggle-filter": _vm.changeInboxId
                  }
                }),
                _vm._v(" "),
                _c("woot-single-selector", {
                  attrs: {
                    disabled: false,
                    variant: "secondary",
                    size: "medium",
                    "default-option": _vm.selectedWorkingHour,
                    "option-list": _vm.optionList,
                    "show-selected-text": false,
                    "custom-style": { width: "auto" }
                  },
                  on: { click: _vm.changeWorkingHourType }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("woot-primary-button", {
                  attrs: {
                    name: _vm.$t("ANALYTICS.DOWNLOAD_REPORT.REPORT"),
                    "front-icon": "email"
                  },
                  on: { click: _vm.download }
                })
              ],
              1
            )
          ]
        )
      ]),
      _vm._v(" "),
      _vm.inboxWebsiteDivisionUIFlag
        ? _c("div", { staticClass: "flex-space-between" }, [
            _c(
              "div",
              { staticStyle: { width: "33%" } },
              [_c("pie-chart-skeleton")],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { width: "33%" } },
              [_c("pie-chart-skeleton")],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { width: "33%" } },
              [_c("pie-chart-skeleton")],
              1
            )
          ])
        : _c("div", { staticClass: "flex-space-between" }, [
            _c(
              "div",
              {
                staticClass: "lime-card-1dp bottom-container",
                staticStyle: { width: "49%", height: "400px" }
              },
              [
                _c("pie-chart", {
                  attrs: {
                    title: "Inbox Division",
                    series: _vm.channelDistributionSeries,
                    colors: _vm.channelDistributionColors,
                    labels: _vm.channelDistributionCategories,
                    height: 350,
                    width: "100%",
                    "legend-position": "bottom",
                    "legend-offset-x": 0,
                    "legend-offset-y": 0,
                    "marker-offset-x": 0,
                    "market-offset-y": 0,
                    "chart-offset-x": -10,
                    "chart-offset-y": 10
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "lime-card-1dp bottom-container",
                staticStyle: { width: "49%", height: "400px" }
              },
              [
                _c("pie-chart", {
                  attrs: {
                    title: "Website page breakdown",
                    series: _vm.websiteEntrySeries,
                    colors: _vm.websiteEntryColors,
                    labels: _vm.websiteEntryLabels,
                    height: 350,
                    width: "100%",
                    "legend-position": "bottom",
                    "legend-offset-x": 0,
                    "legend-offset-y": 0,
                    "marker-offset-x": 0,
                    "market-offset-y": 0,
                    "chart-offset-x": -10,
                    "chart-offset-y": 10
                  }
                })
              ],
              1
            )
          ]),
      _vm._v(" "),
      _vm.userConcernComplainUIFlag
        ? _c(
            "div",
            { staticClass: "flex-space-between" },
            [_c("table-skeleton")],
            1
          )
        : _c("div", { staticClass: "flex-space-between" }, [
            _c(
              "div",
              {
                staticClass: "bottom-container",
                staticStyle: { width: "49%" }
              },
              [
                _c("woot-table", {
                  staticClass: "lime-card-1dp",
                  attrs: {
                    title: "Users Concerns",
                    "header-list": _vm.$t("ANALYTICS.USER_CONCERNS_HEADER"),
                    "table-data": _vm.userTableOne,
                    "is-sortable": true,
                    "max-height": _vm.maxTableHeight
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "bottom-container",
                staticStyle: { width: "49%" }
              },
              [
                _c("woot-table", {
                  staticClass: "lime-card-1dp",
                  attrs: {
                    title: "Users Complaints",
                    "header-list": _vm.$t("ANALYTICS.USER_COMPLAINT_HEADER"),
                    "table-data": _vm.userTableTwo,
                    "is-sortable": true,
                    "max-height": _vm.maxTableHeight
                  }
                })
              ],
              1
            )
          ]),
      _vm._v(" "),
      _vm.userHandoffUIFlag
        ? _c("div", { staticClass: "flex-space-between" }, [
            _c(
              "div",
              { staticStyle: { width: "33%" } },
              [_c("pie-chart-skeleton")],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { width: "33%" } },
              [_c("pie-chart-skeleton")],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { width: "33%" } },
              [_c("pie-chart-skeleton")],
              1
            )
          ])
        : _c("div", { staticClass: "flex-space-between" }, [
            _c(
              "div",
              {
                staticClass: "lime-card-1dp bottom-container",
                staticStyle: { width: "49%", height: "390px" }
              },
              [
                _c("pie-chart", {
                  attrs: {
                    title: "Reason of agent handoff",
                    series: _vm.handoffReasonPieSeries,
                    colors: _vm.handoffReasonColors,
                    labels: _vm.handoffReasonLabels,
                    height: 400,
                    width: "510",
                    "legend-position": "right",
                    "legend-offset-x": -10,
                    "legend-offset-y": 20,
                    "marker-offset-x": 0,
                    "market-offset-y": 0,
                    "chart-offset-x": 0,
                    "chart-offset-y": 15
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "lime-card-1dp bottom-container",
                staticStyle: { width: "49%", height: "390px" }
              },
              [
                _c("pie-chart", {
                  attrs: {
                    title: "Flow Breakdown of Agent Handoff",
                    series: _vm.userHandoffPieSeries,
                    colors: _vm.userHandoffPieColors,
                    labels: _vm.userHandoffPieCategories,
                    height: 340,
                    width: "490",
                    "legend-position": "right",
                    "legend-offset-x": -40,
                    "legend-offset-y": 70,
                    "marker-offset-x": 0,
                    "market-offset-y": 0,
                    "chart-offset-x": 0,
                    "chart-offset-y": 10
                  }
                })
              ],
              1
            )
          ]),
      _vm._v(" "),
      _vm.userActivityUIFlag
        ? _c("div", [_c("line-chart-skeleton")], 1)
        : _c(
            "div",
            { staticClass: "lime-card-1dp medium-12 bottom-container" },
            [
              _c("heat-map-chart", {
                attrs: {
                  title: "Users Activity",
                  series: _vm.userActivityHeatMapData,
                  height: _vm.heatMapHeight
                }
              })
            ],
            1
          ),
      _vm._v(" "),
      _vm.userBotFailingTableUIFlag
        ? _c("div", [_c("table-skeleton")], 1)
        : _c(
            "div",
            { staticClass: "bottom-container" },
            [
              _c("woot-table", {
                staticClass: "lime-card-1dp",
                attrs: {
                  title: "Bot failing utterances",
                  "header-list": _vm.$t(
                    "ANALYTICS.BOT_FAILING_UTTERANCES_HEADER"
                  ),
                  "table-data": _vm.botFailingTable,
                  "is-sortable": true,
                  "is-downloadable": true,
                  "download-text": "Download Full List",
                  "is-downloading": _vm.downloadUIFlag,
                  "column-width-one": "40%",
                  "column-width-two": "20%",
                  "text-width": "40rem"
                },
                on: { "download-csv": _vm.downloadBotFailingUtterancesTable }
              })
            ],
            1
          ),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: { show: _vm.downloading, "on-close": _vm.hidePopup },
          on: {
            "update:show": function($event) {
              _vm.downloading = $event
            }
          }
        },
        [
          _c("div", { staticClass: "download-note" }, [
            _c("img", {
              attrs: {
                src:
                  "https://s3.ap-south-1.amazonaws.com/cdn.limechat.ai/assets/images/limechat_report_sent.svg"
              }
            }),
            _vm._v(" "),
            _c("div", [
              _vm._v(
                "\n        An email with attached report will be sent to\n        "
              ),
              _c("span", [_vm._v(_vm._s(_vm.userEmail) + " ")]),
              _vm._v(" shortly.\n      ")
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: { show: _vm.downloadError, "on-close": _vm.hidePopup },
          on: {
            "update:show": function($event) {
              _vm.downloadError = $event
            }
          }
        },
        [
          _c("div", { staticClass: "download-note" }, [
            _c("div", [_vm._v("Sorry for inconvenience some error occured.")])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }