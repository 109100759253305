var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    {
      attrs: {
        show: _vm.show,
        "on-close": _vm.onCancel,
        "show-close": false,
        "small-modal": ""
      },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c("div", { staticClass: "column content-box" }, [
        _c("div", { staticClass: "column page-top-bar modal-header" }, [
          _c("span", { staticClass: "page-sub-title title-h2" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.$t("BROADCAST.BROADCAST_TAB.SAVE_AND_SCHEDULE.TITLE")
                ) +
                "\n      "
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row settings-form" }, [
          _c("span", { staticClass: "text-dark body-b2 mg-bottom--medium" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.$t("BROADCAST.BROADCAST_TAB.SAVE_AND_SCHEDULE.DESC")
                ) +
                "\n      "
            )
          ]),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "medium-12 body-b2 text-light mg-bottom--small" },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t("BROADCAST.BROADCAST_TAB.SAVE_AND_SCHEDULE.SUBTITLE")
                  ) +
                  "\n      "
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "flex-row" }, [
            _c(
              "div",
              { staticClass: "date-picker mg-right" },
              [
                _c("date-picker", {
                  attrs: {
                    "show-range": false,
                    value: _vm.selectedDate,
                    variant: "small",
                    "disabled-future-dates": _vm.allowedDates,
                    "disable-previous-date": true
                  },
                  on: { change: _vm.onChange }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "limechat-timepicker row" }, [
              _c(
                "div",
                {
                  staticClass: "column flex-row-justify-center picker-container"
                },
                [
                  _c("vue-timepicker", {
                    attrs: {
                      "hide-clear-button": "",
                      "minute-interval": 15,
                      format: "HH:mm",
                      "hour-range": _vm.enabledHours,
                      "minute-range": _vm.enabledMinutes
                    },
                    model: {
                      value: _vm.selectedTime,
                      callback: function($$v) {
                        _vm.selectedTime = $$v
                      },
                      expression: "selectedTime"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-footer mg-top" },
            [
              _c("woot-tertiary-button", {
                attrs: {
                  name: _vm.$t(
                    "BROADCAST.BROADCAST_TAB.SAVE_AND_SCHEDULE.CANCEL"
                  ),
                  "custom-style": { color: "#FF422E" }
                },
                on: { click: _vm.onCancel }
              }),
              _vm._v(" "),
              _c("woot-primary-button", {
                attrs: {
                  name: _vm.$t(
                    "BROADCAST.BROADCAST_TAB.SAVE_AND_SCHEDULE.BUTTON"
                  )
                },
                on: {
                  click: function($event) {
                    return _vm.onSaveAndSchedule(
                      _vm.selectedDate,
                      _vm.selectedTime
                    )
                  }
                }
              })
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }