var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column view-box" },
    [
      _c("settings-header", {
        attrs: {
          icon: "graph",
          "header-title": _vm.$t("ANALYTICS.HEADER.SHOPIFY_ANALYTICS"),
          "show-back-button": false
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "column content-box settings-box" }, [
        _c(
          "div",
          { staticClass: "settings-container columns custom-scroll" },
          [
            _c(
              "div",
              { staticClass: "flex-space-between" },
              [
                _c(
                  "div",
                  { staticClass: "flex-row gap--normal" },
                  [
                    _c("date-range-selector", {
                      attrs: { "default-selection": 0 },
                      on: { "date-range-change": _vm.onDateRangeChange }
                    }),
                    _vm._v(" "),
                    _c("inbox-filter", {
                      attrs: {
                        value: _vm.selectedInbox,
                        "exclude-inbox-types": _vm.excludedInboxTypes,
                        "default-option": _vm.defaultInbox
                      },
                      on: { click: _vm.changeInboxId }
                    }),
                    _vm._v(" "),
                    _c("woot-single-selector", {
                      attrs: {
                        variant: "secondary",
                        "option-list": _vm.agentList,
                        "default-option": _vm.selectedAgent,
                        "custom-style": { width: "auto" },
                        "show-selected-text": false
                      },
                      on: { click: _vm.changeAgentId }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "woot-base-button",
                  {
                    attrs: { "front-icon": "email" },
                    on: { click: _vm.downloadReport }
                  },
                  [_vm._v("\n          Report\n        ")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("metrics"),
            _vm._v(" "),
            _c("sales-data"),
            _vm._v(" "),
            _c("products-table", {
              attrs: { "applied-filters": _vm.appliedFilters }
            }),
            _vm._v(" "),
            _c("orders-table", {
              attrs: { "applied-filters": _vm.appliedFilters },
              on: { "status-change": _vm.onStatusChange }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: { show: _vm.downloading, "on-close": _vm.hidePopup },
          on: {
            "update:show": function($event) {
              _vm.downloading = $event
            }
          }
        },
        [
          _c("div", { staticClass: "download-note" }, [
            _c("img", {
              attrs: {
                src:
                  "https://s3.ap-south-1.amazonaws.com/cdn.limechat.ai/assets/images/limechat_report_sent.svg"
              }
            }),
            _vm._v(" "),
            _c("div", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.$t("ANALYTICS.DOWNLOAD_REPORT.EMAIL", {
                    email: _vm.userEmail
                  })
                )
              }
            })
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: { show: _vm.downloadError, "on-close": _vm.hidePopup },
          on: {
            "update:show": function($event) {
              _vm.downloadError = $event
            }
          }
        },
        [
          _c("div", { staticClass: "download-note" }, [
            _c("div", {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("ANALYTICS.DOWNLOAD_REPORT.REPORT_FAIL")
                )
              }
            })
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }