var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currentView
    ? _c(
        "div",
        {
          staticClass:
            "views-toggle-button flex-row flex-align flex-justify--between gap--small"
        },
        [
          _c("div", { staticClass: "flex-row flex-align gap--small" }, [
            _c("div", {
              staticClass: "selected-view",
              domProps: { textContent: _vm._s(_vm.currentView.name) }
            }),
            _vm._v(" "),
            _c("div", {
              staticClass: "metric-capsule",
              domProps: { textContent: _vm._s(_vm.showCount) }
            })
          ]),
          _vm._v(" "),
          _c(
            "woot-base-button",
            {
              staticClass: "open-views-list-btn",
              attrs: { tag: "span", size: "small", variant: "tertiary" },
              on: {
                click: function($event) {
                  return _vm.$emit("showViewsManager")
                },
                mousedown: function($event) {
                  $event.stopPropagation()
                }
              }
            },
            [
              _c("icons", {
                attrs: {
                  name: "chevronDown",
                  color: "grey",
                  view: "0 0 24 24",
                  size: "semimedium"
                }
              })
            ],
            1
          )
        ],
        1
      )
    : _c("div", {
        staticClass: "skeleton-animation",
        staticStyle: { height: "28px", width: "200px" }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }