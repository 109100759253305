var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "full-width": _vm.fullWidth,
        "no-spin-buttons": !_vm.showSpinButtons
      }
    },
    [
      _c(
        "label",
        {
          ref: "formLabel",
          staticClass: "formlabel flex-row flex-justify--between gap-4",
          style:
            !_vm.label && !_vm.hasError && !_vm.required && "margin-bottom: 0"
        },
        [
          _c(
            "div",
            { ref: "labelContainer", staticClass: "flex-row flex-align gap-4" },
            [
              _vm.required
                ? _c("span", {
                    staticClass: "dot-circle",
                    attrs: { title: "required" }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.label
                ? _c("span", {
                    class: [
                      "title-h5 text-dark",
                      { "text-light": _vm.disabled }
                    ],
                    domProps: { textContent: _vm._s(_vm.label) }
                  })
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c("transition", { attrs: { name: "slide-down" } }, [
            _vm.hasError
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip.top",
                        value: _vm.collapseErrorMessage ? _vm.error : "",
                        expression: "collapseErrorMessage ? error : ''",
                        modifiers: { top: true }
                      }
                    ],
                    ref: "errorContainer",
                    staticClass: "error-container flex-row flex-align gap-4"
                  },
                  [
                    _c("icons", {
                      attrs: {
                        name: "danger",
                        size: "semimedium",
                        color: "danger",
                        "show-title": false
                      }
                    }),
                    _vm._v(" "),
                    !_vm.collapseErrorMessage
                      ? _c("span", {
                          staticClass: "body-b3 text-warn",
                          attrs: { "show-title": false },
                          domProps: { textContent: _vm._s(_vm.error) }
                        })
                      : _c("span", {
                          staticClass: "body-b3 text-warn",
                          domProps: { textContent: _vm._s("Incorrect Details") }
                        })
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: { "read-only-active": _vm.readonly },
          staticStyle: { position: "relative" }
        },
        [
          _c(_vm.tag, {
            directives: [
              {
                name: "focus",
                rawName: "v-focus",
                value: _vm.autoFocus,
                expression: "autoFocus"
              }
            ],
            tag: "component",
            staticClass: "body-b3 form-input custom-scroll",
            style: [_vm.inputSize],
            attrs: {
              type: _vm.inputType,
              rows: _vm.rows,
              placeholder: _vm.placeholder,
              readonly: _vm.readonly,
              disabled: _vm.disabled
            },
            domProps: { value: _vm.value },
            on: {
              input: _vm.onChange,
              blur: _vm.onBlur,
              keydown: function($event) {
                _vm.inputType === "Number" ? _vm.validatePureNumber : null
              }
            }
          }),
          _vm._v(" "),
          _vm.helpText
            ? _c("div", {
                staticClass: "body-b3 helper-text",
                domProps: { textContent: _vm._s(_vm.helpText) }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showPasswordButton && _vm.value.length > 0
            ? _c(
                "button",
                {
                  staticClass: "password-eye-button",
                  class: { "password-hide": !_vm.hidePassword },
                  attrs: { type: "button" },
                  on: { click: _vm.showPasswordToggle }
                },
                [
                  _c("icons", {
                    attrs: {
                      name: "eye",
                      size: "semimedium",
                      color: "stroke-grey",
                      "custom-style": { strokeWidth: 2 },
                      title: _vm.hidePassword ? "Hide" : "Show"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }