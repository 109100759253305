var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "type-section-wrapper" }, [
    _c("section", { staticClass: "type-section mg-bottom--normal" }, [
      _c("label", { staticClass: "formlabel" }, [
        _c("span", {
          staticClass: "body-b2 text-light",
          domProps: {
            textContent: _vm._s(
              _vm.$t("CUSTOM_TICKET_FIELDS.ADD.FORM.TYPE.LABEL")
            )
          }
        })
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-row gap--normal" },
        [
          _c("woot-single-selector", {
            class: { disabled: _vm.editView },
            attrs: {
              size: "small",
              "default-option": _vm.selectedType,
              "option-list": _vm.CUSTOM_FIELD_TEMPLATES,
              "show-selected-text": false,
              "custom-style": { left: 0, width: "100%" },
              "dropdown-container-style": { width: "36rem" }
            },
            on: { click: _vm.changeType }
          }),
          _vm._v(" "),
          _vm.editView
            ? _c(
                "span",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: "Cannot change field type in edit view",
                      expression: "'Cannot change field type in edit view'"
                    }
                  ]
                },
                [
                  _c("icons", {
                    attrs: {
                      name: "warning",
                      color: "grey evenodd",
                      size: "semimedium",
                      "show-title": false
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }