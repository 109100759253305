var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.onSubmitCannedResponse($event)
        }
      }
    },
    [
      !_vm.data
        ? _c("div", {
            staticClass: "title-h3 text-dark mg-bottom--two",
            domProps: { textContent: _vm._s("Details") }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("woot-input", {
        staticClass: "medium-12 columns content-margin",
        class: { error: _vm.$v.shortCode.$error },
        attrs: {
          required: "",
          "auto-focus": "",
          "has-error": _vm.$v.shortCode.$error,
          error: _vm.$t("CANNED_MGMT.ADD.FORM.SHORT_CODE.ERROR"),
          label: _vm.$t("CANNED_MGMT.ADD.FORM.SHORT_CODE.LABEL"),
          placeholder: _vm.$t("CANNED_MGMT.ADD.FORM.SHORT_CODE.PLACEHOLDER")
        },
        on: { blur: _vm.$v.shortCode.$touch },
        model: {
          value: _vm.shortCode,
          callback: function($$v) {
            _vm.shortCode = typeof $$v === "string" ? $$v.trim() : $$v
          },
          expression: "shortCode"
        }
      }),
      _vm._v(" "),
      _c("woot-input", {
        staticClass: "medium-12 columns content-margin",
        class: { error: _vm.$v.content.$error },
        attrs: {
          rows: _vm.data ? "15" : "6",
          type: "text",
          required: "",
          "has-error": _vm.$v.content.$error,
          error: _vm.$t("CANNED_MGMT.ADD.FORM.CONTENT.ERROR"),
          label: _vm.$t("CANNED_MGMT.ADD.FORM.CONTENT.LABEL"),
          placeholder: _vm.$t("CANNED_MGMT.ADD.FORM.CONTENT.PLACEHOLDER")
        },
        on: { blur: _vm.$v.content.$touch },
        model: {
          value: _vm.content,
          callback: function($$v) {
            _vm.content = typeof $$v === "string" ? $$v.trim() : $$v
          },
          expression: "content"
        }
      }),
      _vm._v(" "),
      false
        ? _c(
            "div",
            { staticClass: "attachment-container" },
            [
              _c(
                "file-upload",
                {
                  ref: "uploadCannedMedia",
                  attrs: {
                    size: 4096 * 4096,
                    accept:
                      "image/*, application/pdf, audio/mpeg, video/mp4, audio/ogg"
                  },
                  on: {
                    "input-filter": _vm.addImagePreviewUrl,
                    "input-file": _vm.onFileUpload
                  },
                  model: {
                    value: _vm.media,
                    callback: function($$v) {
                      _vm.media = $$v
                    },
                    expression: "media"
                  }
                },
                [
                  _c(
                    "span",
                    { staticClass: "form-label form-attachment" },
                    [
                      !_vm.isUploading
                        ? _c("icons", {
                            attrs: {
                              name: "clip",
                              color: "darkestgrey",
                              size: "semimedium"
                            }
                          })
                        : _c("woot-spinner"),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticStyle: { "padding-left": "1.2rem" } },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("CANNED_MGMT.ADD.FORM.MEDIA.LABEL"))
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _vm.media.length && _vm.attachmentPreview
                ? _c("attachment-preview", {
                    attrs: { files: _vm.media },
                    on: { clear: _vm.clearAttachments }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.data
        ? _c(
            "div",
            { staticClass: "pagination-controls" },
            [
              _c(
                "woot-base-button",
                {
                  staticClass: "modal-button",
                  attrs: { variant: "tertiary-danger" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.onClose($event)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("CANNED_MGMT.ADD.CANCEL_BUTTON_TEXT")) +
                      "\n    "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "woot-base-button",
                {
                  staticClass: "modal-button",
                  attrs: {
                    variant: "primary",
                    disabled:
                      _vm.$v.content.$invalid ||
                      _vm.$v.shortCode.$invalid ||
                      _vm.canned.showLoading,
                    loading: _vm.canned.showLoading,
                    type: "submit"
                  }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("CANNED_MGMT.ADD.FORM.SUBMIT")) +
                      "\n    "
                  )
                ]
              )
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "flex-row flex-justify--end" },
            [
              _c(
                "woot-base-button",
                {
                  staticClass: "modal-button mg-bottom",
                  attrs: {
                    variant: "primary",
                    disabled:
                      _vm.$v.content.$invalid ||
                      _vm.$v.shortCode.$invalid ||
                      _vm.canned.showLoading,
                    loading: _vm.canned.showLoading,
                    type: "submit"
                  },
                  nativeOn: {
                    click: function($event) {
                      return _vm.InlineButtonClickHandler($event)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("CANNED_MGMT.EDIT.FORM.SAVE")) +
                      "\n    "
                  )
                ]
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }