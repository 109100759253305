var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "banner-class", class: _vm.variant, style: _vm.customStyle },
    [
      _c("icons", {
        attrs: { name: "info", size: "medium", color: _vm.iconColor }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "icon-margin" }, [
        _c("h5", { staticClass: "title-h5 title-color" }, [
          _vm._v("\n      " + _vm._s(_vm.title) + "\n      "),
          _vm._v(" "),
          _c(
            "a",
            {
              attrs: {
                href: _vm.href,
                rel: "noopener noreferrer nofollow",
                target: "_blank"
              }
            },
            [
              _c("span", { staticClass: "body-b3 mg-left--slab link-color" }, [
                _vm._v(_vm._s(_vm.linkMsg))
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "body-b3 subtitle-color" }, [
          _vm._v("\n      " + _vm._s(_vm.subtitle) + "\n    ")
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }