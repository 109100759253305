<template>
  <transition-group name="menu-list" tag="div" class="selected-contact-results">
    <div
      v-for="({ name, phone_number, type }, idx) in contacts"
      :key="`${type}_${idx}`"
      class="selected-contact gap--slab mg-bottom--slab"
      :class="`CREATE_TICKET_PHONE_NUMBER${idx}`"
    >
      <woot-input
        :label="idx === 0 ? 'Contact Number' : ''"
        auto-focus
        style="max-width: 232px"
        size="small"
        :value="phone_number"
        type="number"
        :required="idx === 0"
        placeholder="Phone number"
        :disabled="type === 'existing'"
        :class="{ error: $v.contacts.$each[idx].phone_number.$error }"
        :has-error="$v.contacts.$each[idx].phone_number.$error"
        :error="$t('CREATE_TICKET.FORM.ERRORS.NOT_VALID_PHONE_NUMBER_DETAILED')"
        @input="updateField('phone_number', $event, idx)"
      />
      <woot-input
        :label="idx === 0 ? 'Contact Name' : ''"
        :value="name"
        size="small"
        placeholder="Name"
        :disabled="type === 'existing'"
        @input="updateField('name', $event, idx)"
      />
      <woot-base-button
        tag="span"
        variant="tertiary-danger"
        class="remove-button"
        @click="removeContact(idx)"
      >
        <icons
          name="delete"
          size="semimedium"
          color="red"
          title="Remove contact"
        />
      </woot-base-button>
    </div>
  </transition-group>
</template>
<script>
import { required } from 'vuelidate/lib/validators';
import { checkValidPhoneNumber } from '../../utils/validations';

export default {
  props: {
    contacts: {
      type: Array,
      default: () => [],
    },
  },
  validations: {
    contacts: {
      $each: {
        phone_number: { required, checkValidPhoneNumber },
      },
    },
  },
  watch: { 'contacts.length': 'validateNewlyAddedContacts' },
  mounted() {
    this.validateContactAt(0);
  },
  methods: {
    validateNewlyAddedContacts(newLength, oldLength) {
      // Check if a contact was added (newLength > oldLength)
      if (newLength > oldLength)
        this.$nextTick(() => this.validateContactAt(newLength - 1));
    },
    validateContactAt(index) {
      this.$v.contacts.$each[index].$touch();
    },
    updateField(fieldName, newValue, index) {
      const updatedContacts = [...this.contacts];
      updatedContacts[index][fieldName] = newValue;
      this.$emit('updateContacts', updatedContacts);
    },
    removeContact(indexToRemove) {
      const updatedContacts = this.contacts.filter(
        (_, idx) => idx !== indexToRemove
      );
      this.$emit('updateContacts', updatedContacts);
    },
  },
};
</script>
