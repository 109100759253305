var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "filter", class: _vm.actionInputStyles },
    [
      _c(
        "div",
        {
          staticClass:
            "flex-row flex-row gap--normal flex-align--start flex-justify--between"
        },
        [
          _c(
            "div",
            { staticClass: "filter-inputs full-width" },
            [
              _c("woot-single-selector", {
                class: "AUTOMATION_ACTION_NAME_" + _vm.index,
                attrs: {
                  "default-option": _vm.selectedAction,
                  "option-list": _vm.actionTypes,
                  "custom-style": _vm.selectorCustomStyle,
                  "dropdown-container-style": _vm.attributeDropdownStyle
                },
                on: { click: _vm.handleActionTypeChange }
              }),
              _vm._v(" "),
              _vm.showActionInput
                ? _c(
                    "div",
                    {
                      staticClass: "filter__answer--wrap",
                      class: "AUTOMATION_ACTION_PARAMS_" + _vm.index
                    },
                    [
                      _vm.inputType
                        ? _c(
                            "div",
                            [
                              _vm.inputType === "search_select"
                                ? _c(
                                    "div",
                                    { staticClass: "multiselect-wrap--small" },
                                    [
                                      _c("multiselect", {
                                        attrs: {
                                          "track-by": "id",
                                          label: "name",
                                          placeholder: _vm.$t(
                                            "FORMS.MULTISELECT.SELECT"
                                          ),
                                          "selected-label": "",
                                          "select-label": _vm.$t(
                                            "FORMS.MULTISELECT.ENTER_TO_SELECT"
                                          ),
                                          "deselect-label": "",
                                          "max-height": 160,
                                          options: _vm.dropdownValues,
                                          "allow-empty": "",
                                          "option-height": 104
                                        },
                                        model: {
                                          value: _vm.actionParams,
                                          callback: function($$v) {
                                            _vm.actionParams = $$v
                                          },
                                          expression: "actionParams"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm.inputType === "multi_select"
                                ? _c(
                                    "div",
                                    { staticClass: "multiselect-wrap--small" },
                                    [
                                      _c("multiselect", {
                                        attrs: {
                                          "track-by": "id",
                                          label: "name",
                                          placeholder: _vm.$t(
                                            "FORMS.MULTISELECT.SELECT"
                                          ),
                                          multiple: true,
                                          "selected-label": "",
                                          "select-label": _vm.$t(
                                            "FORMS.MULTISELECT.ENTER_TO_SELECT"
                                          ),
                                          "deselect-label": "",
                                          "max-height": 160,
                                          options: _vm.dropdownValues,
                                          "allow-empty": "",
                                          "option-height": 104
                                        },
                                        model: {
                                          value: _vm.actionParams,
                                          callback: function($$v) {
                                            _vm.actionParams = $$v
                                          },
                                          expression: "actionParams"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm.inputType === "email"
                                ? _c("woot-input", {
                                    staticClass: "answer--text-input",
                                    attrs: {
                                      type: "email",
                                      placeholder: "Enter email"
                                    },
                                    model: {
                                      value: _vm.actionParams,
                                      callback: function($$v) {
                                        _vm.actionParams = $$v
                                      },
                                      expression: "actionParams"
                                    }
                                  })
                                : _vm.inputType === "url"
                                ? _c("woot-input", {
                                    staticClass: "answer--text-input",
                                    attrs: {
                                      type: "url",
                                      placeholder: "Enter URL"
                                    },
                                    model: {
                                      value: _vm.actionParams,
                                      callback: function($$v) {
                                        _vm.actionParams = $$v
                                      },
                                      expression: "actionParams"
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.inputType === "attachment"
                                ? _c("file-input", {
                                    attrs: {
                                      "initial-file-name": _vm.initialFileName
                                    },
                                    model: {
                                      value: _vm.actionParams,
                                      callback: function($$v) {
                                        _vm.actionParams = $$v
                                      },
                                      expression: "actionParams"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isMacro && !_vm.isFirstAction
                ? _c(
                    "woot-base-button",
                    {
                      attrs: { tag: "span", variant: "tertiary-danger" },
                      on: { click: _vm.removeAction }
                    },
                    [
                      _c("icons", {
                        attrs: {
                          name: "bin",
                          color: "red",
                          size: "semimedium",
                          "show-title": false
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm.inputType === "team_message"
        ? _c("team-message-input", {
            attrs: { teams: _vm.dropdownValues },
            model: {
              value: _vm.actionParams,
              callback: function($$v) {
                _vm.actionParams = $$v
              },
              expression: "actionParams"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.inputType === "textarea"
        ? _c("woot-input", {
            staticClass: "action-message",
            attrs: {
              rows: "4",
              placeholder: _vm.$t(
                "AUTOMATION.ACTION.TEAM_MESSAGE_INPUT_PLACEHOLDER"
              )
            },
            model: {
              value: _vm.actionParams,
              callback: function($$v) {
                _vm.actionParams = $$v
              },
              expression: "actionParams"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.inputType === "text_with_variables"
        ? _c("text-editor", {
            staticClass: "action-message",
            attrs: {
              "enable-variables": "",
              "is-menu-bar-required": "",
              "is-format-mode": "",
              "min-height": 15,
              placeholder:
                "Type '{' to select a variable to add in your message template"
            },
            model: {
              value: _vm.actionParams,
              callback: function($$v) {
                _vm.actionParams = $$v
              },
              expression: "actionParams"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }