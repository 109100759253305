var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-header",
    {
      attrs: {
        "header-title": _vm.title,
        size: "small",
        "header-content": _vm.errorMessage,
        "custom-class": "grid-1-2-ratio",
        "info-text": _vm.description
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "flex-row flex-align flex-justify--between gap--larger"
        },
        _vm._l(Object.keys(_vm.timeDelay), function(unit) {
          return _c("woot-input", {
            key: unit,
            staticClass: "small-3",
            class: { error: _vm.$v.timeDelay[unit].$error },
            attrs: {
              "has-error": _vm.$v.timeDelay[unit].$error,
              error: "Please add a positive number",
              label: _vm.$t("INBOX_MGMT.COMMON_PROPERTIES." + unit),
              type: "number",
              "show-spin-buttons": ""
            },
            on: {
              blur: function($event) {
                return _vm.$v.timeDelay[unit].$touch()
              },
              input: _vm.updateConfig
            },
            model: {
              value: _vm.timeDelay[unit],
              callback: function($$v) {
                _vm.$set(_vm.timeDelay, unit, _vm._n($$v))
              },
              expression: "timeDelay[unit]"
            }
          })
        }),
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }