var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chat-list-top-deprecated" },
    [
      _c(
        "div",
        { staticClass: "chat-list__top" },
        [
          _c(
            "h1",
            { staticClass: "page-title text-truncate title-h1 text-dark" },
            [
              _vm._m(0),
              _vm._v(
                "\n      " + _vm._s(_vm.$t("CHAT_LIST.TAB_HEADING")) + "\n    "
              )
            ]
          ),
          _vm._v(" "),
          _c("conversation-status", {
            staticClass: "conversation-status-filter",
            attrs: { "active-status": _vm.activeStatus, variant: "tertiary" },
            on: {
              statusFilterChange: function($event) {
                return _vm.$emit("statusFilterChange", $event)
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("search-filter", { attrs: { "assignee-tab": _vm.activeAssigneeTab } }),
      _vm._v(" "),
      _c("conversation-filter-tab", {
        key: _vm.rerenderConversationFilters,
        staticClass: "conversation-filters-container",
        attrs: {
          "filter-list": _vm.$t("FILTERS.TICKET_SECTION_FILTER_LIST"),
          "active-tab": _vm.activeAssigneeTab,
          "applied-filters": _vm.appliedConversationFilters,
          "parent-component-name": "Tickets"
        },
        on: { filterChange: _vm.onUpdateFilter }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "mg-right--smaller" }, [
      _c("img", { attrs: { src: require("assets/images/ticket.svg") } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }