var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("woot-switch", {
    attrs: { id: _vm.data.name, value: _vm.data.active },
    on: { click: _vm.toggleSLA }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }