var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "column padding-container settings-box custom-scroll",
      staticStyle: { "padding-top": "0px" }
    },
    [
      _c(
        "woot-modal",
        {
          attrs: { show: _vm.downloading, "on-close": _vm.hidePopup },
          on: {
            "update:show": function($event) {
              _vm.downloading = $event
            }
          }
        },
        [
          _c("div", { staticClass: "download-note" }, [
            _c("img", {
              attrs: {
                src:
                  "https://s3.ap-south-1.amazonaws.com/cdn.limechat.ai/assets/images/limechat_report_sent.svg"
              }
            }),
            _vm._v(" "),
            _c("div", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.$t("ANALYTICS.DOWNLOAD_REPORT.EMAIL", {
                    email: _vm.userEmail
                  })
                )
              }
            })
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: { show: _vm.downloadError, "on-close": _vm.hidePopup },
          on: {
            "update:show": function($event) {
              _vm.downloadError = $event
            }
          }
        },
        [
          _c("div", { staticClass: "download-note" }, [
            _c("div", [
              _vm._v(_vm._s(_vm.$t("ANALYTICS.DOWNLOAD_REPORT.REPORT_FAIL")))
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "filter-pane" },
        [
          _c("date-range-selector", {
            attrs: { "default-selection": 2 },
            on: { "date-range-change": _vm.onDateRangeChange }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.isMetricsLoading
        ? _c(
            "div",
            { staticClass: "metrics-card-container" },
            _vm._l(_vm.metricsCount, function(item, index) {
              return _c("metrics-card-skeleton", { key: index })
            }),
            1
          )
        : _c(
            "div",
            { staticClass: "metrics-card-container" },
            _vm._l(_vm.metricsCount, function(item, index) {
              return _c("metrics-card", {
                key: index,
                attrs: {
                  title: item.title,
                  "sub-title": item.sub,
                  unit: item.unit,
                  color: item.color
                }
              })
            }),
            1
          ),
      _vm._v(" "),
      _vm.isChartLoading
        ? _c("div", { staticClass: "flex-space-between" }, [
            _c(
              "div",
              { staticStyle: { width: "63%" } },
              [
                _c("line-chart-skeleton", {
                  attrs: { "chart-height": "17rem" }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("pie-chart-skeleton", {
                  attrs: { "chart-height": "23.5rem" }
                })
              ],
              1
            )
          ])
        : _c("div", { staticClass: "flex-space-between" }, [
            _c(
              "div",
              {
                staticClass: "bottom-container",
                staticStyle: { width: "64%" }
              },
              [
                _c("div", { staticClass: "lime-card-1dp" }, [
                  _c(
                    "div",
                    { staticStyle: { position: "relative" } },
                    [
                      _c("span", { staticClass: "table-title" }, [
                        _vm._v("Periodic sales data")
                      ]),
                      _vm._v(" "),
                      _c("line-chart", {
                        attrs: {
                          series: _vm.chartSeries,
                          categories: _vm.chartCategories,
                          colors: _vm.chartColors,
                          "toolbar-enable": false
                        }
                      })
                    ],
                    1
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "bottom-container" }, [
              _c(
                "div",
                { staticClass: "lime-card-1dp" },
                [
                  _c("pie-chart", {
                    attrs: {
                      title: "New Users Vs Existing Users",
                      series: _vm.pieSeries,
                      colors: _vm.pieColors,
                      labels: _vm.pieLabels,
                      height: 292,
                      "legend-position": "bottom",
                      "legend-offset-x": 0,
                      "legend-offset-y": -10,
                      "marker-offset-x": 0,
                      "marker-offset-y": 0,
                      "chart-offset-x": 0,
                      "chart-offset-y": 0
                    }
                  })
                ],
                1
              )
            ])
          ]),
      _vm._v(" "),
      _vm.tableUiFlag
        ? _c("div", [_c("table-skeleton")], 1)
        : _c(
            "div",
            { staticClass: "medium-12 bottom-container" },
            [
              _c("woot-table", {
                staticClass: "lime-card-1dp",
                attrs: {
                  title: "Product wise Sales data",
                  "header-list": _vm.$t("ANALYTICS.SALES_ANALYTICS_HEADER"),
                  "table-data": _vm.formattedTableData,
                  "is-sortable": true,
                  "is-paginated": true,
                  "current-page": _vm.currentPage,
                  "total-count": _vm.tableTotalCount,
                  "on-page-change": _vm.onPageChange,
                  "is-downloadable": true,
                  "is-downloading": false,
                  "download-text": "Download",
                  "text-width": "30rem"
                },
                on: { "download-csv": _vm.downloadSalesTable }
              })
            ],
            1
          ),
      _vm._v(" "),
      _vm.uiFlag
        ? _c("div", { staticClass: "flex-space-between" }, [
            _c(
              "div",
              {
                staticClass: "bottom-container",
                staticStyle: { width: "49%" }
              },
              [_c("line-chart-skeleton")],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "bottom-container",
                staticStyle: { width: "49%" }
              },
              [_c("line-chart-skeleton")],
              1
            )
          ])
        : _c("div", { staticClass: "flex-space-between" }, [
            _c(
              "div",
              {
                staticClass: "bottom-container",
                staticStyle: { width: "49%" }
              },
              [
                _c(
                  "div",
                  { staticClass: "lime-card-1dp medium-12" },
                  [
                    _c("funnel-chart", {
                      attrs: {
                        id: "chartdiv1",
                        subtitle: _vm.productTransactionValue,
                        data: _vm.funnelChartDataOne,
                        "chart-colors": _vm.funnelOneColorSet,
                        title: "Product Quiz conversion"
                      }
                    })
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "bottom-container",
                staticStyle: { width: "49%" }
              },
              [
                _c(
                  "div",
                  { staticClass: "lime-card-1dp medium-12" },
                  [
                    _c("funnel-chart", {
                      attrs: {
                        id: "chartdiv2",
                        subtitle: _vm.pdpTransactionValue,
                        data: _vm.funnelChartDataTwo,
                        "chart-colors": _vm.funnelTwoColorSet,
                        title: "PDP Entry Conversion"
                      }
                    })
                  ],
                  1
                )
              ]
            )
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }