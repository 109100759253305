var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: ["offline-assignment-selector", { disabled: !_vm.inboxes.length }]
    },
    [
      _c(
        "div",
        { staticClass: "flex-row flex-align gap--slab" },
        [
          _c("label", { staticClass: "container-checkbox" }, [
            _c("div", { staticClass: "body-b2 label-text" }, [
              _c("span", {
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("TICKET_ASSIGNMENT.OFFLINE_ASSIGNMENT.TITLE")
                  )
                }
              })
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.isSelected,
                  expression: "isSelected"
                }
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.isSelected)
                  ? _vm._i(_vm.isSelected, null) > -1
                  : _vm.isSelected
              },
              on: {
                input: _vm.onInputSelected,
                change: function($event) {
                  var $$a = _vm.isSelected,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.isSelected = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.isSelected = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.isSelected = $$c
                  }
                }
              }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "checkmark" })
          ]),
          _vm._v(" "),
          _c("woot-single-selector", {
            staticClass: "mg-bottom--small",
            class: { disabled: !_vm.isSelected },
            attrs: {
              variant: "secondary",
              "default-option": "Select Inboxes",
              "option-list": _vm.inboxList,
              "show-selected-text": false,
              "custom-style": {
                left: 0,
                maxHeight: "20rem",
                width: "20rem",
                borderRadius: "0.4rem",
                bottom: "4.4rem"
              },
              "top-position": { top: undefined }
            },
            on: { click: _vm.onSelectInbox }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.selectedInboxes, function(inbox) {
        return _c("woot-chips", {
          key: inbox.id,
          attrs: {
            title: inbox.name,
            "show-tooltip": false,
            "front-icon": _vm.getInboxMeta(inbox).name,
            variant: "primary-small",
            "show-cancel-icon": ""
          },
          on: {
            click: function($event) {
              return _vm.onRemoveInbox(inbox)
            }
          }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }