var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-attributes--panel" },
    _vm._l(_vm.listOfAttributes, function(attribute) {
      return _c(
        "div",
        { key: attribute },
        [
          _c("woot-chips", {
            attrs: {
              title: attribute + ": " + _vm.customAttributes[attribute],
              variant: "primary-small",
              "show-cancel-icon": false
            }
          })
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }