var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "contact-conversation--panel" }, [
    !_vm.uiFlags.isFetching
      ? _c("div", [
          !_vm.previousConversations.length
            ? _c("span", { staticClass: "body-b2 text-dark" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$t("CONTACT_PANEL.CONVERSATIONS.NO_RECORDS_FOUND")
                    ) +
                    "\n    "
                )
              ])
            : _c(
                "div",
                { staticClass: "contact-conversation--list" },
                [
                  _c("conversation-card", {
                    key: _vm.previousConversations[0].id,
                    staticClass: "compact",
                    attrs: {
                      "show-select-option": false,
                      "active-tab": "me",
                      "active-status": "open",
                      chat: _vm.previousConversations[0],
                      "hide-inbox-name": false,
                      "hide-thumbnail": true,
                      "custom-style": {
                        border: "0.5px solid #D9D9D9",
                        "border-radius": "8px",
                        "margin-bottom": "8px"
                      },
                      "custom-card-style": {
                        padding: "8px"
                      }
                    },
                    on: { cardClick: _vm.handleCardClickEvent }
                  }),
                  _vm._v(" "),
                  !_vm.showAllConversations &&
                  _vm.previousConversations.length > 1
                    ? _c(
                        "span",
                        {
                          staticClass: "see-more-conv body-b3",
                          on: { click: _vm.showMoreConversations }
                        },
                        [
                          _vm._v(
                            "\n        See More (" +
                              _vm._s(_vm.previousConversations.length - 1) +
                              ")\n      "
                          )
                        ]
                      )
                    : _c(
                        "div",
                        [
                          _vm._l(_vm.previousConversations.slice(1), function(
                            conversation
                          ) {
                            return _c("conversation-card", {
                              key: conversation.id,
                              staticClass: "compact",
                              attrs: {
                                "show-select-option": false,
                                "active-tab": "me",
                                "active-status": "open",
                                chat: conversation,
                                "hide-inbox-name": false,
                                "hide-thumbnail": true,
                                "custom-style": {
                                  border: "0.5px solid #D9D9D9",
                                  "border-radius": "8px",
                                  "margin-bottom": "8px"
                                },
                                "custom-card-style": {
                                  padding: "8px"
                                }
                              },
                              on: { cardClick: _vm.handleCardClickEvent }
                            })
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "see-more-conv body-b3",
                              on: {
                                click: function($event) {
                                  _vm.showAllConversations = false
                                }
                              }
                            },
                            [_vm._v("\n          See Less\n        ")]
                          )
                        ],
                        2
                      )
                ],
                1
              )
        ])
      : _c("div", { staticClass: "mg-top--small" }, [_c("spinner")], 1)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }