var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "settings-form custom-scroll" },
      [
        _c("div", {
          staticClass: "settings-title title-h3 text-dark",
          domProps: { textContent: _vm._s("Details") }
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex-row flex-align gap--small content-margin" },
          [
            _c("woot-input", {
              class: { error: _vm.$v.title.$error },
              attrs: {
                required: "",
                "auto-focus": "",
                label: _vm.$t("LABEL_MGMT.FORM.NAME.LABEL"),
                "has-error": _vm.$v.title.$error,
                error: _vm.$t("LABEL_MGMT.FORM.NAME.ERROR"),
                placeholder: _vm.$t("LABEL_MGMT.FORM.NAME.PLACEHOLDER")
              },
              on: { input: _vm.onChange },
              model: {
                value: _vm.title,
                callback: function($$v) {
                  _vm.title = typeof $$v === "string" ? $$v.trim() : $$v
                },
                expression: "title"
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("div", {
                  staticClass: "title-h5 text-dark mg-bottom--small",
                  domProps: {
                    textContent: _vm._s(_vm.$t("LABEL_MGMT.FORM.TYPE.LABEL"))
                  }
                }),
                _vm._v(" "),
                _c("woot-single-selector", {
                  attrs: {
                    "default-option": _vm.selectedType,
                    "option-list": _vm.labelTypeOptions,
                    "custom-style": { left: 0 }
                  },
                  on: { click: _vm.onSelectType }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("woot-input", {
          staticClass: "medium-12 columns",
          attrs: {
            rows: "4",
            type: "text",
            label: _vm.$t("LABEL_MGMT.FORM.DESCRIPTION.LABEL"),
            placeholder: _vm.$t("LABEL_MGMT.FORM.DESCRIPTION.PLACEHOLDER")
          },
          on: { input: _vm.onChange },
          model: {
            value: _vm.description,
            callback: function($$v) {
              _vm.description = typeof $$v === "string" ? $$v.trim() : $$v
            },
            expression: "description"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }