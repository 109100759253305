var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "pii-wrapper" },
    [
      _vm.isLoading
        ? [
            _vm.isInputMask
              ? _vm._t("default", null, {
                  data: _vm.displayData,
                  isMasked: _vm.isMasked,
                  updateData: _vm.updateData
                })
              : _c("lottie", {
                  attrs: {
                    options: _vm.defaultAnimOptions,
                    height: 30,
                    width: 80
                  },
                  on: { animCreated: _vm.handleAnimation }
                })
          ]
        : [
            _vm._t("default", null, {
              data: _vm.displayData,
              isMasked: _vm.isMasked,
              updateData: _vm.updateData
            })
          ],
      _vm._v(" "),
      _vm.showToggleIcon && _vm.isMasked
        ? _c(
            "span",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip.top",
                  value: _vm.tooltipMsg,
                  expression: "tooltipMsg",
                  modifiers: { top: true }
                }
              ],
              staticClass: "toggle-icon",
              class: { disabled: _vm.isLoading },
              on: { click: _vm.toggleMask }
            },
            [
              _c("icons", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip.top",
                    value: _vm.tooltipMsg,
                    expression: "tooltipMsg",
                    modifiers: { top: true }
                  }
                ],
                attrs: {
                  name: "lock",
                  color: "stroke-darkest-grey",
                  size: _vm.toggleIconSize
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }