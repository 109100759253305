var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "search gap--one",
      class: [
        { "search--white": _vm.variant },
        { "no-spin-buttons": _vm.inputType === "number" },
        _vm.customClass
      ],
      style: _vm.searchHeight
    },
    [
      _vm._t("default"),
      _vm._v(" "),
      _c("input", {
        ref: "searchInput",
        staticClass: "search-input text-dark",
        class: _vm.size === "medium" ? "body-b3" : "body-b2",
        attrs: { type: _vm.inputType, placeholder: _vm.placeholder },
        domProps: { value: _vm.value },
        on: {
          keydown: _vm.disableArrowKey,
          keyup: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.onEnterHandler($event)
          },
          input: _vm.handleInput
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "button-right", on: { click: _vm.triggerSearch } },
        [
          _c("icons", {
            attrs: {
              name: "search",
              color: _vm.value.length ? "green" : "lightgrey",
              size: _vm.iconSize
            }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }