var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "file-wrapper",
          class: { hoverable: !_vm.canDelete },
          on: { click: _vm.onOpenImg }
        },
        [
          _c(
            "div",
            {
              staticClass: "file flex-row flex-align",
              style: _vm.canDelete && "cursor:default",
              attrs: {
                title: _vm.attachmentName || _vm.fileName(_vm.attachmentUrl)
              },
              on: { click: _vm.openLink }
            },
            [
              _c(
                "div",
                { staticClass: "icon-wrap" },
                [
                  _c("icons", {
                    attrs: {
                      name: _vm.iconName,
                      size: "medium",
                      color: "darkestgrey"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", [
                _c(
                  "span",
                  { staticClass: "text-block-title title-h6_m text-dark" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.truncateFilename(
                            _vm.attachmentName ||
                              _vm.fileName(_vm.attachmentUrl),
                            10
                          )
                        ) +
                        "\n        "
                    )
                  ]
                )
              ])
            ]
          ),
          _vm._v(" "),
          _vm.canDelete
            ? _c(
                "span",
                {
                  staticClass: "cancel--preview",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.$emit("clear", _vm.id)
                    }
                  }
                },
                [
                  _c("icons", {
                    attrs: {
                      name: "cancel",
                      color: "stroke-grey",
                      size: "semimedium",
                      "custom-style": {
                        strokeWidth: "2"
                      },
                      title: "remove"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.attachmentSize !== 0
            ? _c("span", { staticClass: "file-size-text body-b3 text-light" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.formatFileSize(_vm.attachmentSize)) +
                    "\n    "
                )
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: {
            "full-width": true,
            show: _vm.showModal,
            "on-close": _vm.onClose
          },
          on: {
            "update:show": function($event) {
              _vm.showModal = $event
            }
          }
        },
        [
          _c("img", {
            staticStyle: { "max-height": "100%" },
            attrs: { src: _vm.attachmentUrl }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }