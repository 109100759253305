var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.rows, function(index) {
      return _c(
        "div",
        { key: index, staticClass: "flex-row-justify-center margin-container" },
        _vm._l(index + _vm.rows, function(idx) {
          return _c(
            "div",
            { key: idx, staticClass: "header-skeleton-margin" },
            [_c("div", { staticClass: "skeleton-animation header-skeleton" })]
          )
        }),
        0
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }