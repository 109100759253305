var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "summary-head flex-row flex-align flex-justify--between" },
    [
      _c(
        "div",
        { staticClass: "flex-row gap--small" },
        [
          _c("thumbnail", { attrs: { src: _vm.avatarSource, size: "2rem" } }),
          _vm._v(" "),
          _c("div", { staticClass: "summary-bubble-heading" }, [
            _c("b", { staticClass: "text-red new-badge" }, [_vm._v("New")]),
            _vm._v(" "),
            _c("span", {
              staticClass: "title-h5 text-blue",
              domProps: {
                textContent: _vm._s(_vm.$t("CONVERSATION.GPT.HEADING"))
              }
            })
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("span", {
        staticClass: "title-h6_m text-light",
        domProps: {
          textContent: _vm._s(_vm.$t("CONVERSATION.GPT.SUB_HEADING"))
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }