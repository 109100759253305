var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "draggable-list" },
    [
      _c(
        "table-zero-state",
        {
          attrs: {
            title: _vm.$t("SLA.LIST.EMPTY.TITLE"),
            subtitle: _vm.$t("SLA.LIST.EMPTY.DESC"),
            "asset-link": "/brand-assets/inbox_zero_state.svg"
          }
        },
        [
          _c(
            "woot-base-button",
            {
              attrs: {
                size: "medium",
                "front-icon": "plus",
                "icon-view-box": "0 0 20 20"
              },
              on: {
                click: function($event) {
                  return _vm.$emit("onCreate")
                }
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("SLA.CREATE.TITLE")) + "\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }