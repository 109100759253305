var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box settings-box no-padding" },
    [
      _c(
        "div",
        { staticClass: "settings-button flex-row flex-align gap--normal" },
        [
          _c("reference-box", {
            attrs: {
              to: _vm.documentation.tags,
              content: "Know More",
              type: "document"
            }
          }),
          _vm._v(" "),
          !_vm.modalMode
            ? _c(
                "woot-base-button",
                {
                  attrs: {
                    size: "medium",
                    "front-icon": "plus",
                    "icon-view-box": "0 0 20 20"
                  },
                  on: {
                    click: function($event) {
                      return _vm.openAddPopup(null)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("LABEL_MGMT.HEADER_BTN_TXT")) +
                      "\n    "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.modalMode ? null : "settings-container" },
        [
          _c(
            "div",
            {
              class: _vm.modalMode ? "column" : "column settings-main-menu-new"
            },
            [
              !_vm.selectedTagsToPerformAction.length
                ? _c("search-box", {
                    staticClass: "search-box",
                    attrs: {
                      placeholder: _vm.$t("LABEL_MGMT.SEARCH_TXT"),
                      value: _vm.search
                    },
                    on: { setSearch: _vm.setSearch }
                  })
                : !_vm.modalMode
                ? _c(
                    "div",
                    {
                      staticClass:
                        "bulk-actions-container flex-row flex-align gap--normal"
                    },
                    [
                      _c(
                        "woot-base-button",
                        {
                          attrs: { size: "small", variant: "tertiary" },
                          on: { click: _vm.handleSelectAll }
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "container-checkbox",
                              staticStyle: { cursor: "pointer" }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectAllChecked,
                                    expression: "selectAllChecked"
                                  }
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: _vm.selectAllChecked,
                                  checked: Array.isArray(_vm.selectAllChecked)
                                    ? _vm._i(_vm.selectAllChecked, null) > -1
                                    : _vm.selectAllChecked
                                },
                                on: {
                                  click: _vm.handleSelectAll,
                                  change: function($event) {
                                    var $$a = _vm.selectAllChecked,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.selectAllChecked = $$a.concat([
                                            $$v
                                          ]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.selectAllChecked = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.selectAllChecked = $$c
                                    }
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("span", {
                                staticClass: "grey-checkmark checkmark"
                              })
                            ]
                          ),
                          _vm._v("\n          Select all\n        ")
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "woot-base-button",
                        {
                          attrs: {
                            size: "small",
                            variant: "secondary-danger",
                            "front-icon": "delete"
                          },
                          on: { click: _vm.bulkDeleteLabel }
                        },
                        [_vm._v("\n          Delete\n        ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "woot-base-button",
                        {
                          attrs: { size: "small", variant: "secondary" },
                          on: {
                            click: function($event) {
                              return _vm.bulkUpdateStatus(true)
                            }
                          }
                        },
                        [_vm._v("\n          Activate\n        ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "woot-base-button",
                        {
                          attrs: { size: "small", variant: "secondary-danger" },
                          on: {
                            click: function($event) {
                              return _vm.bulkUpdateStatus(false)
                            }
                          }
                        },
                        [_vm._v("\n          Deactivate\n        ")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.modalMode
                ? _c(
                    "woot-tabs",
                    {
                      attrs: { index: _vm.selectedTabIndex },
                      on: {
                        change: function(index, ref) {
                          var key = ref.key

                          return _vm.onApplyTypeFilter(index, key)
                        }
                      }
                    },
                    _vm._l(_vm.tabs, function(tab) {
                      return _c("woot-tabs-item", {
                        key: tab.key,
                        attrs: {
                          name: tab.name,
                          data: tab,
                          disabled: _vm.uiFlags.isFetching
                        }
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "header" }, [
                _c("span", {
                  staticClass: "title-h4 text-dark",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("LABEL_MGMT.LIST.TABLE_HEADER.TAGS")
                    )
                  }
                })
              ]),
              _vm._v(" "),
              !_vm.uiFlags.isFetching && _vm.records.length
                ? _c(
                    "div",
                    {
                      staticClass:
                        "nested-label-container custom-scroll bg-light-grey"
                    },
                    [
                      _c("nested-labels", {
                        attrs: {
                          "selected-tags": _vm.selectedTags,
                          "on-open-add-popup": _vm.openAddPopup,
                          "on-open-delete-popup": _vm.openDeletePopup,
                          "modal-mode": _vm.modalMode,
                          search: _vm.search,
                          resource: _vm.selectedResourceType,
                          "selected-tag": _vm.selectedTag,
                          "selected-tags-to-perform-action":
                            _vm.selectedTagsToPerformAction,
                          "on-label-select": _vm.onLabelSelect,
                          "on-tag-select": _vm.onTagSelect,
                          "on-view-tag": _vm.onViewTag
                        },
                        model: {
                          value: _vm.labels,
                          callback: function($$v) {
                            _vm.labels = $$v
                          },
                          expression: "labels"
                        }
                      }),
                      _vm._v(" "),
                      !_vm.uiFlags.isFetching &&
                      !_vm.labels.length &&
                      _vm.search
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex-row flex-justify flex-align zero-state"
                            },
                            [
                              _c("span", {
                                staticClass: "title-h5 text-light",
                                domProps: { textContent: _vm._s("No Data") }
                              })
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.uiFlags.isFetching
                ? _c(
                    "div",
                    { staticClass: "flex-column gap--small bg-light-grey" },
                    _vm._l(7, function(idx) {
                      return _c("box-skeleton", { key: idx })
                    }),
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.records.length
                ? _c(
                    "table-zero-state",
                    {
                      staticClass: "table-zero-state",
                      attrs: {
                        title: "No Tags added till now",
                        subtitle:
                          "Click on the Add Tag Button to add a new tag",
                        "asset-link": "/brand-assets/inbox_zero_state.svg"
                      }
                    },
                    [
                      _c(
                        "woot-base-button",
                        {
                          staticStyle: { "margin-bottom": "5vh" },
                          attrs: {
                            size: "medium",
                            "front-icon": "plus",
                            "icon-view-box": "0 0 20 20"
                          },
                          on: {
                            click: function($event) {
                              return _vm.openAddPopup(null)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("LABEL_MGMT.HEADER_BTN_TXT")) +
                              "\n        "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          !_vm.modalMode
            ? _c(
                "settings-side-card",
                [
                  _vm.selectedTag.id
                    ? _c("rhs-panel", {
                        key:
                          _vm.selectedTag.id + _vm.selectedTag.parent_id || 1,
                        attrs: {
                          "selected-tag": _vm.selectedTag,
                          resource: _vm.selectedResourceType
                        },
                        on: { updateLabel: _vm.handleUpdateLabel }
                      })
                    : _c("educational-onboarding", {
                        staticClass: "full-height",
                        attrs: { "show-case-data": _vm.SHOWCASE_TAGS_DATA }
                      }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "action-btns full-width flex-row flex-justify gap--one"
                    },
                    [
                      _vm.records.length
                        ? _c(
                            "woot-base-button",
                            {
                              attrs: { variant: "secondary", "full-width": "" },
                              on: {
                                click: function($event) {
                                  _vm.showExportTagModal = true
                                }
                              }
                            },
                            [_vm._v("\n          Export\n        ")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "woot-base-button",
                        {
                          attrs: { variant: "secondary", "full-width": "" },
                          on: {
                            click: function($event) {
                              _vm.showImportTagModal = true
                            }
                          }
                        },
                        [_vm._v("\n          Import\n        ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.showImportTagModal
        ? _c("import-tag", {
            on: {
              onClose: function($event) {
                _vm.showImportTagModal = false
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showExportTagModal
        ? _c("export-tag", {
            on: {
              onClose: function($event) {
                _vm.showExportTagModal = false
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showAddPopup
        ? _c(
            "woot-modal-new",
            {
              attrs: {
                show: true,
                "on-close": _vm.hideAddPopup,
                "disable-btn": _vm.areLabelDetailsNotValid
              },
              on: { submit: _vm.addLabel },
              scopedSlots: _vm._u(
                [
                  {
                    key: "paginated-components",
                    fn: function() {
                      return [
                        _c("label-details", {
                          key: "labelDetails",
                          staticClass: "label-settings-container",
                          attrs: { resource: _vm.selectedResourceType },
                          on: {
                            labelDetails: _vm.onChangeLabelDetails,
                            formValidity: function($event) {
                              return (_vm.areLabelDetailsNotValid = Object.assign(
                                {},
                                _vm.areLabelDetailsNotValid,
                                { 0: $event }
                              ))
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("label-heirarchy", {
                          key: _vm.selectedResourceType,
                          staticClass: "heirarchy-settings-container",
                          attrs: {
                            "parent-id": _vm.selectedParentId,
                            resource: _vm.selectedResourceType
                          },
                          on: {
                            labelHeirarchy: function($event) {
                              _vm.labelHeirarchySetting = $event
                            }
                          }
                        })
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                3049797382
              )
            },
            [
              _c("woot-modal-header-new", {
                attrs: { "header-title": _vm.$t("LABEL_MGMT.ADD.TITLE") }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("woot-delete-modal-new", {
        attrs: {
          show: _vm.showDeleteConfirmationPopup,
          "on-close": _vm.closeDeletePopup,
          "on-confirm": _vm.confirmDeletion,
          "show-close": "",
          title: _vm.$t("LABEL_MGMT.DELETE.CONFIRM.TITLE"),
          message: _vm.deleteMessage,
          "confirm-text": "Continue",
          "reject-text": "Back",
          "custom-style": {
            "max-width": "35.2rem",
            height: "auto"
          }
        },
        on: {
          "update:show": function($event) {
            _vm.showDeleteConfirmationPopup = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }