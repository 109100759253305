var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "sideCard",
      staticClass:
        "column settings-side-card flex-column flex-align flex-justify--between"
    },
    [
      _c(
        "div",
        {
          ref: "sideContent",
          staticClass: "side-card-content flex-column custom-scroll"
        },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _vm.image && _vm.showImage
        ? _c("div", { staticClass: "side-card-image flex-row flex-justify" }, [
            _c("img", {
              attrs: {
                alt: "side card image",
                src: require("dashboard/assets/images/settings/" +
                  _vm.image +
                  ".svg")
              }
            })
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }