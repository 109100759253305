var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "secondary-button flex-space-between",
      class: [_vm.size, _vm.customClass, "button-" + _vm.size],
      style: [_vm.customStyle, _vm.fullWidthClass],
      attrs: { disabled: _vm.disabled, type: _vm.type, name: _vm.name },
      on: {
        mouseover: _vm.hoverIcon,
        mouseleave: _vm.leaveIcon,
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [
      _vm.frontIcon
        ? _c(
            "span",
            { style: _vm.name && { "margin-right": "0.8rem" } },
            [
              _c("icons", {
                attrs: {
                  name: _vm.frontIcon,
                  color: _vm.iconStyle,
                  size: _vm.icon,
                  "layer-fill": _vm.layerFill
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("span", { staticClass: "btn-name" }, [_vm._v(_vm._s(_vm.name))]),
      _vm._v(" "),
      _vm.backIcon
        ? _c(
            "span",
            { style: _vm.name && { "margin-left": "0.8rem" } },
            [
              _c("icons", {
                attrs: {
                  name: _vm.backIcon,
                  color: _vm.iconStyle,
                  size: _vm.icon,
                  "layer-fill": _vm.layerFill
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loading
        ? _c("spinner", {
            staticClass: "spinner-margin",
            attrs: { color: _vm.spinnerColor, size: _vm.spinnerSize }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }