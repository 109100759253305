var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "account-switcher",
          on: { click: _vm.handleCompanyModal }
        },
        [
          _c(
            "span",
            {
              staticClass: "text-truncate title-h4 mg-right--small text-green"
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.currentAccount ? _vm.currentAccount.name : "N/A") +
                  "\n    "
              )
            ]
          ),
          _vm._v(" "),
          _vm.avatarUrl
            ? _c("div", { staticClass: "company-logo-img" }, [
                _c("img", { attrs: { src: _vm.avatarUrl, alt: "logo" } })
              ])
            : _c(
                "div",
                [
                  _c("avatar", {
                    attrs: {
                      username: _vm.currentAccount && _vm.currentAccount.name,
                      rounded: false,
                      colored: true,
                      size: 30,
                      "custom-style": { cursor: "pointer", fontSize: "1.8rem" }
                    }
                  })
                ],
                1
              )
        ]
      ),
      _vm._v(" "),
      _vm.showCompanyModal
        ? _c("client-logo-modal", {
            directives: [
              {
                name: "on-clickaway",
                rawName: "v-on-clickaway",
                value: _vm.hideCompanyModal,
                expression: "hideCompanyModal"
              }
            ],
            attrs: {
              "current-account": _vm.currentAccount,
              "current-user": _vm.currentUser,
              "avatar-url": _vm.avatarUrl
            },
            on: { getLogoImg: _vm.getLogo }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }