var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "zero-state-container" }, [
    _c(
      "div",
      [
        _c("h2", {
          staticClass: "title-h2 text-light mg-bottom--medium",
          domProps: {
            textContent: _vm._s(_vm.$t("TICKET_ASSIGNMENT.NO_INBOXES.TITLE"))
          }
        }),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: {
              href: _vm.ADD_INBOX_DEMO_VIDEO_URL,
              target: "_blank",
              rel: "noopener noreferrer"
            }
          },
          [
            _c(
              "video",
              {
                staticClass: "video-container",
                attrs: {
                  preload: "",
                  autoplay: "",
                  muted: "",
                  loop: "",
                  height: "360",
                  onloadstart: "playbackRate = 3"
                },
                domProps: { muted: true }
              },
              [
                _c("source", {
                  attrs: {
                    src: _vm.ADD_INBOX_DEMO_VIDEO_URL,
                    type: "video/mp4"
                  }
                })
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          { attrs: { to: _vm.newInboxURL() } },
          [
            _c("woot-base-button", { staticClass: "mg-auto mg-top--medium" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("CONVERSATION.ADD_INBOX")) +
                  "\n      "
              )
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }