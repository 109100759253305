var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "flex-row flex-align flex-justify--between top--container"
      },
      [
        _c("div", { staticClass: "flex-row flex-align info" }, [
          _c(
            "span",
            { staticStyle: { cursor: "pointer" }, attrs: { title: "Go back" } },
            [
              _c("icons", {
                attrs: {
                  name: "chevronLeft",
                  color: "green",
                  size: "medium",
                  "show-title": false
                },
                on: {
                  click: function($event) {
                    return _vm.$emit("closeCreateAddress")
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("span", { staticClass: "button-small" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.isShipping ? "Shipping" : "Billing") +
                " Address\n      "
            )
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-container" },
      [
        _c(
          "div",
          {
            staticClass: "flex-row flex-justify--between content-margin",
            staticStyle: { gap: "1.6rem" }
          },
          [
            _c("woot-input", {
              class: { error: _vm.$v.firstName.$error },
              staticStyle: { width: "45%" },
              attrs: {
                "has-error": _vm.$v.firstName.$error,
                error: _vm.$t("ORDER.ADDRESS.NAME.ERROR"),
                label: _vm.$t("ORDER.ADDRESS.NAME.FIRST_NAME.LABEL"),
                placeholder: _vm.$t("ORDER.ADDRESS.NAME.FIRST_NAME.PLACEHOLDER")
              },
              on: { blur: _vm.$v.firstName.$touch },
              model: {
                value: _vm.firstName,
                callback: function($$v) {
                  _vm.firstName = typeof $$v === "string" ? $$v.trim() : $$v
                },
                expression: "firstName"
              }
            }),
            _vm._v(" "),
            _c("woot-input", {
              class: { error: _vm.$v.lastName.$error },
              staticStyle: { width: "45%" },
              attrs: {
                "has-error": _vm.$v.lastName.$error,
                error: _vm.$t("ORDER.ADDRESS.NAME.ERROR"),
                label: _vm.$t("ORDER.ADDRESS.NAME.LAST_NAME.LABEL"),
                placeholder: _vm.$t("ORDER.ADDRESS.NAME.LAST_NAME.PLACEHOLDER")
              },
              on: { blur: _vm.$v.lastName.$touch },
              model: {
                value: _vm.lastName,
                callback: function($$v) {
                  _vm.lastName = typeof $$v === "string" ? $$v.trim() : $$v
                },
                expression: "lastName"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content-margin" },
          [
            _c(
              "label",
              {
                staticClass: "form-label flex-row flex-justify--between",
                staticStyle: { color: "#3c492c" }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("ORDER.ADDRESS.ADDRESS_LINE_1.LABEL")) +
                    "\n      "
                )
              ]
            ),
            _vm._v(" "),
            _c("woot-input", {
              class: { error: _vm.$v.addressLine1.$error },
              attrs: {
                rows: "2",
                type: "text",
                placeholder: _vm.$t("ORDER.ADDRESS.ADDRESS_LINE_1.PLACEHOLDER")
              },
              on: { blur: _vm.$v.addressLine1.$touch },
              model: {
                value: _vm.addressLine1,
                callback: function($$v) {
                  _vm.addressLine1 = $$v
                },
                expression: "addressLine1"
              }
            }),
            _vm._v(" "),
            _vm.$v.addressLine1.$error
              ? _c(
                  "span",
                  {
                    staticClass: "capital_c3 text-padding",
                    class: { "desc-error": true }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("ORDER.ADDRESS.ADDRESS_LINE_1.ERROR")) +
                        "\n      "
                    )
                  ]
                )
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "label",
          {
            staticClass: "form-label flex-row flex-justify--between",
            staticStyle: { color: "#3c492c" }
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("ORDER.ADDRESS.ADDRESS_LINE_2.LABEL")) +
                "\n    "
            )
          ]
        ),
        _vm._v(" "),
        _c("woot-input", {
          staticClass: "content-margin",
          attrs: {
            rows: "2",
            type: "text",
            placeholder: _vm.$t("ORDER.ADDRESS.ADDRESS_LINE_2.PLACEHOLDER")
          },
          model: {
            value: _vm.addressLine2,
            callback: function($$v) {
              _vm.addressLine2 = $$v
            },
            expression: "addressLine2"
          }
        }),
        _vm._v(" "),
        _c("woot-input", {
          staticClass: "content-margin",
          class: { error: _vm.$v.city.$error },
          attrs: {
            "has-error": _vm.$v.city.$error,
            error: _vm.$t("ORDER.ADDRESS.CITY.ERROR"),
            label: _vm.$t("ORDER.ADDRESS.CITY.LABEL"),
            placeholder: _vm.$t("ORDER.ADDRESS.CITY.PLACEHOLDER")
          },
          on: { blur: _vm.$v.city.$touch },
          model: {
            value: _vm.city,
            callback: function($$v) {
              _vm.city = typeof $$v === "string" ? $$v.trim() : $$v
            },
            expression: "city"
          }
        }),
        _vm._v(" "),
        _c("woot-input", {
          staticClass: "content-margin",
          class: { error: _vm.$v.state.$error },
          attrs: {
            "has-error": _vm.$v.state.$error,
            error: _vm.$t("ORDER.ADDRESS.STATE.ERROR"),
            label: _vm.$t("ORDER.ADDRESS.STATE.LABEL"),
            placeholder: _vm.$t("ORDER.ADDRESS.STATE.PLACEHOLDER")
          },
          on: { blur: _vm.$v.state.$touch },
          model: {
            value: _vm.state,
            callback: function($$v) {
              _vm.state = typeof $$v === "string" ? $$v.trim() : $$v
            },
            expression: "state"
          }
        }),
        _vm._v(" "),
        _c("woot-input", {
          staticClass: "content-margin",
          class: { error: _vm.$v.country.$error },
          attrs: {
            "has-error": _vm.$v.country.$error,
            error: _vm.$t("ORDER.ADDRESS.COUNTRY.ERROR"),
            label: _vm.$t("ORDER.ADDRESS.COUNTRY.LABEL"),
            placeholder: _vm.$t("ORDER.ADDRESS.COUNTRY.PLACEHOLDER")
          },
          on: { blur: _vm.$v.country.$touch },
          model: {
            value: _vm.country,
            callback: function($$v) {
              _vm.country = typeof $$v === "string" ? $$v.trim() : $$v
            },
            expression: "country"
          }
        }),
        _vm._v(" "),
        _c("woot-input", {
          staticClass: "content-margin",
          class: { error: _vm.$v.phoneNumber.$error },
          attrs: {
            "has-error": _vm.$v.phoneNumber.$error,
            error: _vm.$t("ORDER.ADDRESS.PHONE_NUMBER.ERROR"),
            label:
              "" +
              _vm.$t("ORDER.ADDRESS.PHONE_NUMBER.LABEL") +
              (_vm.permissions.phoneMandatory ? "" : " (optional)"),
            placeholder: _vm.$t("ORDER.ADDRESS.PHONE_NUMBER.PLACEHOLDER")
          },
          on: { blur: _vm.$v.phoneNumber.$touch },
          model: {
            value: _vm.phoneNumber,
            callback: function($$v) {
              _vm.phoneNumber = typeof $$v === "string" ? $$v.trim() : $$v
            },
            expression: "phoneNumber"
          }
        }),
        _vm._v(" "),
        _c("woot-input", {
          staticClass: "medium-12 columns content-margin",
          class: { error: _vm.$v.email.$error },
          attrs: {
            "has-error": _vm.$v.email.$error,
            size: "large",
            error: _vm.$t("ORDER.ADDRESS.EMAIL.ERROR"),
            label:
              "" +
              _vm.$t("ORDER.ADDRESS.EMAIL.LABEL") +
              (_vm.permissions.emailMandatory ? "" : " (optional)"),
            placeholder: _vm.$t("ORDER.ADDRESS.EMAIL.PLACEHOLDER")
          },
          on: { blur: _vm.$v.email.$touch },
          model: {
            value: _vm.email,
            callback: function($$v) {
              _vm.email = typeof $$v === "string" ? $$v.trim() : $$v
            },
            expression: "email"
          }
        }),
        _vm._v(" "),
        _c("woot-input", {
          staticClass: "content-margin",
          class: { error: _vm.$v.pinCode.$error },
          attrs: {
            "has-error": _vm.$v.pinCode.$error,
            error: _vm.$t("ORDER.ADDRESS.PINCODE.ERROR"),
            label: _vm.$t("ORDER.ADDRESS.PINCODE.LABEL"),
            placeholder: _vm.$t("ORDER.ADDRESS.PINCODE.PLACEHOLDER")
          },
          on: { blur: _vm.$v.pinCode.$touch },
          model: {
            value: _vm.pinCode,
            callback: function($$v) {
              _vm.pinCode = typeof $$v === "string" ? $$v.trim() : $$v
            },
            expression: "pinCode"
          }
        }),
        _vm._v(" "),
        _vm.addressId === -1
          ? _c(
              "div",
              { staticClass: "address--container" },
              [
                _c("woot-secondary-button", {
                  staticClass: "address--btn",
                  attrs: {
                    size: "medium",
                    name: _vm.$t("ORDER.ADDRESS.SAVE"),
                    loading: _vm.uiFlags.creatingAddress
                  },
                  on: { click: _vm.onSaveAddress }
                })
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "address--container flex-row" },
              [
                _c("woot-secondary-button", {
                  staticClass: "address--btn",
                  attrs: {
                    size: "medium",
                    name: _vm.$t("ORDER.ADDRESS.UPDATE"),
                    disabled: _vm.$v.$invalid
                  },
                  on: { click: _vm.onUpdateAddress }
                }),
                _vm._v(" "),
                _c("woot-primary-button", {
                  staticClass: "address--btn",
                  attrs: {
                    size: "medium",
                    name: _vm.$t("ORDER.ADDRESS.DELETE"),
                    "custom-class": "address--delete-btn"
                  },
                  on: { click: _vm.onDeleteAddress }
                })
              ],
              1
            )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }