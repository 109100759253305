var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box settings-wrapper" },
    [
      _c("form-header", {
        staticClass: "sla-header mg-bottom--slab",
        attrs: {
          name: _vm.slaPayload.name,
          description: _vm.slaPayload.description,
          "name-placeholder": _vm.$t("SLA.CREATE.MODAL.NAME.PLACEHOLDER"),
          "description-placeholder": _vm.$t(
            "SLA.CREATE.MODAL.DESC.LONG_PLACEHOLDER"
          ),
          active: _vm.slaPayload.active,
          "on-click": function() {
            return (_vm.showDetailsModal = true)
          },
          "on-back": _vm.onBack
        },
        on: {
          updateActive: function($event) {
            _vm.slaPayload.active = $event
          }
        }
      }),
      _vm._v(" "),
      _c("create-modal", {
        attrs: {
          show: _vm.showDetailsModal,
          name: _vm.slaPayload.name,
          description: _vm.slaPayload.description
        },
        on: {
          updateDetail: _vm.onUpdateDetail,
          close: _vm.onCloseModal,
          submit: _vm.onSubmitModal
        }
      }),
      _vm._v(" "),
      _c("unsaved-changes-modal", {
        attrs: { show: _vm.showUnsavedChangesModal },
        on: {
          close: function($event) {
            _vm.showUnsavedChangesModal = false
          },
          confirm: _vm.navigateToSlaHome
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "sla-form-wrapper" },
        [
          _c(
            "div",
            { staticClass: "sla-form-sections" },
            [
              _c("triggers-section", {
                attrs: { data: _vm.slaPayload },
                on: { update: _vm.onUpdatePayload }
              }),
              _vm._v(" "),
              _c("metrics-section", {
                attrs: { data: _vm.slaPayload },
                on: { update: _vm.onUpdatePayload }
              }),
              _vm._v(" "),
              _c("reminders-section", {
                attrs: { data: _vm.slaPayload },
                on: { update: _vm.onUpdatePayload }
              }),
              _vm._v(" "),
              _c("escalations-section", {
                attrs: { data: _vm.slaPayload },
                on: { update: _vm.onUpdatePayload }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("form-footer", {
            attrs: { "edit-view": _vm.isEditView },
            on: { submit: _vm.submitForm, onBack: _vm.onBack }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }