var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    {
      staticClass: "progress-tracker-modal",
      attrs: {
        "show-close": "",
        show: _vm.showPopup,
        "on-close": function() {
          return _vm.$emit("closeModal")
        }
      },
      on: {
        "update:show": function($event) {
          _vm.showPopup = $event
        }
      }
    },
    [
      _c("header", { staticClass: "modal-header" }, [
        _c("img", {
          staticClass: "image",
          attrs: {
            src: require("assets/images/progress_tracker.svg"),
            alt: "welcome-illustration"
          }
        })
      ]),
      _vm._v(" "),
      _c(
        "main",
        { staticClass: "modal-content" },
        [
          _c("h1", {
            staticClass: "title-h1 content-margin",
            domProps: {
              textContent: _vm._s(
                _vm.$t("SIDEBAR_ITEMS.PROGRESS_TRACKER_MODAL.TITLE")
              )
            }
          }),
          _vm._v(" "),
          _c("reference-box", {
            staticClass: "reference-box content-margin",
            attrs: {
              content: _vm.$t("SIDEBAR_ITEMS.PROGRESS_TRACKER_MODAL.SUB_TITLE")
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row flex-row gap--slab mg-bottom--large" },
            _vm._l(_vm.tasksList, function(task) {
              return _c(
                "div",
                {
                  key: task.key,
                  staticClass:
                    "task-container flex-row flex-justify--between flex-align",
                  class: {
                    active: task.active,
                    "not-active": task.active === false
                  },
                  on: {
                    click: function($event) {
                      return _vm.navigateTo(task)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "flex-row flex-justify flex-align",
                      class: task.active ? "gap--slab" : "gap--two"
                    },
                    [
                      _c(
                        "span",
                        [
                          task.active
                            ? _c("icons", {
                                attrs: {
                                  name: task.active ? "tickSingle" : "error",
                                  color: task.active ? "green" : "danger",
                                  size: "mediumlarge",
                                  view: "-2 -3 24 24",
                                  "show-title": false
                                }
                              })
                            : task.active === false
                            ? _c("icons", {
                                attrs: {
                                  name: "error",
                                  color: "danger",
                                  size: "medium",
                                  "show-title": false
                                }
                              })
                            : _c("icons", {
                                attrs: {
                                  name: "info",
                                  color: "warning",
                                  size: "semimedium",
                                  "show-title": false
                                }
                              })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "title-h4 text-dark",
                        domProps: { textContent: _vm._s(task.label) }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _c("span", {
                      staticClass: "body-b3 text-light task-status",
                      domProps: {
                        textContent: _vm._s(_vm.taskStatus(task.active))
                      }
                    })
                  ])
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "woot-base-button",
                {
                  staticClass: "mg-auto",
                  attrs: { size: "small", variant: "tertiary-danger" },
                  on: {
                    click: function() {
                      return _vm.$emit("closeModal")
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("SIDEBAR_ITEMS.PROGRESS_TRACKER_MODAL.CLOSE")
                      ) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }