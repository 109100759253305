var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "small-12" },
    [
      _c(
        "div",
        {
          staticClass: "flex-space-between",
          staticStyle: { "align-items": "flex-start" }
        },
        [
          _c("div", [
            _c("div", { staticClass: "title-h4 text-dark" }, [
              _vm._v("\n        Download Template and fill informaton\n      ")
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "mg-top--small subtitle-s2 text-light",
                staticStyle: { "max-width": "38rem" }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t(
                        "BROADCAST.BROADCAST_TAB.UPLOAD_CSV.SELECTED_CSV_CONTENT"
                      )
                    ) +
                    "\n      "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "mg-top mg-bottom--smaller button-small text-light"
              },
              [_vm._v("\n        Selected Template\n      ")]
            ),
            _vm._v(" "),
            _c("div", {
              ref: "selectUserContainer",
              staticClass: "body-b2 text-dark  custom-scroll",
              staticStyle: { "max-width": "43rem", "max-height": "8rem" },
              domProps: {
                innerHTML: _vm._s(
                  _vm.highlightedBrackets(_vm.selectedTemplate.body, true)
                )
              }
            })
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "csv-preview" },
            [
              _c(
                "div",
                {
                  staticClass: "flex-row mg-bottom",
                  staticStyle: { "justify-content": "flex-end" }
                },
                [
                  _c("woot-primary-button", {
                    attrs: {
                      name: "Download CSV template",
                      size: "small",
                      "icon-view-box": "0 0 20 20",
                      "front-icon": "excel"
                    },
                    on: { click: _vm.downloadCSVTemplate }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("csv-preview", {
                attrs: {
                  "headers-only": true,
                  "params-count": _vm.paramsCount,
                  "needs-media-header": _vm.needsMediaHeader
                }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "flex-space-between  mg-top--large",
          staticStyle: { "align-items": "flex-start" }
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "csv-preview" },
            [
              _c("drop-zone", {
                attrs: {
                  "accept-format": ".xlsx, .csv",
                  height: 180,
                  "img-icon": "",
                  "show-attachment-button": true
                },
                on: { fileData: _vm.onCSVUpload }
              }),
              _vm._v(" "),
              _vm.csvLoading
                ? _c("div", { staticClass: "flex-row mg-top--small" }, [
                    _c(
                      "div",
                      { staticClass: "flex-row-center" },
                      [
                        _c("woot-spinner", { attrs: { color: "lime-green" } }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "title-h4 text-dark button-small",
                            staticStyle: { color: "#6BAC1B" }
                          },
                          [
                            _vm._v(
                              "\n            uploading please wait\n          "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                : _c(
                    "div",
                    { staticClass: "flex-space-between mg-top--small" },
                    [
                      _vm.selectedFileName
                        ? _c("div", { staticClass: "flex-row-center" }, [
                            _c("img", {
                              staticClass: "mg-right--small",
                              attrs: { src: require("assets/images/excel.svg") }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "title-h4 text-dark" }, [
                              _vm._v(" " + _vm._s(_vm.selectedFileName))
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isUploaded
                        ? _c("div", [
                            !_vm.fileUploadFailed &&
                            _vm.csvErrorMessages.length === 0 &&
                            _vm.csvSoftErrorMessages.length === 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "flex-row title-h4_m",
                                    staticStyle: { color: "#6BAC1B" }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "mg-right--small" },
                                      [
                                        _c("icons", {
                                          attrs: {
                                            name: "tickRound",
                                            size: "medium",
                                            color: "green"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.$t(
                                            "BROADCAST.BROADCAST_TAB.UPLOAD_CSV.STATUS.SUCCESS"
                                          )
                                        ) +
                                        "\n          "
                                    )
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass: "flex-row title-h4_m",
                                    staticStyle: {
                                      color: "#FF7E61",
                                      cursor: "pointer"
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.showModal = true
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "mg-right--small" },
                                      [
                                        _c("icons", {
                                          attrs: {
                                            name: "error",
                                            color: "red",
                                            size: "semimedium"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(
                                      "\n            " +
                                        _vm._s("Error in file") +
                                        "\n          "
                                    )
                                  ]
                                )
                          ])
                        : _vm._e()
                    ]
                  )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-row flex-justify--end mg-top--large" },
        [
          _c("woot-primary-button", {
            attrs: {
              name: "previous",
              "custom-style": _vm.buttonStyle,
              "custom-class": "mg-right",
              size: "medium"
            },
            on: { click: _vm.goToPrevious }
          }),
          _vm._v(" "),
          _c("woot-primary-button", {
            attrs: {
              name: "next",
              "custom-style": _vm.buttonStyle,
              size: "medium",
              disabled: _vm.disableNext
            },
            on: { click: _vm.goToNext }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.showUploadModal
        ? _c(
            "woot-modal",
            {
              attrs: { show: _vm.showModal, "on-close": _vm.hideUploadModal },
              on: {
                "update:show": function($event) {
                  _vm.showModal = $event
                }
              }
            },
            [
              _c("div", { staticClass: "column content-box" }, [
                _c("div", { staticClass: "column page-top-bar modal-header" }, [
                  _c(
                    "span",
                    {
                      staticClass: "page-sub-title title-h2",
                      staticStyle: { color: "#FF7E61" }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s("Errors found in CSV") +
                          "\n        "
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row settings-form" }, [
                  _c(
                    "div",
                    { staticClass: "button-small text-light mg-bottom" },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t(
                              "BROADCAST.BROADCAST_TAB.UPLOAD_CSV.SELECTED_TEMPLATE"
                            )
                          ) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "small-12" }, [
                    _vm.selectedTemplate !== null
                      ? _c(
                          "div",
                          {
                            staticClass: "flex-row-justify-center template-prev"
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "template-prev--sc title-h5 text-dark"
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      "(" +
                                        _vm.selectedTemplate.display_id +
                                        ") " +
                                        _vm.selectedTemplate.short_code
                                    ) +
                                    "\n            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", {
                              staticClass: "body-b2 text-dark  custom-scroll",
                              staticStyle: { "max-height": "10rem" },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.highlightedBrackets(
                                    _vm.selectedTemplate.body,
                                    true
                                  )
                                )
                              }
                            })
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    _c("div", { staticStyle: { width: "100%" } }, [
                      _c(
                        "div",
                        { staticClass: "title-h5_m text-light mg-bottom" },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t(
                                  "BROADCAST.BROADCAST_TAB.UPLOAD_CSV.UPLOADED_CSV"
                                )
                              ) +
                              "\n            "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "flex-space-between",
                          staticStyle: { gap: "4.8rem" }
                        },
                        [
                          !_vm.fileUploadFailed
                            ? _c("div", { staticClass: "flex-row-center" }, [
                                _c("img", {
                                  staticClass: "mg-right--small",
                                  attrs: {
                                    src: require("assets/images/excel.svg")
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "title-h4 text-dark" },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.selectedFileName) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ])
                            : _c(
                                "div",
                                {
                                  staticClass: "flex-row-center",
                                  staticStyle: {
                                    "min-width": "30%",
                                    gap: "0.4rem"
                                  }
                                },
                                [
                                  _c("icons", {
                                    attrs: {
                                      name: "error",
                                      size: "normal",
                                      color: "warning"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "title-h5 text-dark" },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t(
                                              "BROADCAST.BROADCAST_TAB.UPLOAD_CSV.UPLOAD_FAILED"
                                            )
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                          _vm._v(" "),
                          _c("div", [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "primary-button button-medium flex-row-center"
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "mg-right--small" },
                                  [
                                    _c("icons", {
                                      attrs: {
                                        name: "refreshRound",
                                        view: "0 0 20 20",
                                        color: "white",
                                        size: "semimedium"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(
                                  "\n                  " +
                                    _vm._s("Re-upload ") +
                                    "\n                  "
                                ),
                                _c("file-upload", {
                                  ref: "upload",
                                  attrs: { accept: ".xlsx, .csv" },
                                  on: { "input-file": _vm.onCSVUpload }
                                })
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _vm.csvErrorMessages.length > 0 ||
                    _vm.csvSoftErrorMessages.length > 0
                      ? _c("div", [
                          _c("hr"),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "title-h5_m text-light mg-bottom" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      "BROADCAST.BROADCAST_TAB.UPLOAD_CSV.ERRORS_FOUND"
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm.csvErrorMessages.length
                            ? _c(
                                "ul",
                                {
                                  staticClass: "mg-left",
                                  staticStyle: { "list-style-type": "disc" }
                                },
                                _vm._l(_vm.csvErrorMessages, function(
                                  error,
                                  index
                                ) {
                                  return _c(
                                    "li",
                                    {
                                      key: index,
                                      staticClass: "title-h4_m",
                                      staticStyle: { color: "#FF7E61" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(error.content) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.csvSoftErrorMessages.length
                            ? _c(
                                "ul",
                                {
                                  staticClass: "mg-left",
                                  staticStyle: { "list-style-type": "disc" }
                                },
                                _vm._l(_vm.csvSoftErrorMessages, function(
                                  error,
                                  index
                                ) {
                                  return _c(
                                    "li",
                                    {
                                      key: index,
                                      staticClass: "title-h4_m",
                                      staticStyle: { color: "#F6BD16" }
                                    },
                                    [
                                      error.type === "phone" ||
                                      error.type === "opt_out"
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "flex-space-between"
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(error.content))
                                              ]),
                                              _vm._v(" "),
                                              _c("woot-tertiary-button", {
                                                attrs: {
                                                  size: "small",
                                                  name: "see list",
                                                  "custom-style": {
                                                    color: "#1B83AC"
                                                  }
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.downloadErrorCsv(
                                                      error.type
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _c("span", [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(error.content) +
                                                "\n                "
                                            )
                                          ])
                                    ]
                                  )
                                }),
                                0
                              )
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-row mg-top flex-align--start" },
                      [
                        _c("img", {
                          staticClass: "mg-right--small",
                          attrs: {
                            src: require("assets/images/light-bulb.svg")
                          }
                        }),
                        _vm._v(" "),
                        _c("div", [
                          _c("div", { staticClass: "body-b3" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.$t(
                                    "BROADCAST.BROADCAST_TAB.UPLOAD_CSV.INTERNATIONAL_INFO"
                                  )
                                ) +
                                "\n              "
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "body-b3" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.$t(
                                    "BROADCAST.BROADCAST_TAB.UPLOAD_CSV.BROADCAST_LIMIT_INFO"
                                  )
                                ) +
                                "\n              "
                            )
                          ])
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "modal-footer mg-top--medium",
                        staticStyle: {
                          "justify-content": "flex-end",
                          padding: "0"
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex-row-justify-center" },
                          [
                            _c("woot-tertiary-button", {
                              attrs: {
                                name: _vm.$t(
                                  "BROADCAST.BROADCAST_TAB.UPLOAD_CSV.CANCEL"
                                ),
                                "custom-style": {
                                  color: "#FF422E",
                                  "margin-right": "0.8rem"
                                }
                              },
                              on: { click: _vm.hideUploadModal }
                            }),
                            _vm._v(" "),
                            _c("woot-primary-button", {
                              attrs: {
                                name: _vm.$t(
                                  "BROADCAST.BROADCAST_TAB.UPLOAD_CSV.BUTTON.SUCCESS"
                                ),
                                disabled:
                                  _vm.csvErrorMessages.length > 0 &&
                                  !_vm.fileUploadFailed
                              },
                              on: { click: _vm.continueCSV }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ])
                ])
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("woot-delete-modal", {
        attrs: {
          show: _vm.showConfirmationModal,
          "on-close": _vm.closeModal,
          "on-confirm": _vm.proceedWithErrors,
          message: _vm.$t(
            "BROADCAST.BROADCAST_TAB.UPLOAD_CSV.CONFIRMATION_MODAL_CONTENT",
            {
              defectiveNumberLength: _vm.defectiveNumberLength,
              contact: _vm.defectiveNumberLength > 1 ? "contacts" : "contact",
              this: _vm.defectiveNumberLength > 1 ? "these" : "this"
            }
          ),
          "confirm-text": _vm.$t("BROADCAST.BROADCAST_TAB.UPLOAD_CSV.CONTINUE"),
          "reject-text": _vm.$t("BROADCAST.BROADCAST_TAB.UPLOAD_CSV.BACK")
        },
        on: {
          "update:show": function($event) {
            _vm.showConfirmationModal = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "title-h4 text-dark" }, [
        _vm._v("\n        Upload the edited template csv\n      ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "mg-top--slab body-b3 text-light",
          staticStyle: { "max-width": "40rem" }
        },
        [
          _vm._v(
            "\n        Upload the final CSV here. You can drag and drop or upload it from the\n        files."
          ),
          _c("br"),
          _vm._v("💡 Messages will not be sent to opted-out customers"),
          _c("br"),
          _vm._v(
            "💡Accepted\n        Phone No Format : [PHONE_NO] without '+' sign or country code"
          ),
          _c("br"),
          _vm._v(
            "💡Accepted\n        Country Format : [COUNTRY_CODE] without '+' sign"
          ),
          _c("br"),
          _vm._v(
            "💡Broadcast\n        Messaging now has international number support. CountryCode if left\n        empty will default to '91'\n      "
          )
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }