var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex-row-center" }, [
    _c(
      "button",
      { staticStyle: { cursor: "pointer" }, on: { click: _vm.muteToggle } },
      [
        _vm.soundOn
          ? _c("icons", {
              attrs: {
                name: "sound",
                "layer-fill": "white",
                color: "darkestgrey",
                size: "semimedium"
              }
            })
          : _c("icons", {
              attrs: {
                name: "soundoff",
                "layer-fill": "white",
                color: "darkestgrey",
                size: "semimedium"
              }
            })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }