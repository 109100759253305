var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("woot-single-selector", {
    attrs: {
      size: "small",
      "front-icon": "people",
      "custom-style": _vm.computedCustomStyle,
      "button-style": _vm.buttonStyle,
      "show-selected-text": false,
      "default-option": _vm.selectedTeamComputed,
      "option-list": _vm.modifiedTeamList,
      "highlight-selected": _vm.highlightTeamName
    },
    on: { click: _vm.onSelectTeam }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }