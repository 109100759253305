var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    {
      attrs: {
        show: _vm.opened,
        "custom-style": { width: "600px" },
        "on-close": _vm.onClose
      },
      on: {
        "update:show": function($event) {
          _vm.opened = $event
        }
      }
    },
    [
      _c("woot-modal-header", { attrs: { "header-title": _vm.headerTitle } }),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "modal-body",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.onSubmit($event)
            }
          }
        },
        [
          _c("woot-input", {
            staticClass: "mg-bottom medium-6",
            class: { error: _vm.$v.name.$error },
            attrs: {
              size: "small",
              label: _vm.$t("CONVERSATION.CONVERSATION_VIEWS.ADD.FORM.NAME"),
              type: "text",
              "auto-focus": "",
              required: "",
              "has-error": _vm.$v.name.$error,
              error: _vm.$t("CONVERSATION.CONVERSATION_VIEWS.ADD.FORM.ERROR"),
              placeholder: _vm.$t(
                "CONVERSATION.CONVERSATION_VIEWS.ADD.FORM.PLACEHOLDER"
              )
            },
            on: { blur: _vm.$v.name.$touch },
            model: {
              value: _vm.name,
              callback: function($$v) {
                _vm.name = $$v
              },
              expression: "name"
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "conversation-view-filters" },
            [
              _c("conversation-filter-tab", {
                staticClass: "mg-bottom",
                attrs: {
                  "applied-filters": _vm.filters,
                  "filter-list": _vm.conditionalFilterList,
                  "max-dropdown-height": "16rem"
                },
                on: { filterChange: _vm.onUpdateFilter }
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "container-checkbox",
                  class: { disabled: _vm.isDefaultView }
                },
                [
                  _c("div", { staticClass: "body-b2 label-text" }, [
                    _c("span", {
                      domProps: { textContent: _vm._s(_vm.makeDefaultText) }
                    })
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.isDefault,
                        expression: "isDefault"
                      }
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.isDefault)
                        ? _vm._i(_vm.isDefault, null) > -1
                        : _vm.isDefault
                    },
                    on: {
                      input: _vm.onInputSelected,
                      change: function($event) {
                        var $$a = _vm.isDefault,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.isDefault = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.isDefault = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.isDefault = $$c
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "checkmark" })
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-body--footer flex-row flex-justify--end" },
            [
              _c(
                "woot-base-button",
                {
                  attrs: {
                    size: "small",
                    type: "submit",
                    loading: _vm.uiFlags.isSaving,
                    disabled: _vm.$v.$invalid
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("CONVERSATION.CONVERSATION_VIEWS.ADD.FORM.SAVE")
                      ) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }