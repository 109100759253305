var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    {
      attrs: {
        show: _vm.show,
        "on-close": _vm.onCancel,
        "show-close": false,
        "small-modal": "",
        "custom-style": {
          "margin-top": "3rem",
          "margin-left": "-15rem",
          width: "40rem"
        },
        "modal-position": { "align-items": "flex-start" }
      },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c("div", { staticClass: "column content-box" }, [
        _c("div", { staticClass: "flex-row modal-header" }, [
          _c(
            "span",
            {
              staticClass: "mg-right--smaller",
              staticStyle: { cursor: "pointer" }
            },
            [
              _c("icons", {
                attrs: { name: "chevronLeft", color: "green", size: "medium" },
                on: { click: _vm.onCancel }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("span", { staticClass: "subtitle-s2 text-dark" }, [
            _vm._v("Edit Customer Information")
          ])
        ]),
        _vm._v(" "),
        _c(
          "form",
          {
            staticClass: "settings-form",
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.onSubmit($event)
              }
            }
          },
          [
            _c("account-pii-mask-wrapper", {
              attrs: {
                "pii-data": _vm.name,
                "mask-type": _vm.maskType.NAME_INPUT,
                "unmask-payload": _vm.unmaskRequestPayload,
                "on-unmask": function(data) {
                  return _vm.handleUnmask("name", data)
                },
                "unmask-action": "piiMask/logUnmaskEvent"
              },
              on: {
                update: function($event) {
                  _vm.name = $event
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var data = ref.data
                    var isMasked = ref.isMasked
                    var updateData = ref.updateData
                    return [
                      _c("woot-input", {
                        staticClass: "medium-11 columns content-margin",
                        class: { error: _vm.$v.name.$error },
                        attrs: {
                          value: data,
                          label: _vm.$t("EDIT_CONTACT.FORM.NAME.LABEL"),
                          "has-error": _vm.$v.name.$error,
                          error: _vm.$t("EDIT_CONTACT.FORM.NAME.ERROR_MESSAGE"),
                          size: "large",
                          placeholder: _vm.$t(
                            "EDIT_CONTACT.FORM.NAME.PLACEHOLDER"
                          ),
                          disabled: isMasked
                        },
                        on: { input: updateData, blur: _vm.$v.name.$touch }
                      })
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("account-pii-mask-wrapper", {
              attrs: {
                "pii-data": _vm.phoneNumber,
                "mask-type": _vm.maskType.PHONE_INPUT,
                "unmask-payload": _vm.unmaskRequestPayload,
                "on-unmask": function(data) {
                  return _vm.handleUnmask("phone_number", data)
                },
                "unmask-action": "piiMask/logUnmaskEvent"
              },
              on: {
                update: function($event) {
                  _vm.phoneNumber = $event
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var data = ref.data
                    var isMasked = ref.isMasked
                    var updateData = ref.updateData
                    return [
                      _c("woot-input", {
                        staticClass: "medium-11 columns content-margin",
                        class: { error: _vm.$v.phoneNumber.$error },
                        attrs: {
                          value: data,
                          label: _vm.$t("EDIT_CONTACT.FORM.PHONE_NUMBER.LABEL"),
                          "has-error": _vm.$v.phoneNumber.$error,
                          error: _vm.$t(
                            "EDIT_CONTACT.FORM.PHONE_NUMBER.ERROR_MESSAGE"
                          ),
                          size: "large",
                          placeholder: _vm.$t(
                            "EDIT_CONTACT.FORM.PHONE_NUMBER.PLACEHOLDER"
                          ),
                          disabled: isMasked
                        },
                        on: {
                          input: updateData,
                          blur: _vm.$v.phoneNumber.$touch
                        }
                      })
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("account-pii-mask-wrapper", {
              attrs: {
                "pii-data": _vm.email,
                "mask-type": _vm.maskType.EMAIL_INPUT,
                "unmask-action": "piiMask/logUnmaskEvent",
                "on-unmask": function(data) {
                  return _vm.handleUnmask("email", data)
                },
                "unmask-payload": _vm.unmaskRequestPayload
              },
              on: {
                update: function($event) {
                  _vm.email = $event
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var data = ref.data
                    var isMasked = ref.isMasked
                    var updateData = ref.updateData
                    return [
                      _c("woot-input", {
                        staticClass: "medium-11 columns content-margin",
                        class: { error: _vm.$v.email.$error },
                        attrs: {
                          value: data,
                          "has-error": _vm.$v.email.$error,
                          size: "large",
                          error: _vm.$t(
                            "EDIT_CONTACT.FORM.EMAIL_ADDRESS.ERROR_MESSAGE"
                          ),
                          label: _vm.$t(
                            "EDIT_CONTACT.FORM.EMAIL_ADDRESS.LABEL"
                          ),
                          placeholder: _vm.$t(
                            "EDIT_CONTACT.FORM.EMAIL_ADDRESS.PLACEHOLDER"
                          ),
                          disabled: isMasked
                        },
                        on: { input: updateData, blur: _vm.$v.email.$touch }
                      })
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("label", { staticClass: "formlabel" }, [
              _c("span", {
                staticClass: "title-h5 text-dark",
                domProps: {
                  textContent: _vm._s(_vm.$t("EDIT_CONTACT.FORM.INSTAGRAM"))
                }
              })
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "input-group" },
              [
                _c(
                  "span",
                  { staticClass: "input-group-label body-b2 text-dark" },
                  [_vm._v(_vm._s("https://instagram.com/"))]
                ),
                _vm._v(" "),
                _c("account-pii-mask-wrapper", {
                  attrs: {
                    "pii-data":
                      _vm.socialProfileUserNames.instagram_username || "",
                    "mask-type": _vm.maskType.INSTAGRAM_INPUT,
                    "unmask-action": "piiMask/logUnmaskEvent",
                    "on-unmask": function(data) {
                      return _vm.handleUnmask("instagram_username", data)
                    },
                    "unmask-payload": _vm.unmaskRequestPayload
                  },
                  on: {
                    update: function($event) {
                      _vm.socialProfileUserNames.instagram_username = $event
                    }
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var data = ref.data
                        var isMasked = ref.isMasked
                        var updateData = ref.updateData
                        return [
                          _c("input", {
                            staticClass:
                              "input-group-field form-input body-b2 text-dark",
                            attrs: {
                              placeholder: _vm.$t(
                                "EDIT_CONTACT.FORM.INSTAGRAM"
                              ),
                              disabled: isMasked
                            },
                            domProps: { value: data },
                            on: {
                              input: function(event) {
                                return updateData(event.target.value)
                              }
                            }
                          })
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("label", { staticClass: "formlabel" }, [
              _c("span", {
                staticClass: "title-h5 text-dark",
                domProps: {
                  textContent: _vm._s(_vm.$t("EDIT_CONTACT.FORM.FACEBOOK"))
                }
              })
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "input-group" },
              [
                _c(
                  "span",
                  { staticClass: "input-group-label body-b2 text-dark" },
                  [_vm._v(_vm._s("https://facebook.com/"))]
                ),
                _vm._v(" "),
                _c("account-pii-mask-wrapper", {
                  attrs: {
                    "pii-data": _vm.socialProfileUserNames.facebook || "",
                    "mask-type": _vm.maskType.FACEBOOK_INPUT,
                    "unmask-action": "piiMask/logUnmaskEvent",
                    "on-unmask": function(data) {
                      return _vm.handleUnmask("facebook", data)
                    },
                    "unmask-payload": _vm.unmaskRequestPayload
                  },
                  on: {
                    update: function($event) {
                      _vm.socialProfileUserNames.facebook = $event
                    }
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var data = ref.data
                        var isMasked = ref.isMasked
                        var updateData = ref.updateData
                        return [
                          _c("input", {
                            staticClass:
                              "input-group-field form-input body-b2 text-dark",
                            attrs: {
                              placeholder: _vm.$t("EDIT_CONTACT.FORM.FACEBOOK"),
                              disabled: isMasked
                            },
                            domProps: { value: data },
                            on: {
                              input: function(event) {
                                return updateData(event.target.value)
                              }
                            }
                          })
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm.contact.identifier
              ? _c("woot-input", {
                  staticClass:
                    "medium-12 columns content-margin disable-social-input",
                  attrs: {
                    size: "large",
                    label: _vm.$t("EDIT_CONTACT.FORM.IDENTIFIER.LABEL"),
                    placeholder: _vm.$t(
                      "EDIT_CONTACT.FORM.IDENTIFIER.PLACEHOLDER"
                    )
                  },
                  model: {
                    value: _vm.identifier,
                    callback: function($$v) {
                      _vm.identifier =
                        typeof $$v === "string" ? $$v.trim() : $$v
                    },
                    expression: "identifier"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "modal-footer" },
              [
                _c("woot-primary-button", {
                  attrs: {
                    loading: _vm.uiFlags.isUpdating,
                    name: "Save and close",
                    "full-width": "",
                    "custom-style": { margin: "0rem 6rem" }
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }