var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sla-status-container" },
    _vm._l(_vm.metrics, function(metric) {
      return _c("item", {
        key: metric.key,
        attrs: { "chat-id": _vm.chat.id, name: metric.key, time: metric.time }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }