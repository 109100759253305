var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box settings-box no-padding" },
    [
      _c(
        "div",
        { staticClass: "settings-button flex-row flex-align gap--normal" },
        [
          _c("reference-box", {
            attrs: {
              to: _vm.documentation.agent.role,
              content: "Know More",
              type: "document"
            }
          }),
          _vm._v(" "),
          _c(
            "woot-base-button",
            {
              attrs: {
                size: "medium",
                "front-icon": "plus",
                "icon-view-box": "0 0 20 20",
                disabled: _vm.inPendingPaymentForNonShopify && !_vm.isEnterprise
              },
              on: { click: _vm.openAddPopup }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("AGENT_MGMT.HEADER_BTN_TXT")) +
                  "\n    "
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "settings-container" },
        [
          _c("div", { staticClass: "column settings-main-menu-new" }, [
            _c(
              "div",
              { staticClass: "table-container" },
              [
                _c("data-table", {
                  ref: "dataTable",
                  attrs: {
                    columns: _vm.tableColumns,
                    "table-data": _vm.agentList,
                    loading: _vm.uiFlags.isFetching,
                    actions: _vm.actionsList,
                    "enable-animations": _vm.agentList.length <= 10,
                    _class: "scrollable-table custom-scroll",
                    "collapse-actions": ""
                  },
                  on: {
                    edit: _vm.editAgent,
                    select: _vm.viewAgent,
                    delete: _vm.openDeletePopup
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "table-features",
                      fn: function() {
                        return [
                          _c("search-box", {
                            attrs: {
                              placeholder: _vm.$t("AGENT_MGMT.SEARCH_TXT"),
                              value: _vm.search,
                              "custom-class": "search-box"
                            },
                            on: { setSearch: _vm.setSearch }
                          }),
                          _vm._v(" "),
                          _vm.inboxList.length
                            ? _c("woot-single-selector", {
                                attrs: {
                                  disabled: false,
                                  variant: "secondary",
                                  size: "medium",
                                  "front-icon": _vm.inboxFilterIcon
                                    ? _vm.inboxFilterIcon
                                    : "inbox",
                                  "default-option": _vm.selectedInboxObject,
                                  "option-list": _vm.inboxList,
                                  "show-selected-text": false,
                                  "dropdown-container-style": _vm.filterStyles,
                                  "button-style": { "border-radius": "0.4rem" },
                                  "custom-style": { left: 0 }
                                },
                                on: { click: _vm.applyInboxFilter }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("woot-single-selector", {
                            attrs: {
                              disabled: false,
                              variant: "secondary",
                              size: "medium",
                              "default-option": _vm.selectedRole,
                              "option-list": _vm.roles,
                              "show-selected-text": false,
                              "dropdown-container-style": _vm.filterStyles,
                              "button-style": { "border-radius": "0.4rem" },
                              "custom-style": { left: 0 }
                            },
                            on: { click: _vm.applyRoleFilter }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "zero-state",
                      fn: function() {
                        return [
                          !_vm.agentList.length
                            ? _c(
                                "table-zero-state",
                                {
                                  attrs: {
                                    title: "No Agents added till now",
                                    subtitle:
                                      "Click on the Add Agent Button to add a new agent",
                                    "asset-link":
                                      "/brand-assets/agent_zero_state.svg"
                                  }
                                },
                                [
                                  _c(
                                    "woot-base-button",
                                    {
                                      staticStyle: { "margin-bottom": "5vh" },
                                      attrs: {
                                        size: "medium",
                                        "front-icon": "plus",
                                        "icon-view-box": "0 0 20 20"
                                      },
                                      on: { click: _vm.openAddPopup }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t("AGENT_MGMT.HEADER_BTN_TXT")
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      },
                      proxy: true
                    }
                  ])
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "settings-side-card",
            [
              _c("agent-rhs-panel", {
                ref: "rhsPanel",
                attrs: { data: _vm.currentAgent },
                on: {
                  openDeleteModal: _vm.openDeletePopup,
                  openEditModal: _vm.editAgent
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.showAgentPopup
        ? _c("add-edit-agent", {
            attrs: { show: _vm.showAgentPopup, "agent-data": _vm.currentAgent },
            on: { onClose: _vm.hideAddPopup, updateCurrAgent: _vm.viewAgent }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("woot-delete-modal-new", {
        attrs: {
          show: _vm.showDeletePopup,
          "on-close": _vm.closeDeletePopup,
          "on-confirm": _vm.confirmDeletion,
          "show-close": "",
          title: _vm.$t("AGENT_MGMT.DELETE.CONFIRM.TITLE"),
          message: _vm.$t("AGENT_MGMT.DELETE.CONFIRM.MESSAGE"),
          "confirm-text": "Continue",
          "reject-text": "Back",
          "custom-style": {
            "max-width": "35.2rem",
            height: "auto"
          }
        },
        on: {
          "update:show": function($event) {
            _vm.showDeletePopup = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }