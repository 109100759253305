var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "assignment-panel custom-scroll" }, [
    _c("div", { staticClass: "assignment-panel--header" }, [
      _vm.isAutoMode
        ? _c(
            "div",
            { staticClass: "flex-row flex-align gap--medium" },
            [
              _c("assignment-logic-radio", {
                attrs: {
                  label: _vm.$t(
                    "TICKET_ASSIGNMENT.ASSIGNMENT_PANEL.TYPES.LOAD_BALANCED.TITLE"
                  ),
                  "info-text": _vm.$t(
                    "TICKET_ASSIGNMENT.ASSIGNMENT_PANEL.TYPES.LOAD_BALANCED.INFO_TEXT"
                  ),
                  "is-active": _vm.isLoadBalanceActive
                },
                on: {
                  toggle: function($event) {
                    return _vm.toggleAssignmentLogic(
                      _vm.ASSIGNMENT_LOGIC.LOAD_BALANCED
                    )
                  }
                }
              }),
              _vm._v(" "),
              _c("assignment-logic-radio", {
                attrs: {
                  label: _vm.$t(
                    "TICKET_ASSIGNMENT.ASSIGNMENT_PANEL.TYPES.ROUND_ROBIN.TITLE"
                  ),
                  "info-text": _vm.$t(
                    "TICKET_ASSIGNMENT.ASSIGNMENT_PANEL.TYPES.ROUND_ROBIN.INFO_TEXT"
                  ),
                  "is-active": _vm.isRoundRobinActive
                },
                on: {
                  toggle: function($event) {
                    return _vm.toggleAssignmentLogic(
                      _vm.ASSIGNMENT_LOGIC.ROUND_ROBIN
                    )
                  }
                }
              })
            ],
            1
          )
        : _c("div", { staticClass: "flex-row flex-align gap--small" }, [
            _c("span", {
              staticClass: "title-h4 text-dark",
              domProps: {
                textContent: _vm._s(
                  _vm.$t(
                    "TICKET_ASSIGNMENT.ASSIGNMENT_PANEL.TYPES.MANUAL.TITLE"
                  )
                )
              }
            }),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.$t(
                      "TICKET_ASSIGNMENT.ASSIGNMENT_PANEL.TYPES.MANUAL.INFO_TEXT"
                    ),
                    expression:
                      "\n          $t('TICKET_ASSIGNMENT.ASSIGNMENT_PANEL.TYPES.MANUAL.INFO_TEXT')\n        "
                  }
                ]
              },
              [
                _c("icons", {
                  attrs: { name: "info", size: "small", color: "darkgrey" }
                })
              ],
              1
            )
          ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "inboxes-container" },
      [
        _vm.isAutoMode && _vm.noInboxes
          ? _c("reference-box", {
              staticClass: "warn-banner",
              attrs: {
                content: _vm.$t("TICKET_ASSIGNMENT.ASSIGNMENT_PANEL.INFO_TEXT"),
                "icon-name": "info",
                "icon-size": "normal",
                "icon-color": "darkestgrey",
                "view-box": "0 -3 24 26"
              }
            })
          : _vm._e(),
        _vm._v(" "),
        !_vm.noInboxes
          ? [
              _c("div", {
                staticClass: "title-h5_m text-light mg-bottom--slab",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("TICKET_ASSIGNMENT.ASSIGNMENT_PANEL.ACTIVE_INBOXES")
                  )
                }
              }),
              _vm._v(" "),
              _c(
                "transition-group",
                {
                  staticClass: "row gap--slab",
                  attrs: { tag: "div", name: "modal-fade" }
                },
                _vm._l(_vm.inboxes, function(inbox) {
                  return _c("inbox-card", {
                    key: inbox.id,
                    attrs: { inbox: inbox, mode: _vm.mode },
                    on: {
                      click: function($event) {
                        return _vm.$emit("update", $event)
                      }
                    }
                  })
                }),
                1
              )
            ]
          : _c("div", {
              staticClass: "no-inboxes body-b2 text-light",
              domProps: {
                textContent: _vm._s(
                  _vm.$t("TICKET_ASSIGNMENT.ASSIGNMENT_PANEL.NO_INBOXES")
                )
              }
            })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }