var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "text-align mg-bottom--larger" }, [
      _c("div", {
        staticClass: "title-h1 text-dark mg-bottom",
        domProps: {
          innerHTML: _vm._s(
            _vm.$t(
              "ONBOARDING.WHATSAPP.WABA_INTEGRATION_COMPLETE.ERROR_HEADING"
            )
          )
        }
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "body-b2 text-light",
        domProps: {
          innerHTML: _vm._s(
            _vm.$t(
              "ONBOARDING.WHATSAPP.WABA_INTEGRATION_COMPLETE.ERROR_SUB_HEADING"
            )
          )
        }
      })
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "flex-row flex-justify--end" },
      [
        _c("connect-button", {
          staticClass: "navigation-button",
          attrs: {
            label: _vm.$t(
              "ONBOARDING.WHATSAPP.WABA_INTEGRATION_COMPLETE.TRY_AGAIN"
            ),
            "query-parameters": {
              redirect_url: _vm.setRedirectUrl(),
              state: _vm.inbox.phone_number
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }