var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-row flex-align flex-wrap gap--small" },
    [
      _c("search-box", {
        attrs: {
          placeholder: _vm.$t("SLA.SEARCH_TXT"),
          value: _vm.searchTerm,
          "custom-class": "search-box"
        },
        on: { setSearch: _vm.setSearch }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }