var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "conversations-sidebar columns" },
    [
      _c(
        "div",
        { staticClass: "conversations-sidebar-header" },
        [
          _c("chat-list-top-deprecated", {
            on: {
              filterChange: _vm.updateFilter,
              statusFilterChange: _vm.updateStatusType
            }
          }),
          _vm._v(" "),
          _vm.assigneeTabItems.length !== 0
            ? _c(
                "woot-tabs",
                {
                  staticClass: "assignee-tabs",
                  attrs: { index: _vm.selectedTabIndex },
                  on: {
                    change: function(_, ref) {
                      var key = ref.key

                      return _vm.updateAssigneeTab(
                        key,
                        _vm.fetchConversationsIfNeeded
                      )
                    }
                  }
                },
                _vm._l(_vm.assigneeTabItems, function(tab) {
                  return _c("woot-tabs-item", {
                    key: tab.key,
                    staticClass: "assignee-tab",
                    attrs: { name: _vm.getTabName(tab), data: tab }
                  })
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "conversation-sort-filter" },
            [
              _c("conversation-sort-filter", {
                ref: "conversationSortFilter",
                attrs: { variant: "tertiary" },
                on: { applyFilter: _vm.applySortFilter }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.selected.length
            ? _c(
                "div",
                { staticClass: "selection-bar" },
                [
                  _c("div", { staticClass: "checkbox-container" }, [
                    _c("label", { staticClass: "container-checkbox" }, [
                      _c("input", {
                        attrs: { type: "checkbox", value: "select_all" },
                        domProps: { checked: _vm.selectAllLoaded },
                        on: {
                          input: function($event) {
                            return _vm.selectCard(_vm.selectAllLoaded)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "checkmark" })
                    ]),
                    _vm._v(" "),
                    !_vm.selectAllLoaded
                      ? _c(
                          "label",
                          {
                            staticClass: "checkbox-label",
                            staticStyle: { "margin-top": "0.6rem" },
                            attrs: { for: "select_all" }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$t("CONVERSATION.SELECT_BUTTON") +
                                    " (" +
                                    _vm.selected.length +
                                    ")"
                                ) +
                                "\n        "
                            )
                          ]
                        )
                      : _c("div", { staticClass: "checkbox-container" }, [
                          _c(
                            "label",
                            {
                              staticClass: "checkbox-label",
                              staticStyle: { "margin-top": "0.8rem" },
                              attrs: { for: "select_all" }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s("(" + _vm.selected.length + ")") +
                                  "\n          "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "checkbox-container",
                              staticStyle: { "margin-left": "1.6rem" }
                            },
                            [
                              _c(
                                "label",
                                { staticClass: "container-checkbox" },
                                [
                                  _c("input", {
                                    attrs: {
                                      type: "checkbox",
                                      value: "select_all"
                                    },
                                    domProps: { checked: _vm.selectAll },
                                    on: { input: _vm.selectAllConversation }
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "checkmark" })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass: "checkbox-label",
                                  staticStyle: { "margin-top": "0.6rem" },
                                  attrs: { for: "select_all" }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t("CONVERSATION.SELECT_BUTTON") +
                                          " (" +
                                          _vm.selectCount +
                                          ")"
                                      ) +
                                      "\n            "
                                  )
                                ]
                              )
                            ]
                          )
                        ])
                  ]),
                  _vm._v(" "),
                  _c("woot-primary-button", {
                    attrs: {
                      type: "button",
                      size: "small",
                      name: _vm.$t("CONVERSATION.MODIFY_BUTTON"),
                      "front-icon": "refresh",
                      "icon-size": "semimedium"
                    },
                    on: { click: _vm.showModifyPopup }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      !_vm.chatListLoading && !_vm.conversationList.length
        ? _c("p", { staticClass: "content-box" }, [
            _vm._v("\n    " + _vm._s(_vm.$t("CHAT_LIST.LIST.404")) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "conversations-list" },
        [
          _vm._l(_vm.conversationShowList, function(chat) {
            return _c("conversation-card", {
              key: chat.id,
              attrs: {
                "show-select-option": true,
                selected: _vm.isSelected(chat.id),
                selection: !!_vm.selected.length,
                "select-all": _vm.selectAllLoaded,
                "active-tab": _vm.activeAssigneeTab,
                "active-status": _vm.activeStatus,
                chat: chat,
                "team-id": _vm.teamId,
                "chat-account-name": _vm.companyName(chat.account_id),
                "refresh-sla-status": _vm.slaStatusRerenderMap[chat.id] || 0
              },
              on: { select: _vm.selectCard }
            })
          }),
          _vm._v(" "),
          _vm.chatListLoading
            ? _c("div", { staticClass: "text-center" }, [
                _c("span", { staticClass: "spinner" })
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.hasCurrentPageEndReached && !_vm.chatListLoading
            ? _c(
                "div",
                {
                  staticClass: "clear button load-more-conversations",
                  on: { click: _vm.fetchConversations }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("CHAT_LIST.LOAD_MORE_CONVERSATIONS")) +
                      "\n    "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.conversationList.length &&
          _vm.hasCurrentPageEndReached &&
          !_vm.chatListLoading
            ? _c(
                "p",
                { staticClass: "text-center text-muted end-of-list-text" },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("CHAT_LIST.EOF")) + "\n    "
                  )
                ]
              )
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _vm.showModifyModal
        ? _c("bulk-action-modal", {
            attrs: {
              show: _vm.showModifyModal,
              "selected-conv": _vm.selected,
              "selected-count": _vm.selectAll
                ? _vm.selectCount
                : _vm.selected.length,
              "select-all": _vm.selectAll,
              filters: _vm.appliedConversationFilters,
              "on-close": _vm.hideModifyPopup
            },
            on: {
              "update:show": function($event) {
                _vm.showModifyModal = $event
              },
              done: function($event) {
                return _vm.selectCard(true)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }