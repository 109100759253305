var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "settings full-height custom-scroll" },
      [
        _c("div", {
          staticClass: "settings-title title-h3 text-dark",
          domProps: { textContent: _vm._s("Define Heirarchy") }
        }),
        _vm._v(" "),
        _c("label", { staticClass: "formlabel" }, [
          _c("span", {
            staticClass: "title-h5 text-dark",
            domProps: {
              textContent: _vm._s(_vm.$t("LABEL_MGMT.FORM.GROUP.LABEL"))
            }
          })
        ]),
        _vm._v(" "),
        _c("woot-single-selector", {
          attrs: {
            size: "large",
            "default-key": "title",
            "option-list": _vm.parentLabels,
            "custom-style": {
              width: "100%",
              left: 0,
              boxShadow: "none",
              maxHeight: "18rem"
            },
            "default-option": _vm.selectedGroup
          },
          on: { click: _vm.onChangeGroup }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }