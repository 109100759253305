var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "small-12" }, [
    _c(
      "div",
      {
        staticClass: "flex-space-between",
        staticStyle: { "align-items": "flex-start" }
      },
      [
        _c(
          "div",
          {
            staticClass: "message-display-container flex-column",
            staticStyle: { width: "50rem" }
          },
          [
            _c("div", { staticClass: "selected-inbox" }, [
              _c("span", { staticClass: "subtitle-s1 text-light" }, [
                _vm._v("Inbox Channel")
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "mg-top--small flex-row-center" }, [
                _c(
                  "span",
                  { staticClass: "mg-right--small" },
                  [
                    _c("icons", {
                      attrs: {
                        name: "whatsApp",
                        size: "medium",
                        color: "green"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", { staticClass: "body-b2 text-dark" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.selectedInbox.name) +
                      " - " +
                      _vm._s(_vm.selectedInbox.phone) +
                      "\n          "
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _vm.selectedTemplate
              ? _c("div", [
                  _c("span", { staticClass: "subtitle-s1 text-light" }, [
                    _vm._v("Selected Template")
                  ]),
                  _vm._v(" "),
                  _vm.selectedTemplate.template_type === "image"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "mg-top--medium body-b2 text-dark custom-scroll flex-space-between",
                          staticStyle: {
                            "max-width": "42rem",
                            "max-height": "10rem",
                            "align-items": "flex-start"
                          }
                        },
                        [
                          _c("div", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.highlightedBrackets(
                                  _vm.selectedTemplate.body,
                                  true
                                )
                              )
                            }
                          }),
                          _vm._v(" "),
                          _vm.firstRow.MediaURL
                            ? _c("img", {
                                staticClass: "mg-left",
                                attrs: {
                                  src: _vm.firstRow.MediaURL,
                                  height: "72px",
                                  width: "72px"
                                }
                              })
                            : _vm._e()
                        ]
                      )
                    : _c(
                        "div",
                        {
                          staticClass:
                            "mg-top--medium body-b2 text-dark custom-scroll",
                          staticStyle: {
                            "max-width": "42rem",
                            "max-height": "10rem"
                          }
                        },
                        [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.highlightedBrackets(
                                  _vm.selectedTemplate.body,
                                  true
                                )
                              )
                            }
                          })
                        ]
                      ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "subtitle-s1 text-light mg-bottom--slab",
                      staticStyle: { "margin-top": "2rem" }
                    },
                    [_vm._v("\n          Variables\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "flex-row variable-container" },
                    _vm._l(_vm.parameters, function(param, index) {
                      return _c("div", { key: index }, [
                        param
                          ? _c(
                              "div",
                              { staticClass: "variable-chip text-truncate " },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(param) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e()
                      ])
                    }),
                    0
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _vm.selectedTemplate
              ? _c("div", [
                  _c("span", { staticClass: "subtitle-s1 text-light" }, [
                    _vm._v("Test your Message")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "mg-top flex-row-center",
                      staticStyle: {
                        width: "40rem",
                        "align-items": "flex-start"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { "min-width": "38rem" } },
                        [
                          _c("woot-input", {
                            class: {
                              error:
                                _vm.testPhoneNumber !== "fake" &&
                                _vm.$v.testPhoneNumber.$error
                            },
                            attrs: {
                              type: "text",
                              "has-error":
                                _vm.testPhoneNumber !== "fake" &&
                                _vm.$v.testPhoneNumber.$error,
                              error: _vm.$t(
                                "INBOX_MGMT.ADD.WEBSITE_CHANNEL.PHONE.ERROR"
                              ),
                              placeholder: _vm.$t(
                                "BROADCAST.BROADCAST_TAB.PHONE_INPUT_PLACEHOLDER"
                              )
                            },
                            on: { blur: _vm.$v.testPhoneNumber.$touch },
                            model: {
                              value: _vm.testPhoneNumber,
                              callback: function($$v) {
                                _vm.testPhoneNumber = $$v
                              },
                              expression: "testPhoneNumber"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "mg-left--large",
                          staticStyle: { "margin-top": "0.6rem" }
                        },
                        [
                          _c("woot-secondary-button", {
                            attrs: {
                              size: "small",
                              name: "Test",
                              disabled:
                                _vm.testPhoneNumber === "" ||
                                (_vm.$v.testPhoneNumber.$error &&
                                  _vm.testPhoneNumber !== "fake")
                            },
                            on: { click: _vm.sendTestMessage }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ])
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mg-right whatsapp-position" },
          [_c("whatsapp-component", { attrs: { messages: _vm.messages } })],
          1
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "flex-row flex-justify--end mg-top--medium mg-right" },
      [
        _c("woot-primary-button", {
          attrs: {
            name: "save and schedule",
            "custom-class": "mg-right",
            "front-icon": "history",
            size: "medium"
          },
          on: { click: _vm.scheduleBroadcast }
        }),
        _vm._v(" "),
        _c("woot-primary-button", {
          attrs: {
            name: "send now",
            "front-icon": "send",
            "custom-style": { width: "12rem" },
            size: "medium"
          },
          on: { click: _vm.sendBroadcast }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }