import { render, staticRenderFns } from "./EditContactModal.vue?vue&type=template&id=f91ff904&scoped=true&"
import script from "./EditContactModal.vue?vue&type=script&lang=js&"
export * from "./EditContactModal.vue?vue&type=script&lang=js&"
import style0 from "./EditContactModal.vue?vue&type=style&index=0&id=f91ff904&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f91ff904",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/limechat/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f91ff904')) {
      api.createRecord('f91ff904', component.options)
    } else {
      api.reload('f91ff904', component.options)
    }
    module.hot.accept("./EditContactModal.vue?vue&type=template&id=f91ff904&scoped=true&", function () {
      api.rerender('f91ff904', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/lime-ui/dashboard/components/widgets/conversation/EditContactModal.vue"
export default component.exports