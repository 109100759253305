var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-footer flex-row flex-justify--between full-width" },
    [
      _c(
        "woot-base-button",
        {
          attrs: { size: "small", loading: _vm.isLoading },
          on: {
            click: function($event) {
              return _vm.$emit("submit")
            }
          }
        },
        [_vm._v("\n    " + _vm._s(_vm.editView ? "SAVE" : "CREATE") + "\n  ")]
      ),
      _vm._v(" "),
      _c(
        "woot-base-button",
        {
          attrs: { size: "small", variant: "secondary-danger" },
          on: {
            click: function($event) {
              return _vm.$emit("onBack")
            }
          }
        },
        [_vm._v("\n    DISCARD\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }