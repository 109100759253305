var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.uiFlags.fetchingMetrics
    ? _c(
        "div",
        { staticClass: "metrics-card-container" },
        _vm._l(_vm.metricsData, function(item, index) {
          return _c("metrics-card", {
            key: index,
            attrs: { title: item.title, "sub-title": item.sub }
          })
        }),
        1
      )
    : _c(
        "div",
        { staticClass: "metrics-card-container " },
        _vm._l(_vm.metricsData, function(item, index) {
          return _c("metrics-card-skeleton", { key: index })
        }),
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }