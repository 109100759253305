var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showCaseDatum
    ? _c(
        "div",
        {
          staticClass:
            "educational-onboarding-wrapper flex-column flex-align mg-bottom--medium",
          on: { mouseenter: _vm.stopSlider, mouseleave: _vm.startSlider }
        },
        [
          _c("div", {
            staticClass: "profile-viewer full-width",
            style: {
              backgroundImage: "url(" + _vm.showCaseDatum.image + ")",
              backgroundColor: _vm.showCaseDatum.color || "#4267B2"
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "information-container" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "information-slider flex-row flex-justify--between flex-align"
                },
                [
                  _c("span", {
                    staticClass: "title-h1 text-dark capitalize",
                    domProps: { textContent: _vm._s(_vm.showCaseDatum.name) }
                  }),
                  _vm._v(" "),
                  _vm.showCaseData.length > 1
                    ? _c(
                        "div",
                        { staticClass: "slide flex-row" },
                        _vm._l(_vm.showCaseData.length, function(el, idx) {
                          return _c(
                            "span",
                            {
                              key: idx,
                              class: { capsule: idx === _vm.currIndex },
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function($event) {
                                  _vm.currIndex = idx
                                }
                              }
                            },
                            [_c("span", { staticClass: "capsule-green" })]
                          )
                        }),
                        0
                      )
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c("div", {
                staticClass: "body-b3 text-light custom-scroll",
                style: _vm.contentStyles,
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t(
                      _vm.showCaseDatum.contentPath +
                        ".EDUCATIONAL_ONBOARDING." +
                        _vm.showCaseDatum.key +
                        ".DESC"
                    )
                  )
                }
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "title-h4 text-dark mg-bottom--small mg-top--slab",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t(
                      _vm.showCaseDatum.contentPath +
                        ".EDUCATIONAL_ONBOARDING." +
                        _vm.showCaseDatum.key
                    ).LIST_TITLE || ""
                  )
                }
              }),
              _vm._v(" "),
              _c(
                "transition",
                { attrs: { name: "modal-fade", mode: "out-in", appear: "" } },
                [
                  _vm.isListAvailable
                    ? _c(
                        "ul",
                        { key: _vm.forceRerender(), staticClass: "slide-in" },
                        _vm._l(
                          _vm.$t(
                            _vm.showCaseDatum.contentPath +
                              ".EDUCATIONAL_ONBOARDING." +
                              _vm.showCaseDatum.key +
                              ".LIST"
                          ),
                          function(listItem) {
                            return _c(
                              "li",
                              {
                                key: listItem,
                                staticClass: "access-list flex-row",
                                staticStyle: { gap: "0.8rem" }
                              },
                              [
                                _c("icons", {
                                  attrs: {
                                    name: "tickSingle",
                                    color: "green",
                                    view: "0 0 16 16"
                                  }
                                }),
                                _vm._v(" "),
                                _c("span", {
                                  staticClass: "body-b3 text-light",
                                  domProps: { textContent: _vm._s(listItem) }
                                })
                              ],
                              1
                            )
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showCaseDatum.asset
                    ? _c("img", {
                        staticClass: "full-width",
                        attrs: {
                          src: _vm.showCaseDatum.asset,
                          alt: "illustration"
                        }
                      })
                    : _vm._e()
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm._t("default")
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }