var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lime-card-1dp" },
    [
      _c("div", { staticClass: "table-title mg-bottom" }, [
        _vm._v("\n    Agent Wise Conversation States\n    "),
        _c(
          "a",
          {
            staticClass: "mg-left--slab",
            on: {
              click: function($event) {
                _vm.showEducationalModal = true
              }
            }
          },
          [_vm._v("\n      How states work?\n    ")]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "conv-states-tables-container" }, [
        _c(
          "div",
          { staticClass: "custom-scroll" },
          [
            _c("span", {
              staticClass: "subtitle-s2 text-dark",
              domProps: {
                textContent: _vm._s(
                  _vm.showConvStatesTitleWithDateRange(
                    _vm.startDate,
                    _vm.endDate
                  )
                )
              }
            }),
            _vm._v(" "),
            _c("woot-table", {
              staticClass: "conv-states-table with-range",
              attrs: {
                "header-list": _vm.$t(
                  "ANALYTICS.CONVERSATION_HEADER_WITH_RANGE"
                ),
                "table-data": _vm.tableDataWithRange,
                "column-width-one": "20%"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "custom-scroll" },
          [
            _c("span", {
              staticClass: "subtitle-s2 text-dark",
              domProps: {
                textContent: _vm._s(
                  _vm.showConvStatesTitleWithEndDate(_vm.endDate)
                )
              }
            }),
            _vm._v(" "),
            _c("woot-table", {
              staticClass: "conv-states-table",
              attrs: {
                "header-list": _vm.$t(
                  "ANALYTICS.CONVERSATION_HEADER_WITHOUT_RANGE"
                ),
                "table-data": _vm.tableDataWithoutRange
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("educational-onboarding-modal", {
        attrs: { show: _vm.showEducationalModal },
        on: {
          onClose: function($event) {
            _vm.showEducationalModal = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }