var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wizard-body small-12 columns" },
    [
      _c("page-header", {
        attrs: {
          "header-title": _vm.$t("INBOX_MGMT.ADD.TWO_FACTOR.TITLE"),
          "header-content": _vm.$t("INBOX_MGMT.ADD.TWO_FACTOR.DESC")
        }
      }),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "row",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.createChannel()
            }
          }
        },
        [
          _c("woot-input", {
            staticClass: "medium-12 columns content-margin",
            class: { error: _vm.$v.channelName.$error },
            attrs: {
              "has-error": _vm.$v.channelName.$error,
              error: _vm.$t("INBOX_MGMT.ADD.TWO_FACTOR.CHANNEL_NAME.ERROR"),
              label: _vm.$t("INBOX_MGMT.ADD.TWO_FACTOR.CHANNEL_NAME.LABEL"),
              placeholder: _vm.$t(
                "INBOX_MGMT.ADD.TWO_FACTOR.CHANNEL_NAME.PLACEHOLDER"
              )
            },
            on: { blur: _vm.$v.channelName.$touch },
            model: {
              value: _vm.channelName,
              callback: function($$v) {
                _vm.channelName = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "channelName"
            }
          }),
          _vm._v(" "),
          _c("woot-input", {
            staticClass: "medium-12 columns content-margin",
            class: { error: _vm.$v.phoneNumber.$error },
            attrs: {
              "has-error": _vm.$v.phoneNumber.$error,
              error:
                _vm.phoneNumber === ""
                  ? _vm.$t("INBOX_MGMT.COMMON_ERROR_MESSAGE")
                  : _vm.$t("INBOX_MGMT.ADD.TWO_FACTOR.PHONE_NUMBER.ERROR"),
              label: _vm.$t("INBOX_MGMT.ADD.TWO_FACTOR.PHONE_NUMBER.LABEL"),
              placeholder: _vm.$t(
                "INBOX_MGMT.ADD.TWO_FACTOR.PHONE_NUMBER.PLACEHOLDER"
              )
            },
            on: { blur: _vm.$v.phoneNumber.$touch },
            model: {
              value: _vm.phoneNumber,
              callback: function($$v) {
                _vm.phoneNumber = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "phoneNumber"
            }
          }),
          _vm._v(" "),
          _c("woot-input", {
            staticClass: "medium-12 columns content-margin",
            class: { error: _vm.$v.senderId.$error },
            attrs: {
              "has-error": _vm.$v.senderId.$error,
              error: _vm.$t("INBOX_MGMT.ADD.TWO_FACTOR.SENDER_ID.ERROR"),
              label: _vm.$t("INBOX_MGMT.ADD.TWO_FACTOR.SENDER_ID.LABEL"),
              placeholder: _vm.$t(
                "INBOX_MGMT.ADD.TWO_FACTOR.SENDER_ID.PLACEHOLDER"
              )
            },
            on: { blur: _vm.$v.senderId.$touch },
            model: {
              value: _vm.senderId,
              callback: function($$v) {
                _vm.senderId = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "senderId"
            }
          }),
          _vm._v(" "),
          _c("woot-input", {
            staticClass: "medium-12 columns content-margin",
            class: { error: _vm.$v.authToken.$error },
            attrs: {
              "has-error": _vm.$v.authToken.$error,
              error: _vm.$t("INBOX_MGMT.ADD.TWO_FACTOR.AUTH_TOKEN.ERROR"),
              label: _vm.$t("INBOX_MGMT.ADD.TWO_FACTOR.AUTH_TOKEN.LABEL"),
              placeholder: _vm.$t(
                "INBOX_MGMT.ADD.TWO_FACTOR.AUTH_TOKEN.PLACEHOLDER"
              )
            },
            on: { blur: _vm.$v.authToken.$touch },
            model: {
              value: _vm.authToken,
              callback: function($$v) {
                _vm.authToken = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "authToken"
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "medium-12 columns" },
            [
              _c("woot-primary-button", {
                attrs: {
                  loading: _vm.uiFlags.isCreating,
                  name: _vm.$t("INBOX_MGMT.ADD.TWO_FACTOR.SUBMIT_BUTTON"),
                  "custom-style": { float: "right" }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }