var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("custom-field-form", {
    attrs: {
      "custom-field": _vm.customField,
      "initial-custom-field-inboxes": _vm.customFieldInboxes
    },
    on: { submitFieldForm: _vm.createField }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }