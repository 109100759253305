var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "top-nav-bar" },
    _vm._l(_vm.tabList, function(tab, index) {
      return _c(
        "li",
        {
          key: tab.key,
          class: {
            "is-active": _vm.active === index,
            "is-first": index === 0,
            "is-last": index === _vm.tabList.length - 1,
            "is-inactive": index !== _vm.active && index > _vm.active
          },
          on: {
            click: function($event) {
              return _vm.switchActive(index)
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "folder-tab flex-row-center",
              class: {
                "is-first": index === 0,
                "title-h4": _vm.active === index,
                "subtitle-s1": _vm.active !== index
              },
              style: [_vm.customStyle]
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "index-number title-h5 mg-right--slab flex-column-center flex-justify",
                  class: {
                    "active-index": _vm.active === index,
                    "inactive-index": _vm.active !== index
                  }
                },
                [_vm._v("\n        " + _vm._s(index + 1) + "\n      ")]
              ),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(tab.name))])
            ]
          )
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }