var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view-box-new columns" },
    [
      _c("settings-header", {
        attrs: {
          icon: "graph",
          "header-title": _vm.$t("ANALYTICS.HEADER.ANALYTICS"),
          "show-back-button": false
        }
      }),
      _vm._v(" "),
      _c("last-updated-time", {
        key: _vm.computedScope,
        staticClass: "flex-row flex-justify--end pd-right",
        attrs: { scope: _vm.computedScope }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tabs-pane" },
        [
          _c(
            "woot-tabs",
            {
              attrs: { index: _vm.selectedTabIndex },
              on: { change: _vm.onTabChange }
            },
            _vm._l(_vm.tabs, function(tab) {
              return _c("woot-tabs-item", {
                key: tab.key,
                attrs: { name: tab.name }
              })
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.selectedTabIndex === 0 ? _c("agent-csat") : _vm._e(),
      _vm._v(" "),
      _vm.selectedTabIndex === 1 ? _c("bot-csat") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }