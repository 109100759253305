var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("multiselect", {
    attrs: {
      "track-by": "id",
      label: _vm.labelKey,
      placeholder: "Select",
      multiple: "",
      "selected-label": "",
      "deselect-label": "",
      "select-label": _vm.$t("FORMS.MULTISELECT.ENTER_TO_SELECT"),
      "max-height": 160,
      options: _vm.dropdownValues,
      "allow-empty": ""
    },
    model: {
      value: _vm.values,
      callback: function($$v) {
        _vm.values = $$v
      },
      expression: "values"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }