var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isStatus
    ? _c(
        "div",
        {
          staticClass: "bagde-v3-component",
          class: _vm.status.toLowerCase().replaceAll(" ", "")
        },
        [
          _vm.statusIcon
            ? _c("icons", {
                attrs: {
                  name: _vm.statusIcon,
                  size: "semimedium",
                  color: _vm.statusIconColor
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticStyle: {
                padding: "0.2rem 0.8rem 0.2rem 0.6rem",
                "text-transform": "uppercase"
              }
            },
            [_vm._v("\n    " + _vm._s(_vm.statusText) + "\n  ")]
          )
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "bagde-v3-component", class: _vm.type },
        [
          !_vm.noIcon
            ? _c("icons", {
                attrs: { name: _vm.icon, size: "semimedium", color: "white" }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            { staticStyle: { padding: "0.2rem 0.8rem 0.2rem 0.6rem" } },
            [_vm._v("\n    " + _vm._s(_vm.text) + "\n  ")]
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }