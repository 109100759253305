var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "full-width" },
    [
      _c("Header"),
      _vm._v(" "),
      _vm.computedInbox
        ? _c(
            "div",
            { staticClass: "create-ticket-wrapper" },
            [
              _c("create-ticket-header", {
                attrs: { inbox: _vm.computedInbox }
              }),
              _vm._v(" "),
              _vm.computedInbox.isWhatsAppInbox
                ? _c("create-wa-ticket-form", {
                    attrs: {
                      "inbox-id": _vm.computedInbox && _vm.computedInbox.id
                    },
                    on: { submit: _vm.onSubmit }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.computedInbox.isEmailInbox
                ? _c("create-email-ticket-form", {
                    attrs: {
                      "inbox-id": _vm.computedInbox && _vm.computedInbox.id
                    },
                    on: { submit: _vm.onSubmit }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("success-modal", {
                attrs: {
                  show: _vm.showSuccessModal,
                  contacts: _vm.selectedContacts,
                  "inbox-type":
                    _vm.computedInbox && _vm.computedInbox.isWhatsAppInbox
                      ? "whatsapp"
                      : "email"
                },
                on: { closeModal: _vm.onCloseModal }
              })
            ],
            1
          )
        : _c("create-ticket-zero-state")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }