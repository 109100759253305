var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoading && _vm.allEnabledFields.length
    ? _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.updateValues($event)
            }
          }
        },
        [
          _c(
            "woot-base-button",
            {
              staticClass: "update-button",
              attrs: {
                size: "small",
                disabled: _vm.$v.fieldValues.$invalid,
                type: "submit"
              }
            },
            [_vm._v("\n    UPDATE\n  ")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "flex-column gap--normal" }, [
            _c(
              "section",
              { staticClass: "flex-column gap--normal mg-top--normal" },
              [
                _c("data-type-input", {
                  staticClass: "FIELD_INPUT_0",
                  attrs: {
                    label: _vm.allEnabledFields[0].name,
                    "data-type":
                      _vm.FIELD_DATA_TYPE[_vm.allEnabledFields[0].field_type],
                    options: _vm.allEnabledFields[0].dropdown_options || [],
                    value: _vm.fieldValue(_vm.allEnabledFields[0]),
                    validator: _vm.$v.fieldValues.$each[0].value,
                    error: _vm.getErrorMessage(
                      _vm.$v.fieldValues.$each[0].value
                    ),
                    "update-value": function(newValue) {
                      return _vm.updateField(_vm.allEnabledFields[0], newValue)
                    }
                  }
                }),
                _vm._v(" "),
                _vm.allEnabledFields.length > 1 && !_vm.showAllFields
                  ? _c(
                      "div",
                      {
                        staticClass: "see-more body-b3",
                        on: {
                          click: function($event) {
                            _vm.showAllFields = true
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n        See More\n        " +
                            _vm._s(
                              _vm.allEnabledFields.length > 1
                                ? "(" + (_vm.allEnabledFields.length - 1) + ")"
                                : ""
                            ) +
                            "\n      "
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _vm.showAllFields
              ? _c(
                  "section",
                  { staticClass: "flex-column gap--normal" },
                  [
                    _vm._l(_vm.allEnabledFields.slice(1), function(
                      field,
                      index
                    ) {
                      return _c("data-type-input", {
                        key: index,
                        class: "FIELD_INPUT_" + (index + 1),
                        attrs: {
                          label: field.name,
                          "data-type": _vm.FIELD_DATA_TYPE[field.field_type],
                          options: field.dropdown_options || [],
                          value: _vm.fieldValue(field),
                          validator: _vm.$v.fieldValues.$each[index + 1].value,
                          error: _vm.getErrorMessage(
                            _vm.$v.fieldValues.$each[index + 1].value
                          ),
                          "update-value": function(newValue) {
                            return _vm.updateField(field, newValue)
                          }
                        }
                      })
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "see-more body-b3",
                        on: {
                          click: function($event) {
                            _vm.showAllFields = false
                          }
                        }
                      },
                      [_vm._v("\n        See Less\n      ")]
                    )
                  ],
                  2
                )
              : _vm._e()
          ])
        ],
        1
      )
    : _vm.isLoading
    ? _c("spinner", { staticClass: "mg-top--small" })
    : _c("div", {
        staticClass: "body-b2 text-dark mg-top--small",
        domProps: {
          textContent: _vm._s(_vm.$t("CUSTOM_TICKET_FIELDS.NO_FIELDS_MESSAGE"))
        }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }