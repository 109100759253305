var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    { class: ["input-wrapper", _vm.uploadState] },
    [
      _vm.uploadState !== "processing"
        ? _c("input", {
            attrs: { type: "file", name: "attachment" },
            on: { change: _vm.onChangeFile }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.uploadState === "processing" ? _c("woot-spinner") : _vm._e(),
      _vm._v(" "),
      _vm.uploadState === "idle"
        ? _c("icons", { attrs: { icon: "file-upload" } })
        : _vm._e(),
      _vm._v(" "),
      _vm.uploadState === "uploaded"
        ? _c("icons", {
            staticClass: "success-icon",
            attrs: { icon: "checkmark-circle", type: "outline" }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.uploadState === "failed"
        ? _c("icons", {
            staticClass: "error-icon",
            attrs: { icon: "dismiss-circle", type: "outline" }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("p", { staticClass: "file-button" }, [_vm._v(_vm._s(_vm.label))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }