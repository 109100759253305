var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "relative-position" },
    [
      _vm.isSelected
        ? _c(
            "span",
            {
              staticClass: "table-title flex-row-justify-center",
              staticStyle: { "justify-content": "left" }
            },
            [
              _c("icons", {
                attrs: {
                  name: "chevronLeft",
                  color: "green",
                  size: "mediumlarge"
                },
                on: {
                  click: function($event) {
                    return _vm.$emit("select-summary")
                  }
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "subtable-title" }, [
                _vm._v(_vm._s(_vm.title) + " ")
              ])
            ],
            1
          )
        : _c("span", { staticClass: "table-title" }, [
            _vm._v(_vm._s(_vm.title) + " ")
          ]),
      _vm._v(" "),
      _vm._t("filters"),
      _vm._v(" "),
      _c("span", { staticClass: "tooltip-position flex-row-justify-center" }, [
        _vm.isDownloadable
          ? _c(
              "div",
              {
                staticClass: "flex-row-justify-center",
                staticStyle: { "margin-right": "0.3rem" },
                on: {
                  click: function($event) {
                    return _vm.$emit("download-csv")
                  }
                }
              },
              [
                _c(
                  "woot-base-button",
                  { attrs: { "front-icon": "download" } },
                  [_vm._v("\n        " + _vm._s(_vm.downloadText) + "\n      ")]
                ),
                _vm._v(" "),
                _vm.isDownloading
                  ? _c("span", { staticClass: "spinner" })
                  : _vm._e()
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.tooltipContent
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.tooltipContent,
                    expression: "tooltipContent"
                  }
                ]
              },
              [
                _c("icons", {
                  attrs: { name: "info", size: "medium", color: "grey" }
                })
              ],
              1
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", [
        _vm.maxHeight
          ? _c(
              "table",
              {
                staticClass:
                  "metrics-table custom-scroll scroll-table relative-position",
                style: { height: _vm.maxHeight }
              },
              [
                _c(
                  "tr",
                  _vm._l(_vm.headerList, function(item) {
                    return _c(
                      "th",
                      { key: item.id, staticClass: "sticky-header" },
                      [
                        _vm.isSortable
                          ? _c(
                              "div",
                              {
                                staticClass: "table-header",
                                on: {
                                  click: function($event) {
                                    return _vm.sortBy(item.id)
                                  }
                                }
                              },
                              [
                                _c("icons", {
                                  attrs: {
                                    name: "sort",
                                    color: "green",
                                    size: "semimedium"
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "0.8rem" } },
                                  [_vm._v(_vm._s(item.name))]
                                )
                              ],
                              1
                            )
                          : _c("div", { staticClass: "table-header" }, [
                              _c("span", [_vm._v(_vm._s(item.name))])
                            ])
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _vm.tableData.length && !_vm.hasSubtable
                  ? _c(
                      "tbody",
                      _vm._l(_vm.formattedTableData, function(row, $index) {
                        return _c(
                          "tr",
                          { key: $index },
                          [
                            _c("td", { style: { width: _vm.columnWidthOne } }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(row[0]) +
                                  "\n          "
                              )
                            ]),
                            _vm._v(" "),
                            _vm._l(row.slice(1), function(cell, $index2) {
                              return _c(
                                "td",
                                {
                                  key: $index2,
                                  style: { width: _vm.columnWidthTwo }
                                },
                                [_c("span", [_vm._v(" " + _vm._s(cell))])]
                              )
                            })
                          ],
                          2
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.tableData.length && _vm.hasSubtable
                  ? _c(
                      "tbody",
                      _vm._l(_vm.formattedTableData, function(row, $index) {
                        return _c(
                          "tr",
                          {
                            key: $index,
                            on: {
                              click: function($event) {
                                return _vm.$emit("select-agent", row[0])
                              }
                            }
                          },
                          _vm._l(row, function(cell, i) {
                            return _c("td", { key: i }, [
                              _vm._v(
                                "\n            " + _vm._s(cell) + "\n          "
                              )
                            ])
                          }),
                          0
                        )
                      }),
                      0
                    )
                  : _vm._e()
              ]
            )
          : _c("table", { staticClass: "metrics-table" }, [
              _c(
                "tr",
                _vm._l(_vm.headerList, function(item) {
                  return _c("th", { key: item.id }, [
                    _vm.isSortable
                      ? _c(
                          "div",
                          {
                            staticClass: "table-header",
                            on: {
                              click: function($event) {
                                return _vm.sortBy(item.id)
                              }
                            }
                          },
                          [
                            _c("icons", {
                              attrs: {
                                name: "sort",
                                color: "green",
                                size: "semimedium"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "0.8rem" } },
                              [_vm._v(_vm._s(item.name))]
                            )
                          ],
                          1
                        )
                      : _c("div", { staticClass: "table-header" }, [
                          _c("span", [_vm._v(_vm._s(item.name))])
                        ])
                  ])
                }),
                0
              ),
              _vm._v(" "),
              _vm.tableData.length && !_vm.hasSubtable
                ? _c(
                    "tbody",
                    [
                      _vm._l(_vm.formattedTableData, function(row, $index) {
                        return _c(
                          "tr",
                          { key: $index },
                          [
                            _c("td", { style: { width: _vm.columnWidthOne } }, [
                              _c("div", {
                                style: { width: _vm.textWidth },
                                domProps: { innerHTML: _vm._s(row[0]) }
                              })
                            ]),
                            _vm._v(" "),
                            _vm._l(row.slice(1), function(cell, i) {
                              return _c(
                                "td",
                                {
                                  key: i,
                                  style: { width: _vm.columnWidthTwo }
                                },
                                [
                                  cell == "AGENT HANDOFF" ||
                                  cell == "BOT CONFUSED"
                                    ? _c("span", [
                                        cell == "AGENT HANDOFF"
                                          ? _c(
                                              "span",
                                              [
                                                _c("woot-badge", {
                                                  attrs: {
                                                    text: cell,
                                                    type:
                                                      "badge-yellow-variant",
                                                    "no-icon": true
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        cell == "BOT CONFUSED"
                                          ? _c(
                                              "span",
                                              [
                                                _c("woot-badge", {
                                                  attrs: {
                                                    text: cell,
                                                    type: "badge-green-variant",
                                                    "no-icon": true
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ])
                                    : typeof cell === "object"
                                    ? _c(
                                        "span",
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "row-link",
                                              attrs: { to: cell.link }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "mg-right--smaller"
                                                },
                                                [_vm._v(_vm._s(cell.message))]
                                              ),
                                              _vm._v(" "),
                                              _c("u", [
                                                _vm._v(
                                                  " " + _vm._s(cell.conv_id)
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _c("span", {
                                        domProps: { innerHTML: _vm._s(cell) }
                                      })
                                ]
                              )
                            })
                          ],
                          2
                        )
                      }),
                      _vm._v(" "),
                      _vm.totalArray.length > 0
                        ? _c(
                            "tr",
                            _vm._l(_vm.totalArray, function(row, $index) {
                              return _c(
                                "td",
                                { key: $index, staticClass: "row-padding" },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(row) +
                                      "\n          "
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.tableData.length && _vm.hasSubtable
                ? _c(
                    "tbody",
                    _vm._l(_vm.formattedTableData, function(row, $index) {
                      return _c(
                        "tr",
                        {
                          key: $index,
                          on: {
                            click: function($event) {
                              return _vm.$emit("select-agent", row[0])
                            }
                          }
                        },
                        _vm._l(row, function(cell, i) {
                          return _c("td", { key: i }, [
                            _vm._v(
                              "\n            " + _vm._s(cell) + "\n          "
                            )
                          ])
                        }),
                        0
                      )
                    }),
                    0
                  )
                : _vm._e()
            ])
      ]),
      _vm._v(" "),
      !_vm.tableData.length
        ? _c("div", { staticClass: "no-data flex-column-center" }, [
            _c("div", [
              _c("img", {
                attrs: {
                  src: require("dashboard/assets/images/noData.svg"),
                  alt: "No Data",
                  height: "100px",
                  width: "100px"
                }
              })
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "no-items-error-message" }, [
              _vm._v("\n      " + _vm._s("No data found") + "\n    ")
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isPaginated && _vm.isPaginationVisible
        ? _c(
            "div",
            {
              staticClass: "flex-row-justify-center",
              staticStyle: { "justify-content": "flex-end" }
            },
            [
              _c(
                "p",
                {
                  staticStyle: { "margin-top": "1rem", "margin-right": "1rem" }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.pageSize) +
                      " of " +
                      _vm._s(_vm.totalCount) +
                      "\n    "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "icon-button rotate-180" },
                [
                  _c("icons", {
                    attrs: {
                      name: "chevronRightDouble",
                      size: "medium",
                      color: "green"
                    },
                    on: { click: _vm.onFirstPage }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "icon-button" },
                [
                  _c("icons", {
                    attrs: {
                      name: "chevronLeft",
                      size: "medium",
                      color: "green"
                    },
                    on: { click: _vm.onPrevPage }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.currentPage < 10
                ? _c("p", { staticStyle: { "margin-top": "1rem" } }, [
                    _vm._v(
                      "\n      " + _vm._s("0" + _vm.currentPage) + "\n    "
                    )
                  ])
                : _c("p", { staticStyle: { "margin-top": "1rem" } }, [
                    _vm._v("\n      " + _vm._s(_vm.currentPage) + "\n    ")
                  ]),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "icon-button" },
                [
                  _c("icons", {
                    attrs: {
                      name: "chevronRight",
                      size: "medium",
                      color: "green"
                    },
                    on: { click: _vm.onNextPage }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "icon-button" },
                [
                  _c("icons", {
                    attrs: {
                      name: "chevronRightDouble",
                      size: "medium",
                      color: "green"
                    },
                    on: { click: _vm.onLastPage }
                  })
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("bottom")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }