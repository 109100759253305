var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contacts-page row" },
    [
      _c(
        "div",
        { staticClass: "left-wrap lime-card-1dp", class: _vm.wrapClass },
        [
          _c("contacts-header", {
            attrs: {
              "search-query": _vm.searchQuery,
              "on-search-submit": _vm.onSearchSubmit,
              "this-selected-contact-id": "",
              "on-input-search": _vm.onInputSearch,
              "on-close": _vm.closeContactInfoPanel,
              "is-closed": _vm.showContactInfoPanelPane,
              "search-param": _vm.searchParam,
              "set-search-param": _vm.setSearchParam,
              "on-change-label-ids": _vm.onChangeLabelIds
            }
          }),
          _vm._v(" "),
          _c("contacts-table", {
            attrs: {
              contacts: _vm.activeRecords,
              "show-search-empty-state": _vm.showEmptySearchResult,
              "is-loading": _vm.uiFlags.isFetching,
              "on-click-contact": _vm.openContactInfoPanel,
              "active-contact-id": _vm.activeContactId
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "full-width flex-row flex-align flex-justify--end navigation"
            },
            [
              _c(
                "div",
                { staticClass: "flex-row flex-align navigation-controls" },
                [
                  _c("woot-primary-button", {
                    attrs: {
                      name: "previous",
                      "custom-style": { width: "12rem" },
                      disabled: _vm.uiFlags.isFetching || _vm.currentPage === 1
                    },
                    on: { click: _vm.prevPage }
                  }),
                  _vm._v(" "),
                  _c("woot-primary-button", {
                    attrs: {
                      name: "next",
                      "custom-style": { width: "12rem" },
                      disabled:
                        _vm.uiFlags.isFetching || _vm.activeRecords.length < 15
                    },
                    on: { click: _vm.nextPage }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.showContactViewPane
        ? _c("contact-info-panel", {
            key: "contact-info-panel-" + _vm.selectedContact.id,
            attrs: { contact: _vm.selectedContact }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }