var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "ticket-card mg-bottom--small" }, [
        _c(
          "div",
          { staticClass: "flex-space-between mg-bottom--smaller" },
          [
            _c("span", {
              staticClass: "title-h5 text-dark",
              domProps: {
                textContent: _vm._s(_vm.truncateString(_vm.ticket.id))
              }
            }),
            _vm._v(" "),
            _c("woot-chips", {
              attrs: {
                title: _vm.truncateString(_vm.ticket.status),
                variant: "primary-small",
                "show-tooltip": false,
                "show-cancel-icon": false
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", [
          _c("span", { staticClass: "title-h5 text-dark" }, [_vm._v("Sub: ")]),
          _vm._v(" "),
          _c("span", {
            staticClass: "body-b2 text-dark",
            domProps: {
              textContent: _vm._s(_vm.truncateString(_vm.ticket.subject))
            }
          })
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex-space-between" },
          [
            _c("div", [
              _c("span", { staticClass: "title-h5 text-dark" }, [
                _vm._v("Order ID: ")
              ]),
              _vm._v(" "),
              _c("span", {
                staticClass: "body-b2 text-dark",
                domProps: {
                  textContent: _vm._s(_vm.truncateString(_vm.ticket.orderId))
                }
              })
            ]),
            _vm._v(" "),
            _c(
              "woot-base-button",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: "Edit",
                    expression: "'Edit'"
                  }
                ],
                attrs: { tag: "span", size: "small", variant: "tertiary" },
                on: { click: _vm.toggleEditModal }
              },
              [
                _c("icons", {
                  attrs: {
                    name: "editRound",
                    color: "green",
                    size: "semimedium",
                    "show-title": false
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.showEditModal
        ? _c("edit-ticket", {
            attrs: {
              show: _vm.showEditModal,
              ticket: _vm.ticket,
              "selected-crm": _vm.selectedCrm
            },
            on: { cancel: _vm.toggleEditModal }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }