var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition-group",
    {
      staticClass: "customer--container lime-card-border custom-scroll",
      attrs: { name: "menu-list", mode: "out-in", tag: "div" }
    },
    [
      _c("create-address", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showCreateAddress,
            expression: "showCreateAddress"
          }
        ],
        key: 1,
        attrs: {
          "is-shipping": _vm.isShippingAddress,
          "address-id": _vm.selecteAddressId
        },
        on: {
          closeCreateAddress: function($event) {
            _vm.showCreateAddress = false
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showCreateAddress,
              expression: "!showCreateAddress"
            }
          ],
          key: 2
        },
        [
          _c("div", { staticClass: "top--container" }, [
            _c(
              "div",
              { staticClass: "flex-row flex-align flex-justify--between " },
              [
                _c("div", { staticClass: "flex-row flex-align info" }, [
                  _c(
                    "span",
                    {
                      staticStyle: { cursor: "pointer" },
                      attrs: { title: "Go back" }
                    },
                    [
                      _c("icons", {
                        attrs: {
                          name: "chevronLeft",
                          color: "green",
                          size: "medium",
                          "show-title": false
                        },
                        on: { click: _vm.onCloseCreateOrder }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "button-small" },
                    [
                      _vm.orderMode === "edit"
                        ? _c("span", { staticClass: "text-light" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t("ORDER.CREATE.EDIT_MODE.TITLE", {
                                    orderStatus: _vm.activeShopifyOrder.is_cod
                                      ? "COD"
                                      : "PREPAID"
                                  })
                                ) +
                                "\n            "
                            )
                          ])
                        : _c("span", { staticClass: "text-light" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t("ORDER.CREATE.CREATE_MODE.TITLE")
                                ) +
                                "\n            "
                            )
                          ]),
                      _vm._v(" "),
                      _c(
                        "transition",
                        { attrs: { name: "toast-fade", mode: "out-in" } },
                        [
                          !_vm.shouldEditName
                            ? _c(
                                "span",
                                { on: { dblclick: _vm.onEditCustomerName } },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.contact.name) +
                                      "\n              "
                                  )
                                ]
                              )
                            : _c("woot-input", {
                                staticClass: "mt-4",
                                attrs: {
                                  placeholder: _vm.$t(
                                    "ORDER.CREATE.CUSTOMER_NAME.PLACEHOLDER"
                                  )
                                },
                                nativeOn: {
                                  keyup: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.onUpdateCustomerName($event)
                                  }
                                },
                                model: {
                                  value: _vm.customerName,
                                  callback: function($$v) {
                                    _vm.customerName =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "customerName"
                                }
                              })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                !_vm.shouldEditName
                  ? _c(
                      "span",
                      {
                        staticClass: "edit-name-icon",
                        attrs: {
                          title: _vm.$t(
                            "ORDER.CREATE.CUSTOMER_NAME.TOOLTIP_TEXT_EDIT"
                          )
                        },
                        on: { click: _vm.onEditCustomerName }
                      },
                      [
                        _c("icons", {
                          attrs: {
                            name: "editRound",
                            color: "green",
                            size: "semimedium",
                            "show-title": false
                          }
                        })
                      ],
                      1
                    )
                  : _c(
                      "span",
                      { staticClass: "update-name-icon" },
                      [
                        _c("icons", {
                          attrs: {
                            name: "refresh",
                            size: "medium",
                            color: "green",
                            title: _vm.$t(
                              "ORDER.CREATE.CUSTOMER_NAME.TOOLTIP_TEXT_UPDATE"
                            )
                          },
                          on: { click: _vm.onUpdateCustomerName }
                        })
                      ],
                      1
                    )
              ]
            ),
            _vm._v(" "),
            _vm.orderMode === "edit"
              ? _c("span", { staticClass: "text-red body-b2 alert" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("ORDER.CREATE.EDIT_MODE.EDIT_WARNING_TEXT")
                      ) +
                      "\n      "
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("woot-secondary-button", {
            attrs: {
              size: "medium",
              name: _vm.$t("ORDER.CREATE.ADD_PRODUCTS"),
              "back-icon": _vm.showProductList ? "chevronTop" : "chevronDown",
              "icon-color": "green",
              "custom-class": [
                "add-new-product-btn",
                _vm.showProductList && "active-btn-state"
              ]
            },
            on: {
              click: function($event) {
                _vm.showProductList = !_vm.showProductList
              }
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "flex-column flex-justify--between customer--tools-container"
            },
            [
              _c(
                "div",
                { staticClass: "customer--tools" },
                [
                  _c(
                    "transition",
                    { attrs: { name: "slide-up" } },
                    [
                      _vm.showProductList
                        ? _c("products-dropdown", {
                            directives: [
                              {
                                name: "on-clickaway",
                                rawName: "v-on-clickaway",
                                value: function() {
                                  return (_vm.showProductList = false)
                                },
                                expression: "() => (showProductList = false)"
                              }
                            ],
                            on: {
                              closeDropdown: function($event) {
                                _vm.showProductList = false
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.hasProducts
                    ? _c(
                        "div",
                        [
                          _c("order-details", {
                            attrs: {
                              orders: _vm.productsInCart,
                              "cart-data": _vm.cartDetails
                            },
                            on: {
                              error: function($event) {
                                _vm.areCartFieldsValid = !$event
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "info-excerpt body-b3 text-dark" },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("ORDER.CREATE.NO_PRODUCTS_ADDED")) +
                              "\n        "
                          )
                        ]
                      ),
                  _vm._v(" "),
                  _vm.hasProducts && !_vm.customerAddresses.length
                    ? _c(
                        "div",
                        { staticClass: "info-excerpt body-b3 text-dark" },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("ORDER.CREATE.NO_ADDRESS_ADDED")) +
                              "\n        "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("customer-address", {
                    attrs: {
                      "address-type": "shipping",
                      addresses: _vm.customerAddresses
                    },
                    on: { showCreateAddress: _vm.handleShowCreateAddress }
                  }),
                  _vm._v(" "),
                  _c("customer-address", {
                    attrs: {
                      "address-type": "billing",
                      addresses: _vm.customerAddresses
                    }
                  }),
                  _vm._v(" "),
                  _c("notes", {
                    on: {
                      "change-notes-data": function($event) {
                        return _vm.setNotesData($event)
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.orderMode !== "edit"
                ? _c("transition", { attrs: { name: "modal-fade" } }, [
                    !_vm.showProductList
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "place-order-button flex-row flex-justify",
                            class: { disabled: _vm.isBtnDisabled },
                            on: { click: _vm.btnDisabledHandler }
                          },
                          [
                            _vm.permissions.canPrepay && _vm.permissions.canCod
                              ? _c("woot-dropdown-button", {
                                  attrs: {
                                    "is-inverted": true,
                                    name: _vm.placeOrderButtonLabel,
                                    "option-list": _vm.orderOptions,
                                    "custom-class":
                                      "place-order--dropdown actions--container",
                                    "custom-button-style": _vm.isBtnDisabled
                                      ? { opacity: "60%" }
                                      : {},
                                    "button-click": _vm.placePrepaidOrder
                                  },
                                  on: { click: _vm.placeCodOrder }
                                })
                              : _vm.permissions.canPrepay
                              ? _c("woot-primary-button", {
                                  attrs: {
                                    name: _vm.placeOrderButtonLabel,
                                    "custom-class":
                                      "place-order--dropdown actions--container",
                                    "custom-style": _vm.isBtnDisabled
                                      ? { opacity: "60%" }
                                      : {}
                                  },
                                  on: { click: _vm.placePrepaidOrder }
                                })
                              : _vm.permissions.canCod
                              ? _c("woot-primary-button", {
                                  attrs: {
                                    name: "Place COD order",
                                    "custom-class":
                                      "place-order--dropdown actions--container",
                                    "custom-style": _vm.isBtnDisabled
                                      ? { opacity: "60%" }
                                      : {}
                                  },
                                  on: { click: _vm.placeCodOrder }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "woot-modal",
            {
              attrs: {
                show: _vm.showConfirmationModal,
                "on-close": function() {
                  return (_vm.showConfirmationModal = false)
                }
              },
              on: {
                "update:show": function($event) {
                  _vm.showConfirmationModal = $event
                }
              }
            },
            [
              _c("woot-modal-header", {
                attrs: {
                  "header-title": _vm.$t(
                    "ORDER.CREATE.PREPAID_PAYMENT.CONFIRMATION.HEADER_TITLE"
                  ),
                  "header-content": _vm.$t(
                    "ORDER.CREATE.PREPAID_PAYMENT.CONFIRMATION.HEADER_CONTENT"
                  )
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "content-box" }, [
                _c(
                  "div",
                  { staticClass: "flex-row flex-justify--end" },
                  [
                    _c("woot-primary-button", {
                      attrs: {
                        loading: _vm.prepaidLoading,
                        disabled: _vm.prepaidLoading,
                        name: "Share Link"
                      },
                      on: { click: _vm.preparePrepaidOrderData }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "woot-modal",
            {
              attrs: {
                show: _vm.showCodConfirmationModal,
                "on-close": function() {
                  return (_vm.showCodConfirmationModal = false)
                }
              },
              on: {
                "update:show": function($event) {
                  _vm.showCodConfirmationModal = $event
                }
              }
            },
            [
              _c("woot-modal-header", {
                attrs: {
                  "header-title": _vm.$t(
                    "ORDER.CREATE.CASH_ON_DELIVERY.CONFIRMATION.HEADER_TITLE"
                  ),
                  "header-content":
                    "\n          <br />\n          " +
                    _vm.$t(
                      "ORDER.CREATE.CASH_ON_DELIVERY.CONFIRMATION.HEADER_CONTENT"
                    ) +
                    " " +
                    _vm.contact.name +
                    " <br /> Amount: " +
                    _vm.accountCurrency +
                    _vm.draftOrderData.total_price
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex-row flex-justify--end content-box" },
                [
                  _c("woot-primary-button", {
                    attrs: {
                      name: _vm.$t(
                        "ORDER.CREATE.CASH_ON_DELIVERY.CONFIRMATION.BUTTON_TEXT"
                      ),
                      loading: _vm.creatingCodOrder,
                      disabled: _vm.creatingCodOrder
                    },
                    on: { click: _vm.createCodOrder }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }