var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.uiFlags.isFetchingItem
    ? _c(
        "div",
        { staticClass: "small-12 custom-scroll center-aligned" },
        [
          _c("woot-loading-state", {
            attrs: { message: "Fetching Plan details" }
          })
        ],
        1
      )
    : _c("div", [
        _vm.hideBillingSection || _vm.isOnCustomPlan
          ? _c("div", { staticClass: "column content-box billing-box" }, [
              _c("div", { staticClass: "settings-form lime-card-1dp" }, [
                _vm.hideBillingSection
                  ? _c(
                      "div",
                      {
                        staticClass: "title-h2",
                        staticStyle: { "text-transform": "uppercase" }
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("BILLING.NO_ACTIVE_PLAN_TXT")) +
                            "\n      "
                        )
                      ]
                    )
                  : _c("div", { staticClass: "flex-space-between" }, [
                      _c(
                        "div",
                        {
                          staticClass: "title-h2",
                          staticStyle: { "text-transform": "uppercase" }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("BILLING.CUSTOM_PLAN")) +
                              "\n        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "title-h1 plan-rate" }, [
                        _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.subscriptionForShopify.plan &&
                                  _vm.subscriptionForShopify.plan
                                    .currency_code == "USD"
                                  ? "$"
                                  : _vm.subscriptionForShopify.plan
                                      .currency_code
                              ) +
                              _vm._s(
                                _vm.subscriptionForShopify.plan &&
                                  _vm.subscriptionForShopify.plan.price
                              ) +
                              "/mo\n          "
                          )
                        ])
                      ])
                    ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex-space-between flex-align--start" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "body-b2 text-dark",
                        staticStyle: { "min-width": "85%" }
                      },
                      [
                        _vm.hideBillingSection
                          ? _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("BILLING.NO_ACTIVE_PLAN")) +
                                  "\n          "
                              )
                            ])
                          : _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t("BILLING.PAID_PLAN_MAIN_PAGE_TEXT", {
                                      daysLeft: _vm.daysLeft + 1,
                                      day: _vm.daysLeft > 1 ? "days" : "day",
                                      resetDate: _vm.resetDate
                                    })
                                  ) +
                                  "\n            "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t("BILLING.PLAN_START_END", {
                                      startDate: _vm.startDate,
                                      endDate: _vm.endDate
                                    })
                                  ) +
                                  "\n          "
                              )
                            ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("woot-primary-button", {
                      attrs: {
                        name: _vm.$t("BILLING.BROWSE_PLANS"),
                        "custom-class": "mg-top--large",
                        "full-width": ""
                      },
                      on: { click: _vm.showPlansPage }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex-row  mg-top" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "body-b2 text-dark" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("BILLING.LIMECHAT_TXT")) +
                      "\n      "
                  )
                ])
              ])
            ])
          : _c("div", [
              _vm.activePlanOver
                ? _c("div", { staticClass: "column content-box billing-box" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "settings-form lime-card-1dp flex-space-between",
                        staticStyle: {
                          padding: "2.4rem",
                          "align-items": "flex-start"
                        }
                      },
                      [
                        _c("div", { staticStyle: { "min-width": "60%" } }, [
                          _c(
                            "div",
                            {
                              staticClass: "title-h2",
                              staticStyle: { "text-transform": "uppercase" }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("BILLING.NO_ACTIVE_PLAN_TXT")) +
                                  "\n          "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "mg-top--small body-b2 text-dark" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("BILLING.NO_ACTIVE_PLAN")) +
                                  "\n          "
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("woot-primary-button", {
                              attrs: {
                                name: _vm.$t("BILLING.BROWSE_PLANS"),
                                "custom-class": "mg-top--large",
                                "full-width": ""
                              },
                              on: { click: _vm.showPlansPage }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ])
                : _c("div", { staticClass: "column content-box billing-box" }, [
                    _vm.freeTrialOver
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "settings-form lime-card-1dp flex-space-between",
                            staticStyle: {
                              padding: "2.4rem",
                              "align-items": "flex-start"
                            }
                          },
                          [
                            _c("div", { staticStyle: { "min-width": "60%" } }, [
                              _c(
                                "div",
                                {
                                  staticClass: "title-h2",
                                  staticStyle: { "text-transform": "uppercase" }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t("BILLING.FREE_TRIAL_OVER")
                                      ) +
                                      "\n          "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "mg-top--small body-b2 text-dark"
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t("BILLING.FREE_TRIAL_EXPIRED", {
                                          freeTrial: _vm.freeTrialEndDate
                                        })
                                      ) +
                                      "\n          "
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c("woot-primary-button", {
                                  attrs: {
                                    name: _vm.$t("BILLING.BROWSE_PLANS"),
                                    "custom-class": "mg-top--large",
                                    "full-width": ""
                                  },
                                  on: { click: _vm.showPlansPage }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticClass:
                              "settings-form lime-card-1dp flex-space-between",
                            staticStyle: {
                              padding: "2.4rem",
                              "align-items": "flex-start"
                            }
                          },
                          [
                            _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass: "title-h2",
                                  staticStyle: { "text-transform": "uppercase" }
                                },
                                [
                                  _vm.isOnFreeTrialCheck
                                    ? _c("span", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(_vm.$t("BILLING.STARTER")) +
                                            "\n            "
                                        )
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.subscriptionForShopify.plan &&
                                              _vm.subscriptionForShopify.plan.name.split(
                                                " "
                                              )[0]
                                          )
                                        )
                                      ])
                                ]
                              ),
                              _vm._v(" "),
                              _vm.isOnFreeTrialCheck
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "mg-top--small body-b2",
                                      staticStyle: { color: "#E8684A" }
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.$t(
                                              "BILLING.FREE_TRIAL_START_END",
                                              {
                                                trialStartDate:
                                                  _vm.trialStartDate,
                                                freeTrialEndDate:
                                                  _vm.freeTrialEndDate
                                              }
                                            )
                                          ) +
                                          "\n          "
                                      )
                                    ]
                                  )
                                : _vm.isOnGracePeriod
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mg-top--small title-h5 text-dark"
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.$t(
                                              "BILLING.PAID_PLAN_GRACE_PERIOD_TEXT",
                                              {
                                                startDate:
                                                  _vm.gracePeriodStartDate,
                                                endDate: _vm.gracePeriodLastDate
                                              }
                                            )
                                          ) +
                                          "\n          "
                                      )
                                    ]
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mg-top--small body-b2 text-dark"
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.$t(
                                              "BILLING.PAID_PLAN_MAIN_PAGE_TEXT",
                                              {
                                                daysLeft: _vm.daysLeft + 1,
                                                day:
                                                  _vm.daysLeft > 1
                                                    ? "days"
                                                    : "day",
                                                resetDate: _vm.resetDate
                                              }
                                            )
                                          ) +
                                          "\n            "
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.$t("BILLING.PLAN_START_END", {
                                              startDate: _vm.startDate,
                                              endDate: _vm.endDate
                                            })
                                          ) +
                                          "\n          "
                                      )
                                    ]
                                  ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "mg-top flex-row flex-row-center"
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { width: "90%" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-dark title-h5 mg-bottom--slab"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$t("BILLING.CYCLE_USAGE")
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("progress-bar", {
                                        attrs: {
                                          val: _vm.conversationTrackerValue,
                                          text: _vm.conversationTrackerText,
                                          "bar-border-radius": 8,
                                          "bg-color": "#F0F0F0",
                                          "bar-color": "#1B83AC",
                                          "text-align": "left",
                                          size: 12
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mg-left mg-top",
                                      staticStyle: {
                                        width: "20%",
                                        "margin-top": "4rem"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "subtitle-s2",
                                          staticStyle: { color: "#E8684A" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm.extraConversations) +
                                              "\n              "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", [
                                        _vm.extraConversations > 0
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                  $" +
                                                  _vm._s(_vm.extraCharges) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "BILLING.EXTRA_CHARGES"
                                                    )
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          : _vm._e()
                                      ])
                                    ]
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticStyle: { "text-align": "right" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "title-h1 plan-rate" },
                                  [
                                    _vm.isOnFreeTrialCheck ||
                                    _vm.isOnGracePeriod
                                      ? _c("span", [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.$t(
                                                  "BILLING.FREE_TRIAL_RATE"
                                                )
                                              ) +
                                              "\n            "
                                          )
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.subscriptionForShopify
                                                  .plan &&
                                                  _vm.subscriptionForShopify
                                                    .plan.currency_code == "USD"
                                                  ? "$"
                                                  : _vm.subscriptionForShopify
                                                      .plan.currency_code
                                              ) +
                                              _vm._s(
                                                _vm.subscriptionForShopify
                                                  .plan &&
                                                  _vm.subscriptionForShopify
                                                    .plan.price
                                              ) +
                                              "/mo\n            "
                                          )
                                        ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "title-h5_m text-dark" },
                                  [
                                    _vm.isOnFreeTrialCheck ||
                                    _vm.isOnGracePeriod
                                      ? _c("span", [_vm._v("150")])
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.subscriptionForShopify.plan &&
                                                _vm.subscriptionForShopify.plan
                                                  .free_conversations
                                            )
                                          )
                                        ]),
                                    _vm._v("\n            tickets"),
                                    _c("span", { staticClass: "text-light" }, [
                                      _vm._v("/month")
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "title-h6_m text-light" },
                                  [
                                    _vm.isOnFreeTrialCheck ||
                                    _vm.isOnGracePeriod
                                      ? _c("span", [
                                          _vm._v(
                                            "\n              $0.15 " +
                                              _vm._s(
                                                _vm.$t(
                                                  "BILLING.EXTRA_TICKETS_TEXT"
                                                )
                                              ) +
                                              "\n            "
                                          )
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            "\n              $" +
                                              _vm._s(
                                                _vm.subscriptionForShopify
                                                  .plan &&
                                                  _vm.subscriptionForShopify
                                                    .plan.extra_usage_rate
                                              ) +
                                              "\n              per additional ticket\n            "
                                          )
                                        ])
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.subscriptionForShopify.plan.allowed_logins
                                  ? _c(
                                      "div",
                                      { staticClass: "title-h6_m text-light" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text-dark" },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  _vm.subscriptionForShopify
                                                    .plan &&
                                                    _vm.subscriptionForShopify
                                                      .plan.allowed_logins
                                                ) +
                                                "\n              agent logins\n            "
                                            )
                                          ]
                                        ),
                                        _vm._v(
                                          "\n            /month\n          "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "title-h6_m text-light" },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(_vm.$t("BILLING.AGENT_CHARGE")) +
                                        "\n          "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("woot-primary-button", {
                                  attrs: {
                                    name: "Browse Plans",
                                    "custom-class": "mg-top--large",
                                    "full-width": ""
                                  },
                                  on: { click: _vm.showPlansPage }
                                })
                              ],
                              1
                            )
                          ]
                        ),
                    _vm._v(" "),
                    _vm.showStarterPlanCta(
                      _vm.subscriptionForShopify.trial_end
                    ) &&
                    !_vm.freeTrialOver &&
                    !_vm.isAppUninstallFlow &&
                    !_vm.isAppReinstallFlow
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "settings-form lime-card-1dp flex-space-between mg-top",
                            staticStyle: { padding: "2.4rem" }
                          },
                          [
                            _c("div", [
                              _c("div", { staticClass: "title-h2" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("BILLING.ACTIVATE_PLAN")) +
                                    "\n          "
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "body-b1 text-light mg-top--small"
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("BILLING.LAST_DAY_CTA")) +
                                      "\n          "
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mg-top  starter-plan-cta" },
                              [
                                _c("woot-primary-button", {
                                  attrs: {
                                    name: _vm.$t("BILLING.LAST_DAY_CTA_BTN")
                                  },
                                  on: { click: _vm.activateStarterPlan }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex-row  mg-top" }, [
                      _vm._m(1),
                      _vm._v(" "),
                      _c("div", { staticClass: "body-b2 text-dark" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("BILLING.LIMECHAT_TXT")) +
                            "\n        "
                        )
                      ])
                    ])
                  ])
            ])
      ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mg-right--smaller" }, [
      _c("img", {
        attrs: { src: "/brand-assets/bulb.svg", height: "20", width: "20" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mg-right--smaller" }, [
      _c("img", {
        attrs: { src: "/brand-assets/bulb.svg", height: "20", width: "20" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }