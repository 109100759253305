var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-header",
    {
      attrs: {
        "header-title": _vm.$t("BOT_CSAT.CONTROLS.FLOWS.TITLE"),
        size: "small",
        "custom-class": "grid-1-2-ratio"
      }
    },
    [
      _c(
        "div",
        { staticClass: "csat-flow-grid" },
        _vm._l(Object.keys(_vm.flowsObject), function(flow) {
          return _c(
            "label",
            {
              key: flow,
              staticClass: "container-checkbox csat-flow",
              staticStyle: { cursor: "pointer" }
            },
            [
              _c("span", {
                staticClass: "title-h5_m text-dark label-text capitalize",
                domProps: { textContent: _vm._s(_vm.formatFlowLabel(flow)) }
              }),
              _vm._v(" "),
              _c("input", {
                attrs: { type: "checkbox" },
                domProps: { value: flow, checked: _vm.flowsObject[flow] },
                on: {
                  input: function($event) {
                    var _obj
                    return _vm.$emit(
                      "updateConfig",
                      Object.assign(
                        {},
                        _vm.flowsObject,
                        ((_obj = {}),
                        (_obj[flow] = !_vm.flowsObject[flow]),
                        _obj)
                      )
                    )
                  }
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "grey-checkmark checkmark" })
            ]
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }