var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.tagName,
    _vm._g(
      _vm._b(
        {
          tag: "component",
          class: [_vm.btnClass, _vm.btnSizeClass],
          attrs: { href: _vm.to, type: _vm.type }
        },
        "component",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _vm.frontIcon
        ? _c("icons", {
            attrs: {
              name: _vm.frontIcon,
              color: _vm.computedIconColor,
              view: _vm.iconViewBox,
              size: _vm.computedIconSize,
              "show-title": false
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _vm.backIcon
        ? _c("icons", {
            attrs: {
              name: _vm.backIcon,
              color: _vm.computedIconColor,
              view: _vm.iconViewBox,
              size: _vm.computedIconSize,
              "show-title": false
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }