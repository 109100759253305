var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "animation--container" }, [
    _c(
      "div",
      { staticClass: "animation text-align--center" },
      [
        _c("lottie", {
          attrs: {
            options: _vm.defaultOptions,
            height: +_vm.height,
            width: +_vm.width
          }
        }),
        _vm._v(" "),
        _vm.message
          ? _c("span", {
              staticClass: "body-b1 text-light animation-text",
              domProps: { textContent: _vm._s(_vm.message) }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }