var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ticket-card mg-bottom--small" }, [
    _c(
      "div",
      {
        staticClass:
          "ticket-card-header flex-row flex-justify--between flex-align"
      },
      [
        _c(
          "a",
          {
            staticClass: "ticket-id title-h5",
            attrs: {
              href: _vm.ticket.ticket_url,
              target: "_blank",
              rel: "noopener noreferrer"
            }
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.ticket.project_key) +
                "-" +
                _vm._s(_vm.ticket.id) +
                "\n    "
            )
          ]
        ),
        _vm._v(" "),
        _vm.ticket.status
          ? _c("woot-chips", {
              attrs: {
                variant: "primary-small",
                title: _vm.ticket.status,
                "show-tooltip": false,
                "show-cancel-icon": false
              }
            })
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c("div", {
      staticClass: "ticket-summary line-clamp-2 body-b2 text-dark",
      domProps: { textContent: _vm._s(_vm.ticket.summary) }
    }),
    _vm._v(" "),
    _c("div", { staticClass: "ticket-footer flex-row flex-justify--between" }, [
      _c("div", { staticClass: "flex-row flex-align" }, [
        _c("img", {
          staticClass: "project-type-icon",
          attrs: { src: _vm.projectAvatar }
        }),
        _vm._v(" "),
        _c("span", {
          staticClass: "ticket-project body-b3",
          domProps: { textContent: _vm._s(_vm.ticket.project_key) }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-row flex-align" }, [
        _c("img", {
          staticClass: "issue-type-icon",
          attrs: { src: _vm.issueTypeIcon }
        }),
        _vm._v(" "),
        _c("span", {
          staticClass: "ticket-category body-b3",
          domProps: { textContent: _vm._s(_vm.ticket.issue_type) }
        })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }