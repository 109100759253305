var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: ["view-item", { active: _vm.isActive, default: _vm.isDefault }],
      on: { click: _vm.makeCurrent }
    },
    [
      _c(
        "div",
        { staticClass: "flex-row flex-align gap--small" },
        [
          _vm.isDefault
            ? _c("icons", {
                attrs: {
                  name: "flagFilled",
                  color: "orange",
                  size: "semimedium"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("span", {
            staticClass: "view-name",
            domProps: { textContent: _vm._s(_vm.data.name) }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.controls.length !== 0
        ? _c(
            "div",
            { staticClass: "view-item-control-panel" },
            [
              _vm.controls.includes("makeDefault") && !_vm.isDefault
                ? _c(
                    "woot-base-button",
                    {
                      attrs: {
                        tag: "span",
                        size: "small",
                        variant: "tertiary"
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.makeDefault($event)
                        }
                      }
                    },
                    [
                      _c("icons", {
                        attrs: {
                          name: "flag",
                          color: "grey",
                          size: "semimedium",
                          title: "make default"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.controls.includes("edit")
                ? _c(
                    "woot-base-button",
                    {
                      attrs: {
                        tag: "span",
                        size: "small",
                        variant: "tertiary"
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.edit($event)
                        }
                      }
                    },
                    [
                      _c("icons", {
                        attrs: {
                          name: "editRound",
                          color: "grey",
                          size: "semimedium",
                          title: "edit"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.controls.includes("clone")
                ? _c(
                    "woot-base-button",
                    {
                      attrs: {
                        tag: "span",
                        size: "small",
                        variant: "tertiary"
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.edit($event)
                        }
                      }
                    },
                    [
                      _c("icons", {
                        attrs: {
                          name: "copy",
                          color: "grey",
                          size: "medium",
                          title: "edit",
                          view: "-2 -3 22 22"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.controls.includes("delete") && !_vm.isDefault
                ? _c(
                    "woot-base-button",
                    {
                      attrs: {
                        tag: "span",
                        size: "small",
                        variant: "tertiary-danger"
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.deleteItem($event)
                        }
                      }
                    },
                    [
                      _c("icons", {
                        attrs: {
                          name: "bin",
                          color: "grey",
                          size: "semimedium",
                          title: "delete"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }