var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal-new",
    {
      attrs: { show: _vm.show, "on-close": _vm.onClose },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c("woot-modal-header-new", {
        attrs: { "header-title": _vm.$t("CONTACTS_PAGE.DOWNLOAD.MODAL.TITLE") }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "settings-form" },
        [
          _c("div", {
            staticClass: "title-h3 text-dark mg-bottom--large",
            domProps: {
              textContent: _vm._s(
                _vm.$t("CONTACTS_PAGE.DOWNLOAD.MODAL.HEADING")
              )
            }
          }),
          _vm._v(" "),
          _c("label", { staticClass: "formlabel flex-row" }, [
            _c("span", {
              staticClass: "title-h5 text-dark",
              domProps: {
                textContent: _vm._s(
                  _vm.$t("CONTACTS_PAGE.DOWNLOAD.DATE_RANGE.TITLE")
                )
              }
            }),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.$t("CONTACTS_PAGE.DOWNLOAD.DATE_RANGE.INFO"),
                    expression: "$t('CONTACTS_PAGE.DOWNLOAD.DATE_RANGE.INFO')"
                  }
                ],
                staticClass: "desc-text capital_c3"
              },
              [
                _c("icons", {
                  attrs: {
                    name: "warning",
                    color: "grey evenodd",
                    size: "semimedium",
                    "show-title": false
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("date-range-picker", {
            staticClass: "secondary-dropdown content-margin",
            attrs: {
              "show-range": "",
              variant: "full-width",
              value: _vm.customDateRange,
              "disable-after-next-number-of-days": 90,
              "confirm-text": _vm.$t("ANALYTICS.CUSTOM_DATE_RANGE.CONFIRM"),
              placeholder: _vm.$t("ANALYTICS.CUSTOM_DATE_RANGE.PLACEHOLDER")
            },
            on: { change: _vm.onChangeDate }
          }),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "formlabel flex-row",
              staticStyle: { "margin-bottom": "0.4rem" }
            },
            [
              _c("span", {
                staticClass: "title-h5 text-dark",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("CONTACTS_PAGE.DOWNLOAD.INBOX.TITLE")
                  )
                }
              }),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.$t("CONTACTS_PAGE.DOWNLOAD.INBOX.INFO"),
                      expression: "$t('CONTACTS_PAGE.DOWNLOAD.INBOX.INFO')"
                    }
                  ],
                  staticClass: "desc-text capital_c3"
                },
                [
                  _c("icons", {
                    attrs: {
                      name: "warning",
                      color: "grey evenodd",
                      size: "semimedium",
                      "show-title": false
                    }
                  })
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c("woot-single-selector", {
            attrs: {
              size: "large",
              "option-list": _vm.inactiveInbox,
              "default-option": _vm.defaultOption,
              "custom-style": {
                top: "4.8rem",
                left: "auto",
                height: "18rem",
                width: "100%"
              },
              "dropdown-container-style": { height: "4.4rem" }
            },
            on: { click: _vm.addInbox }
          }),
          _vm._v(" "),
          _vm.inboxList.length
            ? _c(
                "div",
                { staticClass: "medium-12 inbox-chips-container" },
                _vm._l(_vm.inboxList, function(inbox, index) {
                  return _c("woot-chips", {
                    key: inbox.id,
                    attrs: {
                      title: inbox.name,
                      variant: "secondary-small",
                      "show-cancel-icon": "",
                      "show-tooltip": false,
                      "custom-style": {
                        "border-color": _vm.getInboxMeta(inbox).hex,
                        color: _vm.getInboxMeta(inbox).hex
                      },
                      "front-icon": _vm.getInboxMeta(inbox).name,
                      "front-icon-color": _vm.getInboxMeta(inbox).color
                    },
                    on: {
                      click: function($event) {
                        return _vm.onRemoveInbox(index)
                      }
                    }
                  })
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pagination-controls" },
            [
              _c(
                "woot-base-button",
                {
                  attrs: { variant: "tertiary-danger" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.onClose($event)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("CONTACTS_PAGE.DOWNLOAD.MODAL.CANCEL_BUTTON")
                      ) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "woot-base-button",
                {
                  attrs: { variant: "primary", loading: _vm.isLoading },
                  on: { click: _vm.sendContactsOverEmail }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("CONTACTS_PAGE.DOWNLOAD.MODAL.SUBMIT_BUTTON")
                      ) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: {
            show: _vm.showConfirmationModal,
            "show-close": false,
            "small-modal": ""
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "flex-colum-center",
              staticStyle: { padding: "3.6rem" }
            },
            [
              _c(
                "span",
                [
                  _c("lottie", {
                    attrs: {
                      options: _vm.defaultAnimOptions,
                      height: 200,
                      width: 200
                    },
                    on: { animCreated: _vm.handleAnimation }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "title-h3 text-align text-light mg-top" },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.responseMessage) + "\n      "
                  )
                ]
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }