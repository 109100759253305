var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    {
      attrs: {
        show: _vm.opened,
        "custom-style": { width: "932px" },
        "on-close": _vm.onClose
      },
      on: {
        "update:show": function($event) {
          _vm.opened = $event
        }
      }
    },
    [
      _c("woot-modal-header", { attrs: { "header-title": _vm.modalTitle } }),
      _vm._v(" "),
      !_vm.loading
        ? [
            _c("div", { staticClass: "modal-body" }, [
              _c(
                "div",
                { staticClass: "template-cards row gap--two custom-scroll" },
                [
                  _vm.canCreateNew
                    ? _c(
                        "div",
                        {
                          staticClass: "create-card template-card",
                          on: {
                            click: function($event) {
                              return _vm.onClickHandleCreate({})
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "create-icon" },
                            [
                              _c("icons", {
                                attrs: {
                                  name: "plus",
                                  color: "lightestgrey",
                                  size: "large",
                                  view: "-2 -2 24 24",
                                  "show-title": false
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "title-h4 title text-dark" },
                            [_vm._v("\n            Create new\n          ")]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.templates, function(template) {
                    return _c(
                      "div",
                      {
                        key: template.id,
                        staticClass: "template-card",
                        on: {
                          click: function($event) {
                            return _vm.onClickHandleCreate(template)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "template-card--top" }, [
                          _c("img", {
                            attrs: {
                              src: template.file_url,
                              alt: "Template Image"
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "template-card--body" }, [
                          _c("div", {
                            staticClass:
                              "title title-h4 text-dark line-clamp-1",
                            domProps: { textContent: _vm._s(template.name) }
                          }),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "desc body-b3 text-light line-clamp-2",
                            domProps: {
                              textContent: _vm._s(template.description)
                            }
                          })
                        ])
                      ]
                    )
                  })
                ],
                2
              )
            ])
          ]
        : _c("loading-state", { staticStyle: { height: "40vh" } })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }