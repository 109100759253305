var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "address--container custom-scroll",
      class: {
        "address--selected": _vm.isSelected,
        "address--disable": _vm.creatingDraftOrder
      },
      on: {
        mouseover: function($event) {
          _vm.showEditOption = true
        },
        mouseleave: function($event) {
          _vm.showEditOption = false
        },
        click: function($event) {
          return _vm.$emit("updateSelectedAddress", _vm.data.id)
        }
      }
    },
    [
      _c("div", { staticClass: "flex-row flex-justify--between" }, [
        _c("span", { staticClass: "subtitle-s2 text-light" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("ORDER.ADDRESS.LABEL", { idx: _vm.index + 1 })) +
              "\n    "
          )
        ]),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass: "flex-row",
            staticStyle: { cursor: "pointer", gap: "0.8rem" }
          },
          [
            _vm.showEditOption && _vm.isSelected
              ? _c("icons", {
                  attrs: {
                    name: "editRound",
                    size: "medium",
                    color: _vm.isSelected ? "green" : "grey",
                    title: "Edit"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("icons", {
              attrs: {
                name: _vm.isSelected ? "tickFilled" : "tickRound",
                size: "medium",
                color: _vm.isSelected ? "green" : "grey",
                title: "Select"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("span", {
        staticClass: "body-b2 text-dark",
        domProps: { innerHTML: _vm._s(_vm.address) }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }