var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-base-button",
    {
      staticStyle: { "margin-left": "auto" },
      attrs: {
        size: "small",
        tag: "span",
        loading: _vm.isUpdating,
        "preserve-case": ""
      },
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.onMarkAsRead($event)
        }
      }
    },
    [
      _vm._v(
        "\n  " +
          _vm._s(_vm.$t("NOTIFICATIONS_PAGE.MARK_NOTIFICATION_READ")) +
          "\n"
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }