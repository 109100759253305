var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-integration", {
    ref: "baseIntegration",
    attrs: {
      "integration-fields": _vm.integrationFields,
      "form-length": _vm.formLength
    },
    on: { catchInput: _vm.integrationConnect }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }