var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.$refs.upload,
          expression: "$refs.upload"
        }
      ],
      staticClass: "dropzone flex-column-center",
      class:
        _vm.$refs.upload && _vm.$refs.upload.dropActive
          ? "dropzone--active"
          : null,
      style: [_vm.dropZoneHeight]
    },
    [
      _c(
        "span",
        {
          staticClass: "dropzone--title",
          staticStyle: { "margin-bottom": "0.8rem" }
        },
        [
          _vm.imgIcon
            ? _c("img", {
                staticClass: "mg-right--small",
                attrs: { src: require("assets/images/excel.svg") }
              })
            : _c("icons", {
                attrs: {
                  name: _vm.iconName,
                  size: "mediumlarge",
                  color: "warning"
                }
              })
        ],
        1
      ),
      _vm._v(" "),
      _vm.$refs.upload && !_vm.$refs.upload.dropActive
        ? _c("span", { staticClass: "dropzone--title" }, [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.title || _vm.$t("CONVERSATION.REPLYBOX.DROP_FILES.TITLE")
                ) +
                "\n  "
            )
          ])
        : _c("span", { staticClass: "dropzone--title" }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t("CONVERSATION.REPLYBOX.DROP_FILES.DROP_ACTIVE")) +
                "\n  "
            )
          ]),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "flex-column-center" },
        [
          _vm.showAttachmentButton &&
          _vm.$refs.upload &&
          !_vm.$refs.upload.dropActive
            ? _c("span", { staticClass: "dropzone--title separator" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$t("CONVERSATION.REPLYBOX.DROP_FILES.SEPERATOR_TEXT")
                    ) +
                    "\n    "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "file-upload",
            {
              ref: "upload",
              attrs: {
                multiple: _vm.multiFileSupport,
                drop: true,
                "drop-directory": _vm.dropDirectory,
                size: _vm.sizeLimit,
                accept: _vm.acceptFormat
              },
              on: { "input-file": _vm.uploadFile },
              model: {
                value: _vm.files,
                callback: function($$v) {
                  _vm.files = $$v
                },
                expression: "files"
              }
            },
            [
              _vm.showAttachmentButton &&
              _vm.$refs.upload &&
              !_vm.$refs.upload.dropActive
                ? _c("woot-primary-button", {
                    attrs: {
                      name:
                        _vm.buttonTitle ||
                        _vm.$t("CONVERSATION.REPLYBOX.DROP_FILES.BUTTON"),
                      size: "small"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }