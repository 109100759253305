var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "employee-details-container" }, [
    _c("form", { staticClass: "settings-form custom-scroll" }, [
      _c(
        "div",
        { staticClass: "content-margin" },
        [
          _c(
            "label",
            { staticClass: "formlabel flex-row flex-space-between" },
            [
              _c("div", { staticClass: "flex-row" }, [
                _c("span", {
                  staticClass: "title-h5 text-dark",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("GENERAL_SETTINGS.FORM.CONCURRENCY.ENABLED")
                    )
                  }
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.$t(
                          "GENERAL_SETTINGS.FORM.CONCURRENCY.INFO_TEXT"
                        ),
                        expression:
                          "$t('GENERAL_SETTINGS.FORM.CONCURRENCY.INFO_TEXT')"
                      }
                    ],
                    staticClass: "desc-text capital_c3"
                  },
                  [
                    _c("icons", {
                      attrs: {
                        name: "warning",
                        color: "grey evenodd",
                        size: "semimedium",
                        "show-title": false
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("woot-switch", {
                attrs: { id: Math.random(), value: _vm.concurrencyEnabledVal },
                on: { click: _vm.changeConcurrency }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "slide-up" } },
            [
              _c("woot-input", {
                staticClass: "medium-12 columns",
                class: { error: _vm.$v.concurrencyLimitVal.$error },
                attrs: {
                  type: "Number",
                  "has-error": _vm.$v.concurrencyLimitVal.$error,
                  error: _vm.$t(
                    "GENERAL_SETTINGS.FORM.CONCURRENCY.ERROR_MESSAGE"
                  ),
                  label: _vm.$t("GENERAL_SETTINGS.FORM.CONCURRENCY.LIMIT"),
                  placeholder: _vm.$t(
                    "GENERAL_SETTINGS.FORM.CONCURRENCY.LIMIT"
                  ),
                  disabled: !_vm.concurrencyEnabledVal
                },
                on: {
                  input: function($event) {
                    return _vm.$emit(
                      "update:concurrencyLimit",
                      _vm.concurrencyLimitVal
                    )
                  },
                  blur: _vm.$v.concurrencyLimitVal.$touch
                },
                model: {
                  value: _vm.concurrencyLimitVal,
                  callback: function($$v) {
                    _vm.concurrencyLimitVal =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "concurrencyLimitVal"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "medium-12 content-margin",
          staticStyle: { position: "relative" }
        },
        [
          _c(
            "div",
            {
              staticClass: "form-label body-b2 flex-row flex-justify--between"
            },
            [
              _c("label", { staticClass: "formlabel flex-row flex-align" }, [
                _c("span", {
                  staticClass: "title-h5 text-dark",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("AGENT_MGMT.EMAIL_SIGNATURE.LABEL")
                    )
                  }
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.$t("AGENT_MGMT.EMAIL_SIGNATURE.DESCRIPTION"),
                        expression:
                          "$t('AGENT_MGMT.EMAIL_SIGNATURE.DESCRIPTION')"
                      }
                    ],
                    staticClass: "mg-top--six mg-left--small"
                  },
                  [
                    _c("icons", {
                      attrs: {
                        name: "warning",
                        color: "grey evenodd",
                        size: "semimedium",
                        "show-title": false
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("woot-tab-switch", {
                attrs: {
                  "active-state": _vm.markdownModeVal,
                  "state-text": _vm.$t("AGENT_MGMT.EMAIL_SIGNATURE.MODE.TEXT"),
                  "compliment-state-text": _vm.$t(
                    "AGENT_MGMT.EMAIL_SIGNATURE.MODE.HTML"
                  )
                },
                on: { click: _vm.toggleEditor }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.markdownModeVal
            ? _c("text-editor", {
                attrs: {
                  "for-email": false,
                  height: 11.6,
                  "is-menu-bar-required": "",
                  placeholder: "Start your signature here..."
                },
                on: {
                  input: function($event) {
                    return _vm.$emit("update:emailSignMd", _vm.emailSignMdVal)
                  }
                },
                model: {
                  value: _vm.emailSignMdVal,
                  callback: function($$v) {
                    _vm.emailSignMdVal = $$v
                  },
                  expression: "emailSignMdVal"
                }
              })
            : _c("woot-input", {
                attrs: {
                  rows: "6",
                  type: "text",
                  placeholder: "Paste html code here..."
                },
                on: {
                  input: function($event) {
                    return _vm.$emit(
                      "update:emailSignHtml",
                      _vm.emailSignHtmlVal
                    )
                  }
                },
                model: {
                  value: _vm.emailSignHtmlVal,
                  callback: function($$v) {
                    _vm.emailSignHtmlVal = $$v
                  },
                  expression: "emailSignHtmlVal"
                }
              }),
          _vm._v(" "),
          _c("transition", { attrs: { name: "slide-up" } }, [
            _vm.emailSignHtmlVal && !_vm.markdownModeVal
              ? _c("div", {
                  staticClass: "signature-container",
                  domProps: { innerHTML: _vm._s(_vm.emailSignHtmlVal) }
                })
              : _vm._e()
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }