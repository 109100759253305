var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "column padding-container settings-box custom-scroll",
      staticStyle: { "padding-top": "0px" }
    },
    [
      _c("div", { staticClass: "filter-pane flex-space-between" }, [
        _c("div", { staticClass: "flex-container medium-flex-dir-row" }, [
          _c(
            "div",
            { staticClass: "mr-right" },
            [
              _c("report-date-range-selector", {
                attrs: { "default-selection": 0 },
                on: { "date-range-change": _vm.onDateRangeChange }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mr-right" },
            [
              _c("woot-single-selector", {
                attrs: {
                  variant: "secondary",
                  size: "medium",
                  "option-list": _vm.timingList,
                  "default-option": _vm.selectedTiming,
                  "show-selected-text": false,
                  "custom-style": { width: "auto" }
                },
                on: { click: _vm.onTimingChange }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mr-right" },
            [
              _c("woot-single-selector", {
                attrs: {
                  variant: "secondary",
                  size: "medium",
                  "option-list": _vm.conversationTypeList,
                  "default-option": _vm.selectedConversationType,
                  "show-selected-text": false
                },
                on: { click: _vm.onConversationTypeChange }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mr-right" },
            [
              _c("inbox-filter", {
                attrs: {
                  value: "All Inboxes",
                  "option-list": _vm.csatInboxes,
                  "exclude-inbox-types": _vm.excludedInboxTypes,
                  "toggle-filter": _vm.changeInboxId
                }
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "column content-box settings-box" }, [
        _c(
          "div",
          { staticClass: "settings-container columns custom-scroll" },
          [
            _c("csat-metrics"),
            _vm._v(" "),
            _c("csat-table", {
              attrs: {
                "page-index": _vm.pageIndex,
                "show-inbox-column": _vm.selectedInboxes.length === 0
              },
              on: { "page-change": _vm.onPageNumberChange }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }