var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.allowMultiSelect
    ? _c("woot-multi-selector", {
        attrs: {
          variant: "secondary",
          size: _vm.size,
          "default-option": _vm.value,
          "option-list": _vm.inboxesList,
          "collapse-selected": _vm.collapseSelected,
          "custom-style": _vm.customStyle,
          "show-selected-text": _vm.showSelectedText,
          "toggle-filter": _vm.toggleFilter
        }
      })
    : _c("woot-single-selector", {
        attrs: {
          variant: "secondary",
          size: _vm.size,
          "front-icon": _vm.inboxFilterIcon ? _vm.inboxFilterIcon : "inbox",
          "default-option": _vm.value,
          "option-list": _vm.inboxesList,
          "custom-style": _vm.customStyle,
          "show-selected-text": _vm.showSelectedText
        },
        on: { click: _vm.propagateClick }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }