var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scroll--panel custom-scroll" },
    [
      _c(
        "woot-modal",
        {
          attrs: { show: _vm.showConfirmationModal, "on-close": _vm.onCancel },
          on: {
            "update:show": function($event) {
              _vm.showConfirmationModal = $event
            }
          }
        },
        [
          _c("woot-modal-header", {
            attrs: {
              "header-title":
                _vm.$t("ORDER.CANCEL.TITLE") + " - " + _vm.selectedOrder.name,
              "header-content": _vm.$t("ORDER.CANCEL.CONFIRMATION")
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content-box" },
            [
              _c("label", { staticClass: "formlabel text-light" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("ORDER.CANCELLATION.TITLE")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "woot-single-selector",
                {
                  attrs: {
                    variant: "tertiary",
                    size: "large",
                    "default-option": _vm.reasonForCancellation.name,
                    "custom-button": true,
                    "custom-style": { width: "100%", left: "0.01rem" },
                    "option-list": _vm.reasonsForCancellation
                  },
                  on: { click: _vm.onSelectReason }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "agent-dropdown-button content-margin flex-row flex-align"
                    },
                    [
                      _c("span", { staticClass: "dropdown-option" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.reasonForCancellation.name) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticStyle: { "margin-left": "0.8rem" } },
                        [
                          _c("icons", {
                            attrs: {
                              name: "chevronDown",
                              size: "medium",
                              color: "green"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _vm.permissions.canRefund &&
              (!_vm.selectedOrder.is_cod ||
                !_vm.selectedOrder.shopify_financial_status.status ===
                  "pending")
                ? _c(
                    "div",
                    [
                      false
                        ? _c(
                            "div",
                            {
                              staticClass: "flex-row flex-align content-margin"
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "container-checkbox",
                                  staticStyle: {
                                    cursor: "pointer",
                                    "padding-bottom": "0.8rem"
                                  }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.shouldSendNotification,
                                        expression: "shouldSendNotification"
                                      }
                                    ],
                                    attrs: { type: "checkbox" },
                                    domProps: {
                                      value: _vm.shouldSendNotification,
                                      checked: Array.isArray(
                                        _vm.shouldSendNotification
                                      )
                                        ? _vm._i(
                                            _vm.shouldSendNotification,
                                            _vm.shouldSendNotification
                                          ) > -1
                                        : _vm.shouldSendNotification
                                    },
                                    on: {
                                      input: function($event) {
                                        _vm.shouldSendNotification = !_vm.shouldSendNotification
                                      },
                                      change: function($event) {
                                        var $$a = _vm.shouldSendNotification,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = _vm.shouldSendNotification,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.shouldSendNotification = $$a.concat(
                                                [$$v]
                                              ))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.shouldSendNotification = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.shouldSendNotification = $$c
                                        }
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "checkmark" })
                                ]
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "text-dark" }, [
                                _vm._v("Send a notification to the customer")
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("label", { staticClass: "formlabel text-light" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("ORDER.REFUND.TITLE_LIGHT")) +
                            "\n        "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex-row flex-align content-margin" },
                        _vm._l(_vm.refundingActions, function(radioButton) {
                          return _c("div", { key: radioButton.id }, [
                            _c(
                              "label",
                              {
                                staticClass: "radio-container",
                                staticStyle: { cursor: "pointer" },
                                attrs: { for: radioButton.id }
                              },
                              [
                                _c("span", { staticClass: "body-b2" }, [
                                  _vm._v(" " + _vm._s(radioButton.name))
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.shouldRefundNow,
                                      expression: "shouldRefundNow"
                                    }
                                  ],
                                  attrs: {
                                    id: radioButton.id,
                                    type: "radio",
                                    name: "refund-action"
                                  },
                                  domProps: {
                                    value: radioButton.value,
                                    checked: _vm._q(
                                      _vm.shouldRefundNow,
                                      radioButton.value
                                    )
                                  },
                                  on: {
                                    change: function($event) {
                                      _vm.shouldRefundNow = radioButton.value
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "radio-circle" })
                              ]
                            )
                          ])
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _vm.shouldRefundNow
                        ? _c("woot-input", {
                            staticClass: "refund-amt-input",
                            attrs: {
                              placeholder: _vm.$t("ORDER.REFUND.AMOUNT")
                            },
                            model: {
                              value: _vm.refundAmt,
                              callback: function($$v) {
                                _vm.refundAmt =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "refundAmt"
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.refundAmt > _vm.maximumRefundableAmt &&
                      _vm.shouldRefundNow
                        ? _c(
                            "span",
                            {
                              staticClass: "capital_c4",
                              class: { "desc-error": true }
                            },
                            [
                              _vm._v(
                                "\n          Amount should not be more than order value\n        "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _c("div", { staticStyle: { height: "16rem" } }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex-row flex-justify--end" },
                [
                  _c("woot-primary-button", {
                    attrs: {
                      name: "Yes, Cancel",
                      disabled: _vm.isCancelling,
                      loading: _vm.isCancelling
                    },
                    on: { click: _vm.onCancelOrder }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: {
            show: _vm.showRefundModal,
            "on-close": _vm.onCancelRefundModal,
            "custom-style": { "min-height": "12rem" }
          },
          on: {
            "update:show": function($event) {
              _vm.showRefundModal = $event
            }
          }
        },
        [
          _c("woot-modal-header", {
            attrs: {
              "header-title": _vm.$t("ORDER.REFUND.TITLE"),
              "header-content": _vm.$t("ORDER.REFUND.INITIATE_REFUND", {
                orderName: _vm.selectedOrder.name
              })
            }
          }),
          _vm._v(" "),
          _vm.refundTransactionsLoading
            ? _c("span", {
                staticClass: "content-box",
                domProps: { textContent: _vm._s("Please wait...") }
              })
            : !_vm.selectedOrder.is_cod && _vm.refundTransactions
            ? _c(
                "div",
                { staticClass: "content-box" },
                [
                  _c("span", {
                    staticClass: "text-dark",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("ORDER.REFUND.MAXIUMUM_REFUND", {
                          amount: _vm.refundTransactions[0].maximum_refundable
                        })
                      )
                    }
                  }),
                  _vm._v(" "),
                  _c("woot-input", {
                    staticClass: "refund-amt-input",
                    attrs: {
                      value: _vm.selectedOrder.maximum_refundable,
                      disabled: _vm.isEditedOrder,
                      label: _vm.$t("ORDER.REFUND.LABEL"),
                      placeholder: _vm.$t("ORDER.REFUND.AMOUNT")
                    },
                    model: {
                      value: _vm.refundAmt,
                      callback: function($$v) {
                        _vm.refundAmt =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "refundAmt"
                    }
                  }),
                  _vm._v(" "),
                  _vm.refundAmt > _vm.maximumRefundableAmt
                    ? _c("span", {
                        staticClass: "capital_c3 desc-error",
                        domProps: {
                          textContent: _vm._s(
                            "Amount should not be more than order value"
                          )
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "flex-row flex-justify--end",
                      staticStyle: { gap: "0.8rem" }
                    },
                    [
                      _c("woot-primary-button", {
                        attrs: {
                          name: _vm.$t("ORDER.REFUND.CONFIRMATION.REFUND"),
                          color: "red",
                          disabled:
                            _vm.isRefunding ||
                            _vm.refundAmt > _vm.maximumRefundableAmt,
                          loading: _vm.isRefunding
                        },
                        on: { click: _vm.onRefundOrder }
                      }),
                      _vm._v(" "),
                      _c("woot-secondary-button", {
                        attrs: {
                          name: _vm.$t("ORDER.REFUND.CONFIRMATION.CANCEL")
                        },
                        on: { click: _vm.onCancelRefundModal }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _c("div", { staticClass: "content-box" }, [
                _c("span", [_vm._v(_vm._s(_vm.$t("ORDER.REFUND.NOT_PREPAID")))])
              ])
        ],
        1
      ),
      _vm._v(" "),
      !_vm.transformCreateBtn
        ? _c(
            "div",
            {
              staticClass: "flex-row flex-justify--between",
              staticStyle: { gap: "0.8rem", "margin-bottom": "2.4rem" }
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.ctaTooltipContent("create"),
                      expression: "ctaTooltipContent('create')"
                    }
                  ],
                  staticClass: "flex-row",
                  staticStyle: { width: "100%" }
                },
                [
                  _c("woot-secondary-button", {
                    attrs: {
                      size: "medium",
                      name:
                        _vm.productsInCart && _vm.productsInCart.length
                          ? _vm.$t("ORDER.VIEW.TITLE")
                          : _vm.$t("ORDER.CREATE.CREATE_NEW.TITLE"),
                      "back-icon": "circleAdd",
                      "icon-color": "stroke-green",
                      "icon-hover-color": "semidarkgreen-stroke",
                      "custom-class": "create-new-order-btn",
                      "custom-style": _vm.shouldDisable
                    },
                    on: { click: _vm.onShowCreateOrder }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "create-new-order-btn--transformed",
                  staticStyle: { cursor: "pointer" },
                  attrs: { title: _vm.$t("ORDER.SEARCH") },
                  on: {
                    click: function($event) {
                      _vm.transformCreateBtn = true
                    }
                  }
                },
                [
                  _c(
                    "span",
                    [
                      _c("icons", {
                        attrs: {
                          name: "search",
                          color: "green",
                          size: "medium",
                          "custom-style": { "stroke-width": 2 },
                          "show-title": false
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ]
          )
        : _c(
            "div",
            {
              staticClass: "flex-row flex-justify--between",
              staticStyle: { gap: "0.8rem", "margin-bottom": "2.4rem" }
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.ctaTooltipContent("create"),
                      expression: "ctaTooltipContent('create')"
                    }
                  ],
                  staticClass: "flex-row"
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "create-new-order-btn--transformed",
                      staticStyle: { cursor: "pointer" },
                      style: _vm.shouldDisable,
                      attrs: { title: "New Order" },
                      on: { click: _vm.onShowCreateOrder }
                    },
                    [
                      _c("icons", {
                        attrs: {
                          name: "circleAdd",
                          color: "stroke-green",
                          size: "medium",
                          "custom-style": { "stroke-width": 2 },
                          "show-title": false
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c("search-box", {
                attrs: {
                  placeholder: _vm.$t("ORDER.SEARCH"),
                  value: _vm.search,
                  variant: true,
                  "on-search": _vm.searchOrders,
                  "custom-class": "search-box"
                },
                on: { setSearch: _vm.setSearch }
              })
            ],
            1
          ),
      _vm._v(" "),
      !_vm.uiFlags.fetchingList && _vm.orders.length === 0
        ? _c("div", { staticClass: "no-order" }, [
            _c("img", {
              attrs: {
                src: require("dashboard/assets/images/noOrder.svg"),
                alt: "No Order"
              }
            }),
            _vm._v(" "),
            _c("p", { staticClass: "no-items-error-message" }, [
              _vm._v("\n      " + _vm._s(_vm.$t("ORDER.NO_ORDERS")) + "\n    ")
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.uiFlags.fetchingList
        ? _c("woot-loading-state", {
            attrs: { message: _vm.$t("ORDER.LOADING") }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.uiFlags.fetchingList && _vm.orders.length
        ? _c(
            "div",
            _vm._l(_vm.orders, function(order, index) {
              return _c(
                "accordian-card",
                {
                  key: order.id,
                  attrs: {
                    "first-time-open": index === 0,
                    "custom-desc": true
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "desc",
                        fn: function() {
                          return [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "order--top flex-row flex-justify--between"
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "order--title title-h5",
                                    on: {
                                      click: function(e) {
                                        return _vm.openOrderHyperlink(e, order)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(order.name) +
                                        "\n          "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "share-icon",
                                    on: {
                                      click: function(e) {
                                        return _vm.openSharePopup(e, order)
                                      }
                                    }
                                  },
                                  [
                                    _c("icons", {
                                      attrs: {
                                        name: "share",
                                        color: "green",
                                        size: "semimedium"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "close",
                        fn: function() {
                          return [
                            order.shopify_order_status &&
                            order.shopify_financial_status
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "actions-list--container flex-row"
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: _vm.ctaTooltipContent(
                                              "edit",
                                              order
                                            ),
                                            expression:
                                              "ctaTooltipContent('edit', order)"
                                          }
                                        ]
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "title-h5_m action",
                                            class: {
                                              disabled: _vm.isEditActionDisable(
                                                order,
                                                "edit"
                                              )
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.onOrderActionSelected(
                                                  "edit",
                                                  order
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n              Edit\n            "
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: _vm.ctaTooltipContent(
                                              "refund",
                                              order
                                            ),
                                            expression:
                                              "ctaTooltipContent('refund', order)"
                                          }
                                        ]
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "title-h5_m action",
                                            class: {
                                              disabled: _vm.isRefundActionDisable(
                                                order,
                                                "refund"
                                              )
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.onOrderActionSelected(
                                                  "refund",
                                                  order
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n              Refund\n            "
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: _vm.ctaTooltipContent(
                                              "cancel",
                                              order
                                            ),
                                            expression:
                                              "ctaTooltipContent('cancel', order)"
                                          }
                                        ]
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "title-h5_m action is-cancel",
                                            class: {
                                              disabled: _vm.isCancelActionDisable(
                                                order,
                                                "cancel"
                                              )
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.onOrderActionSelected(
                                                  "cancel",
                                                  order
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n              Cancel\n            "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "flex-row flex-justify--start" },
                              [
                                order.shopify_order_status &&
                                order.shopify_order_status.status !== "open"
                                  ? _c(
                                      "div",
                                      { staticClass: "close-button-container" },
                                      [
                                        order.shopify_order_status
                                          ? _c(
                                              "span",
                                              {
                                                staticClass: "body-b3",
                                                style: _vm.orderStatusColors(
                                                  order.shopify_order_status
                                                    .color_code
                                                )
                                              },
                                              [
                                                _vm._v(
                                                  "\n              " +
                                                    _vm._s(
                                                      order.shopify_order_status
                                                        .title
                                                    ) +
                                                    "\n            "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                order.shopify_financial_status
                                  ? _c(
                                      "div",
                                      { staticClass: "close-button-container" },
                                      [
                                        order.shopify_financial_status
                                          ? _c(
                                              "span",
                                              {
                                                staticClass: "body-b3",
                                                style: _vm.orderStatusColors(
                                                  order.shopify_financial_status
                                                    .color_code
                                                )
                                              },
                                              [
                                                _vm._v(
                                                  "\n              " +
                                                    _vm._s(
                                                      order
                                                        .shopify_financial_status
                                                        .title
                                                    ) +
                                                    "\n            "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                order.shopify_fulfillment_status
                                  ? _c(
                                      "div",
                                      { staticClass: "close-button-container" },
                                      [
                                        order.shopify_fulfillment_status
                                          ? _c(
                                              "span",
                                              {
                                                staticClass: "body-b3",
                                                style: _vm.orderStatusColors(
                                                  order
                                                    .shopify_fulfillment_status
                                                    .color_code
                                                )
                                              },
                                              [
                                                _vm._v(
                                                  "\n              " +
                                                    _vm._s(
                                                      order
                                                        .shopify_fulfillment_status
                                                        .title
                                                    ) +
                                                    "\n            "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      { staticClass: "close-button-container" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "body-b3",
                                            style: {
                                              background: "#F6BD16",
                                              color: "#000",
                                              border: "none"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s("Unfulfilled") +
                                                "\n            "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                              ]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    true
                  )
                },
                [
                  _vm._v(" "),
                  _vm._v(" "),
                  _c("table", [
                    _c("tbody", [
                      order.last_name || order.first_name
                        ? _c(
                            "tr",
                            { staticClass: "flex flex-justify--between" },
                            [
                              order.first_name || order.last_name
                                ? _c("td", [
                                    _vm._v(
                                      "\n              Invoice Name :\n            "
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      (order.first_name || "") +
                                        " " +
                                        (order.last_name || "")
                                    ) +
                                    "\n            "
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      order.purchase_datetime || true
                        ? _c("tr", [
                            _c("td", [_vm._v("Placed On :")]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(_vm._s(_vm.timeStamp(order.created_at)))
                            ])
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  order.cart
                    ? _c("order-details", {
                        attrs: {
                          orders: order.cart.line_items,
                          "cart-data": order.cart,
                          "order-data": order,
                          "read-only": ""
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  order.shopify_fulfillment_status &&
                  order.fulfillment.length &&
                  order.fulfillment[0].tracking_url
                    ? _c(
                        "div",
                        { staticClass: "tracking-container content-margin" },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "content-label body-b2 flex-row flex-justify--between"
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("ORDER.TRACKING.TITLE")) +
                                  "\n          "
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "copy-icon",
                                  on: {
                                    click: function($event) {
                                      return _vm.onCopy(
                                        "Tracking link should be copied"
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("icons", {
                                    attrs: {
                                      name: "copy",
                                      view: "0 0 16 16",
                                      color: "grey",
                                      size: "normal"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "content-body body-b2" }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: order.fulfillment[0].tracking_url,
                                  target: "_blank",
                                  rel: "noopener noreferrer"
                                }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(order.fulfillment[0].tracking_url) +
                                    "\n          "
                                )
                              ]
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  order.shopify_shipping_address
                    ? _c(
                        "div",
                        { staticClass: "tracking-container content-margin" },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "content-label body-b2 flex-row flex-justify--between"
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("ORDER.SHIPPING.LABEL")) +
                                  "\n          "
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "copy-icon",
                                  on: {
                                    click: function($event) {
                                      _vm.onCopy(_vm.getShippingAddress(order))
                                    }
                                  }
                                },
                                [
                                  _c("icons", {
                                    attrs: {
                                      name: "copy",
                                      view: "0 0 16 16",
                                      color: "grey",
                                      size: "normal"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "content-body text-dark body-b2" },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.getShippingAddress(order)) +
                                  "\n          "
                              ),
                              _c("div", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t("ORDER.SHIPPING.PINCODE", {
                                        pincode:
                                          order.shopify_shipping_address.zip
                                      })
                                    ) +
                                    "\n          "
                                )
                              ])
                            ]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  order.shopify_shipping_address
                    ? _c(
                        "div",
                        { staticClass: "tracking-container content-margin" },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "content-label body-b2 flex-row flex-justify--between"
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("ORDER.BILLING.LABEL")) +
                                  "\n        "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "content-body text-dark body-b2" },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.$t("ORDER.BILLING.SAME_AS_SHIPPING")
                                  ) +
                                  "\n        "
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  order.order_notes
                    ? _c(
                        "div",
                        { staticClass: "tracking-container content-margin" },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "content-label body-b2 flex-row flex-justify--between"
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("ORDER.NOTES.LABEL")) +
                                  "\n          "
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "copy-icon",
                                  on: {
                                    click: function($event) {
                                      return _vm.onCopy(order.order_notes)
                                    }
                                  }
                                },
                                [
                                  _c("icons", {
                                    attrs: {
                                      name: "copy",
                                      view: "0 0 16 16",
                                      color: "grey",
                                      size: "normal"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "content-body text-dark body-b2" },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(order.order_notes) +
                                  "\n        "
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.uiFlags.fetchingList && _vm.orders.length && _vm.hasNextPage
        ? _c(
            "p",
            {
              staticClass: "clear button load-more-conversations",
              on: { click: _vm.onPageChange }
            },
            [_vm._v("\n    " + _vm._s(_vm.$t("ORDER.LOAD_MORE")) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.uiFlags.fetchingList && _vm.orders.length && !_vm.hasNextPage
        ? _c("p", { staticClass: "text-center text-muted end-of-list-text" }, [
            _vm._v("\n    " + _vm._s(_vm.$t("ORDER.LOADED")) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("woot-delete-modal", {
        attrs: {
          show: _vm.showShareModal,
          "on-close": _vm.closeSharePopup,
          "on-confirm": _vm.shareOrder,
          title: _vm.$t("ORDER.SHARE.TITLE"),
          message: _vm.shareMessage,
          "confirm-text": _vm.$t("ORDER.SHARE.CONFIRM"),
          "reject-text": _vm.$t("ORDER.SHARE.REJECT")
        },
        on: {
          "update:show": function($event) {
            _vm.showShareModal = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }