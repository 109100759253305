var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "item-container" },
    [
      _vm._l(_vm.order, function(item) {
        return _c("div", { key: item.id, staticClass: "item-body" }, [
          item.name
            ? _c("span", { staticClass: "item-body--title" }, [
                _vm._v("\n      " + _vm._s("" + item.name) + "\n    ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "item-body--sub flex-space-between" }, [
            _c("span", [_vm._v(_vm._s("Quantity " + item.quantity))]),
            _vm._v(" "),
            _c("span", {
              staticStyle: { color: "black" },
              domProps: {
                innerHTML: _vm._s(_vm.accountCurrency + " " + item.price)
              }
            })
          ])
        ])
      }),
      _vm._v(" "),
      _c("div", { staticClass: "item-pricing" }, [
        _c("div", { staticClass: "flex-space-between item-body--sub" }, [
          _c("span", [_vm._v("Shipping charges")]),
          _vm._v(" "),
          _c("span", {
            domProps: {
              innerHTML: _vm._s(_vm.accountCurrency + " " + _vm.shippingCharges)
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex-space-between item-body--sub" }, [
          _c("span", [
            _vm._v(
              _vm._s("Tax @ " + _vm.totalTax.rate + "% " + _vm.totalTax.title)
            )
          ]),
          _vm._v(" "),
          _c("span", {
            domProps: {
              innerHTML: _vm._s(_vm.accountCurrency + " " + _vm.totalTax.tax)
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex-space-between item-body--sub" }, [
          _c("span", [_vm._v("Discount")]),
          _vm._v(" "),
          _c("span", {
            domProps: {
              innerHTML: _vm._s(
                "-" + _vm.accountCurrency + " " + _vm.totalDiscount
              )
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "item-footer",
        domProps: {
          innerHTML: _vm._s(
            "Total Price " + _vm.accountCurrency + " " + _vm.amount
          )
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }