var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    _vm._l(_vm.colorArray, function(color, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "columns small-1 picker--container",
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.updateColor(color)
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "colorpicker--element",
              class: { selected: color === _vm.value }
            },
            [
              _c("span", {
                staticClass: "colorpicker",
                style: "background-color: " + color
              })
            ]
          )
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }