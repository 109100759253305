var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.imgSrc
    ? _c("div", { staticClass: "avatar-with-camera-container" }, [
        _c("div", { staticClass: "img-container" }, [
          _c("img", { attrs: { src: _vm.imgSrc } })
        ]),
        _vm._v(" "),
        _vm.showUploadButton
          ? _c(
              "div",
              { staticClass: "center-aligned" },
              [
                _c(
                  "file-upload",
                  {
                    attrs: { size: 4096 * 4096, accept: "image/*" },
                    on: { "input-filter": _vm.handleImageUpload }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "upload-photo-container" },
                      [
                        _c("icons", {
                          attrs: {
                            name: "camera",
                            color: "stroke-green",
                            view: "0 0 16 16",
                            size: "normal"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          : _vm._e()
      ])
    : _c(
        "div",
        {
          staticClass: "avatar-with-camera-container",
          class: _vm.cssClassName,
          style: [_vm.style, _vm.customStyle],
          attrs: { "aria-hidden": "true" }
        },
        [
          _c("span", { class: _vm.avatarTextClass }, [
            _vm._v(_vm._s(_vm.userInitial))
          ]),
          _vm._v(" "),
          _vm.showUploadButton
            ? _c(
                "div",
                { staticClass: "center-aligned" },
                [
                  _c(
                    "file-upload",
                    {
                      attrs: { size: 4096 * 4096, accept: "image/*" },
                      on: { "input-filter": _vm.handleImageUpload }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "upload-photo-container" },
                        [
                          _c("icons", {
                            attrs: {
                              name: "camera",
                              color: "stroke-green",
                              view: "0 0 16 16",
                              size: "normal"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }