var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "multiselect-wrap--small" },
      [
        _c("multiselect", {
          staticStyle: { "max-width": "32rem" },
          attrs: {
            "track-by": "id",
            label: "name",
            placeholder: _vm.$t("AUTOMATION.ACTION.TEAM_DROPDOWN_PLACEHOLDER"),
            multiple: true,
            "selected-label": "",
            "select-label": _vm.$t("FORMS.MULTISELECT.ENTER_TO_SELECT"),
            "deselect-label": "",
            "max-height": 160,
            options: _vm.teamsList,
            "allow-empty": ""
          },
          on: { input: _vm.updateValue },
          model: {
            value: _vm.selectedTeams,
            callback: function($$v) {
              _vm.selectedTeams = $$v
            },
            expression: "selectedTeams"
          }
        }),
        _vm._v(" "),
        _c("text-editor", {
          staticClass: "action-message",
          attrs: {
            "is-menu-bar-required": "",
            "is-format-mode": "",
            "min-height": 15,
            placeholder: _vm.$t(
              "AUTOMATION.ACTION.TEAM_MESSAGE_INPUT_PLACEHOLDER"
            )
          },
          on: { input: _vm.updateValue },
          model: {
            value: _vm.message,
            callback: function($$v) {
              _vm.message = $$v
            },
            expression: "message"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }