var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add-contact-wrapper" },
    [
      _c(
        "div",
        { staticClass: "search-contact" },
        [
          _c("search-box", {
            attrs: {
              placeholder: "Search for contact numbers here",
              "custom-class": "search-box",
              size: "medium",
              "input-type": "number",
              value: _vm.searchQuery,
              "on-search": _vm.onSearchSubmit,
              "on-enter": _vm.onAddNewContact
            },
            on: { setSearch: _vm.onInputSearch }
          }),
          _vm._v(" "),
          _c(
            "woot-base-button",
            {
              attrs: {
                variant: "secondary",
                size: "small",
                disabled: !_vm.searchQuery
              },
              on: { click: _vm.onAddNewContact }
            },
            [_vm._v("\n      ADD\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.showSearchResults
        ? _c("show-contact-results-panel", {
            attrs: {
              "inbox-type": "whatsapp",
              "is-loading": _vm.isFetchingContacts,
              contacts: _vm.filteredContacts,
              "on-select-contact": _vm.onAddContact,
              "on-add-new-contact": _vm.onAddNewContact,
              "can-load-more-results": _vm.canLoadMoreResults,
              "close-panel": function() {
                return (_vm.showSearchResults = false)
              }
            },
            on: { onPageChange: _vm.onPageChange }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedContacts.length !== 0
        ? _vm._t("selected-contacts-display-panel", null, {
            contacts: _vm.selectedContacts,
            updateContacts: _vm.onUpdateContacts
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }