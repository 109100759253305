var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showRaiseTicketSection
    ? _c("raise-ticket", {
        staticClass: "raise-ticket-section",
        on: { closeRaiseTicket: _vm.closeRaiseTicket }
      })
    : _c(
        "accordian-card",
        {
          attrs: {
            name: _vm.$t("CONTACT_PANEL.CRM.TITLE"),
            icon: "ticket",
            "always-open": "",
            "has-add-button": ""
          },
          on: { addButtonMethod: _vm.handleAddButton }
        },
        [
          _vm.canShowTickets
            ? _c("show-tickets", { key: _vm.rerenderTickets })
            : _vm._e()
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }