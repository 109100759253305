var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "views-container" },
    [
      _c("current-view-tab", {
        on: {
          showViewsManager: function($event) {
            _vm.showViewsManager = true
          }
        }
      }),
      _vm._v(" "),
      _vm.showViewsManager
        ? _c("views-manager", {
            on: {
              onChange: _vm.onChange,
              close: function($event) {
                _vm.showViewsManager = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }