import { render, staticRenderFns } from "./OdooIntegration.vue?vue&type=template&id=24411d62&"
import script from "./OdooIntegration.vue?vue&type=script&lang=js&"
export * from "./OdooIntegration.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/limechat/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('24411d62')) {
      api.createRecord('24411d62', component.options)
    } else {
      api.reload('24411d62', component.options)
    }
    module.hot.accept("./OdooIntegration.vue?vue&type=template&id=24411d62&", function () {
      api.rerender('24411d62', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/lime-ui/dashboard/routes/dashboard/settings/integrations/OdooIntegration.vue"
export default component.exports