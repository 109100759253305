var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "automation-toggler" },
    [
      _c("woot-switch", {
        attrs: { id: _vm.data.id, value: _vm.data.active },
        on: { click: _vm.toggleAutomation }
      }),
      _vm._v(" "),
      _vm.showStatusText
        ? _c("span", {
            staticClass: "title-h5_m text-dark",
            domProps: { textContent: _vm._s(_vm.currentStatus) }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }