var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "columns full-height conv-empty-state" },
    [
      _vm.uiFlags.isFetching || _vm.loadingChatList
        ? _c("woot-loading-state", {
            attrs: { message: _vm.loadingIndicatorMessage }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.uiFlags.isFetching && !_vm.loadingChatList
        ? _c("div", { staticClass: "current-chat" }, [
            !_vm.inboxesList.length
              ? _c("div", [
                  _c("img", {
                    attrs: {
                      src: require("dashboard/assets/images/inboxes.svg"),
                      alt: "No Inboxes"
                    }
                  }),
                  _vm._v(" "),
                  _vm.isAdmin
                    ? _c(
                        "span",
                        { staticClass: "empty-state-content" },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("CONVERSATION.NO_INBOX_1")) +
                              "\n        "
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "router-link",
                            {
                              staticClass: "add-inbox-route",
                              attrs: { to: _vm.newInboxURL }
                            },
                            [
                              _c("woot-primary-button", {
                                attrs: {
                                  name: _vm.$t("CONVERSATION.ADD_INBOX"),
                                  "front-icon": "add"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isAdmin
                    ? _c("span", { staticClass: "empty-state-content" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("CONVERSATION.NO_INBOX_AGENT")) +
                            "\n      "
                        )
                      ])
                    : _vm._e()
                ])
              : !_vm.allConversations.length
              ? _c("div", [
                  _c("img", {
                    attrs: {
                      src: require("dashboard/assets/images/chat.svg"),
                      alt: "No Chat"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "empty-state-content" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("CONVERSATION.NO_MESSAGE_1")) +
                        "\n      "
                    )
                  ])
                ])
              : _vm.allConversations.length && !_vm.currentChat.id
              ? _c("div", [
                  _c("img", {
                    attrs: {
                      src: require("dashboard/assets/images/chat.svg"),
                      alt: "No Chat"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "empty-state-content" }, [
                    _vm._v(_vm._s(_vm.$t("CONVERSATION.404")))
                  ])
                ])
              : _vm._e()
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }