var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "small-12 columns" },
    [
      _c("page-header", {
        attrs: {
          "header-title": _vm.$t("INBOX_MGMT.ADD.CHAT_API.TITLE"),
          "header-content": _vm.$t("INBOX_MGMT.ADD.CHAT_API.DESC")
        }
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.createChannel($event)
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "settings-sub-menu-content--larger mg-bottom--jumbo"
            },
            [
              _c("woot-input", {
                staticClass: "medium-12 columns content-margin",
                class: { error: _vm.$v.channelName.$error },
                attrs: {
                  required: "",
                  "auto-focus": "",
                  "has-error": _vm.$v.channelName.$error,
                  error: _vm.$t("INBOX_MGMT.ADD.CHAT_API.CHANNEL_NAME.ERROR"),
                  label: _vm.$t("INBOX_MGMT.ADD.CHAT_API.CHANNEL_NAME.LABEL"),
                  placeholder: _vm.$t(
                    "INBOX_MGMT.ADD.CHAT_API.CHANNEL_NAME.PLACEHOLDER"
                  )
                },
                on: { blur: _vm.$v.channelName.$touch },
                model: {
                  value: _vm.channelName,
                  callback: function($$v) {
                    _vm.channelName = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "channelName"
                }
              }),
              _vm._v(" "),
              _c("woot-input", {
                staticClass: "medium-12 columns content-margin",
                class: { error: _vm.$v.phoneNumber.$error },
                attrs: {
                  required: "",
                  "has-error": _vm.$v.phoneNumber.$error,
                  error:
                    _vm.phoneNumber === ""
                      ? _vm.$t("INBOX_MGMT.COMMON_ERROR_MESSAGE")
                      : _vm.$t("INBOX_MGMT.ADD.CHAT_API.PHONE_NUMBER.ERROR"),
                  label: _vm.$t("INBOX_MGMT.ADD.CHAT_API.PHONE_NUMBER.LABEL"),
                  placeholder: _vm.$t(
                    "INBOX_MGMT.ADD.CHAT_API.PHONE_NUMBER.PLACEHOLDER"
                  )
                },
                on: { blur: _vm.$v.phoneNumber.$touch },
                model: {
                  value: _vm.phoneNumber,
                  callback: function($$v) {
                    _vm.phoneNumber = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "phoneNumber"
                }
              }),
              _vm._v(" "),
              _c("woot-input", {
                staticClass: "medium-12 columns content-margin",
                class: { error: _vm.$v.instanceNo.$error },
                attrs: {
                  required: "",
                  "has-error": _vm.$v.instanceNo.$error,
                  error: _vm.$t("INBOX_MGMT.ADD.CHAT_API.INSTANCE_NO.ERROR"),
                  label: _vm.$t("INBOX_MGMT.ADD.CHAT_API.INSTANCE_NO.LABEL"),
                  placeholder: _vm.$t(
                    "INBOX_MGMT.ADD.CHAT_API.INSTANCE_NO.PLACEHOLDER"
                  )
                },
                on: { blur: _vm.$v.instanceNo.$touch },
                model: {
                  value: _vm.instanceNo,
                  callback: function($$v) {
                    _vm.instanceNo = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "instanceNo"
                }
              }),
              _vm._v(" "),
              _c("woot-input", {
                staticClass: "medium-12 columns content-margin",
                class: { error: _vm.$v.authToken.$error },
                attrs: {
                  required: "",
                  "has-error": _vm.$v.authToken.$error,
                  error: _vm.$t("INBOX_MGMT.ADD.CHAT_API.AUTH_TOKEN.ERROR"),
                  label: _vm.$t("INBOX_MGMT.ADD.CHAT_API.AUTH_TOKEN.LABEL"),
                  placeholder: _vm.$t(
                    "INBOX_MGMT.ADD.CHAT_API.AUTH_TOKEN.PLACEHOLDER"
                  )
                },
                on: { blur: _vm.$v.authToken.$touch },
                model: {
                  value: _vm.authToken,
                  callback: function($$v) {
                    _vm.authToken = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "authToken"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "medium-12 columns settings-sub-menu-bottom-nav" },
            [
              _c(
                "woot-base-button",
                {
                  attrs: { variant: "secondary" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return (function() {
                        return _vm.$router.go(-1)
                      })($event)
                    }
                  }
                },
                [_vm._v("\n        Back\n      ")]
              ),
              _vm._v(" "),
              _c(
                "woot-base-button",
                { attrs: { loading: _vm.uiFlags.isCreating, type: "submit" } },
                [_vm._v("\n        Next\n      ")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }