var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "automation-form" },
    [
      _c("automation-header", {
        attrs: {
          automation: _vm.automation,
          mode: _vm.mode,
          "on-show-details-modal": function() {
            return (_vm.showDetailsModal = true)
          },
          "on-update-automation-detail": _vm.onUpdateAutomationDetail,
          "on-close": _vm.onClose
        }
      }),
      _vm._v(" "),
      _c("automation-details-modal", {
        attrs: {
          opened: _vm.showDetailsModal,
          name: _vm.automation.name,
          description: _vm.automation.description
        },
        on: {
          updateDetail: _vm.onUpdateAutomationDetail,
          onClose: _vm.onCloseDetailsModal,
          onSubmit: _vm.onCloseDetailsModal
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "automation-form-wrapper" },
        [
          !_vm.isLoadingData && _vm.automation
            ? _c(
                "div",
                {
                  staticClass: "full-height",
                  staticStyle: { position: "relative" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "sections" },
                    [
                      _c("event-selector", {
                        attrs: {
                          "selected-event": _vm.automation.event_name,
                          events: _vm.eventsList
                        },
                        on: { select: _vm.onSelectEvent }
                      }),
                      _vm._v(" "),
                      _vm.automation.event_name
                        ? _c("conditions-section", {
                            attrs: {
                              conditions: _vm.automation.conditions,
                              "event-name": _vm.automation.event_name,
                              mode: _vm.mode
                            },
                            on: {
                              appendCondition: _vm.appendNewCondition,
                              resetFilter: _vm.resetFilter,
                              removeFilter: _vm.removeFilter
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.automation.event_name
                        ? _c("actions-section", {
                            attrs: {
                              actions: _vm.automation.actions,
                              "action-types": _vm.automationActionTypes,
                              files: _vm.automation.files
                            },
                            on: {
                              appendAction: _vm.appendNewAction,
                              resetAction: _vm.resetAction,
                              removeAction: _vm.removeAction
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "form-footer flex-row flex-justify--between full-width"
                    },
                    [
                      _c(
                        "woot-base-button",
                        {
                          staticClass: "modal-button",
                          attrs: { size: "small", loading: _vm.isSubmitting },
                          on: { click: _vm.submitAutomation }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.submitButtonText) +
                              "\n        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "woot-base-button",
                        {
                          staticClass: "modal-button",
                          attrs: { size: "small", variant: "secondary-danger" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.onClose($event)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t("AUTOMATION.EDIT.CANCEL_BUTTON_TEXT")
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            : _vm.isLoadingData
            ? _c("loading-state", { staticStyle: { height: "100%" } })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }