var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "accordian-container lime-card-border",
      class: _vm.customClass,
      style: _vm.customStyle,
      attrs: { id: _vm.id }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "medium-12 columns" }, [
          _c(
            "div",
            {
              staticClass: "accordian-description",
              class: _vm.id,
              on: { click: _vm.toggle }
            },
            [
              !_vm.customDesc
                ? _c(
                    "span",
                    {
                      staticClass: "flex-row-justify-center",
                      staticStyle: { overflow: "hidden" }
                    },
                    [
                      _vm.icon
                        ? _c(
                            "span",
                            { staticClass: "accordian-icon" },
                            [
                              _c("icons", {
                                attrs: {
                                  name: _vm.icon,
                                  color: "textlightgrey",
                                  view: _vm.iconView,
                                  size: "semimedium"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "text-light accordian-title text-truncate",
                          class: _vm.computedClass
                        },
                        [
                          _vm._v(
                            "\n            " + _vm._s(_vm.name) + "\n          "
                          )
                        ]
                      )
                    ]
                  )
                : _vm._t("desc"),
              _vm._v(" "),
              !_vm.alwaysOpen
                ? _c(
                    "span",
                    {
                      staticClass: "accordian-toggle",
                      class: { "rotate-icon": _vm.isPanelOpen }
                    },
                    [
                      _c("icons", {
                        attrs: {
                          name: "chevronDown",
                          color: "grey",
                          view: "0 0 24 24",
                          size: "semimedium",
                          title: !_vm.isPanelOpen ? "open" : "close"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.hasAddButton
                ? _c(
                    "span",
                    {
                      staticClass: "accordian-toggle",
                      on: {
                        click: function($event) {
                          return _vm.$emit("addButtonMethod")
                        }
                      }
                    },
                    [
                      _c("icons", {
                        attrs: {
                          name: "circleAdd",
                          color: "stroke-green",
                          view: "0 0 24 24",
                          size: "medium"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "medium-12 columns" }, [_vm._t("close")], 2)
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "toast-fade" } }, [
        _vm.isPanelOpen ? _c("div", [_vm._t("default")], 2) : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }