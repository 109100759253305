var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scroll--panel custom-scroll" },
    [
      _c("search-box", {
        attrs: {
          placeholder: _vm.$t("PRODUCTS_MGMT.SEARCH_TXT"),
          value: _vm.search,
          variant: true,
          "on-search": _vm.searchProducts
        },
        on: { setSearch: _vm.setSearch }
      }),
      _vm._v(" "),
      !_vm.uiFlags.fetchingProducts && !_vm.productList.length
        ? _c("div", { staticClass: "no-order" }, [
            _c("img", {
              attrs: {
                src: require("dashboard/assets/images/noOrder.svg"),
                alt: "No Product"
              }
            }),
            _vm._v(" "),
            _c("p", { staticClass: "no-items-error-message" }, [
              _vm._v("\n      No products found.\n    ")
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm._l(_vm.filteredProductList, function(product) {
            return _c(
              "accordian-card",
              {
                key: product.id,
                attrs: { "custom-desc": true },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "desc",
                      fn: function() {
                        return [
                          _c("div", { staticClass: "item-wrap" }, [
                            _c(
                              "div",
                              { staticClass: "card__media lime-card-1dp" },
                              [
                                _c("img", {
                                  attrs: {
                                    src: product.Image,
                                    alt: "Card image"
                                  }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "card__content" }, [
                              _c(
                                "header",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: product.name,
                                      expression: "product.name"
                                    }
                                  ],
                                  staticClass: "card__header text-truncate"
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(product.name) +
                                      "\n            "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "footer",
                                {
                                  staticClass: "card__meta flex-space-between",
                                  attrs: { role: "contentinfo" }
                                },
                                [
                                  _c("div", { staticClass: "card__price" }, [
                                    product.sale_price < product.price
                                      ? _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.accountCurrency +
                                                " " +
                                                product.sale_price
                                            )
                                          }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("span", {
                                      class: {
                                        "sale-price":
                                          product.sale_price < product.price
                                      },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.accountCurrency +
                                            " " +
                                            product.price
                                        )
                                      }
                                    })
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              product.inventory < 1 &&
                              !["not_managed", "instock"].includes(
                                product.inventory_management
                              )
                                ? _c("span", {
                                    staticClass: "out-of-stock title-h6",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("PRODUCTS_MGMT.OUT_OF_STOCK")
                                      )
                                    }
                                  })
                                : _vm._e()
                            ])
                          ])
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "close",
                      fn: function() {
                        return undefined
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              },
              [
                _vm._v(" "),
                _vm._v(" "),
                product.description
                  ? _c("p", {
                      staticClass: "card__excerpt custom-scroll",
                      domProps: { innerHTML: _vm._s(product.description) }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-button" },
                  [
                    _c("woot-secondary-button", {
                      attrs: {
                        size: "small",
                        "custom-style": {
                          "margin-left": "0.4rem",
                          width: "60%",
                          "justify-content": "center"
                        },
                        name: _vm.$t("PRODUCTS_MGMT.SHARE")
                      },
                      on: {
                        click: function($event) {
                          return _vm.openSharePopup(product)
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            )
          }),
          _vm._v(" "),
          _vm.uiFlags.fetchingProducts
            ? _c("woot-loading-state", {
                attrs: { message: "Fetching products..." }
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.uiFlags.fetchingProducts &&
          _vm.productList.length &&
          _vm.hasNextPage
            ? _c(
                "p",
                {
                  staticClass: "clear button load-more-conversations",
                  on: {
                    click: function($event) {
                      return _vm.onPageChange()
                    }
                  }
                },
                [_vm._v("\n      " + _vm._s("Load More Products") + "\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.uiFlags.fetchingProducts &&
          _vm.productList.length &&
          !_vm.hasNextPage
            ? _c(
                "p",
                { staticClass: "text-center text-muted end-of-list-text" },
                [_vm._v("\n      " + _vm._s("All Products Loaded") + "\n    ")]
              )
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c("woot-delete-modal", {
        attrs: {
          show: _vm.showShareModal,
          "on-close": _vm.closeSharePopup,
          "on-confirm": _vm.shareProduct,
          title: _vm.$t("PRODUCTS_MGMT.SHARE"),
          message: _vm.shareMessage,
          "confirm-text": "Share",
          "reject-text": "Cancel"
        },
        on: {
          "update:show": function($event) {
            _vm.showShareModal = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }