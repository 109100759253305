var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isSent
    ? _c("div", { staticClass: "message-tooltip" }, [
        !_vm.isDeleted
          ? _c("span", [
              _vm._v("\n    " + _vm._s(_vm.$t("CONVERSATION.SENT_BY")) + "\n  ")
            ])
          : _c("span", { staticClass: "message-deleted" }, [
              _vm._v(
                "\n    " + _vm._s(_vm.$t("CONVERSATION.DELETED_BY")) + "\n  "
              )
            ]),
        _vm._v(" "),
        _c("span", { staticClass: "message-tooltip--value" }, [
          _vm._v(_vm._s(_vm.name))
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }