var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip",
          value: _vm.tooltipOptions,
          expression: "tooltipOptions"
        }
      ],
      staticClass:
        "lime-card-new metrics-card flex-column flex-justify--between",
      class: { "flex-align--start gap--four": _vm.actual },
      style: _vm.customStyle
    },
    [
      _c("span", {
        staticClass: "title-h2 text-dark",
        domProps: { innerHTML: _vm._s(_vm.title) }
      }),
      _vm._v(" "),
      _c("span", {
        staticClass: "title-h5_m text-light",
        domProps: { innerHTML: _vm._s(_vm.subTitle) }
      }),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }