var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "full-height", staticStyle: { position: "relative" } },
    [
      _c("div", { staticClass: "tags-rhs-panel" }, [
        _c(
          "div",
          [
            _c("label-details", {
              attrs: {
                "tag-title": _vm.selectedTagVal.title,
                "tag-description": _vm.selectedTagVal.description,
                resource: _vm.resource
              },
              on: {
                labelDetails: _vm.onChangeLabelDetails,
                formValidity: function($event) {
                  _vm.disableSavebtn = $event
                }
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("label-heirarchy", {
        key: _vm.selectedType,
        staticStyle: { height: "calc(100vh - 60rem)" },
        attrs: {
          "label-id": _vm.selectedTagVal.id,
          "parent-id": _vm.selectedTagVal.parent_id,
          resource: _vm.selectedType
        },
        on: {
          labelHeirarchy: function($event) {
            _vm.labelHeirarchySetting = $event
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "save-btn flex-row flex-justify--end" },
        [
          _c(
            "woot-base-button",
            {
              attrs: { variant: "primary", disabled: _vm.disableSavebtn },
              on: { click: _vm.emitEditLabelEvent }
            },
            [_vm._v("\n      Save\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }