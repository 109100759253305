var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view-box columns" },
    [
      _c("settings-header", {
        attrs: {
          icon: "graph",
          "header-title": _vm.$t("ANALYTICS.HEADER.CONVERSATION"),
          "show-back-button": false
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "column content-box settings-box" }, [
        _c(
          "div",
          { staticClass: "settings-container" },
          [
            _c(
              "div",
              { staticClass: "column analytics-box analytics-panel" },
              [
                _c("woot-folder-tabs", {
                  attrs: {
                    active: _vm.currentSelection,
                    "tab-list": _vm.tabList,
                    "custom-style": { padding: "0.8rem 1.6rem 0.8rem" }
                  },
                  on: { click: _vm.changeSelection }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "analytics-container custom-scroll" },
                  [
                    _c("div", { staticClass: "graph-filter" }, [
                      _c("div", { staticClass: "flex-row-justify-center" }, [
                        _c("label", { staticClass: "lime-radio-container" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("ANALYTICS.DATE_RANGE[2].name")) +
                              "\n                "
                          ),
                          _c("input", {
                            attrs: { type: "radio", name: "radio" },
                            domProps: {
                              checked: _vm.currentDateRangeSelection
                            },
                            on: { click: _vm.changeDateSelection }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "lime-checkmark" })
                        ]),
                        _vm._v(" "),
                        _c("label", { staticClass: "lime-radio-container" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("ANALYTICS.DATE_RANGE[3].name")) +
                              "\n                "
                          ),
                          _c("input", {
                            attrs: { type: "radio", name: "radio" },
                            domProps: {
                              checked: !_vm.currentDateRangeSelection
                            },
                            on: { click: _vm.changeDateSelection }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "lime-checkmark" })
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "flex-row-justify-center dropdown-filter"
                        },
                        [
                          _vm.filterSelection
                            ? _c(
                                "selector",
                                {
                                  attrs: {
                                    "show-all": "",
                                    "custom-button": true,
                                    "custom-style": { width: "auto" },
                                    "option-list": _vm.agentList,
                                    selected: _vm.selectedAgent
                                  },
                                  on: { click: _vm.changeFilterId }
                                },
                                [
                                  _c(
                                    "button",
                                    { staticClass: "selector-button" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "button-text" },
                                        [_vm._v(_vm._s(_vm.selectedAgent))]
                                      ),
                                      _vm._v(" "),
                                      _c("icons", {
                                        attrs: {
                                          name: "chevronDown",
                                          size: "semimedium",
                                          color: "darkestgrey"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _c(
                                "selector",
                                {
                                  attrs: {
                                    "show-all": "",
                                    "custom-button": true,
                                    "custom-style": { width: "auto" },
                                    "option-list": _vm.inboxList,
                                    selected: _vm.selectedInbox
                                  },
                                  on: { click: _vm.changeFilterId }
                                },
                                [
                                  _c(
                                    "button",
                                    { staticClass: "selector-button" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "button-text" },
                                        [_vm._v(_vm._s(_vm.selectedInbox))]
                                      ),
                                      _vm._v(" "),
                                      _c("icons", {
                                        attrs: {
                                          name: "chevronDown",
                                          size: "semimedium",
                                          color: "darkestgrey"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              ),
                          _vm._v(" "),
                          _c("woot-tab-switch", {
                            attrs: {
                              "active-state": !_vm.filterSelection,
                              "state-text": _vm.$t(
                                "ANALYTICS.FILTER_SELECTION.INBOX.NAME"
                              ),
                              "compliment-state-text": _vm.$t(
                                "ANALYTICS.FILTER_SELECTION.AGENT.NAME"
                              ),
                              "color-flipped": false
                            },
                            on: {
                              click: function($event) {
                                return _vm.filterSelectionChange()
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "report-bar" },
                      [
                        _vm.accountReport.isFetching
                          ? _c("woot-loading-state", {
                              attrs: {
                                message: _vm.$t("ANALYTICS.LOADING_CHART")
                              }
                            })
                          : _c(
                              "div",
                              { staticClass: "chart-container" },
                              [
                                _vm.accountReport.data.length &&
                                _vm.currentDateRangeSelection
                                  ? _c("woot-bar", {
                                      attrs: { collection: _vm.collection }
                                    })
                                  : _vm.accountReport.data.length &&
                                    !_vm.currentDateRangeSelection
                                  ? _c("woot-line", {
                                      attrs: { collection: _vm.collection }
                                    })
                                  : _c("span", { staticClass: "empty-state" }, [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t("ANALYTICS.NO_ENOUGH_DATA")
                                          ) +
                                          "\n              "
                                      )
                                    ])
                              ],
                              1
                            )
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "settings-side-card",
              { attrs: { image: "analytics" } },
              [_c("download")],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }