import { render, staticRenderFns } from "./Text.vue?vue&type=template&id=3ef4d90e&scoped=true&"
import script from "./Text.vue?vue&type=script&lang=js&"
export * from "./Text.vue?vue&type=script&lang=js&"
import style0 from "./Text.vue?vue&type=style&index=0&id=3ef4d90e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ef4d90e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/limechat/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3ef4d90e')) {
      api.createRecord('3ef4d90e', component.options)
    } else {
      api.reload('3ef4d90e', component.options)
    }
    module.hot.accept("./Text.vue?vue&type=template&id=3ef4d90e&scoped=true&", function () {
      api.rerender('3ef4d90e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/lime-ui/dashboard/components/widgets/conversation/bubble/Text.vue"
export default component.exports