var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("section-header", { attrs: { "translation-key": "TRIGGER" } }),
      _vm._v(" "),
      _c(
        "aside",
        { staticClass: "sla-conditions" },
        [
          _vm._l(_vm.triggers, function(triggerKey) {
            return _c(
              "div",
              {
                key: triggerKey,
                staticClass: "filter",
                class: {
                  "mg-bottom--medium":
                    _vm.triggers.indexOf(triggerKey) !== _vm.triggers.length - 1
                }
              },
              [
                _c("span", [_vm._v("if")]),
                _vm._v(" "),
                _c("trigger-selector", {
                  class: "sla-conditions__trigger_selector__" + triggerKey,
                  attrs: { value: triggerKey, triggers: _vm.triggers },
                  on: {
                    click: function($event) {
                      return _vm.onSelectTrigger($event, triggerKey)
                    }
                  }
                }),
                _vm._v(" "),
                triggerKey !== "select"
                  ? [
                      _c("span", [_vm._v("is")]),
                      _vm._v(" "),
                      _c("multi-select-trigger-values", {
                        key: triggerKey,
                        staticClass: "multi-select-filter",
                        class: "multi-select-filter__" + triggerKey,
                        attrs: {
                          value: _vm.getValues(triggerKey),
                          "dropdown-values": _vm.getOptionsList(triggerKey),
                          "label-key": _vm.getLabelKey(triggerKey)
                        },
                        on: {
                          input: function($event) {
                            return _vm.handleValues($event, triggerKey)
                          }
                        }
                      })
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "woot-base-button",
                  {
                    attrs: { tag: "span", variant: "tertiary-danger" },
                    on: {
                      click: function($event) {
                        return _vm.deleteCondition(triggerKey)
                      }
                    }
                  },
                  [
                    _c("icons", {
                      attrs: {
                        name: "bin",
                        color: "red",
                        size: "semimedium",
                        "show-title": false
                      }
                    })
                  ],
                  1
                )
              ],
              2
            )
          }),
          _vm._v(" "),
          _vm.triggers.length !== 3
            ? _c(
                "div",
                { staticClass: "mg-top" },
                [
                  _c("add-filter-button", {
                    staticClass: "sla-conditions__add-filter",
                    attrs: {
                      label: _vm.$t("SLA.CREATE.SECTIONS.TRIGGER.ADD_FILTER")
                    },
                    on: { click: _vm.onAddFilter }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }