var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lime-card-border flex-column raise-ticket-container" },
    [
      _c(
        "span",
        {
          staticClass: "flex-row mg-bottom--slab navigate-back",
          on: {
            click: function($event) {
              return _vm.$emit("closeRaiseTicket")
            }
          }
        },
        [
          _c(
            "span",
            { staticClass: "mg-right--smaller" },
            [
              _c("icons", {
                attrs: {
                  name: "chevronLeft",
                  color: "green",
                  size: "semimedium",
                  "show-title": false
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("span", {
            staticClass: "text-light title-h5_m",
            domProps: {
              textContent: _vm._s(_vm.$t("INTEGRATION_SETTINGS.CRM.TITLE"))
            }
          })
        ]
      ),
      _vm._v(" "),
      _c("main-ticket-form")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }