<template>
  <span v-if="contacts.length === 0" class="text-light body-b3">
    No Contacts added, please search and add from above
  </span>
  <transition-group
    v-else
    name="menu-list"
    tag="div"
    class="selected-contact-results flex-row flex-wrap"
  >
    <woot-chips
      v-for="({ email, type }, idx) in contacts"
      :key="`${type}_${idx}`"
      :class="`CREATE_TICKET_EMAIL_${contactType}_${idx}`"
      show-cancel-icon
      :show-tooltip="false"
      variant="primary-small"
      :title="email"
      style="width: fit-content; max-width: 23.2rem;"
      @click="removeContact"
    />
  </transition-group>
</template>
<script>
export default {
  props: {
    contacts: {
      type: Array,
      default: () => [],
    },
    contactType: {
      type: String,
      default: 'TO',
    },
  },
  methods: {
    updateField(fieldName, newValue, index) {
      const updatedContacts = [...this.contacts];
      updatedContacts[index][fieldName] = newValue;
      this.$emit('updateContacts', updatedContacts);
    },
    removeContact(emailToRemove) {
      const updatedContacts = this.contacts.filter(
        contact => contact.email !== emailToRemove
      );
      this.$emit('updateContacts', updatedContacts);
    },
  },
};
</script>
