var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "device" }, [
    _c("div", { class: { screen: !_vm.onlyChatScreen } }, [
      _c("div", { staticClass: "screen-container" }, [
        _c("div", { staticClass: "chat" }, [
          _c("div", { staticClass: "chat-container" }, [
            !_vm.onlyChatScreen
              ? _c("div", { staticClass: "user-bar" }, [
                  _c(
                    "div",
                    { staticClass: "back" },
                    [
                      _c("icons", {
                        attrs: {
                          name: "chevronLeft",
                          color: "lightgrey",
                          "view-box": "0 0 30 30",
                          size: "medium"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.userAvatar
                    ? _c("div", { staticClass: "avatar" }, [
                        _c("img", { attrs: { src: _vm.userAvatar } })
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "name" }, [
                    _c("span", [_vm._v(_vm._s(_vm.name))])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "actions more" },
                    [
                      _c("icons", {
                        attrs: {
                          name: "threeDot",
                          color: "white",
                          size: "semimedium"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "actions attachment" },
                    [
                      _c("icons", {
                        attrs: {
                          name: "phone",
                          color: "white",
                          size: "semimedium"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "actions" },
                    [
                      _c("icons", {
                        attrs: {
                          name: "videoCall",
                          color: "white",
                          size: "semimedium"
                        }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "whatsapp-conversation",
                class: { expanded: _vm.onlyChatScreen },
                style: _vm.chatBoxHeight
              },
              [
                !_vm.onlyChatScreen
                  ? _c("div", { staticClass: "date" }, [
                      _c("span", { staticClass: "today body-b3" }, [
                        _vm._v("Today")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "conversation-container custom-scroll" },
                  _vm._l(_vm.messages, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "flex-column",
                        staticStyle: { "max-width": "85%" }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "message", class: item.type },
                          [
                            item.img
                              ? _c(
                                  "div",
                                  { staticClass: "img mg-bottom--smaller" },
                                  [
                                    item.src
                                      ? _c("img", { attrs: { src: item.src } })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", {
                                      staticClass: "img-placeholder"
                                    })
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.doc
                              ? _c("div", { staticClass: "flex-column" }, [
                                  _c("div", { staticClass: "doc" }),
                                  _vm._v(" "),
                                  _vm._m(0, true)
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("span", {
                              domProps: { innerHTML: _vm._s(item.value) }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "metadata" }, [
                              _c("span", {
                                staticClass: "time",
                                domProps: {
                                  textContent: _vm._s(
                                    new Date().toLocaleString("en-US", {
                                      hour: "numeric",
                                      minute: "numeric",
                                      hour12: true
                                    })
                                  )
                                }
                              }),
                              _vm._v(" "),
                              item.type == "sent"
                                ? _c(
                                    "span",
                                    { staticClass: "tick" },
                                    [
                                      _c("icons", {
                                        attrs: {
                                          name: "tick",
                                          color: "tick",
                                          view: "0 0 16 16"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        item.buttons && item.buttons.buttons
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "whatsapp-ctas-container flex-row gap--smaller",
                                staticStyle: { "flex-wrap": "wrap" }
                              },
                              _vm._l(item.buttons.buttons, function(
                                button,
                                inx
                              ) {
                                return _c(
                                  "woot-base-button",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip.bottom",
                                        value: button.url,
                                        expression: "button.url",
                                        modifiers: { bottom: true }
                                      }
                                    ],
                                    key: inx,
                                    staticClass: "whatsapp-ctas",
                                    attrs: {
                                      "icon-size": "semimedium",
                                      "icon-color": "grey",
                                      size: "small",
                                      tag: "span",
                                      variant: "tertiary",
                                      "front-icon":
                                        item.buttons.type === "call_to_action"
                                          ? "reply"
                                          : ""
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(button.title) +
                                        "\n                  "
                                    )
                                  ]
                                )
                              }),
                              1
                            )
                          : _vm._e()
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                !_vm.onlyChatScreen
                  ? _c("div", { staticClass: "conversation-compose" }, [
                      _c(
                        "div",
                        { staticClass: "emoji" },
                        [
                          _c("icons", {
                            attrs: {
                              name: "emoji",
                              color: "grey",
                              size: "medium"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("input", {
                        staticClass: "input-msg",
                        staticStyle: {
                          "border-radius": "0px !important",
                          height: "38px"
                        },
                        attrs: {
                          name: "input",
                          placeholder: "Type a message",
                          autocomplete: "off",
                          autofocus: ""
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "photo" }, [
                        _c(
                          "div",
                          { staticClass: "photo-icons" },
                          [
                            _c("icons", {
                              attrs: {
                                name: "clip",
                                color: "grey",
                                size: "medium"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "photo-icons" },
                          [
                            _c("icons", {
                              attrs: {
                                name: "camera",
                                color: "stroke-lightgrey",
                                size: "medium",
                                view: "0 0 16 16"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("button", { staticClass: "send" }, [
                        _c(
                          "div",
                          { staticClass: "circle" },
                          [
                            _c("icons", {
                              attrs: {
                                name: "mic",
                                color: "white",
                                size: "medium"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  : _vm._e()
              ]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "doc-name flex-row-center" }, [
      _c("span", [_vm._v("document.pdf")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }