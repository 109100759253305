var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "profile-container",
      on: {
        mouseenter: function() {
          return (_vm.hovering = true)
        },
        mouseleave: function() {
          return (_vm.hovering = false)
        }
      }
    },
    [
      !_vm.imgError
        ? _c("img", {
            class: { "grey-filterr": _vm.hovering },
            attrs: { src: _vm.imgSrc },
            on: { error: _vm.onImgError }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "actions-container" }, [
        _vm.action
          ? _c("span", {
              staticClass: "action-btn title-h6 text-dark",
              domProps: { textContent: _vm._s(_vm.action) },
              on: {
                click: function($event) {
                  return _vm.$emit("action")
                }
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.isDelete
          ? _c(
              "span",
              {
                staticClass: "action-btn",
                on: {
                  click: function($event) {
                    return _vm.$emit("delete")
                  }
                }
              },
              [
                _c("icons", {
                  attrs: { name: "delete", size: "semimedium", color: "red" }
                })
              ],
              1
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      !_vm.disableFileUpload
        ? _c("transition", { attrs: { name: "modal-fade" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.hovering,
                    expression: "hovering"
                  }
                ],
                staticClass: "center-aligned flex-column flex-align"
              },
              [
                _c(
                  "file-upload",
                  {
                    attrs: { size: 4096 * 4096, accept: "image/*" },
                    on: { "input-filter": _vm.handleImageUpload }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "upload-image" },
                      [
                        _c("icons", {
                          staticClass: "camera-icon",
                          attrs: { name: "camera", size: "large" }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _vm._v(" "),
                _c("span", {
                  staticClass: "title-h5 text-align--center",
                  staticStyle: { color: "#fff" },
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t(
                        "AGENT_MGMT.RHS_PANEL.SECTIONS.PROFILE_VIEWER.TITLE"
                      )
                    )
                  }
                })
              ],
              1
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }