var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "customer-ringing flex-row" }, [
    _c(
      "div",
      {
        staticClass: "avatar-container flex-column flex-align",
        staticStyle: { width: "50%" }
      },
      [
        _c("avatar", {
          attrs: {
            username: _vm.agentName,
            rounded: "",
            size: 60,
            colored: true,
            "custom-style": {
              "font-size": "2.4rem",
              "box-shadow": "none",
              border: "none"
            }
          }
        }),
        _vm._v(" "),
        !_vm.eventName.HANGUP
          ? _c("span", { staticClass: "body-b3 text-connected" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$t(
                      "INBOX_MGMT.ADD.VOICE_CHANNEL.CALLING_COMPONENT.STATES.CONNECTED"
                    )
                  ) +
                  "\n    "
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.eventName.CUSTOMER_ANSWER ||
        _vm.eventName.BRIDGE ||
        _vm.eventName.HANGUP
          ? _c("span", { staticClass: "flex-column flex-align" }, [
              _c(
                "span",
                { staticClass: "text-align--center title-h5 text-dark mt-8" },
                [_vm._v("\n        " + _vm._s(_vm.agentName) + "\n      ")]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "body-b3 text-light" }, [
                _vm._v("\n        " + _vm._s(_vm.agentRole) + "\n      ")
              ])
            ])
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "avatar-container flex-column flex-align",
        staticStyle: { width: "50%" }
      },
      [
        _vm.eventName.AGENT_ANSWER || _vm.eventName.CUSTOMER_CALL
          ? _c(
              "div",
              {
                staticClass: "outbound-ringer",
                staticStyle: { width: "145%" }
              },
              [
                _c("lottie", {
                  attrs: {
                    options: _vm.defaultOptions,
                    height: 114,
                    width: 114
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.customerName && _vm.customerName !== "New Customer"
          ? _c("avatar", {
              attrs: {
                username: _vm.customerName,
                rounded: "",
                size: 60,
                colored: true,
                "custom-style": {
                  "font-size": "2.4rem",
                  "box-shadow": "none",
                  border: "none"
                }
              }
            })
          : _c(
              "span",
              { staticClass: "user-icon" },
              [
                _c("icons", {
                  attrs: {
                    name: "user",
                    color: "primary",
                    "custom-style": { width: "5.2rem" }
                  }
                })
              ],
              1
            ),
        _vm._v(" "),
        _vm.eventName.AGENT_ANSWER
          ? _c("span", { staticClass: "body-b3 text-light mt-8" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$t(
                      "INBOX_MGMT.ADD.VOICE_CHANNEL.CALLING_COMPONENT.STATES.CONNECTED"
                    )
                  ) +
                  "\n    "
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.eventName.CUSTOMER_CALL
          ? _c("span", { staticClass: "body-b3 text-waiting mt-8" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$t(
                      "INBOX_MGMT.ADD.VOICE_CHANNEL.CALLING_COMPONENT.STATES.WAITING"
                    )
                  ) +
                  "\n    "
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.eventName.CUSTOMER_ANSWER || _vm.eventName.BRIDGE
          ? _c("span", { staticClass: "body-b3 text-connected mt-8" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$t(
                      "INBOX_MGMT.ADD.VOICE_CHANNEL.CALLING_COMPONENT.STATES.CONNECTED"
                    )
                  ) +
                  "\n    "
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.eventName.CUSTOMER_ANSWER ||
        _vm.eventName.BRIDGE ||
        _vm.eventName.HANGUP
          ? _c("span", { staticClass: "flex-column flex-align" }, [
              _c(
                "span",
                { staticClass: "text-align--center title-h5 text-dark mt-8" },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.customerName || "Customer") +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _vm.customerName
                ? _c("span", { staticClass: "body-b3 text-light" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t(
                            "INBOX_MGMT.ADD.VOICE_CHANNEL.CALLING_COMPONENT.ROLES.CUSTOMER"
                          )
                        ) +
                        "\n      "
                    )
                  ])
                : _vm._e()
            ])
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }