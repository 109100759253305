var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sla-rhs-panel custom-scroll" }, [
    _c(
      "div",
      { staticClass: "sla-details" },
      [
        _c(
          "div",
          {
            staticClass:
              "sla-name flex-row flex-align flex-wrap gap--small mg-bottom--medium"
          },
          [
            _c("div", { staticClass: "body-b2 text-light" }, [
              _vm._v("\n        Policy:\n      ")
            ]),
            _vm._v(" "),
            _c("div", {
              staticClass: "title-h4 text-dark line-clamp-1",
              domProps: { textContent: _vm._s(_vm.slaName) }
            })
          ]
        ),
        _vm._v(" "),
        _vm.slaDescription
          ? [
              _c(
                "div",
                { staticClass: "body-b2 text-light mg-bottom--smaller" },
                [_vm._v("\n        Description:\n      ")]
              ),
              _vm._v(" "),
              _c("div", {
                staticClass:
                  "sla-description body-b2 text-dark line-clamp-3 mg-bottom--medium",
                domProps: { textContent: _vm._s(_vm.slaDescription) }
              })
            ]
          : _vm._e()
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "sla-summary" }, [
      _c("div", { staticClass: "body-b2 text-light mg-bottom--small" }, [
        _vm._v("\n      Summary of the policy:\n    ")
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "body-b2 text-light",
        domProps: { innerHTML: _vm._s(_vm.slaSummary) }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }