var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "conversation-view__header" }, [
    _c("div", { staticClass: "header--title" }, [_vm._v("\n    HelpDesk\n  ")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "header--menu" },
      [
        _c("create-ticket-button", {
          staticClass: "mg-right",
          attrs: { "max-height": null }
        }),
        _vm._v(" "),
        _vm.currentRole === "administrator"
          ? [
              _c("AppSwitcher"),
              _vm._v(" "),
              _c("span", { staticClass: "header--menu__divider" })
            ]
          : _vm._e(),
        _vm._v(" "),
        _c("AccountSwitcher")
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }