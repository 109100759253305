var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "columns content-box settings-box" }, [
    _c(
      "div",
      {
        staticClass:
          "settings-container column custom-scroll lime-card-1dp mr-right"
      },
      [
        _c(
          "woot-base-button",
          {
            staticClass: "settings-button",
            attrs: { loading: _vm.isUpdating },
            on: { click: _vm.updateAccount }
          },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("GENERAL_SETTINGS.SUBMIT")) + "\n    "
            )
          ]
        ),
        _vm._v(" "),
        !_vm.uiFlags.isFetchingItem
          ? _c(
              "div",
              { staticClass: "small-12 columns" },
              [
                _c("page-header", {
                  attrs: { "header-title": "Company Account Details" }
                }),
                _vm._v(" "),
                _c(
                  "form",
                  { staticClass: "settings-sub-menu-content--larger medium-8" },
                  [
                    _c(
                      "div",
                      { staticClass: "flex-row full-width gap--normal" },
                      [
                        _c("woot-input", {
                          staticClass: "full-width content-margin",
                          class: { error: _vm.$v.name.$error },
                          attrs: {
                            required: "",
                            "has-error": _vm.$v.name.$error,
                            error: _vm.$t("GENERAL_SETTINGS.FORM.NAME.ERROR"),
                            label: _vm.$t("GENERAL_SETTINGS.FORM.NAME.LABEL"),
                            placeholder: _vm.$t(
                              "GENERAL_SETTINGS.FORM.NAME.PLACEHOLDER"
                            )
                          },
                          on: { input: _vm.$v.name.$touch },
                          model: {
                            value: _vm.name,
                            callback: function($$v) {
                              _vm.name =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "name"
                          }
                        }),
                        _vm._v(" "),
                        _c("woot-input", {
                          staticClass: "full-width content-margin",
                          class: { error: _vm.$v.websiteUrl.$error },
                          attrs: {
                            "has-error": _vm.$v.websiteUrl.$error,
                            error: _vm.$t(
                              "GENERAL_SETTINGS.FORM.WEBSITE_URL.ERROR"
                            ),
                            label: _vm.$t(
                              "GENERAL_SETTINGS.FORM.WEBSITE_URL.LABEL"
                            ),
                            placeholder: _vm.$t(
                              "GENERAL_SETTINGS.FORM.WEBSITE_URL.PLACEHOLDER"
                            )
                          },
                          on: { input: _vm.$v.websiteUrl.$touch },
                          model: {
                            value: _vm.websiteUrl,
                            callback: function($$v) {
                              _vm.websiteUrl =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "websiteUrl"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("woot-input", {
                      staticClass: "medium-12 columns content-margin",
                      class: { error: _vm.$v.currency.$error },
                      attrs: {
                        required: "",
                        "has-error": _vm.$v.currency.$error,
                        error: _vm.$t("GENERAL_SETTINGS.FORM.CURRENCY.ERROR"),
                        label: _vm.$t("GENERAL_SETTINGS.FORM.CURRENCY.LABEL"),
                        placeholder: _vm.$t(
                          "GENERAL_SETTINGS.FORM.CURRENCY.PLACEHOLDER"
                        ),
                        "help-text": _vm.$t(
                          "GENERAL_SETTINGS.FORM.CURRENCY.INFO_TEXT"
                        )
                      },
                      on: { input: _vm.$v.currency.$touch },
                      model: {
                        value: _vm.currency,
                        callback: function($$v) {
                          _vm.currency =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "currency"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "medium-12 columns" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "formlabel",
                            class: { error: _vm.$v.locale.$error }
                          },
                          [
                            _c("span", {
                              staticClass: "title-h5 text-dark",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("GENERAL_SETTINGS.FORM.LANGUAGE.LABEL")
                                )
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c("woot-single-selector", {
                          attrs: {
                            size: "large",
                            "option-list": _vm.languagesSortedByCode,
                            "custom-style": { width: "100%" },
                            "default-option": _vm.getLangName
                          },
                          on: { click: _vm.changeLanguage }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "desc-text" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t("GENERAL_SETTINGS.FORM.LANGUAGE.ERROR")
                              ) +
                              "\n          "
                          )
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.featureInboundEmailEnabled
                      ? _c("label", { staticClass: "formlabel" }, [
                          _c("span", {
                            staticClass: "title-h5 text-dark",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "GENERAL_SETTINGS.FORM.FEATURES.INBOUND_EMAIL_ENABLED"
                                )
                              )
                            }
                          })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.featureCustomDomainEmailEnabled
                      ? _c("label", { staticClass: "formlabel" }, [
                          _c("span", {
                            staticClass: "title-h5 text-dark",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "GENERAL_SETTINGS.FORM.FEATURES.CUSTOM_EMAIL_DOMAIN_ENABLED"
                                )
                              )
                            }
                          })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.featureCustomDomainEmailEnabled
                      ? _c("woot-input", {
                          staticClass: "medium-12 columns content-margin",
                          attrs: {
                            label: _vm.$t("GENERAL_SETTINGS.FORM.DOMAIN.LABEL"),
                            placeholder: _vm.$t(
                              "GENERAL_SETTINGS.FORM.DOMAIN.PLACEHOLDER"
                            )
                          },
                          model: {
                            value: _vm.domain,
                            callback: function($$v) {
                              _vm.domain =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "domain"
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.featureCustomDomainEmailEnabled
                      ? _c("woot-input", {
                          staticClass: "medium-12 columns content-margin",
                          attrs: {
                            label: _vm.$t(
                              "GENERAL_SETTINGS.FORM.SUPPORT_EMAIL.LABEL"
                            ),
                            placeholder: _vm.$t(
                              "GENERAL_SETTINGS.FORM.SUPPORT_EMAIL.PLACEHOLDER"
                            )
                          },
                          model: {
                            value: _vm.supportEmail,
                            callback: function($$v) {
                              _vm.supportEmail =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "supportEmail"
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "page-header",
                      {
                        attrs: {
                          "header-title": _vm.$t(
                            "GENERAL_SETTINGS.FORM.HIDE_ALL_TAB.TITLE"
                          ),
                          size: "small"
                        }
                      },
                      [
                        _c("woot-switch", {
                          attrs: { id: Math.random(), value: _vm.hideAllTab },
                          on: {
                            click: function() {
                              return (_vm.hideAllTab = !_vm.hideAllTab)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "page-header",
                      {
                        attrs: {
                          "header-title": _vm.$t(
                            "GENERAL_SETTINGS.FORM.HIDE_QUEUED_TAB.TITLE"
                          ),
                          size: "small"
                        }
                      },
                      [
                        _c("woot-switch", {
                          attrs: {
                            id: Math.random(),
                            value: _vm.hideQueuedTab
                          },
                          on: {
                            click: function() {
                              return (_vm.hideQueuedTab = !_vm.hideQueuedTab)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "page-header",
                      {
                        attrs: {
                          "header-title": _vm.$t(
                            "GENERAL_SETTINGS.FORM.HIDE_BOT_TICKETS.TITLE"
                          ),
                          size: "small"
                        }
                      },
                      [
                        _c("woot-switch", {
                          attrs: {
                            id: "hide-bot-switch",
                            value: _vm.hideBotConversations
                          },
                          on: {
                            click: function() {
                              return (_vm.hideBotConversations = !_vm.hideBotConversations)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "page-header",
                      {
                        attrs: {
                          "header-title": _vm.$t(
                            "GENERAL_SETTINGS.FORM.ENFORCE_TAGGING.TITLE"
                          ),
                          size: "small"
                        }
                      },
                      [
                        _c("woot-switch", {
                          attrs: {
                            id: "enforce-tagging",
                            value: _vm.enforceTagging
                          },
                          on: {
                            click: function() {
                              return (_vm.enforceTagging = !_vm.enforceTagging)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "page-header",
                      {
                        attrs: {
                          "header-title": _vm.$t(
                            "GENERAL_SETTINGS.FORM.HIDE_OUT_OF_STOCK.TITLE"
                          ),
                          size: "small"
                        }
                      },
                      [
                        _c("woot-switch", {
                          attrs: {
                            id: "hide-out-of-stock",
                            value: _vm.hideOutOfStock
                          },
                          on: {
                            click: function() {
                              return (_vm.hideOutOfStock = !_vm.hideOutOfStock)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "page-header",
                      {
                        attrs: {
                          "header-title": _vm.$t(
                            "GENERAL_SETTINGS.FORM.APPLY_PII_MASK.TITLE"
                          ),
                          size: "small"
                        }
                      },
                      [
                        _c("woot-switch", {
                          attrs: {
                            id: "apply-pii-mask",
                            value: _vm.applyPiiMask
                          },
                          on: {
                            click: function() {
                              return (_vm.applyPiiMask = !_vm.applyPiiMask)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "current-version body-b3 text-light" },
                  [
                    _c("div", [
                      _vm._v(_vm._s("v" + _vm.globalConfig.appVersion))
                    ]),
                    _vm._v(" "),
                    _vm.hasAnUpdateAvailable && _vm.globalConfig.displayManifest
                      ? _c("div", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.useInstallationName(
                                  _vm.$t("GENERAL_SETTINGS.UPDATE_CHATWOOT", {
                                    latestChatwootVersion:
                                      _vm.latestChatwootVersion
                                  })
                                )
                              ) +
                              "\n        "
                          )
                        ])
                      : _vm._e()
                  ]
                )
              ],
              1
            )
          : _c("loading-state")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }