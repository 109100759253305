var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "text-dark flex-row title-h1" }, [
    _vm.hours !== "00"
      ? _c("span", { staticClass: "hours" }, [_vm._v(_vm._s(_vm.hours) + ":")])
      : _vm._e(),
    _vm._v(" "),
    _c("span", { staticClass: "minutes" }, [_vm._v(_vm._s(_vm.minutes) + ":")]),
    _vm._v(" "),
    _c("span", { staticClass: "seconds" }, [_vm._v(_vm._s(_vm.seconds))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }