var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        show: _vm.show,
        "show-close": false,
        "custom-style": { "max-width": "50rem" }
      },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c("woot-modal-header", {
        attrs: { "header-title": _vm.title, "header-content": _vm.message }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal-footer delete-item" },
        [
          _c("woot-tertiary-button", {
            attrs: {
              name: _vm.rejectText,
              size: "small",
              "custom-style": { color: "#FF422E" }
            },
            on: { click: _vm.onClose }
          }),
          _vm._v(" "),
          _vm.confirmText
            ? _c("woot-primary-button", {
                attrs: {
                  "front-icon": "tickRound",
                  size: "small",
                  name: _vm.confirmText
                },
                on: { click: _vm.onConfirm }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }