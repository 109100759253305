var render = function(_h, _vm) {
  var _c = _vm._c
  return _c("span", { staticClass: "custom-icons" }, [
    _c(
      "svg",
      _vm._g(
        _vm._b(
          {
            staticClass: "icon",
            class: _vm.$options.methods.getStyleClasses(_vm.props),
            style: _vm.props.customStyle,
            attrs: {
              viewBox: _vm.props.view,
              "text-anchor": "middle",
              role: "img"
            }
          },
          "svg",
          _vm.data.attrs,
          false
        ),
        _vm.listeners
      ),
      [
        _vm.props.showTitle
          ? _c("title", [_vm._v(_vm._s(_vm.props.title || _vm.props.name))])
          : _vm._e(),
        _vm._v(" "),
        _vm.props.desc
          ? _c("desc", [_vm._v(_vm._s(_vm.props.desc))])
          : _vm._e(),
        _vm._v(" "),
        _c("path", { attrs: { d: _vm.$options.methods.getPath(_vm.props) } }),
        _vm._v(" "),
        _vm.props.layerFill || _vm.props.layerStroke
          ? _c("path", {
              attrs: {
                d: _vm.$options.methods.getPathLayer(_vm.props),
                fill: _vm.props.layerFill,
                stroke: _vm.props.layerStroke
              }
            })
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }