var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isDraggable
    ? _c(
        "div",
        { staticClass: "list-bottom" },
        [
          _c("span", {
            staticClass: "title-h6_m text-dark",
            domProps: { textContent: _vm._s(_vm.$t("SLA.LIST.BOTTOM.TITLE")) }
          }),
          _vm._v(" "),
          _c(
            "woot-base-button",
            {
              attrs: { variant: "secondary", size: "small" },
              on: { click: _vm.onClickReorder }
            },
            [
              _vm._v(
                "\n    " +
                  _vm._s(_vm.$t("SLA.LIST.BOTTOM.CTA.REORDER")) +
                  "\n  "
              )
            ]
          )
        ],
        1
      )
    : _c(
        "div",
        {
          staticClass:
            "mg-top--small flex-row flex-align flex-justify--end gap--small"
        },
        [
          _c(
            "woot-base-button",
            {
              attrs: { variant: "tertiary-danger", size: "small" },
              on: { click: _vm.onCancel }
            },
            [
              _vm._v(
                "\n    " + _vm._s(_vm.$t("SLA.LIST.BOTTOM.CTA.CANCEL")) + "\n  "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "woot-base-button",
            { attrs: { size: "small" }, on: { click: _vm.onSaveOrder } },
            [
              _vm._v(
                "\n    " + _vm._s(_vm.$t("SLA.LIST.BOTTOM.CTA.SAVE")) + "\n  "
              )
            ]
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }