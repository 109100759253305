import { render, staticRenderFns } from "./ZeroState.vue?vue&type=template&id=7609e494&scoped=true&"
import script from "./ZeroState.vue?vue&type=script&lang=js&"
export * from "./ZeroState.vue?vue&type=script&lang=js&"
import style0 from "./ZeroState.vue?vue&type=style&index=0&id=7609e494&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7609e494",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/limechat/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7609e494')) {
      api.createRecord('7609e494', component.options)
    } else {
      api.reload('7609e494', component.options)
    }
    module.hot.accept("./ZeroState.vue?vue&type=template&id=7609e494&scoped=true&", function () {
      api.rerender('7609e494', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/lime-ui/dashboard/routes/dashboard/settings/ticketAssignment/components/ZeroState.vue"
export default component.exports