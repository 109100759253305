var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Header", {
    staticClass: "mg-bottom--slab",
    attrs: {
      name: _vm.automation.name,
      description: _vm.automation.description,
      "name-placeholder": _vm.$t("AUTOMATION.ADD.FORM.NAME.PLACEHOLDER"),
      "description-placeholder": _vm.$t(
        "AUTOMATION.ADD.FORM.DESC.LONG_PLACEHOLDER"
      ),
      "on-click": _vm.onShowDetailsModal,
      "on-back": _vm.onClose
    },
    scopedSlots: _vm._u([
      {
        key: "right-section",
        fn: function() {
          return [
            _vm.isEditMode && _vm.automation.id
              ? _c(
                  "div",
                  { staticClass: "flex-row gap--small flex-align--end" },
                  [
                    _c("test-automation", {
                      attrs: { "automation-test-data": _vm.automationTestData },
                      on: {
                        updateAutomationTestData: _vm.onUpdateAutomationDetail
                      }
                    }),
                    _vm._v(" "),
                    _c("toggle-automation-rule", {
                      staticClass: "automation-toggler--secondary",
                      attrs: { data: _vm.automation, "show-status-text": "" },
                      on: {
                        onToggle: function(ref) {
                          var active = ref.active

                          return _vm.onUpdateAutomationDetail({
                            active: active
                          })
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }