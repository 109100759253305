var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column padding-container settings-box custom-scroll" },
    [
      _c(
        "div",
        { staticClass: "filter-pane" },
        [
          _c("date-range-selector", {
            attrs: { "default-selection": 2 },
            on: { "date-range-change": _vm.onDateRangeChange }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.fetchingSalesData
        ? _c(
            "div",
            { staticClass: "metrics-card-container" },
            _vm._l(_vm.metricsCount, function(item, index) {
              return _c("metrics-card-skeleton", { key: index })
            }),
            1
          )
        : _c(
            "div",
            { staticClass: "metrics-card-container" },
            _vm._l(_vm.metricsCount, function(item, index) {
              return _c("metrics-card", {
                key: index,
                attrs: {
                  title: item.title,
                  "sub-title": item.sub,
                  actual: item.actual
                }
              })
            }),
            1
          ),
      _vm._v(" "),
      _vm.fetchingSalesData
        ? _c("line-chart-skeleton", { attrs: { "chart-height": "16rem" } })
        : _c(
            "div",
            { staticClass: "lime-card-1dp medium-12" },
            [
              _c("div", { staticClass: "flex-space-between" }, [
                _c("div"),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex-row flex-align flex-justify--between" },
                  [
                    _c("woot-single-selector", {
                      attrs: {
                        "option-list": _vm.options,
                        "default-option": _vm.selectedChart.name,
                        "custom-style": { "z-index": "10000" },
                        "show-selected-text": false,
                        "front-icon": "filterHollow"
                      },
                      on: { click: _vm.changeChart }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("line-chart", {
                attrs: {
                  series: _vm.selectedChartData,
                  categories: _vm.xAxis,
                  colors: _vm.SALES_LINE_CHART_COLOR,
                  "show-marker": false,
                  "toggle-data": false,
                  "highlight-data": false
                }
              })
            ],
            1
          ),
      _vm._v(" "),
      _vm.fetchingSalesData
        ? _c("pie-chart-skeleton", { attrs: { "chart-height": "20rem" } })
        : _c(
            "div",
            {
              staticClass: "lime-card-1dp mg-top mg-bottom",
              staticStyle: { width: "50%", height: "40rem" }
            },
            [
              _c("pie-chart", {
                attrs: {
                  title: "Influenced Revenue Breakdown",
                  series: _vm.influencedSalesBreakdownValues,
                  colors: _vm.INFLUENCED_SALES_PIE_CHART_COLORS,
                  labels: _vm.influencedSalesBreakdownLabels,
                  height: 350,
                  width: "100%",
                  "legend-position": "bottom",
                  "legend-offset-x": 0,
                  "legend-offset-y": 0,
                  "marker-offset-x": 0,
                  "market-offset-y": 0,
                  "chart-offset-x": -10,
                  "chart-offset-y": 10
                }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }