var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    { staticClass: "fav--button", on: { click: _vm.onClick } },
    [
      _vm.isFav
        ? _c("icons", {
            attrs: {
              name: _vm.icon,
              color: _vm.active,
              view: _vm.view,
              size: _vm.size
            }
          })
        : _c("icons", { attrs: { name: "starStroke", size: _vm.size } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }