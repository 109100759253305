var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("form-section-header", {
        attrs: {
          heading: _vm.$t("CREATE_TICKET.FORM.SECTIONS.ADD_EMAIL.HEADING"),
          "sub-heading": _vm.$t(
            "CREATE_TICKET.FORM.SECTIONS.ADD_EMAIL.SUB_HEADING"
          ),
          icon: "ticket"
        }
      }),
      _vm._v(" "),
      _c("add-email", {
        on: {
          selectedContacts: function($event) {
            return _vm.$emit("selectedContactsTo", $event)
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "flex-row flex-justify--end",
          staticStyle: { "max-width": "49rem", margin: "0 4.8rem" }
        },
        [
          !_vm.showCC
            ? _c(
                "woot-base-button",
                {
                  staticStyle: { border: "0", "margin-bottom": "0.8rem" },
                  attrs: { size: "small", variant: "secondary", tag: "span" },
                  on: {
                    click: function($event) {
                      _vm.showCC = !_vm.showCC
                    }
                  }
                },
                [
                  _c("span", { staticClass: "button-small text-blue" }, [
                    _vm._v("CC")
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.showBCC
            ? _c(
                "woot-base-button",
                {
                  staticStyle: { border: "0", "margin-bottom": "0.8rem" },
                  attrs: { size: "small", variant: "secondary", tag: "span" },
                  on: {
                    click: function($event) {
                      _vm.showBCC = !_vm.showBCC
                    }
                  }
                },
                [
                  _c("span", { staticClass: "button-small text-blue" }, [
                    _vm._v("BCC")
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.showCC
        ? _c("add-email", {
            attrs: { type: "CC" },
            on: {
              selectedContacts: function($event) {
                return _vm.$emit("selectedContactsCC", $event)
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showBCC
        ? _c("add-email", {
            attrs: { type: "BCC" },
            on: {
              selectedContacts: function($event) {
                return _vm.$emit("selectedContactsBCC", $event)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }