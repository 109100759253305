var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "columns content-box settings-box" }, [
    _c(
      "div",
      {
        staticClass:
          "settings-container column custom-scroll lime-card-1dp mr-right"
      },
      [
        _c(
          "div",
          { staticClass: "small-12 columns" },
          [
            _c(
              "page-header",
              {
                attrs: {
                  "header-title": _vm.$t("DEEP_SHOPIFY.TITLE"),
                  "header-content": _vm.$t("DEEP_SHOPIFY.SUB_TITLE")
                },
                scopedSlots: _vm._u([
                  {
                    key: "icon",
                    fn: function() {
                      return [
                        _c("div", { staticClass: "crm-image-container" }, [
                          _c("div", { staticClass: "centered-image" }, [
                            _c("img", {
                              attrs: {
                                src:
                                  "/dashboard/images/integrations/shopify.svg"
                              }
                            })
                          ])
                        ])
                      ]
                    },
                    proxy: true
                  }
                ])
              },
              [
                _vm._v(" "),
                _c("reference-box", {
                  attrs: {
                    to: _vm.documentation.shopify,
                    content: "Know more about Shopify Integration",
                    type: "document"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "shopify-settings settings-sub-menu-content--larger flex-column"
              },
              [
                _vm._l(Object.keys(_vm.controls), function(control) {
                  return _c(
                    "div",
                    { key: _vm.controls[control].id },
                    [
                      _c(
                        "page-header",
                        {
                          attrs: {
                            "header-title": _vm.$t(
                              "DEEP_SHOPIFY.CONTROLS." +
                                _vm.controls[control].name +
                                ".TITLE"
                            ),
                            "header-content": _vm.$t(
                              "DEEP_SHOPIFY.CONTROLS." +
                                _vm.controls[control].name +
                                ".DESC"
                            ),
                            "show-border": !_vm.getOtherSettings(control),
                            size: "small"
                          }
                        },
                        [
                          _c("woot-switch", {
                            attrs: { id: _vm.controls[control].id },
                            on: {
                              click: function($event) {
                                return _vm.toggleSelectedControl(control)
                              }
                            },
                            model: {
                              value: _vm.controls[control].value,
                              callback: function($$v) {
                                _vm.$set(_vm.controls[control], "value", $$v)
                              },
                              expression: "controls[control].value"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.getOtherSettings(control) &&
                      _vm.controls[control].value
                        ? [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "text-light body-b2 other-settings-title"
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "DEEP_SHOPIFY.CONTROLS." +
                                          _vm.controls[control].name +
                                          ".OTHER_SETTINGS.DESC"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "other-settings flex-row" },
                              _vm._l(
                                Object.keys(_vm.getOtherSettings(control)),
                                function(subControl) {
                                  return _c(
                                    "div",
                                    {
                                      key: _vm.getOtherSettings(control)[
                                        subControl
                                      ].id,
                                      staticClass: "flex-row flex-align--start",
                                      staticStyle: { cursor: "pointer" }
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "container-checkbox",
                                          class: {
                                            disabled:
                                              !_vm.canAccessPrepaid &&
                                              subControl === "prepaidEnabled"
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "body-b2 label-text"
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "DEEP_SHOPIFY.CONTROLS." +
                                                        _vm.controls[control]
                                                          .name +
                                                        ".OTHER_SETTINGS." +
                                                        _vm.getOtherSettings(
                                                          control
                                                        )[subControl].name
                                                    )
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.selectedSubControls,
                                                expression:
                                                  "selectedSubControls"
                                              }
                                            ],
                                            attrs: { type: "checkbox" },
                                            domProps: {
                                              value: subControl,
                                              checked: Array.isArray(
                                                _vm.selectedSubControls
                                              )
                                                ? _vm._i(
                                                    _vm.selectedSubControls,
                                                    subControl
                                                  ) > -1
                                                : _vm.selectedSubControls
                                            },
                                            on: {
                                              input: function(e) {
                                                return _vm.handleSubControl(
                                                  e,
                                                  control
                                                )
                                              },
                                              change: function($event) {
                                                var $$a =
                                                    _vm.selectedSubControls,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = subControl,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      (_vm.selectedSubControls = $$a.concat(
                                                        [$$v]
                                                      ))
                                                  } else {
                                                    $$i > -1 &&
                                                      (_vm.selectedSubControls = $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        ))
                                                  }
                                                } else {
                                                  _vm.selectedSubControls = $$c
                                                }
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("span", {
                                            staticClass: "checkmark"
                                          })
                                        ]
                                      ),
                                      _vm._v(" "),
                                      !_vm.canAccessPrepaid &&
                                      subControl === "prepaidEnabled"
                                        ? _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "tooltip",
                                                  rawName: "v-tooltip",
                                                  value:
                                                    "Enable Razorpay Integration to use this feature.",
                                                  expression:
                                                    "\n                    'Enable Razorpay Integration to use this feature.'\n                  "
                                                }
                                              ],
                                              staticClass: "warning-icon"
                                            },
                                            [
                                              _c("icons", {
                                                attrs: {
                                                  name: "error",
                                                  color: "warning",
                                                  size: "normal",
                                                  "show-title": false
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                            _vm._v(" "),
                            _c("hr")
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass:
                    "title-h5 text-dark mg-top--medium mg-bottom--small",
                  domProps: {
                    textContent: _vm._s(
                      "Mandatory fields for order creation or updation"
                    )
                  }
                }),
                _vm._v(" "),
                _c("woot-single-selector", {
                  attrs: {
                    "default-option": _vm.selectedOptionName,
                    "option-list": _vm.actionsList,
                    "show-selected-text": false
                  },
                  on: { click: _vm.onActionSelected }
                }),
                _vm._v(" "),
                _c(
                  "woot-base-button",
                  {
                    staticClass: "settings-button",
                    attrs: { name: "Save" },
                    on: { click: _vm.updateControls }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("GENERAL_SETTINGS.SUBMIT")) +
                        "\n        "
                    )
                  ]
                )
              ],
              2
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }