import { render, staticRenderFns } from "./Facebook.vue?vue&type=template&id=e706f128&scoped=true&"
import script from "./Facebook.vue?vue&type=script&lang=js&"
export * from "./Facebook.vue?vue&type=script&lang=js&"
import style0 from "./Facebook.vue?vue&type=style&index=0&id=e706f128&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e706f128",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/limechat/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e706f128')) {
      api.createRecord('e706f128', component.options)
    } else {
      api.reload('e706f128', component.options)
    }
    module.hot.accept("./Facebook.vue?vue&type=template&id=e706f128&scoped=true&", function () {
      api.rerender('e706f128', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/lime-ui/dashboard/routes/dashboard/settings/inbox/channels/Facebook.vue"
export default component.exports