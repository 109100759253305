var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "columns notification--page lime-card-1dp custom-scroll" },
    [
      _c(
        "div",
        { staticClass: "notification--content medium-12" },
        [
          _c("List", {
            attrs: {
              notifications: _vm.records,
              "is-loading": _vm.uiFlags.isFetching,
              "on-click-notification": _vm.openConversation
            }
          }),
          _vm._v(" "),
          _c("table-footer", {
            attrs: {
              "on-page-change": _vm.onPageChange,
              "current-page": Number(_vm.meta.currentPage),
              "total-count": _vm.meta.count
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }