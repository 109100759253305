var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "columns content-box settings-box" }, [
    _c(
      "div",
      {
        staticClass:
          "settings-container column custom-scroll lime-card-1dp mr-right"
      },
      [
        _c(
          "div",
          { staticClass: "small-12 columns" },
          [
            _c("page-header", {
              attrs: {
                "header-title": _vm.$t("INTEGRATION_SETTINGS.HEADER"),
                "header-content": _vm.$t("INTEGRATION_SETTINGS.SUB_HEADER")
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "channels" }, [
              _c("div", {
                staticClass: "title-h6 text-dark mg-bottom--small",
                domProps: {
                  textContent: _vm._s(_vm.$t("INTEGRATION_SETTINGS.CRM.TITLE"))
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.AVAILABLE_CRMS, function(_, crm) {
                  return _c(
                    "div",
                    { key: crm.ID },
                    [
                      _c("integration", {
                        attrs: {
                          "integration-id": _vm.$t(
                            "INTEGRATION_SETTINGS.CRM.INTEGRATIONS." +
                              crm +
                              ".ID"
                          ),
                          "integration-logo": _vm.$t(
                            "INTEGRATION_SETTINGS.CRM.INTEGRATIONS." +
                              crm +
                              ".THUMBNAIL"
                          ),
                          "integration-name": _vm.$t(
                            "INTEGRATION_SETTINGS.CRM.INTEGRATIONS." +
                              crm +
                              ".TITLE"
                          ),
                          "integration-action": _vm.$t(
                            "INTEGRATION_SETTINGS.CRM.INTEGRATIONS." +
                              crm +
                              ".ACTION"
                          ),
                          "integration-enabled": _vm.isCrmEnabled(crm)
                        }
                      })
                    ],
                    1
                  )
                }),
                0
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "channels" }, [
              _c("div", {
                staticClass: "title-h6 text-dark mg-bottom--small",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("INTEGRATION_SETTINGS.LOGISTICS.TITLE")
                  )
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row" },
                _vm._l(
                  _vm.$t("INTEGRATION_SETTINGS.LOGISTICS.INTEGRATIONS"),
                  function(crm) {
                    return _c(
                      "div",
                      { key: crm.ID },
                      [
                        _c("integration", {
                          attrs: {
                            "integration-id": crm.ID,
                            "integration-logo": crm.THUMBNAIL,
                            "integration-name": crm.TITLE,
                            "integration-action": crm.ACTION,
                            "integration-enabled": _vm.isLogisticsEnabled(crm)
                          }
                        })
                      ],
                      1
                    )
                  }
                ),
                0
              )
            ]),
            _vm._v(" "),
            _vm.isDeepShopifyEnabled
              ? _c("div", { staticClass: "channels" }, [
                  _c("div", {
                    staticClass: "title-h6 text-dark mg-bottom--small",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("INTEGRATION_SETTINGS.STOREFRONT")
                      )
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _c("integration", {
                        attrs: {
                          "integration-id": "shopify-permissions",
                          "integration-logo": "shopify.svg",
                          "integration-name": "Shopify",
                          "integration-action": "shopify-permissions",
                          "integration-enabled": true
                        }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "channels" }, [
              _c("div", {
                staticClass: "title-h6 text-dark mg-bottom--small",
                domProps: {
                  textContent: _vm._s(_vm.$t("INTEGRATION_SETTINGS.OTHERS"))
                }
              }),
              _vm._v(" "),
              _vm.integrationsList.length
                ? _c(
                    "div",
                    { staticClass: "row" },
                    _vm._l(_vm.integrationsList, function(item) {
                      return _c(
                        "div",
                        { key: item.id },
                        [
                          _c("integration", {
                            attrs: {
                              "integration-id": item.id,
                              "integration-logo": item.logo,
                              "integration-name": item.name,
                              "integration-description": item.description,
                              "integration-action": item.id
                            }
                          })
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _c("div", {
                    staticClass:
                      "row integration-card-skeleton skeleton-animation"
                  })
            ])
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }