var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scroll--panel custom-scroll" },
    [
      _vm._l(_vm.selectors, function(selector) {
        return _c(
          "section",
          { key: selector.id, attrs: { id: selector.id, name: selector.name } },
          [
            _c("span", {
              staticClass: "text-light subtitle-s2 mg-right mg-left--smaller",
              domProps: { textContent: _vm._s(selector.title) }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "multiselect-box" },
              [
                _c(
                  "woot-single-selector",
                  {
                    attrs: {
                      variant: "tertiary",
                      size: "large",
                      "default-option": selector.defaultOption,
                      "custom-button": true,
                      "custom-style": { width: "100%", left: "0.01rem" },
                      "option-list": selector.list
                    },
                    on: { click: selector.action }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "agent-dropdown-button" },
                      [
                        _c("img", {
                          style: selector.iconStyle,
                          attrs: { src: selector.iconSrc }
                        }),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "dropdown-option",
                          style: selector.labelStyle,
                          domProps: {
                            textContent: _vm._s(selector.defaultOption)
                          }
                        }),
                        _vm._v(" "),
                        _c("icons", {
                          attrs: {
                            name: "chevronDown",
                            size: "medium",
                            color: "green"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          ]
        )
      }),
      _vm._v(" "),
      _c("sla-status", {
        key: "sla_" + _vm.currentChat.id,
        staticClass: "mg-bottom mg-left--slab",
        attrs: { chat: _vm.currentChat }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isSectionHidden,
              expression: "!isSectionHidden"
            }
          ]
        },
        [
          _c(
            "accordian-card",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.contact.id,
                  expression: "contact.id"
                }
              ],
              attrs: {
                id: "previous-tickets-accordion",
                name: _vm.$t("CONTACT_PANEL.CONVERSATIONS.TITLE"),
                icon: "history",
                "always-open": true,
                "icon-view": "0 0 24 24"
              }
            },
            [
              _c("contact-conversations", {
                attrs: {
                  "contact-id": _vm.contact.id,
                  "conversation-id": _vm.conversationId,
                  "parent-component-name": "TicketOverview"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "accordian-card",
            {
              attrs: {
                id: "custom-fields-accordion",
                name: "Custom Properties",
                icon: "editRound",
                "icon-view": "0 0 24 24",
                "has-add-button": false,
                "always-open": ""
              }
            },
            [
              _c("custom-fields", {
                attrs: {
                  "inbox-id": _vm.currentInboxId,
                  "conversation-id": _vm.conversationId
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "accordian-card",
            {
              attrs: {
                id: "add-tags-accordion",
                name: _vm.$t("CONTACT_PANEL.TAGS.LABEL"),
                icon: "hashFilled",
                "icon-view": "0 0 24 24",
                "always-open": ""
              }
            },
            [
              _c("conversation-labels", {
                attrs: {
                  "conversation-id": _vm.conversationId,
                  "selected-labels": _vm.selectedConvLabels,
                  "show-add-button": ""
                },
                on: { updateLabels: _vm.UpdateConvLabels }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "accordian-card",
            {
              attrs: {
                id: "add-tags-accordion",
                name: _vm.$t("CONTACT_PANEL.CONTACT_TAGS.LABEL"),
                icon: "hashFilled",
                "icon-view": "0 0 24 24",
                "always-open": ""
              }
            },
            [
              _c("contact-labels", {
                key: _vm.contactId,
                attrs: {
                  "contact-id": _vm.contactId,
                  "contact-labels": _vm.contactLabels,
                  "show-add-button": ""
                },
                on: { updateLabels: _vm.UpdateContactLabels }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.hasContactAttributes
            ? _c(
                "accordian-card",
                {
                  attrs: {
                    name: "Custom Attributes",
                    icon: "hashFilled",
                    "icon-view": "0 0 24 24",
                    "always-open": ""
                  }
                },
                [
                  _c("contact-custom-attributes", {
                    attrs: {
                      "custom-attributes": _vm.contact.custom_attributes
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("crm-tickets", {
        key: _vm.conversationId,
        on: {
          raiseTicketSectionOpened: function($event) {
            _vm.isSectionHidden = true
          },
          raiseTicketSectionClosed: function($event) {
            _vm.isSectionHidden = false
          }
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }