var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sla-list-container" },
    [
      _c("div", { staticClass: "header" }, [
        _c("span", {
          staticClass: "title-h4 text-dark",
          domProps: {
            textContent: _vm._s(_vm.$t("SLA.LIST.TABLE_HEADER.NAME"))
          }
        }),
        _vm._v(" "),
        _c("span", {
          staticClass: "title-h4 text-dark",
          domProps: {
            textContent: _vm._s(_vm.$t("SLA.LIST.TABLE_HEADER.ACTION"))
          }
        })
      ]),
      _vm._v(" "),
      !_vm.uiFlags.isFetching && _vm.slasList.length !== 0
        ? [
            _c(
              _vm.componentName,
              {
                tag: "component",
                staticClass: "draggable-list",
                model: {
                  value: _vm.slasList,
                  callback: function($$v) {
                    _vm.slasList = $$v
                  },
                  expression: "slasList"
                }
              },
              _vm._l(_vm.slasList, function(sla) {
                return _c("list-item", {
                  key: sla.id,
                  attrs: {
                    active: _vm.selectedSla.id === sla.id,
                    data: sla,
                    "is-draggable": _vm.isDraggable
                  },
                  on: {
                    toggle: _vm.onToggle,
                    clone: _vm.onClone,
                    delete: _vm.onDelete,
                    edit: _vm.onEdit,
                    view: _vm.onView
                  }
                })
              }),
              1
            )
          ]
        : _vm.uiFlags.isFetching
        ? _c("loading-state", { staticClass: "draggable-list" })
        : _c("zero-state", {
            on: {
              onCreate: function($event) {
                return _vm.$emit("onCreate")
              }
            }
          }),
      _vm._v(" "),
      _vm.slasList.length !== 0
        ? _c("list-bottom", {
            attrs: { "is-draggable": _vm.isDraggable },
            on: {
              onReorder: _vm.onClickReorder,
              onSaveOrder: _vm.onSaveOrder,
              onCancel: _vm.onCancel
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("delete-modal", {
        attrs: { show: _vm.showDeleteModal, "selected-sla": _vm.selectedSla },
        on: {
          close: function($event) {
            _vm.showDeleteModal = false
          },
          delete: _vm.onConfirmDeletion
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }