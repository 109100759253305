var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "conversation",
      class: {
        active: _vm.isActiveChat,
        "unread-chat": _vm.hasUnread
      },
      style: _vm.customStyle
    },
    [
      _vm.showSelectOption
        ? _c(
            "div",
            { staticClass: "more-action" },
            [
              _c("woot-single-selector", {
                attrs: {
                  variant: "tertiary",
                  "custom-style": {
                    top: "2.4rem",
                    width: "auto",
                    left: "auto",
                    right: "0.8rem",
                    borderRadius: "0.8rem"
                  },
                  "option-list": _vm.getActionList,
                  icon: "threeDot",
                  "show-selected-text": false,
                  "show-option-name": false
                },
                on: {
                  click: _vm.toggleAction,
                  "dropdown-opened": _vm.handleDropdownOpened
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.selection
        ? _c("div", { staticClass: "selected--container" }, [
            _c("label", { staticClass: "container-checkbox" }, [
              _c("input", {
                staticClass: "selected--checkbox",
                attrs: { type: "checkbox" },
                domProps: { checked: _vm.selected },
                on: {
                  input: function($event) {
                    return _vm.toggleAction({ id: "select" })
                  }
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "checkmark" })
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "conversation--details columns",
          class: { "selection--cards": _vm.selection },
          style: _vm.customCardStyle,
          on: {
            click: function($event) {
              return _vm.cardClick(_vm.chat)
            }
          }
        },
        [
          _c("div", { staticClass: "conversation--top" }, [
            _c(
              "span",
              { staticClass: "title-h5 text-dark conversation--user flex-row" },
              [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.inboxTooltip,
                        expression: "inboxTooltip"
                      }
                    ]
                  },
                  [
                    _c("icons", {
                      attrs: {
                        name: _vm.computedInboxClass.name,
                        color: _vm.computedInboxClass.color + " evenodd",
                        "show-title": false,
                        size: "semimedium"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("account-pii-mask-wrapper", {
                  attrs: {
                    "mask-type": _vm.maskType,
                    "pii-data": _vm.currentContact.name,
                    "unmask-action": "piiMask/logUnmaskEvent",
                    "unmask-payload": _vm.unmaskRequestPayload
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var data = ref.data
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "text-truncate",
                              staticStyle: { "max-width": "15rem" },
                              attrs: { title: data }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(data) +
                                  "\n            "
                              )
                            ]
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _vm.showSlaBreachStatus
                  ? _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.getSlaBreachStatus(),
                            expression: "getSlaBreachStatus()"
                          }
                        ],
                        key: _vm.refreshSlaStatus,
                        staticClass: "jiggle"
                      },
                      [
                        _c("icons", {
                          attrs: {
                            name: "bell",
                            color: "red",
                            "show-title": false,
                            size: "semimedium"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.conversationForwardedBy
                  ? _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value:
                              _vm.$t("CONVERSATION.FORWARDED_BY") +
                              _vm.conversationForwardedBy,
                            expression:
                              "\n            $t('CONVERSATION.FORWARDED_BY') + conversationForwardedBy\n          "
                          }
                        ]
                      },
                      [
                        _c("icons", {
                          attrs: {
                            name: "forward",
                            color: "stroke-blue",
                            size: "semimedium",
                            "show-title": false
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "conversation--meta" }, [
              _c("span", { staticClass: "timestamp body-b3 line-clamp-1" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.dynamicTime(_vm.chat.timestamp)) +
                    "\n        "
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _vm.lastMessageInChat
            ? _c("div", { staticClass: "conversation--middle" }, [
                _vm.lastMessageInChat.content
                  ? _c(
                      "span",
                      { staticClass: "flex-row" },
                      [
                        _vm.messageByAgent &&
                        _vm.inboxInfo.channel_type !== "Channel::Email"
                          ? _c("icons", {
                              attrs: { name: "replyFilled", color: "grey" }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", {
                          staticClass:
                            "conversation--message body-b2 text-light",
                          domProps: { innerHTML: _vm._s(_vm.parsedLastMessage) }
                        })
                      ],
                      1
                    )
                  : _vm.lastMessageInChat.attachments
                  ? _c(
                      "span",
                      { staticClass: "flex-row" },
                      [
                        _vm.getIcon !== "file"
                          ? _c("icons", {
                              attrs: { name: _vm.getIcon, color: "grey" }
                            })
                          : _c("i", { staticClass: "ion-document-text" }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "conversation--message body-b2",
                            staticStyle: { "margin-left": "4px" }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  this.$t(_vm.attachmentIconKey + ".CONTENT")
                                ) +
                                "\n        "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm.lastMessageInChat.content_type
                  ? _c(
                      "span",
                      {
                        staticClass: "flex-row",
                        staticStyle: { "max-width": "85%" }
                      },
                      [
                        _c("icons", { attrs: { name: "bot", color: "grey" } }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "conversation--message body-b2" },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.lastMessageInChat.content_type.replace(
                                    "_",
                                    " "
                                  )
                                ) +
                                "\n        "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _c("span", [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("CHAT_LIST.NO_CONTENT")) +
                          "\n      "
                      )
                    ]),
                _vm._v(" "),
                _c("span", { staticClass: "flex-space-between" }, [
                  _vm.showAssigneeName
                    ? _c("span", { staticClass: "assignee--label" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.getChatAssignee) +
                            "\n        "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", { staticClass: "flex-row" }, [
                    _vm.isConvStarred
                      ? _c(
                          "span",
                          { staticClass: "mg-left--smaller mg-top--micro" },
                          [
                            _c("icons", {
                              attrs: {
                                name: "star",
                                color: "warningyellow",
                                size: "medium"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", [
                      _vm.hasUnread
                        ? _c(
                            "span",
                            { staticClass: "unread", style: _vm.unreadColor },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.unreadCount > 99
                                      ? "99+"
                                      : _vm.unreadCount
                                  ) +
                                  "\n            "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.hasUnread && (_vm.isConvAlert || _vm.isConvNew)
                        ? _c(
                            "span",
                            { staticClass: "unread", style: _vm.unreadColor },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s("") +
                                  "\n            "
                              )
                            ]
                          )
                        : _vm._e()
                    ])
                  ])
                ])
              ])
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }