var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "column padding-container settings-box custom-scroll",
      staticStyle: { "padding-top": "0px" }
    },
    [
      _c("div", { staticClass: "filter-pane" }, [
        _c(
          "div",
          {
            staticClass: "flex-space-between",
            staticStyle: { "margin-top": "0.5rem" }
          },
          [
            _c(
              "div",
              { staticClass: "flex-row-justify-center analytics-filters" },
              [
                _c("date-range-selector", {
                  attrs: { "default-selection": 2 },
                  on: { "date-range-change": _vm.onDateRangeChange }
                }),
                _vm._v(" "),
                _c("inbox-filter", {
                  attrs: {
                    value: "All Inboxes",
                    "allow-multi-select": true,
                    "exclude-inbox-types": _vm.excludedInboxTypes,
                    "toggle-filter": _vm.changeInboxId
                  },
                  on: { "selected-option": _vm.logSelectedInbox }
                }),
                _vm._v(" "),
                _c("tags-filter", {
                  attrs: {
                    value: "All Tags",
                    "toggle-filter": _vm.changeLabelId
                  },
                  on: { "selected-option": _vm.logSelectedTag }
                }),
                _vm._v(" "),
                _c("woot-single-selector", {
                  attrs: {
                    variant: "secondary",
                    "option-list": _vm.agentList,
                    "default-option": _vm.selectedAgent,
                    "custom-style": { width: "auto" },
                    "show-selected-text": false
                  },
                  on: { click: _vm.changeAgentId }
                }),
                _vm._v(" "),
                _c("woot-single-selector", {
                  attrs: {
                    variant: "secondary",
                    size: "medium",
                    disabled: false,
                    "option-list": _vm.optionList,
                    "default-option": _vm.selectedWorkingHour,
                    "show-selected-text": false,
                    "custom-style": { width: "auto" }
                  },
                  on: { click: _vm.changeWorkingHourType }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("woot-primary-button", {
                  attrs: { name: "Performance Report", "front-icon": "email" },
                  on: { click: _vm.download }
                })
              ],
              1
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "column settings-box custom-scroll",
          staticStyle: { "padding-top": "0px" }
        },
        [
          _c(
            "div",
            { staticClass: "settings-container columns custom-scroll" },
            [
              _vm.metricCardUIFlag
                ? _c(
                    "div",
                    { staticClass: "metrics-card-container" },
                    _vm._l(_vm.metricsCount, function(item, index) {
                      return _c("metrics-card-skeleton", { key: index })
                    }),
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "metrics-card-container" },
                    _vm._l(_vm.metricsCount, function(item, index) {
                      return _c("metrics-card", {
                        key: index,
                        attrs: {
                          title: item.title,
                          "sub-title": item.sub,
                          actual: item.actual
                        }
                      })
                    }),
                    1
                  ),
              _vm._v(" "),
              _vm.periodicChartUIFlag
                ? _c("div", [_c("line-chart-skeleton")], 1)
                : _c("div", { staticClass: "medium-12 bottom-container" }, [
                    _c("div", { staticClass: "lime-card-1dp medium-12" }, [
                      _c(
                        "div",
                        { staticStyle: { position: "relative" } },
                        [
                          _c("span", { staticClass: "table-title" }, [
                            _vm._v("Periodic Agent Performance")
                          ]),
                          _vm._v(" "),
                          _c("line-chart", {
                            attrs: {
                              series: _vm.chartSeries,
                              categories: _vm.chartCategories,
                              colors: _vm.chartColors,
                              "toolbar-enable": false,
                              "label-append": "Min"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
              _vm._v(" "),
              _vm.meanTableUIFlag
                ? _c("div", [_c("table-skeleton")], 1)
                : _c(
                    "div",
                    { staticClass: "medium-12 bottom-container" },
                    [
                      _c("woot-table", {
                        staticClass: "lime-card-1dp",
                        attrs: {
                          title: "Agents Performance (Mean)",
                          "header-list": _vm.$t(
                            "ANALYTICS.PERFORMANCE_SUMMARY_HEADER"
                          ),
                          "table-data": _vm.formattedTableData,
                          "is-sortable": true,
                          "column-width-one": "20%",
                          "column-width-two": "15%"
                        }
                      })
                    ],
                    1
                  ),
              _vm._v(" "),
              _vm.slaTableUIFlag
                ? _c("div", [_c("table-skeleton")], 1)
                : _c(
                    "div",
                    { staticClass: "medium-12 bottom-container" },
                    [
                      _c("woot-table", {
                        staticClass: "lime-card-1dp",
                        attrs: {
                          title: "SLA Violation (%)",
                          "header-list": _vm.$t(
                            "ANALYTICS.AGENT_SLA_TABLE_HEADER"
                          ),
                          "table-data": _vm.formattedSlaTableData,
                          "is-sortable": true,
                          "column-width-one": "20%",
                          "column-width-two": "15%"
                        }
                      })
                    ],
                    1
                  ),
              _vm._v(" "),
              _vm.msgBlockUIFlag
                ? _c(
                    "div",
                    { staticClass: "bottom-container" },
                    [_c("line-chart-skeleton")],
                    1
                  )
                : _c("div", { staticClass: "medium-12 bottom-container" }, [
                    _c(
                      "div",
                      { staticClass: "lime-card-1dp medium-12" },
                      [
                        _c("column-chart", {
                          attrs: {
                            title: "Total Message Blocks sent by Agents",
                            series: _vm.messageBlockSeries,
                            categories: _vm.messageBlockCategories,
                            colors: _vm.messageBlockColor,
                            "has-title": true,
                            "border-radius": 10,
                            "column-width": "30%",
                            "chart-offset-y": 30
                          }
                        })
                      ],
                      1
                    )
                  ]),
              _vm._v(" "),
              _vm.hourlyUIFlag
                ? _c(
                    "div",
                    [
                      _c("line-chart-skeleton"),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex-space-between" }, [
                        _c(
                          "div",
                          { staticStyle: { width: "49%" } },
                          [_c("line-chart-skeleton")],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { width: "49%" } },
                          [_c("line-chart-skeleton")],
                          1
                        )
                      ])
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "lime-card-1dp medium-12 bottom-container" },
                    [
                      _vm._m(0),
                      _vm._v(" "),
                      _c("column-chart", {
                        attrs: {
                          series: _vm.columnChartSeries,
                          categories: _vm.columnChartCategories,
                          colors: _vm.columnChartColors
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "chart-card mg-bottom--medium" },
                        [
                          _c("div", { staticClass: "flex-space-between" }, [
                            _c(
                              "span",
                              { staticClass: "table-title smaller-font" },
                              [
                                _vm._v(
                                  "\n              First Response Time\n            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value:
                                      "First Response time indicates the time taken by the agent to respond to a chat",
                                    expression:
                                      "\n                'First Response time indicates the time taken by the agent to respond to a chat'\n              "
                                  }
                                ]
                              },
                              [
                                _c("icons", {
                                  attrs: {
                                    name: "info",
                                    size: "medium",
                                    color: "grey"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("line-chart", {
                            staticClass: "chartOverflowVisible",
                            attrs: {
                              series: _vm.firstResponseTimeSeries,
                              categories: _vm.firstResponseTimeCategories,
                              colors: _vm.firstResponseChartColors,
                              "toolbar-enable": false,
                              "show-tooltip": true,
                              width: 510,
                              "label-append": "Min"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "chart-card" },
                        [
                          _c("div", { staticClass: "flex-space-between" }, [
                            _c(
                              "span",
                              { staticClass: "table-title smaller-font" },
                              [_vm._v("Unresolved Tickets")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value:
                                      "Tickets which are unresolved in that time for the selected agent",
                                    expression:
                                      "\n                'Tickets which are unresolved in that time for the selected agent'\n              "
                                  }
                                ]
                              },
                              [
                                _c("icons", {
                                  attrs: {
                                    name: "info",
                                    size: "medium",
                                    color: "grey"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("line-chart", {
                            staticClass: "chartOverflowVisible",
                            attrs: {
                              series: _vm.pendingChatSeries,
                              categories: _vm.pendingChatCategories,
                              colors: _vm.pendingChatChartColors,
                              "toolbar-enable": false,
                              "show-tooltip": true,
                              width: 510,
                              "label-append": "Min"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
              _vm._v(" "),
              _vm.convStateUIFlag
                ? _c("div", [_c("table-skeleton")], 1)
                : _c("agent-conversation-states", {
                    attrs: {
                      "agent-conv-states": _vm.agentConvStates,
                      "selected-date-range": {
                        startDate: _vm.startDate,
                        endDate: _vm.endDate
                      }
                    }
                  }),
              _vm._v(" "),
              _c(
                "woot-modal",
                {
                  attrs: { show: _vm.downloading, "on-close": _vm.hidePopup },
                  on: {
                    "update:show": function($event) {
                      _vm.downloading = $event
                    }
                  }
                },
                [
                  _c("div", { staticClass: "download-note" }, [
                    _c("img", {
                      attrs: {
                        src:
                          "https://s3.ap-south-1.amazonaws.com/cdn.limechat.ai/assets/images/limechat_report_sent.svg"
                      }
                    }),
                    _vm._v(" "),
                    _c("div", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("ANALYTICS.DOWNLOAD_REPORT.EMAIL", {
                            email: _vm.userEmail
                          })
                        )
                      }
                    })
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "woot-modal",
                {
                  attrs: { show: _vm.downloadError, "on-close": _vm.hidePopup },
                  on: {
                    "update:show": function($event) {
                      _vm.downloadError = $event
                    }
                  }
                },
                [
                  _c("div", { staticClass: "download-note" }, [
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.$t("ANALYTICS.DOWNLOAD_REPORT.REPORT_FAIL"))
                      )
                    ])
                  ])
                ]
              )
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "flex-space-between", staticStyle: { height: "40px" } },
      [
        _c("span", { staticClass: "table-title" }, [
          _vm._v("Agents Hourly Performance")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }