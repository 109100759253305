var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contact-results-wrapper custom-scroll" },
    [
      _vm.isLoading
        ? _c("loading-state", {
            staticStyle: { height: "20rem" },
            attrs: { height: "80", message: "Searching..." }
          })
        : _vm.showZeroState
        ? _c("div", { staticClass: "zero-state capital_c3 text-light" }, [
            _vm._v("\n    No contacts available\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showZeroState
        ? _c(
            "woot-base-button",
            {
              staticClass: "load-more-button",
              attrs: { variant: "secondary", size: "small" },
              on: { click: _vm.onAddNewContact }
            },
            [
              _vm._v(
                "\n    Add This " +
                  _vm._s(_vm.inboxType === "email" ? "Email" : "Number") +
                  "\n  "
              )
            ]
          )
        : [
            _c(
              "span",
              { staticClass: "results-heading capital_c3 text-light" },
              [_vm._v("\n      Available Contacts\n    ")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "contact-results" },
              _vm._l(_vm.contacts, function(ref) {
                var id = ref.id
                var phone_number = ref.phone_number
                var email = ref.email
                var name = ref.name
                var opt_in = ref.opt_in
                return _c("contact-entry", {
                  key: id,
                  attrs: {
                    "contact-id": id,
                    "contact-name": name,
                    "contact-phone": phone_number,
                    email: email,
                    "is-opt-in": opt_in,
                    "inbox-type": _vm.inboxType
                  },
                  on: { onSelectContact: _vm.onSelectContact }
                })
              }),
              1
            ),
            _vm._v(" "),
            _vm.canLoadMoreResults
              ? _c(
                  "woot-base-button",
                  {
                    staticClass: "load-more-button",
                    attrs: { variant: "tertiary", size: "small" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("onPageChange")
                      }
                    }
                  },
                  [_vm._v("\n      Load More Results\n    ")]
                )
              : _vm._e()
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }