var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal-new",
    {
      attrs: {
        show: true,
        "on-close": function() {
          return _vm.$emit("onClose")
        }
      }
    },
    [
      _c("woot-modal-header-new", { attrs: { "header-title": "Import Tags" } }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "modal-content-wrapper flex-column flex-justify--between custom-scroll"
        },
        [
          _c("div", { staticClass: "flex-column gap--normal" }, [
            _c("div", {
              staticClass: "illustration-container",
              style: {
                backgroundImage:
                  "url(" + require("assets/images/import_tags.svg") + ")"
              }
            }),
            _vm._v(" "),
            _c("span", {
              staticClass: "body-b2 text-light",
              domProps: {
                textContent: _vm._s(
                  "Please upload the Tags in the given format. If you don’t have a template ready, feel free to download here and fill & upload accordingly"
                )
              }
            })
          ]),
          _vm._v(" "),
          _vm.metadata
            ? _c(
                "table",
                {
                  staticStyle: {
                    width: "100%",
                    "border-collapse": "collapse",
                    "margin-top": "10px"
                  }
                },
                [
                  _c("tr", { staticStyle: { "background-color": "#f2f2f2" } }, [
                    _c(
                      "td",
                      {
                        staticStyle: {
                          padding: "10px",
                          border: "1px solid #ddd"
                        }
                      },
                      [_vm._v("\n          Total Rows\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticStyle: {
                          padding: "10px",
                          border: "1px solid #ddd"
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.metadata.total_rows) +
                            "\n        "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c(
                      "td",
                      {
                        staticStyle: {
                          padding: "10px",
                          border: "1px solid #ddd"
                        }
                      },
                      [_vm._v("\n          Successful Imports\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticStyle: {
                          padding: "10px",
                          border: "1px solid #ddd"
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.metadata.success) +
                            "\n        "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("tr", { staticStyle: { "background-color": "#f2f2f2" } }, [
                    _c(
                      "td",
                      {
                        staticStyle: {
                          padding: "10px",
                          border: "1px solid #ddd"
                        }
                      },
                      [_vm._v("\n          Existing Tags\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticStyle: {
                          padding: "10px",
                          border: "1px solid #ddd"
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.metadata.exists) +
                            "\n        "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c(
                      "td",
                      {
                        staticStyle: {
                          padding: "10px",
                          border: "1px solid #ddd"
                        }
                      },
                      [_vm._v("\n          Errors\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticStyle: {
                          padding: "10px",
                          border: "1px solid #ddd"
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.metadata.errors) +
                            "\n        "
                        )
                      ]
                    )
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.errorMessage
            ? _c("span", {
                staticClass: "text-align--right body-b3 text-dark",
                domProps: { textContent: _vm._s(_vm.errorMessage) }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.csvData
            ? _c(
                "woot-base-button",
                {
                  staticClass: "download-report-btn",
                  attrs: { variant: "secondary" },
                  on: { click: _vm.downloadCSV }
                },
                [_vm._v("\n      Download Import Tags Report\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showUpload
            ? _c(
                "div",
                { staticClass: "flex-row flex-justify--between" },
                [
                  _c(
                    "woot-base-button",
                    {
                      attrs: { to: "/example_tag.csv", variant: "secondary" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.InlineButtonClickHandler($event)
                        }
                      }
                    },
                    [_vm._v("\n        Download Example\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "woot-base-button",
                    {
                      staticClass: "custom-import-btn",
                      attrs: { loading: _vm.isUploading }
                    },
                    [
                      _c(
                        "file-upload",
                        {
                          attrs: { size: 4096 * 4096, accept: ".csv" },
                          on: { "input-file": _vm.onFileUpload }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.errorMessage ? "Retry" : "Upload CSV"
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }