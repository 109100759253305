var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "flex-container flex-dir-column medium-flex-dir-row csat-date-range"
    },
    [
      _c(
        "div",
        { staticClass: "pull-right date-picker" },
        [
          !_vm.isDateRangeSelected
            ? _c("woot-single-selector", {
                attrs: {
                  variant: "secondary",
                  size: "medium",
                  "default-option": _vm.currentDateRangeSelection.name,
                  "option-list": _vm.dateRange,
                  "show-selected-text": false
                },
                on: { click: _vm.changeDateSelection }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.isDateRangeSelected
        ? [
            _c(
              "div",
              {
                staticClass: "flex-row",
                staticStyle: {
                  border: "1px solid #bfbfbf",
                  "border-radius": "0.8rem"
                }
              },
              [
                _c("woot-date-range-picker", {
                  attrs: {
                    "show-range": "",
                    value: _vm.customDateRange,
                    "confirm-text": _vm.$t(
                      "ANALYTICS.CUSTOM_DATE_RANGE.CONFIRM"
                    ),
                    placeholder: _vm.$t(
                      "ANALYTICS.CUSTOM_DATE_RANGE.PLACEHOLDER"
                    ),
                    "disabled-future-dates": _vm.currentDate
                  },
                  on: { change: _vm.onChange }
                }),
                _vm._v(" "),
                _c("woot-single-selector", {
                  attrs: {
                    variant: "tertiary",
                    "custom-style": {
                      top: "2.4rem",
                      width: "auto",
                      left: "auto",
                      right: "0.8rem"
                    },
                    size: "medium",
                    icon: "chevronDown",
                    "option-list": _vm.dateRange,
                    "show-option-name": false,
                    "show-selected-text": false
                  },
                  on: { click: _vm.changeDateSelection }
                })
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }