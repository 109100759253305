var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("thumbnail", {
        attrs: {
          src: _vm.avatarSource,
          username: _vm.name,
          "bg-color": "#6bac1b",
          color: "#fff",
          size: "20px"
        },
        nativeOn: {
          mouseover: function($event) {
            return (function() {
              return (_vm.isHovering = true)
            })($event)
          },
          mouseleave: function($event) {
            return (function() {
              return (_vm.isHovering = false)
            })($event)
          }
        }
      }),
      _vm._v(" "),
      _vm.isHovering ? _vm._t("hover-state") : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }