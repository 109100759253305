var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "flex-row-justify-center",
      class: _vm.computedClass,
      attrs: {
        type: _vm.type,
        "data-testid": "submit_button",
        disabled: _vm.disabled
      },
      on: { click: _vm.onClick }
    },
    [
      !!_vm.iconName
        ? _c("icons", {
            attrs: {
              color: _vm.iconClass,
              size: _vm.iconSize,
              name: _vm.iconName
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.buttonText))]),
      _vm._v(" "),
      _vm.loading ? _c("spinner") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }