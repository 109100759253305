var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("woot-delete-modal-new", {
    attrs: {
      show: _vm.show,
      "on-close": _vm.onClose,
      "on-confirm": _vm.onConfirm,
      title: _vm.$t("SLA.DELETE.TITLE"),
      message: _vm.deleteMessage,
      "show-close": "",
      "confirm-text": _vm.$t("SLA.DELETE.CONFIRM"),
      "reject-text": _vm.$t("SLA.DELETE.CANCEL"),
      "custom-style": {
        "max-width": "35.2rem",
        height: "auto"
      }
    },
    on: {
      "update:show": function($event) {
        _vm.show = $event
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }