var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "mg-bottom--medium" }, [
    _c("div", {
      staticClass: "title-h4 text-dark",
      domProps: {
        textContent: _vm._s(
          _vm.$t("SLA.CREATE.SECTIONS." + _vm.translationKey + ".TITLE")
        )
      }
    }),
    _vm._v(" "),
    _c("div", {
      staticClass: "body-b2 text-light",
      domProps: {
        textContent: _vm._s(
          _vm.$t("SLA.CREATE.SECTIONS." + _vm.translationKey + ".DESC")
        )
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }