<template>
  <span
    class="flex-row flex-align gap--smaller pd-left--smaller pd-right--smaller"
  >
    <span class="deleted-tag" v-text="$t('CONVERSATION.DELETED')" />
    <span v-if="deletedByText" class="text-dark" v-text="deletedByText" />
  </span>
</template>

<script>
import { mapGetters } from 'vuex';
import { humanReadableDate } from 'dashboard/helper/time';

export default {
  props: {
    messageAttributes: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      getAgent: 'agents/getAgent',
    }),
    deletedByText() {
      const { deleted_time = null, deleted_by_user_id = null } =
        this.messageAttributes || {};

      if (deleted_time && deleted_by_user_id) {
        const user = this.getAgentName(deleted_by_user_id);
        const formattedTime = humanReadableDate(new Date(deleted_time * 1000));

        return this.$t('CONVERSATION.DELETED_BY_USER_AT_TIME', {
          user,
          time: formattedTime,
        });
      }

      return null;
    },
  },
  methods: {
    getAgentName(agentId) {
      let agent = this.getAgent(agentId);

      if (!agent.name) {
        agent = { name: 'NA' };
      }

      return agent.name;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.deleted-tag {
  color: $r-500;
  text-transform: uppercase;
}
</style>
