var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "automation-rhs-panel custom-scroll" }, [
    _c(
      "div",
      { staticClass: "automation-details" },
      [
        _c(
          "div",
          {
            staticClass:
              "automation-name flex-row flex-align flex-wrap gap--small mg-bottom--medium"
          },
          [
            _c("div", { staticClass: "body-b2 text-light" }, [
              _vm._v("\n        Rule:\n      ")
            ]),
            _vm._v(" "),
            _vm.automation.is_test ? _c("test-mode-badge") : _vm._e(),
            _vm._v(" "),
            _c("div", {
              staticClass: "title-h4 text-dark line-clamp-1",
              domProps: { textContent: _vm._s(_vm.automationName) }
            })
          ],
          1
        ),
        _vm._v(" "),
        _vm.automationDescription
          ? [
              _c(
                "div",
                { staticClass: "body-b2 text-light mg-bottom--smaller" },
                [_vm._v("\n        Description:\n      ")]
              ),
              _vm._v(" "),
              _c("div", {
                staticClass:
                  "automation-description body-b2 text-dark line-clamp-3 mg-bottom--medium",
                domProps: { textContent: _vm._s(_vm.automationDescription) }
              })
            ]
          : _vm._e()
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "automation-summary" }, [
      _c("div", { staticClass: "body-b2 text-light mg-bottom--small" }, [
        _vm._v("\n      Summary of the rule:\n    ")
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "body-b2 text-light",
        domProps: { innerHTML: _vm._s(_vm.automationSummary) }
      })
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "rhs-panel-footer" },
      [
        _c(
          "woot-base-button",
          {
            attrs: { variant: "secondary", size: "small" },
            on: {
              click: function($event) {
                return _vm.$emit("cloneAutomation", _vm.automation.id)
              }
            }
          },
          [_vm._v("\n      Clone\n    ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }