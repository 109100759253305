var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "settings-header lime-card-1dp", style: _vm.customStyle },
    [
      _c(
        "div",
        { staticClass: "page-title-container" },
        [
          _vm.showBackButton
            ? _c("back-button", { attrs: { "back-url": _vm.backUrl } })
            : _vm._e(),
          _vm._v(" "),
          _vm.icon
            ? _c("icons", {
                attrs: {
                  name: _vm.icon,
                  size: "mediumlarge",
                  color: "grey evenodd",
                  "show-title": false
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("span", { staticClass: "page-title" }, [
            _vm._v(_vm._s(_vm.headerTitle))
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }