var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "textarea-container flex-space-between",
      class: _vm.isAnEmail && "textarea-container--email"
    },
    [
      _c("textarea", {
        ref: "textarea",
        style: "min-height: " + _vm.minHeight + "rem",
        attrs: { placeholder: _vm.placeholder },
        domProps: { value: _vm.value },
        on: {
          input: _vm.onInput,
          focus: _vm.onFocus,
          keyup: _vm.onKeyup,
          mouseup: _vm.captureSelectedText,
          blur: _vm.onBlur,
          paste: _vm.onMediaPaste
        }
      }),
      _vm._v(" "),
      _c("file-upload", {
        ref: "upload",
        attrs: {
          size: 4096 * 4096,
          accept:
            "image/*, application/pdf, audio/mpeg, video/mp4, audio/ogg, .zip"
        },
        on: { "input-file": _vm.uploadFile },
        model: {
          value: _vm.files,
          callback: function($$v) {
            _vm.files = $$v
          },
          expression: "files"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }