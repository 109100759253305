var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "form-container",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.createThreeSixtyInbox($event)
        }
      }
    },
    [
      _c("div", {
        staticClass: "title-h1 text-dark mg-bottom--medium",
        domProps: {
          textContent: _vm._s(_vm.$t("ONBOARDING.WHATSAPP.FORM.TITLE"))
        }
      }),
      _vm._v(" "),
      _c("woot-input", {
        staticClass: "mg-bottom--slab",
        class: { error: _vm.$v.inboxName.$error },
        attrs: {
          required: "",
          "auto-focus": "",
          "full-width": "",
          "has-error": _vm.$v.inboxName.$error,
          error: _vm.$t(
            "INBOX_MGMT.ADD.THREE_SIXTY_DIALOG.CHANNEL_CREATION.INBOX_NAME.ERROR"
          ),
          label: _vm.$t(
            "INBOX_MGMT.ADD.THREE_SIXTY_DIALOG.CHANNEL_CREATION.INBOX_NAME.LABEL"
          ),
          placeholder: _vm.$t(
            "INBOX_MGMT.ADD.THREE_SIXTY_DIALOG.CHANNEL_CREATION.INBOX_NAME.PLACEHOLDER"
          )
        },
        on: { blur: _vm.$v.inboxName.$touch },
        model: {
          value: _vm.inboxName,
          callback: function($$v) {
            _vm.inboxName = typeof $$v === "string" ? $$v.trim() : $$v
          },
          expression: "inboxName"
        }
      }),
      _vm._v(" "),
      _c("woot-input", {
        staticClass: "content-margin",
        class: { error: _vm.$v.phoneNumber.$error },
        attrs: {
          required: "",
          "full-width": "",
          "has-error": _vm.$v.phoneNumber.$error,
          error: _vm.$t(
            "INBOX_MGMT.ADD.THREE_SIXTY_DIALOG.CHANNEL_CREATION.PHONE_NUMBER.ERROR"
          ),
          label: _vm.$t(
            "INBOX_MGMT.ADD.THREE_SIXTY_DIALOG.CHANNEL_CREATION.PHONE_NUMBER.LABEL"
          ),
          placeholder: _vm.$t(
            "INBOX_MGMT.ADD.THREE_SIXTY_DIALOG.CHANNEL_CREATION.PHONE_NUMBER.PLACEHOLDER"
          )
        },
        on: { blur: _vm.$v.phoneNumber.$touch },
        model: {
          value: _vm.phoneNumber,
          callback: function($$v) {
            _vm.phoneNumber = typeof $$v === "string" ? $$v.trim() : $$v
          },
          expression: "phoneNumber"
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-row flex-justify--between" },
        [
          _c(
            "woot-base-button",
            {
              staticClass: "navigation-button",
              attrs: { variant: "secondary" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.navigateTo(_vm.ONBOARDING_SCREENS.EDUCATE_USER.key)
                }
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("ONBOARDING.NAVIGATION.PREVIOUS")) +
                  "\n    "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "woot-base-button",
            { staticClass: "navigation-button", attrs: { type: "submit" } },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("ONBOARDING.NAVIGATION.NEXT")) +
                  "\n    "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }