var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("form-section-header", {
        attrs: {
          heading: _vm.$t(
            "CREATE_TICKET.FORM.SECTIONS.ADD_SUBJECT_AND_BODY.HEADING"
          ),
          "sub-heading": _vm.$t(
            "CREATE_TICKET.FORM.SECTIONS.ADD_SUBJECT_AND_BODY.SUB_HEADING"
          ),
          icon: "template"
        }
      }),
      _vm._v(" "),
      _c("woot-input", {
        staticClass: "CREATE_TICKET_SUBJECT",
        staticStyle: {
          "max-width": "50rem",
          "margin-left": "4.8rem",
          "margin-bottom": "1.6rem"
        },
        attrs: {
          label: _vm.$t(
            "CREATE_TICKET.FORM.SECTIONS.ADD_SUBJECT_AND_BODY.SUBJECT.LABEL"
          ),
          size: "small",
          type: "text",
          required: "",
          placeholder: _vm.$t(
            "CREATE_TICKET.FORM.SECTIONS.ADD_SUBJECT_AND_BODY.SUBJECT.PLACEHOLDER"
          )
        },
        on: {
          input: function($event) {
            return _vm.$emit("subjectInput", $event)
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "flex-row flex-align mg-bottom--small",
          staticStyle: { "margin-left": "4.8rem" }
        },
        [
          _c("span", {
            staticClass: "dot-circle",
            attrs: { title: "required" }
          }),
          _vm._v(" "),
          _c("span", {
            staticClass: "title-h5 text-dark",
            domProps: {
              textContent: _vm._s(
                _vm.$t(
                  "CREATE_TICKET.FORM.SECTIONS.ADD_SUBJECT_AND_BODY.BODY.LABEL"
                )
              )
            }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "email-body-editor form-input" },
        [
          _c(
            "div",
            { staticClass: "reply-box-icons" },
            [
              _c(
                "file-upload",
                {
                  attrs: {
                    multiple: true,
                    size: 4096 * 4096,
                    accept:
                      "image/*, application/pdf, audio/mpeg, video/mp4, audio/ogg, .zip, .csv"
                  },
                  on: { "input-file": _vm.onFileUpload },
                  model: {
                    value: _vm.files,
                    callback: function($$v) {
                      _vm.files = $$v
                    },
                    expression: "files"
                  }
                },
                [
                  _c("icons", {
                    attrs: {
                      name: "clip",
                      size: "semimedium",
                      color: "darkestgrey",
                      title: "Upload file(s)"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("text-editor", {
            staticClass: "CREATE_TICKET_BODY",
            attrs: {
              "auto-focus": "",
              "is-menu-bar-required": "",
              "is-format-mode": "",
              "no-border": "",
              "for-email": "",
              height: 13,
              placeholder: _vm.$t(
                "CREATE_TICKET.FORM.SECTIONS.ADD_SUBJECT_AND_BODY.BODY.PLACEHOLDER"
              )
            },
            on: {
              input: function($event) {
                return _vm.$emit("messageInput", $event)
              }
            }
          }),
          _vm._v(" "),
          _vm.files.length && _vm.attachmentPreview
            ? _c(
                "div",
                {
                  staticClass:
                    "flex-row flex-justify--start uploaded-files-container"
                },
                _vm._l(_vm.files, function(file) {
                  return _c(
                    "div",
                    { key: file.id },
                    [
                      _c("email-file", {
                        attrs: {
                          id: "" + file.id,
                          "attachment-type": file.type,
                          "attachment-name": file.name,
                          "attachment-size": file.size,
                          "attachment-url": file.url,
                          "can-delete": ""
                        },
                        on: { clear: _vm.clearAttachments }
                      })
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "signature-switch flex-row" },
        [
          _c("span", {
            staticClass: "title-h5 text-dark",
            domProps: {
              textContent: _vm._s(
                _vm.$t(
                  "CREATE_TICKET.FORM.SECTIONS.ADD_SUBJECT_AND_BODY.SIGNATURE.HEADING"
                )
              )
            }
          }),
          _vm._v(" "),
          _c("woot-switch", {
            attrs: { id: Math.random(), value: _vm.hasEmailSignature },
            on: { click: _vm.toggleEmailSignature }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "signature-switch mg-top--smaller" }, [
        _c("span", {
          staticClass: "body-b2 text-light",
          domProps: {
            textContent: _vm._s(
              _vm.$t(
                "CREATE_TICKET.FORM.SECTIONS.ADD_SUBJECT_AND_BODY.SIGNATURE.SUB_HEADING"
              )
            )
          }
        })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }