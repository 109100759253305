var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view-box columns" },
    [
      _c("settings-header", {
        attrs: {
          icon: "megaphone",
          "header-title": _vm.$t("BROADCAST.HEADER"),
          "show-back-button": false
        }
      }),
      _vm._v(" "),
      _vm.displayDeprecationPage
        ? _c(
            "deprecation-page",
            { on: { "on-close": _vm.onHideDeprecationPage } },
            [
              _c("reference-box", {
                staticClass: "warn-banner",
                attrs: {
                  heading: _vm.$t("BROADCAST.DEPRECATION.BANNER.HEADING"),
                  content: _vm.$t("BROADCAST.DEPRECATION.BANNER.CONTENT"),
                  "icon-name": "info",
                  "icon-color": "red",
                  "view-box": "0 0 24 24"
                }
              })
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "settings-box custom-scroll flex-row" },
            [
              _c(
                "woot-base-button",
                {
                  staticClass: "settings-button",
                  attrs: {
                    "front-icon": "heavyPlus",
                    "icon-view-box": "0 0 20 20",
                    disabled:
                      (_vm.inPendingPaymentForNonShopify &&
                        !_vm.isEnterprise) ||
                      _vm.addBillingRestrictions(_vm.accountDetails)
                  },
                  on: { click: _vm.goToCreateBroadcast }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("BROADCAST.CREATE.LABEL")) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "small-12" },
                [
                  _c("div", { staticClass: "flex-space-between" }, [
                    _c(
                      "div",
                      { staticClass: "flex-row-center" },
                      [
                        _c("search-box", {
                          attrs: {
                            placeholder: _vm.$t("BROADCAST.SEARCH.PLACEHOLDER"),
                            value: _vm.search,
                            "custom-class": "reports-search",
                            "on-search": _vm.searchReports
                          },
                          on: { setSearch: _vm.setSearch }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mg-left" },
                          [
                            _c("woot-single-selector", {
                              attrs: {
                                variant: "secondary",
                                size: "medium",
                                "front-icon": "inbox",
                                "custom-style": { width: "auto" },
                                "default-option": _vm.inboxName
                                  ? _vm.inboxName
                                  : "All Inboxes",
                                "option-list": _vm.inboxes,
                                "button-style": {
                                  "border-radius": "4px",
                                  color: "#3C492C",
                                  border: "1px solid #8c8c8c"
                                },
                                "show-selected-text": false
                              },
                              on: { click: _vm.changeInboxId }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "table-container lime-card-1dp custom-scroll mg-top",
                      staticStyle: {
                        padding: "0",
                        margin: "1.6rem 1.6rem 0.1rem 0.1rem"
                      }
                    },
                    [
                      _c("table", { staticClass: "woot-table" }, [
                        !_vm.isGupshup
                          ? _c(
                              "thead",
                              _vm._l(
                                _vm.$t("BROADCAST.DOWNLOAD_TAB.TABLE_HEADER"),
                                function(thHeader) {
                                  return _c(
                                    "th",
                                    {
                                      key: thHeader,
                                      class: {
                                        "text-align": [
                                          "Reports",
                                          "Status"
                                        ].includes(thHeader)
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(thHeader) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          : _c("thead", [
                              _c("th", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "BROADCAST.DOWNLOAD_TAB.TABLE_HEADER"
                                    )[0]
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("th", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "BROADCAST.DOWNLOAD_TAB.TABLE_HEADER"
                                    )[1]
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("th", [
                                _c("div", { staticClass: "flex-column" }, [
                                  _c("span", [_vm._v("Gupshup")]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticStyle: { color: "#1B83AC" } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "#6BAC1B",
                                            "text-transform": "capitalize"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      Sent\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                    ,\n                    "
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "#FF7E61",
                                            "text-transform": "capitalize"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      Failed\n                    "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("th", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "BROADCAST.DOWNLOAD_TAB.TABLE_HEADER"
                                    )[2]
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("th", { staticClass: "text-align" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t(
                                        "BROADCAST.DOWNLOAD_TAB.TABLE_HEADER"
                                      )[3]
                                    ) +
                                    "\n              "
                                )
                              ])
                            ]),
                        _vm._v(" "),
                        _vm.broadcastList.length
                          ? _c(
                              "tbody",
                              _vm._l(_vm.broadcastList, function(status) {
                                return _c(
                                  "tr",
                                  {
                                    key: status.id,
                                    staticStyle: {
                                      "border-bottom": "none",
                                      position: "relative"
                                    },
                                    attrs: { height: "76px" }
                                  },
                                  [
                                    _c(
                                      "td",
                                      { staticClass: "body-b2 text-dark" },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(status.id) +
                                            "\n                "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("td", [
                                      _c("div", [
                                        _c("span", {
                                          staticClass:
                                            "title-h5_m text-dark text-truncate",
                                          domProps: {
                                            textContent: _vm._s(
                                              status.name !== "null"
                                                ? status.name ||
                                                    _vm.$t(
                                                      "BROADCAST.DOWNLOAD_TAB.TABLE_HEADER"
                                                    )[1]
                                                : _vm.$t(
                                                    "BROADCAST.DOWNLOAD_TAB.TABLE_HEADER"
                                                  )[1]
                                            )
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "body-b3 text-light" },
                                          [
                                            _vm._v(
                                              "\n                      |" +
                                                _vm._s(" " + status.total) +
                                                " message scheduled\n                    "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "body-b3 text-light" },
                                          [
                                            _vm._v(
                                              "\n                      |" +
                                                _vm._s(" " + status.date) +
                                                "\n                    "
                                            )
                                          ]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("div", {
                                        staticClass:
                                          "body-b3 text-light line-clamp-1",
                                        staticStyle: { "max-width": "40rem" },
                                        domProps: {
                                          textContent: _vm._s(status.content)
                                        }
                                      })
                                    ]),
                                    _vm._v(" "),
                                    _vm.isGupshup
                                      ? _c(
                                          "td",
                                          { staticClass: "body-b2 text-dark" },
                                          [
                                            status.gupshupSent
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      display: "flex"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          color: "#6BAC1B"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              status.gupshupSent
                                                            ) +
                                                            ",\n                    "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "mg-left--smaller",
                                                        staticStyle: {
                                                          color: "#FF7E61"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              status.gupshupFailed
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "mg-top--smaller mg-left--micro",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.viewBroadcastCount(
                                                              status.id
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("icons", {
                                                          attrs: {
                                                            name:
                                                              "refreshRound",
                                                            color: "green",
                                                            size: "normal"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              : _c(
                                                  "woot-base-button",
                                                  {
                                                    staticClass: "view-button",
                                                    attrs: {
                                                      tag: "span",
                                                      variant: "tertiary"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.viewBroadcastCount(
                                                          status.id
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "BROADCAST.STATUS.VIEW"
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.isBeforeScheduledTimeStamp(
                                      status.scheduled_at
                                    )
                                      ? _c("td", [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex-row flex-align flex-justify gap--larger"
                                            },
                                            [
                                              !status.is_cancelled
                                                ? _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "tooltip",
                                                          rawName: "v-tooltip",
                                                          value:
                                                            "Scheduled @ " +
                                                            _vm.formatDate(
                                                              status.scheduled_at
                                                            ),
                                                          expression:
                                                            "\n                        'Scheduled @ ' + formatDate(status.scheduled_at)\n                      "
                                                        }
                                                      ],
                                                      staticClass:
                                                        "mg-right--smaller"
                                                    },
                                                    [
                                                      _c("icons", {
                                                        attrs: {
                                                          name: "history",
                                                          size: "medium",
                                                          color: "warning",
                                                          "show-title": false
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _c("span", {
                                                    staticClass:
                                                      "title-h5 text-light",
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.$t(
                                                          "BROADCAST.STATUS.NOT_APPLICABLE"
                                                        )
                                                      )
                                                    }
                                                  })
                                            ]
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.isBeforeScheduledTimeStamp(
                                      status.scheduled_at
                                    )
                                      ? _c("td", [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex-row flex-justify"
                                            },
                                            [
                                              _c(
                                                "woot-base-button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip.left",
                                                      value:
                                                        "Share report on email",
                                                      expression:
                                                        "'Share report on email'",
                                                      modifiers: { left: true }
                                                    }
                                                  ],
                                                  staticClass: "view-button",
                                                  attrs: {
                                                    tag: "span",
                                                    variant: "tertiary"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.downloadBroadcast(
                                                        status.id,
                                                        status.direct_broadcast
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("icons", {
                                                    attrs: {
                                                      name: "email",
                                                      color: "green",
                                                      size: "medium",
                                                      "show-title": false
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "body-b2 text-dark text-align"
                                      },
                                      [
                                        status.is_cancelled
                                          ? _c("span", {
                                              staticClass:
                                                "text-red cancelled-status title-h6",
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t(
                                                    "BROADCAST.STATUS.CANCELLED"
                                                  )
                                                )
                                              }
                                            })
                                          : _c(
                                              "woot-base-button",
                                              {
                                                staticClass: "view-button",
                                                attrs: {
                                                  tag: "span",
                                                  variant: "tertiary",
                                                  loading: status.loading
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.viewBroadcastCount(
                                                      status.id
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "BROADCAST.STATUS.VIEW"
                                                      )
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    (status.sentCount ||
                                      status.sentCount === 0) &&
                                    _vm.selectedBroadcastId === status.id &&
                                    _vm.showModal &&
                                    !status.loading
                                      ? _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "on-clickaway",
                                                rawName: "v-on-clickaway",
                                                value: _vm.hideStats,
                                                expression: "hideStats"
                                              }
                                            ],
                                            staticClass:
                                              "lime-card-1dp flex-column-center stat-card"
                                          },
                                          [
                                            status.failedState
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "mg-bottom--medium flex-column-center"
                                                  },
                                                  [
                                                    _c("icons", {
                                                      attrs: {
                                                        name: "error",
                                                        color: "error",
                                                        size: "mediumlarge"
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c("span", {
                                                      staticClass:
                                                        "body-b2 text-light",
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.$t(
                                                            "BROADCAST.STATUS.FAIL"
                                                          )
                                                        )
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _vm.isBeforeScheduledTimeStamp(
                                                  status.scheduled_at
                                                )
                                              ? _c("div", [
                                                  _c("div", [
                                                    _c("span", {
                                                      staticClass:
                                                        "body-b2 text-light",
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.$t(
                                                            "BROADCAST.STATUS.SCHEDULED"
                                                          )
                                                        )
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c("div", {
                                                      staticClass:
                                                        "title-h5 text-dark",
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.timeStamp(
                                                            status.scheduled_at
                                                          )
                                                        )
                                                      }
                                                    })
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "cancel-btn"
                                                    },
                                                    [
                                                      _c(
                                                        "woot-base-button",
                                                        {
                                                          staticClass:
                                                            "mg-auto",
                                                          attrs: {
                                                            variant:
                                                              "secondary-danger",
                                                            size: "small"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.onCancelBroadcast(
                                                                status.id
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "BROADCAST.STATUS.CANCEL_BROADCAST"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ])
                                              : _c(
                                                  "div",
                                                  [
                                                    _c("progress-bar", {
                                                      attrs: {
                                                        series:
                                                          _vm.selectedBroadcastProgress
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c("span", {
                                                      staticClass:
                                                        "body-b2 text-light mg-left",
                                                      staticStyle: {
                                                        "margin-top": "-1.5rem"
                                                      },
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.$t(
                                                            "BROADCAST.STATUS.COMPLETE"
                                                          )
                                                        )
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                            _vm._v(" "),
                                            !_vm.isBeforeScheduledTimeStamp(
                                              status.scheduled_at
                                            )
                                              ? [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-light body-b2 mg-top mg-bottom--small flex-row flex-justify--start full-width"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      LimeChat status\n                    "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "flex-space-between full-width"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "flex-column-center"
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "title-h2 text-dark"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    status.sentCount
                                                                  ) +
                                                                  "\n                        "
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          status.total <
                                                          _vm.ROW_CAPPED_NUMBER
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "body-b2 text-light"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                          Sent\n                        "
                                                                  )
                                                                ]
                                                              )
                                                            : _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "body-b2 text-light"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Success"
                                                                  )
                                                                ]
                                                              )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "flex-column-center"
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "title-h2 text-dark"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    status.failedCount
                                                                  ) +
                                                                  "\n                        "
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "body-b2 text-light"
                                                            },
                                                            [_vm._v("Failed")]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  status.total <
                                                  _vm.ROW_CAPPED_NUMBER
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-light body-b2 mg-top mg-bottom--small flex-row flex-justify--start full-width"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      WhatsApp BSP status\n                    "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  status.total <
                                                  _vm.ROW_CAPPED_NUMBER
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "flex-space-between full-width"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "flex-column-center"
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "title-h2 text-dark"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        status.gupshupSent
                                                                      ) +
                                                                      "\n                        "
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c("span", {
                                                                staticClass:
                                                                  "body-b2 text-light",
                                                                domProps: {
                                                                  textContent: _vm._s(
                                                                    _vm.$t(
                                                                      "BROADCAST.STATUS.SENT"
                                                                    )
                                                                  )
                                                                }
                                                              })
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "flex-column-center"
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "title-h2 text-dark"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        status.gupshupFailed
                                                                      ) +
                                                                      "\n                        "
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c("span", {
                                                                staticClass:
                                                                  "body-b2 text-light",
                                                                domProps: {
                                                                  textContent: _vm._s(
                                                                    _vm.$t(
                                                                      "BROADCAST.STATUS.FAIL"
                                                                    )
                                                                  )
                                                                }
                                                              })
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              : _vm._e()
                                          ],
                                          2
                                        )
                                      : _vm._e()
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _vm.broadcastList.length
                        ? _c("table-footer", {
                            attrs: {
                              "on-page-change": _vm.onPageChange,
                              "current-page": Number(_vm.meta.currentPage),
                              "total-count": _vm.meta.count,
                              "page-size": 8
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.broadcastList.length && !_vm.isRefreshing
                        ? _c(
                            "div",
                            { staticClass: "no-data flex-column-center" },
                            [
                              _c("div", [
                                _c("img", {
                                  attrs: {
                                    src: require("dashboard/assets/images/noData.svg"),
                                    alt: "No Data",
                                    height: "100px",
                                    width: "100px"
                                  }
                                })
                              ]),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticClass: "no-items-error-message" },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t("BROADCAST.DOWNLOAD_TAB.404")
                                      ) +
                                      "\n            "
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isRefreshing
                        ? _c("woot-loading-state", {
                            attrs: {
                              message: _vm.$t("BROADCAST.DOWNLOAD_TAB.LOADING")
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "woot-modal",
                    {
                      attrs: {
                        show: _vm.isDownloading,
                        "on-close": _vm.hidePopup
                      },
                      on: {
                        "update:show": function($event) {
                          _vm.isDownloading = $event
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "download-note" }, [
                        _c("img", {
                          attrs: {
                            src:
                              "https://s3.ap-south-1.amazonaws.com/cdn.limechat.ai/assets/images/limechat_report_sent.svg"
                          }
                        }),
                        _vm._v(" "),
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t("ANALYTICS.DOWNLOAD_REPORT.EMAIL", {
                                email: _vm.userEmail
                              })
                            )
                          }
                        })
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: { show: _vm.downloadError, "on-close": _vm.hidePopup },
          on: {
            "update:show": function($event) {
              _vm.downloadError = $event
            }
          }
        },
        [
          _c("div", { staticClass: "download-note" }, [
            _c("div", [
              _vm._v(_vm._s(_vm.$t("ANALYTICS.DOWNLOAD_REPORT.REPORT_FAIL")))
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c("woot-delete-modal-new", {
        attrs: {
          show: _vm.showCancelPopup,
          "on-close": function() {
            return (_vm.showCancelPopup = false)
          },
          "on-confirm": _vm.cancelScheduledBroadcast,
          "show-close": "",
          title: "Cancel Broadcast",
          message:
            _vm.$t("BROADCAST.CANCEL.CONFIRMATION_TEXT") +
            _vm.selectedBroadcastId,
          "confirm-text": "Continue",
          "reject-text": "Back",
          "custom-style": { "max-width": "35.2rem", height: "auto" }
        },
        on: {
          "update:show": function($event) {
            _vm.showCancelPopup = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }