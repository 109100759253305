var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "flex-row flex-justify--start gap--slab mg-bottom--slab flex-wrap"
    },
    [
      _c("search-box", {
        attrs: {
          placeholder: _vm.$t("CUSTOM_TICKET_FIELDS.SEARCH_TXT"),
          value: _vm.search,
          "custom-class": "search-box"
        },
        on: {
          setSearch: function($event) {
            return _vm.$emit("setSearch", $event)
          }
        }
      }),
      _vm._v(" "),
      _c("inbox-filter", {
        attrs: {
          value: _vm.selectedInbox || "Inbox",
          "default-option": _vm.defaultOption
        },
        on: { click: _vm.selectInbox }
      }),
      _vm._v(" "),
      _c("woot-single-selector", {
        attrs: {
          "default-option": _vm.selectedType || "Type",
          "option-list": _vm.allTemplates
        },
        on: { click: _vm.selectType }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }