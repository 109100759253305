var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showTooltip
    ? _c(
        "tippy",
        {
          attrs: {
            to: _vm.id,
            visible: true,
            trigger: "manual",
            placement: _vm.placement,
            theme: "animated",
            arrow: true,
            animation: "shift-toward"
          }
        },
        [_vm._v("\n  " + _vm._s(_vm.text) + "\n")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }