import { render, staticRenderFns } from "./DeprecationPage.vue?vue&type=template&id=62488f74&scoped=true&"
import script from "./DeprecationPage.vue?vue&type=script&lang=js&"
export * from "./DeprecationPage.vue?vue&type=script&lang=js&"
import style0 from "./DeprecationPage.vue?vue&type=style&index=0&id=62488f74&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62488f74",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/limechat/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('62488f74')) {
      api.createRecord('62488f74', component.options)
    } else {
      api.reload('62488f74', component.options)
    }
    module.hot.accept("./DeprecationPage.vue?vue&type=template&id=62488f74&scoped=true&", function () {
      api.rerender('62488f74', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/lime-ui/dashboard/routes/dashboard/broadcast/components/DeprecationPage.vue"
export default component.exports