import { render, staticRenderFns } from "./TableSkeleton.vue?vue&type=template&id=7a31a927&scoped=true&"
var script = {}
import style0 from "./TableSkeleton.vue?vue&type=style&index=0&id=7a31a927&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a31a927",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/limechat/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7a31a927')) {
      api.createRecord('7a31a927', component.options)
    } else {
      api.reload('7a31a927', component.options)
    }
    module.hot.accept("./TableSkeleton.vue?vue&type=template&id=7a31a927&scoped=true&", function () {
      api.rerender('7a31a927', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/lime-ui/dashboard/routes/dashboard/analytics/components/TableSkeleton.vue"
export default component.exports