var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "contact-info--row" }, [
    _vm.href
      ? _c(
          "a",
          { staticClass: "contact-info--details", attrs: { href: _vm.href } },
          [
            _c(
              "div",
              { staticClass: "flex-row" },
              [
                _c(
                  "span",
                  { staticClass: "contact-info--icon" },
                  [
                    _c("icons", {
                      attrs: {
                        name: _vm.icon,
                        color: _vm.iconColor,
                        size: _vm.iconSize
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.value
                  ? _c("account-pii-mask-wrapper", {
                      attrs: {
                        "pii-data": _vm.value,
                        "mask-type": _vm.getMaskTypeByIcon(_vm.icon),
                        "unmask-action": "piiMask/logUnmaskEvent",
                        "unmask-payload": _vm.unmaskRequestPayload
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var data = ref.data
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass: "subtitle-s2 text-dark",
                                    attrs: { title: _vm.value }
                                  },
                                  [_vm._v(_vm._s(data))]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2703062345
                      )
                    })
                  : _c("span", { staticClass: "subtitle-s2 text-light" }, [
                      _vm._v(_vm._s(_vm.$t("CONTACT_PANEL.NOT_AVAILABLE")))
                    ])
              ],
              1
            ),
            _vm._v(" "),
            _vm.showCopy
              ? _c(
                  "button",
                  {
                    staticClass: "button nice link hollow grey-btn compact",
                    attrs: { type: "submit" },
                    on: { click: _vm.onCopy }
                  },
                  [_c("i", { staticClass: "icon copy-icon ion-clipboard" })]
                )
              : _vm._e()
          ]
        )
      : _c("div", { staticClass: "contact-info--details" }, [
          _vm.icon
            ? _c(
                "span",
                { staticClass: "contact-info--icon" },
                [
                  _c("icons", {
                    attrs: {
                      name: _vm.icon,
                      color: _vm.iconColor,
                      size: _vm.iconSize
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.value
            ? _c("span", { staticClass: "text-truncate" }, [
                _vm._v(_vm._s(_vm.value))
              ])
            : _c("span", { staticClass: "text-muted" }, [
                _vm._v(_vm._s(_vm.$t("CONTACT_PANEL.NOT_AVAILABLE")))
              ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }