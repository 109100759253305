var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orders--container" },
    [
      _c(
        "woot-modal",
        {
          attrs: {
            show: _vm.showDiscountModal,
            "on-close": function() {
              return (_vm.showDiscountModal = false)
            }
          },
          on: {
            "update:show": function($event) {
              _vm.showDiscountModal = $event
            }
          }
        },
        [
          _c("woot-modal-header", {
            attrs: { "header-title": "ADD DISCOUNT" }
          }),
          _vm._v(" "),
          !_vm.readOnly
            ? [
                !_vm.shopifyBetaEnabledFlag ||
                (_vm.shopifyBetaEnabledFlag && !_vm.isAgent)
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "content-box flex-row flex-align flex-justify--center content-margin"
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "radio-container",
                            staticStyle: { cursor: "pointer" },
                            attrs: { for: "numericalDiscount" }
                          },
                          [
                            _c("span", { staticClass: "body-b2" }, [
                              _vm._v("\n            Discount value\n          ")
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.discountType,
                                  expression: "discountType"
                                }
                              ],
                              attrs: {
                                id: "numericalDiscount",
                                type: "radio",
                                name: "discountype",
                                value: "FIXED_AMOUNT"
                              },
                              domProps: {
                                checked: _vm._q(
                                  _vm.discountType,
                                  "FIXED_AMOUNT"
                                )
                              },
                              on: {
                                change: function($event) {
                                  _vm.discountType = "FIXED_AMOUNT"
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "radio-circle" })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "radio-container",
                            staticStyle: { cursor: "pointer" },
                            attrs: { for: "percentageDiscount" }
                          },
                          [
                            _c("span", { staticClass: "body-b2" }, [
                              _vm._v("Discount percentage")
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.discountType,
                                  expression: "discountType"
                                }
                              ],
                              attrs: {
                                id: "percentageDiscount",
                                type: "radio",
                                name: "discountype",
                                value: "PERCENTAGE"
                              },
                              domProps: {
                                checked: _vm._q(_vm.discountType, "PERCENTAGE")
                              },
                              on: {
                                change: function($event) {
                                  _vm.discountType = "PERCENTAGE"
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "radio-circle" })
                          ]
                        )
                      ]
                    )
                  : _vm._e()
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content-box" },
            [
              !_vm.readOnly
                ? [
                    _c(
                      "div",
                      {
                        staticClass: "flex-row flex-align flex-justify--between"
                      },
                      [
                        _c("woot-input", {
                          staticClass: "discount-field",
                          class: { error: _vm.$v.discount.$error },
                          attrs: {
                            type: "number",
                            label:
                              "Discount value (" +
                              _vm.accountCurrency +
                              ")" +
                              (_vm.isDiscountPercentage ? " (%)" : ""),
                            "has-error": _vm.$v.discount.$error,
                            error: _vm.customDiscountErrMsg,
                            disabled: _vm.shopifyBetaEnabledFlag && _vm.isAgent
                          },
                          on: { input: _vm.onInput },
                          nativeOn: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.applyDiscount($event)
                            }
                          },
                          model: {
                            value: _vm.discount,
                            callback: function($$v) {
                              _vm.discount = $$v
                            },
                            expression: "discount"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.shopifyBetaEnabledFlag
                      ? _c(
                          "div",
                          { staticClass: "mg-top--small" },
                          _vm._l(_vm.quickDiscountValues, function(val) {
                            return _c("woot-chips", {
                              key: val.name,
                              attrs: {
                                title: val.name,
                                "show-tooltip": false,
                                "custom-style": {
                                  cursor: "pointer",
                                  borderRadius: "4px"
                                },
                                variant: "secondary-small",
                                "bg-color":
                                  val.value === _vm.discount ? "#6bac1b" : ""
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.onClickDiscountValue(val)
                                }
                              }
                            })
                          }),
                          1
                        )
                      : _vm._e()
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "flex-row flex-justify--end discount-modal-btn-container",
                  staticStyle: { gap: "0.8rem" }
                },
                [
                  _c("woot-secondary-button", {
                    attrs: { name: "Cancel" },
                    on: {
                      click: function() {
                        return (_vm.showDiscountModal = false)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("woot-primary-button", {
                    attrs: { name: "Apply", disabled: _vm.$v.discount.$error },
                    on: { click: _vm.applyDiscount }
                  })
                ],
                1
              )
            ],
            2
          )
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: {
            show: _vm.showShippingChargeModal,
            "on-close": function() {
              return (_vm.showShippingChargeModal = false)
            }
          },
          on: {
            "update:show": function($event) {
              _vm.showShippingChargeModal = $event
            }
          }
        },
        [
          _c("woot-modal-header", {
            attrs: { "header-title": "ADD SHIPPING CHARGE" }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "content-box" }, [
            _c(
              "div",
              {
                staticClass:
                  "flex-row flex-align flex-justify--center content-margin"
              },
              [
                _vm.accountShippingRate.name
                  ? _c(
                      "label",
                      {
                        staticClass: "radio-container",
                        staticStyle: { cursor: "pointer" },
                        attrs: { for: "applicableShipping" }
                      },
                      [
                        _c("span", { staticClass: "body-b2" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.accountShippingRate.name +
                                  " - " +
                                  _vm.accountCurrency +
                                  _vm.accountShippingRate.price
                              ) +
                              "\n          "
                          )
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.shippingSelection,
                              expression: "shippingSelection"
                            }
                          ],
                          attrs: {
                            id: "applicableShipping",
                            type: "radio",
                            name: "refund-action"
                          },
                          domProps: {
                            value: 0,
                            checked: _vm.shippingSelection === 0,
                            checked: _vm._q(_vm.shippingSelection, 0)
                          },
                          on: {
                            change: function($event) {
                              _vm.shippingSelection = 0
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "radio-circle" })
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "radio-container",
                    staticStyle: { cursor: "pointer" },
                    attrs: { for: "freeShipping" }
                  },
                  [
                    _c("span", { staticClass: "body-b2" }, [
                      _vm._v(_vm._s("Free Shipping"))
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.shippingSelection,
                          expression: "shippingSelection"
                        }
                      ],
                      attrs: {
                        id: "freeShipping",
                        type: "radio",
                        name: "refund-action"
                      },
                      domProps: {
                        value: 1,
                        checked: _vm.shippingSelection === 1,
                        checked: _vm._q(_vm.shippingSelection, 1)
                      },
                      on: {
                        change: function($event) {
                          _vm.shippingSelection = 1
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "radio-circle" })
                  ]
                ),
                _vm._v(" "),
                !_vm.shopifyBetaEnabledFlag ||
                (_vm.shopifyBetaEnabledFlag && !_vm.isAgent)
                  ? _c(
                      "label",
                      {
                        staticClass: "radio-container",
                        staticStyle: { cursor: "pointer" },
                        attrs: { for: "customShipping" }
                      },
                      [
                        _c("span", { staticClass: "body-b2" }, [
                          _vm._v(_vm._s("Custom Shipping"))
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.shippingSelection,
                              expression: "shippingSelection"
                            }
                          ],
                          attrs: {
                            id: "customShipping",
                            type: "radio",
                            name: "refund-action"
                          },
                          domProps: {
                            value: 2,
                            checked: _vm.shippingSelection === 2,
                            checked: _vm._q(_vm.shippingSelection, 2)
                          },
                          on: {
                            change: function($event) {
                              _vm.shippingSelection = 2
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "radio-circle" })
                      ]
                    )
                  : _vm._e()
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content-box" },
            [
              !_vm.readOnly && _vm.shippingSelection === 2
                ? [
                    _c(
                      "div",
                      {
                        staticClass: "flex-row flex-align flex-justify--between"
                      },
                      [
                        _c("woot-input", {
                          staticClass: "discount-field",
                          attrs: {
                            type: "number",
                            label:
                              "Shipping Charge (" + _vm.accountCurrency + ")"
                          },
                          on: { input: _vm.onInput },
                          nativeOn: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.applyShippingCharge($event)
                            }
                          },
                          model: {
                            value: _vm.shippingCharge,
                            callback: function($$v) {
                              _vm.shippingCharge = $$v
                            },
                            expression: "shippingCharge"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.shopifyBetaEnabledFlag
                      ? _c(
                          "div",
                          { staticClass: "mg-top--small" },
                          _vm._l(_vm.quickShippingValues, function(val) {
                            return _c("woot-chips", {
                              key: val.name,
                              attrs: {
                                title: val.name,
                                "show-tooltip": false,
                                "custom-style": {
                                  cursor: "pointer",
                                  borderRadius: "4px"
                                },
                                "bg-color":
                                  val.value === _vm.shippingCharge
                                    ? "#6bac1b"
                                    : "",
                                variant: "secondary-small"
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.onClickShippingValue(val)
                                }
                              }
                            })
                          }),
                          1
                        )
                      : _vm._e()
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "flex-row flex-justify--end discount-modal-btn-container",
                  staticStyle: { gap: "0.8rem" }
                },
                [
                  _c("woot-secondary-button", {
                    attrs: { name: "Cancel" },
                    on: {
                      click: function() {
                        return (_vm.showShippingChargeModal = false)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("woot-primary-button", {
                    attrs: { name: "Apply" },
                    on: { click: _vm.applyShippingCharge }
                  })
                ],
                1
              )
            ],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition-group",
        {
          staticClass: "menu vertical",
          attrs: { name: "menu-list", tag: "div" }
        },
        _vm._l(_vm.orders, function(order) {
          return _c(
            "div",
            { key: order.id },
            [
              order.fulfillable_quantity !== 0
                ? _c("order", {
                    attrs: { "order-data": order, "read-only": _vm.readOnly }
                  })
                : _vm._e()
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.readOnly || _vm.hasAddress
        ? _c(
            "div",
            {
              staticClass: "orders--calculation",
              class: { "orders--disable": _vm.creatingDraftOrder }
            },
            [
              _c(
                "div",
                {
                  staticClass: "flex-row flex-justify--between content-margin"
                },
                [
                  _c("span", { staticClass: "body-b3 text-light" }, [
                    _vm._v("Item(s) total")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "body-b3 text-dark" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.accountCurrency + _vm.cartTotal) +
                        "\n      "
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex-column content-margin" }, [
                !_vm.readOnly && _vm.hasAddress && _vm.orderMode !== "edit"
                  ? _c(
                      "div",
                      {
                        staticClass: "flex-row flex-align flex-justify--between"
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "body-b3 text-light discount-label",
                            on: { click: _vm.onShowShippingChargesModal }
                          },
                          [_vm._v("\n          Add Shipping Charge\n        ")]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "body-b3 text-dark" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.accountCurrency + _vm.shippingChargeAmt
                              ) +
                              "\n        "
                          )
                        ])
                      ]
                    )
                  : _vm.readOnly || _vm.orderMode === "edit"
                  ? _c(
                      "div",
                      {
                        staticClass: "flex-row flex-align flex-justify--between"
                      },
                      [
                        _c("span", { staticClass: "body-b3 text-light" }, [
                          _vm._v("\n          Shipping Charge\n        ")
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "body-b3 text-dark" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.accountCurrency + _vm.shippingChargeAmt
                              ) +
                              "\n        "
                          )
                        ])
                      ]
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex-column content-margin" }, [
                !_vm.readOnly && _vm.hasAddress && _vm.orderMode !== "edit"
                  ? _c(
                      "div",
                      {
                        staticClass: "flex-row flex-align flex-justify--between"
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "body-b3 text-light discount-label",
                            on: { click: _vm.onShowDiscountModal }
                          },
                          [_vm._v("\n          Apply Discount\n        ")]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "body-b3 text-dark" }, [
                          _vm._v(
                            "\n          -" +
                              _vm._s(_vm.accountCurrency + _vm.discountAmt) +
                              "\n        "
                          )
                        ])
                      ]
                    )
                  : _vm.readOnly || _vm.orderMode === "edit"
                  ? _c(
                      "div",
                      {
                        staticClass: "flex-row flex-align flex-justify--between"
                      },
                      [
                        _c("span", { staticClass: "body-b3 text-light" }, [
                          _vm._v("\n          Discount\n        ")
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "body-b3 text-dark" }, [
                          _vm._v(
                            "\n          -" +
                              _vm._s(_vm.accountCurrency + _vm.discountAmt) +
                              "\n        "
                          )
                        ])
                      ]
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "flex-row flex-justify--between content-margin"
                },
                [
                  _c("span", { staticClass: "body-b3 text-light" }, [
                    _vm._v("Tax")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "body-b3 text-dark" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.accountCurrency + _vm.totalTax) +
                        "\n      "
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex-row flex-align flex-justify--between" },
                [
                  _c("span", { staticClass: "title-h5_m text-dark" }, [
                    _vm._v("Total Price")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "title-h5_m text-dark" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.accountCurrency + _vm.totalPrice || 0.0) +
                        "\n      "
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              !_vm.orderDetail.is_cod &&
              _vm.orderDetail.user_owe_amount &&
              _vm.orderDetail.user_owe_amount !== "0.00"
                ? _c(
                    "div",
                    {
                      staticClass: "flex-row flex-align flex-justify--between"
                    },
                    [
                      _c("span", { staticClass: "title-h5_m text-dark" }, [
                        _vm._v("Balance (customer owes you)")
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "title-h5_m text-red" }, [
                        _vm._v(
                          _vm._s(
                            _vm.accountCurrency +
                              (_vm.orderDetail.is_cod
                                ? _vm.totalPrice
                                : _vm.orderDetail.user_owe_amount)
                          )
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.orderDetail.user_refund_amount &&
              _vm.orderDetail.user_refund_amount !== "0.00" &&
              _vm.orderDetail.user_owe_amount === "0.00" &&
              !_vm.orderDetail.is_cod
                ? _c(
                    "div",
                    {
                      staticClass: "flex-row flex-align flex-justify--between"
                    },
                    [
                      _c("span", { staticClass: "title-h5_m text-dark" }, [
                        _vm._v("Balance (you owe customer)")
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "title-h5_m text-green" }, [
                        _vm._v(
                          _vm._s(
                            _vm.accountCurrency +
                              _vm.orderDetail.user_refund_amount
                          )
                        )
                      ])
                    ]
                  )
                : _vm._e()
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }