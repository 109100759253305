import {
  OPERATOR_TYPES_1,
  OPERATOR_TYPES_2,
  OPERATOR_TYPES_3,
  OPERATOR_TYPES_6,
} from './operators';
import adminIntro from 'assets/images/admin_intro.svg';

export const ERROR_PREFIX = 'AUTOMATION';

export const MODES = {
  CREATE: 'create',
  EDIT: 'edit',
  CREATE_WITH_TEMPLATE: 'create_with_template',
};

export const AUTOMATION_TABLE_COLUMNS = [
  {
    label: 'Name',
    prop: 'name',
    width: '5%',
    sortable: true,
    component: 'AutomationRuleName',
  },
];

export const AUTOMATION_TABLE_ACTIONS_LIST = [
  { id: 1, name: 'clone' },
  { id: 2, name: 'delete' },
];

export const AUTOMATION_TABLE_TABS = [
  {
    key: 'automation_rules',
    id: 'automation_rules',
    name: 'Rules',
  },
  {
    key: 'automation_templates',
    id: 'automation_templates',
    name: 'Library',
  },
];

export const SHOWCASE_AUTOMATION_DATA = [
  {
    name: 'automation',
    key: 'AUTOMATION',
    color: '#4267B2',
    image: adminIntro,
    contentPath: 'AUTOMATION',
  },
];

export const AUTOMATIONS = {
  message_created: {
    conditions: [
      {
        key: 'inbox_id',
        id: 'inbox_id',
        name: 'Inbox',
        attributeI18nKey: 'INBOX',
        inputType: 'multi_select',
        filterOperators: OPERATOR_TYPES_1,
      },
      {
        key: 'message_type',
        id: 'message_type',
        name: 'Message Type',
        attributeI18nKey: 'MESSAGE_TYPE',
        inputType: 'search_select',
        filterOperators: OPERATOR_TYPES_1,
      },
      {
        key: 'content',
        id: 'content',
        name: 'Message Content',
        attributeI18nKey: 'MESSAGE_CONTAINS',
        inputType: 'array[text]',
        filterOperators: OPERATOR_TYPES_2,
      },
      {
        key: 'identifier',
        id: 'identifier',
        name: 'Identifier',
        attributeI18nKey: 'IDENTIFIER',
        inputType: 'multi_select',
        labelKey: 'name',
        filterOperators: OPERATOR_TYPES_1,
      },
    ],
    actions: [
      {
        key: 'assign_team',
        id: 'assign_team',
        name: 'Assign a team',
        attributeI18nKey: 'ASSIGN_TEAM',
      },
      {
        key: 'assign_agent',
        id: 'assign_agent',
        name: 'Assign an agent',
        attributeI18nKey: 'ASSIGN_AGENT',
      },
      {
        key: 'add_label',
        id: 'add_label',
        name: 'Add a label',
        attributeI18nKey: 'ADD_LABEL',
      },
      {
        key: 'add_contact_label',
        id: 'add_contact_label',
        name: 'Add Contact label',
        attributeI18nKey: 'ADD_CONTACT_LABEL',
      },
      {
        key: 'send_email_to_team',
        id: 'send_email_to_team',
        name: 'Send an email to team',
        attributeI18nKey: 'SEND_EMAIL_TO_TEAM',
      },
      {
        key: 'send_message',
        id: 'send_message',
        name: 'Send a message',
        attributeI18nKey: 'SEND_MESSAGE',
      },
      {
        key: 'mute_conversation',
        id: 'mute_conversation',
        name: 'Mute conversation',
        attributeI18nKey: 'MUTE_CONVERSATION',
      },
      {
        key: 'resolve_conversation',
        id: 'resolve_conversation',
        name: 'Resolve conversation',
        attributeI18nKey: 'RESOLVE_CONVERSATION',
      },
      {
        key: 'open_conversation',
        id: 'open_conversation',
        name: 'Open conversation',
        attributeI18nKey: 'OPEN_CONVERSATION',
      },
      // {
      //   key: 'send_attachment',
      //   id: 'send_attachment',
      //   name: 'Send Attachment',
      //   attributeI18nKey: 'SEND_ATTACHMENT',
      // },
    ],
  },
  conversation_created: {
    conditions: [
      {
        key: 'inbox_id',
        id: 'inbox_id',
        name: 'Inbox',
        attributeI18nKey: 'INBOX',
        inputType: 'multi_select',
        filterOperators: OPERATOR_TYPES_1,
      },
      {
        key: 'mail_subject',
        id: 'mail_subject',
        name: 'Email Subject',
        attributeI18nKey: 'MAIL_SUBJECT',
        inputType: 'array[text]',
        filterOperators: OPERATOR_TYPES_2,
      },
      {
        key: 'identifier',
        id: 'identifier',
        name: 'Identifier',
        attributeI18nKey: 'IDENTIFIER',
        inputType: 'multi_select',
        labelKey: 'name',
        filterOperators: OPERATOR_TYPES_1,
      },
      {
        key: 'email',
        id: 'email',
        name: 'Email',
        attributeI18nKey: 'EMAIL',
        inputType: 'array[text]',
        filterOperators: OPERATOR_TYPES_1,
      },
      // {
      //   key: 'country_code',
      //   id: 'country_code',
      //   name: 'Country',
      //   attributeI18nKey: 'COUNTRY_NAME',
      //   inputType: 'search_select',
      //   filterOperators: OPERATOR_TYPES_1,
      // },
    ],
    actions: [
      {
        key: 'assign_team',
        id: 'assign_team',
        name: 'Assign a team',
        attributeI18nKey: 'ASSIGN_TEAM',
      },
      {
        key: 'assign_agent',
        id: 'assign_agent',
        name: 'Assign an agent',
        attributeI18nKey: 'ASSIGN_AGENT',
      },
      {
        key: 'send_email_to_team',
        id: 'send_email_to_team',
        name: 'Send an email to team',
        attributeI18nKey: 'SEND_EMAIL_TO_TEAM',
      },
      {
        key: 'send_message',
        id: 'send_message',
        name: 'Send a message',
        attributeI18nKey: 'SEND_MESSAGE',
      },
      {
        key: 'send_email',
        id: 'send_email',
        label: 'Send an email',
        attributeI18nKey: 'SEND_EMAIL',
      },
      {
        key: 'mute_conversation',
        id: 'mute_conversation',
        name: 'Mute conversation',
        attributeI18nKey: 'MUTE_CONVERSATION',
      },
      {
        key: 'resolve_conversation',
        id: 'resolve_conversation',
        name: 'Resolve conversation',
        attributeI18nKey: 'RESOLVE_CONVERSATION',
      },
      // {
      //   key: 'send_attachment',
      //   id: 'send_attachment',
      //   name: 'Send Attachment',
      //   attributeI18nKey: 'SEND_ATTACHMENT',
      // },
    ],
  },
  conversation_opened: {
    conditions: [
      {
        key: 'inbox_id',
        id: 'inbox_id',
        name: 'Inbox',
        attributeI18nKey: 'INBOX',
        inputType: 'multi_select',
        labelKey: 'name',
        filterOperators: OPERATOR_TYPES_1,
      },
      {
        key: 'identifier',
        id: 'identifier',
        name: 'Identifier',
        attributeI18nKey: 'IDENTIFIER',
        inputType: 'multi_select',
        labelKey: 'name',
        filterOperators: OPERATOR_TYPES_1,
      },
      {
        key: 'label_id',
        id: 'label_id',
        name: 'Label',
        attributeI18nKey: 'LABEL',
        inputType: 'multi_select',
        labelKey: 'title',
        filterOperators: OPERATOR_TYPES_6,
      },
      {
        key: 'contact_label_id',
        id: 'contact_label_id',
        name: 'Contact Label',
        attributeI18nKey: 'CONTACT LABEL',
        inputType: 'multi_select',
        labelKey: 'title',
        filterOperators: OPERATOR_TYPES_6,
      },
      {
        key: 'mail_subject',
        id: 'mail_subject',
        name: 'Email Subject',
        attributeI18nKey: 'MAIL_SUBJECT',
        inputType: 'array[text]',
        filterOperators: OPERATOR_TYPES_2,
      },
      // {
      //   key: 'country_code',
      //   id: 'country_code',
      //   name: 'Country',
      //   attributeI18nKey: 'COUNTRY_NAME',
      //   inputType: 'search_select',
      //   filterOperators: OPERATOR_TYPES_1,
      // },
      {
        key: 'assignee_id',
        id: 'assignee_id',
        name: 'Assignee',
        attributeI18nKey: 'ASSIGNEE_NAME',
        inputType: 'search_select',
        filterOperators: OPERATOR_TYPES_3,
      },
      {
        key: 'team_id',
        id: 'team_id',
        name: 'Team',
        attributeI18nKey: 'TEAM_NAME',
        inputType: 'search_select',
        filterOperators: OPERATOR_TYPES_3,
      },
      {
        key: 'previous_status',
        id: 'previous_status',
        name: 'Previous Status',
        attributeI18nKey: 'PREVIOUS_STATUS',
        inputType: 'multi_select',
        filterOperators: OPERATOR_TYPES_1,
      },
      {
        key: 'email',
        id: 'email',
        name: 'Email',
        attributeI18nKey: 'EMAIL',
        inputType: 'array[text]',
        filterOperators: OPERATOR_TYPES_1,
      },
    ],
    actions: [
      {
        key: 'assign_team',
        id: 'assign_team',
        name: 'Assign a team',
        attributeI18nKey: 'ASSIGN_TEAM',
      },
      {
        key: 'assign_agent',
        id: 'assign_agent',
        name: 'Assign an agent',
        attributeI18nKey: 'ASSIGN_AGENT',
      },
      {
        key: 'send_email_to_team',
        id: 'send_email_to_team',
        name: 'Send an email to team',
        attributeI18nKey: 'SEND_EMAIL_TO_TEAM',
      },
      {
        key: 'send_message',
        id: 'send_message',
        name: 'Send a message',
        attributeI18nKey: 'SEND_MESSAGE',
      },
      {
        key: 'send_email',
        id: 'send_email',
        label: 'Send an email',
        attributeI18nKey: 'SEND_EMAIL',
      },
      {
        key: 'mute_conversation',
        id: 'mute_conversation',
        name: 'Mute conversation',
        attributeI18nKey: 'MUTE_CONVERSATION',
      },
      {
        key: 'resolve_conversation',
        id: 'resolve_conversation',
        name: 'Resolve conversation',
        attributeI18nKey: 'RESOLVE_CONVERSATION',
      },
      {
        key: 'create_crm_ticket',
        id: 'create_crm_ticket',
        label: 'Create CRM Ticket',
        attributeI18nKey: 'CRM_TYPES',
      },
      {
        key: 'update_crm_ticket',
        id: 'update_crm_ticket',
        label: 'Update CRM Ticket',
        attributeI18nKey: 'CRM_TYPES',
      },
      {
        key: 'add_contact_label',
        id: 'add_contact_label',
        name: 'Add Contact label',
        attributeI18nKey: 'ADD_CONTACT_LABEL',
      },
      // {
      //   key: 'send_attachment',
      //   id: 'send_attachment',
      //   name: 'Send Attachment',
      //   attributeI18nKey: 'SEND_ATTACHMENT',
      // },
    ],
  },
  conversation_resolved: {
    conditions: [
      {
        key: 'inbox_id',
        id: 'inbox_id',
        name: 'Inbox',
        attributeI18nKey: 'INBOX',
        inputType: 'multi_select',
        filterOperators: OPERATOR_TYPES_1,
      },
      {
        key: 'mail_subject',
        id: 'mail_subject',
        name: 'Email Subject',
        attributeI18nKey: 'MAIL_SUBJECT',
        inputType: 'array[text]',
        filterOperators: OPERATOR_TYPES_2,
      },
      // {
      //   key: 'country_code',
      //   id: 'country_code',
      //   name: 'Country',
      //   attributeI18nKey: 'COUNTRY_NAME',
      //   inputType: 'search_select',
      //   filterOperators: OPERATOR_TYPES_1,
      // },
      {
        key: 'assignee_id',
        id: 'assignee_id',
        name: 'Assignee',
        attributeI18nKey: 'ASSIGNEE_NAME',
        inputType: 'search_select',
        filterOperators: OPERATOR_TYPES_3,
      },
      {
        key: 'label_id',
        id: 'label_id',
        name: 'Label',
        attributeI18nKey: 'LABEL',
        inputType: 'multi_select',
        labelKey: 'title',
        filterOperators: OPERATOR_TYPES_6,
      },
      {
        key: 'contact_label_id',
        id: 'contact_label_id',
        name: 'Contact Label',
        attributeI18nKey: 'CONTACT LABEL',
        inputType: 'multi_select',
        labelKey: 'title',
        filterOperators: OPERATOR_TYPES_6,
      },
      {
        key: 'team_id',
        id: 'team_id',
        name: 'Team',
        attributeI18nKey: 'TEAM_NAME',
        inputType: 'search_select',
        filterOperators: OPERATOR_TYPES_3,
      },
      {
        key: 'previous_status',
        id: 'previous_status',
        name: 'Previous Status',
        attributeI18nKey: 'PREVIOUS_STATUS',
        inputType: 'multi_select',
        filterOperators: OPERATOR_TYPES_1,
      },
    ],
    actions: [
      {
        key: 'assign_team',
        id: 'assign_team',
        name: 'Assign a team',
        attributeI18nKey: 'ASSIGN_TEAM',
      },
      {
        key: 'assign_agent',
        id: 'assign_agent',
        name: 'Assign an agent',
        attributeI18nKey: 'ASSIGN_AGENT',
      },
      {
        key: 'send_email_to_team',
        id: 'send_email_to_team',
        name: 'Send an email to team',
        attributeI18nKey: 'SEND_EMAIL_TO_TEAM',
      },
      {
        key: 'send_message',
        id: 'send_message',
        name: 'Send a message',
        attributeI18nKey: 'SEND_MESSAGE',
      },
      {
        key: 'send_email',
        id: 'send_email',
        label: 'Send an email',
        attributeI18nKey: 'SEND_EMAIL',
      },
      {
        key: 'mute_conversation',
        id: 'mute_conversation',
        name: 'Mute conversation',
        attributeI18nKey: 'MUTE_CONVERSATION',
      },
      {
        key: 'resolve_conversation',
        id: 'resolve_conversation',
        name: 'Resolve conversation',
        attributeI18nKey: 'RESOLVE_CONVERSATION',
      },
      {
        key: 'create_crm_ticket',
        id: 'create_crm_ticket',
        label: 'Create CRM Ticket',
        attributeI18nKey: 'CRM_TYPES',
      },
      {
        key: 'update_crm_ticket',
        id: 'update_crm_ticket',
        label: 'Update CRM Ticket',
        attributeI18nKey: 'CRM_TYPES',
      },
      {
        key: 'add_contact_label',
        id: 'add_contact_label',
        name: 'Add Contact label',
        attributeI18nKey: 'ADD_CONTACT_LABEL',
      },
      {
        key: 'open_conversation',
        id: 'open_conversation',
        name: 'Open conversation',
        attributeI18nKey: 'OPEN_CONVERSATION',
      },
      // {
      //   key: 'send_attachment',
      //   id: 'send_attachment',
      //   name: 'Send Attachment',
      //   attributeI18nKey: 'SEND_ATTACHMENT',
      // },
    ],
  },
  assignee_changed: {
    conditions: [
      {
        key: 'inbox_id',
        id: 'inbox_id',
        name: 'Inbox',
        attributeI18nKey: 'INBOX',
        inputType: 'multi_select',
        filterOperators: OPERATOR_TYPES_1,
      },
      {
        key: 'mail_subject',
        id: 'mail_subject',
        name: 'Email Subject',
        attributeI18nKey: 'MAIL_SUBJECT',
        inputType: 'array[text]',
        filterOperators: OPERATOR_TYPES_2,
      },
      // {
      //   key: 'country_code',
      //   id: 'country_code',
      //   name: 'Country',
      //   attributeI18nKey: 'COUNTRY_NAME',
      //   inputType: 'search_select',
      //   filterOperators: OPERATOR_TYPES_1,
      // },
      {
        key: 'assignee_id',
        id: 'assignee_id',
        name: 'Assignee',
        attributeI18nKey: 'ASSIGNEE_NAME',
        inputType: 'search_select',
        filterOperators: OPERATOR_TYPES_3,
      },
      {
        key: 'team_id',
        id: 'team_id',
        name: 'Team',
        attributeI18nKey: 'TEAM_NAME',
        inputType: 'search_select',
        filterOperators: OPERATOR_TYPES_3,
      },
    ],
    actions: [
      {
        key: 'assign_team',
        id: 'assign_team',
        name: 'Assign a team',
        attributeI18nKey: 'ASSIGN_TEAM',
      },
      {
        key: 'assign_agent',
        id: 'assign_agent',
        name: 'Assign an agent',
        attributeI18nKey: 'ASSIGN_AGENT',
      },
      {
        key: 'send_email_to_team',
        id: 'send_email_to_team',
        name: 'Send an email to team',
        attributeI18nKey: 'SEND_EMAIL_TO_TEAM',
      },
      {
        key: 'send_message',
        id: 'send_message',
        name: 'Send a message',
        attributeI18nKey: 'SEND_MESSAGE',
      },
      {
        key: 'send_email',
        id: 'send_email',
        label: 'Send an email',
        attributeI18nKey: 'SEND_EMAIL',
      },
      {
        key: 'mute_conversation',
        id: 'mute_conversation',
        name: 'Mute conversation',
        attributeI18nKey: 'MUTE_CONVERSATION',
      },
      {
        key: 'resolve_conversation',
        id: 'resolve_conversation',
        name: 'Resolve conversation',
        attributeI18nKey: 'RESOLVE_CONVERSATION',
      },
      {
        key: 'add_contact_label',
        id: 'add_contact_label',
        name: 'Add Contact label',
        attributeI18nKey: 'ADD_CONTACT_LABEL',
      },
      {
        key: 'open_conversation',
        id: 'open_conversation',
        name: 'Open conversation',
        attributeI18nKey: 'OPEN_CONVERSATION',
      },
      // {
      //   key: 'send_attachment',
      //   id: 'send_attachment',
      //   name: 'Send Attachment',
      //   attributeI18nKey: 'SEND_ATTACHMENT',
      // },
    ],
  },
  team_changed: {
    conditions: [
      {
        key: 'inbox_id',
        id: 'inbox_id',
        name: 'Inbox',
        attributeI18nKey: 'INBOX',
        inputType: 'multi_select',
        filterOperators: OPERATOR_TYPES_1,
      },
      {
        key: 'status',
        id: 'status',
        name: 'Status',
        attributeI18nKey: 'STATUS',
        inputType: 'multi_select',
        filterOperators: OPERATOR_TYPES_1,
      },
      {
        key: 'mail_subject',
        id: 'mail_subject',
        name: 'Email Subject',
        attributeI18nKey: 'MAIL_SUBJECT',
        inputType: 'array[text]',
        filterOperators: OPERATOR_TYPES_2,
      },
      // {
      //   key: 'country_code',
      //   id: 'country_code',
      //   name: 'Country',
      //   attributeI18nKey: 'COUNTRY_NAME',
      //   inputType: 'search_select',
      //   filterOperators: OPERATOR_TYPES_1,
      // },
      {
        key: 'assignee_id',
        id: 'assignee_id',
        name: 'Assignee',
        attributeI18nKey: 'ASSIGNEE_NAME',
        inputType: 'search_select',
        filterOperators: OPERATOR_TYPES_3,
      },
      {
        key: 'team_id',
        id: 'team_id',
        name: 'Team',
        attributeI18nKey: 'TEAM_NAME',
        inputType: 'search_select',
        filterOperators: OPERATOR_TYPES_3,
      },
    ],
    actions: [
      {
        key: 'assign_team',
        id: 'assign_team',
        name: 'Assign a team',
        attributeI18nKey: 'ASSIGN_TEAM',
      },
      {
        key: 'assign_agent',
        id: 'assign_agent',
        name: 'Assign an agent',
        attributeI18nKey: 'ASSIGN_AGENT',
      },
      {
        key: 'send_email_to_team',
        id: 'send_email_to_team',
        name: 'Send an email to team',
        attributeI18nKey: 'SEND_EMAIL_TO_TEAM',
      },
      {
        key: 'send_message',
        id: 'send_message',
        name: 'Send a message',
        attributeI18nKey: 'SEND_MESSAGE',
      },
      {
        key: 'send_email',
        id: 'send_email',
        label: 'Send an email',
        attributeI18nKey: 'SEND_EMAIL',
      },
      {
        key: 'mute_conversation',
        id: 'mute_conversation',
        name: 'Mute conversation',
        attributeI18nKey: 'MUTE_CONVERSATION',
      },
      {
        key: 'resolve_conversation',
        id: 'resolve_conversation',
        name: 'Resolve conversation',
        attributeI18nKey: 'RESOLVE_CONVERSATION',
      },
      {
        key: 'add_contact_label',
        id: 'add_contact_label',
        name: 'Add Contact label',
        attributeI18nKey: 'ADD_CONTACT_LABEL',
      },
      {
        key: 'open_conversation',
        id: 'open_conversation',
        name: 'Open conversation',
        attributeI18nKey: 'OPEN_CONVERSATION',
      },
      // {
      //   key: 'send_attachment',
      //   id: 'send_attachment',
      //   name: 'Send Attachment',
      //   attributeI18nKey: 'SEND_ATTACHMENT',
      // },
    ],
  },
  conversation_closed: {
    conditions: [
      {
        key: 'inbox_id',
        id: 'inbox_id',
        name: 'Inbox',
        attributeI18nKey: 'INBOX',
        inputType: 'multi_select',
        filterOperators: OPERATOR_TYPES_1,
      },
      {
        key: 'mail_subject',
        id: 'mail_subject',
        name: 'Email Subject',
        attributeI18nKey: 'MAIL_SUBJECT',
        inputType: 'array[text]',
        filterOperators: OPERATOR_TYPES_2,
      },
      {
        key: 'assignee_id',
        id: 'assignee_id',
        name: 'Assignee',
        attributeI18nKey: 'ASSIGNEE_NAME',
        inputType: 'search_select',
        filterOperators: OPERATOR_TYPES_3,
      },
      {
        key: 'team_id',
        id: 'team_id',
        name: 'Team',
        attributeI18nKey: 'TEAM_NAME',
        inputType: 'search_select',
        filterOperators: OPERATOR_TYPES_3,
      },
    ],
    actions: [
      {
        key: 'send_message',
        id: 'send_message',
        name: 'Send a message',
        attributeI18nKey: 'SEND_MESSAGE',
      },
      {
        key: 'create_crm_ticket',
        id: 'create_crm_ticket',
        label: 'Create CRM Ticket',
        attributeI18nKey: 'CRM_TYPES',
      },
      {
        key: 'update_crm_ticket',
        id: 'update_crm_ticket',
        label: 'Update CRM Ticket',
        attributeI18nKey: 'CRM_TYPES',
      },
      {
        key: 'add_contact_label',
        id: 'add_contact_label',
        name: 'Add Contact label',
        attributeI18nKey: 'ADD_CONTACT_LABEL',
      },
    ],
  },
  conversation_to_waiting: {
    conditions: [
      {
        key: 'inbox_id',
        id: 'inbox_id',
        name: 'Inbox',
        attributeI18nKey: 'INBOX',
        inputType: 'multi_select',
        labelKey: 'name',
        filterOperators: OPERATOR_TYPES_1,
      },
      {
        key: 'identifier',
        id: 'identifier',
        name: 'Identifier',
        attributeI18nKey: 'IDENTIFIER',
        inputType: 'multi_select',
        labelKey: 'name',
        filterOperators: OPERATOR_TYPES_1,
      },
      {
        key: 'label_id',
        id: 'label_id',
        name: 'Label',
        attributeI18nKey: 'LABEL',
        inputType: 'multi_select',
        labelKey: 'title',
        filterOperators: OPERATOR_TYPES_6,
      },
      {
        key: 'contact_label_id',
        id: 'contact_label_id',
        name: 'Contact Label',
        attributeI18nKey: 'CONTACT LABEL',
        inputType: 'multi_select',
        labelKey: 'title',
        filterOperators: OPERATOR_TYPES_6,
      },
      {
        key: 'mail_subject',
        id: 'mail_subject',
        name: 'Email Subject',
        attributeI18nKey: 'MAIL_SUBJECT',
        inputType: 'array[text]',
        filterOperators: OPERATOR_TYPES_2,
      },
      // {
      //   key: 'country_code',
      //   id: 'country_code',
      //   name: 'Country',
      //   attributeI18nKey: 'COUNTRY_NAME',
      //   inputType: 'search_select',
      //   filterOperators: OPERATOR_TYPES_1,
      // },
      {
        key: 'assignee_id',
        id: 'assignee_id',
        name: 'Assignee',
        attributeI18nKey: 'ASSIGNEE_NAME',
        inputType: 'search_select',
        filterOperators: OPERATOR_TYPES_3,
      },
      {
        key: 'team_id',
        id: 'team_id',
        name: 'Team',
        attributeI18nKey: 'TEAM_NAME',
        inputType: 'search_select',
        filterOperators: OPERATOR_TYPES_3,
      },
      {
        key: 'previous_status',
        id: 'previous_status',
        name: 'Previous Status',
        attributeI18nKey: 'PREVIOUS_STATUS',
        inputType: 'multi_select',
        labelKey: 'name',
        filterOperators: OPERATOR_TYPES_1,
      },
    ],
    actions: [
      {
        key: 'assign_team',
        id: 'assign_team',
        name: 'Assign a team',
        attributeI18nKey: 'ASSIGN_TEAM',
      },
      {
        key: 'assign_agent',
        id: 'assign_agent',
        name: 'Assign an agent',
        attributeI18nKey: 'ASSIGN_AGENT',
      },
      {
        key: 'send_email_to_team',
        id: 'send_email_to_team',
        name: 'Send an email to team',
        attributeI18nKey: 'SEND_EMAIL_TO_TEAM',
      },
      {
        key: 'send_message',
        id: 'send_message',
        name: 'Send a message',
        attributeI18nKey: 'SEND_MESSAGE',
      },
      {
        key: 'send_email',
        id: 'send_email',
        label: 'Send an email',
        attributeI18nKey: 'SEND_EMAIL',
      },
      {
        key: 'mute_conversation',
        id: 'mute_conversation',
        name: 'Mute conversation',
        attributeI18nKey: 'MUTE_CONVERSATION',
      },
      {
        key: 'resolve_conversation',
        id: 'resolve_conversation',
        name: 'Resolve conversation',
        attributeI18nKey: 'RESOLVE_CONVERSATION',
      },
      {
        key: 'create_crm_ticket',
        id: 'create_crm_ticket',
        label: 'Create CRM Ticket',
        attributeI18nKey: 'CRM_TYPES',
      },
      {
        key: 'update_crm_ticket',
        id: 'update_crm_ticket',
        label: 'Update CRM Ticket',
        attributeI18nKey: 'CRM_TYPES',
      },
      {
        key: 'add_contact_label',
        id: 'add_contact_label',
        name: 'Add Contact label',
        attributeI18nKey: 'ADD_CONTACT_LABEL',
      },
      // {
      //   key: 'send_attachment',
      //   id: 'send_attachment',
      //   name: 'Send Attachment',
      //   attributeI18nKey: 'SEND_ATTACHMENT',
      // },
    ],
  },
  conversation_to_followup: {
    conditions: [
      {
        key: 'inbox_id',
        id: 'inbox_id',
        name: 'Inbox',
        attributeI18nKey: 'INBOX',
        inputType: 'multi_select',
        labelKey: 'name',
        filterOperators: OPERATOR_TYPES_1,
      },
      {
        key: 'identifier',
        id: 'identifier',
        name: 'Identifier',
        attributeI18nKey: 'IDENTIFIER',
        inputType: 'multi_select',
        labelKey: 'name',
        filterOperators: OPERATOR_TYPES_1,
      },
      {
        key: 'label_id',
        id: 'label_id',
        name: 'Label',
        attributeI18nKey: 'LABEL',
        inputType: 'multi_select',
        labelKey: 'title',
        filterOperators: OPERATOR_TYPES_6,
      },
      {
        key: 'contact_label_id',
        id: 'contact_label_id',
        name: 'Contact Label',
        attributeI18nKey: 'CONTACT LABEL',
        inputType: 'multi_select',
        labelKey: 'title',
        filterOperators: OPERATOR_TYPES_6,
      },
      {
        key: 'mail_subject',
        id: 'mail_subject',
        name: 'Email Subject',
        attributeI18nKey: 'MAIL_SUBJECT',
        inputType: 'array[text]',
        filterOperators: OPERATOR_TYPES_2,
      },
      // {
      //   key: 'country_code',
      //   id: 'country_code',
      //   name: 'Country',
      //   attributeI18nKey: 'COUNTRY_NAME',
      //   inputType: 'search_select',
      //   filterOperators: OPERATOR_TYPES_1,
      // },
      {
        key: 'assignee_id',
        id: 'assignee_id',
        name: 'Assignee',
        attributeI18nKey: 'ASSIGNEE_NAME',
        inputType: 'search_select',
        filterOperators: OPERATOR_TYPES_3,
      },
      {
        key: 'team_id',
        id: 'team_id',
        name: 'Team',
        attributeI18nKey: 'TEAM_NAME',
        inputType: 'search_select',
        filterOperators: OPERATOR_TYPES_3,
      },
      {
        key: 'previous_status',
        id: 'previous_status',
        name: 'Previous Status',
        attributeI18nKey: 'PREVIOUS_STATUS',
        inputType: 'multi_select',
        labelKey: 'name',
        filterOperators: OPERATOR_TYPES_1,
      },
    ],
    actions: [
      {
        key: 'assign_team',
        id: 'assign_team',
        name: 'Assign a team',
        attributeI18nKey: 'ASSIGN_TEAM',
      },
      {
        key: 'assign_agent',
        id: 'assign_agent',
        name: 'Assign an agent',
        attributeI18nKey: 'ASSIGN_AGENT',
      },
      {
        key: 'send_email_to_team',
        id: 'send_email_to_team',
        name: 'Send an email to team',
        attributeI18nKey: 'SEND_EMAIL_TO_TEAM',
      },
      {
        key: 'send_message',
        id: 'send_message',
        name: 'Send a message',
        attributeI18nKey: 'SEND_MESSAGE',
      },
      {
        key: 'send_email',
        id: 'send_email',
        label: 'Send an email',
        attributeI18nKey: 'SEND_EMAIL',
      },
      {
        key: 'mute_conversation',
        id: 'mute_conversation',
        name: 'Mute conversation',
        attributeI18nKey: 'MUTE_CONVERSATION',
      },
      {
        key: 'resolve_conversation',
        id: 'resolve_conversation',
        name: 'Resolve conversation',
        attributeI18nKey: 'RESOLVE_CONVERSATION',
      },
      {
        key: 'create_crm_ticket',
        id: 'create_crm_ticket',
        label: 'Create CRM Ticket',
        attributeI18nKey: 'CRM_TYPES',
      },
      {
        key: 'update_crm_ticket',
        id: 'update_crm_ticket',
        label: 'Update CRM Ticket',
        attributeI18nKey: 'CRM_TYPES',
      },
      {
        key: 'add_contact_label',
        id: 'add_contact_label',
        name: 'Add Contact label',
        attributeI18nKey: 'ADD_CONTACT_LABEL',
      },
      // {
      //   key: 'send_attachment',
      //   id: 'send_attachment',
      //   name: 'Send Attachment',
      //   attributeI18nKey: 'SEND_ATTACHMENT',
      // },
    ],
  },
};

export const AUTOMATION_RULE_EVENTS = [
  {
    key: 'conversation_created',
    id: 'conversation_created',
    value: 'Conversation Created',
  },
  {
    key: 'conversation_opened',
    id: 'conversation_opened',
    value: 'Conversation Opened',
  },
  {
    key: 'conversation_resolved',
    id: 'conversation_resolved',
    value: 'Conversation Resolved',
  },
  {
    key: 'conversation_to_waiting',
    id: 'conversation_to_waiting',
    value: 'Conversation to Waiting',
  },
  {
    key: 'conversation_to_followup',
    id: 'conversation_to_followup',
    value: 'Conversation to Followup',
  },
  {
    key: 'conversation_closed',
    id: 'conversation_closed',
    value: 'Conversation Closed',
  },
  {
    key: 'assignee_changed',
    id: 'assignee_changed',
    value: 'Assignee Changed',
  },
  {
    key: 'team_changed',
    id: 'team_changed',
    value: 'Team Changed',
  },
  {
    key: 'message_created',
    id: 'message_created',
    value: 'Message Created',
  },
];

export const AUTOMATION_ACTION_TYPES = [
  {
    key: 'assign_team',
    id: 'assign_team',
    label: 'Assign a team',
    name: 'Assign a team',
    inputType: 'search_select',
  },
  {
    key: 'assign_agent',
    id: 'assign_agent',
    label: 'Assign an agent',
    name: 'Assign an agent',
    inputType: 'search_select',
  },
  {
    key: 'add_label',
    id: 'add_label',
    label: 'Add a label',
    name: 'Add a label',
    inputType: 'multi_select',
  },
  {
    key: 'add_contact_label',
    id: 'add_contact_label',
    label: 'Add Contact label',
    name: 'Add Contact label',
    inputType: 'multi_select',
  },
  {
    key: 'send_email_to_team',
    id: 'send_email_to_team',
    label: 'Send an email to team',
    name: 'Send an email to team',
    inputType: 'team_message',
  },
  {
    key: 'mute_conversation',
    id: 'mute_conversation',
    label: 'Mute conversation',
    name: 'Mute conversation',
    inputType: null,
  },
  {
    key: 'resolve_conversation',
    id: 'resolve_conversation',
    label: 'Resolve conversation',
    name: 'Resolve conversation',
    inputType: null,
  },
  {
    key: 'open_conversation',
    id: 'open_conversation',
    label: 'Open conversation',
    name: 'Open conversation',
    inputType: null,
  },
  // {
  //   key: 'send_attachment',
  //   id: 'send_attachment',
  //   label: 'Send Attachment',
  //   name: 'Send Attachment',
  //   inputType: 'attachment',
  // },
  {
    key: 'send_message',
    id: 'send_message',
    label: 'Send a message',
    name: 'Send a message',
    inputType: 'textarea',
  },
  {
    key: 'send_email',
    id: 'send_email',
    label: 'Send an email',
    name: 'Send an email',
    inputType: 'text_with_variables',
  },
  {
    key: 'create_crm_ticket',
    id: 'create_crm_ticket',
    label: 'Create CRM Ticket',
    name: 'Create CRM Ticket',
    inputType: 'multi_select',
  },
  {
    key: 'update_crm_ticket',
    id: 'update_crm_ticket',
    label: 'Update CRM Ticket',
    name: 'Update CRM Ticket',
    inputType: 'multi_select',
  },
];

export const DEFAULT_AUTOMATION_PAYLOAD = {
  name: '',
  description: '',
  event_name: '',
  conditions: [
    {
      attribute_key: '',
      filter_operator: 'equal_to',
      values: '',
      query_operator: 'and',
      custom_attribute_type: '',
    },
  ],
  actions: [
    {
      action_name: '',
      action_params: [],
    },
  ],
  active: false,
  is_test: false,
  test_numbers: [],
};
