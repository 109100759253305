var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "flex-row flex-align flex-justify--between search-container gap--small"
        },
        [
          _c(
            "woot-base-button",
            {
              staticClass: "search--button full-width",
              attrs: {
                variant: "secondary",
                "icon-size": "semimedium",
                "front-icon": "search"
              },
              on: { click: _vm.onShowSearchModal }
            },
            [
              _c("span", {
                staticClass: "body-b3 text-light",
                domProps: {
                  textContent: _vm._s(_vm.$t("CONVERSATION.SEARCH_MESSAGES"))
                }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "woot-base-button",
            {
              staticClass: "filter-button",
              attrs: {
                tag: "span",
                variant: _vm.uiFlags.isFilterActive ? "primary" : "secondary"
              },
              on: { click: _vm.onShowFilterModal }
            },
            [
              _c("icons", {
                attrs: {
                  name: "filterHollow",
                  color: _vm.uiFlags.isFilterActive
                    ? "stroke-white"
                    : "stroke-lightgrey",
                  size: "semimedium",
                  title: "Apply filters"
                }
              }),
              _vm._v(" "),
              _vm.uiFlags.isFilterActive
                ? _c("div", { staticClass: "statbadge statbadge--danger" })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "modal-fade" } },
        [
          _vm.showFilterModal
            ? _c("filter-options", {
                attrs: {
                  "assignee-tab": _vm.assigneeTab,
                  "on-close": _vm.closeFilter
                }
              })
            : _vm.showSearchModal
            ? _c("search", {
                attrs: {
                  "saved-search-data": _vm.savedSearchMeta.results.length
                    ? _vm.savedSearchMeta
                    : null,
                  "on-close": _vm.closeSearch
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }