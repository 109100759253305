var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bottom-container lime-card-new medium-12" },
    [
      _c(
        "div",
        { staticClass: "table-skeleton-grid" },
        _vm._l(20, function(index) {
          return _c("div", { key: index }, [
            _c("div", { staticClass: "skeleton-animation skeleton-col" })
          ])
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }