var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "post-container", attrs: { id: _vm.message.id } },
    [
      _c(
        "div",
        { class: "flex-row-justify-center post-info" },
        [
          _c("icons", {
            attrs: {
              name: _vm.postType,
              size: "medium",
              color: _vm.postType + " evenodd"
            }
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "title-h5",
              staticStyle: { "margin-left": "0.4rem" }
            },
            [_vm._v("\n      " + _vm._s(_vm.mediaData.name) + "\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.postType === "facebook"
        ? _c(
            "span",
            {
              staticClass:
                "body-b2 text-dark post-info--caption post-info--limiter",
              staticStyle: { "margin-top": "0" }
            },
            [_vm._v("\n    " + _vm._s(_vm.mediaData.caption) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.mediaData.attachment
        ? _c("div", { staticClass: "post-media" }, [
            !_vm.isAnMp4
              ? _c(
                  "a",
                  {
                    staticClass: "flex-row-justify-center",
                    attrs: {
                      href: _vm.mediaData.post_url,
                      target: "_blank",
                      rel: "noopener noreferrer"
                    }
                  },
                  [
                    _vm.isAVideo
                      ? _c("span", [
                          _c("i", { staticClass: "ion ion-play mg-right" })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("img", {
                      attrs: {
                        src: _vm.mediaData.attachment,
                        width: "382",
                        height: "280"
                      }
                    })
                  ]
                )
              : _c(
                  "video",
                  {
                    key: _vm.currentChat.id,
                    attrs: { width: "328", controls: "", height: "280" }
                  },
                  [
                    _c("source", {
                      attrs: {
                        src: _vm.mediaData.attachment,
                        type: "video/mp4"
                      }
                    })
                  ]
                )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.postType === "facebook"
        ? _c("div", { staticClass: "flex-space-between likes-container" }, [
            _c("div", { staticClass: "flex-row-center" }, [
              _c(
                "span",
                { staticClass: "mg-left--smaller mg-bottom--micro" },
                [
                  _c("icons", {
                    attrs: {
                      name: "like",
                      color: "facebook",
                      size: "semimedium"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "mg-left--smaller" },
                [
                  _c("icons", {
                    attrs: {
                      name: "smiley",
                      color: "facebook",
                      size: "semimedium"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "mg-left--smaller" },
                [
                  _c("icons", {
                    attrs: {
                      name: "commentIg",
                      color: "facebook",
                      size: "semimedium"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex-row-center" }, [
              _c(
                "a",
                {
                  staticClass: "flex-row-justify-center",
                  attrs: {
                    href: _vm.mediaData.post_url,
                    target: "_blank",
                    rel: "noopener noreferrer"
                  }
                },
                [
                  _c("icons", {
                    attrs: {
                      name: "transfer",
                      color: "facebook",
                      size: "semimedium"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "button-small facebook-blue",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("CONVERSATION.SOCIAL_POST.OPEN_POST")
                      )
                    }
                  })
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.postType === "instagram"
        ? _c("div", { staticClass: "flex-space-between likes-container" }, [
            _c(
              "div",
              {
                staticClass: "flex-row-center",
                staticStyle: { "margin-top": "0.4rem" }
              },
              [
                _c(
                  "span",
                  { staticClass: "mg-left--smaller" },
                  [
                    _c("icons", {
                      attrs: {
                        name: "heartLike",
                        color: "darkestgrey",
                        size: "medium"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "mg-left--smaller" },
                  [
                    _c("icons", {
                      attrs: {
                        name: "commentIg",
                        color: "darkestgrey",
                        size: "medium"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "mg-left--smaller" },
                  [
                    _c("icons", {
                      attrs: {
                        name: "sendIg",
                        color: "darkestgrey",
                        size: "medium"
                      }
                    })
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "flex-row-center" }, [
              _c(
                "a",
                {
                  staticClass: "flex-row-justify-center",
                  attrs: {
                    href: _vm.mediaData.post_url,
                    target: "_blank",
                    rel: "noopener noreferrer"
                  }
                },
                [
                  _c("img", {
                    staticClass: "mg-right--small",
                    attrs: {
                      src: require("assets/images/instagram_transfer.svg")
                    }
                  }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "button-small instagram-gradient",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("CONVERSATION.SOCIAL_POST.OPEN_POST")
                      )
                    }
                  })
                ]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.postType === "facebook"
        ? _c("div", { staticClass: "post-footer" }, [
            _c(
              "div",
              {
                staticClass: "flex-row-justify-center",
                staticStyle: { "justify-content": "flex-start" }
              },
              [
                _c(
                  "div",
                  { staticClass: "mg-right--small" },
                  [
                    _c("woot-avatar", {
                      attrs: {
                        size: 20,
                        username: _vm.username,
                        color: "#4267b2",
                        colored: false
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: {
                      "text-truncate": _vm.isCommentLengthReachingThreshold
                    },
                    staticStyle: { width: "100%" }
                  },
                  [
                    _c("div", { staticClass: "flex-space-between" }, [
                      _c("b", { staticClass: "title-h5 text-dark" }, [
                        _vm._v(_vm._s(_vm.username))
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "flex-row" },
                        [
                          _c(
                            "woot-base-button",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: "Reply",
                                  expression: "'Reply'"
                                }
                              ],
                              attrs: {
                                size: "small",
                                variant: "tertiary",
                                tag: "span"
                              },
                              on: { click: _vm.replyComment }
                            },
                            [
                              _c("icons", {
                                attrs: {
                                  name: "reply",
                                  color: "green",
                                  size: "semimedium",
                                  "show-title": false
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "woot-base-button",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: "Reply privately",
                                  expression: "'Reply privately'"
                                }
                              ],
                              attrs: {
                                size: "small",
                                variant: "tertiary",
                                tag: "span"
                              },
                              on: { click: _vm.onPrivateReply }
                            },
                            [
                              _c("icons", {
                                attrs: {
                                  name: "messenger",
                                  color: "green",
                                  size: "semimedium",
                                  "show-title": false
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "comment-container body-b2 text-dark",
                        class: {
                          "text-truncate": _vm.isCommentLengthReachingThreshold
                        }
                      },
                      [
                        _c("span", [_vm._v(" " + _vm._s(_vm.content))]),
                        _vm._v(" "),
                        _c("div", { staticClass: "timestamp" }, [
                          _c("span", { staticClass: "capital_c3" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.messageStamp(_vm.message.created_at)
                                ) +
                                "\n            "
                            )
                          ])
                        ])
                      ]
                    )
                  ]
                )
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.postType === "instagram"
        ? _c("div", { staticClass: "flex-row-center" }, [
            _c(
              "span",
              {
                staticClass:
                  "body-b2 text-dark post-info--caption post-info--limiter"
              },
              [
                _c("b", [_vm._v(" " + _vm._s(_vm.mediaData.name))]),
                _vm._v("\n      " + _vm._s(_vm.mediaData.caption) + "\n    ")
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.postType === "instagram"
        ? _c("div", [
            _c(
              "div",
              {
                staticClass: "flex-row-justify-center",
                staticStyle: {
                  "justify-content": "center",
                  padding: "0.8rem",
                  "padding-top": "0"
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "mg-top--one" },
                  [
                    _c("woot-avatar", {
                      attrs: {
                        size: 20,
                        username: _vm.username,
                        color: "#dd2a7b",
                        colored: false
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "full-width flex-column" }, [
                  _c("div", { staticClass: "flex-row flex-justify--between" }, [
                    _c(
                      "span",
                      { staticClass: "body-b2 text-dark post-info--caption" },
                      [
                        _c("b", [
                          _vm._v(
                            _vm._s(
                              _vm.contact.instagram_username || _vm.username
                            )
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "flex-row" },
                      [
                        _c(
                          "woot-base-button",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: "Reply",
                                expression: "'Reply'"
                              }
                            ],
                            attrs: {
                              size: "small",
                              variant: "tertiary",
                              tag: "span"
                            },
                            on: { click: _vm.replyComment }
                          },
                          [
                            _c("icons", {
                              attrs: {
                                name: "reply",
                                color: "green",
                                size: "semimedium",
                                "show-title": false
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "woot-base-button",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: "Reply privately",
                                expression: "'Reply privately'"
                              }
                            ],
                            attrs: {
                              size: "small",
                              variant: "tertiary",
                              tag: "span"
                            },
                            on: { click: _vm.onPrivateReply }
                          },
                          [
                            _c("icons", {
                              attrs: {
                                name: "messenger",
                                color: "green",
                                size: "semimedium",
                                "show-title": false
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        !_vm.isDeleted
                          ? _c(
                              "woot-base-button",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: "Delete Comment",
                                    expression: "'Delete Comment'"
                                  }
                                ],
                                attrs: {
                                  size: "small",
                                  variant: "tertiary-danger",
                                  tag: "span"
                                },
                                on: { click: _vm.toggleDeleteModal }
                              },
                              [
                                _c("icons", {
                                  attrs: {
                                    name: "bin",
                                    color: "darkestgrey",
                                    size: "semimedium",
                                    "show-title": false
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "full-width flex-column pd-top--smaller pd-bottom--smaller",
                      class: { "deleted-message-outline": _vm.isDeleted }
                    },
                    [
                      _vm.isDeleted
                        ? _c("deleted-message-tag", {
                            attrs: {
                              "message-attributes": _vm.messageContentAttributes
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "pd-left--small pd-right--small" },
                        [
                          _c("span", { staticClass: "body-b2 text-dark" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.content) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "timestamp" }, [
                            _c("span", { staticClass: "capital_c3" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.messageStamp(_vm.message.created_at)
                                  ) +
                                  "\n              "
                              )
                            ])
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ])
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("delete-modal", {
        attrs: {
          show: _vm.showDeleteModal,
          message: "Are you sure you want to delete this message?",
          "confirm-text": "Yes, Delete",
          "reject-text": "Cancel",
          "on-close": _vm.toggleDeleteModal,
          "on-confirm": _vm.deleteChat
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }