var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column content-box settings-wrapper" }, [
    _c(
      "div",
      { staticClass: "settings-container column lime-card-1dp" },
      [
        _vm.inbox.id && _vm.isThreeSixtyChannelInProgress
          ? _c("three-sixty-onboarding-modal", {
              attrs: { inbox: _vm.inbox, screen: _vm.currentScreen },
              on: {
                updateCurrentScreen: function(value) {
                  return (_vm.currentScreen = value)
                },
                closeModal: _vm.onCloseModal
              }
            })
          : _vm.inbox.id
          ? [
              _c(
                "woot-tabs",
                {
                  attrs: { index: _vm.selectedTabIndex },
                  on: { change: _vm.onTabChange }
                },
                _vm._l(_vm.tabs, function(tab) {
                  return _c("woot-tabs-item", {
                    key: tab.key,
                    attrs: { name: _vm.$t("INBOX_MGMT.TABS." + tab.label) }
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(_vm.renderComponent, {
                tag: "component",
                attrs: { inbox: _vm.inbox }
              })
            ]
          : _c("loading-state")
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }