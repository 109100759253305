var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "small-12 columns" },
    [
      _c("page-header", {
        attrs: {
          "header-title": _vm.$t("INBOX_MGMT.ADD.TWILIO.TITLE"),
          "header-content": _vm.$t("INBOX_MGMT.ADD.TWILIO.DESC")
        }
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.createChannel($event)
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "settings-sub-menu-content--larger mg-bottom--jumbo"
            },
            [
              _c("woot-input", {
                staticClass: "medium-12 columns content-margin",
                class: { error: _vm.$v.channelName.$error },
                attrs: {
                  required: "",
                  "auto-focus": "",
                  "has-error": _vm.$v.channelName.$error,
                  error: _vm.$t("INBOX_MGMT.ADD.TWILIO.CHANNEL_NAME.ERROR"),
                  label: _vm.$t("INBOX_MGMT.ADD.TWILIO.CHANNEL_NAME.LABEL"),
                  placeholder: _vm.$t(
                    "INBOX_MGMT.ADD.TWILIO.CHANNEL_NAME.PLACEHOLDER"
                  )
                },
                on: { blur: _vm.$v.channelName.$touch },
                model: {
                  value: _vm.channelName,
                  callback: function($$v) {
                    _vm.channelName = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "channelName"
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "medium-12 content-margin" },
                [
                  _c("label", { staticClass: "formlabel flex-row gap--slab" }, [
                    _c("span", {
                      staticClass: "title-h5 text-dark",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("INBOX_MGMT.ADD.TWILIO.CHANNEL_TYPE.LABEL")
                        )
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("woot-single-selector", {
                    attrs: {
                      size: "large",
                      "option-list": _vm.mediumList,
                      "default-option": _vm.medium.name,
                      "custom-style": {
                        top: "4.8rem",
                        left: "auto",
                        width: "100%"
                      }
                    },
                    on: { click: _vm.changeMedium }
                  }),
                  _vm._v(" "),
                  _vm.$v.medium.$error
                    ? _c("span", { staticClass: "desc-text capital_c3" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("INBOX_MGMT.ADD.TWILIO.CHANNEL_TYPE.ERROR")
                          )
                        )
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("woot-input", {
                staticClass: "medium-12 columns content-margin",
                class: { error: _vm.$v.phoneNumber.$error },
                attrs: {
                  required: "",
                  "has-error": _vm.$v.phoneNumber.$error,
                  error: _vm.$t("INBOX_MGMT.ADD.TWILIO.PHONE_NUMBER.ERROR"),
                  label: _vm.$t("INBOX_MGMT.ADD.TWILIO.PHONE_NUMBER.LABEL"),
                  placeholder: _vm.$t(
                    "INBOX_MGMT.ADD.TWILIO.PHONE_NUMBER.PLACEHOLDER"
                  )
                },
                on: { blur: _vm.$v.phoneNumber.$touch },
                model: {
                  value: _vm.phoneNumber,
                  callback: function($$v) {
                    _vm.phoneNumber = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "phoneNumber"
                }
              }),
              _vm._v(" "),
              _c("woot-input", {
                staticClass: "medium-12 columns content-margin",
                class: { error: _vm.$v.accountSID.$error },
                attrs: {
                  required: "",
                  "has-error": _vm.$v.accountSID.$error,
                  error: _vm.$t("INBOX_MGMT.ADD.TWILIO.ACCOUNT_SID.ERROR"),
                  label: _vm.$t("INBOX_MGMT.ADD.TWILIO.ACCOUNT_SID.LABEL"),
                  placeholder: _vm.$t(
                    "INBOX_MGMT.ADD.TWILIO.ACCOUNT_SID.PLACEHOLDER"
                  )
                },
                on: { blur: _vm.$v.accountSID.$touch },
                model: {
                  value: _vm.accountSID,
                  callback: function($$v) {
                    _vm.accountSID = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "accountSID"
                }
              }),
              _vm._v(" "),
              _c("woot-input", {
                staticClass: "medium-12 columns content-margin",
                class: { error: _vm.$v.authToken.$error },
                attrs: {
                  required: "",
                  "has-error": _vm.$v.authToken.$error,
                  error: _vm.$t("INBOX_MGMT.ADD.TWILIO.AUTH_TOKEN.ERROR"),
                  label: _vm.$t("INBOX_MGMT.ADD.TWILIO.AUTH_TOKEN.LABEL"),
                  placeholder: _vm.$t(
                    "INBOX_MGMT.ADD.TWILIO.AUTH_TOKEN.PLACEHOLDER"
                  )
                },
                on: { blur: _vm.$v.authToken.$touch },
                model: {
                  value: _vm.authToken,
                  callback: function($$v) {
                    _vm.authToken = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "authToken"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "medium-12 columns settings-sub-menu-bottom-nav" },
            [
              _c(
                "woot-base-button",
                {
                  attrs: { variant: "secondary" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return (function() {
                        return _vm.$router.go(-1)
                      })($event)
                    }
                  }
                },
                [_vm._v("\n        Back\n      ")]
              ),
              _vm._v(" "),
              _c(
                "woot-base-button",
                { attrs: { loading: _vm.uiFlags.isCreating, type: "submit" } },
                [_vm._v("\n        Next\n      ")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }