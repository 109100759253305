var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-section-header flex-row flex-align--start gap--normal"
    },
    [
      _c(
        "div",
        { staticClass: "section-icon-wrapper" },
        [
          _c("icons", {
            attrs: { name: _vm.icon, size: "semimedium", color: "green" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "flex-column gap--smaller" }, [
        _c("span", {
          staticClass: "title-h5 text-dark",
          domProps: { textContent: _vm._s(_vm.heading) }
        }),
        _vm._v(" "),
        _c("span", {
          staticClass: "body-b3 text-light",
          domProps: { textContent: _vm._s(_vm.subHeading) }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }