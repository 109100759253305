var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition-group",
    {
      staticClass: "selected-contact-results",
      attrs: { name: "menu-list", tag: "div" }
    },
    _vm._l(_vm.contacts, function(ref, idx) {
      var name = ref.name
      var phone_number = ref.phone_number
      var type = ref.type
      return _c(
        "div",
        {
          key: type + "_" + idx,
          staticClass: "selected-contact gap--slab mg-bottom--slab",
          class: "CREATE_TICKET_PHONE_NUMBER" + idx
        },
        [
          _c("woot-input", {
            class: { error: _vm.$v.contacts.$each[idx].phone_number.$error },
            staticStyle: { "max-width": "232px" },
            attrs: {
              label: idx === 0 ? "Contact Number" : "",
              "auto-focus": "",
              size: "small",
              value: phone_number,
              type: "number",
              required: idx === 0,
              placeholder: "Phone number",
              disabled: type === "existing",
              "has-error": _vm.$v.contacts.$each[idx].phone_number.$error,
              error: _vm.$t(
                "CREATE_TICKET.FORM.ERRORS.NOT_VALID_PHONE_NUMBER_DETAILED"
              )
            },
            on: {
              input: function($event) {
                return _vm.updateField("phone_number", $event, idx)
              }
            }
          }),
          _vm._v(" "),
          _c("woot-input", {
            attrs: {
              label: idx === 0 ? "Contact Name" : "",
              value: name,
              size: "small",
              placeholder: "Name",
              disabled: type === "existing"
            },
            on: {
              input: function($event) {
                return _vm.updateField("name", $event, idx)
              }
            }
          }),
          _vm._v(" "),
          _c(
            "woot-base-button",
            {
              staticClass: "remove-button",
              attrs: { tag: "span", variant: "tertiary-danger" },
              on: {
                click: function($event) {
                  return _vm.removeContact(idx)
                }
              }
            },
            [
              _c("icons", {
                attrs: {
                  name: "delete",
                  size: "semimedium",
                  color: "red",
                  title: "Remove contact"
                }
              })
            ],
            1
          )
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }