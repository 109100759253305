var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "time-selector" }, [
    _vm.label
      ? _c("label", { staticClass: "formlabel" }, [
          _c("span", {
            staticClass: "title-h5 text-dark",
            domProps: { textContent: _vm._s(_vm.label) }
          })
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "time-selection-options" },
      _vm._l(_vm.timeOptions, function(options, type) {
        return _c(
          "div",
          { key: type },
          [
            _c("span", {
              staticClass: "body-b3 text-light",
              domProps: { textContent: _vm._s(options.title) }
            }),
            _vm._v(" "),
            _c("woot-single-selector", {
              attrs: {
                size: "small",
                "option-list": options.optionList,
                "default-option": options.selectedOption.value.toString(),
                "button-style": _vm.buttonStyle,
                "custom-style": _vm.customStyle
              },
              on: {
                click: function($event) {
                  return _vm.onSelect(type, $event)
                }
              }
            })
          ],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }