var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "small-12 columns" },
    [
      _c("page-header", {
        attrs: {
          "header-title": _vm.$t("INBOX_MGMT.ADD.AUTH.TITLE"),
          "header-content": _vm.$t("INBOX_MGMT.ADD.AUTH.DESC")
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row channels" },
        _vm._l(_vm.channelList, function(channel) {
          return _c("channel-item", {
            key: channel.key,
            attrs: {
              channel: channel,
              "enabled-features": _vm.enabledFeatures
            },
            on: {
              "channel-item-click": function($event) {
                return _vm.initChannelAuth(channel.key)
              }
            }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }