var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "toast-fade", appear: "" } }, [
    _c(
      "div",
      { staticClass: "views-manager-wrapper" },
      [
        !_vm.uiFlags.isFetching
          ? _c(
              "div",
              { staticClass: "views-manager" },
              [
                _c("Section", {
                  attrs: {
                    title: _vm.$t(
                      "CONVERSATION.CONVERSATION_VIEWS.SECTIONS.CURRENT"
                    ),
                    views: [_vm.currentView],
                    "is-active": function() {
                      return true
                    },
                    "is-default": _vm.isDefaultView,
                    controls: _vm.CONTROLS.current
                  },
                  on: { onMakeDefault: _vm.onMakeDefault }
                }),
                _vm._v(" "),
                _c("Section", {
                  attrs: {
                    title: _vm.$t(
                      "CONVERSATION.CONVERSATION_VIEWS.SECTIONS.DEFAULT"
                    ),
                    views: [_vm.defaultView],
                    "is-default": function() {
                      return true
                    }
                  },
                  on: { onMakeCurrent: _vm.onMakeCurrent }
                }),
                _vm._v(" "),
                _c("Section", {
                  attrs: {
                    title: _vm.$t(
                      "CONVERSATION.CONVERSATION_VIEWS.CUSTOM_VIEW_INFO"
                    ),
                    "accordion-title": _vm.$t(
                      "CONVERSATION.CONVERSATION_VIEWS.SECTIONS.CURATED"
                    ),
                    views: _vm.curatedViews,
                    "is-default": _vm.isDefaultView,
                    "is-active": _vm.isCurrentView,
                    controls: _vm.CONTROLS.curated,
                    "has-accordion": "",
                    "is-accordion-open": ""
                  },
                  on: {
                    onEdit: _vm.onClone,
                    onMakeCurrent: _vm.onMakeCurrent,
                    onMakeDefault: _vm.onMakeDefault
                  }
                }),
                _vm._v(" "),
                _c("Section", {
                  attrs: {
                    "accordion-title": _vm.$t(
                      "CONVERSATION.CONVERSATION_VIEWS.SECTIONS.CUSTOM"
                    ),
                    views: _vm.customViews,
                    "is-default": _vm.isDefaultView,
                    "is-active": _vm.isCurrentView,
                    "has-accordion": "",
                    "is-accordion-open": _vm.isCustomViewsAccordionOpen,
                    controls: _vm.CONTROLS.custom
                  },
                  on: {
                    onEdit: _vm.onEditView,
                    onDelete: _vm.onDeleteView,
                    onMakeCurrent: _vm.onMakeCurrent,
                    onMakeDefault: _vm.onMakeDefault
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "create-custom-view-container" },
                  [
                    _c(
                      "woot-base-button",
                      {
                        staticClass: "create-custom-view-button",
                        attrs: {
                          variant: "secondary",
                          "full-width": "",
                          "preserve-case": ""
                        },
                        on: { click: _vm.createCustomView }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex-row flex-align full-width gap--slab"
                          },
                          [
                            _c("icons", {
                              attrs: {
                                name: "circleAdd",
                                color: "stroke-green",
                                size: "medium"
                              }
                            }),
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "CONVERSATION.CONVERSATION_VIEWS.ADD.TITLE"
                                  )
                                ) +
                                "\n          "
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _c("loading-state", {
              style: "maxHeight: 20rem",
              attrs: { message: "Please wait..." }
            }),
        _vm._v(" "),
        _vm.showUpdateModal
          ? _c("update-view-modal", {
              attrs: {
                opened: _vm.showUpdateModal,
                data: _vm.selectedView,
                "is-default-view": _vm.isDefaultView(_vm.selectedView)
              },
              on: { onClose: _vm.onCloseUpdateModal, onSubmit: _vm.onSubmit }
            })
          : _vm._e(),
        _vm._v(" "),
        _c("woot-delete-modal-new", {
          attrs: {
            show: _vm.showDeleteModal,
            "on-close": _vm.onCloseDeleteModal,
            "on-confirm": _vm.onConfirmDelete,
            "show-close": "",
            title: _vm.$t("CONVERSATION.CONVERSATION_VIEWS.DELETE.TITLE"),
            message: _vm.$t("CONVERSATION.CONVERSATION_VIEWS.DELETE.DESC"),
            "confirm-text": _vm.$t(
              "CONVERSATION.CONVERSATION_VIEWS.DELETE.DELETE"
            ),
            "reject-text": _vm.$t(
              "CONVERSATION.CONVERSATION_VIEWS.DELETE.CANCEL"
            ),
            "custom-style": { "max-width": "35.2rem", height: "auto" }
          },
          on: {
            "update:show": function($event) {
              _vm.showDeleteModal = $event
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }