var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.time
    ? _c("div", { staticClass: "sla-status-item" }, [
        _c("div", {
          staticClass: "text-light body-b2",
          domProps: { textContent: _vm._s(_vm.label) }
        }),
        _vm._v(" "),
        _c("div", {
          staticClass: "title-h5",
          class: !_vm.isBreached ? "text-sunrise-yellow" : "text-light-red",
          domProps: { textContent: _vm._s(_vm.formattedTime) }
        })
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }