var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "marketing-banner flex-column flex-align gap--larger" },
    [
      _vm._t("default"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content flex-column flex-justify flex-align" },
        [
          _c("img", {
            staticClass: "mg-bottom--large",
            attrs: {
              src:
                "https://limechat-fb-uploads.s3.amazonaws.com/uploaded/7/be8328e4-e52e-40ba-9ecf-da9be4dcce1b.svg",
              width: "320"
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "header mg-bottom--small" }, [
            _c("div", {
              staticClass: "title-h1 text-dark text-align",
              domProps: {
                textContent: _vm._s(_vm.$t("BROADCAST.DEPRECATION.HEADER"))
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "details mg-bottom--medium" }, [
            _c("div", {
              staticClass: "subtitle-s2 information text-light text-align",
              domProps: {
                innerHTML: _vm._s(_vm.$t("BROADCAST.DEPRECATION.DETAILS"))
              }
            })
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "actions flex-column flex-justify gap--small mg-top"
            },
            [
              _c(
                "woot-base-button",
                { on: { click: _vm.switchToMarketingBroadcast } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("BROADCAST.DEPRECATION.ACTIONS.SWITCH")) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "woot-base-button",
                {
                  attrs: { variant: "tertiary" },
                  on: { click: _vm.stayInCurrentBroadcast }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("BROADCAST.DEPRECATION.ACTIONS.STAY")) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }