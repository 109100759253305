var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "products-dropdown--container custom-scroll" },
    [
      _vm.uiFlags.fetchingProducts
        ? _c("woot-loading-state", {
            attrs: { message: _vm.$t("PRODUCTS_MGMT.LIST.LOADING") }
          })
        : !_vm.filteredProductList.length
        ? _c("div", { staticClass: "no-order" }, [
            _c("img", {
              attrs: {
                src: require("dashboard/assets/images/noOrder.svg"),
                alt: "No Product"
              }
            }),
            _vm._v(" "),
            _c("p", { staticClass: "no-items-error-message" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("PRODUCTS_MGMT.NO_PRODUCTS")) +
                  "\n    "
              )
            ])
          ])
        : [
            _c("search-box", {
              attrs: {
                placeholder: _vm.$t("PRODUCTS_MGMT.SEARCH_TXT"),
                value: _vm.search,
                variant: true,
                "on-search": _vm.searchProducts,
                "custom-class": "no-border"
              },
              on: { setSearch: _vm.setSearch }
            }),
            _vm._v(" "),
            _vm._l(_vm.filteredProductList, function(product) {
              return _c(
                "div",
                { key: product.id, staticClass: "product-container" },
                [
                  _c("product-option", {
                    attrs: { product: product },
                    on: {
                      closeDropdown: function($event) {
                        return _vm.$emit("closeDropdown")
                      }
                    }
                  })
                ],
                1
              )
            }),
            _vm._v(" "),
            _vm.uiFlags.fetchingProducts
              ? _c("woot-loading-state", {
                  attrs: { message: _vm.$t("PRODUCTS_MGMT.LIST.LOADING") }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "text-center" }, [
              !_vm.uiFlags.fetchingProducts &&
              _vm.filteredProductList.length &&
              _vm.hasNextPage
                ? _c(
                    "span",
                    {
                      staticClass: "clear button load-more-conversations",
                      on: { click: _vm.onPageChange }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("PRODUCTS_MGMT.LIST.LOAD_MORE")) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.uiFlags.fetchingProducts &&
              _vm.filteredProductList.length &&
              !_vm.hasNextPage
                ? _c("span", { staticClass: "text-muted end-of-list-text" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("PRODUCTS_MGMT.LIST.LOADED")) +
                        "\n      "
                    )
                  ])
                : _vm._e()
            ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }