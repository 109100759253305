var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal-new",
    {
      attrs: {
        show: true,
        "on-close": function() {
          return _vm.$emit("onClose")
        }
      }
    },
    [
      _c("woot-modal-header-new", {
        attrs: { "header-title": "Download Canned Responses" }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal-content-wrapper flex-column flex-justify--between"
        },
        [
          _c("div", { staticClass: "flex-column gap--normal" }, [
            _c("div", {
              staticClass: "illustration-container",
              style: {
                backgroundImage:
                  "url(" + require("assets/images/export_canned.svg") + ")"
              }
            }),
            _vm._v(" "),
            _c("span", {
              staticClass: "body-b2 text-light",
              domProps: {
                textContent: _vm._s(
                  "This will export the whole content of the canned responses in the Given format"
                )
              }
            })
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "full-width flex-row flex-justify--end" },
            [
              _c(
                "woot-base-button",
                {
                  attrs: { loading: _vm.isDownloading },
                  on: {
                    click: function($event) {
                      return _vm.download("canned")
                    }
                  }
                },
                [_vm._v("\n        Export as csv\n      ")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }