var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "settings-box shopify-container" }, [
    _c("div", { staticClass: "shopify-body-container" }, [
      _c("div", { staticClass: "shopify-image shopify-image-margin" }, [
        _c("img", {
          attrs: { src: "/dashboard/images/integrations/shopify.svg" }
        })
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "heading" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.$t("INTEGRATION_SETTINGS.SHOPIFY.TITLE")) +
            "\n    "
        )
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "steps" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.$t("INTEGRATION_SETTINGS.SHOPIFY.SUB_TEXT_ONE")) +
            "\n    "
        )
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "steps" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.$t("INTEGRATION_SETTINGS.SHOPIFY.SUB_TEXT_TWO")) +
            "\n    "
        )
      ]),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "shopify-form",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.$emit("shopify-connect", _vm.shopifyDomain)
            }
          }
        },
        [
          _c("woot-input", {
            staticClass: "shopify-input",
            class: { error: _vm.$v.shopifyDomain.$error },
            attrs: { placeholder: "Shopify Domain Name" },
            on: { input: _vm.$v.shopifyDomain.$touch },
            model: {
              value: _vm.shopifyDomain,
              callback: function($$v) {
                _vm.shopifyDomain = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "shopifyDomain"
            }
          }),
          _vm._v(" "),
          _c(
            "woot-button",
            {
              staticClass: "button nice shopify-button",
              attrs: { type: "submit", disabled: _vm.$v.shopifyDomain.$invalid }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("INTEGRATION_SETTINGS.CONNECT.BUTTON_TEXT")) +
                  "\n      "
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("p", { staticClass: "steps question" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.$t("INTEGRATION_SETTINGS.SHOPIFY.NO_CODE_TXT")) +
            "\n    "
        )
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "steps limechat-store",
          attrs: {
            href: "https://apps.shopify.com/limechat-shop",
            target: "_blank"
          }
        },
        [
          _c("icons", {
            attrs: { name: "rocketFilled", size: "medium", color: "green" }
          }),
          _vm._v(" "),
          _c("span", { staticClass: "text-margin" }, [
            _vm._v(_vm._s(_vm.$t("INTEGRATION_SETTINGS.SHOPIFY.LIMECHAT_APP")))
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }