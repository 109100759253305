var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "template-body-substitution" },
    _vm._l(_vm.parsedText, function(part, index) {
      return _c(
        "span",
        { key: index, staticClass: "body-b2 text-light" },
        [
          part.type === "variable"
            ? [
                _c(
                  "span",
                  {
                    staticClass: "variable-button capital_c3",
                    class: [
                      "CREATE_TICKET_TEMPLATE_ATTRIBUTE" + part.index,
                      { filled: _vm.substitutionsFilled[part.index] }
                    ],
                    on: {
                      click: function($event) {
                        return _vm.variableClicked(part.index)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.substitutionsFilled[part.index] ? "ADDED" : "ADD"
                        ) +
                        "\n      "
                    )
                  ]
                )
              ]
            : [_vm._v("\n      " + _vm._s(part.value) + "\n    ")]
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }