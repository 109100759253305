var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column settings-box custom-scroll" },
    [
      _c(
        "woot-modal",
        {
          attrs: { show: _vm.downloading, "on-close": _vm.hidePopup },
          on: {
            "update:show": function($event) {
              _vm.downloading = $event
            }
          }
        },
        [
          _c("div", { staticClass: "download-note" }, [
            _c("img", {
              attrs: {
                src:
                  "https://s3.ap-south-1.amazonaws.com/cdn.limechat.ai/assets/images/limechat_report_sent.svg"
              }
            }),
            _vm._v(" "),
            _c("div", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.$t("ANALYTICS.DOWNLOAD_REPORT.EMAIL", {
                    email: _vm.userEmail
                  })
                )
              }
            })
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: { show: _vm.downloadError, "on-close": _vm.hidePopup },
          on: {
            "update:show": function($event) {
              _vm.downloadError = $event
            }
          }
        },
        [
          _c("div", { staticClass: "download-note" }, [
            _c("div", [
              _vm._v(_vm._s(_vm.$t("ANALYTICS.DOWNLOAD_REPORT.REPORT_FAIL")))
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "settings-container padding-container columns custom-scroll"
        },
        [
          _c("span", { staticClass: "section-title" }, [
            _vm._v("\n      Live Agent Availability\n    ")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "metrics-card-container" },
            _vm._l(_vm.metricsCount, function(item, index) {
              return _c("metrics-card", {
                key: index,
                attrs: {
                  title: item.title,
                  "sub-title": item.sub,
                  color: item.color
                }
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("span", { staticClass: "section-title" }, [_vm._v("Filters")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex-space-between container-margin" },
                [
                  _c("date-range-selector", {
                    attrs: { "default-selection": 0 },
                    on: { "date-range-change": _vm.onDateRangeChange }
                  }),
                  _vm._v(" "),
                  _c("woot-primary-button", {
                    attrs: {
                      "front-icon": "email",
                      name: "Attendance Report",
                      loading: _vm.uiFlag
                    },
                    on: {
                      click: function($event) {
                        return _vm.download()
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "transition",
                { attrs: { name: "slide-up" } },
                [
                  _vm.selectedUserId > 0
                    ? _c("logs-table", {
                        attrs: {
                          "header-list": _vm.$t(
                            "ANALYTICS.ATTENDANCE_LOGS_HEADER"
                          ),
                          "agent-name": _vm.selectedAgentName
                        },
                        on: { "select-summary": _vm.closeAgentLogs }
                      })
                    : _c("summary-table", {
                        attrs: {
                          "header-list": _vm.$t(
                            "ANALYTICS.ATTENDANCE_SUMMARY_HEADER"
                          )
                        },
                        on: { "select-agent": _vm.getAgentLogs }
                      })
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }