var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-container" },
    [
      _c("data-table", {
        attrs: {
          actions: _vm.actionsList,
          "collapse-actions": "",
          columns: _vm.tableColumns,
          "table-data": _vm.automationsList,
          loading: _vm.loading,
          "enable-animations": _vm.automationsList.length <= 10,
          _class: "scrollable-table custom-scroll"
        },
        on: {
          select: _vm.viewAutomation,
          clone: _vm.cloneAutomation,
          delete: _vm.openDeletePopup
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "div",
                  { staticClass: "flex-row flex-align gap--large" },
                  [
                    _vm.canToggleRules
                      ? _c("toggle-automation-rule", {
                          attrs: { data: row },
                          on: { onToggle: _vm.toggleAutomation }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "woot-base-button",
                      {
                        attrs: {
                          tag: "span",
                          size: "small",
                          variant: "tertiary"
                        },
                        on: {
                          click: function($event) {
                            return _vm.openEditPopup(row)
                          }
                        }
                      },
                      [
                        _c("icons", {
                          attrs: {
                            name: "editRound",
                            size: "medium",
                            view: "0 0 24 24",
                            color: "green"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "zero-state",
            fn: function() {
              return [
                !_vm.automationsList.length
                  ? _c(
                      "table-zero-state",
                      {
                        attrs: {
                          title: _vm.$t("AUTOMATION.LIST.ZERO_STATE.TITLE"),
                          subtitle: _vm.$t(
                            "AUTOMATION.LIST.ZERO_STATE.SUBTITLE"
                          ),
                          "asset-link": "/brand-assets/inbox_zero_state.svg"
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex-row flex-align gap--small" },
                          [
                            _vm.selectedTabIndex === 0
                              ? _c(
                                  "woot-base-button",
                                  {
                                    attrs: {
                                      size: "medium",
                                      "front-icon": "plus",
                                      "icon-view-box": "0 0 20 20"
                                    },
                                    on: { click: _vm.openAddPopup }
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.$t("AUTOMATION.HEADER_BTN_TXT")
                                        ) +
                                        "\n          "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "woot-base-button",
                              {
                                attrs: { size: "medium", variant: "secondary" },
                                on: { click: _vm.changeSelectedTab }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.buttonText) +
                                    "\n          "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }