var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "small-12 columns" },
    [
      _c("page-header", {
        attrs: {
          "header-title": _vm.$t("INBOX_MGMT.ADD.INFOBIP.TITLE"),
          "header-content": _vm.$t("INBOX_MGMT.ADD.INFOBIP.DESC")
        }
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.createChannel($event)
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "settings-sub-menu-content--larger mg-bottom--jumbo"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "flex-row flex-justify--between content-margin full-width gap--two"
                },
                [
                  _c("woot-input", {
                    class: { error: _vm.$v.channelName.$error },
                    attrs: {
                      required: "",
                      "auto-focus": "",
                      "full-width": "",
                      "has-error": _vm.$v.channelName.$error,
                      error: _vm.$t(
                        "INBOX_MGMT.ADD.INFOBIP.CHANNEL_NAME.ERROR"
                      ),
                      label: _vm.$t(
                        "INBOX_MGMT.ADD.INFOBIP.CHANNEL_NAME.LABEL"
                      ),
                      placeholder: _vm.$t(
                        "INBOX_MGMT.ADD.INFOBIP.CHANNEL_NAME.PLACEHOLDER"
                      )
                    },
                    on: { blur: _vm.$v.channelName.$touch },
                    model: {
                      value: _vm.channelName,
                      callback: function($$v) {
                        _vm.channelName =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "channelName"
                    }
                  }),
                  _vm._v(" "),
                  _c("woot-input", {
                    class: { error: _vm.$v.phoneNumber.$error },
                    attrs: {
                      required: "",
                      "full-width": "",
                      "has-error": _vm.$v.phoneNumber.$error,
                      error:
                        _vm.phoneNumber === ""
                          ? _vm.$t("INBOX_MGMT.COMMON_ERROR_MESSAGE")
                          : _vm.$t("INBOX_MGMT.ADD.INFOBIP.PHONE_NUMBER.ERROR"),
                      label: _vm.$t(
                        "INBOX_MGMT.ADD.INFOBIP.PHONE_NUMBER.LABEL"
                      ),
                      placeholder: _vm.$t(
                        "INBOX_MGMT.ADD.INFOBIP.PHONE_NUMBER.PLACEHOLDER"
                      )
                    },
                    on: { blur: _vm.$v.phoneNumber.$touch },
                    model: {
                      value: _vm.phoneNumber,
                      callback: function($$v) {
                        _vm.phoneNumber =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "phoneNumber"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("woot-input", {
                staticClass: "medium-12 columns content-margin",
                class: { error: _vm.$v.authToken.$error },
                attrs: {
                  required: "",
                  "has-error": _vm.$v.authToken.$error,
                  error: _vm.$t("INBOX_MGMT.ADD.INFOBIP.AUTH_TOKEN.ERROR"),
                  label: _vm.$t("INBOX_MGMT.ADD.INFOBIP.AUTH_TOKEN.LABEL"),
                  placeholder: _vm.$t(
                    "INBOX_MGMT.ADD.INFOBIP.AUTH_TOKEN.PLACEHOLDER"
                  )
                },
                on: { blur: _vm.$v.authToken.$touch },
                model: {
                  value: _vm.authToken,
                  callback: function($$v) {
                    _vm.authToken = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "authToken"
                }
              }),
              _vm._v(" "),
              _c("woot-input", {
                staticClass: "medium-12 columns content-margin",
                class: { error: _vm.$v.baseURL.$error },
                attrs: {
                  required: "",
                  "has-error": _vm.$v.baseURL.$error,
                  error: _vm.$t("INBOX_MGMT.ADD.INFOBIP.BASE_URL.ERROR"),
                  label: _vm.$t("INBOX_MGMT.ADD.INFOBIP.BASE_URL.LABEL"),
                  placeholder: _vm.$t(
                    "INBOX_MGMT.ADD.INFOBIP.BASE_URL.PLACEHOLDER"
                  )
                },
                on: { blur: _vm.$v.baseURL.$touch },
                model: {
                  value: _vm.baseURL,
                  callback: function($$v) {
                    _vm.baseURL = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "baseURL"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "medium-12 columns settings-sub-menu-bottom-nav" },
            [
              _c(
                "woot-base-button",
                {
                  attrs: { variant: "secondary" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return (function() {
                        return _vm.$router.go(-1)
                      })($event)
                    }
                  }
                },
                [_vm._v("\n        Back\n      ")]
              ),
              _vm._v(" "),
              _c(
                "woot-base-button",
                { attrs: { loading: _vm.uiFlags.isCreating, type: "submit" } },
                [_vm._v("\n        Next\n      ")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }