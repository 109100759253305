var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("top-banner", [
    _c(
      "div",
      { staticClass: "shake flex-row flex-align flex-justify full-width" },
      [
        _c("div", { staticClass: "subtitle-s2" }, [
          _vm._v(
            "\n      We've noticed one or more of your inboxes are currently inactive due to\n      authentication issues. Click\n      "
          ),
          _c("b", [_vm._v(" reauthenticate now ")]),
          _vm._v(" to ensure uninterrupted service.\n    ")
        ]),
        _vm._v(" "),
        _c(
          "woot-base-button",
          {
            staticClass: "mg-left",
            attrs: { size: "small" },
            on: { click: _vm.navigateToInboxPage }
          },
          [_vm._v("\n      Reauthenticate Now\n    ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }