var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "content-margin" }, [
        _c("h1", {
          staticClass: "title-h1 text-dark",
          domProps: {
            innerHTML: _vm._s(_vm.$t("ONBOARDING.WHATSAPP.WELCOME.HEADING"))
          }
        }),
        _vm._v(" "),
        _c("span", {
          staticClass: "body-b2 text-light",
          domProps: {
            innerHTML: _vm._s(_vm.$t("ONBOARDING.WHATSAPP.WELCOME.SUB_HEADING"))
          }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content-margin" }, [
        _c("span", {
          staticClass: "text-light body-b2 mg-bottom--small",
          domProps: {
            textContent: _vm._s(_vm.$t("ONBOARDING.WHATSAPP.LIST.HEADING"))
          }
        }),
        _vm._v(" "),
        _c(
          "ol",
          _vm._l(_vm.$t("ONBOARDING.WHATSAPP.LIST.ARRAY"), function(item, idx) {
            return _c("li", {
              key: idx,
              staticClass: "text-dark title-h4",
              domProps: { textContent: _vm._s(item) }
            })
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c(
        "reference-box",
        {
          staticClass: "reference-box content-margin",
          attrs: {
            content: _vm.$t(
              "ONBOARDING.WHATSAPP.INFO_TEXTS.IMPORTANT_INFORMATION.TITLE"
            )
          }
        },
        [
          _c("a", {
            staticClass: "body-b2 mg-top--small",
            attrs: {
              href: "https://www.limechat.ai",
              rel: "noopener noreferrer nofollow",
              target: "_blank"
            },
            domProps: {
              textContent: _vm._s(
                _vm.$t(
                  "ONBOARDING.WHATSAPP.INFO_TEXTS.IMPORTANT_INFORMATION.LINK"
                )
              )
            }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-row flex-justify--between" },
        [
          _c(
            "woot-base-button",
            {
              staticClass: "navigation-button",
              attrs: { variant: "secondary" },
              on: {
                click: function($event) {
                  return _vm.navigateTo(_vm.ONBOARDING_SCREENS.WELCOME.key)
                }
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("ONBOARDING.NAVIGATION.PREVIOUS")) +
                  "\n    "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "woot-base-button",
            {
              staticClass: "navigation-button",
              on: {
                click: function($event) {
                  return _vm.navigateTo(_vm.ONBOARDING_SCREENS.WA_FORM.key)
                }
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("ONBOARDING.NAVIGATION.NEXT")) +
                  "\n    "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }