var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "actions--container-new",
      style: _vm.dropdownContainerStyle
    },
    [
      !_vm.customButton && !_vm.showSearch
        ? _c(
            "div",
            {
              staticClass: "action--button-new flex-space-between",
              class: [
                _vm.cssClassName,
                _vm.id,
                _vm.customClass,
                { pressed: _vm.showDropdown }
              ],
              style: [_vm.buttonStyle, _vm.highlightButtonStyle],
              on: { click: _vm.toggleDropdown }
            },
            [
              _vm.frontIcon
                ? _c(
                    "span",
                    { staticStyle: { "margin-right": "0.2rem" } },
                    [
                      _c("icons", {
                        attrs: {
                          name: _vm.frontIcon,
                          color: _vm.highlightSelected
                            ? "green"
                            : _vm.frontIconColor,
                          size: _vm.iconSize
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showOptionName
                ? _c("span", {
                    staticClass: "selected-option title-h6_m text-truncate",
                    style: [_vm.optionStyle, _vm.highlightStyle],
                    attrs: {
                      title: _vm.defaultOption.name || _vm.defaultOption
                    },
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.staticTitle ||
                          _vm.defaultOption.name ||
                          _vm.defaultOption
                      )
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.icon
                ? _c(
                    "span",
                    { class: { "mg-left--small": _vm.showOptionName } },
                    [
                      _c("icons", {
                        attrs: {
                          name: _vm.icon,
                          size: _vm.iconSize,
                          color: _vm.iconColor,
                          "show-title": false
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        : _c(
            "div",
            {
              staticClass: "action--button-new flex-space-between",
              class: _vm.id,
              on: { click: _vm.toggleDropdown }
            },
            [_vm._t("default")],
            2
          ),
      _vm._v(" "),
      _vm.showSearch
        ? _c(
            "div",
            { on: { click: _vm.toggleDropdown } },
            [
              _c("woot-input", {
                attrs: {
                  size: _vm.size,
                  type: "text",
                  placeholder: _vm.searchPlaceholder
                },
                model: {
                  value: _vm.search,
                  callback: function($$v) {
                    _vm.search = $$v
                  },
                  expression: "search"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("transition", { attrs: { name: "dropdown" } }, [
        _vm.showDropdown
          ? _c(
              "ul",
              {
                staticClass: "dropdown-list dropdown--pane-new custom-scroll",
                class: { "dropdown--pane-new--open": _vm.showDropdown },
                style: [_vm.customStyle, _vm.dropDownPanePosition],
                attrs: { id: _vm.id }
              },
              [
                _vm._l(_vm.formattedOptions, function(option) {
                  return _c(
                    "li",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value:
                            _vm.showOptionTooltip && option[_vm.defaultKey],
                          expression: "showOptionTooltip && option[defaultKey]"
                        }
                      ],
                      key: option.id
                    },
                    [
                      !_vm.isSelected(option) || _vm.showAll
                        ? _c(
                            "button",
                            {
                              staticClass: "dropdown-button-new",
                              class: {
                                "dropdown-button-new--selected":
                                  (_vm.isSelected(option) && !_vm.disabled) ||
                                  option.selected,
                                "dropdown-button-new--disabled": option.disabled
                              },
                              attrs: { type: "button" },
                              on: {
                                click: function(e) {
                                  return _vm.onClick(option, e)
                                }
                              }
                            },
                            [
                              _vm.optionIcon
                                ? _c(
                                    "span",
                                    {
                                      staticStyle: { "margin-right": "0.4rem" }
                                    },
                                    [
                                      _c("icons", {
                                        attrs: {
                                          name: _vm.optionIcon,
                                          size: _vm.iconSize,
                                          color: _vm.isSelected(option)
                                            ? "white"
                                            : "darkestgrey"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              option.icon
                                ? _c(
                                    "span",
                                    {
                                      staticStyle: { "margin-right": "0.4rem" }
                                    },
                                    [
                                      _c("icons", {
                                        attrs: {
                                          name: option.icon,
                                          size: "normal",
                                          color: _vm.isSelected(option)
                                            ? "white"
                                            : option.color + " evenodd"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              option.availability
                                ? _c("span", {
                                    class:
                                      "availability-status availability-status--" +
                                      option.availability
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "option-name title-h6_m text-truncate"
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(option[_vm.defaultKey]) +
                                      "\n          "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _vm.showSelectedText
                                ? _c("span", { staticClass: "title-h6_m" }, [
                                    _vm.isSelected(option)
                                      ? _c("span", [_vm._v("Selected")])
                                      : _c(
                                          "span",
                                          { staticClass: "show-selected" },
                                          [_vm._v("Select")]
                                        )
                                  ])
                                : _vm._e()
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                }),
                _vm._v(" "),
                !_vm.formattedOptions.length
                  ? _c("li", [
                      _c(
                        "button",
                        {
                          staticClass:
                            "button action--button-new no-border-radius"
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.zeroState))])]
                      )
                    ])
                  : _vm._e()
              ],
              2
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }