var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "draggable-wrapper",
      style: _vm.customStyle,
      on: {
        touchstart: function($event) {
          $event.stopPropagation()
          return _vm.hang($event)
        },
        touchend: function($event) {
          $event.stopPropagation()
          return _vm.drop($event)
        },
        mousedown: function($event) {
          $event.stopPropagation()
          return _vm.hang($event)
        },
        mouseup: function($event) {
          $event.stopPropagation()
          return _vm.drop($event)
        }
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }