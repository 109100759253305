var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { staticClass: "header" },
    [
      _c("div", { staticClass: "table-actions-wrap" }, [
        _c("div", { staticClass: "left-aligned-wrap" }, [
          _c("h1", { staticClass: "page-title" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.$t("CONTACTS_PAGE.HEADER")) + "\n      "
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex-row" }, [
          _c(
            "div",
            {
              staticClass: "download-controls flex-row flex-align gap--normal"
            },
            [
              _c(
                "woot-base-button",
                {
                  attrs: { tag: "span", "front-icon": "download" },
                  on: { click: _vm.handleDownloadButtonClick }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("CONTACTS_PAGE.DOWNLOAD.TITLE")) +
                      "\n        "
                  )
                ]
              ),
              _vm._v(" "),
              _c("tags-filter", {
                attrs: {
                  value: "All Tags",
                  "default-key": "title",
                  "resource-type": "Contact",
                  "show-option-tooltip": false,
                  "toggle-filter": _vm.onChangeLabelIds
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "right-aligned-wrap flex-space-between" }, [
            _c(
              "div",
              { staticClass: "search-wrap" },
              [
                _c(
                  "search-box",
                  {
                    attrs: {
                      placeholder: "Search by " + _vm.defaultSelectedParam.name,
                      value: _vm.searchQuery,
                      variant: true,
                      "custom-class": "contact-search",
                      "on-search": _vm.onSearchSubmit
                    },
                    on: { setSearch: _vm.onInputSearch }
                  },
                  [
                    _c(
                      "woot-single-selector",
                      {
                        attrs: {
                          variant: "tertiary",
                          size: "large",
                          "default-option": _vm.defaultSelectedParam.name,
                          "custom-button": true,
                          "option-list": _vm.searchParams,
                          "custom-style": { left: "calc(100% - 13.6rem)" },
                          "top-position": { top: "3.3rem" }
                        },
                        on: { click: _vm.setSearchParam }
                      },
                      [
                        _c("div", { staticClass: "dropdown-button" }, [
                          _c(
                            "span",
                            { staticClass: "flex-row" },
                            [
                              _c("icons", {
                                attrs: {
                                  name: "chevronDown",
                                  size: "medium",
                                  color: "green"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _vm.isClosed
              ? _c("div", { staticClass: "toggle-container" }, [
                  _c(
                    "button",
                    {
                      staticClass: "toggle-button",
                      on: { click: _vm.onClose }
                    },
                    [
                      _c("icons", {
                        attrs: {
                          name: "chevronFilled",
                          size: "medium",
                          color: "green",
                          view: "0 0 24 24"
                        }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e()
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "modal-fade" } },
        [
          _vm.showDownloadModal
            ? _c("download-contacts-modal", {
                attrs: {
                  "on-close": function() {
                    return (_vm.showDownloadModal = false)
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }