var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasAttachments ||
    _vm.isQuotedContent ||
    _vm.data.content ||
    _vm.isAnEmail ||
    _vm.validContentType ||
    _vm.data.date
    ? _c(
        "li",
        {
          class: [
            _vm.alignBubble,
            {
              "email flex-column":
                _vm.isAnEmail &&
                [0, 1, 3, 9, 10, 11].includes(_vm.data.message_type)
            }
          ],
          attrs: { id: _vm.data.id }
        },
        [
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in" } },
            [
              _vm.showMediaSlider
                ? _c("media-slider", {
                    attrs: {
                      attachments: _vm.mediaAttachments,
                      "active-attachment": _vm.selectedMediaFileIndex
                    },
                    on: {
                      closeImageSlider: function($event) {
                        _vm.showMediaSlider = false
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { class: [_vm.wrapClass, { "padding-smaller": _vm.isActivity }] },
            [
              _vm.isDeleted
                ? _c("deleted-message-tag", {
                    attrs: {
                      "message-attributes": _vm.messageContentAttributes
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.data.date && !_vm.isAnEmail
                ? _c("div", { staticClass: "conversation-date-container" }, [
                    _c("span", {
                      staticClass: "conversation-date",
                      domProps: { innerHTML: _vm._s(_vm.data.date) }
                    })
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.showReplyDep && !_vm.showReply
                ? _c("reply-message-depricated", {
                    class: { "deleted-reply": _vm.isDeleted },
                    attrs: { data: _vm.inReplyDataDep, "enable-cancel": false }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.showReply
                ? _c(
                    "reply-message",
                    {
                      class: { "deleted-reply": _vm.isDeleted },
                      attrs: {
                        theme: _vm.replyMessageTheme,
                        "with-header": _vm.canShowReplyMessageHeader,
                        "actions-list": _vm.actionsList,
                        "get-reply-data": _vm.getReplyData
                      },
                      on: { onActionSelected: _vm.onReplyMessageActionSelected }
                    },
                    [
                      !_vm.data.date
                        ? _c("bubble-actions", {
                            attrs: {
                              "is-a-tweet": false,
                              "is-private": false,
                              "message-type": _vm.data.message_type,
                              "readable-time": _vm.readableTime,
                              "source-id": _vm.data.source_id
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isFirstIncomingComment && !_vm.showReply
                ? _c(
                    "div",
                    {
                      class: {
                        "with-avatar flex-row flex-align--end gap--six":
                          (_vm.isOutgoing || _vm.isOutOfOffice) &&
                          !_vm.isAnEmail,
                        "status-message--div": _vm.isActivity
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          class: [
                            _vm.bubbleClass,
                            { bubble: _vm.data.private && _vm.isAnEmail },
                            { "status-message--wrapper": _vm.isActivity }
                          ]
                        },
                        [
                          _vm.showIdentifier
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "flex-row flex-align mg-bottom--smaller"
                                },
                                [
                                  _c("icons", {
                                    attrs: { name: "forward", color: "grey" }
                                  }),
                                  _vm._v(" "),
                                  _c("p", {
                                    staticClass:
                                      "capitalize body-b3 mg-left--micro",
                                    domProps: {
                                      textContent: _vm._s(_vm.identifierToText)
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.data.private
                            ? _c("div", { staticClass: "note-heading" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "flex-row-justify-center flex-center"
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "note-heading--circle"
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "note-heading--text" },
                                      [_vm._v(_vm._s("#Note"))]
                                    ),
                                    _vm._v(" "),
                                    _vm.isAnEmail
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "body-b4 text-light forwarded-text"
                                          },
                                          [
                                            _vm._v(
                                              "\n              by " +
                                                _vm._s(_vm.data.sender.name) +
                                                "\n            "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "note-heading--icon" },
                                  [
                                    _c("icons", {
                                      attrs: {
                                        name: "lock",
                                        color: "primary",
                                        size: "medium"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isCards
                            ? _c(
                                "span",
                                _vm._l(_vm.getItems, function(card) {
                                  return _c(
                                    "span",
                                    { key: card.id },
                                    [
                                      card.media_url &&
                                      !_vm.videoFileType(card.media_url)
                                        ? _c("bubble-image", {
                                            attrs: {
                                              url: card.media_url,
                                              "readable-time": _vm.readableTime
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      card.media_url &&
                                      _vm.videoFileType(card.media_url)
                                        ? _c("bubble-file", {
                                            attrs: {
                                              url: card.media_url,
                                              "readable-time": _vm.readableTime
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("bubble-text", {
                                        attrs: {
                                          message: _vm.getCardText(card),
                                          "readable-time": _vm.readableTime,
                                          data: _vm.data
                                        }
                                      }),
                                      _vm._v(" "),
                                      card.actions
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "flex-column flex-align mg-top--smaller"
                                            },
                                            _vm._l(card.actions, function(
                                              button,
                                              inx
                                            ) {
                                              return _c(
                                                "woot-secondary-button",
                                                {
                                                  key: inx,
                                                  attrs: {
                                                    size: "small",
                                                    "custom-class":
                                                      "mg-bottom--smaller read-only-button",
                                                    "full-width": "",
                                                    name: button.text
                                                  }
                                                }
                                              )
                                            }),
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isPending &&
                          _vm.isAnEmail &&
                          !_vm.data.private &&
                          _vm.data.message_type === 1
                            ? _c("line-chart-skeleton", {
                                attrs: { "chart-height": "1rem" }
                              })
                            : !_vm.showReply
                            ? [
                                !_vm.data.private &&
                                _vm.isAnEmail &&
                                [0, 1, 3, 9, 10, 11].includes(
                                  _vm.data.message_type
                                )
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex-row flex-justify--between flex-align--start email-head",
                                        on: {
                                          click: function($event) {
                                            _vm.isExpanded = true
                                          }
                                        }
                                      },
                                      [
                                        _c("bubble-mail-head", {
                                          attrs: {
                                            "email-attributes":
                                              _vm.contentAttributes.email,
                                            recipient:
                                              _vm.contentAttributes.to_emails ||
                                              _vm.contentAttributes.to,
                                            "message-type":
                                              _vm.data.message_type,
                                            "reply-time-stamp":
                                              _vm.data.created_at || 0,
                                            cc: _vm.emailHeadAttributes.cc,
                                            bcc: _vm.emailHeadAttributes.bcc,
                                            "is-first-email": _vm.isFirstEmail,
                                            sender: _vm.sender,
                                            "content-type":
                                              _vm.data.content_type
                                          }
                                        }),
                                        _vm._v(" "),
                                        [0, 1, 9, 10, 11].includes(
                                          _vm.data.message_type
                                        )
                                          ? _c(
                                              "div",
                                              { staticClass: "email-action" },
                                              [
                                                _c("woot-single-selector", {
                                                  attrs: {
                                                    variant: "tertiary",
                                                    "custom-style": {
                                                      top: "2.4rem",
                                                      width: "auto",
                                                      left: "auto",
                                                      right: "0.8rem",
                                                      borderRadius: "0.8rem"
                                                    },
                                                    "custom-class": _vm.disableEmailButtons
                                                      ? "disable-email-dropdown"
                                                      : "",
                                                    "option-list":
                                                      _vm.getEmailActionList,
                                                    "icon-size-prop":
                                                      "semimedium",
                                                    icon: "threeDot",
                                                    "show-selected-text": false,
                                                    "show-option-name": false
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.onEmailActionSelected
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isAnEmail && _vm.hasText && _vm.canExpand
                                  ? _c(
                                      "div",
                                      {
                                        class: {
                                          "is-collapsed": !_vm.isExpanded
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.isExpanded = true
                                          }
                                        }
                                      },
                                      [
                                        _c("bubble-text", {
                                          attrs: {
                                            message: _vm.truncateCollapseMessage(
                                              _vm.getPlainText(
                                                _vm.message.replace(
                                                  /<[^>]*>?/gm,
                                                  ""
                                                )
                                              )
                                            ),
                                            "is-deleted": _vm.isDeleted,
                                            "is-email": _vm.isAnEmail,
                                            "readable-time": _vm.readableTime,
                                            "display-quoted-button": false,
                                            data: _vm.data
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm.hasText &&
                                    !_vm.hasAttachments &&
                                    !_vm.isAnEmail &&
                                    !_vm.isGptSummary
                                  ? _c("bubble-text", {
                                      class: _vm.isActivity
                                        ? "status-message--text"
                                        : "",
                                      attrs: {
                                        message: _vm.message,
                                        "is-deleted": _vm.isDeleted,
                                        "is-email": _vm.isAnEmail,
                                        "readable-time": _vm.readableTime,
                                        "display-quoted-button": false,
                                        data: _vm.data
                                      }
                                    })
                                  : _vm.hasText && _vm.isAnEmail
                                  ? _c("bubble-text-shadowed", {
                                      class: _vm.isActivity
                                        ? "status-message--text"
                                        : "",
                                      attrs: {
                                        message: _vm.message,
                                        "is-deleted": _vm.isDeleted,
                                        "is-email": _vm.isAnEmail,
                                        "readable-time": _vm.readableTime,
                                        "display-quoted-button": false,
                                        "email-signature": _vm.emailSignature,
                                        data: _vm.data
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.hasText &&
                                _vm.isQuotedContent &&
                                !_vm.canExpand
                                  ? _c("quoted-text", {
                                      attrs: {
                                        message: _vm.quotedContent,
                                        "is-email":
                                          _vm.isEmailContentType ||
                                          _vm.isQuotedContent
                                      }
                                    })
                                  : _vm._e()
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.templateData && _vm.templateData.buttons
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "flex-column flex-align mg-top--smaller"
                                },
                                _vm._l(_vm.templateData.buttons, function(
                                  button,
                                  inx
                                ) {
                                  return _c("woot-secondary-button", {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip.top",
                                        value: _vm.getButtonLink(button),
                                        expression: "getButtonLink(button)",
                                        modifiers: { top: true }
                                      }
                                    ],
                                    key: inx,
                                    attrs: {
                                      size: "small",
                                      "custom-class": [
                                        "mg-bottom--smaller",
                                        !_vm.templateData.button_url &&
                                          "read-only-button"
                                      ].join(" "),
                                      "full-width": "",
                                      name: button.title
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.templateData.button_url &&
                                          _vm.openLink(
                                            _vm.getButtonLink(button)
                                          )
                                      }
                                    }
                                  })
                                }),
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isSticker
                            ? _c("bubble-image", {
                                attrs: {
                                  url: _vm.contentAttributes.sticker,
                                  "readable-time": _vm.readableTime
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isProductList
                            ? _c(
                                "span",
                                _vm._l(_vm.getItems, function(productItem) {
                                  return _c(
                                    "span",
                                    { key: productItem.id },
                                    [
                                      _c("bubble-text", {
                                        attrs: {
                                          message: _vm.getProductText(
                                            productItem
                                          ),
                                          "readable-time": _vm.readableTime,
                                          data: _vm.data
                                        }
                                      })
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isPending && _vm.hasAttachments
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "chat-bubble has-attachment agent",
                                  staticStyle: { "margin-bottom": "1rem" }
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(
                                        _vm.$t(
                                          "CONVERSATION.UPLOADING_ATTACHMENTS"
                                        )
                                      ) +
                                      "\n        "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.isPending &&
                          _vm.hasAttachments &&
                          _vm.isAnEmail &&
                          !_vm.canExpand
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "flex-row flex-justify--start files-container"
                                },
                                _vm._l(_vm.data.attachments, function(
                                  attachment
                                ) {
                                  return _c(
                                    "div",
                                    { key: attachment.id },
                                    [
                                      _c("email-file", {
                                        attrs: {
                                          id: "" + attachment.id,
                                          "attachment-type":
                                            attachment.file_type,
                                          "attachment-url": attachment.data_url
                                        },
                                        on: {
                                          openMediaSlider: _vm.onOpenMediaSlider
                                        }
                                      })
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isPending &&
                          _vm.hasText &&
                          _vm.hasAttachments &&
                          !_vm.isAnEmail
                            ? _c("bubble-text", {
                                attrs: {
                                  message: _vm.message,
                                  "is-deleted": _vm.isDeleted,
                                  "is-email": false,
                                  "readable-time": _vm.readableTime,
                                  "display-quoted-button": false,
                                  data: _vm.data
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.isPending && _vm.hasAttachments && !_vm.isAnEmail
                            ? _c(
                                "div",
                                _vm._l(_vm.data.attachments, function(
                                  attachment
                                ) {
                                  return _c(
                                    "div",
                                    { key: attachment.id },
                                    [
                                      attachment.file_type === "image"
                                        ? _c("bubble-image", {
                                            attrs: {
                                              url: attachment.data_url,
                                              "has-attached-content": !!_vm.data
                                                .content,
                                              message: _vm.message,
                                              "is-deleted": _vm.isDeleted,
                                              "readable-time": _vm.readableTime
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      attachment.file_type !== "image"
                                        ? _c("bubble-file", {
                                            attrs: {
                                              "attachment-type":
                                                attachment.file_type,
                                              url: attachment.data_url,
                                              "has-attached-content": !!_vm.data
                                                .content,
                                              message: _vm.message,
                                              "is-deleted": _vm.isDeleted,
                                              "readable-time": _vm.readableTime
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.data.date && !_vm.isGptSummary && !_vm.showReply
                            ? _c("bubble-actions", {
                                class: _vm.isActivity
                                  ? "status-message--actions"
                                  : "",
                                attrs: {
                                  id: _vm.data.id,
                                  status: _vm.data.status,
                                  details: _vm.messageStatusDetails,
                                  sender: _vm.data.sender,
                                  "is-an-email": _vm.isAnEmail,
                                  "is-a-tweet": _vm.isATweet,
                                  "is-private": _vm.data.private,
                                  "message-type": _vm.data.message_type,
                                  "readable-time": _vm.readableTime,
                                  "source-id": _vm.data.source_id
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showMoreOption
                            ? _c(
                                "div",
                                { staticClass: "bubble-more-action" },
                                [
                                  _c(
                                    "woot-single-selector",
                                    {
                                      attrs: {
                                        variant: "tertiary",
                                        icon: "share",
                                        size: "normal",
                                        "custom-class": "selector-button",
                                        "custom-style": {
                                          top: "1.4rem",
                                          left: _vm.isOutgoing
                                            ? "-12.5rem"
                                            : "0",
                                          borderRadius: "0.8rem",
                                          padding: "0.4rem"
                                        },
                                        "custom-button": true,
                                        "option-list": _vm.getActionList,
                                        "show-selected-text": false,
                                        "show-option-name": false
                                      },
                                      on: { click: _vm.toggleAction }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "selector-button reply-button"
                                        },
                                        [
                                          _c("icons", {
                                            attrs: {
                                              name: "threeDot",
                                              color: "green-stroke",
                                              size: "normal"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm.isGptSummary
                        ? _c("summary-bubble", { attrs: { message: _vm.data } })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.shouldShowAvatar
                        ? _c(
                            "agent-avatar",
                            _vm._b(
                              {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "hover-state",
                                      fn: function() {
                                        return [
                                          _c(
                                            "agent-info-card",
                                            _vm._b(
                                              {},
                                              "agent-info-card",
                                              _vm.cardInfo,
                                              false
                                            )
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  2120717737
                                )
                              },
                              "agent-avatar",
                              _vm.cardInfo,
                              false
                            )
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isATweet && _vm.isIncoming && _vm.sender
                ? _c(
                    "a",
                    {
                      staticClass: "sender--info",
                      attrs: {
                        href: _vm.twitterProfileLink,
                        target: "_blank",
                        rel: "noopener noreferrer nofollow"
                      }
                    },
                    [
                      _c("woot-thumbnail", {
                        attrs: {
                          src: _vm.sender.thumbnail,
                          username: _vm.sender.name,
                          size: "16px"
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "sender--available-name" }, [
                        _vm._v(
                          "\n        " + _vm._s(_vm.sender.name) + "\n      "
                        )
                      ])
                    ],
                    1
                  )
                : _vm.isOutgoing
                ? _c("span", { staticStyle: { width: "2rem" } })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("bubble-tooltip", {
            attrs: {
              "is-sent": _vm.sentByMessage,
              "is-deleted": _vm.isDeleted,
              name: _vm.sender.name
            }
          }),
          _vm._v(" "),
          _c("delete-modal", {
            attrs: {
              show: _vm.showDeleteModal,
              message: "Are you sure you want to delete this message?",
              "confirm-text": "Yes, Delete",
              "reject-text": "Cancel",
              "on-close": _vm.toggleDeleteModal,
              "on-confirm": _vm.deleteMessage
            }
          }),
          _vm._v(" "),
          _vm.isLatestEmail && !_vm.isReplyBoxOpen
            ? _c(
                "div",
                { staticClass: "buttons-container flex-row" },
                [
                  _c(
                    "woot-base-button",
                    {
                      attrs: {
                        variant: "secondary",
                        size: "medium",
                        "icon-size": "medium",
                        "front-icon": "reply",
                        disabled: _vm.disableEmailButtons
                      },
                      on: {
                        click: function($event) {
                          return _vm.onEmailActionSelected({ value: "reply" })
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.$t("CONVERSATION.REPLYBOX.REPLY")) +
                          "\n    "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm.hasMultipleRecepients
                    ? _c(
                        "woot-base-button",
                        {
                          attrs: {
                            size: "medium",
                            variant: "secondary",
                            "icon-size": "semimedium",
                            "front-icon": "replyAll",
                            disabled: _vm.disableEmailButtons
                          },
                          on: {
                            click: function($event) {
                              return _vm.onEmailActionSelected({
                                value: "reply_to_all"
                              })
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n      " +
                              _vm._s(
                                _vm.$t("CONVERSATION.REPLYBOX.REPLY_TO_ALL")
                              ) +
                              "\n    "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "woot-base-button",
                    {
                      attrs: {
                        size: "medium",
                        variant: "secondary",
                        "icon-size": "semimedium",
                        "front-icon": "forward",
                        disabled: _vm.disableEmailButtons
                      },
                      on: {
                        click: function($event) {
                          return _vm.onEmailActionSelected({ value: "forward" })
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.$t("CONVERSATION.REPLYBOX.FORWARD")) +
                          "\n    "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "woot-base-button",
                    {
                      attrs: {
                        size: "medium",
                        variant: "secondary",
                        "icon-size": "medium",
                        "front-icon": "lock"
                      },
                      on: {
                        click: function($event) {
                          return _vm.$emit("onPrivateReply")
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.$t("CONVERSATION.REPLYBOX.PRIVATE_NOTE")) +
                          "\n    "
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }