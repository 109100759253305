var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    {
      attrs: {
        show: _vm.showModal,
        "on-close": _vm.hideModal,
        "show-close": false,
        "small-width": true
      },
      on: {
        "update:show": function($event) {
          _vm.showModal = $event
        }
      }
    },
    [
      _c("div", { staticClass: "feature-intro-modal" }, [
        _c("p", { staticClass: "modal-heading" }, [
          _vm._v("\n      " + _vm._s(_vm.content.heading) + "\n    ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "media-container" }, [
          _c("img", {
            attrs: { src: "/brand-assets/feature1_illustration.svg" }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "feature-container" }, [
          _c("div", { staticClass: "body-b2 text-dark" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.content.modal_content) + "\n      "
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button-container flex-space-between",
            staticStyle: { "justify-content": "flex-end" }
          },
          [
            _vm.currentRole === "administrator"
              ? _c("woot-primary-button", {
                  attrs: {
                    size: "small",
                    "custom-class": "primary-button",
                    name: _vm.content.show_cta ? "Browse Plans" : "GOT IT!"
                  },
                  on: { click: _vm.hideModal }
                })
              : _c("woot-primary-button", {
                  attrs: {
                    size: "small",
                    "custom-class": "primary-button",
                    name: "GOT IT!"
                  },
                  on: { click: _vm.closeModal }
                })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }