var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    {
      staticClass: "ticket-creation-success-modal",
      attrs: {
        "show-close": "",
        show: _vm.show,
        "on-close": function() {
          return _vm.$emit("closeModal")
        }
      },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c("header", { staticClass: "modal-header" }, [
        _c("img", {
          staticClass: "image",
          attrs: {
            src:
              "https://ik.imagekit.io/vysbzd9hl/ticket_creation_header_illustration.svg",
            alt: "welcome-illustration"
          }
        })
      ]),
      _vm._v(" "),
      _c("main", { staticClass: "modal-content" }, [
        _c("h1", {
          staticClass: "title-h1 content-margin",
          domProps: {
            textContent: _vm._s(_vm.$t("CREATE_TICKET.FORM.SUBMIT.SUCCESS"))
          }
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "show-created-tickets mg-bottom--large" },
          _vm._l(_vm.contacts, function(ref, idx) {
            var name = ref.name
            var email_address = ref.email_address
            var phone_number = ref.phone_number
            return _c(
              "div",
              { key: idx, staticClass: "ticket-details" },
              [
                _c("icons", {
                  attrs: {
                    name: "ticket",
                    size: "semimedium",
                    color: "darkgrey"
                  }
                }),
                _vm._v(" "),
                _vm.inboxType === "whatsapp"
                  ? _c("span", {
                      staticClass: "body-b2 text-dark",
                      domProps: { textContent: _vm._s("+" + phone_number) }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.inboxType === "email"
                  ? _c("span", {
                      staticClass: "body-b2 text-dark",
                      domProps: { textContent: _vm._s(email_address) }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("span", {
                  staticClass:
                    "title-h5_m text-light ticket-contact-name line-clamp-1",
                  domProps: { textContent: _vm._s(name) }
                })
              ],
              1
            )
          }),
          0
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }