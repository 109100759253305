var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isMp4(_vm.url) || _vm.attachmentType === "video"
        ? _c("video-view", { staticClass: "file", attrs: { url: _vm.url } })
        : _vm.attachmentType === "audio"
        ? _c("audio", { attrs: { controls: "" } }, [
            _c("source", { attrs: { src: _vm.url, type: "audio/ogg" } }),
            _vm._v(" "),
            _c("source", { attrs: { src: _vm.url, type: "audio/mpeg" } }),
            _vm._v("\n    Your browser does not support the audio element.\n  ")
          ])
        : _c(
            "div",
            {
              staticClass: "file message-text__wrap",
              on: { click: _vm.openLink }
            },
            [
              _c("div", { staticClass: "icon-wrap" }, [
                _c("i", { staticClass: "ion-document-text" })
              ]),
              _vm._v(" "),
              _c("div", [
                _c("h5", { staticClass: "text-block-title" }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.fileName(_vm.url)) + "\n      "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "download clear button small",
                    attrs: {
                      rel: "noreferrer noopener nofollow",
                      target: "_blank",
                      href: _vm.url
                    }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("CONVERSATION.DOWNLOAD")) +
                        "\n      "
                    )
                  ]
                )
              ])
            ]
          ),
      _vm._v(" "),
      _vm.hasAttachedContent
        ? _c("span", {
            staticClass: "message-span",
            class: {
              "message-deleted": _vm.isDeleted
            },
            domProps: { innerHTML: _vm._s(_vm.message) }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }