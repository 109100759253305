var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "network-notification-fade", tag: "div" } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showNotification && _vm.bannerText,
              expression: "showNotification && bannerText"
            }
          ]
        },
        [
          _c(
            "div",
            [
              _c("top-banner", { attrs: { variant: _vm.bannerVariant } }, [
                _c(
                  "div",
                  {
                    staticClass: "flex-row flex-align flex-justify full-width"
                  },
                  [
                    _c("span", { staticClass: "subtitle-s2" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.bannerText) +
                          "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _vm.canRefresh
                      ? _c("woot-button", {
                          attrs: {
                            title:
                              _vm.$t("NETWORK.BUTTON.REFRESH") || "refresh",
                            variant: "clear",
                            size: "small",
                            "color-scheme": "warning",
                            icon: "arrow-clockwise"
                          },
                          on: { click: _vm.refreshPage }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("woot-button", {
                      attrs: {
                        variant: "clear",
                        size: "small",
                        "color-scheme": "warning",
                        icon: "dismiss"
                      },
                      on: { click: _vm.closeNotification }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }