var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-row flex-justify" },
    [
      _c("woot-base-button", { on: { click: _vm.openWidget } }, [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.$t(
                "INTEGRATION_SETTINGS.CRM.INTEGRATIONS.FRESHDESK.OVERVIEW_PANEL.FORM.CREATE_TICKET"
              )
            ) +
            "\n  "
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }