var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "filter-button",
      class: { "filter-button--active": _vm.active },
      attrs: { type: "button", name: _vm.name, icon: _vm.icon },
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [
      _c(
        "span",
        { staticStyle: { "margin-bottom": "0.2rem" } },
        [
          _vm.icon
            ? _c("icons", {
                attrs: {
                  name: _vm.icon,
                  color: _vm.active ? "green" : "stroke-grey"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "filter-text",
          class: { "filter-text--active": _vm.active }
        },
        [_vm._v(_vm._s(_vm.name))]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }