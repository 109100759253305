import marked from 'marked';
import DOMPurify from 'dompurify';
import { escapeHtml, afterSanitizeAttributes } from './HTMLSanitizer';

const TWITTER_USERNAME_REGEX = /(^|[^@\w])@(\w{1,15})\b/g;
const TWITTER_USERNAME_REPLACEMENT =
  '$1<a href="http://twitter.com/$2" target="_blank" rel="noreferrer nofollow noopener">@$2</a>';

const TWITTER_HASH_REGEX = /(^|\s)#(\w+)/g;
const TWITTER_HASH_REPLACEMENT =
  '$1<a href="https://twitter.com/hashtag/$2" target="_blank" rel="noreferrer nofollow noopener">#$2</a>';

const STRONG_REGEX = /^(?:(\*))([^*]*)(?:\*)/g;

class MessageFormatter {
  constructor(message, isATweet = false, isAnEmail = false) {
    this.message = DOMPurify.sanitize(escapeHtml(message) || '');
    this.isATweet = isATweet;
    this.isAnEmail = isAnEmail;
    this.marked = marked;

    const renderer = {
      heading(text) {
        return `<strong>${text}</strong>`;
      },
      link(url, title, text) {
        return `<a rel="noreferrer noopener nofollow" href="${url}" class="link" title="${title ||
          ''}" target="_blank">${text}</a>`;
      },
    };

    let tokenizer = null;

    if (!this.isAnEmail) {
      tokenizer = {
        strong(text) {
          const rule = STRONG_REGEX;
          const match = rule.exec(text);
          if (match) {
            return {
              type: 'strong',
              raw: match[0],
              text: match[2].trim(),
            };
          }
          return false;
        },
      };
    }
    this.marked.use({ renderer, tokenizer });
  }

  formatMessage() {
    if (this.isATweet) {
      const withUserName = this.message.replace(
        TWITTER_USERNAME_REGEX,
        TWITTER_USERNAME_REPLACEMENT
      );
      const withHash = withUserName.replace(
        TWITTER_HASH_REGEX,
        TWITTER_HASH_REPLACEMENT
      );
      const markedDownOutput = marked(withHash);
      return markedDownOutput;
    }

    if (this.isAnEmail) {
      DOMPurify.addHook('afterSanitizeAttributes', afterSanitizeAttributes);
      return DOMPurify.sanitize(
        marked(this.message.replace(/&gt;+/g, '>'), { breaks: true, gfm: true })
      );
    }

    return marked(this.message);
  }

  get formattedMessage() {
    return this.formatMessage();
  }

  get plainText() {
    const strippedOutHtml = new DOMParser().parseFromString(
      this.formattedMessage,
      'text/html'
    );
    return strippedOutHtml.body.textContent || '';
  }
}

export default MessageFormatter;
