var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "create-ticket-form-with-preview flex-row flex-justify--between gap--normal"
    },
    [
      _c(
        "form",
        {
          staticClass: "create-ticket-form custom-scroll",
          on: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c("add-contact-section", {
            staticClass: "CREATE_TICKET_CONTACTS",
            attrs: { "selected-contacts": _vm.selectedContacts },
            on: {
              selectedContacts: function($event) {
                _vm.selectedContacts = $event
              }
            }
          }),
          _vm._v(" "),
          _c("add-template-section", {
            attrs: {
              "inbox-id": _vm.inboxId,
              "number-of-substitutions": _vm.selectedContacts.length
            },
            on: {
              templateInputs: function($event) {
                _vm.templateInputs = $event
              },
              selectedTemplate: function($event) {
                _vm.selectedTemplate = $event
              }
            }
          }),
          _vm._v(" "),
          _c("assign-agent-or-team-section", {
            on: { select: _vm.onAgentOrTeamSelection }
          }),
          _vm._v(" "),
          _c("assign-tags-section", {
            attrs: { "tag-type": "Conversation" },
            on: {
              selectedLabels: function($event) {
                _vm.selectedConvLabels = $event
              }
            }
          }),
          _vm._v(" "),
          _c("assign-tags-section", {
            attrs: { "tag-type": "Contact" },
            on: {
              selectedLabels: function($event) {
                _vm.selectedContactLabels = $event
              }
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "submit-button" },
            [
              _c(
                "woot-base-button",
                {
                  attrs: {
                    "front-icon": "rocket",
                    loading: _vm.uiFlags.isCreatingConversation
                  },
                  on: { click: _vm.onSubmit }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("CREATE_TICKET.FORM.SUBMIT.TITLE")) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("whatsapp-ui", {
        staticClass: "whatsapp-ui-wrapper",
        attrs: {
          messages: _vm.messages,
          "only-chat-screen": "",
          "custom-height": "height: calc(100vh - 20rem)"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }