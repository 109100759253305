var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-align" }, [
    _c("div", { staticClass: "mg-bottom--large" }, [
      _c("h1", {
        staticClass: "title-h1 text-dark",
        domProps: { innerHTML: _vm._s(_vm.$t("ONBOARDING.WELCOME.HEADING")) }
      }),
      _vm._v(" "),
      _c("span", {
        staticClass: "body-b2 text-light",
        domProps: {
          innerHTML: _vm._s(_vm.$t("ONBOARDING.WELCOME.SUB_HEADING"))
        }
      })
    ]),
    _vm._v(" "),
    _c(
      "div",
      [
        _c(
          "woot-base-button",
          {
            staticClass: "navigation-button mg-auto",
            on: { click: _vm.onStartOnboarding }
          },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("ONBOARDING.WELCOME.START")) + "\n    "
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }