var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.uiFlags.fetchingOrders
    ? _c("table-skeleton", { staticClass: "orders-table-skeleton" })
    : _c("woot-table", {
        staticClass: "deep-shopify-orders-table lime-card-1dp",
        attrs: {
          title: _vm.$t(
            "ANALYTICS.SHOPIFY_ANALYTICS.TABLES.ORDERS_TABLE.TITLE"
          ),
          "header-list": _vm.tableHeader,
          "table-data": _vm.formattedTableData
        },
        scopedSlots: _vm._u([
          {
            key: "filters",
            fn: function() {
              return [
                _c("woot-single-selector", {
                  staticClass: "small-2 mg-bottom--slab mg-top--slab",
                  attrs: {
                    variant: "secondary",
                    "custom-style": { width: "auto" },
                    "default-option": _vm.selectedStatusTitle,
                    "option-list": _vm.statusOptions,
                    "show-selected-text": false
                  },
                  on: { click: _vm.applyStatus }
                })
              ]
            },
            proxy: true
          },
          {
            key: "bottom",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "table-pagination" },
                  [
                    _c("ve-pagination", {
                      attrs: {
                        total: _vm.ordersMeta.total_entries,
                        "page-index": _vm.ordersMeta.current_page,
                        "page-size": 5,
                        "paging-count": 2,
                        layout: ["total", "prev", "pager", "next"]
                      },
                      on: { "on-page-number-change": _vm.onPageNumberChange }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }