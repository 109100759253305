var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [_c("loading-state", { attrs: { message: "Taking you to Login..." } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }