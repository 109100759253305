var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "text-align mg-bottom--larger" }, [
      _c(
        "div",
        {
          staticClass: "flex-row flex-justify flex-align gap--small mg-bottom"
        },
        [
          _c("icons", {
            attrs: {
              name: "tickFilled",
              size: "medium",
              color: "green",
              "show-title": false
            }
          }),
          _vm._v(" "),
          _c("span", {
            staticClass: "title-h1 text-dark",
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("ONBOARDING.WHATSAPP.FINISH_SETUP.HEADING")
              )
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("span", {
        staticClass: "body-b2 text-light",
        domProps: {
          innerHTML: _vm._s(
            _vm.$t("ONBOARDING.WHATSAPP.FINISH_SETUP.SUB_HEADING")
          )
        }
      })
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "flex-row flex-justify--end" },
      [
        _c("woot-base-button", { on: { click: _vm.onCloseModal } }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.$t("ONBOARDING.WHATSAPP.FINISH_SETUP.EXPLORE_HELPDESK")
              ) +
              "\n    "
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }