var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      class: [
        "tab-element flex-row flex-align flex-justify",
        { "is-active": _vm.active }
      ],
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.onTabClick($event)
        }
      }
    },
    [
      _c("span", {
        class: {
          "title-h4 text-dark": _vm.active,
          "title-h5_m text-light": !_vm.active
        },
        domProps: { textContent: _vm._s(_vm.name) }
      }),
      _vm._v(" "),
      _c("span", { staticClass: "tab-element--bar" })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }