var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "filter flex-column gap--small" },
    _vm._l(_vm.filterList, function(group, groupIndex) {
      return _c("div", { key: groupIndex }, [
        group.GROUP_NAME
          ? _c("div", {
              staticClass: "title-h6_m text-light mg-bottom--smaller",
              domProps: { textContent: _vm._s(group.GROUP_NAME) }
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "filters-container flex-row flex-wrap flex-align--start gap--small"
          },
          [
            _vm._l(group.FILTERS, function(item) {
              return [
                item.key === "assignee_type"
                  ? _c("woot-single-selector", {
                      key: item.key,
                      attrs: {
                        size: "small",
                        "button-style": {
                          borderRadius: "4px",
                          color: "#808975",
                          padding: "0.4rem 0.6rem"
                        },
                        "custom-style": { left: 0 },
                        "show-selected-text": false,
                        "highlight-selected": "",
                        "default-option": _vm.defaultAssigneeTabOption.name,
                        "option-list": _vm.assigneeTabsList
                      },
                      on: { click: _vm.onUpdateAssigneeTab }
                    })
                  : item.key === "inbox"
                  ? _c("woot-single-selector", {
                      key: item.key,
                      class: "all-" + item.key + "--filter",
                      attrs: {
                        "front-icon": _vm.inboxFilterIcon
                          ? _vm.inboxFilterIcon
                          : "inbox",
                        size: "small",
                        "option-list": _vm.modifiedInboxList,
                        "highlight-selected": _vm.highlightInboxName,
                        "button-style": {
                          borderRadius: "4px",
                          color: "#808975",
                          padding: "0.4rem"
                        },
                        "custom-style": {
                          left: 0,
                          width: "20rem",
                          "overflow-x": "hidden"
                        },
                        "show-selected-text": false,
                        "show-option-tooltip": true,
                        "static-title": item.static_title,
                        "default-option": _vm.selectedInboxComputed
                      },
                      on: { click: _vm.applyInboxFilter }
                    })
                  : item.key === "sla_breached"
                  ? _c("woot-single-selector", {
                      key: item.key,
                      staticClass: "sla-filter",
                      attrs: {
                        size: "small",
                        "front-icon": item.icon,
                        "option-list": _vm.slaOptions,
                        "default-option": _vm.selectedSlaOption,
                        "button-style": {
                          borderRadius: "4px",
                          color: "#808975",
                          padding: "0.4rem 0.6rem"
                        },
                        "custom-style": {
                          left: 0,
                          width: "20rem",
                          "overflow-x": "hidden"
                        },
                        "show-selected-text": false
                      },
                      on: { click: _vm.onSlaChange }
                    })
                  : item.key === "relevance"
                  ? _c("woot-multi-selector", {
                      key: item.key,
                      ref: "multiSelector",
                      refInFor: true,
                      attrs: {
                        size: "small",
                        "front-icon": item.icon,
                        "option-list": _vm.filters,
                        "show-selected-text": false,
                        "default-option": "Relevance",
                        "prefix-text":
                          "<b style='color: #6bac1b'>Sorted By: </b>",
                        "toggle-filter": _vm.handleSelections,
                        "button-style": {
                          borderRadius: "4px",
                          color: "#808975",
                          padding: "0.4rem"
                        },
                        "custom-style": {
                          left: 0,
                          width: "20rem",
                          "overflow-x": "hidden"
                        }
                      },
                      on: {
                        "dropdown-opened": _vm.handleDropdownOpened,
                        "selected-option": _vm.handleSelectedOption
                      }
                    })
                  : item.key === "agent"
                  ? _c("agents-filter", {
                      key: item.key,
                      attrs: {
                        item: item,
                        "selected-agent-id": _vm.appliedFilters.selectedAgent,
                        "toggle-filter": _vm.filterToggle
                      }
                    })
                  : item.key === "label" && _vm.labels
                  ? _c("tags-filter", {
                      key: item.key,
                      ref: "tagsFilter",
                      refInFor: true,
                      staticClass: "filter--button",
                      attrs: {
                        "highlight-selected": "",
                        size: "small",
                        "front-icon": item.icon,
                        "static-title": item.static_title,
                        value: item.value,
                        "button-style": {
                          borderRadius: "4px",
                          color: "#808975",
                          padding: "0.4rem"
                        },
                        "toggle-filter": function(_, __, value) {
                          return _vm.filterToggle("label", __, value)
                        }
                      },
                      on: { labelSelected: _vm.handleLabelClick }
                    })
                  : item.key === "teams"
                  ? _c("teams-filter", {
                      key: item.key,
                      attrs: {
                        item: item,
                        "selected-team-id": _vm.appliedFilters.teamId,
                        "toggle-filter": _vm.filterToggle
                      }
                    })
                  : item.key === "assigned_by" && _vm.showAssignedByFilter
                  ? _c("woot-single-selector", {
                      key: item.key,
                      attrs: {
                        size: "small",
                        "front-icon": item.icon,
                        "option-list": _vm.assignedByOptions,
                        "default-option": _vm.assignedByOption,
                        "highlight-selected": _vm.highlightAssignedBy,
                        "button-style": {
                          borderRadius: "4px",
                          color: "#808975",
                          padding: "0.4rem 0.6rem"
                        },
                        "custom-style": {
                          left: 0,
                          width: "20rem",
                          "overflow-x": "hidden"
                        },
                        "show-selected-text": false
                      },
                      on: { click: _vm.onAssignedByChange }
                    })
                  : item.key === "resolved_by" && _vm.showResolvedByFilter
                  ? _c("woot-single-selector", {
                      key: item.key,
                      attrs: {
                        size: "small",
                        "front-icon": item.icon,
                        "option-list": _vm.resolvedByOptions,
                        "default-option": _vm.resolvedByOption,
                        "highlight-selected": _vm.highlightResolvedBy,
                        "button-style": {
                          borderRadius: "4px",
                          color: "#808975",
                          padding: "0.4rem 0.6rem"
                        },
                        "custom-style": {
                          left: 0,
                          width: "180px"
                        },
                        "show-selected-text": false
                      },
                      on: { click: _vm.onResolvedByChange }
                    })
                  : item.key === "closed_by" && _vm.showClosedByFilter
                  ? _c("woot-single-selector", {
                      key: item.key,
                      attrs: {
                        size: "small",
                        "front-icon": item.icon,
                        "option-list": _vm.closedByOptions,
                        "default-option": _vm.closedByOption,
                        "highlight-selected": _vm.highlightClosedBy,
                        "button-style": {
                          borderRadius: "4px",
                          color: "#808975",
                          padding: "0.4rem 0.6rem"
                        },
                        "custom-style": {
                          left: 0,
                          width: "180px"
                        },
                        "show-selected-text": false
                      },
                      on: { click: _vm.onClosedByChange }
                    })
                  : item.key === "status_time"
                  ? _c("status-time-filter", {
                      key: item.key,
                      attrs: {
                        "filter-title": item.static_title,
                        "default-selection": _vm.defaultRangeSelection
                      },
                      on: { "date-range-change": _vm.onDateRangeChange }
                    })
                  : item.key === "facebook" && _vm.isFacebookInbox
                  ? _c("facebook-filter", {
                      key: item.key,
                      attrs: {
                        item: item,
                        "toggle-filter": _vm.filterToggle,
                        "selected-option": _vm.identifier
                      }
                    })
                  : item.key === "conversation_status"
                  ? _c("conversation-status", {
                      key: item.key,
                      attrs: {
                        size: "small",
                        "active-status": _vm.activeStatus,
                        "button-style": { padding: "0.4rem 0.6rem" }
                      },
                      on: { statusFilterChange: _vm.updateStatusType }
                    })
                  : _vm._e()
              ]
            })
          ],
          2
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }