var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      staticClass: "radio-container",
      style: [_vm.customStyle, _vm.cursorStyle],
      attrs: { for: _vm.id }
    },
    [
      _c("span", { staticClass: "body-b2", style: _vm.labelStyle }, [
        _vm._v(" " + _vm._s(_vm.label))
      ]),
      _vm._v(" "),
      _c("input", {
        attrs: {
          id: _vm.id,
          type: "radio",
          name: "radio",
          disabled: _vm.disabled
        },
        domProps: { value: _vm.id },
        on: {
          change: function($event) {
            return _vm.$emit("input", _vm.id)
          }
        }
      }),
      _vm._v(" "),
      _c("span", { staticClass: "radio-circle" })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }