var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "mg-top" },
      [
        _c("woot-single-selector", {
          attrs: {
            "default-option": _vm.selectedCrm,
            "option-list": _vm.crmOptionList,
            "custom-style": { width: "100%" }
          },
          on: { click: _vm.changeSelectedCrm }
        })
      ],
      1
    ),
    _vm._v(" "),
    !_vm.uiFlags.fetchingList
      ? _c("div", [
          _vm.noTicketsFound
            ? _c(
                "div",
                { staticClass: "body-b2 text-light mg-top text-align" },
                [_vm._v("\n      No tickets found\n    ")]
              )
            : _vm.isJiraCrmSelected
            ? _c(
                "div",
                { staticClass: "pd-top--slab" },
                _vm._l(_vm.ticketsList, function(ticket) {
                  return _c("jira-ticket-card", {
                    key: ticket.id,
                    attrs: { ticket: ticket }
                  })
                }),
                1
              )
            : _c(
                "div",
                { staticClass: "pd-top--slab" },
                _vm._l(_vm.ticketsList, function(ticket) {
                  return _c("ticket-card", {
                    key: ticket.id,
                    attrs: { ticket: ticket, "selected-crm": _vm.selectedCrm }
                  })
                }),
                1
              )
        ])
      : _c("div", { staticClass: "body-b2 text-light mg-top text-align" }, [
          _vm._v("\n    Loading tickets...\n  ")
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }