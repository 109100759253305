var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "woot-modal-new",
        {
          attrs: {
            show: true,
            "disable-btn": _vm.isFormNotValid,
            "navigate-to": _vm.agentData.navigateTo,
            "on-close": function() {
              return _vm.$emit("onClose")
            },
            "loading-btn": _vm.loading
          },
          on: { submit: _vm.onSubmission },
          scopedSlots: _vm._u([
            {
              key: "paginated-components",
              fn: function() {
                return [
                  _c("personal-details", {
                    key: "personal-detail",
                    attrs: {
                      "agent-id": _vm.agentId,
                      "agent-name": _vm.agentName,
                      "phone-number": _vm.phoneNumber,
                      "agent-email": _vm.agentEmail,
                      "agent-type": _vm.agentType,
                      "inbox-ids": _vm.inboxIds
                    },
                    on: {
                      "update:agentName": function($event) {
                        _vm.agentName = $event
                      },
                      "update:agent-name": function($event) {
                        _vm.agentName = $event
                      },
                      "update:phoneNumber": function($event) {
                        _vm.phoneNumber = $event
                      },
                      "update:phone-number": function($event) {
                        _vm.phoneNumber = $event
                      },
                      "update:agentEmail": function($event) {
                        _vm.agentEmail = $event
                      },
                      "update:agent-email": function($event) {
                        _vm.agentEmail = $event
                      },
                      "update:agentType": function($event) {
                        _vm.agentType = $event
                      },
                      "update:agent-type": function($event) {
                        _vm.agentType = $event
                      },
                      "update:inboxIds": function($event) {
                        _vm.inboxIds = $event
                      },
                      "update:inbox-ids": function($event) {
                        _vm.inboxIds = $event
                      },
                      formValidity: function($event) {
                        return (_vm.isFormNotValid = Object.assign(
                          {},
                          _vm.isFormNotValid,
                          { 0: $event }
                        ))
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("employee-details", {
                    key: "employee-detail",
                    attrs: {
                      "email-sign-html": _vm.emailSignHtml,
                      "email-sign-md": _vm.emailSignMd,
                      "markdown-mode": _vm.markdownMode,
                      "concurrency-enabled": _vm.concurrencyEnabled,
                      "concurrency-limit": _vm.concurrencyLimit,
                      "agent-id": _vm.agentId
                    },
                    on: {
                      "update:emailSignHtml": function($event) {
                        _vm.emailSignHtml = $event
                      },
                      "update:email-sign-html": function($event) {
                        _vm.emailSignHtml = $event
                      },
                      "update:emailSignMd": function($event) {
                        _vm.emailSignMd = $event
                      },
                      "update:email-sign-md": function($event) {
                        _vm.emailSignMd = $event
                      },
                      "update:markdownMode": function($event) {
                        _vm.markdownMode = $event
                      },
                      "update:markdown-mode": function($event) {
                        _vm.markdownMode = $event
                      },
                      "update:concurrencyEnabled": function($event) {
                        _vm.concurrencyEnabled = $event
                      },
                      "update:concurrency-enabled": function($event) {
                        _vm.concurrencyEnabled = $event
                      },
                      "update:concurrencyLimit": function($event) {
                        _vm.concurrencyLimit = $event
                      },
                      "update:concurrency-limit": function($event) {
                        _vm.concurrencyLimit = $event
                      },
                      formValidity: function($event) {
                        return (_vm.isFormNotValid = Object.assign(
                          {},
                          _vm.isFormNotValid,
                          { 1: $event }
                        ))
                      }
                    }
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "woot-modal-header-new",
            { attrs: { "header-content": _vm.$t("AGENT_MGMT.ADD.DESC") } },
            [
              _c("span", {
                staticClass: "title-h5 text-blue",
                domProps: { textContent: _vm._s(_vm.modalHeader) }
              })
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }