var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "small-12 columns" },
    [
      _c(
        "page-header",
        {
          attrs: {
            "header-title": _vm.$t("INBOX_MGMT.ADD.VOICE_CHANNEL.TITLE"),
            "header-content": _vm.$t("INBOX_MGMT.ADD.VOICE_CHANNEL.DESC")
          }
        },
        [
          _c("reference-box", {
            attrs: {
              to: _vm.documentation.channels.voice.knowlarity,
              content: "Know more about integrating knowlarity",
              type: "document"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.uiFlags.isCreating
        ? _c("woot-loading-state", {
            attrs: {
              message: _vm.$t("INBOX_MGMT.ADD.WEBSITE_CHANNEL.LOADING_MESSAGE")
            }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.uiFlags.isCreating
        ? _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.createChannel($event)
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "settings-sub-menu-content--larger mg-bottom--jumbo"
                },
                [
                  _c("woot-input", {
                    staticClass: "medium-12 columns content-margin",
                    class: { error: _vm.$v.inboxName.$error },
                    attrs: {
                      required: "",
                      "auto-focus": "",
                      "has-error": _vm.$v.inboxName.$error,
                      error: _vm.$t(
                        "INBOX_MGMT.ADD.VOICE_CHANNEL.CHANNEL_NAME.ERROR"
                      ),
                      label: _vm.$t(
                        "INBOX_MGMT.ADD.VOICE_CHANNEL.CHANNEL_NAME.LABEL"
                      ),
                      placeholder: _vm.$t(
                        "INBOX_MGMT.ADD.VOICE_CHANNEL.CHANNEL_NAME.PLACEHOLDER"
                      )
                    },
                    on: { blur: _vm.$v.inboxName.$touch },
                    model: {
                      value: _vm.inboxName,
                      callback: function($$v) {
                        _vm.inboxName =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "inboxName"
                    }
                  }),
                  _vm._v(" "),
                  _c("woot-input", {
                    staticClass: "medium-12 columns content-margin",
                    class: { error: _vm.$v.apiKey.$error },
                    attrs: {
                      required: "",
                      "has-error": _vm.$v.apiKey.$error,
                      error: _vm.$t(
                        "INBOX_MGMT.ADD.VOICE_CHANNEL.API_KEY.ERROR"
                      ),
                      label: _vm.$t(
                        "INBOX_MGMT.ADD.VOICE_CHANNEL.API_KEY.LABEL"
                      ),
                      placeholder: _vm.$t(
                        "INBOX_MGMT.ADD.VOICE_CHANNEL.API_KEY.PLACEHOLDER"
                      )
                    },
                    on: { blur: _vm.$v.apiKey.$touch },
                    model: {
                      value: _vm.apiKey,
                      callback: function($$v) {
                        _vm.apiKey = typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "apiKey"
                    }
                  }),
                  _vm._v(" "),
                  _c("woot-input", {
                    staticClass: "medium-12 columns content-margin",
                    class: { error: _vm.$v.srNumber.$error },
                    attrs: {
                      required: "",
                      "has-error": _vm.$v.srNumber.$error,
                      error:
                        _vm.srNumber === ""
                          ? _vm.$t("INBOX_MGMT.COMMON_ERROR_MESSAGE")
                          : _vm.$t(
                              "INBOX_MGMT.ADD.VOICE_CHANNEL.SR_NUMBER.ERROR"
                            ),
                      label: _vm.$t(
                        "INBOX_MGMT.ADD.VOICE_CHANNEL.SR_NUMBER.LABEL"
                      ),
                      placeholder: _vm.$t(
                        "INBOX_MGMT.ADD.VOICE_CHANNEL.SR_NUMBER.PLACEHOLDER"
                      )
                    },
                    on: { blur: _vm.$v.srNumber.$touch },
                    model: {
                      value: _vm.srNumber,
                      callback: function($$v) {
                        _vm.srNumber =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "srNumber"
                    }
                  }),
                  _vm._v(" "),
                  _c("woot-input", {
                    staticClass: "medium-12 columns content-margin",
                    class: { error: _vm.$v.callerId.$error },
                    attrs: {
                      required: "",
                      "has-error": _vm.$v.callerId.$error,
                      error:
                        _vm.callerId === ""
                          ? _vm.$t("INBOX_MGMT.COMMON_ERROR_MESSAGE")
                          : _vm.$t(
                              "INBOX_MGMT.ADD.VOICE_CHANNEL.CALLER_ID.ERROR"
                            ),
                      label: _vm.$t(
                        "INBOX_MGMT.ADD.VOICE_CHANNEL.CALLER_ID.LABEL"
                      ),
                      placeholder: _vm.$t(
                        "INBOX_MGMT.ADD.VOICE_CHANNEL.CALLER_ID.PLACEHOLDER"
                      )
                    },
                    on: { blur: _vm.$v.callerId.$touch },
                    model: {
                      value: _vm.callerId,
                      callback: function($$v) {
                        _vm.callerId =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "callerId"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "medium-12 columns settings-sub-menu-bottom-nav"
                },
                [
                  _c(
                    "woot-base-button",
                    {
                      attrs: { variant: "secondary" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return (function() {
                            return _vm.$router.go(-1)
                          })($event)
                        }
                      }
                    },
                    [_vm._v("\n        Back\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "woot-base-button",
                    {
                      attrs: {
                        loading: _vm.uiFlags.isCreating,
                        disabled: _vm.$v.$invalid,
                        type: "submit"
                      }
                    },
                    [_vm._v("\n        Next\n      ")]
                  )
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }