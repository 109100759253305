var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasTransition
    ? _c("transition", { attrs: { name: "modal-fade" } }, [
        _vm.show
          ? _c(
              "div",
              {
                staticClass: "modal-mask",
                style: _vm.modalPosition,
                attrs: { transition: "modal" },
                on: { click: _vm.onBackDropClick }
              },
              [
                _c(
                  "div",
                  {
                    class: _vm.modalContainerClassName,
                    style: _vm.customStyle,
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                      }
                    }
                  },
                  [
                    _vm.showClose
                      ? _c(
                          "span",
                          { staticClass: "modal--close" },
                          [
                            _c(
                              "woot-base-button",
                              {
                                staticClass: "close-icon",
                                attrs: {
                                  tag: "span",
                                  variant: "tertiary-danger"
                                },
                                on: { click: _vm.close }
                              },
                              [
                                _c("icons", {
                                  attrs: {
                                    name: "closeRound",
                                    color: "red",
                                    size: "normal",
                                    "show-title": false
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._t("default")
                  ],
                  2
                )
              ]
            )
          : _vm._e()
      ])
    : _c("div", [
        _vm.show
          ? _c(
              "div",
              {
                staticClass: "modal-mask",
                style: _vm.modalPosition,
                attrs: { transition: "modal" },
                on: { click: _vm.onBackDropClick }
              },
              [
                _c(
                  "div",
                  {
                    class: _vm.modalContainerClassName,
                    style: _vm.customStyle,
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                      }
                    }
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "modal--close" },
                      [
                        _vm.showClose
                          ? _c("icons", {
                              attrs: {
                                name: "closeRound",
                                color: "red",
                                size: "medium"
                              },
                              on: { click: _vm.close }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm._t("default")
                  ],
                  2
                )
              ]
            )
          : _vm._e()
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }