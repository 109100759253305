var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-ticket-form",
    {
      attrs: {
        "parent-form-fields": {
          userAction: _vm.userAction,
          selectedCrm: _vm.selectedCrm
        }
      }
    },
    [
      _c("woot-input", {
        staticClass: "content-margin",
        attrs: { label: "Action", type: "text", placeholder: "Action" },
        model: {
          value: _vm.action,
          callback: function($$v) {
            _vm.action = $$v
          },
          expression: "action"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }