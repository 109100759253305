var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "conversations-sidebar columns" },
    [
      _c(
        "div",
        { staticClass: "conversations-sidebar-header" },
        [
          _c("chat-list-top", { on: { filterChange: _vm.updateFilter } }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "conversation-sort-filter" },
            [
              _c("conversation-sort-filter", {
                ref: "conversationSortFilter",
                attrs: { variant: "tertiary" },
                on: { applyFilter: _vm.applySortFilter }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.selected.length
            ? _c("selection-bar", {
                attrs: {
                  selected: _vm.selected,
                  "select-all-loaded": _vm.selectAllLoaded,
                  "select-all": _vm.selectAll,
                  "select-count": _vm.selectCount
                },
                on: {
                  "select-card": _vm.selectCard,
                  "select-all-conversation": _vm.selectAllConversation,
                  "show-modify-popup": _vm.showModifyPopup
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      !_vm.chatListLoading && !_vm.conversationList.length
        ? _c("p", { staticClass: "content-box" }, [
            _vm._v("\n    " + _vm._s(_vm.$t("CHAT_LIST.LIST.404")) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "conversations-list" },
        [
          _vm._l(_vm.conversationShowList, function(chat) {
            return _c("conversation-card", {
              key: chat.id,
              attrs: {
                "show-select-option": true,
                selected: _vm.isSelected(chat.id),
                selection: !!_vm.selected.length,
                "select-all": _vm.selectAllLoaded,
                "active-tab": _vm.activeAssigneeTab,
                "active-status": _vm.activeStatus,
                chat: chat,
                "team-id": _vm.teamId,
                "chat-account-name": _vm.companyName(chat.account_id),
                "refresh-sla-status": _vm.slaStatusRerenderMap[chat.id] || 0
              },
              on: { select: _vm.selectCard }
            })
          }),
          _vm._v(" "),
          _vm.chatListLoading
            ? _c("div", { staticClass: "text-center" }, [
                _c("span", { staticClass: "spinner" })
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.hasCurrentPageEndReached && !_vm.chatListLoading
            ? _c(
                "div",
                {
                  staticClass: "clear button load-more-conversations",
                  on: { click: _vm.loadMoreConversations }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("CHAT_LIST.LOAD_MORE_CONVERSATIONS")) +
                      "\n    "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.conversationList.length &&
          _vm.hasCurrentPageEndReached &&
          !_vm.chatListLoading
            ? _c(
                "p",
                { staticClass: "text-center text-muted end-of-list-text" },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("CHAT_LIST.EOF")) + "\n    "
                  )
                ]
              )
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _vm.showModifyModal
        ? _c("bulk-action-modal", {
            attrs: {
              show: _vm.showModifyModal,
              "selected-conv": _vm.selected,
              "selected-count": _vm.selectAll
                ? _vm.selectCount
                : _vm.selected.length,
              "select-all": _vm.selectAll,
              filters: _vm.appliedConversationFilters,
              "on-close": _vm.hideModifyPopup
            },
            on: {
              "update:show": function($event) {
                _vm.showModifyModal = $event
              },
              done: function($event) {
                return _vm.selectCard(true)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }