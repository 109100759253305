var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header-container" },
    [
      _c("Header", {
        staticClass: "sla-header mg-bottom--slab",
        attrs: {
          name: _vm.name,
          description: _vm.description,
          "name-placeholder": _vm.namePlaceholder,
          "description-placeholder": _vm.descriptionPlaceholder,
          "on-click": _vm.onClick,
          "on-back": _vm.onBack
        },
        scopedSlots: _vm._u([
          {
            key: "right-section",
            fn: function() {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "status-indicator flex-row flex-align gap--small",
                    class: { active: _vm.active }
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "title-h5_m text-dark mg-left--slab" },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.active ? "Active" : "Inactive") +
                            "\n        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("sla-status-toggle", {
                      attrs: { data: { name: _vm.name, active: _vm.active } },
                      on: { toggle: _vm.onToggle }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }