var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-tour", {
        attrs: {
          name: _vm.tourName,
          steps: _vm.steps,
          options: _vm.tourOptions
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(tour) {
              return [
                tour.currentStep !== -1
                  ? _c("div", { staticClass: "overlay app-wrapper" })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "transition",
                  { attrs: { name: "fade" } },
                  [
                    tour.steps[tour.currentStep]
                      ? _c(
                          "v-step",
                          {
                            key: tour.currentStep,
                            attrs: {
                              step: tour.steps[tour.currentStep],
                              "previous-step": tour.previousStep,
                              "next-step": tour.nextStep,
                              stop: tour.stop,
                              skip: tour.skip,
                              "is-first": tour.isFirst,
                              "is-last": tour.isLast,
                              labels: tour.labels,
                              highlight: tour.highlight
                            }
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "header" }, slot: "header" },
                              [
                                _vm.steps[tour.currentStep].header
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "v-step__header flex-space-between"
                                      },
                                      [
                                        _vm.steps[tour.currentStep].header.title
                                          ? _c("div", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.stepHeader
                                                )
                                              }
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "muted-button",
                                            staticStyle: {
                                              "pointer-events": "none"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                STEP " +
                                                _vm._s(
                                                  tour.steps[tour.currentStep]
                                                    .fakeStepNumber
                                                ) +
                                                "/" +
                                                _vm._s(
                                                  tour.steps[tour.currentStep]
                                                    .fakeTotalSteps
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "buttons-container",
                                style: [
                                  tour.isLast
                                    ? { "justify-content": "flex-end" }
                                    : { "justify-content": "space-between" }
                                ],
                                attrs: { slot: "actions" },
                                slot: "actions"
                              },
                              [
                                tour.steps[tour.currentStep].fakeStepNumber !==
                                tour.steps[tour.currentStep].fakeTotalSteps
                                  ? [
                                      !tour.isLast
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "muted-button",
                                              on: { click: _vm.tourSkip }
                                            },
                                            [
                                              _vm._v(
                                                "\n                SKIP STEP\n              "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  : [
                                      !tour.isLast
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "muted-button",
                                              on: { click: _vm.tourStop }
                                            },
                                            [
                                              _vm._v(
                                                "\n                SKIP STEP\n              "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "tour-navigation flex-row-justify-center"
                                  },
                                  [
                                    !tour.isFirst
                                      ? _c("woot-secondary-button", {
                                          attrs: {
                                            "custom-class": "tour-button",
                                            name: "PREVIOUS"
                                          },
                                          on: { click: _vm.customPrevStep }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !tour.isLast
                                      ? _c("woot-primary-button", {
                                          attrs: {
                                            "custom-class": "tour-button",
                                            name: "NEXT"
                                          },
                                          on: { click: _vm.customNextStep }
                                        })
                                      : _c("woot-primary-button", {
                                          staticClass: "tour-button",
                                          attrs: { name: "FINISH" },
                                          on: { click: _vm.tourStop }
                                        })
                                  ],
                                  1
                                )
                              ],
                              2
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }