var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal-new",
    {
      attrs: {
        show: _vm.show,
        "on-close": _vm.onClose,
        "disable-btn": {
          0: !_vm.buttons.buttons,
          1: _vm.editTemplate.showLoading
        },
        "navigate-to": _vm.selectedTemplate.navigateTo
      },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        },
        submit: _vm.editTemplateResponse
      },
      scopedSlots: _vm._u([
        {
          key: "paginated-components",
          fn: function() {
            return [
              _c("div", { staticClass: "template-form" }, [
                _c(
                  "div",
                  { staticClass: "settings-form custom-scroll" },
                  [
                    _c("div", {
                      staticClass: "settings-title title-h3 text-dark",
                      domProps: { textContent: _vm._s("Template Details") }
                    }),
                    _vm._v(" "),
                    _c(
                      "page-header",
                      {
                        staticStyle: { "padding-top": "0" },
                        attrs: {
                          "header-title": _vm.$t(
                            "TEMPLATE.EDIT.FORM.SHORT_CODE.LABEL"
                          ),
                          size: "small"
                        }
                      },
                      [
                        _c("div", {
                          staticClass: "body-b3 text-light",
                          staticStyle: { "line-break": "anywhere" },
                          domProps: { textContent: _vm._s(_vm.shortCode) }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "flex-row flex-align gap--two",
                        class: { "content-margin": !_vm.isMediaTemplate }
                      },
                      [
                        _c(
                          "page-header",
                          {
                            attrs: {
                              "header-title": _vm.$t(
                                "TEMPLATE.EDIT.FORM.TYPE.LABEL"
                              ),
                              size: "small"
                            }
                          },
                          [
                            _c("div", {
                              staticClass: "body-b3 text-light capitalize",
                              domProps: {
                                textContent: _vm._s(_vm.selectedType)
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "page-title",
                            staticStyle: { color: "#f0f0f0" }
                          },
                          [_vm._v("|")]
                        ),
                        _vm._v(" "),
                        _c(
                          "page-header",
                          {
                            attrs: {
                              "header-title": _vm.$t(
                                "TEMPLATE.EDIT.FORM.INBOX.LABEL"
                              ),
                              size: "small"
                            }
                          },
                          [
                            _c("div", {
                              staticClass: "body-b3 text-light capitalize",
                              domProps: {
                                textContent: _vm._s(_vm.selectedInbox)
                              }
                            })
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.isMediaTemplate
                      ? _c(
                          "page-header",
                          {
                            staticClass: "content-margin",
                            attrs: {
                              "header-title": _vm.$t(
                                "TEMPLATE.ADD.FORM.MEDIA.LABEL"
                              ),
                              size: "small"
                            }
                          },
                          [
                            _c("div", {
                              staticClass: "body-b3 text-light capitalize",
                              domProps: { textContent: _vm._s(_vm.mediaUrl) }
                            })
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.header
                      ? _c("woot-input", {
                          staticClass: "medium-12 columns content-margin",
                          attrs: {
                            label: _vm.$t("TEMPLATE.EDIT.FORM.HEADER.LABEL"),
                            "help-text": _vm.$t(
                              "TEMPLATE.EDIT.FORM.HEADER.DESCRIPTION"
                            ),
                            placeholder: _vm.$t(
                              "TEMPLATE.EDIT.FORM.HEADER.PLACEHOLDER"
                            ),
                            readonly: ""
                          },
                          model: {
                            value: _vm.header,
                            callback: function($$v) {
                              _vm.header =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "header"
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("woot-input", {
                      staticClass: "medium-12 columns content-margin",
                      attrs: {
                        rows: "6",
                        type: "text",
                        label: _vm.$t("TEMPLATE.EDIT.FORM.BODY.LABEL"),
                        placeholder: _vm.$t(
                          "TEMPLATE.EDIT.FORM.BODY.PLACEHOLDER"
                        ),
                        readonly: ""
                      },
                      model: {
                        value: _vm.body,
                        callback: function($$v) {
                          _vm.body = typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "body"
                      }
                    }),
                    _vm._v(" "),
                    _vm.footer
                      ? _c("woot-input", {
                          staticClass: "medium-12 columns content-margin",
                          attrs: {
                            label: _vm.$t("TEMPLATE.EDIT.FORM.FOOTER.LABEL"),
                            "help-text": _vm.$t(
                              "TEMPLATE.EDIT.FORM.FOOTER.DESCRIPTION"
                            ),
                            placeholder: _vm.$t(
                              "TEMPLATE.EDIT.FORM.FOOTER.PLACEHOLDER"
                            ),
                            readonly: ""
                          },
                          model: {
                            value: _vm.footer,
                            callback: function($$v) {
                              _vm.footer =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "footer"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _vm.buttons && _vm.buttons.buttons
                ? _c(
                    "form",
                    {
                      staticClass: "template-form",
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.editTemplateResponse($event)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "flex-column settings-form custom-scroll"
                        },
                        [
                          _c("div", {
                            staticClass: "settings-title title-h3 text-dark",
                            domProps: {
                              textContent: _vm._s("Edit Button Payload")
                            }
                          }),
                          _vm._v(" "),
                          _vm._l(_vm.buttons.buttons, function(button, inx) {
                            return _c(
                              "div",
                              {
                                key: inx,
                                staticClass:
                                  "flex-row flex-align gap--two flex-justify--between gap--small mg-bottom"
                              },
                              [
                                _c(
                                  "woot-base-button",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip.bottom",
                                        value: button.url,
                                        expression: "button.url",
                                        modifiers: { bottom: true }
                                      }
                                    ],
                                    staticStyle: { "flex-shrink": "0" },
                                    attrs: {
                                      variant: "secondary",
                                      "icon-size": "semimedium",
                                      "icon-color": "grey",
                                      "front-icon":
                                        _vm.buttons.type === "call_to_action"
                                          ? "reply"
                                          : ""
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return (function() {})($event)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(button.title) +
                                        "\n          "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("woot-input", {
                                  attrs: {
                                    "full-width": "",
                                    placeholder: _vm.$t(
                                      "TEMPLATE.EDIT.FORM.BUTTON.PAYLOAD.PLACEHOLDER"
                                    )
                                  },
                                  model: {
                                    value: button.payload,
                                    callback: function($$v) {
                                      _vm.$set(
                                        button,
                                        "payload",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "button.payload"
                                  }
                                })
                              ],
                              1
                            )
                          })
                        ],
                        2
                      )
                    ]
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("woot-modal-header-new", {
        attrs: { "header-title": _vm.$t("TEMPLATE.EDIT.TITLE") }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }