var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "medium-3 bg-white contact--panel custom-scroll lime-card-1dp"
    },
    [
      _c("contact-info", {
        attrs: { contact: _vm.contact, "parent-component-name": "ContactInfo" }
      }),
      _vm._v(" "),
      _vm.hasContactAttributes
        ? _c("contact-custom-attributes", {
            attrs: { "custom-attributes": _vm.contact.custom_attributes }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.contact.id
        ? _c(
            "accordian-card",
            {
              attrs: {
                name: "Previous Tickets",
                icon: "history",
                "icon-view": "0 0 24 24",
                "first-time-open": true
              }
            },
            [
              _vm.contact.id
                ? _c("contact-conversations", {
                    attrs: {
                      "contact-id": _vm.contact.id || -1,
                      "conversation-id": "",
                      "parent-component-name": "ContactInfo"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "accordian-card",
        {
          attrs: {
            id: "add-tags-accordion",
            name: _vm.$t("CONTACT_PANEL.CONTACT_TAGS.LABEL"),
            icon: "hashFilled",
            "icon-view": "0 0 24 24",
            "always-open": ""
          }
        },
        [
          _c("contact-labels", {
            key: _vm.contact.id,
            attrs: {
              "contact-id": _vm.contact.id,
              "contact-labels": _vm.contactLabels,
              "show-add-button": ""
            },
            on: { updateLabels: _vm.UpdateLabels }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }