var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "small-12 columns" },
    [
      _c("page-header", {
        attrs: { "header-title": _vm.$t("INBOX_MGMT.ADD.EXOTEL_CHANNEL.TITLE") }
      }),
      _vm._v(" "),
      _vm.uiFlags.isCreating
        ? _c("woot-loading-state", {
            attrs: {
              message: _vm.$t("INBOX_MGMT.ADD.WEBSITE_CHANNEL.LOADING_MESSAGE")
            }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.uiFlags.isCreating
        ? _c(
            "form",
            {
              on: {
                keydown: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  $event.preventDefault()
                },
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.createChannel($event)
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "settings-sub-menu-content--larger mg-bottom--jumbo"
                },
                [
                  _c("woot-input", {
                    staticClass: "medium-12 columns content-margin",
                    class: { error: _vm.$v.channelName.$error },
                    attrs: {
                      required: "",
                      "auto-focus": "",
                      "has-error": _vm.$v.channelName.$error,
                      error: _vm.$t(
                        "INBOX_MGMT.ADD.VOICE_CHANNEL.CHANNEL_NAME.ERROR"
                      ),
                      label: _vm.$t(
                        "INBOX_MGMT.ADD.VOICE_CHANNEL.CHANNEL_NAME.LABEL"
                      ),
                      placeholder: _vm.$t(
                        "INBOX_MGMT.ADD.VOICE_CHANNEL.CHANNEL_NAME.PLACEHOLDER"
                      )
                    },
                    on: { blur: _vm.$v.channelName.$touch },
                    model: {
                      value: _vm.channelName,
                      callback: function($$v) {
                        _vm.channelName =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "channelName"
                    }
                  }),
                  _vm._v(" "),
                  _c("woot-input", {
                    staticClass: "medium-12 columns content-margin",
                    class: { error: _vm.$v.apiKey.$error },
                    attrs: {
                      required: "",
                      "has-error": _vm.$v.apiKey.$error,
                      error: _vm.$t(
                        "INBOX_MGMT.ADD.VOICE_CHANNEL.API_KEY.ERROR"
                      ),
                      label: _vm.$t(
                        "INBOX_MGMT.ADD.VOICE_CHANNEL.API_KEY.LABEL"
                      ),
                      placeholder: _vm.$t(
                        "INBOX_MGMT.ADD.VOICE_CHANNEL.API_KEY.PLACEHOLDER"
                      )
                    },
                    on: { blur: _vm.$v.apiKey.$touch },
                    model: {
                      value: _vm.apiKey,
                      callback: function($$v) {
                        _vm.apiKey = typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "apiKey"
                    }
                  }),
                  _vm._v(" "),
                  _c("woot-input", {
                    staticClass: "medium-12 columns content-margin",
                    class: { error: _vm.$v.apiToken.$error },
                    attrs: {
                      required: "",
                      "has-error": _vm.$v.apiToken.$error,
                      error: _vm.$t(
                        "INBOX_MGMT.ADD.VOICE_CHANNEL.API_TOKEN.ERROR"
                      ),
                      label: _vm.$t(
                        "INBOX_MGMT.ADD.VOICE_CHANNEL.API_TOKEN.LABEL"
                      ),
                      placeholder: _vm.$t(
                        "INBOX_MGMT.ADD.VOICE_CHANNEL.API_TOKEN.PLACEHOLDER"
                      )
                    },
                    on: { blur: _vm.$v.apiToken.$touch },
                    model: {
                      value: _vm.apiToken,
                      callback: function($$v) {
                        _vm.apiToken =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "apiToken"
                    }
                  }),
                  _vm._v(" "),
                  _c("woot-input", {
                    staticClass: "medium-12 columns content-margin",
                    class: { error: _vm.$v.subdomain.$error },
                    attrs: {
                      required: "",
                      "has-error": _vm.$v.subdomain.$error,
                      error: _vm.$t(
                        "INBOX_MGMT.ADD.VOICE_CHANNEL.SUBDOMAIN.ERROR"
                      ),
                      label: _vm.$t(
                        "INBOX_MGMT.ADD.VOICE_CHANNEL.SUBDOMAIN.LABEL"
                      ),
                      placeholder: _vm.$t(
                        "INBOX_MGMT.ADD.VOICE_CHANNEL.SUBDOMAIN.PLACEHOLDER"
                      )
                    },
                    on: { blur: _vm.$v.subdomain.$touch },
                    model: {
                      value: _vm.subdomain,
                      callback: function($$v) {
                        _vm.subdomain =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "subdomain"
                    }
                  }),
                  _vm._v(" "),
                  _c("woot-input", {
                    staticClass: "medium-12 columns content-margin",
                    class: { error: _vm.$v.accountSid.$error },
                    attrs: {
                      required: "",
                      "has-error": _vm.$v.accountSid.$error,
                      error: _vm.$t(
                        "INBOX_MGMT.ADD.VOICE_CHANNEL.ACCOUNT_SID.ERROR"
                      ),
                      label: _vm.$t(
                        "INBOX_MGMT.ADD.VOICE_CHANNEL.ACCOUNT_SID.LABEL"
                      ),
                      placeholder: _vm.$t(
                        "INBOX_MGMT.ADD.VOICE_CHANNEL.ACCOUNT_SID.PLACEHOLDER"
                      )
                    },
                    on: { blur: _vm.$v.accountSid.$touch },
                    model: {
                      value: _vm.accountSid,
                      callback: function($$v) {
                        _vm.accountSid =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "accountSid"
                    }
                  }),
                  _vm._v(" "),
                  _c("keyword-input", {
                    attrs: {
                      keywords: _vm.virtualNumbers,
                      type: "Number",
                      "validation-pattern": "^[0-9]*$",
                      size: "small",
                      "input-label": "Virtual Numbers",
                      "input-placeholder":
                        "Type a virtual number and press enter",
                      required: "",
                      "help-text":
                        "Phone numbers should start with the country code without `+` sign"
                    },
                    on: {
                      updateKeywords: function($event) {
                        _vm.virtualNumbers = $event
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "medium-12 columns settings-sub-menu-bottom-nav"
                },
                [
                  _c(
                    "woot-base-button",
                    {
                      attrs: { variant: "secondary" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return (function() {
                            return _vm.$router.go(-1)
                          })($event)
                        }
                      }
                    },
                    [_vm._v("\n        Back\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "woot-base-button",
                    {
                      attrs: {
                        loading: _vm.uiFlags.isCreating,
                        disabled: _vm.$v.$invalid,
                        type: "submit"
                      }
                    },
                    [_vm._v("\n        Next\n      ")]
                  )
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }