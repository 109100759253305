var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex-container actions--container" }, [
    _c(
      "div",
      { staticClass: "action--button", on: { click: _vm.toggleDropdown } },
      [
        !_vm.customButton
          ? _c(
              "button",
              { staticClass: "clear more--button" },
              [
                _vm.name
                  ? _c("span", { staticClass: "button-text" }, [
                      _vm._v(_vm._s(_vm.name))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("icons", {
                  attrs: {
                    name: _vm.icon,
                    size: _vm.iconSize,
                    color: _vm.iconColor
                  }
                })
              ],
              1
            )
          : _vm._t("default")
      ],
      2
    ),
    _vm._v(" "),
    _vm.showDropdown
      ? _c(
          "ul",
          {
            directives: [
              {
                name: "on-clickaway",
                rawName: "v-on-clickaway",
                value: _vm.hideDropdown,
                expression: "hideDropdown"
              }
            ],
            staticClass: "dropdown--pane lime-card custom-scroll",
            class: { "dropdown--pane--open": _vm.showDropdown },
            style: [_vm.customStyle]
          },
          [
            _vm._l(_vm.optionList, function(option) {
              return _c("li", { key: option.id }, [
                !_vm.isSelected(option) || _vm.showAll
                  ? _c(
                      "button",
                      {
                        staticClass: "button dropdown-button",
                        class: {
                          "dropdown-button--selected":
                            (_vm.isSelected(option) && _vm.disabled) ||
                            option.selected,
                          "dropdown-button--disabled": option.disabled
                        },
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.onClick(option)
                          }
                        }
                      },
                      [
                        option.icon
                          ? _c(
                              "span",
                              { staticStyle: { "margin-right": "8px" } },
                              [
                                _c("icons", {
                                  attrs: {
                                    name: option.icon,
                                    size: "semimedium",
                                    color: "grey"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", { staticClass: "text-truncate" }, [
                          _vm._v(_vm._s(option.name))
                        ])
                      ]
                    )
                  : _vm._e()
              ])
            }),
            _vm._v(" "),
            !_vm.optionList.length
              ? _c("li", [
                  _c("button", { staticClass: "button action--button" }, [
                    _c("span", [_vm._v(_vm._s("No options available"))])
                  ])
                ])
              : _vm._e()
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }