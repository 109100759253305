var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add-template-wrapper" },
    [
      !_vm.selectedTemplate.body
        ? _c(
            "woot-base-button",
            {
              staticClass: "CREATE_TICKET_TEMPLATE_ID",
              attrs: { variant: "secondary", size: "small" },
              on: { click: _vm.openTemplateSelectionModal }
            },
            [
              _vm._v(
                "\n    " +
                  _vm._s(
                    _vm.$t("CREATE_TICKET.FORM.SECTIONS.ADD_TEMPLATE.HEADING")
                  ) +
                  "\n  "
              )
            ]
          )
        : _c("template-substitutions", {
            attrs: {
              template: _vm.selectedTemplate,
              "media-url": _vm.templateInputs.media_url,
              "dynamic-url": _vm.templateInputs.dynamic_url,
              "number-of-substitutions": _vm.numberOfSubstitutions
            },
            on: {
              updateField: _vm.onUpdateField,
              removeTemplate: _vm.onClearTemplate
            }
          }),
      _vm._v(" "),
      _c(
        "woot-modal-new",
        {
          attrs: {
            show: _vm.showTemplateSelection,
            "on-close": function() {
              return (_vm.showTemplateSelection = false)
            },
            "medium-width": ""
          }
        },
        [
          _c("woot-modal-header-new", {
            attrs: {
              "header-title": _vm.$t(
                "CREATE_TICKET.FORM.SECTIONS.ADD_TEMPLATE.MODAL.HEADING"
              )
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "template-view-wrapper" },
            [
              _c("view-template", {
                attrs: { "inbox-id": _vm.inboxId, "show-inbox": false },
                on: {
                  select: function(template) {
                    return (_vm.templateToPreview = template)
                  }
                }
              }),
              _vm._v(" "),
              _c("whatsapp-ui", {
                staticClass: "whatsapp-ui-wrapper",
                attrs: {
                  messages: _vm.messages,
                  "only-chat-screen": "",
                  "custom-height": "height: calc(100vh - 42rem)"
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "modal-footer" },
                [
                  _c("woot-base-button", { on: { click: _vm.onSubmit } }, [
                    _vm._v("\n          Submit\n        ")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }