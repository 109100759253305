<template>
  <li
    v-if="
      hasAttachments ||
        isQuotedContent ||
        data.content ||
        isAnEmail ||
        validContentType ||
        data.date
    "
    :id="data.id"
    :class="[
      alignBubble,
      {
        'email flex-column':
          isAnEmail && [0, 1, 3, 9, 10, 11].includes(data.message_type),
      },
    ]"
  >
    <transition name="fade" mode="out-in">
      <media-slider
        v-if="showMediaSlider"
        :attachments="mediaAttachments"
        :active-attachment="selectedMediaFileIndex"
        @closeImageSlider="showMediaSlider = false"
      />
    </transition>
    <div :class="[wrapClass, { 'padding-smaller': isActivity }]">
      <deleted-message-tag
        v-if="isDeleted"
        :message-attributes="messageContentAttributes"
      />

      <div v-if="data.date && !isAnEmail" class="conversation-date-container">
        <span class="conversation-date" v-html="data.date" />
      </div>

      <reply-message-depricated
        v-if="showReplyDep && !showReply"
        :class="{ 'deleted-reply': isDeleted }"
        :data="inReplyDataDep"
        :enable-cancel="false"
      />

      <reply-message
        v-if="showReply"
        :theme="replyMessageTheme"
        :class="{ 'deleted-reply': isDeleted }"
        :with-header="canShowReplyMessageHeader"
        :actions-list="actionsList"
        :get-reply-data="getReplyData"
        @onActionSelected="onReplyMessageActionSelected"
      >
        <bubble-actions
          v-if="!data.date"
          :is-a-tweet="false"
          :is-private="false"
          :message-type="data.message_type"
          :readable-time="readableTime"
          :source-id="data.source_id"
        />
      </reply-message>
      <div
        v-if="!isFirstIncomingComment && !showReply"
        :class="{
          'with-avatar flex-row flex-align--end gap--six':
            (isOutgoing || isOutOfOffice) && !isAnEmail,
          'status-message--div': isActivity,
        }"
      >
        <div
          :class="[
            bubbleClass,
            { bubble: data.private && isAnEmail },
            { 'status-message--wrapper': isActivity },
          ]"
        >
          <span
            v-if="showIdentifier"
            class="flex-row flex-align mg-bottom--smaller"
          >
            <icons name="forward" color="grey" />
            <p
              class="capitalize body-b3 mg-left--micro"
              v-text="identifierToText"
            />
          </span>

          <!-- private note -->
          <div v-if="data.private" class="note-heading">
            <span class="flex-row-justify-center flex-center">
              <span class="note-heading--circle"></span>
              <span class="note-heading--text">{{ `#Note` }}</span>
              <span v-if="isAnEmail" class="body-b4 text-light forwarded-text">
                by {{ data.sender.name }}
              </span>
            </span>
            <span class="note-heading--icon">
              <icons name="lock" color="primary" size="medium" />
            </span>
          </div>

          <!-- card messages -->
          <span v-if="isCards">
            <span v-for="card in getItems" :key="card.id">
              <bubble-image
                v-if="card.media_url && !videoFileType(card.media_url)"
                :url="card.media_url"
                :readable-time="readableTime"
              />
              <bubble-file
                v-if="card.media_url && videoFileType(card.media_url)"
                :url="card.media_url"
                :readable-time="readableTime"
              />
              <bubble-text
                :message="getCardText(card)"
                :readable-time="readableTime"
                :data="data"
              />
              <span
                v-if="card.actions"
                class="flex-column flex-align mg-top--smaller"
              >
                <woot-secondary-button
                  v-for="(button, inx) in card.actions"
                  :key="inx"
                  size="small"
                  custom-class="mg-bottom--smaller read-only-button"
                  full-width
                  :name="button.text"
                />
              </span>
            </span>
          </span>
          <line-chart-skeleton
            v-if="
              isPending && isAnEmail && !data.private && data.message_type === 1
            "
            chart-height="1rem"
          />
          <template v-else-if="!showReply">
            <!-- mail head -->
            <div
              v-if="
                !data.private &&
                  isAnEmail &&
                  [0, 1, 3, 9, 10, 11].includes(data.message_type)
              "
              class="flex-row flex-justify--between flex-align--start email-head"
              @click="isExpanded = true"
            >
              <bubble-mail-head
                :email-attributes="contentAttributes.email"
                :recipient="contentAttributes.to_emails || contentAttributes.to"
                :message-type="data.message_type"
                :reply-time-stamp="data.created_at || 0"
                :cc="emailHeadAttributes.cc"
                :bcc="emailHeadAttributes.bcc"
                :is-first-email="isFirstEmail"
                :sender="sender"
                :content-type="data.content_type"
              />
              <div
                v-if="[0, 1, 9, 10, 11].includes(data.message_type)"
                class="email-action"
              >
                <woot-single-selector
                  variant="tertiary"
                  :custom-style="{
                    top: '2.4rem',
                    width: 'auto',
                    left: 'auto',
                    right: '0.8rem',
                    borderRadius: '0.8rem',
                  }"
                  :custom-class="
                    disableEmailButtons ? 'disable-email-dropdown' : ''
                  "
                  :option-list="getEmailActionList"
                  icon-size-prop="semimedium"
                  icon="threeDot"
                  :show-selected-text="false"
                  :show-option-name="false"
                  @click="onEmailActionSelected"
                />
              </div>
            </div>
            <div
              v-if="isAnEmail && hasText && canExpand"
              :class="{ 'is-collapsed': !isExpanded }"
              @click="isExpanded = true"
            >
              <bubble-text
                :message="
                  truncateCollapseMessage(
                    getPlainText(message.replace(/<[^>]*>?/gm, ''))
                  )
                "
                :is-deleted="isDeleted"
                :is-email="isAnEmail"
                :readable-time="readableTime"
                :display-quoted-button="false"
                :data="data"
              />
            </div>
            <bubble-text
              v-else-if="
                hasText && !hasAttachments && !isAnEmail && !isGptSummary
              "
              :class="isActivity ? 'status-message--text' : ''"
              :message="message"
              :is-deleted="isDeleted"
              :is-email="isAnEmail"
              :readable-time="readableTime"
              :display-quoted-button="false"
              :data="data"
            />
            <bubble-text-shadowed
              v-else-if="hasText && isAnEmail"
              :message="message"
              :class="isActivity ? 'status-message--text' : ''"
              :is-deleted="isDeleted"
              :is-email="isAnEmail"
              :readable-time="readableTime"
              :display-quoted-button="false"
              :email-signature="emailSignature"
              :data="data"
            />
            <quoted-text
              v-if="hasText && isQuotedContent && !canExpand"
              :message="quotedContent"
              :is-email="isEmailContentType || isQuotedContent"
            />
          </template>

          <span
            v-if="templateData && templateData.buttons"
            class="flex-column flex-align mg-top--smaller"
          >
            <woot-secondary-button
              v-for="(button, inx) in templateData.buttons"
              :key="inx"
              v-tooltip.top="getButtonLink(button)"
              size="small"
              :custom-class="
                [
                  'mg-bottom--smaller',
                  !templateData.button_url && 'read-only-button',
                ].join(' ')
              "
              full-width
              :name="button.title"
              @click="
                templateData.button_url && openLink(getButtonLink(button))
              "
            />
          </span>

          <bubble-image
            v-if="isSticker"
            :url="contentAttributes.sticker"
            :readable-time="readableTime"
          />

          <!-- product list -->
          <span v-if="isProductList">
            <span v-for="productItem in getItems" :key="productItem.id">
              <bubble-text
                :message="getProductText(productItem)"
                :readable-time="readableTime"
                :data="data"
              />
            </span>
          </span>

          <!-- attachments -->
          <div
            v-if="isPending && hasAttachments"
            style="margin-bottom:1rem"
            class="chat-bubble has-attachment agent"
          >
            {{ $t('CONVERSATION.UPLOADING_ATTACHMENTS') }}
          </div>
          <div
            v-if="!isPending && hasAttachments && isAnEmail && !canExpand"
            class="flex-row flex-justify--start files-container"
          >
            <div v-for="attachment in data.attachments" :key="attachment.id">
              <email-file
                :id="`${attachment.id}`"
                :attachment-type="attachment.file_type"
                :attachment-url="attachment.data_url"
                @openMediaSlider="onOpenMediaSlider"
              />
            </div>
          </div>
          <!-- image with text -->
          <!-- todo -->
          <bubble-text
            v-if="isPending && hasText && hasAttachments && !isAnEmail"
            :message="message"
            :is-deleted="isDeleted"
            :is-email="false"
            :readable-time="readableTime"
            :display-quoted-button="false"
            :data="data"
          />
          <div v-if="!isPending && hasAttachments && !isAnEmail">
            <div v-for="attachment in data.attachments" :key="attachment.id">
              <bubble-image
                v-if="attachment.file_type === 'image'"
                :url="attachment.data_url"
                :has-attached-content="!!data.content"
                :message="message"
                :is-deleted="isDeleted"
                :readable-time="readableTime"
              />
              <bubble-file
                v-if="attachment.file_type !== 'image'"
                :attachment-type="attachment.file_type"
                :url="attachment.data_url"
                :has-attached-content="!!data.content"
                :message="message"
                :is-deleted="isDeleted"
                :readable-time="readableTime"
              />
            </div>
          </div>
          <!-- actions -->
          <bubble-actions
            v-if="!data.date && !isGptSummary && !showReply"
            :id="data.id"
            :class="isActivity ? 'status-message--actions' : ''"
            :status="data.status"
            :details="messageStatusDetails"
            :sender="data.sender"
            :is-an-email="isAnEmail"
            :is-a-tweet="isATweet"
            :is-private="data.private"
            :message-type="data.message_type"
            :readable-time="readableTime"
            :source-id="data.source_id"
          />
          <!-- more options -->
          <div v-if="showMoreOption" class="bubble-more-action">
            <woot-single-selector
              variant="tertiary"
              icon="share"
              size="normal"
              custom-class="selector-button"
              :custom-style="{
                top: '1.4rem',
                left: isOutgoing ? '-12.5rem' : '0',
                borderRadius: '0.8rem',
                padding: '0.4rem',
              }"
              :custom-button="true"
              :option-list="getActionList"
              :show-selected-text="false"
              :show-option-name="false"
              @click="toggleAction"
            >
              <span class="selector-button reply-button">
                <icons name="threeDot" color="green-stroke" size="normal" />
              </span>
            </woot-single-selector>
          </div>
        </div>
        <summary-bubble v-if="isGptSummary" :message="data" />

        <!-- Display agent avatar with hover state -->
        <agent-avatar v-if="shouldShowAvatar" v-bind="cardInfo">
          <template v-slot:hover-state>
            <agent-info-card v-bind="cardInfo" />
          </template>
        </agent-avatar>
      </div>

      <a
        v-if="isATweet && isIncoming && sender"
        class="sender--info"
        :href="twitterProfileLink"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        <woot-thumbnail
          :src="sender.thumbnail"
          :username="sender.name"
          size="16px"
        />
        <div class="sender--available-name">
          {{ sender.name }}
        </div>
      </a>

      <!-- Display space for outgoing messages without the avatar -->
      <span v-else-if="isOutgoing" style="width: 2rem" />
    </div>
    <bubble-tooltip
      :is-sent="sentByMessage"
      :is-deleted="isDeleted"
      :name="sender.name"
    />
    <delete-modal
      :show="showDeleteModal"
      message="Are you sure you want to delete this message?"
      confirm-text="Yes, Delete"
      reject-text="Cancel"
      :on-close="toggleDeleteModal"
      :on-confirm="deleteMessage"
    />
    <div
      v-if="isLatestEmail && !isReplyBoxOpen"
      class="buttons-container flex-row"
    >
      <woot-base-button
        variant="secondary"
        size="medium"
        icon-size="medium"
        front-icon="reply"
        :disabled="disableEmailButtons"
        @click="onEmailActionSelected({ value: 'reply' })"
      >
        {{ $t('CONVERSATION.REPLYBOX.REPLY') }}
      </woot-base-button>
      <woot-base-button
        v-if="hasMultipleRecepients"
        size="medium"
        variant="secondary"
        icon-size="semimedium"
        front-icon="replyAll"
        :disabled="disableEmailButtons"
        @click="onEmailActionSelected({ value: 'reply_to_all' })"
      >
        {{ $t('CONVERSATION.REPLYBOX.REPLY_TO_ALL') }}
      </woot-base-button>
      <woot-base-button
        size="medium"
        variant="secondary"
        icon-size="semimedium"
        front-icon="forward"
        :disabled="disableEmailButtons"
        @click="onEmailActionSelected({ value: 'forward' })"
      >
        {{ $t('CONVERSATION.REPLYBOX.FORWARD') }}
      </woot-base-button>
      <woot-base-button
        size="medium"
        variant="secondary"
        icon-size="medium"
        front-icon="lock"
        @click="$emit('onPrivateReply')"
      >
        {{ $t('CONVERSATION.REPLYBOX.PRIVATE_NOTE') }}
      </woot-base-button>
    </div>
  </li>
</template>

<script>
/* eslint-disable dot-notation */
import { mapGetters } from 'vuex';

import BubbleText from './bubble/Text';
import BubbleTextShadowed from './bubble/TextShadowed';
import QuotedText from './bubble/QuotedText';
import BubbleImage from './bubble/Image';
import BubbleFile from './bubble/File';
import EmailFile from './bubble/EmailFile';
import BubbleTooltip from './bubble/Tooltip';
import BubbleMailHead from './bubble/MailHead';
import BubbleActions from './bubble/Actions';
import ReplyMessage from './bubble/ReplyMessage';
import ReplyMessageDepricated from './ReplyMessageDepricated';
import SummaryBubble from './bubble/Summary/Index.vue';
import DeleteModal from '../modal/DeleteModal';
import LineChartSkeleton from 'dashboard/components/LineChartSkeleton';
import MediaSlider from 'dashboard/components/ui/MediaSlider';
import AgentAvatar from 'widget/components/AgentAvatar';
import AgentInfoCard from 'widget/components/AgentInfoCard';

import timeMixin from '../../../mixins/time';
import alertMixin from 'shared/mixins/alertMixin';
import contentTypeMixin from 'shared/mixins/contentTypeMixin';
import shopifyMixin from 'dashboard/mixins/shopify';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import emailMessageMixin from 'shared/mixins/emailMessageMixin';
import messageMixin from 'shared/mixins/messageMixin';
import inboxMixin from 'shared/mixins/inboxMixin';
import DeletedMessageTag from './bubble/DeletedMessageTag.vue';

import {
  MESSAGE_TYPE,
  MESSAGE_CONTENT_TYPE,
  MESSAGE_STATUS,
} from 'shared/constants/messages';
import { generateBotMessageContent } from './helpers/botMessageContentHelper';
import {
  S3_BUCKET_URL,
  UNSUPPORTED_TYPE_MESSAGE_PLACEHOLDER,
} from 'shared/constants/messages';
import { isString, isNonEmptyString } from 'dashboard/helper/stringUtils';

export default {
  components: {
    BubbleActions,
    BubbleText,
    BubbleTextShadowed,
    QuotedText,
    BubbleImage,
    BubbleFile,
    EmailFile,
    BubbleTooltip,
    BubbleMailHead,
    SummaryBubble,
    ReplyMessage,
    ReplyMessageDepricated,
    DeleteModal,
    LineChartSkeleton,
    MediaSlider,
    AgentAvatar,
    AgentInfoCard,
    DeletedMessageTag,
  },
  mixins: [
    timeMixin,
    messageFormatterMixin,
    contentTypeMixin,
    alertMixin,
    shopifyMixin,
    emailMessageMixin,
    messageMixin,
    inboxMixin,
  ],
  props: {
    data: {
      type: Object,
      required: true,
    },
    inbox: {
      type: Object,
      default: () => {},
    },
    nextMessage: {
      type: Object,
      default: () => {},
    },
    isATweet: {
      type: Boolean,
      default: false,
    },
    isAnEmail: {
      type: Boolean,
      default: false,
    },
    isAPost: {
      type: Boolean,
      default: false,
    },
    channel: {
      type: String,
      default: '',
    },
    inReplyDataDep: {
      type: Object,
      default: () => {
        return {
          senderName: '',
          senderMessage: '',
        };
      },
    },
    getReplyData: {
      type: [Function, Object],
      default: () => null,
    },
    index: {
      type: Number,
      required: true,
    },
    isLatestEmail: {
      type: Boolean,
      default: false,
    },
    isReplyBoxOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDeleteModal: false,
      showMediaSlider: false,
      isExpanded: false,
      selectedMediaFile: '',
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      accountDetails: 'accounts/getAccountDetails',
    }),
    showIdentifier() {
      return (
        (this.isIncoming || (this.isTemplate && this.templateData)) &&
        this.data.identifier
      );
    },
    disableEmailButtons() {
      return this.addBillingRestrictions(this.accountDetails);
    },
    isAssigned() {
      return !!this.currentChat.meta.assignee;
    },
    contentToBeParsed() {
      const defaultEmailContent = {
        html_content: { reply: '' },
        text_content: { reply: '' },
        verification_content: '',
      };

      const {
        html_content: { reply: replyHTMLContent } = {},
        text_content: { reply: replyTextContent } = {},
        verification_content,
      } = this.contentAttributes.email || defaultEmailContent;

      const contentFromS3 = this.contentFromS3;

      // Array of potential content sources in order of priority
      const potentialContents = [
        contentFromS3,
        replyHTMLContent,
        replyTextContent,
        verification_content,
      ];

      // Find the first valid non empty string content
      const validContent = potentialContents.find(isNonEmptyString);

      return validContent || '';
    },
    quotedContent() {
      if ('email' in this.contentAttributes) {
        const {
          html_content: { quoted: quotedHTMLContent } = {},
          text_content: { quoted: quotedTextContent } = {},
        } = this.contentAttributes.email || {};
        const quotedParsedContent =
          quotedHTMLContent || quotedTextContent || '';

        return this.quotedContentFromS3 || quotedParsedContent || '';
      }

      const { quoted_text: quotedText } = this.contentAttributes || {};

      let quotedParsedContent = this.quotedContentFromS3 || quotedText;
      quotedParsedContent = this.formatMessage(
        quotedParsedContent?.replaceAll('\n', '\n>'),
        false,
        true
      );
      return quotedParsedContent || '';
    },
    isQuotedContent() {
      return (
        this.isAnEmail &&
        ((this.isIncoming && this.quotedContent.includes('<blockquote')) ||
          ((this.isOutgoing || this.isForwarded) &&
            this.quotedContent.includes('>')))
      );
    },
    messageComputed() {
      const botMessageContent = generateBotMessageContent(
        this.contentType,
        this.contentAttributes,
        {
          noResponseText: this.$t('CONVERSATION.NO_RESPONSE'),
          csat: {
            ratingTitle: this.$t('CONVERSATION.RATING_TITLE'),
            feedbackTitle: this.$t('CONVERSATION.FEEDBACK_TITLE'),
          },
        }
      );
      if (botMessageContent && this.isInputCsat) return botMessageContent;

      const {
        email: { content_type: contentType = '' } = {},
      } = this.contentAttributes;
      if (this.contentToBeParsed && this.isIncoming) {
        let parsedContent = null;
        if (this.isAnEmail)
          parsedContent = this.sanitizeContent(this.contentToBeParsed);
        else parsedContent = this.stripStyleCharacters(this.contentToBeParsed);
        if (parsedContent) {
          return contentType.includes('text/plain')
            ? parsedContent.replace(/\n/g, '<br />')
            : parsedContent;
        }
      }

      if (this.data.content !== null) {
        const {
          content_attributes: { submitted_values: [sv] = [] } = {},
        } = this.data;
        if (this.isInputSelect) {
          if (sv) {
            return (
              this.getButtonText(this.data) +
              '<br><b>User Selected:</b><br>' +
              this.formatMessage(sv.title)
            );
          }

          return this.getButtonText(this.data);
        }

        if (this.isProductMessage) {
          return this.formatMessage(
            `${this.data.content}\n\n ProductID: ${this.contentAttributes.product_id}`
          );
        }

        if (this.templateData) {
          const { short_code, media_url } = this.templateData;
          let content = '';
          if (short_code) {
            content += `*Template: ${this.templateData.short_code}*\n\n`;
          }
          if (media_url) {
            content += `MEDIA: ${this.templateData.media_url}\n\n`;
          }

          return this.formatMessage(`${content}${'---\n' + this.data.content}`);
        }

        const hasS3LinkToFetchContent = this.data.content.startsWith(
          S3_BUCKET_URL
        );

        if (hasS3LinkToFetchContent) {
          this.fetchContent(this.data.content);
        } else
          return (
            this.formatMessage(
              this.data.content,
              this.isATweet,
              this.isAnEmail
            ) + botMessageContent
          );
      }

      return '';
    },
    // safe guard if message is not a string
    message() {
      const message = this.messageComputed;
      return isString(message) ? message : UNSUPPORTED_TYPE_MESSAGE_PLACEHOLDER;
    },
    contentAttributes() {
      return this.data.content_attributes || {};
    },
    sender() {
      return this.data.sender || {};
    },
    messageStatusDetails() {
      return this.contentAttributes?.details || '';
    },
    isInteractiveReply() {
      return this.contentType === 'interactive_reply';
    },
    contentType() {
      const {
        data: { content_type: contentType },
      } = this;
      return contentType;
    },
    twitterProfileLink() {
      const additionalAttributes = this.sender.additional_attributes || {};
      const { screen_name: screenName } = additionalAttributes;
      return `https://twitter.com/${screenName}`;
    },
    alignBubble() {
      if (this.isAnEmail) return '';
      if (
        [
          MESSAGE_TYPE.INCOMING,
          MESSAGE_TYPE.SYNC_IN,
          MESSAGE_TYPE.GPT,
        ].includes(this.data.message_type)
      )
        return 'left';
      return 'right';
    },
    templateData() {
      return this.data.content_attributes?.template_data;
    },
    readableTime() {
      let timeFormat = 'h:mm a';
      if (this.isAnEmail && this.data.private) {
        timeFormat = ' LLL d yyyy, h:mm a';
      }
      return this.messageStamp(this.data.created_at, timeFormat);
    },
    isBubble() {
      if (this.isAnEmail) return false;
      return [
        MESSAGE_TYPE.INCOMING,
        MESSAGE_TYPE.OUTGOING,
        MESSAGE_TYPE.TEMPLATE,
        MESSAGE_TYPE.SYNC_IN,
        MESSAGE_TYPE.SYNC_OUT,
        MESSAGE_TYPE.START_CONVERSATION,
        MESSAGE_TYPE.INFO_COLLECT,
        MESSAGE_TYPE.OUTBOUND_BOT,
        MESSAGE_TYPE.OUT_OF_OFFICE,
        MESSAGE_TYPE.CSAT_QUESTION,
        12,
        13,
      ].includes(this.data.message_type);
    },
    isActivity() {
      return [MESSAGE_TYPE.ACTIVITY, MESSAGE_TYPE.INFO_COLLECT].includes(
        this.data.message_type
      );
    },
    isIncoming() {
      return this.data.message_type === MESSAGE_TYPE.INCOMING;
    },
    isGptSummary() {
      return this.data.message_type === MESSAGE_TYPE.GPT;
    },
    isBotTypeMessage() {
      return ['agent_bot', 'outbound_bot'].includes(this.sender?.type);
    },
    isOutOfOffice() {
      return this.data.message_type === MESSAGE_TYPE.OUT_OF_OFFICE;
    },
    isOutgoing() {
      return this.data.message_type === MESSAGE_TYPE.OUTGOING;
    },
    isForwarded() {
      return this.data.message_type === MESSAGE_TYPE.FORWARDED;
    },
    isTemplate() {
      return this.data.message_type === MESSAGE_TYPE.TEMPLATE;
    },
    isCsatQuestion() {
      return this.data.message_type === MESSAGE_TYPE.CSAT_QUESTION;
    },
    isCsat() {
      return this.isOutgoing && this.data.content_type === 'input_csat';
    },
    emailHeadAttributes() {
      return {
        email: this.contentAttributes.email,
        cc: this.contentAttributes.cc_emails,
        bcc: this.contentAttributes.bcc_emails,
      };
    },
    identifierToText() {
      return this.data.identifier.split('_').join(' ');
    },
    emailSignature() {
      let {
        signature,
        signature_type,
        has_email_signature,
      } = this.contentAttributes;
      if (!has_email_signature) return false;

      if (signature_type === 'markdown')
        signature = this.formatMessage(signature);
      return '<br />' + signature;
    },
    hasAttachments() {
      return !!(this.data.attachments && this.data.attachments.length > 0);
    },
    getItems() {
      const {
        content_attributes: { items: cards = [] },
      } = this.data;
      cards.forEach((card, index) => {
        card.id = index;
      });
      return cards;
    },
    hasImageAttachment() {
      if (this.hasAttachments && this.data.attachments.length > 0) {
        const { attachments = [{}] } = this.data;
        const { file_type: fileType } = attachments[0];
        return fileType === 'image';
      }
      return false;
    },
    hasText() {
      if (this.isGptSummary) return false;
      return (
        !!this.data.content ||
        this.isInputSelect ||
        this.data.content_attributes?.email?.verification_content ||
        this.isQuotedContent
      );
    },
    sentByMessage() {
      return this.data.message_type === MESSAGE_TYPE.OUTGOING;
    },
    showMoreOption() {
      return (
        [MESSAGE_TYPE.OUTGOING, MESSAGE_TYPE.SYNC_OUT].includes(
          this.data.message_type
        ) &&
        !this.data.private &&
        this.canShowDeleteAction
      );
    },
    isFirstEmail() {
      return (
        (this.isAnEmail && this.isIncoming && this.index === 0) ||
        this.index === 1
      );
    },
    isFirstIncomingComment() {
      return (
        this.isAPost &&
        this.isIncoming &&
        (this.index === 0 || this.index === 1)
      );
    },
    isInstagramOrFacebook() {
      return (
        this.hasFacebookComments ||
        this.hasInstagramComments ||
        this.hasFacebookDms ||
        this.isInstagramDm
      );
    },
    actionsList() {
      if (!this.canShowReplyMessageActions) {
        return [];
      }

      const { replierName } = this.replyData;

      const actions = [
        {
          id: 'reply',
          icon: 'reply',
          name: `Reply to ${replierName}`,
          value: 'reply',
        },
      ];

      if (this.isIncoming) {
        actions.push({
          id: 'private_reply',
          icon: 'messenger',
          name: `Send a private DM to ${replierName}`,
          value: 'private_reply',
        });
      }

      if (this.canShowDeleteAction) {
        actions.push({
          id: 'delete_message',
          icon: 'delete',
          name: `Delete message`,
          value: 'delete_message',
        });
      }

      return actions;
    },
    canShowReplyMessageHeader() {
      return this.isInstagramOrFacebook;
    },
    isInstagramComment() {
      return (this.isIncoming || this.isOutgoing) && this.hasInstagramComments;
    },
    isFacebookComment() {
      return (this.isIncoming || this.isOutgoing) && this.hasFacebookComments;
    },
    isFacebookDm() {
      return (this.isIncoming || this.isOutgoing) && this.hasFacebookDms;
    },
    replyData() {
      return this.getReplyData().payload;
    },
    canShowReplyMessageActions() {
      // don't show these actions for deleted comments or DMs
      if (this.isDeleted) {
        return false;
      }
      return (
        this.isFacebookDm || this.isFacebookComment || this.isInstagramComment
      );
    },
    canShowDeleteAction() {
      return this.isInstagramComment && !this.isDeleted;
    },
    showReplyDep() {
      if (
        [
          MESSAGE_TYPE.INCOMING,
          MESSAGE_TYPE.OUTGOING,
          MESSAGE_TYPE.SYNC_OUT,
        ].includes(this.data.message_type) &&
        this.data['content_attributes']
      ) {
        return !!this.data.content_attributes['in_reply_to'];
      }

      return false;
    },
    forEmail() {
      return [
        MESSAGE_TYPE.INCOMING,
        MESSAGE_TYPE.OUTGOING,
        MESSAGE_TYPE.TEMPLATE,
        MESSAGE_TYPE.OUT_OF_OFFICE,
        MESSAGE_TYPE.FORWARDED,
        MESSAGE_TYPE.CSAT_QUESTION,
      ].includes(this.data.message_type);
    },
    notForEmail() {
      return ![
        MESSAGE_TYPE.OUTGOING,
        MESSAGE_TYPE.TEMPLATE,
        MESSAGE_TYPE.OUT_OF_OFFICE,
        MESSAGE_TYPE.FORWARDED,
        MESSAGE_TYPE.CSAT_QUESTION,
        MESSAGE_TYPE.GPT,
      ].includes(this.data.message_type);
    },
    wrapClass() {
      return {
        wrap: this.isBubble || this.isGptSummary,
        'email-summary-wrap': this.isGptSummary && this.isAnEmail,
        'email-wrap': this.isAnEmail && this.forEmail && !this.isBubble,
        'forwarded-email':
          this.isAnEmail &&
          this.data.message_type === MESSAGE_TYPE.FORWARDED &&
          !this.isBubble,
        'email-wrap--is-private': this.data.private && this.isAnEmail,
        'activity-wrap':
          this.data.content_type !== 'incoming_email' &&
          this.notForEmail &&
          !this.isBubble,
        'is-pending': this.isPending,
        'deleted-message-outline':
          this.isDeleted && this.replyMessageTheme === 'instagram',
      };
    },
    bubbleClass() {
      return {
        bubble: this.isBubble,
        'is-private': this.data.private,
        'is-image': this.hasImageAttachment,
        'is-text': this.hasText,
        'is-cards': this.isCards,
      };
    },
    isCSATOrWaitTime() {
      return (
        this.data.content_type === MESSAGE_CONTENT_TYPE.CSAT ||
        this.data.content_type === MESSAGE_CONTENT_TYPE.WAIT_TIME
      );
    },
    hasMultipleRecepients() {
      if (this.isCSATOrWaitTime) return false;
      if (
        'email' in this.contentAttributes &&
        !this.contentAttributes.email.to?.length === 1 &&
        !this.contentAttributes.email.cc &&
        !this.contentAttributes?.email?.bcc
      )
        return false;
      if (
        this.contentAttributes?.to_emails?.split(',').length === 1 &&
        !this.contentAttributes?.cc_emails &&
        !this.contentAttributes?.bcc_emails
      )
        return false;
      if (this.data.content_type === 'input_csat') return false;
      return true;
    },
    isPending() {
      return this.data.status === MESSAGE_STATUS.PROGRESS;
    },
    getActionList() {
      return [
        {
          id: 'delete_message',
          icon: 'delete',
          name: `Delete message`,
          value: 'delete_message',
        },
      ];
    },
    getEmailActionList() {
      let actions = this.$t('CONVERSATION.EMAIL_ACTIONS');

      if (!this.hasMultipleRecepients)
        actions = actions.filter(action => action.id !== 2);

      return actions.map((item, index) => {
        return {
          id: index,
          name: item.NAME,
          value: item.VALUE,
          icon: item.ICON,
        };
      });
    },
    isDeleted() {
      if (
        [
          MESSAGE_TYPE.INCOMING,
          MESSAGE_TYPE.OUTGOING,
          MESSAGE_TYPE.SYNC_OUT,
        ].includes(this.data.message_type) &&
        this.data['content_attributes']
      ) {
        return this.data.content_attributes?.deleted;
      }

      return false;
    },
    canExpand() {
      return (
        this.data.message_type !== MESSAGE_TYPE.TEMPLATE &&
        !this.isLatestEmail &&
        !this.isExpanded &&
        this.message.length >= 90
      );
    },
    selectedMediaFileIndex() {
      return this.mediaAttachments.findIndex(
        mediaAttachment => mediaAttachment.id === +this.selectedMediaFile
      );
    },
    mediaAttachments() {
      return this.data.attachments.reduce((data, attachment) => {
        if (this.isMediaType(attachment.data_url))
          data.push({ id: attachment.id, url: attachment.data_url });
        return data;
      }, []);
    },
    replyMessageTheme() {
      if (this.hasFacebookComments || this.hasFacebookDms) return 'facebook';
      if (this.hasInstagramComments || this.isInstagramDm) return 'instagram';
      return undefined;
    },
    messageContentAttributes() {
      return (this.data && this.data.content_attributes) || {};
    },
  },
  mounted() {
    this.fetchContentFromS3({
      content: this.contentToBeParsed,
      quotedContent: this.quotedContent,
    });
  },
  methods: {
    getButtonText(buttons) {
      var message_str = buttons.content
        ? this.formatMessage(buttons.content)
        : '';
      buttons.content_attributes.items.forEach((item, index) => {
        message_str += index + 1 + ': ' + item.title + '<br>';
      });
      return message_str;
    },
    getCardText(card) {
      const {
        content_attributes: { submitted_values: [sv] = [] } = {},
      } = this.data;
      if (sv) {
        return (
          this.formatMessage(card.title) +
          '<br>' +
          this.formatMessage(card.description) +
          '<br><br><b>User Selected: </b>' +
          sv.text
        );
      }
      return (
        this.formatMessage(card.title) +
        '<br>' +
        this.formatMessage(card.description)
      );
    },
    getProductText(product) {
      return `${product.title}<br> ProductID's: ${product.product_ids?.join(
        ','
      )}<br><br>`;
    },
    videoFileType(media_url) {
      const theFileType = media_url.substring(media_url.lastIndexOf('.') + 1);
      if (theFileType === 'mp4') {
        return true;
      }
      return false;
    },
    onEmailActionSelected(val) {
      const payload =
        {
          ...this.data,
          s3_content: this.contentToBeParsed,
          s3_quoted_content: this.quotedContent,
        } || {};

      switch (val.value) {
        case 'forward':
          this.$emit('onForwardEmail', payload);
          break;
        case 'reply':
          this.$emit('onReplyEmail', payload);
          break;
        case 'reply_to_all':
          this.$emit('onReplyAllEmail', payload);
          break;
        default:
          break;
      }
    },
    toggleAction(val) {
      if (val.value === 'delete_message') {
        this.toggleDeleteModal();
      }
    },
    onReplyMessageActionSelected({ value }) {
      switch (value) {
        case 'reply':
          this.getReplyData({ showOnReplyBox: true });
          break;
        case 'private_reply': {
          const { hasPrivateReplyAlready } = this.replyData;

          if (hasPrivateReplyAlready) {
            this.showAlert(
              this.$t('CONVERSATION.SOCIAL_POST.PRIVATE_REPLY.ALREADY_REPLIED'),
              'error'
            );
            break;
          }

          this.getReplyData({ showPrivateReply: true });
          break;
        }

        case 'delete_message':
          this.toggleDeleteModal();
          break;
        default:
          break;
      }
    },
    toggleDeleteModal() {
      this.showDeleteModal = !this.showDeleteModal;
    },
    async deleteMessage() {
      try {
        let messagePayload = {
          conversationId: this.data.conversation_id,
          messageId: this.data.id,
        };
        await this.$store.dispatch('deleteMessage', messagePayload);
        this.showAlert('Comment deleted successfully!', 'info');
      } catch (error) {
        this.showAlert('Error deleting message', 'error');
      }
      this.toggleDeleteModal();
    },
    sendReplyPayload() {
      this.getReplyData({ showOnReplyBox: true });
    },
    getButtonLink(button) {
      if (button?.url && this.templateData?.button_url) {
        const baseURL = button.url.replace('{}', '');
        return baseURL + this.templateData.button_url.replace(baseURL, '');
      }

      return '';
    },
    truncateCollapseMessage(description = '') {
      if (description.length < 90) return description;

      return `${description.slice(0, 86)}...`;
    },
    isMediaType(url) {
      const fileTypes = {
        jpeg: true,
        jpg: true,
        png: true,
        mp4: true,
        svg: true,
        gif: true,
      };
      const fileType = url.substring(url.lastIndexOf('.') + 1);
      return fileTypes[fileType] || false;
    },
    onOpenMediaSlider(mediaFileId) {
      this.selectedMediaFile = mediaFileId;
      this.showMediaSlider = true;
    },
    openLink(url) {
      window.open(url, '_blank', 'noopener');
    },
  },
};
</script>
<style lang="scss">
@import '~dashboard/assets/scss/variables';

.wrap {
  order: 2;

  .deleted-title {
    color: $r-500;
    width: 100%;
    display: flex;
    font-size: $font-size-small;
    line-height: 22px;
    padding-left: $space-slab;
    padding-right: $space-slab;
  }

  .deleted-reply {
    opacity: 0.4;
  }

  &:hover {
    + {
      .message-tooltip {
        display: flex;
        margin-right: -$space-small;
        font-size: $font-size-micro;
        line-height: 1.6;
        color: $secondary-blue;

        .message-deleted {
          color: $r-500;
        }

        .message-tooltip--value {
          color: $neutral-grey-600;
        }
      }
    }
  }

  > .bubble {
    &.is-image {
      padding: 0;
      overflow: hidden;

      .bubble-more-action {
        .actions--container {
          .dropdown-pane {
            top: $space-medium;
            bottom: auto;
          }
        }
      }

      .image {
        max-width: 32rem;
        padding: $space-micro;

        > img {
          border-radius: $border-radius;
        }
      }
    }

    &.is-image.is-text > .message-text__wrap {
      max-width: 32rem;
      padding: $space-small $space-normal;
    }
  }

  &.with-avatar {
    display: flex;
    align-items: flex-end;
    gap: $space-six;
  }

  &.is-pending {
    position: relative;
    opacity: 0.5;

    .spinner {
      padding: 0 0.8rem;
      margin-bottom: 0.6rem;
    }

    > .is-image.is-text.bubble > .message-text__wrap {
      padding: 0;
    }
  }
}
.disable-email-dropdown {
  opacity: 0.5;
  pointer-events: none;
}
.files-container {
  padding: $zero $space-large;
  gap: $space-normal;
  flex-wrap: wrap;
}

.sender--info {
  align-items: center;
  color: $neutral-grey-600;
  display: inline-flex;
  padding: $space-smaller 0;

  .sender--available-name {
    font-size: $font-size-mini;
    margin-left: $space-smaller;
  }
}

.reply-button {
  top: 2.4rem;
  width: auto;
  left: auto;
  border-radius: $border-radius;
  padding: $space-smaller;
}

.email-head {
  cursor: pointer;

  .email-action {
    position: absolute;
    top: $space-slab;
    right: $space-medium;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-in-out;
  position: relative;
  z-index: 9999;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.status-message {
  &--div {
    width: 100%;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.2) 0,
      rgba(217, 217, 217, 0.2) 50%,
      rgba(255, 255, 255, 0.2) 100%
    );
  }
  &--wrapper {
    display: grid;
    grid-template-columns: 0.5fr 2fr 0.5fr;
  }
  &--text {
    grid-column-start: 2;
    text-align: center;
    > .message-text__wrap {
      background: transparent !important;
      p {
        margin-top: 1rem;
        color: $text-light;
      }
    }
  }
}
.status-message--div {
  > .status-message--wrapper {
    > .status-message--actions {
      justify-content: end;
      margin: $zero $space-two;
      padding-top: 0;
      background: transparent;
      align-items: center;
      > .time {
        color: $text-light;
      }
    }
  }
}

.forwarded-text {
  margin-left: $space-smaller;
}

.deleted-message-outline {
  border: 1px solid $warn-red-500;
  border-radius: 0.8rem;
  pointer-events: none;
}
</style>
