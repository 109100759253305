var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.uiFlags.isFetching || !_vm.customField
    ? _c("loading-state", { staticStyle: { height: "100%" } })
    : _c("custom-field-form", {
        attrs: {
          "edit-view": "",
          "custom-field": _vm.customField,
          "initial-custom-field-inboxes": _vm.customFieldInboxes
        },
        on: { submitFieldForm: _vm.updateCustomField }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }