var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CreateFromTemplate", {
    attrs: {
      "can-create-new": false,
      templates: _vm.CUSTOM_FIELD_TEMPLATES,
      "modal-title": _vm.$t("CUSTOM_TICKET_FIELDS.CREATE.CREATE_MODAL.TITLE"),
      loading: _vm.uiFlags.isFetchingTemplates,
      opened: "",
      "handle-create": function(data) {
        return _vm.$emit("handleCreate", data)
      }
    },
    on: {
      onClose: function($event) {
        return _vm.$emit("onClose")
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }