var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("apexchart", {
    ref: "chart",
    attrs: {
      width: "100%",
      height: _vm.chartHeight,
      type: "line",
      options: _vm.chartOptions,
      series: _vm.series
    },
    on: { zoomed: _vm.onZoom }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }