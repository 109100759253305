import { render, staticRenderFns } from "./Reminders.vue?vue&type=template&id=10d8a781&"
import script from "./Reminders.vue?vue&type=script&lang=js&"
export * from "./Reminders.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/limechat/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('10d8a781')) {
      api.createRecord('10d8a781', component.options)
    } else {
      api.reload('10d8a781', component.options)
    }
    module.hot.accept("./Reminders.vue?vue&type=template&id=10d8a781&", function () {
      api.rerender('10d8a781', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/lime-ui/dashboard/routes/dashboard/settings/sla/components/create/sections/Reminders.vue"
export default component.exports