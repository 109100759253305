var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "conversation-header" }, [
    _c(
      "div",
      { staticClass: "header-title" },
      [_c("skeleton-loader", { attrs: { width: "200px", height: "28px" } })],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "search-filter" },
      [_c("skeleton-loader", { attrs: { width: "100%", height: "32px" } })],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "filter-options" },
      [
        _c("skeleton-loader", { attrs: { width: "100px", height: "30px" } }),
        _vm._v(" "),
        _c("skeleton-loader", { attrs: { width: "100px", height: "30px" } }),
        _vm._v(" "),
        _c("skeleton-loader", { attrs: { width: "100px", height: "30px" } })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }