var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "status-container flex-column flex-align" }, [
    _c("hr", { staticClass: "top--bar" }),
    _vm._v(" "),
    _vm.currentStatus.message
      ? _c(
          "span",
          {
            staticClass: "capital_c2",
            style: "color: " + _vm.currentStatus.color
          },
          [_vm._v("\n    " + _vm._s(_vm.currentStatus.message) + "\n  ")]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.currentStatus.status
      ? _c(
          "span",
          {
            staticClass: "title-h5",
            style: "color: " + _vm.currentStatus.statusColor
          },
          [_vm._v("\n    " + _vm._s(_vm.currentStatus.status) + "\n  ")]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }