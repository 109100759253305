var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$auth.error
    ? _c("ErrorPage")
    : _c(
        "div",
        { staticClass: "full-height full-width" },
        [
          _c("LoadingState", {
            attrs: { message: "Taking you to your dashboard..." }
          })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }