var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column page-top-bar" },
    [
      _vm.headerImage
        ? _c("img", { attrs: { src: _vm.headerImage, alt: "No image" } })
        : _vm._e(),
      _vm._v(" "),
      _vm.headerTitle
        ? _c("span", { staticClass: "page-sub-title title-h2" }, [
            _vm._v("\n    " + _vm._s(_vm.headerTitle) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.headerContent
        ? _c("p", {
            staticClass: "small-12 column header-content title-h4_m text-dark",
            domProps: { innerHTML: _vm._s(_vm.headerContent) }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }