var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-fields column content-box settings-box no-padding" },
    [
      _c(
        "div",
        { staticClass: "settings-button flex-row flex-align gap--normal" },
        [
          _c("reference-box", {
            attrs: {
              to: _vm.documentation.customFields.resource,
              content: "Know More",
              type: "document"
            }
          }),
          _vm._v(" "),
          _c("reference-box", {
            attrs: {
              to: _vm.documentation.customFields.demoVideo,
              content: "Video Demo",
              type: "video"
            }
          }),
          _vm._v(" "),
          _c(
            "woot-base-button",
            {
              attrs: {
                size: "medium",
                "front-icon": "plus",
                "icon-view-box": "0 0 20 20"
              },
              on: { click: _vm.openAddPopup }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("CUSTOM_TICKET_FIELDS.HEADER_BTN_TXT")) +
                  "\n    "
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "settings-container" },
        [
          _c(
            "div",
            { staticClass: "column settings-main-menu-new" },
            [
              _c("index-header", {
                on: {
                  setSearch: _vm.setSearch,
                  selectedInbox: function($event) {
                    _vm.selectedInbox = $event
                  },
                  selectedType: function($event) {
                    _vm.selectedType = $event
                  }
                }
              }),
              _vm._v(" "),
              _c("List", {
                attrs: {
                  "table-columns": _vm.tableColumns,
                  "custom-fields-list": _vm.filteredCustomFieldsList,
                  loading: _vm.isLoading,
                  search: _vm.search
                },
                on: {
                  viewCustomField: _vm.viewCustomField,
                  openAddPopup: _vm.openAddPopup
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "settings-side-card",
            [
              !_vm.selectedCustomField
                ? _c("educational-onboarding", {
                    attrs: { "show-case-data": _vm.SHOWCASE_CUSTOM_FIELD_DATA }
                  })
                : _c("rhs-panel", {
                    attrs: { "custom-field": _vm.selectedCustomField }
                  })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "modal-fade" } },
            [
              _vm.showCreateModal
                ? _c("create-custom-field-modal", {
                    on: {
                      handleCreate: _vm.onHandleCreate,
                      onClose: function($event) {
                        _vm.showCreateModal = false
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "modal-fade" } },
            [
              _vm.showDetailsModal
                ? _c("custom-field-details-modal", {
                    attrs: {
                      opened: "",
                      name: _vm.selectedCustomFieldDetails.name,
                      description: _vm.selectedCustomFieldDetails.description
                    },
                    on: {
                      onSubmit: _vm.onSubmitDetailsModal,
                      onClose: function($event) {
                        _vm.showDetailsModal = false
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }