var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "primary-button flex-space-between",
      class: [_vm.size, _vm.customClass, "button-" + _vm.size],
      style: [_vm.customStyle, _vm.fullWidthClass],
      attrs: { disabled: _vm.disabled, name: _vm.name },
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [
      _vm.frontIcon
        ? _c(
            "span",
            { staticStyle: { "margin-right": "0.8rem" } },
            [
              _c("icons", {
                attrs: {
                  name: _vm.frontIcon,
                  color: "white",
                  view: _vm.iconViewBox,
                  size: _vm.icon
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.name))]),
      _vm._v(" "),
      _vm.backIcon
        ? _c(
            "span",
            { staticStyle: { "margin-left": "0.8rem" } },
            [
              _c("icons", {
                attrs: {
                  name: _vm.backIcon,
                  color: "white",
                  view: _vm.iconViewBox,
                  size: _vm.icon
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loading
        ? _c("span", { staticClass: "spinner-margin" }, [_c("spinner")], 1)
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }