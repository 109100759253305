var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container--heading" },
    [
      _c("page-header", {
        attrs: {
          "header-title": _vm.$t(
            "INBOX_MGMT.ADD.VOICE_CHANNEL.KNOWLARITY_AGENTS.TITLE"
          ),
          "header-content": _vm.$t(
            "INBOX_MGMT.ADD.VOICE_CHANNEL.KNOWLARITY_AGENTS.DESC"
          )
        }
      }),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _vm.knowlarityAgents.length
        ? _c("div", { staticClass: "agents-heading mb-2 title-h4 text-dark" }, [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.$t(
                    "INBOX_MGMT.ADD.VOICE_CHANNEL.KNOWLARITY_AGENTS.SUB_TITLE"
                  )
                ) +
                "\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.knowlarityAgents.length
        ? _c(
            "div",
            { staticClass: "container--agents" },
            _vm._l(_vm.knowlarityAgents, function(agent) {
              return _c(
                "div",
                { key: agent.id, staticClass: "flex-row agent-info" },
                [
                  _c("avatar", {
                    attrs: {
                      username: agent.first_name + " " + agent.last_name,
                      rounded: "",
                      size: 24,
                      colored: true,
                      "custom-style": {
                        "box-shadow": "none",
                        border: "none"
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.right",
                          value: agent.phone,
                          expression: "agent.phone",
                          modifiers: { right: true }
                        }
                      ],
                      staticClass: "title-h5 text-dark"
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(agent.first_name + " " + agent.last_name) +
                          "\n      "
                      )
                    ]
                  )
                ],
                1
              )
            }),
            0
          )
        : _c("div", {
            staticClass: "body-b2 text-light text-align",
            domProps: { textContent: _vm._s("NO AGENTS FOUND") }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }