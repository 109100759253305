var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "text-align mg-bottom--large" }, [
        _c("span", {
          staticClass: "body-b2 text-dark",
          domProps: {
            innerHTML: _vm._s(
              _vm.$t("ONBOARDING.WHATSAPP.THREE_SIXTY_DIALOG.INFO_TEXT", {
                phoneNumber: _vm.inbox.phone_number
              })
            )
          }
        }),
        _vm._v(" "),
        _c("span", {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.$t(
                "ONBOARDING.WHATSAPP.THREE_SIXTY_DIALOG.INFO_TOOLTIP"
              ),
              expression:
                "$t('ONBOARDING.WHATSAPP.THREE_SIXTY_DIALOG.INFO_TOOLTIP')"
            }
          ],
          staticClass: "body-b2 text-light",
          domProps: {
            innerHTML: _vm._s("<b><em>required information.</em></b>")
          }
        })
      ]),
      _vm._v(" "),
      _c("connect-button", {
        staticClass: "mg-auto",
        attrs: {
          env: "prod",
          label: _vm.$t("ONBOARDING.WHATSAPP.THREE_SIXTY_DIALOG.LOGIN.BUTTON"),
          "query-parameters": {
            redirect_url: _vm.setRedirectUrl(),
            state: _vm.inbox.phone_number
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }