var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "assign-agent-section title-h5_m text-dark" },
    [
      _c(
        "div",
        { staticClass: "label-container" },
        [
          _c("span", {
            staticClass: "title-h5_m text-dark",
            domProps: { textContent: _vm._s(_vm.titleText) }
          }),
          _vm._v(" "),
          _vm.isTagTypeConv
            ? _c("conversation-labels", {
                staticClass: "label-input",
                attrs: {
                  "show-add-button": "",
                  placeholder: "No tags added yet",
                  "selected-labels": _vm.selectedLabelsCopy
                },
                on: { updateLabels: _vm.updateLabels }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isTagTypeContact
            ? _c("contact-labels", {
                staticClass: "label-input",
                attrs: {
                  "show-add-button": "",
                  placeholder: "No tags added yet",
                  "contact-labels": _vm.selectedLabelsCopy
                },
                on: { updateLabels: _vm.updateLabels }
              })
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }