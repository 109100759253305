var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    { staticClass: "image-label" },
    [
      _vm.label ? _c("span", [_vm._v(_vm._s(_vm.label))]) : _vm._e(),
      _vm._v(" "),
      _vm.src
        ? _c("woot-thumbnail", {
            attrs: {
              size: "120px",
              color: "#1b83ac",
              src: _vm.src,
              username: _vm.username,
              "has-border": true
            }
          })
        : _c("img", {
            staticClass: "alt-thumbnail",
            attrs: {
              src: require("dashboard/assets/images/flag.svg"),
              alt: "No Page Image"
            }
          }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "image-upload" },
        [
          _c(
            "file-upload",
            {
              attrs: { size: 4096 * 4096, accept: "image/*" },
              on: { "input-filter": _vm.handleImageUpload }
            },
            [
              _c("icons", {
                attrs: {
                  name: "edit",
                  size: "semimedium",
                  color: "stroke-green"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }