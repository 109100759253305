var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "template-substitutions-wrapper" },
    [
      _vm.hasMedia
        ? _c("woot-input", {
            staticClass:
              "template-subsitution-input-field mg-bottom--one CREATE_TICKET_MEDIA_URL",
            class: { error: _vm.$v.mediaUrl.$error },
            attrs: {
              required: "",
              size: "small",
              label: "Media Url Link",
              value: _vm.mediaUrl,
              placeholder: "Enter media url",
              "has-error": _vm.$v.mediaUrl.$error,
              error: _vm.$t("CREATE_TICKET.FORM.ERRORS.NOT_VALID_MEDIA_URL")
            },
            on: {
              blur: _vm.$v.mediaUrl.$touch,
              input: function($event) {
                return _vm.$emit("updateField", "media_url", $event)
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("template-body-substitutions", {
        style: { paddingRight: "25%" },
        attrs: { body: _vm.template.body, substitutions: _vm.substitutions },
        on: { click: _vm.openSubstitutionModal }
      }),
      _vm._v(" "),
      _vm.hasDynamicButtons
        ? _c("woot-input", {
            staticClass:
              "template-subsitution-input-field mg-top--one CREATE_TICKET_DYNAMIC_URL",
            class: { error: _vm.$v.dynamicUrl.$error },
            attrs: {
              required: "",
              size: "small",
              label: "Dynamic Button Url",
              value: _vm.dynamicUrl,
              placeholder: "Enter dynamic button url",
              "has-error": _vm.$v.dynamicUrl.$error,
              error: _vm.$t("CREATE_TICKET.FORM.ERRORS.NOT_VALID_DYNAMIC_URL")
            },
            on: {
              blur: _vm.$v.dynamicUrl.$touch,
              input: function($event) {
                return _vm.$emit("updateField", "dynamic_url", $event)
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "woot-base-button",
        {
          staticClass: "remove-template-btn",
          attrs: { tag: "span", size: "small", variant: "secondary-danger" },
          on: {
            click: function($event) {
              return _vm.$emit("removeTemplate")
            }
          }
        },
        [
          _c("icons", {
            attrs: { name: "delete", size: "normal", color: "red" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("template-body-substitutions-editor", {
        attrs: {
          show: _vm.showSubstitutionModal,
          substitutions: _vm.substitutions,
          "current-selected-variable-index": _vm.currentSelectedVariableIndex
        },
        on: {
          onClose: function() {
            return (_vm.showSubstitutionModal = false)
          },
          input: _vm.onInputTemplateBodySubtitutions
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }