var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "list-item",
      class: { draggable: _vm.isDraggable, active: _vm.active },
      on: { click: _vm.onSelect }
    },
    [
      _c("div", { staticClass: "flex-row gap--slab" }, [
        _vm.isDraggable
          ? _c(
              "span",
              { staticClass: "drag-icon" },
              [
                _c("icons", {
                  attrs: {
                    name: "drag",
                    color: "lightgrey-fill-only",
                    size: "mediumlarge"
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "flex-column" }, [
          _c("div", {
            staticClass: "title-h5 text-dark",
            domProps: { textContent: _vm._s(_vm.data.name) }
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "body-b3 text-light line-clamp-1",
            domProps: { textContent: _vm._s(_vm.data.description) }
          })
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "actions" },
        [
          _c("toggle-sla", {
            attrs: { data: _vm.data },
            on: { toggle: _vm.onToggle }
          }),
          _vm._v(" "),
          _c(
            "woot-base-button",
            {
              attrs: { tag: "span", size: "small", variant: "tertiary" },
              on: { click: _vm.onEdit }
            },
            [
              _c("icons", {
                attrs: {
                  name: "editRound",
                  size: "medium",
                  view: "0 0 24 24",
                  color: "grey"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("woot-single-selector", {
            attrs: {
              variant: "tertiary",
              "custom-style": {
                borderRadius: "0.8rem",
                boxShadow: "none",
                left: "auto",
                right: "0.8rem",
                width: "auto"
              },
              "icon-size-prop": "semimedium",
              icon: "threeDot",
              "show-selected-text": false,
              "show-option-name": false,
              "option-list": _vm.getActionsList
            },
            on: { click: _vm.onActionSelected }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }