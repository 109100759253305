var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row loading-state", style: _vm.customStyle },
    [
      _c("h6", { staticClass: "message" }, [
        _vm._v("\n    " + _vm._s(_vm.message) + "\n    "),
        _c("span", { staticClass: "spinner" })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }