var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        show: _vm.show,
        "on-close": _vm.onClose,
        "show-close": true,
        "small-modal": true
      },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "column content-box" },
        [
          _c("woot-modal-header", {
            attrs: {
              "header-title": _vm.title,
              "header-content": _vm.description
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "flex-column-center" }, [
            _c("img", {
              attrs: {
                src: require("dashboard/assets/images/sent.gif"),
                alt: "sent"
              }
            }),
            _vm._v(" "),
            _vm.resentEmail
              ? _c("span", {
                  staticClass: "success-text",
                  domProps: {
                    innerHTML: _vm._s(
                      "<b>✓</b> " + _vm.$t("REGISTER.RESEND.SUCCESS_MESSAGE")
                    )
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "resend-container" }, [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("REGISTER.RESEND.DESCRIPTION")))
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "button clear",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.resendConfirmation($event)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("REGISTER.RESEND.NAME")) +
                      "\n        "
                  )
                ]
              )
            ])
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }