var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("form-section-header", {
        attrs: {
          heading: _vm.$t("CREATE_TICKET.FORM.SECTIONS.ADD_TEMPLATE.HEADING"),
          "sub-heading": _vm.$t(
            "CREATE_TICKET.FORM.SECTIONS.ADD_TEMPLATE.SUB_HEADING"
          ),
          icon: "template"
        }
      }),
      _vm._v(" "),
      _c("add-template", {
        attrs: {
          "inbox-id": _vm.inboxId,
          "number-of-substitutions": _vm.numberOfSubstitutions
        },
        on: {
          selectedTemplate: function($event) {
            return _vm.$emit("selectedTemplate", $event)
          },
          templateInputs: function($event) {
            return _vm.$emit("templateInputs", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }