var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("whatsapp-component", {
        attrs: {
          messages: _vm.messages,
          "only-chat-screen": "",
          "custom-height": "height: calc(100vh - 320px)"
        }
      }),
      _vm._v(" "),
      _vm.messages[0].buttons.buttons
        ? _c(
            "div",
            {
              staticClass:
                "full-width edit-button-container flex-row flex-justify--end"
            },
            [
              _c(
                "woot-base-button",
                {
                  attrs: { size: "small", variant: "secondary" },
                  on: { click: _vm.handleEditButtonPayload }
                },
                [_vm._v("\n      Edit Button Payload\n    ")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }