var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data.senderMessage
    ? _c("div", { staticClass: "reply-message" }, [
        _c("span", { staticClass: "reply-message--name text-truncate" }, [
          _vm._v(
            "\n    " + _vm._s("Replying to " + _vm.data.senderName) + "\n  "
          )
        ]),
        _vm._v(" "),
        _c("div", [
          _vm.data.type === "text"
            ? _c("p", { staticClass: "reply-message--content" }, [
                _vm._v("\n      " + _vm._s(_vm.data.senderMessage) + "\n    ")
              ])
            : _vm.data.type === "image"
            ? _c("div", { staticClass: "reply-message--image" }, [
                _c("img", { attrs: { src: _vm.data.senderMessage } })
              ])
            : _c(
                "div",
                { staticClass: "reply-message--docs" },
                [
                  !_vm.fileType
                    ? _c("div", { staticClass: "file message-text__wrap" }, [
                        _vm._m(0),
                        _vm._v(" "),
                        _c("h5", { staticClass: "text-block-title" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(decodeURI(_vm.fileName)) +
                              "\n        "
                          )
                        ])
                      ])
                    : _c("video-view", {
                        staticClass: "file",
                        attrs: { url: _vm.data.senderMessage }
                      })
                ],
                1
              )
        ]),
        _vm._v(" "),
        _vm.enableCancel && _vm.data.enableCancel
          ? _c(
              "button",
              {
                staticClass: "reply-message--button",
                attrs: { type: "button" },
                on: { click: _vm.onCancel }
              },
              [
                _c("icons", {
                  attrs: {
                    name: "cancel",
                    color: "stroke-red",
                    size: "semimedium"
                  }
                })
              ],
              1
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon-wrap" }, [
      _c("i", { staticClass: "ion-document-text" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }