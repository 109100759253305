var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "selection-bar" },
    [
      _c("div", { staticClass: "checkbox-container" }, [
        _c("label", { staticClass: "container-checkbox" }, [
          _c("input", {
            attrs: { type: "checkbox", value: "select_all" },
            domProps: { checked: _vm.selectAllLoaded },
            on: {
              input: function($event) {
                return _vm.selectCard(_vm.selectAllLoaded)
              }
            }
          }),
          _vm._v(" "),
          _c("span", { staticClass: "checkmark" })
        ]),
        _vm._v(" "),
        !_vm.selectAllLoaded
          ? _c(
              "label",
              {
                staticClass: "checkbox-label mg-top--six",
                attrs: { for: "select_all" }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$t("CONVERSATION.SELECT_BUTTON") +
                        " (" +
                        _vm.selected.length +
                        ")"
                    ) +
                    "\n    "
                )
              ]
            )
          : _c("div", { staticClass: "checkbox-container" }, [
              _c(
                "label",
                {
                  staticClass: "checkbox-label",
                  staticStyle: { "margin-top": "0.8rem" },
                  attrs: { for: "select_all" }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s("(" + _vm.selected.length + ")") +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "checkbox-container mg-left" }, [
                _c("label", { staticClass: "container-checkbox" }, [
                  _c("input", {
                    attrs: { type: "checkbox", value: "select_all" },
                    domProps: { checked: _vm.selectAll },
                    on: { input: _vm.selectAllConversation }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "checkmark" })
                ]),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "checkbox-label mg-top--six",
                    attrs: { for: "select_all" }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("CONVERSATION.SELECT_BUTTON") +
                            " (" +
                            _vm.selectCount +
                            ")"
                        ) +
                        "\n        "
                    )
                  ]
                )
              ])
            ])
      ]),
      _vm._v(" "),
      _c("woot-primary-button", {
        attrs: {
          type: "button",
          size: "small",
          name: _vm.$t("CONVERSATION.MODIFY_BUTTON"),
          "front-icon": "refresh",
          "icon-size": "semimedium"
        },
        on: { click: _vm.showModifyPopup }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }