var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-header",
    {
      attrs: {
        "header-title": _vm.$t("BOT_CSAT.CONTROLS.RATING_SCALE.TITLE"),
        size: "small",
        "custom-class": "grid-1-2-ratio",
        "info-text": _vm.$t("BOT_CSAT.CONTROLS.RATING_SCALE.DESCRIPTION")
      }
    },
    [
      _c("div", { staticClass: "rating-controls-grid" }, [
        _c(
          "label",
          {
            staticClass: "radio-container rating-control",
            staticStyle: { cursor: "pointer" },
            attrs: { for: "three-stars" }
          },
          [
            _c("span", {
              staticClass: "body-b2",
              domProps: { textContent: _vm._s("3 star") }
            }),
            _vm._v(" "),
            _c("input", {
              attrs: { id: "three-stars", type: "radio", name: "csat-rating" },
              domProps: { value: 3, checked: _vm.rating === 3 },
              on: {
                change: function($event) {
                  return _vm.$emit("updateConfig", 3)
                }
              }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "radio-circle" })
          ]
        ),
        _vm._v(" "),
        _c(
          "label",
          {
            staticClass: "radio-container rating-control",
            staticStyle: { cursor: "pointer" },
            attrs: { for: "five-stars" }
          },
          [
            _c("span", {
              staticClass: "body-b2",
              domProps: { textContent: _vm._s("5 star") }
            }),
            _vm._v(" "),
            _c("input", {
              attrs: { id: "five-stars", type: "radio", name: "csat-rating" },
              domProps: { value: 5, checked: _vm.rating === 5 },
              on: {
                change: function($event) {
                  return _vm.$emit("updateConfig", 5)
                }
              }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "radio-circle" })
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }