var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box settings-box no-padding" },
    [
      _c(
        "div",
        { staticClass: "settings-button flex-row flex-align gap--normal" },
        [
          _c("reference-box", {
            attrs: {
              to: _vm.documentation.team.create,
              content: "Know More",
              type: "document"
            }
          }),
          _vm._v(" "),
          _vm.isAdmin
            ? _c(
                "woot-base-button",
                {
                  attrs: {
                    size: "medium",
                    "front-icon": "plus",
                    "icon-view-box": "0 0 20 20"
                  },
                  on: { click: _vm.handleClick }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("TEAMS_SETTINGS.NEW_TEAM")) +
                      "\n    "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "settings-container" },
        [
          _c("div", { staticClass: "column settings-main-menu-new" }, [
            _c(
              "div",
              { staticClass: "table-container" },
              [
                _c("data-table", {
                  attrs: {
                    actions: _vm.actionsList,
                    columns: _vm.tableColumns,
                    "table-data": _vm.teamsList,
                    loading: _vm.uiFlags.isFetching,
                    "enable-animations": _vm.teamsList.length <= 10,
                    _class: "scrollable-table custom-scroll"
                  },
                  on: { select: _vm.viewTeam, delete: _vm.openDelete },
                  scopedSlots: _vm._u([
                    {
                      key: "table-features",
                      fn: function() {
                        return [
                          _c("search-box", {
                            attrs: {
                              placeholder: _vm.$t("TEAMS_SETTINGS.SEARCH_TXT"),
                              value: _vm.search,
                              "custom-class": "search-box"
                            },
                            on: { setSearch: _vm.setSearch }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "zero-state",
                      fn: function() {
                        return [
                          !_vm.teamsList.length
                            ? _c(
                                "table-zero-state",
                                {
                                  attrs: {
                                    title: "No Teams added till now",
                                    "asset-link":
                                      "/brand-assets/inbox_zero_state.svg"
                                  }
                                },
                                [
                                  _vm.isAdmin
                                    ? _c(
                                        "woot-base-button",
                                        {
                                          staticClass: "settings-button",
                                          staticStyle: {
                                            "margin-bottom": "5vh"
                                          },
                                          attrs: {
                                            size: "medium",
                                            "front-icon": "plus",
                                            "icon-view-box": "0 0 20 20"
                                          },
                                          on: { click: _vm.handleClick }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$t(
                                                  "TEAMS_SETTINGS.NEW_TEAM"
                                                )
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      },
                      proxy: true
                    }
                  ])
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "settings-side-card",
            [
              _vm.selectedTeam.id
                ? _c("team-form", {
                    key: _vm.selectedTeam.id,
                    staticClass: "team-form-container",
                    attrs: { "selected-team": _vm.selectedTeam }
                  })
                : _c("educational-onboarding", {
                    attrs: { "show-case-data": _vm.SHOWCASE_TEAMS_DATA }
                  })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "woot-modal-new",
        {
          attrs: {
            show: _vm.showAddPopup,
            "on-close": function() {
              return (_vm.showAddPopup = false)
            }
          },
          on: {
            "update:show": function($event) {
              _vm.showAddPopup = $event
            }
          }
        },
        [
          _c("woot-modal-header-new", {
            attrs: {
              "header-title": _vm.$t("TEAMS_SETTINGS.CREATE_FLOW.CREATE.TITLE")
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "add-team-modal" },
            [
              _c("team-form", {
                staticClass: "settings-form custom-scroll",
                on: {
                  onClose: function($event) {
                    _vm.showAddPopup = false
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("woot-delete-modal-new", {
        attrs: {
          show: _vm.showDeletePopup,
          "on-close": _vm.closeDelete,
          "on-confirm": _vm.confirmDeletion,
          title: _vm.$t("TEAMS_SETTINGS.DELETE.CONFIRM.TITLE"),
          message: _vm.$t("TEAMS_SETTINGS.DELETE.CONFIRM.MESSAGE"),
          "show-close": "",
          "confirm-text": "Continue",
          "reject-text": "Back",
          "custom-style": {
            "max-width": "35.2rem",
            height: "auto"
          }
        },
        on: {
          "update:show": function($event) {
            _vm.showDeletePopup = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }