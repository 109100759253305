var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("section-header", { attrs: { "translation-key": "ESCALATIONS" } }),
      _vm._v(" "),
      _c(
        "aside",
        { staticClass: "sla-escalations" },
        [
          _vm._l(_vm.escalations, function(metricKey) {
            return _c(
              "div",
              {
                key: metricKey,
                staticClass: "filter filter--with-border",
                class: { "mg-bottom--medium": _vm.isNotLastMetric(metricKey) }
              },
              [
                _c(
                  "div",
                  { staticClass: "flex-row flex-align gap--slab full-width" },
                  [
                    _c("span", [_vm._v("When SLA of ")]),
                    _vm._v(" "),
                    _c("metric-selector", {
                      class: "sla-escalations__metric_selector__" + metricKey,
                      attrs: {
                        value: metricKey,
                        "excluded-options": _vm.escalations
                      },
                      on: {
                        click: function($event) {
                          return _vm.onSelectMetric($event, metricKey)
                        }
                      }
                    }),
                    _vm._v(" "),
                    metricKey !== "select"
                      ? _c("span", [_vm._v("is breached")])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "woot-base-button",
                      {
                        attrs: { tag: "span", variant: "tertiary-danger" },
                        on: {
                          click: function($event) {
                            return _vm.deleteCondition(metricKey)
                          }
                        }
                      },
                      [
                        _c("icons", {
                          attrs: {
                            name: "bin",
                            color: "red",
                            size: "semimedium",
                            "show-title": false
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                metricKey !== "select"
                  ? _c(
                      "div",
                      {
                        staticClass: "flex-column flex-align--start gap--slab"
                      },
                      [
                        _vm._l(_vm.data.escalations[metricKey].action, function(
                          action,
                          actionIndex
                        ) {
                          return _c("escalation-action", {
                            key: actionIndex,
                            class:
                              "escalation-action__" +
                              metricKey +
                              "__" +
                              actionIndex,
                            attrs: {
                              action: action,
                              "metric-key": metricKey,
                              "action-index": actionIndex,
                              "dropdown-values": _vm.getDropdownValues(
                                _vm.assigneeTriggerKey
                              ),
                              "escalation-level": _vm.escalationLevel(
                                actionIndex
                              ),
                              "selected-values": _vm.getValues(
                                metricKey,
                                actionIndex
                              )
                            },
                            on: {
                              update: _vm.handleActionUpdate,
                              delete: _vm.deleteAction
                            }
                          })
                        }),
                        _vm._v(" "),
                        _vm.canAddAction(metricKey)
                          ? _c("add-filter-button", {
                              staticClass: "mg-top--smaller",
                              attrs: {
                                label: _vm.$t(
                                  "SLA.CREATE.SECTIONS.ESCALATIONS.ACTIONS.ADD_FILTER"
                                )
                              },
                              on: {
                                click: function($event) {
                                  return _vm.onAddAction(metricKey)
                                }
                              }
                            })
                          : _vm._e()
                      ],
                      2
                    )
                  : _vm._e()
              ]
            )
          }),
          _vm._v(" "),
          _vm.canAddMetricFilter
            ? _c(
                "div",
                { staticClass: "mg-top" },
                [
                  _c("add-filter-button", {
                    attrs: {
                      label: _vm.$t(
                        "SLA.CREATE.SECTIONS.ESCALATIONS.ADD_FILTER"
                      )
                    },
                    on: { click: _vm.onAddFilter }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }