var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lime-card-new pie-chart-skeleton", style: _vm.customStyle },
    [
      _c("div", { staticClass: "skeleton-animation pie-header" }),
      _vm._v(" "),
      _c("div", { staticClass: "flex-space-between" }, [
        _c("div", {
          staticClass: "skeleton-animation pie-circle",
          style: { height: _vm.chartHeight }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "skeleton-animation pie-legend" })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }