import { render, staticRenderFns } from "./ProductOption.vue?vue&type=template&id=002a6504&scoped=true&"
import script from "./ProductOption.vue?vue&type=script&lang=js&"
export * from "./ProductOption.vue?vue&type=script&lang=js&"
import style0 from "./ProductOption.vue?vue&type=style&index=0&id=002a6504&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "002a6504",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/limechat/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('002a6504')) {
      api.createRecord('002a6504', component.options)
    } else {
      api.reload('002a6504', component.options)
    }
    module.hot.accept("./ProductOption.vue?vue&type=template&id=002a6504&scoped=true&", function () {
      api.rerender('002a6504', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/lime-ui/dashboard/routes/dashboard/conversation/products/deepShopify/ProductOption.vue"
export default component.exports