var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal-new",
    {
      attrs: {
        show: _vm.show,
        "show-close": false,
        "custom-style": _vm.customStyle,
        "on-close": _vm.onClose
      },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "delete-modal-header flex-row flex-justify--between" },
        [
          _c("span", {
            staticClass: "title-h2 text-warn",
            domProps: { textContent: _vm._s(_vm.title) }
          }),
          _vm._v(" "),
          _vm.showClose
            ? _c(
                "woot-base-button",
                {
                  staticClass: "close-icon",
                  attrs: { tag: "span", variant: "tertiary-danger" },
                  on: { click: _vm.onClose }
                },
                [
                  _c("icons", {
                    attrs: {
                      name: "closeRound",
                      color: "red",
                      size: "normal",
                      "show-title": false
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "modal-content" }, [
        _c("span", {
          staticClass: "body-b1 text-dark",
          domProps: { innerHTML: _vm._s(_vm.message) }
        })
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "modal-footer delete-item flex-row flex-align--end gap--small"
        },
        [
          _c(
            "woot-base-button",
            {
              attrs: { size: "small", variant: "tertiary" },
              on: { click: _vm.onClose }
            },
            [_vm._v("\n      " + _vm._s(_vm.rejectText) + "\n    ")]
          ),
          _vm._v(" "),
          _vm.confirmText
            ? _c(
                "woot-base-button",
                {
                  attrs: {
                    variant: "secondary-danger",
                    size: "small",
                    "front-icon": _vm.showDeleteIcon ? "delete" : ""
                  },
                  on: { click: _vm.onConfirm }
                },
                [_vm._v("\n      " + _vm._s(_vm.confirmText) + "\n    ")]
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }