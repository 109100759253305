var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "shopify-integration" },
    [
      _vm.isLoading
        ? _c("woot-loading-state", {
            attrs: { message: "Fetching shopify details" }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { class: _vm.isLoading ? "disable-container" : "" }, [
        _vm.connectionStatus
          ? _c(
              "div",
              [
                _c("connected", {
                  attrs: { domain: _vm.domain },
                  on: { "get-shopify": _vm.getShopify }
                })
              ],
              1
            )
          : _c(
              "div",
              [
                _c("connect", { on: { "shopify-connect": _vm.shopifyConnect } })
              ],
              1
            )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }