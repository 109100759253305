var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "back-button",
      on: {
        "!click": function($event) {
          return _vm.goBack($event)
        }
      }
    },
    [
      _vm.title
        ? _c("span", [_vm._v(_vm._s(_vm.$t("GENERAL_SETTINGS.BACK")))])
        : _vm._e(),
      _vm._v(" "),
      _c("icons", {
        attrs: {
          name: "chevronLeft",
          color: "green",
          size: "large",
          title: "Go Back"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }