var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.product
    ? _c("div", [
        _c(
          "div",
          { staticClass: "categories" },
          [
            _vm.isOutOfStock
              ? _c("span", { staticClass: "out-of-stock title-h6" }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("PRODUCTS_MGMT.OUT_OF_STOCK")) +
                      "\n    "
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(Object.keys(_vm.categorisedOptions), function(
              category,
              index
            ) {
              return _c("div", { key: index, staticClass: "category" }, [
                _c("span", { staticClass: "text-light body-b3" }, [
                  _vm._v(_vm._s(category))
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "options--container flex-row" },
                  _vm._l(_vm.categorisedOptions[category], function(option) {
                    return _c(
                      "span",
                      { key: option.id },
                      [
                        _c("woot-chips", {
                          attrs: {
                            "custom-style": _vm.setCustomStyle(option),
                            "show-tooltip": false,
                            title: option.value.toUpperCase(),
                            "bg-color": _vm.setBgColor(option),
                            variant: "secondary-small"
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.onSelectOption(option)
                            }
                          }
                        })
                      ],
                      1
                    )
                  }),
                  0
                )
              ])
            })
          ],
          2
        )
      ])
    : _c("product-variants-skeleton")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }