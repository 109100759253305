var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "active-notification" }, [
    _c(
      "span",
      {
        staticClass: "notifications",
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.showNotification($event)
          }
        }
      },
      [
        _c("icons", {
          attrs: {
            name: "notification",
            color: "white",
            size: "mediumlarge",
            "show-title": false
          }
        }),
        _vm._v(" "),
        _vm.unreadCount
          ? _c(
              "span",
              { key: _vm.unreadCount, staticClass: "unread-badge zoom-in-out" },
              [_vm._v("\n      " + _vm._s(_vm.unreadCount) + "\n    ")]
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }