var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.agentData.id
    ? _c("div", [
        _c(
          "div",
          {
            staticClass: "profile-viewer",
            style: {
              backgroundImage: "url(" + _vm.bgImage + ")",
              backgroundColor: _vm.bgColor
            }
          },
          [
            _c("profile-viewer", {
              attrs: {
                src: _vm.agentData.thumbnail,
                "is-delete": _vm.agentData.canDelete,
                action: "edit",
                "disable-file-upload": ""
              },
              on: {
                action: function($event) {
                  return _vm.$emit(
                    "openEditModal",
                    Object.assign({}, _vm.agentData, { navigateTo: 0 })
                  )
                },
                delete: function($event) {
                  return _vm.$emit("openDeleteModal", [_vm.agentData.index])
                },
                profileChange: _vm.handleImageUpload
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "information-container" }, [
          _vm.personalDetails.length
            ? _c(
                "div",
                { staticClass: "personal-details" },
                _vm._l(_vm.personalDetails, function(detail) {
                  return _c(
                    "info-reader",
                    { key: detail },
                    [
                      _c("span", {
                        staticClass: "title-h5 text-dark",
                        domProps: { textContent: _vm._s(detail) }
                      }),
                      _vm._v(" "),
                      _c(
                        "woot-base-button",
                        {
                          attrs: {
                            variant: "tertiary",
                            tag: "span",
                            size: "small"
                          },
                          on: {
                            click: function($event) {
                              return _vm.onCopy(detail)
                            }
                          }
                        },
                        [
                          _c("icons", {
                            attrs: {
                              name: "copy",
                              view: "0 0 16 16",
                              color: "grey",
                              size: "normal"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "partition" }, [
            _vm.employmentDetails.length
              ? _c(
                  "div",
                  {
                    staticClass: "title-h5",
                    staticStyle: {
                      "font-variant": "all-small-caps",
                      "font-size": "2rem"
                    }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t(
                            "AGENT_MGMT.RHS_PANEL.SECTIONS.EMPLOYEMENT_DETAILS.TITLE"
                          )
                        ) +
                        "\n      "
                    )
                  ]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.employmentDetails.length
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "employment-details" },
                  _vm._l(_vm.employmentDetails, function(detail) {
                    return _c(
                      "info-reader",
                      {
                        key: detail.label,
                        attrs: { "custom-class": "employee-item" }
                      },
                      [
                        detail.label === "Signature"
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "flex-row flex-align flex-justify--between full-width"
                              },
                              [
                                _c("span", {
                                  staticClass: "body-b2 text-light",
                                  staticStyle: { cursor: "pointer" },
                                  domProps: {
                                    textContent: _vm._s(detail.label)
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit(
                                        "openEditModal",
                                        Object.assign({}, _vm.agentData, {
                                          navigateTo: 1
                                        })
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "woot-base-button",
                                  {
                                    attrs: {
                                      tag: "span",
                                      size: "small",
                                      variant: "tertiary"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.$emit(
                                          "openEditModal",
                                          Object.assign({}, _vm.agentData, {
                                            navigateTo: 1
                                          })
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("icons", {
                                      attrs: {
                                        name: "signature",
                                        color: "green",
                                        size: "normal"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : [
                              _c("span", {
                                staticClass: "body-b2 text-light",
                                domProps: { textContent: _vm._s(detail.label) }
                              }),
                              _vm._v(" "),
                              _c("span", {
                                staticClass: "title-h5 text-dark capitalize",
                                domProps: { innerHTML: _vm._s(detail.data) }
                              })
                            ]
                      ],
                      2
                    )
                  }),
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "inbox-chips custom-scroll" },
                  _vm._l(_vm.agentInboxes, function(inbox) {
                    return _c("woot-chips", {
                      key: inbox.id,
                      attrs: {
                        title: inbox.name,
                        "show-tooltip": false,
                        "custom-style": {
                          "border-color": _vm.getInboxMeta(inbox).hex,
                          color: _vm.getInboxMeta(inbox).hex
                        },
                        "front-icon": _vm.getInboxMeta(inbox).name,
                        "front-icon-color": _vm.getInboxMeta(inbox).color,
                        variant: "secondary-small"
                      }
                    })
                  }),
                  1
                )
              ])
            : _vm._e()
        ])
      ])
    : _c("educational-onboarding", {
        attrs: { "show-case-data": _vm.SHOWCASE_ROLE_DATA }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }