var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    {
      staticClass: "vertical dropdown menu canned",
      style: { top: _vm.getTopPadding() + "rem" },
      attrs: { id: "canned-list" }
    },
    [
      !_vm.templates.length
        ? _c("li", [
            _c("a", { staticClass: "text-truncate" }, [
              _vm._v(
                "\n      " +
                  _vm._s("No such template found for this inbox.") +
                  "\n    "
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.templates, function(item, index) {
        return _c(
          "li",
          {
            key: index,
            class: { active: index === _vm.selectedIndex },
            attrs: { id: "canned-" + index },
            on: {
              click: function($event) {
                return _vm.onListItemSelection(index)
              }
            }
          },
          [
            _c("strong", { staticStyle: { width: "5%", "min-width": "5%" } }, [
              _vm._v(_vm._s(item.display_id))
            ]),
            _vm._v(" "),
            _c("strong", [_vm._v(_vm._s(item.short_code))]),
            _vm._v(" "),
            _c("span", { staticStyle: { width: "5%", "min-width": "5%" } }, [
              _vm._v(_vm._s(item.template_type))
            ]),
            _vm._v(" "),
            _c("a", { staticClass: "text-truncate" }, [
              _vm._v("\n      " + _vm._s(item.body) + "\n    ")
            ])
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }