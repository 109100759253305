var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-row flex-align flex-justify--between" },
    [
      _c(
        "span",
        { staticClass: "flex-row flex-align" },
        [
          _c(
            "span",
            {
              staticClass: "mg-right",
              staticStyle: { cursor: "pointer" },
              on: { click: _vm.navigateBack }
            },
            [
              _c("icons", {
                attrs: {
                  name: "chevronLeft",
                  color: "grey",
                  size: "mediumlarge",
                  view: "0 -1 24 24",
                  title: "Go back"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("span", {
            staticClass: "title-h3 text-dark",
            domProps: {
              textContent: _vm._s(_vm.$t("CREATE_TICKET.HEADER.TITLE"))
            }
          }),
          _vm._v(" "),
          _c("create-ticket-button", {
            staticClass: "mg-left--slab CREATE_TICKET_INBOX_ID",
            attrs: { inbox: _vm.inbox, "is-menu-styled": "" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-row flex-align gap--normal" },
        [
          _c("reference-box", {
            attrs: {
              to: _vm.documentation.ticketCreation.email,
              content: "Email Ticket Guide",
              type: "document"
            }
          }),
          _vm._v(" "),
          _c("reference-box", {
            attrs: {
              to: _vm.documentation.ticketCreation.whatsapp,
              content: "WhatsApp Ticket Guide",
              type: "document"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }