var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "conversations-sidebar-header" }, [
      _c("div", { staticClass: "chat-list__top" }, [
        _c(
          "h1",
          { staticClass: "page-title text-truncate title-h1 text-dark" },
          [
            _vm._m(0),
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("CHAT_LIST.TAB_HEADING")) +
                "\n      "
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "search-container" },
        [
          _c(
            "woot-base-button",
            {
              staticClass: "search-button full-width",
              attrs: {
                variant: "secondary",
                "icon-size": "semimedium",
                "front-icon": "search",
                "icon-color": "lightgrey"
              },
              on: { click: _vm.onShowSearchModal }
            },
            [
              _c("span", {
                staticClass: "body-b3 text-dark line-clamp-1",
                domProps: { textContent: _vm._s(_vm.savedSearchMeta.query) }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "woot-base-button",
            {
              staticClass: "close-icon",
              attrs: {
                size: "small",
                tag: "span",
                variant: "secondary-danger"
              },
              on: { click: _vm.onClearSearch }
            },
            [
              _c("icons", {
                attrs: {
                  name: "closeRound",
                  color: "red",
                  size: "normal",
                  "show-title": false
                }
              })
            ],
            1
          )
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "conversations-list" },
      [
        _vm.showConversationCards
          ? [
              _vm._l(_vm.conversations, function(result) {
                return _c("conversation-details-card", {
                  key: result.id,
                  staticClass: "conversation-card-cmpt",
                  attrs: {
                    identifier: result.conversation.identifier,
                    "assignee-name": result.conversation.agent_name,
                    "contact-name": result.conversation.contact_name,
                    "display-id": result.conversation.display_id,
                    content: result.content,
                    data: result,
                    "contact-id": result.id,
                    "search-term": _vm.savedSearchMeta.query
                  },
                  on: {
                    routeNavigated: function($event) {
                      return _vm.$emit(
                        "conversation-load",
                        result.conversation.display_id
                      )
                    }
                  }
                })
              }),
              _vm._v(" "),
              _c("div", { staticClass: "search-list-footer" }, [
                _vm.uiFlags.isFetching && _vm.conversations.length
                  ? _c("div", { staticClass: "text-center" }, [
                      _c("span", { staticClass: "spinner" })
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.showLoadMore
                  ? _c(
                      "div",
                      { staticClass: "flex-row flex-justify" },
                      [
                        _c(
                          "woot-base-button",
                          {
                            attrs: { variant: "tertiary", size: "small" },
                            on: { click: _vm.loadMoreResults }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "CONVERSATION.SEARCH.RESULTS.LOAD_MORE"
                                  )
                                ) +
                                "\n          "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showEndOfResultsText
                  ? _c("div", {
                      staticClass: "text-center body-b2 text-light",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("CONVERSATION.SEARCH.RESULTS.END_OF_RESULTS")
                        )
                      }
                    })
                  : _vm._e()
              ])
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.showContactCards
          ? _vm._l(_vm.conversations, function(result) {
              return _c("contact-details-card", {
                key: result.id,
                staticClass: "conversation-card-cmpt",
                attrs: {
                  data: result,
                  "search-term": _vm.savedSearchMeta.query
                },
                on: {
                  routeNavigated: function(conversationid) {
                    return _vm.$emit("conversation-load", conversationid)
                  }
                }
              })
            })
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "mg-right--smaller" }, [
      _c("img", { attrs: { src: require("assets/images/ticket.svg") } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }