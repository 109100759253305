var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "summary-content" }, [
    _c("div", [
      _vm.personalInfo
        ? _c(
            "div",
            { staticClass: "title-h5_m text-dark mg-bottom" },
            [
              _vm._l(_vm.personalInfo, function(value, key) {
                return [
                  value
                    ? _c(
                        "div",
                        { key: key, staticClass: "summary-content-info" },
                        [
                          _vm.getIcon(key)
                            ? [
                                _c("icons", {
                                  attrs: {
                                    name: _vm.getIcon(key),
                                    color: "darkgrey",
                                    size: "normal"
                                  }
                                }),
                                _vm._v(" "),
                                _c("span", {
                                  staticClass: "info-value body-b3",
                                  domProps: {
                                    innerHTML: _vm._s(_vm.formatMessage(value))
                                  }
                                })
                              ]
                            : value
                            ? [
                                _c("span", {
                                  staticClass: "info-key title-h5 capitalize",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.convertToTitleCase(key) + ": "
                                    )
                                  }
                                }),
                                _vm._v(" "),
                                _c("span", {
                                  staticClass: "info-value body-b3",
                                  domProps: {
                                    innerHTML: _vm._s(_vm.formatMessage(value))
                                  }
                                })
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e()
                ]
              })
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.currentSummary.intent || _vm.currentSummary.text
        ? _c("div", { staticClass: "text-light body-b2 mg-bottom--small" }, [
            _vm._v("\n      Ticket Summary\n    ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.currentSummary.intent
        ? _c("div", {
            staticClass: "text-blue body-b2",
            domProps: {
              innerHTML: _vm._s("Intent: " + _vm.currentSummary.intent)
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.currentSummary.text
        ? _c("div", {
            staticClass: "text-dark body-b2 mg-bottom",
            domProps: { innerHTML: _vm._s(_vm.currentSummary.text) }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.previousSummary
        ? _c("div", {
            staticClass: "text-light body-b2 mg-bottom--small",
            domProps: { textContent: _vm._s(_vm.lastTicketSummaryHeader) }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.previousSummary
        ? _c("div", {
            staticClass: "text-dark body-b2",
            domProps: { innerHTML: _vm._s(_vm.previousSummary) }
          })
        : _vm._e()
    ]),
    _vm._v(" "),
    _c(
      "span",
      { staticClass: "meta flex-row flex-align gap--smaller" },
      [
        _c("icons", {
          attrs: {
            name: "eye",
            size: "small",
            color: "stroke-lightgrey",
            "custom-style": { strokeWidth: 2 }
          }
        }),
        _vm._v(" "),
        _c("span", {
          staticClass: "text-light",
          domProps: {
            textContent: _vm._s(_vm.$t("CONVERSATION.GPT.VISIBILITY"))
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }