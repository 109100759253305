var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal-new",
    {
      attrs: {
        show: _vm.show,
        "on-close": _vm.onClose,
        "disable-btn": {
          0: _vm.shouldDisableBtn,
          1: _vm.$v.body.$invalid
        }
      },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        },
        submit: _vm.createTemplate
      },
      scopedSlots: _vm._u([
        {
          key: "paginated-components",
          fn: function() {
            return [
              _c("div", { staticClass: "template-form" }, [
                _c(
                  "div",
                  { staticClass: "settings-form custom-scroll" },
                  [
                    _c("woot-input", {
                      staticClass: "medium-12 columns content-margin",
                      class: { error: _vm.$v.shortCode.$error },
                      attrs: {
                        required: "",
                        "auto-focus": "",
                        "has-error": _vm.$v.shortCode.$error,
                        error: _vm.$t("TEMPLATE.ADD.FORM.SHORT_CODE.ERROR"),
                        label: _vm.$t("TEMPLATE.ADD.FORM.SHORT_CODE.LABEL"),
                        "help-text": _vm.$t("TEMPLATE.CODE_EXMPL"),
                        placeholder: _vm.$t(
                          "TEMPLATE.ADD.FORM.SHORT_CODE.PLACEHOLDER"
                        )
                      },
                      on: { blur: _vm.$v.shortCode.$touch },
                      model: {
                        value: _vm.shortCode,
                        callback: function($$v) {
                          _vm.shortCode =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "shortCode"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex-row flex-align gap--two content-margin",
                        class:
                          !_vm.isGupshupEnterpriseInbox || _vm.isMediaTemplate
                            ? "flex-row"
                            : "flex-column"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "full-width" },
                          [
                            _c("div", { staticClass: "flex-row flex-align" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "formlabel",
                                  class: { error: _vm.$v.type.$error }
                                },
                                [
                                  _c("span", {
                                    staticClass: "title-h5 text-dark",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("TEMPLATE.ADD.FORM.TYPE.LABEL")
                                      )
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.$t(
                                        "TEMPLATE.ADD.FORM.TYPE.INFO"
                                      ),
                                      expression:
                                        "$t('TEMPLATE.ADD.FORM.TYPE.INFO')"
                                    }
                                  ],
                                  staticClass:
                                    "mg-bottom--smaller mg-left--small"
                                },
                                [
                                  _c("icons", {
                                    attrs: {
                                      name: "warning",
                                      color: "grey evenodd",
                                      size: "semimedium",
                                      "show-title": false
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("woot-single-selector", {
                              attrs: {
                                size: "large",
                                "option-list": _vm.typeList,
                                "default-option": _vm.selectedType,
                                "custom-style": {
                                  top: "4.8rem",
                                  left: "auto",
                                  width: "100%"
                                }
                              },
                              on: { click: _vm.changeType }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "full-width",
                            staticStyle: { position: "relative" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "flex-row flex-align gap--smaller"
                              },
                              [
                                _c("span", {
                                  staticClass: "dot-circle mg-bottom--small",
                                  attrs: { title: "required" }
                                }),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass: "formlabel",
                                    class: { error: !_vm.inboxId }
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "title-h5 text-dark",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t(
                                            "TEMPLATE.ADD.FORM.INBOX.LABEL"
                                          )
                                        )
                                      }
                                    })
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: _vm.$t(
                                          "TEMPLATE.ADD.FORM.INBOX.INFO"
                                        ),
                                        expression:
                                          "$t('TEMPLATE.ADD.FORM.INBOX.INFO')"
                                      }
                                    ],
                                    staticClass:
                                      "mg-bottom--smaller mg-left--small"
                                  },
                                  [
                                    _c("icons", {
                                      attrs: {
                                        name: "warning",
                                        color: "grey evenodd",
                                        size: "semimedium",
                                        "show-title": false
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("woot-single-selector", {
                              attrs: {
                                size: "large",
                                "option-list": _vm.inboxList,
                                "default-option": _vm.selectedInboxName,
                                "custom-style": {
                                  top: "4.8rem",
                                  left: "auto",
                                  width: "100%",
                                  maxHeight: "20rem"
                                }
                              },
                              on: { click: _vm.changeInbox }
                            }),
                            _vm._v(" "),
                            _vm.$v.inboxId.$invalid
                              ? _c("span", {
                                  staticClass: "desc-text capital_c3 text-red",
                                  staticStyle: { "padding-left": "0" },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("TEMPLATE.ADD.FORM.INBOX.ERROR")
                                    )
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.isMediaTemplate
                      ? _c("woot-input", {
                          staticClass: "medium-12 columns content-margin",
                          class: { error: _vm.$v.mediaUrl.$error },
                          attrs: {
                            "has-error": _vm.$v.mediaUrl.$error,
                            error: _vm.$t("TEMPLATE.ADD.FORM.MEDIA.ERROR"),
                            label: _vm.$t("TEMPLATE.ADD.FORM.MEDIA.LABEL"),
                            "help-text": _vm.$t(
                              "TEMPLATE.ADD.FORM.MEDIA.DESCRIPTION"
                            ),
                            placeholder: _vm.$t(
                              "TEMPLATE.ADD.FORM.MEDIA.PLACEHOLDER"
                            )
                          },
                          on: { blur: _vm.$v.mediaUrl.$touch },
                          model: {
                            value: _vm.mediaUrl,
                            callback: function($$v) {
                              _vm.mediaUrl =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "mediaUrl"
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isGupshupEnterpriseInbox
                      ? _c("woot-input", {
                          staticClass: "medium-12 columns content-margin",
                          class: { error: _vm.$v.body.$error },
                          attrs: {
                            rows: "6",
                            required: "",
                            "has-error": _vm.$v.body.$error,
                            error: _vm.$t("TEMPLATE.ADD.FORM.BODY.ERROR"),
                            label: _vm.$t("TEMPLATE.ADD.FORM.BODY.LABEL"),
                            placeholder: _vm.$t(
                              "TEMPLATE.ADD.FORM.BODY.PLACEHOLDER"
                            ),
                            "help-text": _vm.$t("TEMPLATE.ADD.FORM.BODY.INFO")
                          },
                          on: { blur: _vm.$v.body.$touch },
                          model: {
                            value: _vm.body,
                            callback: function($$v) {
                              _vm.body =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "body"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _vm.isGupshupEnterpriseInbox
                ? _c("div", { staticClass: "template-form" }, [
                    _c(
                      "div",
                      { staticClass: "settings-form custom-scroll" },
                      [
                        _c("woot-input", {
                          staticClass: "medium-12 columns content-margin",
                          attrs: {
                            label: _vm.$t("TEMPLATE.ADD.FORM.HEADER.LABEL"),
                            "help-text": _vm.$t(
                              "TEMPLATE.ADD.FORM.HEADER.DESCRIPTION"
                            ),
                            placeholder: _vm.$t(
                              "TEMPLATE.ADD.FORM.HEADER.PLACEHOLDER"
                            )
                          },
                          model: {
                            value: _vm.header,
                            callback: function($$v) {
                              _vm.header =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "header"
                          }
                        }),
                        _vm._v(" "),
                        _c("woot-input", {
                          staticClass: "medium-12 columns content-margin",
                          class: { error: _vm.$v.body.$error },
                          attrs: {
                            rows: "6",
                            required: "",
                            "has-error": _vm.$v.body.$error,
                            error: _vm.$t("TEMPLATE.ADD.FORM.BODY.ERROR"),
                            label: _vm.$t("TEMPLATE.ADD.FORM.BODY.LABEL"),
                            placeholder: _vm.$t(
                              "TEMPLATE.ADD.FORM.BODY.PLACEHOLDER"
                            ),
                            "help-text": _vm.$t("TEMPLATE.ADD.FORM.BODY.INFO")
                          },
                          on: { blur: _vm.$v.body.$touch },
                          model: {
                            value: _vm.body,
                            callback: function($$v) {
                              _vm.body =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "body"
                          }
                        }),
                        _vm._v(" "),
                        _c("woot-input", {
                          staticClass: "medium-12 columns",
                          attrs: {
                            label: _vm.$t("TEMPLATE.ADD.FORM.FOOTER.LABEL"),
                            "help-text": _vm.$t(
                              "TEMPLATE.ADD.FORM.FOOTER.DESCRIPTION"
                            ),
                            placeholder: _vm.$t(
                              "TEMPLATE.ADD.FORM.FOOTER.PLACEHOLDER"
                            )
                          },
                          model: {
                            value: _vm.footer,
                            callback: function($$v) {
                              _vm.footer =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "footer"
                          }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("woot-modal-header-new", {
        attrs: { "header-title": _vm.$t("TEMPLATE.ADD.TITLE") }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }