var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "button nice",
      class: _vm.variant,
      attrs: { type: _vm.type },
      on: { click: _vm.onClick }
    },
    [
      !_vm.isLoading && _vm.icon
        ? _c("i", {
            staticClass: "icon",
            class: _vm.buttonIconClass + " " + _vm.icon
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isLoading ? _c("spinner") : _vm._e(),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }