var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoading
    ? _c("LoadingState")
    : !_vm.isEnabled
    ? _c("Index")
    : _c("div", { staticClass: "column content-box settings-box no-padding" }, [
        _c("div", { staticClass: "settings-container" }, [
          _c("iframe", {
            staticStyle: {
              width: "calc(100% + 16px)",
              height: "100%",
              "margin-left": "-16px"
            },
            attrs: { src: _vm.marketingUrl, frameborder: "0" }
          })
        ])
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }