var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    {
      attrs: { show: _vm.show, "on-close": _vm.onClose },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c("page-header", {
        attrs: {
          "header-title": _vm.$t("CONVERSATION.FILTER.TITLE"),
          "show-border": false
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "filter-feature" },
        [
          _vm.appliedConversationFiltersCopy
            ? _c("conversation-filter-tab", {
                staticClass: "conversation-filters",
                attrs: {
                  "filter-list": _vm.filterOptions,
                  "applied-filters": _vm.appliedConversationFiltersCopy,
                  "parent-component-name": "Ticket Filter"
                },
                on: { filterChange: _vm.updateFilterType }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "search-results-container" }, [
            _c(
              "div",
              {
                staticClass:
                  "flex-row flex-align flex-justify--between mg-bottom--smaller"
              },
              [
                _c(
                  "div",
                  [
                    _c("span", {
                      staticClass: "text-light body-b2",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("CONVERSATION.FILTER.RESULTS.HEADING")
                        )
                      }
                    }),
                    _vm._v(" "),
                    _c("transition", { attrs: { name: "modal-fade" } }, [
                      _vm.resultsCount > 3
                        ? _c("span", {
                            staticClass: "text-dark title-h5_m",
                            domProps: {
                              textContent: _vm._s("(" + _vm.resultsCount + ")")
                            }
                          })
                        : _vm._e()
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "conversation-sort-filter" },
                  [
                    _c("conversation-sort-filter", {
                      key: _vm.componentKey,
                      ref: "conversationSortFilter",
                      attrs: { variant: "tertiary" },
                      on: { applyFilter: _vm.updateFilterType }
                    })
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "results custom-scroll" },
              [
                _c(
                  "transition",
                  { attrs: { name: "modal-fade", mode: "out-in", appear: "" } },
                  [
                    _vm.showConversationCards
                      ? _c(
                          "div",
                          { staticClass: "result-cards" },
                          _vm._l(_vm.filteredResults, function(result) {
                            return _c("conversation-details-card", {
                              key: result.id,
                              attrs: {
                                identifier: result.identifier,
                                "assignee-name":
                                  result.meta.assignee &&
                                  result.meta.assignee.name,
                                "contact-name": result.meta.sender.name,
                                "display-id": result.id,
                                content: _vm.lastMessage(result),
                                data: result,
                                "contact-id": result.id
                              },
                              on: { routeNavigated: _vm.onClose }
                            })
                          }),
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isFetchingResults
                      ? _c("loading-state", { staticClass: "loading-state" })
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isFetchingResults && !_vm.filteredResults.length
                      ? _c("img", {
                          staticClass: "zero-state",
                          attrs: {
                            src: require("dashboard/assets/images/search_not_found.svg"),
                            alt: "no-results-found"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "transition",
                  { attrs: { name: "modal-fade", mode: "out-in", appear: "" } },
                  [
                    _vm.showLoadMore
                      ? _c(
                          "div",
                          { staticClass: "flex-row flex-justify" },
                          [
                            _c(
                              "woot-base-button",
                              {
                                attrs: { variant: "tertiary", size: "small" },
                                on: { click: _vm.loadMoreResults }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "CONVERSATION.FILTER.RESULTS.LOAD_MORE"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showEndOfResultsText
                      ? _c("div", {
                          staticClass: "text-center body-b2 text-light",
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t(
                                "CONVERSATION.FILTER.RESULTS.END_OF_RESULTS"
                              )
                            )
                          }
                        })
                      : _vm._e()
                  ]
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "medium-12 columns settings-sub-menu-bottom-nav" },
        [
          _c(
            "woot-base-button",
            {
              attrs: { size: "small", variant: "secondary-danger" },
              on: { click: _vm.resetAllFilters }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("CONVERSATION.FILTER.RESET")) +
                  "\n    "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "woot-base-button",
            { attrs: { size: "small" }, on: { click: _vm.saveFilter } },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("CONVERSATION.FILTER.APPLICATION.TITLE")) +
                  "\n    "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }