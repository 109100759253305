var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box settings-box shopify-container" },
    [
      _c(
        "div",
        { staticClass: "shopify-body-container" },
        [
          _c("div", { staticClass: "image-container" }, [
            _c("div", { staticClass: "shopify-image" }, [
              _c("img", {
                attrs: { src: "/dashboard/images/integrations/shopify.svg" }
              })
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("icons", {
                  attrs: { name: "add", size: "large", color: "#434343" }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", [
              _c("img", {
                staticClass: "shopify-image",
                attrs: { src: "/brand-assets/logo_thumbnail.svg" }
              })
            ])
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "connected-success-text connected-text" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("INTEGRATION_SETTINGS.SHOPIFY.CONNECT_SUCCESS")) +
                "\n    "
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "connected-text" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("INTEGRATION_SETTINGS.SHOPIFY.STORE_NAME")) +
                "\n    "
            )
          ]),
          _vm._v(" "),
          _c(
            "a",
            { attrs: { href: _vm.formattedUrl, target: "_blank" } },
            [
              _c(
                "woot-button",
                {
                  staticClass: "button nice hollow go-to-shopify-button",
                  attrs: { type: "submit" }
                },
                [
                  _c("icons", {
                    attrs: {
                      name: "rocketFilled",
                      size: "medium",
                      color: "green"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("INTEGRATION_SETTINGS.SHOPIFY.GO_TO_STORE_TXT")
                        ) +
                        "\n        "
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "remove-connection shopify-form",
              on: { click: _vm.onClick }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("INTEGRATION_SETTINGS.SHOPIFY.REMOVE_CON")) +
                  "\n    "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "woot-modal",
            {
              attrs: { show: _vm.show, "on-close": _vm.onClose },
              on: {
                "update:show": function($event) {
                  _vm.show = $event
                }
              }
            },
            [
              _c("div", { staticClass: "delete-modal" }, [
                _c("p", { staticClass: "confirm-text modal-typography" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("INTEGRATION_SETTINGS.SHOPIFY.CONFIRM")) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "modal-typography" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("INTEGRATION_SETTINGS.SHOPIFY.CONFIRM_TXT")
                      ) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "flex-end"
                    }
                  },
                  [
                    _c(
                      "woot-button",
                      {
                        staticClass: "button hollow link",
                        staticStyle: { width: "auto", "font-size": "13px" },
                        on: { click: _vm.onClose }
                      },
                      [
                        _c("span", { staticClass: "modal-typography" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t(
                                  "INTEGRATION_SETTINGS.SHOPIFY.KEEP_INTGRETION_TXT"
                                )
                              ) +
                              "\n            "
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "woot-button",
                      {
                        staticClass: "button hollow red cancel",
                        staticStyle: {
                          width: "auto",
                          color: "#ff7e61",
                          border: "1px solid #ff7e61",
                          "font-size": "13px"
                        },
                        on: {
                          click: function($event) {
                            return _vm.shopifyDelete(_vm.domain)
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "modal-typography" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t(
                                  "INTEGRATION_SETTINGS.SHOPIFY.REMOVE_INTGRETION_TXT"
                                )
                              ) +
                              "\n            "
                          )
                        ])
                      ]
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }