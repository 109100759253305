var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "contacts-table-wrap custom-scroll" },
    [
      _c("table", { staticClass: "woot-table contacts-table" }, [
        _c(
          "thead",
          _vm._l(_vm.$t("CONTACTS_PAGE.LIST.TABLE_HEADER"), function(thHeader) {
            return _c("th", { key: thHeader }, [
              _vm._v("\n        " + _vm._s(thHeader) + "\n      ")
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "tbody",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showTableData && !_vm.isInvalidSearch,
                expression: "showTableData && !isInvalidSearch"
              }
            ]
          },
          _vm._l(_vm.contacts, function(contactItem) {
            return _c(
              "tr",
              {
                key: contactItem.id,
                class: { "is-active": contactItem.id === _vm.activeContactId },
                on: {
                  click: function() {
                    return _vm.onClickContact(contactItem.id)
                  }
                }
              },
              [
                _c("td", [
                  _c(
                    "div",
                    { staticClass: "row-main-info" },
                    [
                      _c("thumbnail", {
                        attrs: {
                          src: contactItem.thumbnail,
                          size: "36px",
                          username: contactItem.name,
                          status: contactItem.availability_status
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("account-pii-mask-wrapper", {
                            attrs: {
                              "pii-data": contactItem.name,
                              "mask-type": _vm.maskType.NAME,
                              "unmask-action": "piiMask/logUnmaskEvent",
                              "unmask-payload": _vm.unmaskRequestPayload(
                                contactItem.id
                              )
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var data = ref.data
                                    return [
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "sub-block-title user-name"
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(data) +
                                              "\n                  "
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          }),
                          _vm._v(" "),
                          _c("account-pii-mask-wrapper", {
                            attrs: {
                              "pii-data":
                                _vm.contactMetadata(contactItem) || "",
                              "mask-type": _vm.maskType.EMAIL,
                              "unmask-action": "piiMask/logUnmaskEvent",
                              "unmask-payload": _vm.unmaskRequestPayload(
                                contactItem.id
                              )
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var data = ref.data
                                    return [
                                      _c("p", { staticClass: "user-email" }, [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(data) +
                                            "\n                  "
                                        )
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("account-pii-mask-wrapper", {
                      attrs: {
                        "pii-data": contactItem.phone_number || "",
                        "mask-type": _vm.maskType.PHONE,
                        "unmask-action": "piiMask/logUnmaskEvent",
                        "unmask-payload": _vm.unmaskRequestPayload(
                          contactItem.id
                        )
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var data = ref.data
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(data) +
                                    "\n            "
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("td", { staticClass: "conversation-count-item" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(contactItem.conversations_count) +
                      "\n        "
                  )
                ])
              ]
            )
          }),
          0
        )
      ]),
      _vm._v(" "),
      !_vm.isLoading && (_vm.showSearchEmptyState || _vm.isInvalidSearch)
        ? _c("empty-state", {
            attrs: { title: _vm.$t("CONTACTS_PAGE.LIST.404") }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "contacts--loader" },
            [
              _c("spinner"),
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.$t("CONTACTS_PAGE.LIST.LOADING_MESSAGE")))
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }