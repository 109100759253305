var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.uiFlags.fetchingSchema
    ? _c("loading-state", {
        staticStyle: { height: "20rem" },
        attrs: {
          message: _vm.$t(
            "INTEGRATION_SETTINGS.CRM.INTEGRATIONS.LEADSQUARED.FORM.LOADING"
          )
        }
      })
    : _vm.uiFlags.fetchingList
    ? _c("loading-state", {
        staticStyle: { height: "20rem" },
        attrs: {
          message: _vm.$t(
            "INTEGRATION_SETTINGS.CRM.INTEGRATIONS.LEADSQUARED.FORM.LOADING_DETAILS"
          )
        }
      })
    : _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.onSubmit($event)
            }
          }
        },
        [
          _vm._l(_vm.formFieldNames, function(fieldName) {
            return _c(
              "div",
              { key: fieldName, staticClass: "content-margin" },
              [
                _c("data-type-input", {
                  attrs: {
                    label: fieldName,
                    "data-type": _vm.schema[fieldName].data_type,
                    value: _vm.formData[fieldName],
                    validator: _vm.$v.formData[fieldName],
                    "update-value": function(newValue) {
                      return _vm.updateFormData(fieldName, newValue)
                    }
                  }
                })
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "full-width flex-row flex-justify" },
            [
              _c(
                "woot-base-button",
                {
                  attrs: {
                    type: "submit",
                    loading: _vm.uiFlags.creatingTicket,
                    disabled: _vm.$v.formData.$invalid
                  }
                },
                [_vm._v("\n      Create Opportunity\n    ")]
              )
            ],
            1
          )
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }