var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pos-relative" }, [
    _c("span", { staticClass: "table-title" }, [_vm._v(_vm._s(_vm.title))]),
    _vm._v(" "),
    _c(
      "span",
      { staticClass: "tooltip-position" },
      [_c("icons", { attrs: { name: "info", size: "medium", color: "grey" } })],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "title-h3 text-light" }, [
      _vm._v("\n    " + _vm._s(_vm.subtitle) + "\n  ")
    ]),
    _vm._v(" "),
    _c("div", {
      ref: "funnel_chart",
      staticClass: "chart",
      attrs: { id: _vm.id }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }